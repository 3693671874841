import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
} from "@mui/material";

import { InputRadioPropsType } from "./InputRadio.container";

export const InputRadioComponent = ({
    label,
    data,
    value,
    name,
    onChange,
}: InputRadioPropsType) => {
    return (
        <FormControl>
            <FormLabel>{label}</FormLabel>
            <RadioGroup
                value={value || null}
                name={name}
                onChange={(e, value) => onChange(name, value)}
            >
                {data?.map((item: any) => (
                    <FormControlLabel
                        data-testid={`radio-${label}-${item.replaceAll(
                            " ",
                            "_",
                        )}`}
                        key={item}
                        value={item}
                        control={<Radio />}
                        label={item}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
};
