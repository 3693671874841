import { Checkbox, FormControl, Stack, Typography } from "@mui/material";
import { FormikProvider } from "formik";
import React from "react";

import { Spinner, TMC_Button } from "src/components";
import { Styled_StackMainAlert } from "src/components/Components_Common/Alert/Alert.style";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { Type_formData_NewUser_Invitation } from "src/pages/Pages_Common/Page_NewUser_Invitation/Page_NewUser_Invitation.container";
import {
    Styled_Form,
    Styled_Scrollable_Stack,
} from "src/pages/Pages_Common/Page_NewUser_Invitation/Page_NewUser_Invitation.style";
import { COLORS } from "src/theme/stylesheet";

export type TypeComponentProps = {
    step: number;
    maxStep: number;
    formik: any;
    handleStep: (stateAction: "previous" | "next") => void;
    formData: Type_formData_NewUser_Invitation;
};

type Type_DisplayDataProps = {
    title: string;
    value: any;
};

const DisplayData = ({
    title,
    value,
}: Type_DisplayDataProps): React.JSX.Element => {
    return (
        <Stack
            marginBottom={2}
            data-testid={`UsersInvitation-step-displayData-${value}`}
        >
            <Typography variant="body3" color={COLORS.moon600}>
                {title}
            </Typography>
            <Typography variant="body1Bold" color={COLORS.blue900}>
                {value === null || typeof value === "undefined" || value === ""
                    ? "__"
                    : typeof value === "string"
                      ? value
                      : Array.isArray(value)
                        ? value.toString()
                        : value.format("DD-MM-YYYY")}
            </Typography>
        </Stack>
    );
};

export const Step_5Component = ({
    step,
    maxStep,
    formik,
    handleStep,
    formData,
}: TypeComponentProps) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Teamoty.NewUserInvitation",
    );
    const { formatMessageWithPartialKey: fmtCta } = useCoreIntl("Form.Cta");

    const metadata: Array<React.JSX.Element> = [];
    for (const [key, value] of Object.entries(formData.metadata)) {
        const title: string = key.charAt(0).toUpperCase() + key.slice(1);
        metadata.push(<DisplayData title={title} value={value} key={key} />);
    }

    return (
        <FormikProvider value={formik}>
            <Styled_Form data-testid={`UsersInvitation-step-${step}-form`}>
                <Stack rowGap="24px" width={"100%"}>
                    <Typography
                        variant="body2"
                        color={COLORS.moon600}
                        data-testid={`UsersInvitation-step-${step}-of-${maxStep}`}
                    >
                        {fmt("Steps", {
                            values: {
                                currentStep: step,
                                maxStep: maxStep,
                            },
                        })}
                    </Typography>
                    <Typography
                        variant="h1"
                        data-testid={`UsersInvitation-step-${step}-title`}
                    >
                        {fmt("Step5.Title")}
                    </Typography>
                    <Typography variant="body1">
                        {fmt("Step5.Description")}
                    </Typography>

                    <Styled_StackMainAlert ownerState={{ status: "info" }}>
                        <Styled_Scrollable_Stack>
                            <DisplayData
                                title={fmt("Step5.Label.Email")}
                                value={formData.email}
                            />
                            <DisplayData
                                title={fmt("Step5.Label.FirstName")}
                                value={formData.firstName}
                            />
                            <DisplayData
                                title={fmt("Step5.Label.LastName")}
                                value={formData.lastName}
                            />
                            <DisplayData
                                title={fmt("Step5.Label.Phone")}
                                value={formData.phone}
                            />
                            {metadata}
                            <DisplayData
                                title={fmt("Step5.Label.CompanyName")}
                                value={formData.company_name}
                            />
                            <DisplayData
                                title={fmt("Step5.Label.NationalId")}
                                value={formData.company_nationalId}
                            />
                            <DisplayData
                                title={fmt("Step5.Label.PostCode")}
                                value={formData.company_postalCode}
                            />
                            <DisplayData
                                title={fmt("Step5.Label.City")}
                                value={formData.company_city}
                            />
                            <DisplayData
                                title={fmt("Step5.Label.Country")}
                                value={formData.company_country}
                            />
                        </Styled_Scrollable_Stack>
                    </Styled_StackMainAlert>

                    <Stack>
                        {formik?.errors?.checkbox &&
                            formik?.errors?.checkbox === "Required" && (
                                <Typography
                                    data-testid="UserInvitation-CheckData-HelperText"
                                    variant={"body1"}
                                    color={"error.main"}
                                >
                                    {formik.errors.checkbox}
                                </Typography>
                            )}
                        <Stack direction="row" alignItems="start">
                            <FormControl>
                                <Checkbox
                                    data-testid={`UsersInvitation-step-${step}-Approve-checkbox`}
                                    {...formik.getFieldProps("checkbox")}
                                    name="checkbox"
                                    checked={formik.values.checkbox}
                                />
                            </FormControl>
                            <Stack paddingTop="9px">
                                <Typography variant="body1">
                                    {fmt("Step5.Approve")}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color={COLORS.moon600}
                                >
                                    {fmt("Step5.Checking")}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    paddingTop="24px"
                >
                    <TMC_Button
                        data-testid={`UsersInvitation-step-${step}-GoBack-btn`}
                        onClick={() => handleStep("previous")}
                        type="button"
                        variant="text"
                        disabled={formik.isSubmitting}
                        startIcon={
                            !formik.isSubmitting && (
                                <Icon variant={"solid"} icon={"arrow-left"} />
                            )
                        }
                        sx={{
                            width: "fit-content",
                            blockSize: "fit-content",
                            color: COLORS.blue700,
                        }}
                    >
                        {fmtCta("Back")}
                    </TMC_Button>
                    <TMC_Button
                        data-testid={`UsersInvitation-step-${step}-Submit-btn`}
                        type="submit"
                        variant="contained"
                        disabled={formik.isSubmitting}
                        endIcon={
                            !formik.isSubmitting && (
                                <Icon variant={"solid"} icon={"check"} />
                            )
                        }
                        sx={{ width: "fit-content", blockSize: "fit-content" }}
                    >
                        {formik.isSubmitting ? (
                            <Spinner style="button" />
                        ) : (
                            fmt("Step5.Cta.Submit")
                        )}
                    </TMC_Button>
                </Stack>
            </Styled_Form>
        </FormikProvider>
    );
};
