import { PeopleTaskForm } from "src/forms/peopleTasks/PeopleTaskForm";
import { Styled_PaperContextualLayout } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

type Type_Props_PeopleTaskContextualDrawer = {
    onClose: () => void;
    id: number;
    focus: boolean;
    areaId?: number;
};

export const PeopleTaskContextualDrawer = ({
    onClose,
    id,
    focus = false,
    areaId,
}: Type_Props_PeopleTaskContextualDrawer) => {
    return (
        <Styled_PaperContextualLayout id="peopleTask">
            <PeopleTaskForm
                onClose={onClose}
                id={id}
                focus={focus}
                areaId={areaId}
            />
        </Styled_PaperContextualLayout>
    );
};
