import * as React from "react";

import { LINK_DIRECTION } from "src/api/tms-scheduling/taskLinks/types";
import { LinkForm } from "src/forms/links/LinkForm";
import { Styled_PaperContextualLayout } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

//////////////////////////////////////////////

type Type_Props_TaskLinkContextualDrawer = {
    onClose: () => void;
    id: number;
    type: LINK_DIRECTION;
    taskArea?: boolean;
};

export const TaskLinkContextualDrawer = ({
    onClose,
    id,
    type,
}: Type_Props_TaskLinkContextualDrawer) => {
    return (
        <Styled_PaperContextualLayout id="link">
            <LinkForm onClose={onClose} id={id} type={type} />
        </Styled_PaperContextualLayout>
    );
};
