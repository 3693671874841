import {
    Accordion,
    AccordionDetails,
    AccordionDetailsProps,
    AccordionProps,
    AccordionSummary,
    AccordionSummaryProps,
    Chip,
    ChipProps,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { COLORS } from "src/theme/stylesheet";

export const Styled_Accordion = styled(Accordion)<AccordionProps>(
    ({ theme }) => ({
        "&.MuiAccordion-root::before": {
            display: "none",
        },
        "&.Mui-expanded": {
            borderBoxSize: "border-box",
            borderTop: theme.border.default,
            borderBottom: theme.border.default,
        },
    }),
);

export const Styled_AccordionSummary = styled(
    AccordionSummary,
)<AccordionSummaryProps>(({ theme }) => ({
    ".MuiAccordionSummary-content": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    padding: `0 ${theme.spacing(4)}`,
    margin: 0,
    minHeight: 32,
}));

export const Styled_AccordionDetails = styled(
    AccordionDetails,
)<AccordionDetailsProps>(() => ({}));

export const Styled_AccordionChip = styled(Chip)<ChipProps>(({ theme }) => ({
    backgroundColor: COLORS.grey100,
    color: COLORS.grey600,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    border: `1px solid ${COLORS.grey300}`,
}));
