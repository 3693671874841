import { useState } from "react";

import { useForgetPassword } from "src/api/tms-users/auth";

import { Page_ForgetPasswordComponent } from "./Page_ForgetPassword.component";

export type Type_forgetPasswordState = {
    email: string;
};

export const Page_ForgetPasswordContainer = () => {
    const [emailSended, setEmailSended] = useState(false);
    const initialState: Type_forgetPasswordState = {
        email: "",
    };

    const { mutate: forgetPassword } = useForgetPassword();

    const handleSubmit = (values: Type_forgetPasswordState) => {
        forgetPassword(values.email);
        setEmailSended(true);
    };

    return (
        <Page_ForgetPasswordComponent
            handleSubmit={handleSubmit}
            state={initialState}
            emailSended={emailSended}
        />
    );
};
