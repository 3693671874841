import {
    Autocomplete,
    Checkbox,
    Divider,
    Stack,
    Typography,
} from "@mui/material";
import { Dayjs } from "dayjs";
import { Formik, FormikValues } from "formik";
import React, { useMemo } from "react";
import * as Yup from "yup";

import { ProjectPath, Url } from "src/api/paths";
import { Type_selectList_company } from "src/api/tms-commons/companies/types";
import {
    Form,
    Header,
    LoadingBox,
    Spinner,
    TMC_Button,
    TMC_DatePicker,
    TMC_TextField,
    TMC_TextFieldWithBlock_Deprecated,
    TMC_TextFieldWithUnit_Deprecated,
    UploadImage_Deprecated,
} from "src/components";
import {
    Styled_FormDrawer,
    Styled_MainStack,
    Styled_StackActionDrawer,
    Styled_StackActionsDrawer,
    Styled_StackContentDrawer,
} from "src/components/Components_Common/Drawer/Drawer.style";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { SectionTitleWithOptional } from "src/components/Components_Common/SectionTitle";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { COLORS } from "src/theme/stylesheet";
import {
    getCurrentFormat,
    getDate,
    getDuration,
    isValidDate,
} from "src/utils/date";
import { getUrlName } from "src/utils/urlFormatter";

import { isNameAvailable } from "./Projects_DrawerContent.container";

export type Type_Projects_DrawerContentComponentProps = {
    isFetching: boolean;
    onClose: () => void;
    onSubmit: (values: any, props: any) => void;
    data: any;
    isLoading: boolean;
    isUpdateProject: boolean;
    handleProjectName: (
        e: any,
        setFieldValue: (
            field: string,
            value: any,
            shouldValidate?: boolean | undefined,
        ) => void,
    ) => void;
    handleSlug: (
        value: string,
        setFieldValue: (
            field: string,
            value: any,
            shouldValidate?: boolean | undefined,
        ) => void,
    ) => void;
    projectTypes: any[];
    companies: Type_selectList_company[];
    step: number;
    hasMetadataStep: boolean;
    submitStep: number;
    goToPreviousStep: (values: FormikValues) => void;
    licenseTypes: any[];
    metadataProjects: any;
};

export const ProjectSchema = Yup.object().shape({
    name: Yup.string()
        .max(100, FORM_ERR_FMT.MAX_LENGTH)
        .required(FORM_ERR_FMT.REQUIRED),
    city: Yup.string()
        .max(100, FORM_ERR_FMT.MAX_LENGTH)
        .required(FORM_ERR_FMT.REQUIRED),
    country: Yup.string()
        .max(100, FORM_ERR_FMT.MAX_LENGTH)
        .required(FORM_ERR_FMT.REQUIRED),
    slug: Yup.string()
        .max(100, FORM_ERR_FMT.MAX_LENGTH)
        .required(FORM_ERR_FMT.REQUIRED),
    thumbnail: Yup.array().min(1, FORM_ERR_FMT.REQUIRED),
    projectTypes: Yup.array().min(1, FORM_ERR_FMT.REQUIRED),
    company_id: Yup.object().required(FORM_ERR_FMT.REQUIRED),
    postCode: Yup.string()
        .max(10, FORM_ERR_FMT.MAX_LENGTH)
        .required(FORM_ERR_FMT.REQUIRED),
});

export const Projects_DrawerContentComponent = ({
    isFetching,
    onClose,
    onSubmit,
    data,
    isLoading,
    isUpdateProject,
    handleProjectName,
    handleSlug,
    projectTypes,
    companies,
    step,
    hasMetadataStep,
    submitStep,
    goToPreviousStep,
    licenseTypes,
    metadataProjects,
}: Type_Projects_DrawerContentComponentProps) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Projects.Drawer");
    const { formatMessageWithPartialKey: fmtCta } = useCoreIntl("Form.Cta");

    const icon = <Icon variant="light" icon="square" />;
    const checkedIcon = <Icon variant="solid" icon="square-check" />;

    const ProjectSchema3 = Yup.object().shape({
        licenses: Yup.object().required(FORM_ERR_FMT.REQUIRED),
        startDate: Yup.object()
            .required(FORM_ERR_FMT.REQUIRED)
            .test("startDate", FORM_ERR_FMT.WRONG_DATE, (startDate) => {
                return isValidDate(startDate as Dayjs);
            }),
        cost: Yup.number().min(0, FORM_ERR_FMT.MUST_BE_POSITIF),
        renewal: Yup.number().min(0, FORM_ERR_FMT.MUST_BE_POSITIF),
    });

    return (
        <Styled_MainStack>
            <Header
                onClose={onClose}
                title={fmt("AlertInfo.TitleCreate")}
                currentStep={step + (!hasMetadataStep && step === 2 ? 0 : 1)}
                maxStep={hasMetadataStep ? submitStep + 1 : submitStep}
                dataTestIdTitle={"Projects-Drawer-Create-Title"}
            />
            {isFetching && <LoadingBox />}
            {/* Main form */}
            {step === 0 && !isFetching && (
                <Formik
                    initialValues={data}
                    validationSchema={ProjectSchema}
                    onSubmit={onSubmit}
                    validateOnChange={false}
                >
                    {({
                        errors,
                        handleChange,
                        setFieldValue,
                        values,
                        setErrors,
                    }) => {
                        const { data: dataIsNameAvailable }: any =
                            isNameAvailable(values?.name, setErrors, errors);

                        return (
                            <Styled_FormDrawer data-testid="AddProject-DrawerContent-Step1-globalInformationForm">
                                <Styled_StackContentDrawer gap={4}>
                                    <Typography variant="body1Bold">
                                        {fmt(
                                            `AddProject.Step0.GlobalInformation`,
                                        )}
                                    </Typography>
                                    <UploadImage_Deprecated
                                        host={Url.COMMONS}
                                        service={ProjectPath.PROJECTS}
                                        name="thumbnail"
                                        value={values.thumbnail}
                                        handleChange={setFieldValue}
                                        withDescription
                                        limitResolutions={{
                                            min: 540,
                                            max: 2550,
                                        }}
                                        isRequired={errors.thumbnail}
                                    />
                                    <TMC_TextField
                                        data-testid="AddProject-DrawerContent-name"
                                        name="name"
                                        label={fmt(
                                            `AddProject.Step0.ProjectLabel`,
                                        )}
                                        onChange={(e) =>
                                            handleProjectName(e, setFieldValue)
                                        }
                                        value={values.name}
                                        helperText={errors.name as string}
                                        error={!!errors.name}
                                    />
                                    {dataIsNameAvailable?.data?.data?.newName &&
                                        values?.name?.length && (
                                            <Stack
                                                flexDirection="column"
                                                gap={2}
                                            >
                                                <Typography
                                                    variant="body3"
                                                    color={COLORS.moon600}
                                                >
                                                    {fmt(
                                                        "AddProject.Step0.NameSuggestion",
                                                    )}
                                                </Typography>
                                                <TMC_Button
                                                    type="button"
                                                    variant="outlined"
                                                    sx={{
                                                        width: "fit-content",
                                                    }}
                                                    onClick={() =>
                                                        handleProjectName(
                                                            {
                                                                target: {
                                                                    value: dataIsNameAvailable
                                                                        ?.data
                                                                        ?.data
                                                                        ?.newName,
                                                                },
                                                            },
                                                            setFieldValue,
                                                        )
                                                    }
                                                    endIcon={
                                                        <Icon
                                                            variant="solid"
                                                            icon="check"
                                                            sx={{
                                                                color: COLORS.moon700,
                                                            }}
                                                        />
                                                    }
                                                >
                                                    <Typography
                                                        variant="body1"
                                                        color={COLORS.moon600}
                                                    >
                                                        {
                                                            dataIsNameAvailable
                                                                ?.data?.data
                                                                ?.newName
                                                        }
                                                    </Typography>
                                                </TMC_Button>
                                            </Stack>
                                        )}
                                    <TMC_TextFieldWithBlock_Deprecated
                                        data-testid="AddProject-DrawerContent-slug"
                                        name="slug"
                                        block={getUrlName()}
                                        onChange={(e) =>
                                            handleSlug(
                                                e.target.value,
                                                setFieldValue,
                                            )
                                        }
                                        value={values.slug}
                                        helperText={errors.slug as string}
                                        error={!!errors.slug}
                                    />

                                    <TMC_DatePicker
                                        name="projectStartDate"
                                        label={fmt(
                                            "AddProject.Step0.StartDate",
                                        )}
                                        value={values.projectStartDate}
                                        onChange={(newDate) => {
                                            setFieldValue(
                                                "projectStartDate",
                                                getDate(newDate),
                                            );
                                        }}
                                        format={getCurrentFormat()}
                                    />
                                    <Stack>
                                        <Autocomplete
                                            id="company_id"
                                            multiple={false}
                                            onChange={(e, value) =>
                                                setFieldValue(
                                                    "company_id",
                                                    value,
                                                )
                                            }
                                            value={values.company_id}
                                            options={companies}
                                            getOptionLabel={(option) =>
                                                option?.name
                                            }
                                            isOptionEqualToValue={(
                                                option,
                                                selectedValues,
                                            ) =>
                                                option.id === selectedValues.id
                                            }
                                            renderInput={(params) => (
                                                <TMC_TextField
                                                    data-testid="AddProject-DrawerContent-company_id"
                                                    {...params}
                                                    name="company_id"
                                                    label={fmt(
                                                        `AddProject.Step0.CompanyLabel`,
                                                    )}
                                                    helperText={
                                                        errors.company_id as string
                                                    }
                                                    error={!!errors.company_id}
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack
                                        direction="row"
                                        flexWrap="nowrap"
                                        gap={4}
                                        width="100%"
                                    >
                                        <TMC_TextField
                                            data-testid="AddProject-DrawerContent-postCode"
                                            name="postCode"
                                            label={fmt(
                                                `AddProject.Step0.PostCodeLabel`,
                                            )}
                                            onChange={handleChange}
                                            value={values.postCode}
                                            helperText={
                                                errors.postCode as string
                                            }
                                            error={!!errors.postCode}
                                            fullWidth
                                        />
                                        <TMC_TextField
                                            data-testid="AddProject-DrawerContent-city"
                                            name="city"
                                            label={fmt(
                                                `AddProject.Step0.CityLabel`,
                                            )}
                                            onChange={handleChange}
                                            value={values.city}
                                            helperText={errors.city as string}
                                            error={!!errors.city}
                                            fullWidth
                                        />
                                        <TMC_TextField
                                            data-testid="AddProject-DrawerContent-country"
                                            name="country"
                                            label={fmt(
                                                `AddProject.Step0.CountryLabel`,
                                            )}
                                            onChange={handleChange}
                                            value={values.country}
                                            helperText={
                                                errors.country as string
                                            }
                                            error={!!errors.country}
                                            fullWidth
                                        />
                                    </Stack>
                                    <Stack paddingY={2}>
                                        <Divider />
                                    </Stack>
                                    <Typography variant="body1Bold">
                                        {fmt(
                                            `AddProject.Step0.ProjectInformation`,
                                        )}
                                    </Typography>
                                    <Autocomplete
                                        id="projectTypes"
                                        multiple
                                        options={projectTypes}
                                        onChange={(e, value) =>
                                            setFieldValue("projectTypes", value)
                                        }
                                        value={values.projectTypes || []}
                                        disableCloseOnSelect
                                        isOptionEqualToValue={(
                                            option,
                                            selectedValues,
                                        ) => option.id === selectedValues.id}
                                        getOptionLabel={(option) =>
                                            option?.name
                                        }
                                        renderInput={(params) => (
                                            <TMC_TextField
                                                data-testid="AddProject-DrawerContent-projectTypes"
                                                {...params}
                                                name="roles"
                                                label={fmt(
                                                    `AddProject.Step0.ProjectTypesLabel`,
                                                )}
                                                helperText={
                                                    errors.projectTypes as string
                                                }
                                                error={!!errors.projectTypes}
                                            />
                                        )}
                                        renderOption={(props, option) => (
                                            <li {...props} key={option.id}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={
                                                        !!values?.projectTypes.find(
                                                            (
                                                                projectType: any,
                                                            ) =>
                                                                projectType?.id ===
                                                                option.id,
                                                        )
                                                    }
                                                />
                                                {option?.name}
                                            </li>
                                        )}
                                    />
                                </Styled_StackContentDrawer>
                                <Styled_StackActionDrawer>
                                    <TMC_Button
                                        data-testid="AddProject-DrawerContent-step1-nextStepBtn"
                                        variant="contained"
                                        type="submit"
                                        disabled={isLoading || !!errors.name}
                                        sx={{ minWidth: 200 }}
                                        endIcon={
                                            !isLoading && (
                                                <Icon
                                                    variant="solid"
                                                    icon="arrow-right"
                                                />
                                            )
                                        }
                                    >
                                        {isLoading ? (
                                            <Spinner />
                                        ) : (
                                            fmtCta(`Next`)
                                        )}
                                    </TMC_Button>
                                </Styled_StackActionDrawer>
                            </Styled_FormDrawer>
                        );
                    }}
                </Formik>
            )}
            {/* Metadata form */}
            {step === 1 && !isFetching && (
                <Formik
                    initialValues={data}
                    onSubmit={onSubmit}
                    validateOnChange={false}
                >
                    {({ values, setFieldValue }) => {
                        const customSetFieldValue = (
                            field: string,
                            value: any,
                        ) => {
                            setFieldValue("metadata." + field, value);
                        };

                        return (
                            <Styled_FormDrawer data-testid="AddProject-DrawerContent-Step2-metadateForm">
                                <Styled_StackContentDrawer gap={4}>
                                    <Typography variant="body1Bold">
                                        {fmt(
                                            `AddProject.Step0.GlobalInformation`,
                                        )}
                                    </Typography>
                                    <Form
                                        fields={metadataProjects}
                                        values={values.metadata}
                                        onChange={customSetFieldValue}
                                    />
                                </Styled_StackContentDrawer>
                                <Styled_StackActionsDrawer>
                                    <TMC_Button
                                        data-testid="AddProject-DrawerContent-step2-goBackBtn"
                                        variant="text"
                                        type="button"
                                        sx={{ marginRight: 2 }}
                                        onClick={() => goToPreviousStep(values)}
                                        startIcon={
                                            <Icon
                                                variant="solid"
                                                icon="arrow-left"
                                            />
                                        }
                                    >
                                        {fmtCta(`Back`)}
                                    </TMC_Button>
                                    <TMC_Button
                                        data-testid="AddProject-DrawerContent-step2-nextStepBtn"
                                        variant="contained"
                                        type="submit"
                                        disabled={isLoading}
                                        sx={{ minWidth: 200 }}
                                        endIcon={
                                            !isLoading && (
                                                <Icon
                                                    variant="solid"
                                                    icon="arrow-right"
                                                />
                                            )
                                        }
                                    >
                                        {isLoading ? (
                                            <Spinner />
                                        ) : (
                                            fmtCta(`Next`)
                                        )}
                                    </TMC_Button>
                                </Styled_StackActionsDrawer>
                            </Styled_FormDrawer>
                        );
                    }}
                </Formik>
            )}
            {/* License form */}
            {step === 2 && !isFetching && (
                <Formik
                    initialValues={data}
                    validationSchema={ProjectSchema3}
                    onSubmit={onSubmit}
                    validateOnChange={false}
                >
                    {({ errors, handleChange, setFieldValue, values }) => {
                        useMemo(() => {
                            if (values.startDate && values.licenses) {
                                const date = getDate(values.startDate);
                                // eslint-disable-next-line import/no-named-as-default-member
                                const durationStr = getDuration(
                                    values.licenses.duration,
                                );
                                const newDate = date
                                    .add(durationStr)
                                    .subtract(1, "days");
                                setFieldValue("endDate", getDate(newDate));
                            }
                            return null;
                        }, [values.startDate, values.licenses]);

                        return (
                            <Styled_FormDrawer data-testid="AddProject-DrawerContent-Step3-licensesForm">
                                <Styled_StackContentDrawer gap={4}>
                                    <Typography
                                        variant="body1Bold"
                                        data-testid="AddProject-DrawerContent-AssignLicense-label"
                                    >
                                        {fmt(
                                            `AddProject.Step2.AssignLicenseType`,
                                        )}
                                    </Typography>
                                    <Autocomplete
                                        data-testid="AddProject-DrawerContent-licenses"
                                        id="licenses"
                                        multiple={false}
                                        onChange={(e, value) =>
                                            setFieldValue("licenses", value)
                                        }
                                        value={values.licenses}
                                        options={licenseTypes}
                                        getOptionLabel={(option) =>
                                            option?.name
                                        }
                                        isOptionEqualToValue={(
                                            option,
                                            selectedValues,
                                        ) => option.id === selectedValues.id}
                                        renderInput={(params) => (
                                            <TMC_TextField
                                                {...params}
                                                name="licenses"
                                                label={fmt(
                                                    `AddProject.Step2.LicenseTypesLabel`,
                                                )}
                                                helperText={
                                                    errors.licenses as string
                                                }
                                                error={!!errors.licenses}
                                            />
                                        )}
                                    />
                                    <Stack paddingY={2}>
                                        <Divider />
                                    </Stack>
                                    <Typography
                                        variant="body1Bold"
                                        data-testid="AddProject-DrawerContent-LicensesDates-label"
                                    >
                                        {fmt(`AddProject.Step2.LicenseDates`)}
                                    </Typography>
                                    <Typography variant="body2">
                                        {fmt(
                                            `AddProject.Step2.DescriptionLicenseDates`,
                                        )}
                                    </Typography>
                                    <Stack
                                        direction="row"
                                        flexWrap="nowrap"
                                        gap={4}
                                        width="100%"
                                    >
                                        <TMC_DatePicker
                                            name="startDate"
                                            label={fmt(
                                                `AddProject.Step2.StartDateLabel`,
                                            )}
                                            value={values.startDate}
                                            helperText={
                                                errors.startDate as string
                                            }
                                            error={!!errors.startDate}
                                            format={getCurrentFormat()}
                                        />
                                        <TMC_DatePicker
                                            data-testid="AddProject-DrawerContent-endDateLicense"
                                            name="endDate"
                                            label={fmt(
                                                `AddProject.Step2.EndDateLabel`,
                                            )}
                                            value={values.endDate}
                                            format={getCurrentFormat()}
                                            disabled
                                        />
                                    </Stack>
                                    <Stack paddingY={2}>
                                        <Divider />
                                    </Stack>
                                    <SectionTitleWithOptional
                                        data-testid="AddProject-DrawerContent-LicenseDetails-label"
                                        title={fmt(
                                            `AddProject.Step2.LicenseDetails`,
                                        )}
                                        optionalText={fmt(
                                            `AddProject.Step2.Optional`,
                                        )}
                                    ></SectionTitleWithOptional>
                                    <Stack
                                        direction="row"
                                        flexWrap="nowrap"
                                        gap={4}
                                        width="100%"
                                    >
                                        <TMC_TextFieldWithUnit_Deprecated
                                            data-testid="AddProject-DrawerContent-cost"
                                            name="cost"
                                            type="number"
                                            label={fmt(
                                                `AddProject.Step2.AnnualCost`,
                                            )}
                                            format={"rounded"}
                                            unit={"euro"}
                                            onChange={handleChange}
                                            value={values.cost}
                                            helperText={errors.cost as string}
                                            error={!!errors.cost}
                                        />
                                        <TMC_TextField
                                            data-testid="AddProject-DrawerContent-renewal"
                                            name="renewal"
                                            onChange={handleChange}
                                            value={values.renewal}
                                            type="number"
                                            label={fmt(
                                                `AddProject.Step2.RenewalNbr`,
                                            )}
                                            helperText={
                                                errors.renewal as string
                                            }
                                            error={!!errors.renewal}
                                        />
                                    </Stack>
                                </Styled_StackContentDrawer>
                                <Styled_StackActionsDrawer>
                                    <TMC_Button
                                        data-testid="AddProject-DrawerContent-step3-goBackBtn"
                                        variant="text"
                                        type="button"
                                        sx={{ marginRight: 2 }}
                                        onClick={() => goToPreviousStep(values)}
                                        startIcon={
                                            <Icon
                                                variant="solid"
                                                icon="arrow-left"
                                            />
                                        }
                                    >
                                        {fmtCta(`Back`)}
                                    </TMC_Button>
                                    <TMC_Button
                                        data-testid="AddProject-DrawerContent-step3-submit"
                                        variant="contained"
                                        type="submit"
                                        disabled={isLoading}
                                        sx={{ minWidth: 200 }}
                                    >
                                        {isLoading ? (
                                            <Icon
                                                variant="solid"
                                                icon="spinner-third"
                                                spin
                                            />
                                        ) : isUpdateProject ? (
                                            fmtCta(`Update`)
                                        ) : (
                                            fmtCta(`Save`)
                                        )}
                                    </TMC_Button>
                                </Styled_StackActionsDrawer>
                            </Styled_FormDrawer>
                        );
                    }}
                </Formik>
            )}
        </Styled_MainStack>
    );
};
