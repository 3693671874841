import { useMutation, useQuery } from "react-query";

import { formatterIndexMetadataUsersForForm } from "src/api/tms-users/metadata/formatters";
import { Type_usr_index_metadataUser } from "src/api/tms-users/metadata/types";
import { formatted_usersInvitations } from "src/api/tms-users/userInvitations/formatters";
import {
    checkToken,
    createCompanyWithToken,
    createUserInvitations,
    createUserWithToken,
    getMetadataUsersForm,
    getUsersInvitations,
} from "src/api/tms-users/userInvitations/services";
import {
    Type_Api_userInvitation,
    Type_userInvitation,
} from "src/api/tms-users/userInvitations/types";
import { Type_formData_NewUser_Invitation } from "src/pages/Pages_Common/Page_NewUser_Invitation/Page_NewUser_Invitation.container";

export const mutationCreateUserInvitation = (
    onSubmit: any,
    callback: any,
    usersInvitations: Type_userInvitation[],
) => {
    return useMutation({
        mutationFn: (newUserInvitations: Type_userInvitation[]) =>
            createUserInvitations(newUserInvitations),
        onSuccess: async (data: any) => {
            let submitData: Type_userInvitation[] = usersInvitations;

            data.data.data.map((datum: any) => {
                submitData = [
                    ...submitData,
                    { email: datum.email, role_id: datum.role.id },
                ];
            });
            await callback(submitData);
        },
        onError: (err: any) => {
            return err;
        },
    });
};

export const useGetUsersInvitations = (callback: any) => {
    return useQuery({
        queryKey: ["getUsersInvitations"],
        queryFn: getUsersInvitations,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            data?.data?.data
                ? callback(
                      formatted_usersInvitations(
                          data?.data?.data as Type_Api_userInvitation[],
                      ),
                  )
                : null;
        },
    });
};

export const useCheckToken = () => {
    return useMutation({
        mutationFn: (token: string) => checkToken(token),
    });
};

export const useGetMetadataUsersForm = (callback: any) => {
    return useQuery({
        queryKey: ["getMetadataUsersForm"],
        queryFn: getMetadataUsersForm,
        refetchOnWindowFocus: false,
        onSuccess: (data): void => {
            data?.data?.data
                ? callback(
                      formatterIndexMetadataUsersForForm(
                          data?.data?.data as Type_usr_index_metadataUser[],
                      ),
                  )
                : null;
        },
    });
};

export const mutationCreateUserWithToken = () => {
    return useMutation({
        mutationFn: (formData: Type_formData_NewUser_Invitation) => {
            return createUserWithToken(
                {
                    email: formData.email,
                    password: formData.password,
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    phone: formData.phone,
                    role_id: formData.role_id,
                    company_id: formData.company_id,
                    metadata: formData.metadata,
                },
                formData.token as string,
            );
        },
    });
};

export const mutationCreateCompanyWithToken = (
    formData: any,
    callback: any,
) => {
    return useMutation({
        mutationFn: (formData: Type_formData_NewUser_Invitation) => {
            return createCompanyWithToken(
                {
                    name: formData.company_name,
                    nationalId: formData.company_nationalId,
                    code: formData.company_code,
                    postalCode: formData.company_postalCode,
                    city: formData.company_city,
                    country: formData.company_country,
                },
                formData.token as string,
            );
        },
        onSuccess: async (data) => {
            if (data?.data?.data.id) {
                const submitData = { ...formData, id: data?.data?.data?.id };
                await callback(submitData);
            }
        },
    });
};
