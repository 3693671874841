import React, { useState } from "react";

import { ToolbarWithDrawer } from "src/components/Components_Common/ToolbarWithDrawer/ToolbarWithDrawer";
import { ToolbarDrawer } from "src/components/Components_Common/ToolbarWithDrawer/ToolbarWithDrawer.style";
import { AreasSelection } from "src/components/Components_Teamoty/contents/sequenceAreas/AreasSelection";

type Type_Props_SequencePertLeftBar = {
    sequenceId: number;
};
export const SequencePertLeftBar = ({
    sequenceId,
}: Type_Props_SequencePertLeftBar) => {
    const [toolbarState, setToolbarState] = useState<boolean>(false);

    const handleShow = (): void => {
        setToolbarState((prevState) => !prevState);
    };

    return (
        <ToolbarWithDrawer handleShow={handleShow}>
            {toolbarState && (
                <ToolbarDrawer>
                    <AreasSelection sequenceId={sequenceId} />
                </ToolbarDrawer>
            )}
        </ToolbarWithDrawer>
    );
};
