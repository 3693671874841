import { FormInputs } from "src/components/Components_Common/Form/Form.component";
import { FormFieldsType } from "src/components/Components_Common/Form/Form.container";

import { InputMultiplePropsType } from "./InputMultiple.container";

export type InputMultipleType = {
    type: "inputMultiple";
    label?: {
        label?: string;
        languageLabel?: string;
    };
    fields?: FormFieldsType[];
};

export const InputMultipleComponent = ({ fields }: InputMultiplePropsType) => {
    return (
        <div>
            <FormInputs fields={fields} />
        </div>
    );
};
