import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Type_index_metadataUser } from "src/api/tms-users/metadata/types";
import {
    useCheckToken,
    useGetMetadataUsersForm,
} from "src/api/tms-users/userInvitations";
import { Spinner } from "src/components";

import { Page_NewUser_InvitationComponent } from "./Page_NewUser_Invitation.component";

export type Type_formData_NewUser_Invitation = {
    email: string;
    role_id: number | null;
    password: string;
    passwordConfirm: string;
    firstName: string;
    lastName: string;
    phone: string;
    metadata: any;
    company_id: number | null;
    company_name: string;
    company_nationalId: string | null;
    company_code: string;
    company_postalCode: string;
    company_city: string;
    company_country: string;
    checkbox: boolean;
    token: string | undefined;
};

export const Page_NewUser_InvitationContainer = () => {
    const { token } = useParams();
    const {
        mutate: mutateCheckToken,
        isLoading,
        isSuccess,
        data,
    } = useCheckToken() || {};
    const [formData, setFormData] = useState<Type_formData_NewUser_Invitation>({
        email: "",
        role_id: null,
        password: "",
        passwordConfirm: "",
        firstName: "",
        lastName: "",
        phone: "",
        metadata: {},
        company_id: null,
        company_name: "",
        company_nationalId: null,
        company_code: "",
        company_postalCode: "",
        company_city: "",
        company_country: "",
        checkbox: false,
        token: undefined,
    });

    const [metadataUsersForm, setMetadataUsersForm] =
        useState<Type_index_metadataUser[]>();
    const { isSuccess: isSuccessMetadataForm } =
        useGetMetadataUsersForm(setMetadataUsersForm) || {};

    useEffect(() => {
        mutateCheckToken(token as string, {
            onSuccess: (data) => {
                setFormData({
                    ...formData,
                    email: data.data.data.email,
                    role_id: data.data.data.role_id,
                    token: token,
                });
            },
            onError: (data): void => {
                console.error("Error", data);
            },
        });
    }, []);

    const [step, setStep] = useState<number>(1);

    const handleStep = (stepAction: "previous" | "next"): void => {
        let nextStepInc = 1;
        let previousStepInc = 1;

        // Check if we show metadata or not
        if (isSuccessMetadataForm && !metadataUsersForm?.length) {
            if (step === 2) nextStepInc = 2;
            if (step === 4) previousStepInc = 2;
        }

        setStep((prev) => {
            if (stepAction === "previous") {
                return prev - previousStepInc;
            }
            return prev + nextStepInc;
        });
    };

    const maxStep: number =
        isSuccessMetadataForm && !metadataUsersForm?.length ? 4 : 5;

    if (isLoading) {
        return <Spinner />;
    }

    if (!isLoading && isSuccess && !data?.data?.data.isValid) {
        return <Typography>TODO Error message token expired</Typography>;
    }

    return (
        <Page_NewUser_InvitationComponent
            formData={formData}
            setFormData={setFormData}
            step={step}
            maxStep={maxStep}
            handleStep={handleStep}
            metadataUsersForm={metadataUsersForm}
            setStep={setStep}
        />
    );
};
