import { Grid } from "@mui/material";

import { Switch } from "src/components/Components_Common/forms/reactHookFormComponents/Switch/Switch";
import { TextField } from "src/components/Components_Common/forms/reactHookFormComponents/TextField/TextField";
import { Type_ReadonlyComponent } from "src/components/Components_Common/types";
import { SelectUnits } from "src/components/Components_Teamoty/forms/select/SelectUnits";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_RatioTask = Type_ReadonlyComponent;

export const RatioTask = ({ readonly }: Type_Props_RatioTask) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerTasks.RatioTask",
    );

    return (
        <Grid container py={3} spacing={4}>
            <Grid item xs={6}>
                <TextField
                    name="ratio"
                    label={fmt("Ratio")}
                    type="number"
                    inputProps={{ step: "0.1", min: "0" }}
                    data-testid="Form-RatioTask-ratio"
                    readonly={readonly}
                />
            </Grid>
            <Grid item xs={6}>
                <SelectUnits
                    name="ratioUnit.id"
                    label={fmt("RatioUnit")}
                    data-testid="Form-RatioTask-ratioUnit"
                    readonly={readonly}
                />
            </Grid>
            <Grid item xs={12}>
                <Switch
                    name={"ratioWithTeam"}
                    label={fmt("RatioWithTeam")}
                    labelPlacement={"end"}
                    readonly={readonly}
                />
            </Grid>
        </Grid>
    );
};
