import { yupResolver } from "@hookform/resolvers/yup";
import TabContext from "@mui/lab/TabContext";
import * as React from "react";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import * as Yup from "yup";

import {
    mutationCreateRole,
    mutationUpdateRole,
    useShowRole,
} from "src/api/tms-users/rolesAndPermissions";
import { indexRoleTypes } from "src/api/tms-users/rolesAndPermissions/services";
import {
    Type_index_roleType,
    Type_show_role,
    Type_usr_post_role,
} from "src/api/tms-users/rolesAndPermissions/types";
import { LoadingBox, PermissionsSelection } from "src/components";
import { Type_drawerTab } from "src/components/Components_Common/Drawer/Header/Header.container";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import {
    DrawerTab,
    DrawerTabList,
    Styled_TabPanelDrawerContent,
} from "src/components/Components_Common/DrawerGeneric/DrawerGeneric.style";
import { InputMultiLanguages } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages";
import { inputMultiLanguagesSchema } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages.component";
import {
    ToggleButtonGroup,
    Type_ToggleButtonGroupItem,
} from "src/components/Components_Common/forms/reactHookFormComponents/ToggleButtonGroup/ToggleButtonGroup";
import { SectionTitle } from "src/components/Components_Common/SectionTitle";
import { useFormDefaultConfig } from "src/configurations/app";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { roleTypesIconsHookForm } from "src/configurations/keyIconRelations";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_RoleForm = {
    onClose: () => void;
    roleIdToUpdate?: number | undefined;
    action: Type_action;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
};

const Schema_Roles = Yup.lazy(() => {
    return Yup.object().shape({
        names: Yup.object().shape(inputMultiLanguagesSchema.fields), // Inclut les validations pour les champs multilingues dans l'objet "names"
        role_type_id: Yup.string().required(FORM_ERR_FMT.REQUIRED),
        permissions: Yup.array().min(1, FORM_ERR_FMT.REQUIRED),
    });
});

export const RolesForm = ({
    onClose,
    roleIdToUpdate,
    action,
    setIsLoading,
}: Type_Props_RoleForm) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Roles");
    const { mutateAsync: mutateCreate } = mutationCreateRole() || {};
    const { mutateAsync: mutateUpdate } = mutationUpdateRole();

    const [roleTypes, setRoleTypes] = useState<Type_ToggleButtonGroupItem[]>(
        [],
    );

    const { isFetching: isFetchingRole, data } = useShowRole(
        roleIdToUpdate as number,
    );

    const { isFetching: roleTypesIndexLoading } = useQuery(
        "indexRoleTypes",
        indexRoleTypes,
        {
            onSuccess: (data) => {
                const roleTypes = data?.data?.data.filter(
                    (roleType: Type_index_roleType): boolean => {
                        return roleType.id > 2;
                    },
                );

                const ToggleButtonList: Type_ToggleButtonGroupItem[] =
                    roleTypes.map((roleType: Type_index_roleType) => ({
                        label: fmt(`InputRoleTypeData.${roleType.name}`),
                        value: roleType.id,
                        icon: roleTypesIconsHookForm[roleType.name],
                    }));

                setRoleTypes(data?.data?.data ? ToggleButtonList : []);
            },
            refetchOnWindowFocus: false,
        },
    );

    const form = useForm<Type_usr_post_role | Type_show_role>({
        ...useFormDefaultConfig,
        defaultValues: {
            names: {},
            permissions: [],
        },
        values: data,
        resolver: yupResolver<any>(Schema_Roles),
    });

    const handleSubmit = async (values: any, e?: React.BaseSyntheticEvent) => {
        e?.preventDefault;

        setIsLoading(true);

        try {
            if (action === "update") {
                await mutateUpdate(values as Type_show_role);
            } else {
                delete values.id;
                await mutateCreate(values as Type_usr_post_role);
            }
            onClose();
        } catch (e: any) {
            console.error("HANDLE SUBMIT ERROR", e);
        }

        setIsLoading(false);
    };

    const drawerTabs = [
        { label: fmt("Tabs.General"), value: "0" },
        { label: fmt("Tabs.Permission"), value: "1" },
    ];

    const [tab, setTab] = useState<string>("0");

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setTab(newValue);
    };

    useEffect(() => {
        if (Object.keys(form.formState.errors).length) {
            // check if there is an error on tab and switch to first tab
            if (
                !form.formState.errors.names &&
                !form.formState.errors.role_type_id &&
                tab === "0"
            ) {
                setTab("1");
            }
            // check if there is an error on permission and switch to second tab
            if (!form.formState.errors.permissions && tab === "1") {
                setTab("0");
            }
        }
    }, [form.formState.errors]);

    const isFetching = useMemo(
        () =>
            !!(roleIdToUpdate && form.getValues("id") === undefined) ||
            roleTypesIndexLoading ||
            isFetchingRole,
        [
            roleIdToUpdate,
            form.getValues("id"),
            roleTypesIndexLoading,
            isFetchingRole,
        ],
    );

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)} id={"roles"}>
                <TabContext value={tab}>
                    <DrawerTabList
                        onChange={handleChange}
                        data-testid="Role-TabList"
                    >
                        {drawerTabs.map(
                            (drawerTab: Type_drawerTab, key: number) => {
                                return (
                                    <DrawerTab
                                        data-testid={`Tab-${drawerTab.value}`}
                                        label={drawerTab.label}
                                        value={drawerTab.value}
                                        key={key}
                                    />
                                );
                            },
                        )}
                    </DrawerTabList>
                    {isFetching ? (
                        <LoadingBox />
                    ) : (
                        <>
                            <Styled_TabPanelDrawerContent value={"0"}>
                                <SectionTitle
                                    title={fmt(`InputRoleTypeLabel`)}
                                />
                                <ToggleButtonGroup
                                    name="role_type_id"
                                    items={roleTypes}
                                    exclusive
                                ></ToggleButtonGroup>
                                <InputMultiLanguages
                                    name="names"
                                    label={fmt(`RoleName`)}
                                />
                            </Styled_TabPanelDrawerContent>
                            <Styled_TabPanelDrawerContent value={"1"}>
                                <PermissionsSelection
                                    handleChange={form.setValue}
                                    values={form.getValues("permissions")}
                                />
                            </Styled_TabPanelDrawerContent>
                        </>
                    )}
                </TabContext>
            </form>
        </FormProvider>
    );
};
