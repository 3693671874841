import * as React from "react";

import { useShowTask } from "src/api/tms-scheduling/tasks";
import { LoadingBox } from "src/components";
import { TaskForm } from "src/forms/tasks/TaskForm";
import { Styled_PaperContextualLayout } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

//////////////////////////////////////////////

type Type_Props_TaskContextualDrawer = {
    onClose: () => void;
    id: number;
    readonly?: boolean;
};

export const TaskContextualDrawer = ({
    onClose,
    id,
    readonly = false,
}: Type_Props_TaskContextualDrawer) => {
    const { isFetching, data } = useShowTask(id);

    return (
        <Styled_PaperContextualLayout
            id="task"
            data-testid="TaskContextualDrawer"
        >
            {isFetching || !data ? (
                <LoadingBox />
            ) : (
                <TaskForm onClose={onClose} data={data} readonly={readonly} />
            )}
        </Styled_PaperContextualLayout>
    );
};
