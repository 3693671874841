import { SelectProps, useTheme } from "@mui/material";
import { useMemo } from "react";

import { ConstraintStatus } from "src/api/tms-scheduling/constraints/types";
import { TMC_Chip } from "src/components/Components_Common/Chip/Chip";
import { ChipSelect } from "src/components/Components_Common/forms/reactHookFormComponents/ChipSelect/ChipSelect";
import { Type_ReadonlyComponent } from "src/components/Components_Common/types";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { DEFAULT_COLORS } from "src/theme/stylesheet";

type Type_Props_ChipSelectStatus = SelectProps & {
    name?: string;
} & Type_ReadonlyComponent;

export const ChipSelectStatus = ({
    variant,
    readonly = false,
    disabled,
    value,
    name = "",
    ...props
}: Type_Props_ChipSelectStatus) => {
    const theme = useTheme();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.StatusConstraints",
    );

    const statusList = [
        {
            label: fmt("ToDo"),
            value: ConstraintStatus.TO_DO,
            chipColor: theme.palette.secondary.main,
        },
        {
            label: fmt("InProgress"),
            value: ConstraintStatus.IN_PROGRESS,
            chipColor: theme.palette.primary.main,
        },
        {
            label: fmt("Resolved"),
            value: ConstraintStatus.RESOLVED,
            chipColor: theme.palette.success.main,
        },
        {
            label: fmt("Canceled"),
            value: ConstraintStatus.CANCELED,
            chipColor: theme.palette.error.main,
        },
    ];

    const getStatusById = (): { color: string; label: string } => {
        const status = statusList.find((status) => status.value === value);
        if (status) {
            return { color: status.chipColor, label: status.label };
        }
        return { color: DEFAULT_COLORS.chipStatus, label: "" };
    };

    const status = useMemo(() => getStatusById(), [value]);

    if (readonly)
        return (
            <TMC_Chip
                height={24}
                backgroundColor={status.color}
                colorText="white"
                label={status.label}
            />
        );
    return (
        <ChipSelect
            items={statusList}
            variant={variant || "standard"}
            disabled={readonly || disabled}
            bgColor={status.color}
            textColor="white"
            name={name}
            {...props}
        />
    );
};
