import { Type_index_view } from "src/api/tms-projects/workspacesAndViews/views/types";
import { ViewDashboard } from "src/components/Components_Teamoty/views/ViewDashboard";
import { ViewEmpty } from "src/components/Components_Teamoty/views/ViewEmpty";
import { ViewGantt } from "src/components/Components_Teamoty/views/ViewGantt/ViewGantt";
import { ViewPlanning } from "src/components/Components_Teamoty/views/ViewPlanning/ViewPlanning";
import { Provider_ContextualDrawer } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

export type Type_Props_Page_Views = {
    view?: Type_index_view;
};

export type Type_Props_ViewData = {
    viewData?: Type_index_view;
};

export const Page_Views = ({ view }: Type_Props_Page_Views) => {
    // list of views component to complete
    const viewsComponents = {
        dashboard: ViewDashboard,
        planning: ViewPlanning,
        gantt: ViewGantt,
        workflow: ViewEmpty,
        areamap: ViewEmpty,
        grid: ViewEmpty,
    };

    const SpecificView = view ? viewsComponents[view.data.type] : ViewEmpty;

    return view ? (
        <Provider_ContextualDrawer>
            <SpecificView viewData={view} />
        </Provider_ContextualDrawer>
    ) : (
        <></>
    );
};
