import { Stack, styled, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";

import {
    mutationCreateTaskLink,
    useIndexTaskLinks,
} from "src/api/tms-scheduling/taskLinks";
import {
    formatterIndexTaskLink,
    formatterLinksByDirection,
} from "src/api/tms-scheduling/taskLinks/formatters";
import {
    LINK_DIRECTION,
    Type_index_taskLink,
    Type_Link_By_Direction,
    Type_post_taskLink,
} from "src/api/tms-scheduling/taskLinks/types";
import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { ItemChildrenProps } from "src/components/Components_Common/accordions/Accordion";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { FullSpinner } from "src/components/Components_Common/Spinner/FullSpinner";
import { Type_event_link, useChannel } from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { useContextualDrawer } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

import { TaskLink } from "./TaskLink";

const Styled_StackHead = styled(Stack)(() => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
}));

type Type_Props_LinksForm = ItemChildrenProps & {
    taskId: number;
    areaId?: number;
};

export const LinksTask = ({
    taskId,
    open,
    readonly,
    areaId,
}: Type_Props_LinksForm) => {
    const theme = useTheme();

    const { formatMessageWithPartialKey: fmtActions } = useCoreIntl("Actions");
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerTasks.Links",
    );

    const { openPaper } = useContextualDrawer();

    const [links, setLinks] = useState<Type_Link_By_Direction>({
        FROM: [],
        TO: [],
    });

    /////////////////////
    ///  Queries      ///
    /////////////////////

    const { isFetching: isFetchingLinks, data } = useIndexTaskLinks(
        {
            task_id: taskId,
        },
        !!taskId && open,
    );

    useEffect(() => {
        if (data) {
            const transformedData = formatterLinksByDirection(
                data,
                taskId,
                areaId,
            );
            setLinks(transformedData);
        }
    }, [data]);

    const { mutateAsync } = mutationCreateTaskLink();

    ///////////////////////
    ///   Channels      ///
    ///////////////////////

    const { sendEvent } = useChannel({
        eventHandler: ({ data, event }) => {
            const linkData = data as Type_event_link;
            if (event === "updateLink") {
                setLinks((prev) => {
                    const updatedFrom = prev.FROM.map((link) => {
                        if (linkData && link.id === linkData?.id) {
                            return {
                                ...linkData,
                                task: linkData.taskFrom,
                            };
                        }
                        return link;
                    });

                    const updatedTo = prev.TO.map((link) => {
                        if (link.id === linkData?.id) {
                            return {
                                ...linkData,
                                task: linkData.taskTo,
                            };
                        }
                        return link;
                    });

                    return {
                        ...prev,
                        FROM: updatedFrom,
                        TO: updatedTo,
                    };
                });
            }
        },
    });

    const getLinkActions = (linkType: LINK_DIRECTION) => {
        const handleClick = async (
            e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        ) => {
            e.preventDefault();
            e.stopPropagation();

            const taskLink: Type_post_taskLink = {
                [linkType === LINK_DIRECTION.TO ? "taskFrom" : "taskTo"]: {
                    id: taskId,
                },
                [areaId && linkType === LINK_DIRECTION.TO
                    ? "areaFrom"
                    : "areaTo"]: {
                    id: areaId,
                },
            };

            await mutateAsync(taskLink).then((data) => {
                const newTaskLink = formatterIndexTaskLink(data?.data?.data);
                links[linkType].push(newTaskLink as Type_index_taskLink);
                sendEvent("addLink", newTaskLink);
                if (areaId) {
                    openPaper("taskAreaLink", {
                        id: newTaskLink.id,
                        type: linkType,
                    });
                } else {
                    openPaper("link", {
                        id: newTaskLink.id,
                        type: linkType,
                    });
                }
            });
        };

        return (
            <IconButton
                sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: theme.spacing(1),
                }}
                data-action={linkType}
                data-testid={`Add-new-link-${linkType}-btn`}
                onClick={handleClick}
                color="primary"
            >
                <Icon
                    color="inherit"
                    fontSize="small"
                    icon="add"
                    variant="light"
                />
                <Typography variant="body3Medium" color={"inherit"}>
                    {fmtActions("New")}
                </Typography>
            </IconButton>
        );
    };

    return isFetchingLinks ? (
        <FullSpinner />
    ) : (
        <Stack padding={2} gap={2}>
            <Stack padding={1} gap={1}>
                <Styled_StackHead>
                    <Typography
                        variant="body3Medium"
                        color={theme.palette.text.secondary}
                    >
                        {fmt("Predecessor")}
                    </Typography>
                    {!readonly && getLinkActions(LINK_DIRECTION.FROM)}
                </Styled_StackHead>

                {links?.FROM.map((link) => (
                    <TaskLink
                        key={link.id}
                        type={LINK_DIRECTION.FROM}
                        link={link}
                        readonly={readonly}
                        areaId={areaId}
                    />
                ))}
            </Stack>
            <Stack padding={1} gap={1}>
                <Styled_StackHead>
                    <Typography
                        variant="body3Medium"
                        color={theme.palette.text.secondary}
                    >
                        {fmt("Successor")}
                    </Typography>
                    {!readonly && getLinkActions(LINK_DIRECTION.TO)}
                </Styled_StackHead>

                {links?.TO.map((link) => (
                    <TaskLink
                        key={link.id}
                        type={LINK_DIRECTION.TO}
                        link={link}
                        readonly={readonly}
                        areaId={areaId}
                    />
                ))}
            </Stack>
        </Stack>
    );
};
