import React from "react";

import { Icon } from "src/components/Components_Common/Icon/Icon";

import { InputPasswordConfirmPropsType } from "./InputPasswordConfirm.container";

export type InputPasswordConfirmType = {
    type: "inputPasswordConfirm";
    name: string;
    required?: boolean;
    label?: {
        label?: string;
        languageLabel?: string;
    };
    placeholder?: {
        label?: string;
        languageLabel?: string;
    };
};

export const InputPasswordConfirmComponent = ({
    type,
    changeType,
    props,
    name,
    label,
    placeholder,
    onChange,
}: InputPasswordConfirmPropsType) => {
    return (
        <div className="flex items-center justify-end space-x-4">
            <label className="whitespace-nowrap block text-sm font-medium text-gray-700">
                {label}
            </label>
            <div className="mt-2 flex rounded-md shadow-sm w-96">
                <div className="relative flex flex-grow items-stretch focus-within:z-10">
                    <input
                        {...props}
                        name={name}
                        placeholder={placeholder}
                        onChange={onChange}
                        type={type}
                        className="block w-full rounded-l-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>
                <button
                    type="button"
                    onClick={changeType}
                    className="relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 text-gray-400 hover:text-gray-600"
                >
                    <Icon
                        variant="solid"
                        icon={type === "password" ? "eye" : "eye-slash"}
                    />
                </button>
            </div>
        </div>
    );
};
