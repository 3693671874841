import {
    Checkbox,
    FormControlLabel,
    FormGroup,
    FormLabel,
} from "@mui/material";

import { InputCheckboxPropsType } from "./InputCheckbox.containter";

export const InputCheckboxComponent = ({
    label,
    data,
    onChange,
    value,
}: Omit<InputCheckboxPropsType, "name">) => {
    return (
        <FormGroup onChange={onChange}>
            <FormLabel>{label}</FormLabel>
            {data?.map((item: any) => (
                <FormControlLabel
                    data-testid={`checkbox-${label}-${item.replaceAll(
                        " ",
                        "_",
                    )}`}
                    key={item}
                    control={<Checkbox checked={value.includes(item)} />}
                    label={item}
                    value={item}
                />
            ))}
        </FormGroup>
    );
};
