import * as React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { Type_Filter_Areas } from "src/api/filters";
import {
    formatterCreateArea,
    formatterFlattenSelectAreas,
    formatterIndexArea,
    formatterIndexAreas,
    formatterSelectListAreas,
    formatterShowArea,
    formatterUpdateArea,
} from "src/api/tms-projects/areas/formatters";
import {
    createArea,
    duplicateArea,
    exportAreas,
    importAreas,
    indexAreas,
    removeArea,
    selectListAreas,
    showArea,
    updateArea,
} from "src/api/tms-projects/areas/services";
import {
    Type_index_area,
    Type_post_area,
    Type_prj_put_area,
    Type_put_area,
    Type_select_area,
    Type_show_area,
} from "src/api/tms-projects/areas/types";
import { AreaKeys } from "src/api/tms-projects/keys";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { useChannel } from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";

//INDEX
export const useIndexAreas = (
    filters: Type_Filter_Areas = {},
    enabled: boolean = true,
) => {
    const { requestConfig } = useProject();
    return useQuery({
        queryKey: [AreaKeys.INDEX, requestConfig, filters],
        queryFn: () => indexAreas(requestConfig, filters),
        enabled:
            !!requestConfig.projectId &&
            !!requestConfig.subProjectId &&
            enabled,
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useIndexAreas");
            }
            return formatterIndexAreas(data.data.data);
        },
    });
};

//SHOW
// TODO: Enlever le callback (attente du fix de l autocompleteAreasCustom)
export const useShowArea = (
    id: number,
    callback?: (area: Type_show_area) => void,
) => {
    const { requestConfig } = useProject();
    return useQuery({
        queryKey: [AreaKeys.SHOW, id, requestConfig],
        queryFn: () => showArea(id, requestConfig),
        refetchOnWindowFocus: false,
        select: (data) => {
            callback && callback(formatterShowArea(data?.data?.data));
            return formatterShowArea(data?.data?.data);
        },
        enabled: !!requestConfig.projectId && !!id,
    });
};

//SELECT LIST

export const useSelectListAreas = (enabled = true) => {
    const { requestConfig } = useProject();
    return useQuery({
        queryKey: [AreaKeys.SELECT_LIST, requestConfig],
        queryFn: () => selectListAreas(requestConfig),
        refetchOnWindowFocus: false,
        select: (data): Type_select_area[] => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useSelectListAreas");
            }
            return formatterSelectListAreas(data.data.data);
        },
        enabled: !!requestConfig.projectId && enabled,
    });
};

/**
 * Use by Tree components:
 * components_Teamoty/forms/select/SelectArea
 *
 */
export const useSelectAreas = (enabled = true) => {
    const { requestConfig } = useProject();
    return useQuery({
        queryKey: ["selectAreas", requestConfig],
        queryFn: () => selectListAreas(requestConfig),
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useSelectAreas");
            }
            return formatterFlattenSelectAreas(data.data.data);
        },
        enabled: !!requestConfig.projectId && enabled,
        staleTime: 60000,
    });
};

//CREATE
export const mutationCreateArea = (withInvalidation: boolean = false) => {
    const { requestConfig } = useProject();
    const { sendEvent } = useChannel({});
    const { addSuccess, addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.SubprojectSettings.Areas",
    );
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (area: Type_post_area) =>
            createArea(formatterCreateArea(area), requestConfig),
        onSuccess: async (data: any) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationCreateArea");
            }
            const area = formatterIndexArea(data.data.data);

            addSuccess({
                description: fmt("Toast.ToastSuccessCreate", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        area: area.name,
                    },
                }),
            });

            if (withInvalidation) {
                await queryClient.invalidateQueries({
                    queryKey: [AreaKeys.INDEX, requestConfig],
                });
            } else {
                sendEvent("addArea", {
                    ...area,
                });
                return data;
            }
        },
        onError: (err: any) => {
            console.debug("ERROR mutationCreateArea", err);
            addWarning({
                description: fmtErrors("GenericError"),
            });
            return err;
        },
    });
};

//DUPLICATE
export const mutationDuplicateArea = (
    callback?: (area: Type_index_area) => void,
    showToast: boolean = false,
) => {
    const { requestConfig } = useProject();
    const { addSuccess, addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.SubprojectSettings.Areas",
    );
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (id: number) => duplicateArea(id, requestConfig),
        onSuccess: async (data: any) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationDuplicateArea");
            }

            const area = formatterIndexArea(data.data.data);

            if (showToast) {
                addSuccess({
                    description: fmt("Toast.ToastSuccessDuplicate", {
                        values: {
                            b: (chunks: string) => <b>{chunks}</b>,
                            area: area.name,
                        },
                    }),
                });
            }

            if (callback) {
                callback(area);
            } else {
                await queryClient.invalidateQueries({
                    queryKey: [AreaKeys.INDEX, requestConfig],
                });
            }
        },
        onError: (err: any) => {
            console.debug("ERROR mutationDuplicateArea", err);
            addWarning({
                description: fmtErrors("GenericError"),
            });
            return err;
        },
    });
};

//UPDATE
export const mutationUpdateArea = (
    showToast: boolean = true,
    callback?: (area: any) => void,
) => {
    const { requestConfig } = useProject();
    const { addSuccess, addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.SubprojectSettings.Areas",
    );
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (area: Type_put_area) =>
            updateArea(formatterUpdateArea(area), requestConfig),
        onSuccess: async (data: any) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationUpdateArea");
            }

            const area = formatterIndexArea(data.data.data);

            if (showToast) {
                addSuccess({
                    description: fmt("Toast.ToastSuccessUpdate", {
                        values: {
                            b: (chunks: string) => <b>{chunks}</b>,
                            area: area.name,
                        },
                    }),
                });
            }

            if (callback) {
                callback(area);
            } else {
                await queryClient.invalidateQueries({
                    queryKey: [AreaKeys.INDEX, requestConfig],
                });
            }
        },
        onError: (err: any) => {
            console.debug("ERROR mutationUpdateArea", err);
            addWarning({
                description: fmtErrors("GenericError"),
            });
            return err;
        },
    });
};

//SELECTION
export const useIndexAreasSelection = (
    callback: (area: any[]) => void,
    filters: Type_Filter_Areas = {},
    enabled: boolean = true,
) => {
    const { requestConfig } = useProject();
    return useQuery({
        queryKey: [AreaKeys.INDEX_SELECTION, requestConfig, filters],
        queryFn: () => indexAreas(requestConfig, filters),
        refetchOnWindowFocus: false,
        onSuccess: (data): void => {
            callback(formatterIndexAreas(data?.data?.data));
        },
        enabled: Boolean(requestConfig.projectId) && enabled,
    });
};

//DELETE
export const mutationDeleteArea = (callback?: (id: number) => void) => {
    const { addSuccess, addWarning } = useToast();
    const { sendEvent } = useChannel({});
    const { formatMessageWithPartialKey: fmtError } = useCoreIntl("Errors");
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.SubprojectSettings.Areas",
    );
    const { requestConfig } = useProject();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (id: number) => removeArea(id, requestConfig),
        onSuccess: async (data: any, id: number) => {
            if (!data?.success) {
                throw new Error("Wrong format data: mutationDeleteArea");
            }

            addSuccess({
                description: fmt("Toast.ToastSuccessDelete"),
            });

            sendEvent("deleteArea", { id: id });
            if (callback) {
                callback(id);
            } else {
                await queryClient.invalidateQueries({
                    queryKey: [AreaKeys.INDEX, requestConfig],
                });
            }
        },
        onError: (err: any) => {
            addWarning({
                description: fmtError("GenericError"),
            });
            return err;
        },
    });
};

//EXPORT
export const useExportAreas = () => {
    const { requestConfig } = useProject();
    return useQuery({
        queryKey: [AreaKeys.EXPORT, requestConfig],
        queryFn: () => exportAreas(requestConfig),
        refetchOnWindowFocus: false,
        onSuccess: (response): void => {
            if (!response?.success || !response?.data?.data) {
                throw new Error("Wrong format data: useExportAreas");
            }

            // Create a Blob from the data
            const blob = new Blob([response.data], {
                type: response.headers["content-type"],
            });

            // Create a temporary anchor element
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `${Date.now()}_area.xlsx`;

            // Trigger the download
            document.body.appendChild(a);
            a.click();

            // Cleanup
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        },
        onError: (err: any) => {
            return err;
        },
        enabled: false,
    });
};

//IMPORT
export const mutationImportAreas = () => {
    const queryClient = useQueryClient();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Errors");
    const { requestConfig } = useProject();
    const { addSuccess, addWarning } = useToast();

    return useMutation({
        mutationFn: (data: any) => importAreas(data, requestConfig),
        onSuccess: async (response) => {
            if (!response?.success || !response?.data?.data) {
                throw new Error("Wrong format data: mutationImportAreas");
            }

            addSuccess({
                description: response.data.data,
            });

            await queryClient.invalidateQueries({
                queryKey: [AreaKeys.INDEX, requestConfig],
            });
        },
        onError: (err: any) => {
            console.debug("ERROR mutationImportAreas", err);
            addWarning({
                description: fmt("GenericError"),
            });
            return err;
        },
    });
};

//STATUS
export const mutationStatusArea = () => {
    const { requestConfig } = useProject();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (data: Type_prj_put_area) => {
            return updateArea(data, requestConfig);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: [AreaKeys.INDEX, requestConfig],
            });
        },
    });
};
