import { InputCancelComponent } from "./InputCancel.component";

export type InputCancelPropsType = {
    label: string;
    onClick?: any;
    props?: any;
    type?: string;
};

export const InputCancelContainer = (props: InputCancelPropsType) => {
    return <InputCancelComponent {...props} />;
};
