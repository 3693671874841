import { defaultPrintSettings } from "src/api/tms-print/print/types";
import { Type_default_workspace } from "src/api/tms-projects/workspacesAndViews/workspaces/types";

// get default configuration
export const getDefaultSubprojectConfiguration = () => {
    const defaultSubprojectWorkspaces: Map<string, Type_default_workspace> =
        new Map();

    defaultSubprojectWorkspaces.set("teamoty", <Type_default_workspace>{
        name: "Subproject.Teamoty.Title",
        description: "Subproject.Teamoty.Description",
        type: "teamoty",
        current: true,
        views: [
            {
                name: "View.Planning",
                order: 1,
                data: {
                    version: 1,
                    type: "planning",
                    settings: {
                        print: defaultPrintSettings,
                    },
                },
            },
            {
                name: "View.Gantt",
                order: 2,
                data: {
                    version: 1,
                    type: "gantt",
                    settings: {
                        print: defaultPrintSettings,
                    },
                },
            },
            {
                name: "View.Workflow",
                order: 3,
                data: { version: 1, type: "workflow" },
            },
            {
                name: "View.Dashboard",
                order: 4,
                data: {
                    version: 1,
                    type: "dashboard",
                    settings: {
                        widgets: ["graphs"],
                    },
                },
            },
        ],
    });

    defaultSubprojectWorkspaces.set("method", <Type_default_workspace>{
        name: "Subproject.Method.Title",
        description: "Subproject.Method.Description",
        type: "method",
        current: false,
        views: [
            {
                name: "View.Planning",
                order: 1,
                data: {
                    version: 1,
                    type: "planning",
                    settings: {
                        print: defaultPrintSettings,
                    },
                },
            },
            {
                name: "View.Grid",
                order: 2,
                data: { version: 1, type: "grid" },
            },
        ],
    });

    defaultSubprojectWorkspaces.set("siteManager", <Type_default_workspace>{
        name: "Subproject.SiteManager.Title",
        description: "Subproject.SiteManager.Description",
        type: "siteManager",
        current: false,
        views: [
            {
                name: "View.Dashboard",
                order: 1,
                data: { type: "Subproject siteManager View 1" },
            },
            {
                name: "View.Planning",
                order: 2,
                data: {
                    version: 1,
                    type: "planning",
                    settings: {
                        print: defaultPrintSettings,
                    },
                },
            },
            {
                name: "View.Gantt",
                order: 3,
                data: {
                    version: 1,
                    type: "gantt",
                    settings: {
                        print: defaultPrintSettings,
                    },
                },
            },
            {
                name: "View.Workflow",
                order: 4,
                data: { version: 1, type: "workflow" },
            },
        ],
    });

    return defaultSubprojectWorkspaces;
};
