import { Stack, Typography } from "@mui/material";
import * as React from "react";

import { ProjectPath, Url } from "src/api/paths";
import { Type_selectList_company } from "src/api/tms-commons/companies/types";
import { Autocomplete } from "src/components/Components_Common/forms/reactHookFormComponents/Autocomplete/Autocomplete";
import { DatePicker } from "src/components/Components_Common/forms/reactHookFormComponents/DatePicker/DatePicker";
import {
    formatSelectListToSelectOptionItem,
    Select,
} from "src/components/Components_Common/forms/reactHookFormComponents/Select/Select";
import { TextField } from "src/components/Components_Common/forms/reactHookFormComponents/TextField/TextField";
import { TextFieldWithBlock } from "src/components/Components_Common/forms/reactHookFormComponents/TextFieldWithBlock/TextFieldWithBlock";
import { UploadImageRhf } from "src/components/Components_Common/forms/reactHookFormComponents/UploadImage/UploadImage";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { getUrlName } from "src/utils/urlFormatter";

export type Type_Props_Tab_ProjectFormDetail = {
    projectTypes: any[];
    companies: Type_selectList_company[];
    projectId: number;
};

export const Tab_ProjectDetailForm = ({
    projectTypes,
    companies,
    projectId,
}: Type_Props_Tab_ProjectFormDetail) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Projects.Drawer");

    return (
        <Stack gap={6}>
            <Typography variant="body1Bold">
                {fmt(`AddProject.Step0.GlobalInformation`)}
            </Typography>
            <UploadImageRhf
                host={Url.COMMONS}
                service={ProjectPath.PROJECTS}
                id={projectId}
                name="thumbnail"
                withDescription
                limitResolutions={{
                    min: 540,
                    max: 2550,
                }}
            />
            <TextField
                data-testid="EditProject-DrawerContent-TabGlobal-name"
                name={"name"}
                label={fmt(`AddProject.Step0.ProjectLabel`)}
            />
            <TextFieldWithBlock
                data-testid="EditProject-DrawerContent-TabGlobal-slug"
                name="slug"
                label={fmt("AddProject.Step0.UrlLabel")}
                block={getUrlName()}
                disabled={true}
                description={fmt("AddProject.Step0.UrlCannotBeChanged")}
            />
            <Stack>
                <DatePicker
                    name={"startDate"}
                    label={fmt("AddProject.Step0.StartDate")}
                    data-testid="EditProject-DrawerContent-TabGlobal-StartDate"
                />
            </Stack>
            <Select
                name={"companyId"}
                options={formatSelectListToSelectOptionItem(companies)}
                label={fmt("AddProject.Step0.CompanyLabel")}
                variant={"outlined"}
                data-testid="EditProject-DrawerContent-TabGlobal-Company"
            />
            <Stack direction="row" flexWrap="nowrap" gap={4} width="100%">
                <TextField
                    data-testid="EditProject-DrawerContent-TabGlobal-postCode"
                    name={"postCode"}
                    label={fmt(`AddProject.Step0.PostCodeLabel`)}
                />
                <TextField
                    data-testid="EditProject-DrawerContent-TabGlobal-city"
                    name={"city"}
                    label={fmt(`AddProject.Step0.CityLabel`)}
                />
                <TextField
                    data-testid="EditProject-DrawerContent-TabGlobal-country"
                    name={"country"}
                    label={fmt(`AddProject.Step0.CountryLabel`)}
                />
            </Stack>
            <Typography variant="body1Bold">
                {fmt(`AddProject.Step0.ProjectInformation`)}
            </Typography>
            <Autocomplete
                data-testid="EditProject-DrawerContent-TabGlobal-projectTypes"
                id="projectTypes"
                name="projectTypes"
                multiple
                options={projectTypes}
                renderInputProps={{
                    variant: "outlined",
                }}
            />
        </Stack>
    );
};
