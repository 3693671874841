import Konva from "konva";
import React, { useState } from "react";
import { Group, Rect } from "react-konva";
import { Portal } from "react-konva-utils";

import { Type_point } from "src/components/Components_Common/canvas/types";
import { FlowCircle } from "src/components/Components_Teamoty/Flow/Circle/FlowCircle";
import {
    colorFlowSelected,
    heightFlowShape,
    marginFlowSelected,
} from "src/components/Components_Teamoty/Flow/Flow.const";
import {
    Conv_typeFlowTask,
    Enum_typeCircleFlow,
} from "src/components/Components_Teamoty/Flow/Flow.enum";
import { FlowGroup } from "src/components/Components_Teamoty/Flow/Group/FlowGroup";
import { FlowToolbar } from "src/components/Components_Teamoty/Flow/Toolbar/FlowToolbar";
import {
    addPoint,
    diffPoint,
} from "src/components/Components_Teamoty/Flow/tools/diffPoint";
import { getTaskWidth } from "src/components/Components_Teamoty/Flow/tools/getTaskWidth";
import { onGrid } from "src/components/Components_Teamoty/Flow/tools/onGrid";

import { componentsTaskFlow } from "./FlowShape.const";
import { Type_Props_FlowShape, Type_State_FlowShape } from "./FlowShape.type";

export const FlowShape = ({
    refStage,
    task,
    index,
    stageFlow,
    changeTask,
    changeNewLink,
    selected,
    changeSelected,
    fixedSize,
}: Type_Props_FlowShape) => {
    const [drag, setDrag] = useState<Type_State_FlowShape>({
        dragging: false,
        pt: { x: 0, y: 0 },
        ptMouse: { x: 0, y: 0 },
        ptShape: { x: 0, y: 0 },
    });

    const [over, setOver] = useState<boolean>(false);

    // Toolbar disabled by default
    const [enableToolbar] = useState<boolean>(false);
    const width: number = getTaskWidth(task, fixedSize);
    const height: number = heightFlowShape;

    const marginSelected: number = marginFlowSelected;
    const colorSelected: string = colorFlowSelected;

    const typeShape = Conv_typeFlowTask[
        task.type
    ] as keyof typeof componentsTaskFlow;
    const SpecificShape = componentsTaskFlow[typeShape];

    const shapeDragMove = (e: Konva.KonvaEventObject<MouseEvent>) => {
        e.cancelBubble = true;

        const shape: Konva.Shape = e.currentTarget as Konva.Shape;
        const stage: Konva.Stage | null = shape.getStage();

        if (stage) {
            const mousePos: Type_point | null =
                stage.getRelativePointerPosition();
            if (mousePos) {
                const delta: Type_point = diffPoint(mousePos, drag.ptMouse);
                const newPos: Type_point = addPoint(delta, drag.ptShape);

                task.pt = newPos;

                const mousePosGrid: Type_point = onGrid(newPos, 2);
                setDrag(
                    (prev: Type_State_FlowShape): Type_State_FlowShape => ({
                        ...prev,
                        dragging: true,
                        pt: diffPoint(mousePosGrid, task.pt),
                    }),
                );

                changeTask(task, index);
            }
        }

        e.currentTarget.x(0);
        e.currentTarget.y(0);
    };

    const click = (e: Konva.KonvaEventObject<DragEvent>) => {
        e.cancelBubble = true;

        changeSelected({ selected: true, task: task });
    };

    const shapeDragStart = (e: Konva.KonvaEventObject<DragEvent>) => {
        e.cancelBubble = true;

        const shape: Konva.Shape = e.currentTarget as Konva.Shape;
        const stage: Konva.Stage | null = shape.getStage();

        if (stage) {
            const mousePos: Type_point | null =
                stage.getRelativePointerPosition();

            if (mousePos) {
                setDrag({
                    dragging: true,
                    pt: { x: e.currentTarget.x(), y: e.currentTarget.y() },
                    ptMouse: { x: mousePos.x, y: mousePos.y },
                    ptShape: { x: task.pt.x, y: task.pt.y },
                });
            }
        }
    };

    const shapeDragEnd = (e: Konva.KonvaEventObject<DragEvent>) => {
        e.cancelBubble = true;

        task.pt = addPoint(drag.pt, task.pt);
        changeTask(task, index, { xy: [task.pt.x, task.pt.y] });

        setDrag(
            (prev: Type_State_FlowShape): Type_State_FlowShape => ({
                ...prev,
                dragging: false,
            }),
        );

        e.currentTarget.x(0);
        e.currentTarget.y(0);
    };

    const container: HTMLDivElement | undefined = refStage.current
        ?.getStage()
        .getContent();

    return (
        <Portal selector=".top" enabled={drag.dragging}>
            <Group>
                <Group
                    id={task.id.toString()}
                    draggable={true}
                    onClick={click}
                    onDragMove={shapeDragMove}
                    onDragStart={shapeDragStart}
                    onDragEnd={shapeDragEnd}
                    onMouseOver={(): void => {
                        setOver(true);
                        document.body.style.cursor = "pointer";
                    }}
                    onMouseOut={(): void => {
                        setOver(false);
                        document.body.style.cursor = "default";
                    }}
                >
                    {!drag.dragging &&
                        ((selected.selected && selected?.task == task) ||
                            over) && (
                            <Group>
                                <Rect
                                    x={task.pt.x}
                                    y={task.pt.y}
                                    width={width + marginSelected}
                                    height={height + marginSelected}
                                    offsetX={marginSelected / 2}
                                    offsetY={marginSelected / 2}
                                    dash={
                                        over &&
                                        !(
                                            selected.selected &&
                                            selected?.task == task
                                        )
                                            ? [2, 2]
                                            : []
                                    }
                                    stroke={colorSelected}
                                    strokeWidth={1}
                                    listening={false}
                                />
                            </Group>
                        )}

                    {task.group && <FlowGroup task={task} />}

                    <SpecificShape
                        onChange={(changes) => {
                            changeTask({ ...task, ...changes }, index);
                        }}
                        task={task}
                        drag={drag}
                        fixedSize={fixedSize}
                    />

                    {enableToolbar &&
                        !drag.dragging &&
                        selected.selected &&
                        selected?.task == task && (
                            <FlowToolbar
                                container={container}
                                task={task}
                                width={width}
                                stageFlow={stageFlow}
                            />
                        )}
                </Group>

                <FlowCircle
                    task={task}
                    type={Enum_typeCircleFlow.Start}
                    x={task.pt.x}
                    y={task.pt.y + height / 2}
                    fill={task.color}
                    changeNewLink={changeNewLink}
                    // changeSelected={changeSelected}
                />

                <FlowCircle
                    task={task}
                    type={Enum_typeCircleFlow.Finish}
                    x={task.pt.x + width}
                    y={task.pt.y + height / 2}
                    fill={task.color}
                    changeNewLink={changeNewLink}
                    // changeSelected={changeSelected}
                />
            </Group>
        </Portal>
    );
};
