import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

import { COLORS } from "src/theme/stylesheet";

export const Styled_StackNoData = styled(Stack)(({ theme }: any) => ({
    height: "320px",
    backgroundColor: COLORS.moon100,
    border: `1px dashed ${COLORS.moon300}`,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: theme.shape.borderRadiusLarge,
}));
