import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";

import { useTreeSequenceFolders } from "src/api/tms-scheduling/sequenceFolders";
import {
    Type_index_sequenceFolderTree,
    Type_index_sequenceFolderTree_sequence,
    Type_index_sequenceFolderTree_task,
} from "src/api/tms-scheduling/sequenceFolders/types";
import { LoadingBox } from "src/components";
import { TMC_RichTreeView } from "src/components/Components_Common/TreeView/TMC_RichTreeView";
import { useProject } from "src/contexts/project";
import { useUser } from "src/contexts/user";
import { SequencesTreeItem } from "src/drawers/sequences/SequencesContextualDrawer/SequencesTreeItem";
import {
    Type_event_sequenceFolderTree,
    useChannel,
} from "src/hooks/useChannel";
import { sortData } from "src/utils/sortData";

export const SequencesTreeView = () => {
    const { user } = useUser();
    const { project, subProject } = useProject();
    const { isFetching, data, refetch } = useTreeSequenceFolders();
    const [folders, setFolders] = useState<Type_index_sequenceFolderTree[]>([]);

    useEffect((): void => {
        if (!isFetching) {
            setFolders(
                sortData(data as Type_index_sequenceFolderTree[], "label"),
            );
        }
    }, [isFetching]);

    const addNewFolder = (folder: Type_event_sequenceFolderTree): void => {
        setFolders((prevState: Type_index_sequenceFolderTree[]) => {
            const newFolders: Type_index_sequenceFolderTree[] = [
                ...prevState,
                folder,
            ];
            return sortData(newFolders, "label");
        });
    };

    const updateFolder = (folder: Type_event_sequenceFolderTree): void => {
        setFolders((prevState: Type_index_sequenceFolderTree[]) => {
            const index = prevState.findIndex((r) => r.id === folder.id);
            const newFolders = [...prevState];
            newFolders[index].label = folder.label;
            return sortData(newFolders, "label");
        });
    };

    useChannel({
        eventHandler: ({ event, data }): void => {
            const folder: Type_event_sequenceFolderTree =
                data as Type_event_sequenceFolderTree;
            if (event === "addSequenceFolder") {
                addNewFolder(folder);
            }
            if (event === "updateSequenceFolder") {
                updateFolder(folder);
            }
            if (event === "duplicateSequence") {
                refetch();
            }
        },
    });

    const getItemId = (
        item:
            | Type_index_sequenceFolderTree
            | Type_index_sequenceFolderTree_sequence
            | Type_index_sequenceFolderTree_task,
    ) => {
        return item.itemId;
    };

    const localStorageKey: string = `treeview-sequences-${project?.id}-${subProject?.id}-${user?.id}`;

    return (
        <Box>
            {isFetching ? (
                <Box pt={4}>
                    <LoadingBox />
                </Box>
            ) : (
                <TMC_RichTreeView
                    items={folders}
                    getItemId={getItemId}
                    localStorageKey={localStorageKey}
                    CustomTreeItem={SequencesTreeItem}
                />
            )}
        </Box>
    );
};
