import { InputTextComponent } from "./InputText.component";

export type InputTextPropsType = {
    name: string;
    label?: string;
    placeholder?: string;
    onChange?: any;
    defaultValue?: string;
    value: any;
};

export const InputTextContainer = (props: InputTextPropsType) => {
    return <InputTextComponent {...props} />;
};
