import React from "react";
import { Group, Rect } from "react-konva";

import { TextEdit } from "src/components/Components_Common/canvas/TextEdit";
import {
    colorFlowShapeStroke,
    fontFlowFamily,
    heightFlowShape,
    paddingFlowText,
    sizeFlowGridX,
    sizeFlowGridY,
    sizeFlowTaskName,
    widthFlowShapeStroke,
} from "src/components/Components_Teamoty/Flow/Flow.const";
import { Conv_typeFlowTask } from "src/components/Components_Teamoty/Flow/Flow.enum";
import { getTaskWidth } from "src/components/Components_Teamoty/Flow/tools/getTaskWidth";

import {
    components_shape_flow,
    Type_props_components_shape_flow,
} from "./FlowShape.const";
import { Type_Props_FlowShapeMilestoneBase } from "./FlowShape.type";
import { FlowShapeWorkMilestone } from "./FlowShapeWorkMilestone";

export const FlowShapeMilestoneBase = ({
    task,
    drag,
}: Type_Props_FlowShapeMilestoneBase) => {
    const padding = paddingFlowText;
    const width = getTaskWidth(task);
    const height = heightFlowShape;

    const typeShape: Type_props_components_shape_flow = Conv_typeFlowTask[
        task.type
    ] as Type_props_components_shape_flow;
    const SpecificShape = components_shape_flow[typeShape];

    return (
        <Group>
            <Rect
                x={task.pt.x - sizeFlowGridX / 6}
                y={task.pt.y - sizeFlowGridY / 6}
                width={width + sizeFlowGridX / 3}
                height={height + sizeFlowGridY / 3}
            />

            {drag.dragging && (
                <FlowShapeWorkMilestone
                    shapeFlowFunction={SpecificShape}
                    x={drag.pt.x}
                    y={drag.pt.y}
                    pt={task.pt}
                    width={width}
                    height={height}
                    opacity={0.5}
                    fill={task.color}
                    listening={false}
                />
            )}

            <FlowShapeWorkMilestone
                name="preview"
                shapeFlowFunction={SpecificShape}
                pt={task.pt}
                width={width}
                height={height}
                fill={task.color}
                stroke={task.color}
                strokeWidth={widthFlowShapeStroke}
            />

            <FlowShapeWorkMilestone
                name="preview"
                shapeFlowFunction={SpecificShape}
                pt={task.pt}
                width={width}
                height={height}
                stroke={colorFlowShapeStroke}
                strokeWidth={widthFlowShapeStroke}
            />

            <TextEdit
                id={task.id.toString()}
                ellipsis={true}
                fontFamily={fontFlowFamily}
                fontSize={sizeFlowTaskName}
                wrap="none"
                width={width * 3}
                text={
                    (task?.code ? task?.code.toUpperCase() + " - " : "") +
                    task.name.toUpperCase()
                }
                fill={"black"}
                verticalAlign="middle"
                align="center"
                height={height / 2}
                padding={padding}
                x={task.pt.x - width}
                y={task.pt.y + height + padding}
                listening={false}
                editOffsetY={3}
            />
        </Group>
    );
};
