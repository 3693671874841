import * as React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { TimeModelKeys } from "src/api/tms-commons/keys";
import {
    formatterCreateTimeModel,
    formatterIndexTimeModel,
    formatterIndexTimeModels,
    formatterSelectListTimeModel,
    formatterShowTimeModel,
    formatterStatusTimeModel,
    formatterUpdateTimeModel,
} from "src/api/tms-commons/timeModels/formatters";
import {
    createTimeModel,
    deleteTimeModel,
    indexTimeModels,
    selectListTimeModels,
    showTimeModel,
    statusTimeModel,
    updateTimeModel,
} from "src/api/tms-commons/timeModels/services";
import {
    Type_put_timeModel,
    Type_show_timeModel,
} from "src/api/tms-commons/timeModels/types";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const useIndexTimeModels = () => {
    return useQuery({
        queryKey: [TimeModelKeys.INDEX],
        queryFn: () => indexTimeModels(),
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useIndexTimeModels");
            }
            return formatterIndexTimeModels(data.data.data);
        },
        onError: (err: any) => {
            return err;
        },
    });
};

export const mutationStatusTimeModel = () => {
    const queryClient = useQueryClient();
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");

    return useMutation({
        mutationFn: (data: Type_put_timeModel) => {
            return statusTimeModel(data.id, formatterStatusTimeModel(data));
        },
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationStatusTimeModel");
            }

            await queryClient.invalidateQueries([TimeModelKeys.INDEX]);
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationCreateTimeModel = () => {
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Drawer.TimeModels");
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (timeModel: Type_show_timeModel) =>
            createTimeModel(formatterCreateTimeModel(timeModel)),
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationCreateTimeModel");
            }
            const timeModel = formatterIndexTimeModel(data.data.data);

            await queryClient.invalidateQueries([TimeModelKeys.INDEX]);

            addSuccess({
                description: fmt("ToastSuccess", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        timeModel: timeModel.name,
                    },
                }),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationUpdateTimeModel = () => {
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Drawer.TimeModels");
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");

    const { addSuccess, addWarning } = useToast();

    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (data: Type_show_timeModel) =>
            updateTimeModel(data.id, formatterUpdateTimeModel(data)),
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationUpdateTimeModel");
            }

            const timeModel = formatterIndexTimeModel(data.data.data);

            await queryClient.invalidateQueries([TimeModelKeys.INDEX]);

            addSuccess({
                description: fmt("ToastSuccessUpdate", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        timeModel: timeModel.name,
                    },
                }),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const useShowTimeModel = (id: number) => {
    return useQuery([TimeModelKeys.SHOW, id], () => showTimeModel(id), {
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useShowTimeModel");
            }

            return formatterShowTimeModel(data.data.data);
        },
        onError: (err) => {
            return err;
        },
        enabled: !!id,
        refetchOnWindowFocus: false,
    });
};

export const useSelectListTimeModels = () => {
    return useQuery({
        queryKey: [TimeModelKeys.SELECT_LIST],
        queryFn: selectListTimeModels,
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useSelectListTimeModel");
            }
            return formatterSelectListTimeModel(data.data.data);
        },
        onError: (err) => {
            return err;
        },
    });
};

export const mutationDeleteTimeModel = () => {
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Drawer.TimeModels");

    const queryClient = useQueryClient();

    const { addSuccess, addWarning } = useToast();

    return useMutation({
        mutationFn: (id: number) => deleteTimeModel(id),
        onSuccess: async (data) => {
            if (!data?.success) {
                throw new Error("Wrong format data: mutationDeleteTimeModel");
            }

            await queryClient.invalidateQueries({
                queryKey: [TimeModelKeys.INDEX],
            });

            addSuccess({
                description: fmt("ToastSuccessDelete"),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError", {}),
            });
            return err;
        },
    });
};
