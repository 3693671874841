import { Grid, Stack, Typography } from "@mui/material";
import React, { ReactNode, useState } from "react";

import { Type_show_subProject } from "src/api/tms-projects/subProjects/types";
import { Spinner, TMC_Button } from "src/components";
import { DaysBadge } from "src/components/Components_Common/Days/DaysBadge";
import { Styled_ImagePreviewSm } from "src/components/Components_Common/UploadImage/UploadImage.style";
import { PdfAppearanceDrawer } from "src/drawers/pdfAppearance/PdfAppearanceDrawer";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export type Type_Props_Page_DefaultSettingsComponent = {
    subProject?: Type_show_subProject;
    openDrawer: (step: string) => void;
    isFetching: boolean;
};

export const Page_DefaultSettingsComponent = ({
    subProject,
    openDrawer,
    isFetching,
}: Type_Props_Page_DefaultSettingsComponent) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.SubprojectSettings.DefaultSettings",
    );
    const { formatMessageWithPartialKey: fmtLabels } = useCoreIntl("Labels");

    const [openPDFSettingsDrawer, setOpenPDFSettingsDrawer] = useState(false);

    const handleClosePdfAppearanceDrawer = () => {
        setOpenPDFSettingsDrawer(false);
    };

    return (
        <>
            {isFetching && <Spinner />}
            {!isFetching && (
                <Stack rowGap={6}>
                    <Grid item container justifyContent="space-between" xs={12}>
                        <Typography variant="body1Bold">
                            {fmt("Sequences")}
                        </Typography>
                        <Stack direction="row">
                            <TMC_Button
                                variant="text"
                                data-testid="SubProject_Default_Settings_Edit_Sequences"
                                onClick={() => {
                                    openDrawer("0");
                                }}
                                sx={{
                                    minWidth: "auto",
                                }}
                            >
                                {fmt("Edit")}
                            </TMC_Button>
                        </Stack>
                    </Grid>
                    <Grid container rowSpacing={4} columnSpacing={2}>
                        <Grid item xs={4} md={3}>
                            <Typography
                                variant="body1"
                                color={"sectionTitle.contrastText"}
                            >
                                {fmt("MinDuration")}
                            </Typography>
                        </Grid>
                        <Grid item xs={8} md={9}>
                            {subProject && (
                                <Typography
                                    variant="body1"
                                    data-testid={
                                        "SubProject_Default_Settings_MinDuration"
                                    }
                                >
                                    {fmt(
                                        `MinDuration${subProject.minDuration}`,
                                    )}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={4} md={3}>
                            <Typography
                                variant="body1"
                                color={"sectionTitle.contrastText"}
                            >
                                {fmt("WorkingDays")}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={8}
                            md={9}
                            data-testid={
                                "SubProject_Default_Settings_WorkingDays"
                            }
                        >
                            {subProject && (
                                <DaysBadge
                                    disableEdition
                                    initialValue={subProject?.workingDays}
                                />
                            )}
                        </Grid>
                        <Grid item xs={4} md={3}>
                            <Typography
                                variant="body1"
                                color={"sectionTitle.contrastText"}
                            >
                                {fmt("OptimizedFlow")}
                            </Typography>
                        </Grid>
                        <Grid item xs={8} md={9}>
                            {subProject && (
                                <Typography
                                    variant="body1"
                                    data-testid={
                                        "SubProject_Default_Settings_OptimizedFlow"
                                    }
                                >
                                    {subProject?.optimizedFlow
                                        ? fmtLabels("Yes")
                                        : fmtLabels("No")}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={4} md={3}>
                            <Typography
                                variant="body1"
                                color={"sectionTitle.contrastText"}
                            >
                                {fmt("TimeModel")}
                            </Typography>
                        </Grid>
                        <Grid item xs={8} md={9}>
                            <Typography
                                variant="body1"
                                data-testid={
                                    "SubProject_Default_Settings_TimeModel"
                                }
                            >
                                {subProject?.timeModel?.name}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container justifyContent="space-between" xs={12}>
                        <Typography variant="body1Bold">
                            {fmt("Versions")}
                        </Typography>
                        <Stack direction="row">
                            <TMC_Button
                                variant="text"
                                data-testid="SubProject_Default_Settings_Edit_Versions"
                                onClick={() => {
                                    openDrawer("1");
                                }}
                                sx={{
                                    minWidth: "auto",
                                }}
                            >
                                {fmt("Edit")}
                            </TMC_Button>
                        </Stack>
                    </Grid>
                    <Grid container rowSpacing={4} columnSpacing={2}>
                        <Grid item xs={4} md={3}>
                            <Typography
                                variant="body1"
                                color={"sectionTitle.contrastText"}
                            >
                                {fmt("VersionLimit")}
                            </Typography>
                        </Grid>
                        <Grid item xs={8} md={9}>
                            {subProject && (
                                <Typography
                                    variant="body1"
                                    data-testid={
                                        "SubProject_Default_Settings_VersionLimit"
                                    }
                                >
                                    {subProject.versionLimit &&
                                    subProject.versionLimit > 0
                                        ? subProject.versionLimit
                                        : fmt(`VersionLimitNone`)}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                    <Stack rowGap={4}>
                        <Grid
                            item
                            container
                            justifyContent="space-between"
                            xs={12}
                        >
                            <Typography variant="body1Bold">
                                {fmt("PDFappearance")}
                            </Typography>
                            <Stack direction="row">
                                <TMC_Button
                                    variant={"text"}
                                    data-testid="Default-settings-btn-edit-pdf-appearance"
                                    onClick={() =>
                                        setOpenPDFSettingsDrawer(true)
                                    }
                                    sx={{
                                        minWidth: "auto",
                                    }}
                                >
                                    {fmt("Edit")}
                                </TMC_Button>
                            </Stack>
                        </Grid>
                        <InfoRow title={fmt("Title")} value="test value" />
                        <InfoRow title={fmt("SubTitle")} value="test value" />
                        <InfoRow title={fmt("BackgroundColor")} value="#fff" />
                        <InfoRow title={fmt("LeftImg")}>
                            <Styled_ImagePreviewSm
                                style={{
                                    // test preview
                                    backgroundImage: `url(https://students-residents.aamc.org/sites/default/files/test-taking-photo.jpg)`,
                                }}
                            />
                        </InfoRow>
                        <InfoRow title={fmt("RightImg")}>
                            <Styled_ImagePreviewSm
                                style={{
                                    // test preview
                                    backgroundImage: `url(https://students-residents.aamc.org/sites/default/files/test-taking-photo.jpg)`,
                                }}
                            />
                        </InfoRow>
                    </Stack>
                    <PdfAppearanceDrawer
                        page="subProjects"
                        open={openPDFSettingsDrawer}
                        onClose={handleClosePdfAppearanceDrawer}
                    />
                </Stack>
            )}
        </>
    );
};

type Type_Props_InfoRow = {
    title: string;
    value?: string;
    children?: ReactNode;
};

const InfoRow = ({ title, value, children }: Type_Props_InfoRow) => {
    return (
        <Grid container rowGap={2}>
            <Grid item xs={4} md={3}>
                <Typography
                    variant="body1"
                    color="text.secondary"
                    data-testid={`Default-settings-${title}-label`}
                >
                    {title}
                </Typography>
            </Grid>
            <Grid item xs={8} md={9}>
                <Stack direction="row" spacing={2} alignItems="baseline">
                    {typeof value === "string" ? (
                        <Typography
                            variant="body1"
                            data-testid={`Default-settings-${title}`}
                        >
                            {value}
                        </Typography>
                    ) : (
                        children
                    )}
                </Stack>
            </Grid>
        </Grid>
    );
};
