import React, { Dispatch, SetStateAction } from "react";

import { Type_formData_NewUser_Invitation } from "src/pages/Pages_Common/Page_NewUser_Invitation/Page_NewUser_Invitation.container";

import { CompareCompanyContainer } from "./CompareCompany/CompareCompany.container";
import { CreateCompanyContainer } from "./CreateCompany/CreateCompany.container";
import { SearchCompanyContainer } from "./SearchCompany/SearchCompany.container";

export type Type_Step4ComponentProps = {
    formData: Type_formData_NewUser_Invitation;
    setFormData: Dispatch<SetStateAction<Type_formData_NewUser_Invitation>>;
    step: number;
    maxStep: number;
    companyStep: number;
    handleStep: (stateAction: "previous" | "next") => void;
    handleCompanyStep: (
        stateAction: "previous" | "next",
        resetFormData: boolean,
    ) => void;
    setStep: any;
    companySearch: string;
    setCompanySearch: (stateAction: string) => void;
};
export const Step_4Component = ({
    formData,
    setFormData,
    step,
    maxStep,
    companyStep,
    handleCompanyStep,
    handleStep,
    setStep,
    companySearch,
    setCompanySearch,
}: Type_Step4ComponentProps) => {
    if (companyStep === 1) {
        return (
            <SearchCompanyContainer
                formData={formData}
                setFormData={setFormData}
                step={step}
                maxStep={maxStep}
                handleStep={handleStep}
                handleCompanyStep={handleCompanyStep}
                companySearch={companySearch}
                setCompanySearch={setCompanySearch}
            />
        );
    } else if (companyStep === 2) {
        return (
            <CreateCompanyContainer
                formData={formData}
                setFormData={setFormData}
                step={step}
                maxStep={maxStep}
                handleCompanyStep={handleCompanyStep}
            />
        );
    } else if (companyStep === 3) {
        return (
            <CompareCompanyContainer
                step={step}
                maxStep={maxStep}
                handleStep={handleStep}
                formData={formData}
                setFormData={setFormData}
                handleCompanyStep={handleCompanyStep}
                setStep={setStep}
            />
        );
    } else {
        return <></>;
    }
};
