import { Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState } from "react";

import { Spinner, TMC_Button } from "src/components";
import { PasswordForm } from "src/forms/security/PasswordForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const Styled_Stack_Security = styled(Stack)(() => ({
    minWidth: 600,
}));

export const Page_Security = () => {
    const { formatMessageWithPartialKey: fmt, formatMessageWithFullKey: fmfk } =
        useCoreIntl("MyAccount.Security");

    const [isLoading, setIsLoading] = useState(false);
    const [isRequirementsComplete, setRequirementsComplete] = useState(false);

    const formId = "password-security";

    return (
        <Styled_Stack_Security>
            <Typography
                variant="h2"
                sx={{ mb: 2 }}
                data-testid="PageSecurity-ChangePassword-Title"
            >
                {fmt("Title")}
            </Typography>
            <PasswordForm
                formId={formId}
                setIsLoading={setIsLoading}
                setRequirementsComplete={setRequirementsComplete}
            />
            <TMC_Button
                form={formId}
                type="submit"
                variant="contained"
                disabled={isLoading || !isRequirementsComplete}
                sx={{ marginTop: "20px" }}
                data-testid="MyAccount-Security-Form-submit"
            >
                {isLoading ? <Spinner style="button" /> : fmfk(`Form.Cta.Save`)}
            </TMC_Button>
        </Styled_Stack_Security>
    );
};
