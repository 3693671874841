import { FormikValues } from "formik";

import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    PutReturnType,
    remove,
    RemoveReturnType,
    Type_RequestConfig,
} from "src/api/fetch";
import { ResourcePath, Url } from "src/api/paths";

export const indexResourcesLogistics = (
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(`${Url.LOGISTICS}${ResourcePath.RESOURCES}`, requestConfig);
};

export const createResourceLogistics = (
    data: any,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return post(
        data,
        `${Url.LOGISTICS}${ResourcePath.RESOURCES}`,
        requestConfig,
    );
};

export const updateResourceLogistics = (
    data: any,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return put(
        data,
        `${Url.LOGISTICS}${ResourcePath.RESOURCES}/${data.id}`,
        requestConfig,
    );
};

export const statusResourceLogistics = (
    data: FormikValues,
    requestConfig: Type_RequestConfig,
): PutReturnType => {
    return put(
        data,
        `${Url.LOGISTICS}${ResourcePath.RESOURCES}/${data.id}`,
        requestConfig,
    );
};

export const removeResourceLogistics = (
    id: number,
    requestConfig: Type_RequestConfig,
): RemoveReturnType => {
    return remove(
        { id },
        `${Url.LOGISTICS}${ResourcePath.RESOURCES}/${id}`,
        requestConfig,
    );
};
