import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Form } from "formik";

export const Styled_Stack = styled(Stack)(({ theme }: any) => ({
    display: "flex",
    flexDirection: "column",
    width: "400px",
    height: "200px",
    rowGap: theme.spacing(6),
}));

export const Styled_Form = styled(Form)(() => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "400px",
    height: "84%",
}));

export const Styled_Scrollable_Stack = styled(Stack)(() => ({
    maxHeight: "40vH",
    overflowY: "auto",
    width: "100%",
}));
