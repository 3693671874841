import { useMutation, useQuery, useQueryClient } from "react-query";

import { formatted_FavouritesProjectsIndex } from "src/api/tms-commons/favouriteProjects/formatters";
import {
    addProjectToFavourites,
    indexFavouritesProjects,
    removeProjectFromFavourites,
} from "src/api/tms-commons/favouriteProjects/services";
import { FavouriteProjectKeys } from "src/api/tms-commons/keys";

export const mutationAddProjectToFavourites = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (id: number) =>
            addProjectToFavourites({
                project_id: id,
            }),
        onSuccess: async () => {
            await queryClient.invalidateQueries([FavouriteProjectKeys.INDEX]);
        },
        onError: (err: any) => {
            return err;
        },
    });
};

export const useGetFavouritesProjects = (callback: any) => {
    useQuery({
        queryKey: [FavouriteProjectKeys.INDEX],
        queryFn: indexFavouritesProjects,
        refetchOnWindowFocus: false,
        onSuccess: (data): void => {
            callback(formatted_FavouritesProjectsIndex(data.data.data));
        },
    });
};

export const mutationRemoveProjectFromFavourites = () => {
    return useMutation({
        mutationFn: (id: number) => removeProjectFromFavourites(id),
    });
};
