import { Box } from "@mui/material";
import React from "react";

import { useShowSequence } from "src/api/tms-scheduling/sequences";
import { LoadingBox } from "src/components";
import { SequenceForm } from "src/forms/sequence/SequenceForm";
import { Styled_PaperContextualLayout } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

type Type_props_SequenceContextualDrawer = {
    id: number;
};

export const SequenceContextualDrawer = ({
    id,
}: Type_props_SequenceContextualDrawer) => {
    const { data, isFetching } = useShowSequence(
        id,
        "sequenceContextualDrawer",
    );

    return (
        <Styled_PaperContextualLayout>
            {isFetching ? (
                <Box paddingTop={8}>
                    <LoadingBox />
                </Box>
            ) : (
                <SequenceForm id={id} data={data} />
            )}
        </Styled_PaperContextualLayout>
    );
};
