import { useEffect, useState } from "react";

import {
    mutationAddProjectToFavourites,
    mutationRemoveProjectFromFavourites,
    useGetFavouritesProjects,
} from "src/api/tms-commons/favouriteProjects";
import {
    mutationHandleProjectArchived,
    mutationHandleProjectStatus,
    mutationRemoveProject,
    useIndexProjects,
} from "src/api/tms-commons/projects";
import { Type_index_project } from "src/api/tms-commons/projects/types";

import { Page_ProjectsComponent } from "./Page_Projects.component";

export type Type_modalDeleteProject = {
    isOpen: boolean;
    id?: number;
    card?: any;
};

export const Page_ProjectsContainer = () => {
    const [projects, setProjects] = useState<Type_index_project[]>([]);
    const [favouritesProjects, setFavouritesProjects] = useState<
        Type_index_project[]
    >([]);
    const { mutate: mutateAddProjectToFavourites } =
        mutationAddProjectToFavourites() || {};
    const { mutate: mutateRemoveProjectFromFavourites } =
        mutationRemoveProjectFromFavourites() || {};
    const { mutate: mutateHandleProjectStatus } =
        mutationHandleProjectStatus(setProjects, projects) || {};
    const { mutate: mutateHandleProjectArchived } =
        mutationHandleProjectArchived(setProjects, projects) || {};
    const { mutate: mutateRemoveProject } = mutationRemoveProject() || {};
    const [modalDeleteProject, setModalDeleteProject] =
        useState<Type_modalDeleteProject>({
            isOpen: false,
        });

    useIndexProjects(setProjects);
    useGetFavouritesProjects(setFavouritesProjects);

    useEffect(() => {
        if (favouritesProjects.length && projects.length) {
            const newProjectsList = projects.map((project) => {
                return {
                    ...project,
                    favourite_id: favouritesProjects.find(
                        (favouriteProject) =>
                            favouriteProject.id === project.id,
                    )?.favourite_id,
                };
            });
            setProjects((_prev: Type_index_project[]) => newProjectsList);
        }
    }, [favouritesProjects]);

    const addProjectToFavourites = async (id: number) => {
        mutateAddProjectToFavourites(id);
    };

    const deleteFavouriteProject = async (id: number) => {
        setFavouritesProjects(
            favouritesProjects.filter(
                (favouritesProjects: Type_index_project): boolean =>
                    favouritesProjects.favourite_id != id,
            ),
        );
        mutateRemoveProjectFromFavourites(id);
    };

    const handleProjectStatus = async (
        id: number,
        project: Type_index_project,
    ) => {
        mutateHandleProjectStatus({
            id,
            enabled: !project.enabled,
        });
    };

    const handleProjectArchived = async (
        id: number,
        project: Type_index_project,
    ) => {
        mutateHandleProjectArchived({
            id,
            archived: !project.archived,
        });
    };

    const deleteProject = async (id: number) => {
        setProjects(
            projects.filter(
                (project: Type_index_project): boolean => project.id != id,
            ),
        );
        mutateRemoveProject(id);
    };

    const favouritesProjectsId: number[] = favouritesProjects.map(
        (favouriteProject: Type_index_project) => favouriteProject?.id,
    );

    const archivedProjects: Type_index_project[] = projects.filter(
        (project: Type_index_project): boolean => project.archived,
    );
    const projectsWithoutArchived: Type_index_project[] = projects.filter(
        (project: Type_index_project): boolean => !project.archived,
    );

    return (
        <Page_ProjectsComponent
            projects={projectsWithoutArchived}
            archivedProjects={archivedProjects}
            favouritesProjects={favouritesProjects}
            addProjectToFavourites={addProjectToFavourites}
            deleteFavouriteProject={deleteFavouriteProject}
            handleProjectStatus={handleProjectStatus}
            handleProjectArchived={handleProjectArchived}
            deleteProject={deleteProject}
            modalDeleteProject={modalDeleteProject}
            setModalDeleteProject={setModalDeleteProject}
            favouritesProjectsId={favouritesProjectsId}
        />
    );
};
