import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    PutReturnType,
    remove,
    RemoveReturnType,
    Type_RequestConfig,
} from "src/api/fetch";
import { SequenceFolderPath, Url } from "src/api/paths";
import {
    Type_sch_post_sequenceFolder,
    Type_sch_put_sequenceFolder,
    Type_sch_put_sequenceFolderLegacy,
} from "src/api/tms-scheduling/sequenceFolders/types";

export const indexSequenceFolders = (
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(
        `${Url.SCHEDULING}${SequenceFolderPath.SEQUENCE_FOLDERS}`,
        requestConfig,
    );
};

export const selectListSequenceFolders = (
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(
        `${Url.SCHEDULING}${SequenceFolderPath.SEQUENCE_FOLDERS_SELECT_LIST}`,
        requestConfig,
    );
};

export const createSequenceFolder = (
    data: Type_sch_post_sequenceFolder,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return post(
        data,
        `${Url.SCHEDULING}${SequenceFolderPath.SEQUENCE_FOLDERS}`,
        requestConfig,
    );
};

export const showSequenceFolder = (
    id: number,
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(
        `${Url.SCHEDULING}${SequenceFolderPath.SEQUENCE_FOLDERS}/${id}`,
        requestConfig,
    );
};

export const updateSequenceFolderLegacy = (
    data: Type_sch_put_sequenceFolderLegacy,
    requestConfig: Type_RequestConfig,
): PutReturnType => {
    return put(
        data,
        `${Url.SCHEDULING}${SequenceFolderPath.SEQUENCE_FOLDERS}/${data.id}`,
        requestConfig,
    );
};

export const updateSequenceFolder = (
    data: Type_sch_put_sequenceFolder,
    id: number,
    requestConfig: Type_RequestConfig,
): PutReturnType => {
    return put(
        data,
        `${Url.SCHEDULING}${SequenceFolderPath.SEQUENCE_FOLDERS}/${id}`,
        requestConfig,
    );
};

export const deleteSequenceFolder = (
    id: number,
    requestConfig: Type_RequestConfig,
): RemoveReturnType => {
    return remove(
        { id },
        `${Url.SCHEDULING}${SequenceFolderPath.SEQUENCE_FOLDERS}/${id}`,
        requestConfig,
    );
};

export const treeSequenceFolders = (
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(
        `${Url.SCHEDULING}${SequenceFolderPath.SEQUENCE_FOLDERS_TREE}`,
        requestConfig,
    );
};
