import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    PutReturnType,
    remove,
    RemoveReturnType,
} from "src/api/fetch";
import { ResourcePath, Url } from "src/api/paths";
import {
    Type_com_post_resourceModel,
    Type_com_put_resourceModel,
} from "src/api/tms-commons/resourceModels/types";

export const indexResourceModels = (): GetReturnType => {
    return get(`${Url.COMMONS}${ResourcePath.RESOURCE_MODELS}`);
};

export const createResourceModel = (
    data: Type_com_post_resourceModel,
): PostReturnType => {
    return post(data, `${Url.COMMONS}${ResourcePath.RESOURCE_MODELS}`);
};

export const showResourceModel = (id: number): GetReturnType => {
    return get(`${Url.COMMONS}${ResourcePath.RESOURCE_MODELS}/${id}`);
};

export const updateResourceModel = (
    data: Type_com_put_resourceModel,
    id: number,
): PutReturnType => {
    return put(data, `${Url.COMMONS}${ResourcePath.RESOURCE_MODELS}/${id}`);
};

export const deleteResourceModel = (id: number): RemoveReturnType => {
    return remove(
        { id },
        `${Url.COMMONS}${ResourcePath.RESOURCE_MODELS}/${id}`,
    );
};

export const statusResourceModel = (
    data: Type_com_put_resourceModel,
    id: number,
): PutReturnType => {
    return put(data, `${Url.COMMONS}${ResourcePath.RESOURCE_MODELS}/${id}`);
};

export const selectListResourceModels = (): PutReturnType => {
    return get(`${Url.COMMONS}${ResourcePath.RESOURCE_MODELS_SELECT_LIST}`);
};
