import * as React from "react";
import { useCallback } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { Type_RequestConfig } from "src/api/fetch";
import { SubProjectKeys } from "src/api/tms-projects/keys";
import {
    formatterCreateSubProject,
    formatterIndexSubProject,
    formatterIndexSubProjects,
    formatterSelectListSubProjects,
    formatterShowSubProject,
    formatterShowSubProjectWithCurrentWorkspace,
    formatterUpdateDefaultSettings,
    formatterUpdateSubProject,
} from "src/api/tms-projects/subProjects/formatters";
import {
    createSubProject,
    currentWorkspaceSubProject,
    deleteSubProject,
    indexSubProjects,
    selectListSubProjects,
    showSubProject,
    statusSubProject,
    updateSubProject,
} from "src/api/tms-projects/subProjects/services";
import {
    Type_post_subProject,
    Type_put_default_subProject,
    Type_selectList_subProject,
    Type_show_subProject,
} from "src/api/tms-projects/subProjects/types";
import { useManageErrors } from "src/configurations/errorsLabels";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { setLocalStorageItem } from "src/utils/localStorageServices";

export const useIndexSubProjects = () => {
    const { requestConfig } = useProject();
    return useQuery({
        queryKey: [SubProjectKeys.INDEX, requestConfig],
        queryFn: () => indexSubProjects(requestConfig),
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Error format data: useIndexSubProjects");
            }

            return formatterIndexSubProjects(data.data.data);
        },
        enabled: !!requestConfig?.projectId,
        onError: (err) => {
            return err;
        },
    });
};

export const useSelectListSubProjects = () => {
    const { requestConfig } = useProject();
    return useQuery({
        queryKey: [SubProjectKeys.SELECT_LIST, requestConfig],
        queryFn: () => selectListSubProjects(requestConfig),
        refetchOnWindowFocus: false,
        select: useCallback((data: any): Type_selectList_subProject[] => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Error format data: useSelectListSubProjects");
            }
            return formatterSelectListSubProjects(data.data.data);
        }, []),
        enabled: !!requestConfig.projectId,
        staleTime: 1200000, // 2 minutes
        onError: (err) => {
            return err;
        },
    });
};

export const useShowSubProject = (
    projectId: number | undefined,
    subProjectId: number,
) => {
    return useQuery({
        queryKey: [SubProjectKeys.SHOW, projectId, subProjectId],
        queryFn: () =>
            showSubProject(subProjectId, { projectId, subProjectId }),
        enabled: !!projectId && !!subProjectId,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Error format data: useShowSubProject");
            }

            return formatterShowSubProject(data.data.data);
        },
    });
};

export const mutationCreateSubProject = () => {
    const queryClient = useQueryClient();
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Drawer.SubProject");
    const { addSuccess } = useToast();
    const { generateToastErrsFromBack } = useManageErrors();

    return useMutation({
        mutationFn: (newSubProject: Type_post_subProject) =>
            createSubProject(
                formatterCreateSubProject(newSubProject),
                requestConfig,
            ),
        onSuccess: async (data: any) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Error format data: mutationCreateSubProject");
            }

            await queryClient.invalidateQueries({
                queryKey: [SubProjectKeys.INDEX, requestConfig],
            });

            await queryClient.invalidateQueries({
                queryKey: [SubProjectKeys.SELECT_LIST, requestConfig],
            });

            const formattedData = formatterIndexSubProject(data.data.data);

            addSuccess({
                description: fmt("ToastSuccess", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        subProject: formattedData.name,
                    },
                }),
            });
        },
        onError: (err: any) => {
            generateToastErrsFromBack(err, false);
            return err;
        },
    });
};

export const mutationUpdateSubProject = () => {
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Drawer.SubProject");
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (subProjectToUpdate: Type_show_subProject) =>
            updateSubProject(
                formatterUpdateSubProject(subProjectToUpdate),
                requestConfig,
            ),
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Error format data: mutationUpdateSubProject");
            }

            const formattedData = formatterIndexSubProject(data.data.data);

            addSuccess({
                description: fmt("ToastSuccessUpdate", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        subProject: formattedData.name,
                    },
                }),
            });

            await queryClient.invalidateQueries({
                queryKey: [SubProjectKeys.INDEX, requestConfig],
            });

            await queryClient.invalidateQueries({
                queryKey: [SubProjectKeys.SELECT_LIST, requestConfig],
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationUpdateDefaultSettings = (
    projectId: number | undefined,
    subProjectId: number,
) => {
    const { requestConfig } = useProject();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (defaultSettingsToUpdate: Type_put_default_subProject) =>
            updateSubProject(
                formatterUpdateDefaultSettings(defaultSettingsToUpdate),
                requestConfig,
            ),
        onSuccess: async (data) => {
            if (!data?.success) {
                throw new Error(
                    "Error format data: mutationUpdateDefaultSettings",
                );
            }

            await queryClient.invalidateQueries({
                queryKey: [SubProjectKeys.SHOW, projectId, subProjectId],
            });
        },
    });
};

export const mutationStatusSubProject = () => {
    const { requestConfig } = useProject();
    const queryClient = useQueryClient();
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");

    return useMutation({
        mutationFn: (newStatus: any) => {
            return statusSubProject(
                {
                    enabled: newStatus.enabled,
                },
                newStatus.id as number,
                requestConfig,
            );
        },
        onSuccess: async (data: any) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Error format data: mutationStatusSubProject");
            }

            await queryClient.invalidateQueries({
                queryKey: [SubProjectKeys.INDEX, requestConfig],
            });

            await queryClient.invalidateQueries({
                queryKey: [SubProjectKeys.SELECT_LIST, requestConfig],
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const useDeleteSubProject = () => {
    const { requestConfig } = useProject();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (id: number) => deleteSubProject(id, requestConfig),
        onSuccess: async (data) => {
            if (!data?.success) {
                throw new Error("Error format data: mutationDeleteSubProject");
            }

            await queryClient.invalidateQueries({
                queryKey: [SubProjectKeys.INDEX, requestConfig],
            });

            await queryClient.invalidateQueries({
                queryKey: [SubProjectKeys.SELECT_LIST, requestConfig],
            });
        },
        onError: (err: any) => {
            console.error(err);
            return err;
        },
    });
};

export const useCurrentWorkspaceSubProject = (
    projectId: number,
    enabled: boolean,
    subProjectId?: number,
) => {
    const requestConfig: Type_RequestConfig = { projectId: projectId };
    if (subProjectId) {
        requestConfig.subProjectId = subProjectId;
    }
    return useQuery({
        queryKey: [SubProjectKeys.CURRENT_WORKSPACE, requestConfig],
        queryFn: () => currentWorkspaceSubProject(requestConfig),
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Error format data: useCurrentWorkspaceSubProject",
                );
            }
            const localStorageKey: string = `current-subProject-${projectId}`;

            setLocalStorageItem(localStorageKey, subProjectId);

            return formatterShowSubProjectWithCurrentWorkspace(data.data.data);
        },
        enabled: !!projectId && enabled,
    });
};
