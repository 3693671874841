import React, { useMemo, useState } from "react";

import { useSelectListCompanies } from "src/api/tms-commons/companies";
import { Type_put_project } from "src/api/tms-commons/projects/types";
import { useSelectListProjectTypes } from "src/api/tms-commons/projectTypes";
import { LoadingBox } from "src/components";

import { Tab_ProjectComponent } from "./Tab_Project.component";

export type Type_Tab_ProjectContainerProps = {
    onSubmit: any;
    data: any;
};

export const Tab_ProjectContainer = ({
    onSubmit,
    data: initialData,
}: Type_Tab_ProjectContainerProps) => {
    const { isFetching: isFetching_ProjectTypes, data: projectTypes } =
        useSelectListProjectTypes() || {};
    const { isFetching: isFetching_Companies, data: companies } =
        useSelectListCompanies() || {};

    /* *** GESTION DES ETATS *** */
    const [data] = useState<Type_put_project>(initialData);

    const isLoading = useMemo(() => false, []);

    const handleProjectName = (
        e: any,
        setFieldValue: (
            field: string,
            value: any,
            shouldValidate?: boolean | undefined,
        ) => void,
    ) => {
        setFieldValue("name", e.target.value);
    };

    const isFetching = useMemo(
        () => isFetching_Companies || isFetching_ProjectTypes,
        [isFetching_ProjectTypes, isFetching_Companies],
    );

    if (isFetching) return <LoadingBox />;
    return (
        <Tab_ProjectComponent
            initialName={initialData.name}
            onSubmit={onSubmit}
            data={data}
            isLoading={isLoading}
            handleProjectName={handleProjectName}
            projectTypes={projectTypes || []}
            companies={companies || []}
        />
    );
};
