import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Stack } from "@mui/material";
import * as React from "react";
import { Dispatch, SetStateAction, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";

import { useSelectListResourceFamilies } from "src/api/tms-commons/resourceFamilies";
import {
    mutationCreateResourceModel,
    mutationUpdateResourceModel,
    useShowResourceModel,
} from "src/api/tms-commons/resourceModels";
import {
    Type_post_resourceModel,
    Type_show_resourceModel,
} from "src/api/tms-commons/resourceModels/types";
import { useSelectListUnits } from "src/api/tms-commons/units";
import { Type_selectList } from "src/api/types";
import { LoadingBox } from "src/components";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { InputMultiLanguages } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages";
import { inputMultiLanguagesSchema } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages.component";
import {
    formatSelectListToSelectOptionItem,
    Select,
} from "src/components/Components_Common/forms/reactHookFormComponents/Select/Select";
import { useFormDefaultConfig } from "src/configurations/app";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_ResourceModelForm = {
    onClose: () => void;
    resourceModelIdToUpdate: number | null;
    action: Type_action;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
};

const Schema_ResourceModel = Yup.lazy(() => {
    return Yup.object().shape({
        names: Yup.object().shape(inputMultiLanguagesSchema.fields),
        resourceFamily: Yup.number().required(FORM_ERR_FMT.REQUIRED),
        unit: Yup.number().required(FORM_ERR_FMT.REQUIRED),
    });
});

export const ResourceModelForm = ({
    onClose,
    resourceModelIdToUpdate,
    action,
    setIsLoading,
}: Type_Props_ResourceModelForm) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.ResourceModel",
    );
    const { mutateAsync: mutateCreate } = mutationCreateResourceModel() || {};
    const { mutateAsync: mutateUpdate } = mutationUpdateResourceModel();

    const { isFetching: isFetchingResourceModel, data } = useShowResourceModel(
        resourceModelIdToUpdate as number,
    );

    const { isFetching: isFetchingResourceFamilies, data: resourceFamilies } =
        useSelectListResourceFamilies();

    const { isFetching: isFetchingUnits, data: units } = useSelectListUnits();

    const form = useForm<Type_show_resourceModel | Type_post_resourceModel>({
        ...useFormDefaultConfig,
        defaultValues: {
            id: undefined,
            names: {},
        },
        values: data,
        resolver: yupResolver<any>(Schema_ResourceModel),
    });

    const handleSubmit = async (
        values: Type_show_resourceModel | Type_post_resourceModel,
        e?: React.BaseSyntheticEvent,
    ) => {
        e?.preventDefault;

        setIsLoading(true);

        try {
            if (action === "update") {
                await mutateUpdate(values as Type_show_resourceModel);
            } else {
                await mutateCreate(values as Type_post_resourceModel);
            }

            // Fermeture du drawer
            onClose();
        } catch (e: any) {
            console.error("Error when creating or updating resource model");
        }

        setIsLoading(false);
    };

    const isFetching = useMemo(
        () =>
            !!(resourceModelIdToUpdate && isFetchingResourceModel) ||
            isFetchingResourceFamilies ||
            isFetchingUnits,
        [
            resourceModelIdToUpdate,
            isFetchingResourceModel,
            isFetchingResourceFamilies,
            isFetchingUnits,
        ],
    );

    return (
        <FormProvider {...form}>
            <form
                onSubmit={form.handleSubmit(handleSubmit)}
                id={"resourceModel"}
            >
                {isFetching ? (
                    <LoadingBox />
                ) : (
                    <Stack gap={6}>
                        <Box width={"100%"}>
                            <Select
                                name="resourceFamily"
                                options={
                                    resourceFamilies
                                        ? formatSelectListToSelectOptionItem(
                                              resourceFamilies as Type_selectList[],
                                          )
                                        : []
                                }
                                label={fmt("ResourceFamily")}
                                variant={"outlined"}
                                data-testid="ResourceModel-DrawerContent-resourceFamily"
                            />
                            <InputMultiLanguages
                                name="names"
                                label={fmt(`Name`)}
                            />
                        </Box>
                        <Select
                            name="unit"
                            options={
                                units
                                    ? formatSelectListToSelectOptionItem(
                                          units as Type_selectList[],
                                      )
                                    : []
                            }
                            label={fmt("Unit")}
                            variant={"outlined"}
                            data-testid="ResourceModel-DrawerContent-unit"
                        />
                    </Stack>
                )}
            </form>
        </FormProvider>
    );
};
