import { InputPhonePropsType } from "./InputPhone.container";

export type InputPhoneType = {
    type: "inputPhone";
    name: string;
    required?: boolean;
    label?: {
        label?: string;
        languageLabel?: string;
    };
    placeholder?: {
        label?: string;
        languageLabel?: string;
    };
};

export const InputPhoneComponent = ({
    name,
    label,
    onChange,
    placeholder,
    defaultValue,
}: InputPhonePropsType) => {
    return (
        <div className="flex items-center justify-end space-x-4">
            <label className="whitespace-nowrap block text-sm font-medium text-gray-700">
                {label}
            </label>
            <input
                type="tel"
                name={name}
                onChange={onChange}
                placeholder={placeholder}
                defaultValue={defaultValue}
                className="block w-96 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
        </div>
    );
};
