import { CardActionArea, Stack, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";

import { Type_index_project } from "src/api/tms-commons/projects/types";
import { useCurrentWorkspaceSubProject } from "src/api/tms-projects/subProjects";
import { TMC_Tooltip } from "src/components/Components_Common/_MuiComponentsVariants";
import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { DropdownMenu } from "src/components/Components_Common/DropdownMenu/DropdownMenu";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import {
    Styled_Card,
    Styled_CardChip,
    Styled_CardContent,
    Styled_CardMedia,
    Styled_CardStack,
} from "src/components/Components_Teamoty/cards/ProjectCard/ProjectCard.style";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { Type_modalDeleteProject } from "src/pages/Pages_Teamoty/Page_Projects/Page_Projects.container";
import { URL_TEAMOTY_PROJECT } from "src/router";
import { COLORS } from "src/theme/stylesheet";
import { getFromNow } from "src/utils/date";
import { getLocalStorageItem } from "src/utils/localStorageServices";

export type Type_ProjectCardContainerProps = {
    isFav: boolean;
    status:
        | "is_active"
        | "is_archived"
        | "is_disabled"
        | "is_expired"
        | "not_started";
    menuItems: any[];
    card: Type_index_project;
    img: string;
    addProjectToFavourites: (id: number) => void;
    deleteFavouriteProject: (id: number) => void;
    handleProjectStatus: (id: number, project: Type_index_project) => void;
    setModalDeleteProject?: Dispatch<SetStateAction<Type_modalDeleteProject>>;
    dataTestIdRef: "Favorites" | "List" | "Archived";
};

export const ProjectCard = ({
    isFav,
    menuItems,
    card,
    img,
    status,
    addProjectToFavourites,
    deleteFavouriteProject,
    setModalDeleteProject,
    handleProjectStatus,
    dataTestIdRef,
}: Type_ProjectCardContainerProps) => {
    const { formatMessageWithPartialKey: fmtCta } = useCoreIntl("Form.Cta");
    const navigate = useNavigate();
    const urlParams = useParams();

    if (status != "is_expired") {
        menuItems = [
            ...menuItems,
            {
                label: card.enabled ? fmtCta("Disable") : fmtCta("Enable"),
                call: () => handleProjectStatus(card.id, card),
            },
        ];
    }

    if (card.archived) {
        menuItems = [
            ...menuItems,
            {
                label: fmtCta("Delete"),
                call: (id: number) =>
                    setModalDeleteProject &&
                    setModalDeleteProject({ isOpen: true, id, card }),
            },
        ];
    }
    const localStorageKey: string = `current-subProject-${card.id}`;
    const lastConsultedSubProjectId: number =
        getLocalStorageItem(localStorageKey);

    const [callWorkspace, setCallWorkspace] = useState(false);
    const { isFetching, data } = useCurrentWorkspaceSubProject(
        card.id,
        callWorkspace,
        lastConsultedSubProjectId,
    );

    const handleClick = () => {
        setCallWorkspace(true);
    };

    useEffect(() => {
        if (!isFetching && data && callWorkspace) {
            // Check if project have a workspace
            if (data.currentWorkspace === null) {
                const url = `${URL_TEAMOTY_PROJECT}/:subProjectId/myteamoty/workspace`;
                navigate(
                    generatePath(url, {
                        ...urlParams,
                        projectSlug: card.slug,
                        subProjectId: data.id as unknown as string,
                    }),
                );
            } else if (data.currentWorkspace?.views.length) {
                // Redirect to workspace
                const url = `${URL_TEAMOTY_PROJECT}/:subProjectId/myteamoty/workspace/view/:view`;
                navigate(
                    generatePath(url, {
                        ...urlParams,
                        projectSlug: card.slug,
                        subProjectId: data.id as unknown as string,
                        view: data.currentWorkspace.views[0].id,
                    }),
                );
            }
        }
    }, [isFetching, data, callWorkspace]);

    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Projects.Card");

    const dataTestIdReference: string = `${dataTestIdRef}-${card.name}`;
    const dataTestIdCard: string = `Projects-Home-Cards-Card-${dataTestIdReference}`;

    return (
        <Styled_Card elevation={0} data-testid={dataTestIdCard}>
            {img && (
                <CardActionArea onClick={handleClick}>
                    <Styled_CardMedia
                        image={img}
                        title={card.name}
                        ownerState={{
                            status,
                        }}
                    />
                </CardActionArea>
            )}
            <Styled_CardContent>
                <Stack>
                    <Styled_CardStack>
                        <TMC_Tooltip title={card.name}>
                            {status === "is_disabled" ||
                            status === "is_expired" ? (
                                <Typography
                                    noWrap
                                    variant="body1Strike"
                                    color={COLORS.moon500}
                                >
                                    {card.name}
                                </Typography>
                            ) : (
                                <Typography noWrap variant="body1Bold">
                                    {card.name}
                                </Typography>
                            )}
                        </TMC_Tooltip>
                        <IconButton
                            size="small"
                            onClick={() =>
                                isFav
                                    ? deleteFavouriteProject(
                                          card.favourite_id as number,
                                      )
                                    : addProjectToFavourites(card.id)
                            }
                        >
                            {isFav ? (
                                <Icon
                                    variant="solid"
                                    icon="star"
                                    sx={{ color: COLORS.moon500 }}
                                />
                            ) : (
                                <Icon variant="light" icon="star" />
                            )}
                        </IconButton>
                    </Styled_CardStack>

                    {card.archived && (
                        <Stack
                            direction="row"
                            spacing={"4px"}
                            alignItems="center"
                        >
                            <Icon
                                variant="solid"
                                icon="box
                            -archive"
                                sx={{ color: COLORS.moon600 }}
                            />
                            <Typography variant="body3" color={COLORS.moon600}>
                                {fmt("Archived")}
                            </Typography>
                        </Stack>
                    )}

                    {status === "is_disabled" && (
                        <Stack
                            direction="row"
                            spacing={"4px"}
                            alignItems="center"
                        >
                            <Icon
                                variant="solid"
                                icon="circle-dashed"
                                sx={{ color: COLORS.moon600 }}
                            />
                            <Typography variant="body3" color={COLORS.moon600}>
                                {fmt("Inactive")}
                            </Typography>
                        </Stack>
                    )}

                    {status === "not_started" && (
                        <Stack
                            direction="row"
                            spacing={"4px"}
                            alignItems="center"
                        >
                            <Typography variant="body3" color={COLORS.moon600}>
                                not started
                            </Typography>
                        </Stack>
                    )}

                    {status === "is_expired" && (
                        <Stack direction="row">
                            <Styled_CardChip
                                label={
                                    <Stack
                                        direction="row"
                                        spacing={"4px"}
                                        alignItems="center"
                                    >
                                        <Icon
                                            variant="solid"
                                            icon="triangle-exclamation"
                                            sx={{ color: COLORS.moon900 }}
                                        />
                                        <Typography variant="body3">
                                            {fmt("Expired")}
                                        </Typography>
                                    </Stack>
                                }
                            />
                        </Stack>
                    )}
                </Stack>
                <Styled_CardStack>
                    <Typography noWrap variant="body3" color={COLORS.moon500}>
                        {card.archived
                            ? fmt("ArchivedSince") +
                              " " +
                              `${getFromNow(card.archivedDate) || ""}`
                            : fmt("LastUpdate") +
                              " " +
                              `${getFromNow(card.lastUpdate) || ""}`}
                    </Typography>
                    <DropdownMenu
                        menuItems={menuItems}
                        id={dataTestIdReference}
                    />
                </Styled_CardStack>
            </Styled_CardContent>
        </Styled_Card>
    );
};
