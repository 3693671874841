import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    PutReturnType,
    remove,
    RemoveReturnType,
    Type_RequestConfig,
} from "src/api/fetch";
import { getFilterJoin, Type_Filter_Task } from "src/api/filters";
import { TasksPath, Url } from "src/api/paths";
import {
    Type_sch_post_taskNote,
    Type_sch_put_taskNote,
} from "src/api/tms-scheduling/taskNotes/types";

export const indexTaskNotes = (
    requestConfig: Type_RequestConfig,
    filters: Type_Filter_Task,
): GetReturnType => {
    const urlParams: string = getFilterJoin(filters);

    return get(
        `${Url.SCHEDULING}${TasksPath.TASK_NOTES}${urlParams}`,
        requestConfig,
    );
};

export const createTaskNote = (
    data: Type_sch_post_taskNote,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return post(
        data,
        `${Url.SCHEDULING}${TasksPath.TASK_NOTES}`,
        requestConfig,
    );
};

export const updateTaskNote = (
    id: number,
    data: Type_sch_put_taskNote,
    requestConfig: Type_RequestConfig,
): PutReturnType => {
    return put(
        data,
        `${Url.SCHEDULING}${TasksPath.TASK_NOTES}/${id}`,
        requestConfig,
    );
};

export const deleteTaskNote = (
    id: number,
    requestConfig: Type_RequestConfig,
): RemoveReturnType => {
    return remove(
        {},
        `${Url.SCHEDULING}${TasksPath.TASK_NOTES}/${id}`,
        requestConfig,
    );
};
