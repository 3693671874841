import { Backdrop, Box, Typography } from "@mui/material";
import React, { useEffect } from "react";

import { refreshCurrentUser } from "src/api/tms-gateway/auth";
import { Spinner } from "src/components";
import { AppSwitcherProvider } from "src/contexts/appSwitcher";
import { DrawerProvider } from "src/contexts/drawer";
import { ProjectProvider } from "src/contexts/project";
import { useUser } from "src/contexts/user";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type ProtectedRoutesPropsType = {
    children: React.ReactElement;
};

export const ProtectedRoutes = ({ children }: ProtectedRoutesPropsType) => {
    // hooks
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");
    const { setUser } = useUser();

    // api
    const { data: user, isLoading, isSuccess } = refreshCurrentUser();

    // State manager
    useEffect(() => {
        if (user && isSuccess) {
            setUser(user);
        }
    }, [isSuccess, user]);

    return (
        <>
            {isLoading && (
                <Backdrop open={true}>
                    <Spinner size="large" />
                </Backdrop>
            )}
            {!isLoading && !isSuccess && (
                <Box
                    sx={{
                        width: "100%",
                        height: "100vh",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Typography variant="h1">
                        {fmtErrors("ProtectedRoutesError")}
                    </Typography>
                </Box>
            )}
            {user && (
                <ProjectProvider>
                    <DrawerProvider>
                        <AppSwitcherProvider>{children}</AppSwitcherProvider>
                    </DrawerProvider>
                </ProjectProvider>
            )}
        </>
    );
};
