import { FormikValues } from "formik";

import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    PutReturnType,
    remove,
    RemoveReturnType,
    Type_RequestConfig,
} from "src/api/fetch";
import { Url, Version } from "src/api/paths";
import { formatDateTimeForBack } from "src/utils/date";

export const indexVersionNotes = (
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(`${Url.PROJECTS}${Version.NOTES}`, requestConfig);
};

export const selectListVersionNotes = (
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(`${Url.PROJECTS}${Version.NOTES_SELECT_LIST}`, requestConfig);
};

export const createVersionNote = (
    data: FormikValues,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    // TODO add formatter
    return post(
        {
            ...data,
            date: formatDateTimeForBack(new Date()),
        },
        `${Url.PROJECTS}${Version.NOTES}`,
        requestConfig,
    );
};

export const showVersionNote = (
    id: number,
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(`${Url.PROJECTS}${Version.NOTES}/${id}`, requestConfig);
};

export const updateVersionNote = (
    data: any,
    requestConfig: Type_RequestConfig,
): PutReturnType => {
    return put(
        data,
        `${Url.PROJECTS}${Version.NOTES}/${data.id}`,
        requestConfig,
    );
};

export const deleteVersionNote = (
    id: number,
    requestConfig: Type_RequestConfig,
): RemoveReturnType => {
    return remove(
        { id },
        `${Url.PROJECTS}${Version.NOTES}/${id}`,
        requestConfig,
    );
};
