import { FormikHelpers } from "formik";
import { useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";

import { getCsrfToken } from "src/api/fetch";
import { mutationLogin } from "src/api/tms-gateway/auth";
import { mutationCheckCGU } from "src/api/tms-users/auth";
import { URL_BASE } from "src/router";
import { getLocalStorageItem } from "src/utils/localStorageServices";

import { Page_Identification } from "./Page_Identification.component";

export type Type_loginState = {
    email: string;
    password: string;
    rememberMe: boolean;
    success: string;
};

export const Page_IdentificationContainer = () => {
    const navigate = useNavigate();
    const urlParams = useParams();

    const [state] = useState<Type_loginState>({
        email: "",
        password: "",
        rememberMe: false,
        success: "",
    });

    const { mutateAsync: login } = mutationLogin();
    const { mutateAsync: checkCGU } = mutationCheckCGU();

    const handleSubmit = async (
        values: Type_loginState,
        { setErrors }: FormikHelpers<Type_loginState>,
    ) => {
        try {
            await getCsrfToken();
            await login(values);
            try {
                await checkCGU();
                navigate(
                    generatePath(`${URL_BASE}/projects`, { ...urlParams }),
                    { replace: true },
                );
            } catch (e) {
                const lang = getLocalStorageItem("language");
                navigate(`/${lang}/cgu`, { replace: true });
            }
        } catch (e) {
            setErrors({ success: "error" });
        }
    };

    return <Page_Identification state={state} handleSubmit={handleSubmit} />;
};
