import { useCallback, useEffect, useMemo } from "react";

import { Type_index_constraint } from "src/api/tms-scheduling/constraints/types";
import { Type_index_peopleTask } from "src/api/tms-scheduling/peopleTask/types";
import { Type_index_peopleTaskArea } from "src/api/tms-scheduling/peopleTaskArea/types";
import { Type_index_resourceTasks } from "src/api/tms-scheduling/resourceTask/types";
import {
    Type_index_taskLink,
    Type_show_taskLink,
} from "src/api/tms-scheduling/taskLinks/types";
import { Type_index_productTypeTasks } from "src/api/tms-scheduling/taskProductTypes/types";
import { Type_MenuItem_DropDownRightClick } from "src/components/Components_Common/dropdownMenuRightClick/DropDownMenuRightClick";
import { Type_PlanningFilters } from "src/drawers/planningFilters/PlanningFiltersForm";
import { Type_PlanningSettings } from "src/drawers/planningSettings/PlanningSettingsForm";

export type Type_event_task = {
    id: number;
    name?: string;
    color?: string;
    quantity?: number;
    duration?: number;
    team?: number;
    workforce?: number;
};

export type Type_event_area = {
    id: number;
    name?: string;
    color?: string;
    parentArea?: {
        id: number;
        name: string;
    } | null;
};

export type Type_event_sequence = {
    id: number;
    name?: string;
    color?: string;
    sequenceFolder?: {
        id: number;
        name: string;
        enabled: boolean;
    };
    optimizeByArea?: boolean;
    fixedSize: boolean;
};

export type Type_event_sequenceFolderTree = {
    id: number;
    label: string;
    itemId: string;
    itemType: "folder";
    children: [];
};

export type Type_event_sequenceTree = {
    id: number;
    number: number;
    label: string;
    code: string;
    color: string;
    itemId: string;
    itemType: string;
    children: [];
};

export type Type_event_pert = {
    id: number;
    open: boolean;
};

export type Type_event_dialog = {
    open: boolean;
    action?: "create" | "update";
    data?: any;
};

export type Type_event_dropDownOnRightClick = {
    open: boolean;
    mouseX: number;
    mouseY: number;
    menuItems: Type_MenuItem_DropDownRightClick[];
    props?: any;
};

export type Type_event_idsTaskArea = {
    taskId: number;
    areaId: number;
};

export type Type_event_constraint = Type_index_constraint | number;

export type Type_event_link = Type_show_taskLink | Type_index_taskLink;
export type Type_event_resourceTasks = Type_index_resourceTasks[];
export type Type_event_productTypesTasks = Type_index_productTypeTasks[];
export type Type_event_peopleTasks = Type_index_peopleTask[];
export type Type_event_peopleTaskArea = Type_index_peopleTaskArea[];
export type Type_event_planningSettings = Type_PlanningSettings;
export type Type_event_planningFilters = Type_PlanningFilters;

type Type_event =
    | Type_event_task
    | Type_event_area
    | Type_event_sequence
    | Type_event_link
    | Type_event_resourceTasks
    | Type_event_sequenceFolderTree
    | Type_event_pert
    | Type_event_productTypesTasks
    | Type_event_dialog
    | Type_event_peopleTasks
    | Type_event_peopleTaskArea
    | Type_event_dropDownOnRightClick
    | Type_event_planningSettings
    | Type_event_idsTaskArea
    | Type_event_constraint
    | Type_event_planningFilters;

export type Type_event_message = {
    event: string;
    data?: Type_event;
};

export type Type_callback_sendMessage = (
    event: string,
    data?: Type_event,
) => void;

export const useChannel = ({
    channelName = "defaultChannel",
    eventHandler,
}: {
    channelName?: string;
    eventHandler?: (event: Type_event_message) => void;
}): { sendEvent: Type_callback_sendMessage } => {
    const channel: BroadcastChannel = useMemo(
        () => new BroadcastChannel(channelName),
        [channelName],
    );

    const sendEvent = useCallback(
        (event: string, data?: Type_event): void => {
            channel.postMessage({ event: event, data: data });
        },
        [channel],
    );

    useEffect(() => {
        const messageHandlerLister = (
            message: MessageEvent<Type_event_message>,
        ): void => {
            eventHandler && eventHandler(message.data);
        };

        channel.addEventListener("message", messageHandlerLister);

        return (): void => {
            channel.removeEventListener("message", messageHandlerLister);
        };
    }, [channel, eventHandler]);

    return {
        sendEvent,
    };
};
