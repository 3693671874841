import { useMutation, useQuery } from "react-query";

import { Type_Filter_Sequence } from "src/api/filters";
import { TaskKeys } from "src/api/tms-scheduling/keys";
import {
    formatterShowTask,
    formatterUpdateTask,
} from "src/api/tms-scheduling/tasks/formatters";
import {
    createTask,
    indexTasks,
    removeTask,
    showTask,
    updateTask,
} from "src/api/tms-scheduling/tasks/services";
import {
    Type_put_task,
    Type_sch_index_task,
    Type_sch_post_task,
    Type_show_task,
} from "src/api/tms-scheduling/tasks/types";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const useIndexTasks = (
    callback: any,
    filters: Type_Filter_Sequence = {},
    enabled: boolean = true,
    uniqueId?: string,
) => {
    const { requestConfig } = useProject();
    return useQuery({
        queryKey: [TaskKeys.INDEX, requestConfig, filters, uniqueId],
        queryFn: () => indexTasks(requestConfig, filters),
        refetchOnWindowFocus: false,
        onSuccess: (data): void => {
            callback(data.data.data as Type_sch_index_task[]);
        },
        enabled: !!requestConfig.projectId && enabled,
    });
};

export const useShowTask = (id: number) => {
    const { requestConfig } = useProject();
    return useQuery({
        queryKey: [TaskKeys.SHOW, id, requestConfig],
        queryFn: () => showTask(id, requestConfig),
        refetchOnWindowFocus: false,
        select: (data): Type_show_task => {
            return formatterShowTask(data?.data?.data);
        },
        enabled: !!requestConfig.projectId && !!id,
    });
};

type Type_Props_mutationCreateTask = {
    onSuccess?: () => void | Promise<void>;
};

export const mutationCreateTask = (
    props: Type_Props_mutationCreateTask = {},
) => {
    const { onSuccess = () => {} } = props;
    const { requestConfig } = useProject();
    const { add } = useToast();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Errors");

    return useMutation({
        mutationFn: (data: Type_sch_post_task) => {
            return createTask(data, requestConfig);
        },
        onSuccess: onSuccess,
        onError: (err: any): void => {
            console.debug("ERROR mutationCreateTask", err);
            add({
                type: "warning",
                duration: 4000,
                description: fmt("GenericError", {}),
            });
            return err;
        },
    });
};

export const mutationUpdateTask = () => {
    const { requestConfig } = useProject();
    const { add } = useToast();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");

    return useMutation({
        mutationFn: (task: Type_put_task) => {
            return updateTask(formatterUpdateTask(task), requestConfig);
        },
        onError: (err: any) => {
            console.debug("ERROR mutationUpdateTask", err);
            add({
                type: "warning",
                duration: 4000,
                description: fmtErrors("GenericError", {}),
            });
            return err;
        },
    });
};

export const mutationDeleteTask = () => {
    const { formatMessageWithPartialKey: fmtError } = useCoreIntl("Errors");
    const { requestConfig } = useProject();
    return useMutation({
        mutationFn: (id: number) => removeTask(id, requestConfig),
        onSuccess: (_data, id) => {
            id;
        },
        onError: (err: any) => {
            console.error(fmtError("GenericError", {}), err);

            return err;
        },
    });
};
