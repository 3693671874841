import { FormikErrors } from "formik";
import { useEffect, useState } from "react";
import { ErrorsType, ImageListType } from "react-images-uploading";

import { getImage } from "src/api/storage";
import { UploadImageComponent_Deprecated } from "src/components/Components_Common/UploadImage/UploadImage_Deprecated.component";

export type Type_UploadImageContainerProps = {
    name: string;
    value: ImageListType | string;
    handleChange: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined,
    ) => void;
    limitResolutions?: {
        min?: number;
        max?: number;
    };
    withDescription?: boolean;
    isRequired?:
        | string
        | string[]
        | FormikErrors<any>
        | FormikErrors<any>[]
        | undefined;
    host: string;
    service: string;
    id?: number | undefined;
};

/**
 * @deprecated
 * @param props
 * @constructor
 */
export const UploadImageContainer_Deprecated = (
    props: Type_UploadImageContainerProps,
) => {
    const [errors, setErrors] = useState<ErrorsType>(null);
    const [img, setImg] = useState("");

    const onChange = (imageList: ImageListType) => {
        if (errors) {
            console.error(errors);
            setErrors(null);
        }
        props.handleChange(props.name, imageList); // setFieldValue from Formik
    };

    useEffect(() => {
        const fetchImg = async () => {
            if (props.id && img === "") {
                let cacheTimestamp = "";
                if (typeof props.value === "string") {
                    cacheTimestamp = props.value.slice(props.value.length - 14);
                }

                const res = await getImage(
                    props.id,
                    props.host,
                    props.service,
                    "thumbnail",
                    cacheTimestamp,
                );
                const imageObjectURL = URL.createObjectURL(res.data);
                setImg(imageObjectURL);
            }
        };
        fetchImg();
    }, []);

    const deleteDefaultImg = () => {
        setImg("");
        onChange([]);
    };

    return (
        <UploadImageComponent_Deprecated
            {...props}
            onChange={onChange}
            errors={errors}
            setErrors={setErrors}
            isRequired={!!props.isRequired}
            img={img}
            deleteDefaultImg={deleteDefaultImg}
        />
    );
};
