import Konva from "konva";
import React, {
    Dispatch,
    RefObject,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from "react";
import { Group, Layer, Line, Tag } from "react-konva";
import { RectReadOnly } from "react-use-measure";

import { Type_point } from "src/components/Components_Common/canvas/types";
import { useChannel } from "src/hooks/useChannel";
import { getNewValues, getObjectDifferences } from "src/utils/object";

import {
    colorFlowPlus,
    doubleClick,
    keyFlowEdit,
    keyFlowTrash,
    sizeFlowPlus,
    strokeFlowPlus,
} from "./Flow.const";
import { Enum_typeFlowLink } from "./Flow.enum";
import {
    Type_changeFlowStage,
    Type_flow,
    Type_flowLink,
    Type_flowTask,
    Type_Props_Link,
    Type_State_FlowNewLink,
    Type_State_FlowSelected,
    Type_State_FlowStage,
} from "./Flow.type";
import { FlowGrid } from "./Grid/FlowGrid";
import { FlowLink } from "./Link/FlowLink";
import { FlowShape } from "./Shape/FlowShape";
import { FlowStage } from "./Stage/FlowStage";
import { findTypeFlow } from "./tools/findTypeFlow";
import { onGrid } from "./tools/onGrid";
import { replaceTasks } from "./tools/replaceTasks";

export enum TypeActions {
    EDIT = "EDIT",
    ADD = "ADD",
    DELETE = "DELETE",
    OPEN_DRAWER = "OPEN_DRAWER",
}

export type Type_FlowView = {
    allTasks: Type_flow;
    bounds: RectReadOnly;
    refView: RefObject<Konva.Stage>;
    stageFlow: Type_State_FlowStage;
    setStageFlow: Dispatch<SetStateAction<Type_State_FlowStage>>;
    changeStageFlow: (change: Type_changeFlowStage) => void;
    onTaskChange: (
        type: TypeActions,
        props?: any | { id: number },
    ) => Promise<any>;
    onLinkChange: (
        type: TypeActions,
        props?: Type_Props_Link | { id: number },
    ) => Promise<any>;
    fixedSize?: boolean;
};

export const FlowView = ({
    allTasks,
    bounds,
    refView,
    stageFlow,
    changeStageFlow,
    onTaskChange,
    onLinkChange,
    fixedSize,
}: Type_FlowView) => {
    const addTask = async (pt: [number, number]) =>
        await onTaskChange(TypeActions.ADD, pt);
    const [isOpenDrawer, setIsOpenDrawer] = useState<number | null>(null);
    const addLink = async (
        taskFrom: number,
        taskTo: number,
        type: Enum_typeFlowLink,
    ) => {
        return await onLinkChange(TypeActions.ADD, {
            taskFrom,
            taskTo,
            type,
        });
    };

    const removeLink = async (id: number) => {
        return await onLinkChange(TypeActions.DELETE, {
            id,
        });
    };

    const removeTask = async (id: number) => {
        return await onTaskChange(TypeActions.DELETE, {
            id,
        });
    };

    const [pool, changePool] = useState<any[]>([]);

    const organizeTasks = (autoSort: boolean = false) => {
        if (autoSort) {
            replaceTasks(allTasks);
        }
        allTasks.tasks.map((t: Type_flowTask) => {
            t.pt = onGrid(t.pt);
        });
        return allTasks;
    };

    const [tasks, setTasks] = useState<Type_flow>(() => organizeTasks());

    const [newLink, setNewLink] = useState<Type_State_FlowNewLink>({
        start: false,
    });
    const [selected, setSelected] = useState<Type_State_FlowSelected>({
        selected: false,
    });

    const findPoint = useCallback(
        (id: number): Type_flowTask => {
            return tasks.tasks.find(
                (c: Type_flowTask) => c.id === id,
            ) as Type_flowTask;
        },
        [tasks],
    );

    const changeTask = useCallback(
        (
            task: Type_flowTask,
            index: number,
            newValue?: Type_flowTask,
        ): void => {
            const currentTask = tasks.tasks[index];
            const diff = getObjectDifferences(currentTask, task);
            if (newValue || Object.keys(diff).length > 0) {
                onTaskChange(TypeActions.EDIT, {
                    id: task.id,
                    ...getNewValues(diff),
                    ...newValue,
                });
            }
            setTasks((prev: Type_flow) => {
                prev.tasks[index] = task;
                return { ...prev };
            });
        },
        [tasks],
    );

    const changeSelected = useCallback(
        (change: Type_State_FlowSelected): void => {
            setSelected(
                (prev: Type_State_FlowSelected): Type_State_FlowSelected => ({
                    ...prev,
                    ...{
                        task: undefined,
                        link: undefined,
                    },
                    ...change,
                }),
            );
        },
        [selected],
    );

    const changeNewLink = useCallback(
        (change: Type_State_FlowNewLink): void => {
            changeSelected({ selected: false });
            setNewLink((prev) => {
                change?.taskTo &&
                    prev?.taskFrom &&
                    prev?.typeFrom &&
                    addNewLink(
                        prev.taskFrom,
                        change.taskTo,
                        findTypeFlow(prev.typeFrom, change.typeTo),
                        prev.posNewTask,
                    );
                return { ...prev, ...change };
            });
        },
        [],
    );

    const addNewLink = useCallback(
        (
            taskFrom: number,
            taskTo: number,
            type: Enum_typeFlowLink,
            posNewTask?: Type_point,
        ): void => {
            if (taskFrom < 0) return;

            const linkAlreadyExist = tasks.links.find(
                (c: Type_flowLink) =>
                    c.taskFrom === taskFrom && c.taskTo === taskTo,
            );

            if (linkAlreadyExist) return;

            changePool((prev) => {
                prev.push({
                    mode: "addNewLink",
                    taskFrom: taskFrom,
                    taskTo: taskTo,
                    type: type,
                    posNewTask: posNewTask,
                });
                return [...prev];
            });
        },
        [tasks],
    );

    const removeNewLink = useCallback(
        (link: Type_flowLink): void => {
            const index: number = tasks.links.findIndex(
                (c: Type_flowLink) => c.id === link.id,
            );
            if (index === -1) {
                return;
            }
            changePool((prev) => {
                prev.push({ mode: "removeNewLink", id: link.id });
                return [...prev];
            });
        },
        [tasks],
    );

    const removeNewTask = useCallback(
        (task: Type_flowTask): void => {
            const index: number = tasks.tasks.findIndex(
                (c: Type_flowTask) => c.id === task.id,
            );
            if (index === -1) {
                return;
            }
            changePool((prev) => {
                prev.push({ mode: "removeNewTask", id: task.id });
                return [...prev];
            });
        },
        [tasks],
    );

    useEffect(() => {
        const poolUpdate = async () => {
            const fifo: any = pool[0];

            if (fifo.mode === "addNewLink") {
                if (fifo.taskTo < 0) {
                    const newTask = await addTask([
                        fifo.posNewTask.x as number,
                        fifo.posNewTask.y as number,
                    ]);
                    fifo.taskTo = newTask.id;
                    setTasks((prevTasks: Type_flow) => {
                        prevTasks.tasks.push({
                            id: newTask.id,
                            type: newTask.type,
                            color: newTask.color || "#fff",
                            name: newTask.name,
                            duration: newTask.duration,
                            areaQuantity: newTask.areaQuantity,
                            team: newTask.team,
                            pt: fifo.posNewTask, // TODO: update with real x,y data
                        });
                        return prevTasks;
                    });
                }

                // If link already exist between two task dont do anything
                const linkAreadyExist = tasks.links.find(
                    (c: Type_flowLink) =>
                        c.taskFrom === fifo.taskFrom &&
                        c.taskTo === fifo.taskTo,
                );

                if (!linkAreadyExist) {
                    const newLink = await addLink(
                        fifo.taskFrom,
                        fifo.taskTo,
                        fifo.type,
                    );
                    tasks.links.push(newLink);
                }
            }

            if (fifo.mode === "removeNewLink") {
                removeLink(fifo.id).then(() => {
                    setTasks((prevTasks: Type_flow) => {
                        const index: number = prevTasks.links.findIndex(
                            (c: Type_flowLink) => c.id === fifo.id,
                        );
                        if (index === -1) {
                            return prevTasks;
                        }
                        prevTasks.links.splice(index, 1);

                        return { ...prevTasks };
                    });
                });
            }

            if (fifo.mode === "removeNewTask") {
                removeTask(fifo.id).then(async () => {
                    setTasks((prevTasks: Type_flow) => {
                        const index: number = prevTasks.tasks.findIndex(
                            (c: Type_flowTask) => c.id === fifo.id,
                        );
                        if (index === -1) {
                            return prevTasks;
                        }

                        prevTasks.tasks.splice(index, 1);

                        return { ...prevTasks };
                    });

                    const links = tasks.links.filter(
                        (c: Type_flowLink) =>
                            c.taskFrom === fifo.id || c.taskTo === fifo.id,
                    );

                    for (const link of links) {
                        const index: number = tasks.links.findIndex(
                            (c: Type_flowLink) => c.id === link.id,
                        );
                        if (index > -1) {
                            await removeLink(tasks.links[index].id);
                            setTasks((prev) => {
                                prev.links.splice(index, 1);
                                return { ...prev };
                            });
                        }
                    }
                });
            }

            changePool([]);
        };
        if (pool.length > 0) {
            poolUpdate();
        }
    }, [pool]);

    const [event, sendEvent] = useState<string | null>("");

    useEffect((): void => {
        if (event === keyFlowTrash) {
            const link: Type_flowLink | undefined = selected.link;
            if (link !== undefined) {
                removeNewLink(link);
            }

            const task: Type_flowTask | undefined = selected.task;
            if (task !== undefined) {
                removeNewTask(task);
            }
        }
        if ((event === doubleClick || event === keyFlowEdit) && selected.task) {
            onTaskChange(TypeActions.OPEN_DRAWER, {
                id: selected.task.id,
            });
            setIsOpenDrawer(selected.task.id);
        }
        sendEvent(null);
    }, [event]);

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            e.preventDefault();
            sendEvent(e.key);
        };

        if (
            selected.task &&
            isOpenDrawer &&
            isOpenDrawer !== selected.task.id
        ) {
            onTaskChange(TypeActions.OPEN_DRAWER, {
                id: selected.task.id,
            });
            setIsOpenDrawer(selected.task.id);
        }

        // Listen to event
        if (refView?.current) {
            const container: HTMLDivElement = refView.current.container();

            container.tabIndex = 1;
            container.focus();

            container.addEventListener("keydown", handleKeyDown);
        }

        // remove listener
        return (): void => {
            if (refView?.current) {
                const container: HTMLDivElement = refView.current.container();

                container.tabIndex = 1;
                container.focus();

                container.removeEventListener("keydown", handleKeyDown);
                sendEvent(null);
            }
        };
    }, [selected]);

    useChannel({
        eventHandler: async ({ event }) => {
            if (event === "sortTask") {
                setTasks(organizeTasks(true));
                for (const task of allTasks.tasks) {
                    await onTaskChange(TypeActions.EDIT, {
                        id: task.id,
                        xy: [task.pt.x, task.pt.y],
                    });
                }
            }
        },
    });

    useEffect(() => {
        setTasks(organizeTasks());
    }, [allTasks]);

    return (
        <FlowStage
            refStage={refView}
            bounds={bounds}
            stageFlow={stageFlow}
            changeStageFlow={changeStageFlow}
            changeSelected={changeSelected}
            dblClick={() => sendEvent("dbClick")}
        >
            <FlowGrid bounds={bounds} stageFlow={stageFlow} />
            <Layer name="flow">
                {tasks.tasks.map((task: Type_flowTask, index: number) => (
                    <FlowShape
                        refStage={refView}
                        key={"task-" + index}
                        task={task}
                        index={index}
                        stageFlow={stageFlow}
                        changeTask={changeTask}
                        changeNewLink={changeNewLink}
                        selected={selected}
                        changeSelected={changeSelected}
                        fixedSize={fixedSize}
                    />
                ))}

                {tasks.links.map((link: Type_flowLink, index: number) => {
                    const taskFrom = findPoint(link.taskFrom);
                    const taskTo = findPoint(link.taskTo);
                    return (
                        taskFrom &&
                        taskTo && (
                            <FlowLink
                                key={"link-" + index}
                                index={index}
                                link={link}
                                taskFrom={findPoint(link.taskFrom)}
                                taskTo={findPoint(link.taskTo)}
                                selected={selected}
                                changeSelected={changeSelected}
                                fixedSize={fixedSize}
                            />
                        )
                    );
                })}

                {newLink.start && newLink.ptFrom && newLink.ptTo && (
                    <Group>
                        <Line
                            x={0}
                            y={0}
                            points={[
                                newLink.ptFrom.x,
                                newLink.ptFrom.y,
                                newLink.ptTo.x,
                                newLink.ptTo.y,
                            ]}
                            stroke="black"
                        />
                        {newLink.withNewTask && (
                            <Group x={newLink.ptTo.x} y={newLink.ptTo.y}>
                                <Tag
                                    offsetX={sizeFlowPlus / 2}
                                    offsetY={sizeFlowPlus / 2}
                                    width={sizeFlowPlus}
                                    height={sizeFlowPlus}
                                    cornerRadius={strokeFlowPlus * 2}
                                    strokeWidth={strokeFlowPlus}
                                    stroke={colorFlowPlus}
                                    fill={"white"}
                                />
                                <Line
                                    points={[
                                        -sizeFlowPlus / 2 + strokeFlowPlus,
                                        0,
                                        sizeFlowPlus / 2 - strokeFlowPlus,
                                        0,
                                    ]}
                                    stroke={colorFlowPlus}
                                    strokeWidth={1}
                                />
                                <Line
                                    points={[
                                        0,
                                        -sizeFlowPlus / 2 + strokeFlowPlus,
                                        0,
                                        sizeFlowPlus / 2 - strokeFlowPlus,
                                    ]}
                                    stroke={colorFlowPlus}
                                    strokeWidth={1}
                                />
                            </Group>
                        )}
                    </Group>
                )}

                <Group name="top" />
            </Layer>
        </FlowStage>
    );
};
