import { Stack, Typography } from "@mui/material";
import React, { ReactElement } from "react";

import { Type_index_sequenceFolderTree_task } from "src/api/tms-scheduling/sequenceFolders/types";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { invertColor } from "src/utils/colors";

type Type_taskIcon = {
    [key: number]: ReactElement;
};

const taskIcon: Type_taskIcon = {
    1: <Icon variant={"kit"} icon={"light-tds-task-work"} fontSize={"large"} />,
    2: (
        <Icon
            variant={"kit"}
            icon={"light-tds-task-study"}
            fontSize={"large"}
        />
    ),
    3: (
        <Icon
            variant={"kit"}
            icon={"light-tds-task-waiting"}
            fontSize={"large"}
        />
    ),
    4: (
        <Icon
            variant={"kit"}
            icon={"light-tds-task-milestone"}
            fontSize={"large"}
        />
    ),
    5: (
        <Icon
            variant={"kit"}
            icon={"light-tds-task-delivery"}
            fontSize={"large"}
        />
    ),
    6: (
        <Icon
            variant={"kit"}
            icon={"light-tds-task-technical"}
            fontSize={"large"}
        />
    ),
};

type Type_TaskBadge = {
    task: Type_index_sequenceFolderTree_task;
};

export const TaskBadge = ({ task }: Type_TaskBadge) => {
    return (
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Stack
                bgcolor={task.color}
                boxShadow={"0px 1px 1px 0px rgba(29, 56, 99, 0.32)"}
                borderRadius={0.5}
                color={invertColor(task.color)}
                direction={"row"}
                alignItems={"center"}
            >
                {taskIcon[task.type]}
                {task.code && (
                    <Typography
                        variant={"body2"}
                        color={invertColor(task.color)}
                        paddingInlineEnd={1.5}
                    >
                        {task.code}
                    </Typography>
                )}
            </Stack>
            <Typography variant={"body2"}>{task.label}</Typography>
        </Stack>
    );
};
