import {
    Autocomplete,
    Checkbox,
    Divider,
    Stack,
    Typography,
} from "@mui/material";
import { Formik } from "formik";
import React from "react";
import * as Yup from "yup";

import { ProjectPath, Url } from "src/api/paths";
import { Type_selectList_company } from "src/api/tms-commons/companies/types";
import { Type_selectList } from "src/api/types";
import {
    Spinner,
    TMC_Button,
    TMC_DatePicker,
    TMC_TextField,
    TMC_TextFieldWithBlock_Deprecated,
    UploadImage_Deprecated,
} from "src/components";
import {
    Styled_FormDrawer,
    Styled_StackActionDrawer,
    Styled_StackContentDrawer,
} from "src/components/Components_Common/Drawer/Drawer.style";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { isNameAvailable } from "src/pages/Pages_Teamoty/Page_Projects/Projects_DrawerContent/Projects_DrawerContent.container";
import { COLORS } from "src/theme/stylesheet";
import { getCurrentFormat } from "src/utils/date";
import { getUrlName } from "src/utils/urlFormatter";

const ProjectSchema = Yup.object().shape({
    name: Yup.string()
        .max(100, FORM_ERR_FMT.MAX_LENGTH)
        .required(FORM_ERR_FMT.REQUIRED),
    city: Yup.string()
        .max(100, FORM_ERR_FMT.MAX_LENGTH)
        .required(FORM_ERR_FMT.REQUIRED),
    country: Yup.string()
        .max(100, FORM_ERR_FMT.MAX_LENGTH)
        .required(FORM_ERR_FMT.REQUIRED),
    startDate: Yup.date().required(FORM_ERR_FMT.REQUIRED),
    thumbnail: Yup.array().min(1, FORM_ERR_FMT.REQUIRED),
    projectTypes: Yup.array().min(1, FORM_ERR_FMT.REQUIRED),
    company_id: Yup.object().required(FORM_ERR_FMT.REQUIRED),
    postCode: Yup.string()
        .max(10, FORM_ERR_FMT.MAX_LENGTH)
        .required(FORM_ERR_FMT.REQUIRED),
});

type Type_Tab_ProjectComponentProps = {
    initialName: string;
    onSubmit: (values: any, props: any) => void;
    data: any;
    isLoading: boolean;
    handleProjectName: (
        e: any,
        setFieldValue: (
            field: string,
            value: any,
            shouldValidate?: boolean | undefined,
        ) => void,
    ) => void;
    projectTypes: Type_selectList[];
    companies: Type_selectList_company[];
};

export const Tab_ProjectComponent = ({
    initialName,
    onSubmit,
    data,
    handleProjectName,
    isLoading,
    companies,
    projectTypes,
}: Type_Tab_ProjectComponentProps) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Projects.Drawer");
    const { formatMessageWithPartialKey: fmtCta } = useCoreIntl("Form.Cta");

    const icon = <Icon variant="light" icon="square" />;
    const checkedIcon = <Icon variant="solid" icon="square-check" />;

    return (
        <Formik
            initialValues={data}
            validationSchema={ProjectSchema}
            onSubmit={onSubmit}
            validateOnChange={false}
        >
            {({
                errors,
                isSubmitting,
                setErrors,
                handleChange,
                setFieldValue,
                values,
            }) => {
                const {
                    isLoading: isCheckingName,
                    data: dataIsNameAvailable,
                }: any = isNameAvailable(
                    values?.name,
                    setErrors,
                    errors,
                    initialName,
                );

                return (
                    <Styled_FormDrawer data-testid="EditProject-DrawerContent-TabGlobal-form">
                        <Styled_StackContentDrawer gap={4}>
                            <Typography variant="body1Bold">
                                {fmt(`AddProject.Step0.GlobalInformation`)}
                            </Typography>
                            <UploadImage_Deprecated
                                host={Url.COMMONS}
                                service={ProjectPath.PROJECTS}
                                id={values.id}
                                name="thumbnail"
                                value={values.thumbnail}
                                handleChange={setFieldValue}
                                withDescription
                                limitResolutions={{
                                    min: 540,
                                    max: 2550,
                                }}
                                isRequired={errors.thumbnail}
                            />
                            <TMC_TextField
                                data-testid="EditProject-DrawerContent-TabGlobal-name"
                                name="name"
                                label={fmt(`AddProject.Step0.ProjectLabel`)}
                                onChange={(e) =>
                                    handleProjectName(e, setFieldValue)
                                }
                                value={values.name}
                                helperText={errors.name as string}
                                error={!!errors.name}
                            />
                            {!isCheckingName &&
                                dataIsNameAvailable?.data?.data?.newName &&
                                initialName !== values?.name && (
                                    <Stack flexDirection="column" gap={2}>
                                        <Typography
                                            variant="body3"
                                            color={COLORS.moon600}
                                        >
                                            {fmt(
                                                "AddProject.Step0.NameSuggestion",
                                            )}
                                        </Typography>
                                        <TMC_Button
                                            type="button"
                                            variant="outlined"
                                            sx={{ width: "fit-content" }}
                                            onClick={() =>
                                                handleProjectName(
                                                    {
                                                        target: {
                                                            value: dataIsNameAvailable
                                                                ?.data?.data
                                                                ?.newName,
                                                        },
                                                    },
                                                    setFieldValue,
                                                )
                                            }
                                            endIcon={
                                                <Icon
                                                    variant="solid"
                                                    icon="check"
                                                    sx={{
                                                        color: COLORS.moon700,
                                                    }}
                                                />
                                            }
                                        >
                                            <Typography
                                                variant="body1"
                                                color={COLORS.moon600}
                                            >
                                                {
                                                    dataIsNameAvailable?.data
                                                        ?.data?.newName
                                                }
                                            </Typography>
                                        </TMC_Button>
                                    </Stack>
                                )}
                            <TMC_TextFieldWithBlock_Deprecated
                                data-testid="EditProject-DrawerContent-TabGlobal-slug"
                                name="slug"
                                block={getUrlName()}
                                value={values.slug}
                                helperText={errors.slug as string}
                                error={!!errors.slug}
                                disabled={true}
                            />
                            <TMC_DatePicker
                                name="startDate"
                                label={fmt("AddProject.Step0.StartDate")}
                                helperText={errors.startDate as string}
                                error={!!errors.startDate}
                                format={getCurrentFormat()}
                                value={values.startDate}
                            />
                            <Stack>
                                <Autocomplete
                                    id="company_id"
                                    multiple={false}
                                    onChange={(e, value) =>
                                        setFieldValue("company_id", value)
                                    }
                                    value={values.company_id}
                                    options={companies}
                                    getOptionLabel={(option) => option?.name}
                                    isOptionEqualToValue={(
                                        option,
                                        selectedValues,
                                    ) => option.id === selectedValues.id}
                                    renderInput={(params) => (
                                        <TMC_TextField
                                            data-testid="EditProject-DrawerContent-TabGlobal-company_id"
                                            {...params}
                                            name="company_id"
                                            label={fmt(
                                                `AddProject.Step0.CompanyLabel`,
                                            )}
                                            helperText={
                                                errors.company_id as string
                                            }
                                            error={!!errors.company_id}
                                        />
                                    )}
                                />
                            </Stack>
                            <Stack
                                direction="row"
                                flexWrap="nowrap"
                                gap={4}
                                width="100%"
                            >
                                <TMC_TextField
                                    data-testid="EditProject-DrawerContent-TabGlobal-postCode"
                                    name="postCode"
                                    label={fmt(
                                        `AddProject.Step0.PostCodeLabel`,
                                    )}
                                    onChange={handleChange}
                                    value={values.postCode}
                                    helperText={errors.postCode as string}
                                    error={!!errors.postCode}
                                    fullWidth
                                />
                                <TMC_TextField
                                    data-testid="EditProject-DrawerContent-TabGlobal-city"
                                    name="city"
                                    label={fmt(`AddProject.Step0.CityLabel`)}
                                    onChange={handleChange}
                                    value={values.city}
                                    helperText={errors.city as string}
                                    error={!!errors.city}
                                    fullWidth
                                />
                                <TMC_TextField
                                    data-testid="EditProject-DrawerContent-TabGlobal-country"
                                    name="country"
                                    label={fmt(`AddProject.Step0.CountryLabel`)}
                                    onChange={handleChange}
                                    value={values.country}
                                    helperText={errors.country as string}
                                    error={!!errors.country}
                                    fullWidth
                                />
                            </Stack>
                            <Stack paddingY={2}>
                                <Divider />
                            </Stack>
                            <Typography variant="body1Bold">
                                {fmt(`AddProject.Step0.ProjectInformation`)}
                            </Typography>
                            <Autocomplete
                                data-testid="EditProject-DrawerContent-TabGlobal-projectTypes"
                                id="projectTypes"
                                multiple
                                options={projectTypes}
                                onChange={(e, value) =>
                                    setFieldValue("projectTypes", value)
                                }
                                value={values.projectTypes || []}
                                disableCloseOnSelect
                                isOptionEqualToValue={(
                                    option,
                                    selectedValues,
                                ) => option.id === selectedValues.id}
                                getOptionLabel={(option) => option?.name}
                                renderInput={(params) => (
                                    <TMC_TextField
                                        {...params}
                                        name="roles"
                                        label={fmt(
                                            `AddProject.Step0.ProjectTypesLabel`,
                                        )}
                                        helperText={
                                            errors.projectTypes as string
                                        }
                                        error={!!errors.projectTypes}
                                    />
                                )}
                                renderOption={(props, option) => (
                                    <li {...props} key={option.id}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={
                                                !!values?.projectTypes.find(
                                                    (projectType: any) =>
                                                        projectType?.id ===
                                                        option.id,
                                                )
                                            }
                                        />
                                        {option?.name}
                                    </li>
                                )}
                            />
                        </Styled_StackContentDrawer>
                        <Styled_StackActionDrawer>
                            <TMC_Button
                                data-testid="EditProject-DrawerContent-TabGlobal-updateBtn"
                                variant="contained"
                                type="submit"
                                disabled={
                                    isLoading || !!errors.name || isSubmitting
                                }
                                sx={{ minWidth: 200 }}
                                endIcon={
                                    !isLoading &&
                                    !isSubmitting && (
                                        <Icon variant="solid" icon="check" />
                                    )
                                }
                            >
                                {isLoading || isSubmitting ? (
                                    <Spinner />
                                ) : (
                                    fmtCta(`Update`)
                                )}
                            </TMC_Button>
                        </Styled_StackActionDrawer>
                    </Styled_FormDrawer>
                );
            }}
        </Formik>
    );
};
