import { GridPaginationModel } from "@mui/x-data-grid";
import React, { useEffect, useMemo, useRef, useState } from "react";

import { Type_RequestConfig } from "src/api/fetch";
import {
    mutationDeleteProjectCompany,
    mutationStatusProjectCompany,
    useIndexProjectCompanies,
} from "src/api/tms-projects/projectCompanies";
import {
    Type_index_projectCompany,
    Type_put_projectCompany,
} from "src/api/tms-projects/projectCompanies/types";
import { useProject } from "src/contexts/project";
import { Page_ProjectCompaniesComponent } from "src/pages/Pages_Teamoty/Project/Settings/Page_ProjectCompanies/Page_ProjectCompanies.component";

export type Type_modalDeleteProjectCompany = {
    isOpen: boolean;
    props?: Type_index_projectCompany;
};

export const Page_ProjectCompaniesContainer = () => {
    // get context for project.id
    const { project } = useProject();

    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(
        {
            page: 0,
            pageSize: 25,
        },
    );

    const [requestConfig, setRequestConfig] = useState<Type_RequestConfig>({
        params: {
            page: 1,
            per_page: 25,
        },
        projectId: project?.id,
    });

    // States
    const [companies, setCompanies] = useState<Type_index_projectCompany[]>([]);
    const [modalDeleteProjectCompany, setModalDeleteProjectCompany] =
        useState<Type_modalDeleteProjectCompany>({
            isOpen: false,
        });

    useEffect(() => {
        setRequestConfig({
            params: {
                page: paginationModel.page + 1,
                per_page: paginationModel.pageSize,
            },
            projectId: project?.id,
        });
    }, [paginationModel]);

    const { isFetching, data: projectCompaniesFetched } =
        useIndexProjectCompanies(requestConfig) || {};

    useEffect(() => {
        if (projectCompaniesFetched?.companies?.length) {
            setCompanies(projectCompaniesFetched.companies);
        }
    }, [projectCompaniesFetched]);

    const rowCountRef = useRef(projectCompaniesFetched?.total || 0);

    const rowCount = useMemo(() => {
        if (projectCompaniesFetched?.total !== undefined) {
            rowCountRef.current = projectCompaniesFetched?.total;
        }
        return rowCountRef.current;
    }, [projectCompaniesFetched?.total]);

    const { mutateAsync: mutateDeleteProjectCompany } =
        mutationDeleteProjectCompany(requestConfig) || {};
    const deleteProjectCompany = async (id: number) => {
        await mutateDeleteProjectCompany(id);
    };

    const { mutateAsync: mutationCompanyStatus } =
        mutationStatusProjectCompany(requestConfig) || {};

    const changeStatus = async (projectCompany: Type_put_projectCompany) => {
        await mutationCompanyStatus({
            id: projectCompany.id,
            enabled: !projectCompany.enabled,
        });
    };

    return (
        <Page_ProjectCompaniesComponent
            isFetching={isFetching}
            companies={companies}
            changeStatus={changeStatus}
            deleteProjectCompany={deleteProjectCompany}
            modalDeleteProjectCompany={modalDeleteProjectCompany}
            setModalDeleteProjectCompany={setModalDeleteProjectCompany}
            pagination={paginationModel}
            setPagination={setPaginationModel}
            rowCount={rowCount}
            requestConfig={requestConfig}
        />
    );
};
