import React, { Dispatch, SetStateAction } from "react";

import { Type_formData_NewUser_Invitation } from "./Page_NewUser_Invitation.container";
import { Step_1Container } from "./Step_1/Step_1.container";
import { Step_2Container } from "./Step_2/Step_2.container";
import { Step_3Container } from "./Step_3/Step_3.container";
import { Step_4Container } from "./Step_4/Step_4.container";
import { Step_5Container } from "./Step_5/Step_5.container";
import { Step_6Container } from "./Step_6/Step_6.container";

export type Type_Page_NewUser_InvitationComponentProps = {
    formData: Type_formData_NewUser_Invitation;
    setFormData: Dispatch<SetStateAction<Type_formData_NewUser_Invitation>>;
    step: number;
    maxStep: number;
    handleStep: (stepAction: "previous" | "next") => void;
    metadataUsersForm: any;
    setStep: any;
};

export const Page_NewUser_InvitationComponent = ({
    formData,
    setFormData,
    step,
    maxStep,
    handleStep,
    metadataUsersForm,
    setStep,
}: Type_Page_NewUser_InvitationComponentProps) => {
    return (
        <>
            {step === 1 && (
                <Step_1Container
                    formData={formData}
                    setFormData={setFormData}
                    step={step}
                    maxStep={maxStep}
                    handleStep={handleStep}
                />
            )}
            {step === 2 && (
                <Step_2Container
                    formData={formData}
                    setFormData={setFormData}
                    step={step}
                    maxStep={maxStep}
                    handleStep={handleStep}
                />
            )}
            {step === 3 && (
                <Step_3Container
                    metadataUsersForm={metadataUsersForm}
                    formData={formData}
                    setFormData={setFormData}
                    step={step}
                    maxStep={maxStep}
                    handleStep={handleStep}
                />
            )}
            {step === 4 && (
                <Step_4Container
                    formData={formData}
                    setFormData={setFormData}
                    step={step}
                    maxStep={maxStep}
                    handleStep={handleStep}
                    setStep={setStep}
                />
            )}
            {step === 5 && (
                <Step_5Container
                    formData={formData}
                    setFormData={setFormData}
                    step={step}
                    maxStep={maxStep}
                    handleStep={handleStep}
                />
            )}
            {step === 6 && <Step_6Container formData={formData} />}
        </>
    );
};
