import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import * as axiosRetry from "axios-retry";

import { getLocalStorageItem } from "src/utils/localStorageServices";
import { redirectToIdentification } from "src/utils/urlFormatter";

import { Url } from "./paths";

axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;

axios.interceptors.response.use(
    (response) => response,
    async (err) => {
        if (
            err?.response?.status == 401 || // Unauthenticated
            err?.response?.status == 403 || // Forbidden
            err?.response?.status == 419
        ) {
            console.debug("Axios interceptor: should redirect to login");
            // if (import.meta.env.PROD) {   // TODO fix tests
            const lang = getLocalStorageItem("language") || "en_GB";
            window.location.href = `/${lang}/login`;
            // }
        }

        return Promise.reject(err);
    },
);

axiosRetry.default(axios, {
    retries: 3,
    retryDelay: axiosRetry.exponentialDelay,
    retryCondition(error: AxiosError) {
        switch (error?.response?.status) {
            //retry only if status is 500 or 501
            case 500:
            case 501:
                return true;
            default:
                return false;
        }
    },
    onRetry: (retryCount: number, error: AxiosError) => {
        console.debug(`Axios retry count: `, [
            retryCount,
            error?.request,
            error?.response,
        ]);
    },
});

export enum StatusCodes {
    OK = 200,
    CREATED = 201,
    UNAUTHORIZED = 401,
    NOT_FOUND = 404,
    ERROR = 500,
}

export type Type_RequestConfig = AxiosRequestConfig & {
    withCredentials?: boolean;
    projectId?: number;
    subProjectId?: number;
    versionId?: number;
    taskId?: number;
    sequenceId?: number;
    resourceId?: number;
    areaId?: number;
    taskNumber?: number;
    token?: string;
};

type Type_Headers = {
    language: string;
    Accept: string;
    projectId?: number;
    subProjectId?: number;
    versionId?: number;
    taskId?: number;
    sequenceId?: number;
    resourceId?: number;
    areaId?: number;
    taskNumber?: number;
    token?: string;
};

export type ApiResponse<T> = {
    data: {
        data: T;
        success: boolean;
    };
};

export type PostReturnType = Promise<any>;

export const getHeaders = (config?: Type_RequestConfig): Type_Headers => {
    const language = getLocalStorageItem("language");
    let headers: Type_Headers = {
        ...config?.headers,
        language,
        Accept: "application/json",
    };

    if (config?.projectId) {
        headers = {
            ...headers,
            projectId: config.projectId,
        };
    }

    if (config?.subProjectId) {
        headers = {
            ...headers,
            subProjectId: config.subProjectId,
        };
    }

    if (config?.versionId) {
        headers = {
            ...headers,
            versionId: config.versionId,
        };
    }

    if (config?.taskId) {
        headers = {
            ...headers,
            taskId: config.taskId,
        };
    }

    if (config?.sequenceId) {
        headers = {
            ...headers,
            sequenceId: config.sequenceId,
        };
    }

    if (config?.resourceId) {
        headers = {
            ...headers,
            resourceId: config.resourceId,
        };
    }

    if (config?.areaId) {
        headers = {
            ...headers,
            areaId: config.areaId,
        };
    }

    if (config?.token) {
        headers = {
            ...headers,
            token: config.token,
        };
    }

    return headers;
};

export const post = async (
    body: object,
    url: string,
    config?: Type_RequestConfig,
): PostReturnType => {
    return axios
        .post(
            url,
            { ...body },
            {
                ...config,
                headers: { ...getHeaders(config) },
            },
        )
        .then((response: AxiosResponse) => {
            return {
                status: response.status,
                data: response.data,
                success: true,
            };
        })
        .catch((error: AxiosError) => {
            // Si 401 (erreur de token), renvoie vers le login
            if (error?.response?.status === StatusCodes.UNAUTHORIZED)
                redirectToIdentification();
            throw {
                status: error?.response?.status,
                data: error.response?.data,
                success: false,
            };
        });
};

export type PutReturnType = Promise<any>;

export const put = async (
    body: object,
    url: string,
    config?: Type_RequestConfig,
): PutReturnType => {
    return axios
        .put(
            url,
            { ...body },
            {
                headers: getHeaders(config),
                ...config,
            },
        )
        .then((response: AxiosResponse) => {
            return {
                status: response.status,
                data: response.data,
                success: true,
            };
        })
        .catch((error: AxiosError) => {
            // Si 401 (erreur de token), renvoie vers l'identification
            if (error?.response?.status === StatusCodes.UNAUTHORIZED)
                redirectToIdentification();
            throw {
                status: error?.response?.status,
                data: error.response?.data,
                success: false,
            };
        });
};

export type GetReturnType = Promise<any>;

export const get = async (
    url: string,
    config?: Type_RequestConfig,
    callback?: any,
): GetReturnType => {
    return await axios
        .get(url, {
            headers: getHeaders(config),
            ...config,
        })
        .then((response: AxiosResponse) => {
            if (callback) {
                return callback(response);
            } else
                return {
                    status: response.status,
                    data: response.data,
                    headers: response.headers,
                    success: true,
                };
        })
        .catch((error: AxiosError) => {
            // Si 401 (erreur de token), renvoie vers le login
            if (error?.response?.status === StatusCodes.UNAUTHORIZED)
                redirectToIdentification();
            throw {
                status: error?.response?.status,
                data: error.response?.data,
                success: false,
            };
        });
};

export type RemoveReturnType = Promise<any>;

export const remove = async (
    body: object, // TODO remove
    url: string,
    config?: Type_RequestConfig,
): GetReturnType => {
    // await getCsrfToken();
    return axios
        .delete(url, {
            headers: getHeaders(config),
            ...config,
        })
        .then((response: AxiosResponse) => {
            return {
                status: response.status,
                data: response.data,
                success: true,
            };
        })
        .catch((error: AxiosError) => {
            // Si 401 (erreur de token), renvoie vers l'identification
            if (error?.response?.status === StatusCodes.UNAUTHORIZED)
                redirectToIdentification();
            throw {
                status: error?.response?.status,
                data: error.response?.data,
                success: false,
            };
        });
};

export const getCsrfToken = async (
    config?: Type_RequestConfig,
): GetReturnType => {
    return axios
        .get(Url.CSRF, {
            headers: getHeaders(config),
            ...config,
        })
        .then((response: AxiosResponse) => {
            return {
                status: response.status,
                data: response.data,
                success: true,
            };
        })
        .catch((error: AxiosError) => {
            throw {
                status: error?.response?.status,
                data: error.response?.data,
                success: false,
            };
        });
};

export const getImg = async (
    url: string,
    config?: Type_RequestConfig,
): GetReturnType => {
    return get(url, { responseType: "blob", ...config });
};
