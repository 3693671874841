import Konva from "konva";
import React, { useState } from "react";
import { Group, Rect } from "react-konva";
import { Portal } from "react-konva-utils";

import {
    colorPlanningDrawerResizerOver,
    sizePlanningResizer,
} from "src/components/Components_Teamoty/Planning/Planning.const";

import { Type_Props_PlanningDrawerResizer } from "./PlanningDrawerTitle.type";

export const PlanningPlanningDrawerResizerY = ({
    x,
    y,
    width,
    height,
    minSize,
    onResize,
    showResizer = false,
}: Type_Props_PlanningDrawerResizer) => {
    const [drag, setDrag] = useState<boolean>(false);

    const xx: number = x + width - 2.5;
    const yy: number = height / 2 - sizePlanningResizer / 2;

    return (
        <Portal selector=".top" enabled={true}>
            <Group
                x={xx}
                y={y}
                onMouseOver={(e: Konva.KonvaEventObject<MouseEvent>): void => {
                    e.cancelBubble = true;

                    if (drag) return;

                    const container: HTMLDivElement | undefined =
                        e.currentTarget.getStage()?.getContent();
                    if (container) container.style.cursor = "col-resize";
                }}
                onMouseOut={(e: Konva.KonvaEventObject<MouseEvent>): void => {
                    e.cancelBubble = true;

                    if (drag) return;

                    const container: HTMLDivElement | undefined =
                        e.currentTarget.getStage()?.getContent();
                    if (container) container.style.cursor = "default";
                }}
                draggable={true}
                onDragStart={(e: Konva.KonvaEventObject<DragEvent>) => {
                    setDrag(true);

                    const container: HTMLDivElement | undefined =
                        e.currentTarget.getStage()?.getContent();
                    if (container) container.style.cursor = "col-resize";
                }}
                onDragMove={(e: Konva.KonvaEventObject<DragEvent>): void => {
                    e.cancelBubble = true;
                    onResize(Math.max(minSize ?? 0, e.target.x() - x - 1));
                    e.target.x(xx);
                    e.target.y(y);
                }}
                onDragEnd={(e: Konva.KonvaEventObject<DragEvent>): void => {
                    setDrag(false);

                    const container: HTMLDivElement | undefined =
                        e.currentTarget.getStage()?.getContent();
                    if (container) container.style.cursor = "default";
                }}
            >
                <Rect
                    height={height}
                    width={3}
                    strokeWidth={1}
                    hitStrokeWidth={2}
                />
                {showResizer && (
                    <Rect
                        y={yy}
                        height={sizePlanningResizer}
                        width={3}
                        fill={colorPlanningDrawerResizerOver}
                    />
                )}
            </Group>
        </Portal>
    );
};
