import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import { Type_formData_NewUser_Invitation } from "src/pages/Pages_Common/Page_NewUser_Invitation/Page_NewUser_Invitation.container";

import { Step_4Component } from "./Step_4.component";

export type Type_Step4ContainerProps = {
    formData: Type_formData_NewUser_Invitation;
    setFormData: Dispatch<SetStateAction<Type_formData_NewUser_Invitation>>;
    step: number;
    maxStep: number;
    handleStep: (stateAction: "previous" | "next") => void;
    setStep: any;
};

export const Step_4Container = ({
    formData,
    setFormData,
    step,
    maxStep,
    handleStep,
    setStep,
}: Type_Step4ContainerProps) => {
    //Step of the company creation/choice
    const [companyStep, setCompanyStep] = useState<number>(1);

    // Search input state
    const [companySearch, setCompanySearch] = useState("");

    const handleCompanyStep = (
        stepAction: "previous" | "next",
        resetFormData?: boolean,
    ): void => {
        const nextStepInc = 1;
        const previousStepInc = 1;

        if (resetFormData) {
            setFormData({
                ...formData,
                company_name: "",
                company_nationalId: null,
                company_code: "",
                company_postalCode: "",
                company_city: "",
                company_country: "",
                company_id: null,
            });
        }
        setCompanyStep((prev) => {
            if (stepAction === "previous") {
                return prev - previousStepInc;
            }

            return prev + nextStepInc;
        });
    };

    useEffect(() => {
        if (!formData.company_id && formData.company_name) {
            setCompanyStep(2);
        } else if (formData.company_id) {
            setFormData({
                ...formData,
                company_name: "",
                company_nationalId: null,
                company_code: "",
                company_postalCode: "",
                company_city: "",
                company_country: "",
                company_id: null,
            });
        }
    }, []);

    return (
        <Step_4Component
            formData={formData}
            setFormData={setFormData}
            step={step}
            maxStep={maxStep}
            handleStep={handleStep}
            companyStep={companyStep}
            handleCompanyStep={handleCompanyStep}
            setStep={setStep}
            companySearch={companySearch}
            setCompanySearch={setCompanySearch}
        />
    );
};
