import { Divider, ListItemIcon, Stack, Typography } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";
import { useState } from "react";

import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import {
    Icon,
    Type_Props_Icon,
} from "src/components/Components_Common/Icon/Icon";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export type Type_MenuItem_DropDown = {
    label?: string;
    call?: (id: number | undefined, props: any) => void;
    selected?: boolean;
    show?: (props: any) => boolean;
    icon?: {
        variant: string;
        name: string;
        color?: string;
    };
    divider?: boolean;
    id?: string;
};

export type Type_Props_DropdownMenuContainer = any & {
    menuItems?: Type_MenuItem_DropDown[];
    id?: number;
    icon?: Type_Props_Icon;
    props?: any;
    label?: string;
    showCaretDownIcon?: boolean;
    showHeader?: boolean;
    restProps?: any;
};

export const DropdownMenu = ({
    menuItems,
    id,
    icon,
    label,
    showCaretDownIcon,
    showHeader,
    props,
    ...restProps
}: Type_Props_DropdownMenuContainer) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (
        event:
            | React.MouseEvent<HTMLButtonElement>
            | React.MouseEvent<HTMLLIElement, MouseEvent>,
    ) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (
        event:
            | React.MouseEvent<HTMLButtonElement>
            | React.MouseEvent<HTMLLIElement, MouseEvent>,
    ) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    return (
        <div style={{ alignSelf: "center" }}>
            <IconButton
                data-testid={`Menu_DropDown-${id}`}
                sx={{
                    ...restProps.iconButton?.sx,
                }}
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
            >
                {icon ?? <Icon variant="solid" icon="ellipsis-vertical" />}
                {label && (
                    <Typography sx={{ ml: 1 }} variant="body2">
                        {label}
                    </Typography>
                )}
                {showCaretDownIcon && (
                    <Icon
                        sx={{ ml: 2 }}
                        fontSize="small"
                        icon={"caret-down"}
                        variant={"solid"}
                        color={"inherit"}
                    />
                )}
            </IconButton>
            {menuItems && (
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        "aria-labelledby": "basic-button",
                    }}
                >
                    {showHeader && label && (
                        <div>
                            <Stack
                                sx={{ px: 4, pb: 2 }}
                                flexDirection={"row"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                                data-testid={`Dropdown-${id}-header`}
                            >
                                <Typography color={"secondary"} variant="body2">
                                    {label}
                                </Typography>
                                <Icon
                                    fontSize="small"
                                    color={"secondary"}
                                    icon="question"
                                    variant="light"
                                />
                            </Stack>
                            <Divider orientation="horizontal" />
                        </div>
                    )}
                    {menuItems.map((menuItem: any, index: number) => {
                        const { formatMessageWithPartialKey: fmtLists } =
                            useCoreIntl("Lists");

                        if (menuItem.divider) {
                            return (
                                <Divider
                                    key={index}
                                    orientation="horizontal"
                                    textAlign="center"
                                    variant="fullWidth"
                                />
                            );
                        }
                        // call callback function to show item if exists
                        if (
                            !Object.prototype.hasOwnProperty.call(
                                menuItem,
                                "show",
                            ) ||
                            menuItem.show(props)
                        ) {
                            return (
                                <MenuItem
                                    selected={menuItem.selected}
                                    data-testid={`Dropdown-${id}-item-${menuItem.id}`}
                                    onClick={(event) => {
                                        handleClose(event);
                                        menuItem.call(id, props);
                                    }}
                                    key={index}
                                >
                                    {menuItem.label === "changeStatusLabel" && (
                                        <ListItemIcon>
                                            <Icon
                                                variant="light"
                                                icon="circle-dashed"
                                            />
                                        </ListItemIcon>
                                    )}
                                    {menuItem.icon && (
                                        <ListItemIcon>
                                            <Icon
                                                variant={menuItem.icon.variant}
                                                icon={menuItem.icon.name}
                                                sx={{
                                                    color: menuItem.icon.color
                                                        ? menuItem.icon.color
                                                        : "inherit",
                                                    marginRight: "6px",
                                                }}
                                                {...menuItem.icon}
                                            />
                                        </ListItemIcon>
                                    )}
                                    {menuItem.label === "changeStatusLabel" ? (
                                        props.enabled ? (
                                            fmtLists("Actions.Disable")
                                        ) : (
                                            fmtLists("Actions.Enable")
                                        )
                                    ) : (
                                        <Typography variant="body2">
                                            {menuItem.label}
                                        </Typography>
                                    )}
                                </MenuItem>
                            );
                        }
                    })}
                </Menu>
            )}
        </div>
    );
};
