import { Stack } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { RectReadOnly } from "react-use-measure";

import { useShowSequence } from "src/api/tms-scheduling/sequences";
import { FullSpinner } from "src/components/Components_Common/Spinner/FullSpinner";
import { SequencePertDiagram } from "src/components/Components_Teamoty/SequencePert/SequencePertDiagram";
import { SequencePertLeftBar } from "src/components/Components_Teamoty/SequencePert/SequencePertLeftBar";
import { SequencePertToolbar } from "src/components/Components_Teamoty/SequencePert/SequencePertToolbar";
import { Type_event_sequence, useChannel } from "src/hooks/useChannel";

type Type_Props_SequencePertDrawer = {
    sequenceId: number;
    setShowPertDiagram: Dispatch<SetStateAction<boolean>>;
    bounds: RectReadOnly;
};
export const SequencePertDrawer = ({
    sequenceId,
    setShowPertDiagram,
    bounds,
}: Type_Props_SequencePertDrawer) => {
    const { data, isFetching } = useShowSequence(
        sequenceId,
        "sequencePertDrawer",
    );
    const [fixedSize, setFixedSize] = useState<boolean>(false);

    useChannel({
        eventHandler: ({ event, data: dataEvent }) => {
            if (event === "updateSequence") {
                if ((dataEvent as Type_event_sequence).id === data?.id) {
                    setFixedSize((dataEvent as Type_event_sequence).fixedSize);
                }
            }
        },
    });

    useEffect(() => {
        setFixedSize(data?.fixedSize as boolean);
    }, [isFetching]);

    return (
        <>
            {!isFetching && data ? (
                <Stack
                    sx={{ width: bounds.width, height: bounds.height }}
                    data-testid="SequencePertDrawer-StackY"
                >
                    <SequencePertToolbar
                        handleClose={() => setShowPertDiagram(false)}
                        sequence={data}
                    />
                    <Stack
                        flexDirection={"row"}
                        sx={{
                            width: "100%",
                            height: "100%",
                        }}
                        data-testid="SequencePertDrawer-StackX"
                    >
                        <>
                            <SequencePertLeftBar sequenceId={sequenceId} />
                            {sequenceId && (
                                <SequencePertDiagram
                                    sequenceId={sequenceId}
                                    fixedSize={fixedSize}
                                />
                            )}
                        </>
                    </Stack>
                </Stack>
            ) : (
                <FullSpinner />
            )}
        </>
    );
};
