import { useQuery, UseQueryResult } from "react-query";

import { getImg, GetReturnType, Type_RequestConfig } from "src/api/fetch";

export const getImage = (
    id: number,
    host: string,
    service: string,
    imgType: "cover" | "thumbnail",
    cachedName?: string,
): GetReturnType => {
    return getImg(`${host}${service}/${id}/${imgType}?${cachedName}`);
};

export const getImageQuery = (
    id: number,
    host: string,
    service: string,
    imgType: "cover" | "thumbnail" | "image",
    cachedName?: string,
    enabled?: boolean,
    config?: Type_RequestConfig,
): UseQueryResult => {
    return useQuery(
        [service, imgType, id],
        (): Promise<string | undefined> => {
            return getImg(
                `${host}${service}/${id}/${imgType}?${cachedName}`,
                config,
            ).then((r) => {
                return URL.createObjectURL(r.data);
            });
        },
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            refetchOnMount: false,
            staleTime: 1000 * 60 * 60 * 24 * 30, // 30 days
            enabled: enabled ?? true,
        },
    );
};
