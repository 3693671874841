import { Box, Stack, Typography } from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import {
    Accordion,
    Type_accordionList,
} from "src/components/Components_Common/accordions/Accordion";
import { TMC_Chip } from "src/components/Components_Common/Chip/Chip";
import { TextField } from "src/components/Components_Common/forms/reactHookFormComponents/TextField/TextField";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import {
    getLocalStorageItem,
    setLocalStorageItem,
} from "src/utils/localStorageServices";

import { PlanningFilterTaskStatus } from "./Filters/PlanningFilterStatus";
import { PlanningFilterTaskType } from "./Filters/PlanningFilterTaskType";

const planningFiltersAccordionExpandedStateKey: string =
    "planningFiltersAccordionExpandedState";

export const PlanningFiltersFormAccordionList = () => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerFilters.AccordionsTitle",
    );

    const { watch, resetField } = useFormContext();

    const [expanded, setExpanded] = useState<string[]>(
        getLocalStorageItem(planningFiltersAccordionExpandedStateKey, true),
    );

    const resetFilter = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        name: string,
    ) => {
        e.stopPropagation();
        resetField(name);
    };

    const resetIconAction = (name: string) => {
        const value = watch(name);
        const isValid = value && value.length > 0;
        return isValid ? (
            <IconButton
                aria-label={`filter-clear-btn-${name}`}
                onClick={(e) => {
                    resetFilter(e, name);
                }}
                color={"primary"}
                data-testid={`planning-filter-${name}-clean-btn`}
            >
                <Icon
                    fontSize="small"
                    icon="arrow-rotate-left"
                    variant="solid"
                />
            </IconButton>
        ) : (
            <></>
        );
    };

    const filterTitle = (label: string, key: string): ReactElement => {
        const value = watch(key);
        const isArray = Array.isArray(value);
        const isValid = value && value.length > 0;
        return (
            <Stack
                direction="row"
                display={"flex"}
                alignItems={"center"}
                spacing={2}
            >
                <Typography variant="body1">{fmt(label)}</Typography>
                {isValid && (
                    <TMC_Chip
                        label={isArray ? value.length : 1}
                        height={22}
                        color="primary"
                    />
                )}
            </Stack>
        );
    };

    const accordionList: Type_accordionList[] = [
        {
            key: "period",
            title: filterTitle("Period", "period"),
            action: resetIconAction("period"),
            children: <>{/* TODO: AP-3688 */}</>,
        },
        {
            key: "trades",
            title: filterTitle("Trades", "trades"),
            action: resetIconAction("trades"),
            children: (
                <>
                    <TextField
                        name="trades"
                        placeholder="Search for a trade"
                    ></TextField>
                </>
            ),
        },
        {
            key: "companies",
            title: filterTitle("Companies", "companies"),
            action: resetIconAction("companies"),
            children: (
                <TextField
                    name="companies"
                    placeholder="Search for a company"
                ></TextField>
            ),
        },
        {
            key: "typesTasks",
            title: filterTitle("TypesTasks", "typesTasks"),
            action: resetIconAction("typesTasks"),
            children: <PlanningFilterTaskType name="typesTasks" />,
        },
        {
            key: "status",
            title: filterTitle("Status", "status"),
            action: resetIconAction("status"),
            children: <PlanningFilterTaskStatus name="status" />,
        },
    ];

    const handleAccordionChange =
        (panel: string) =>
        (event: React.SyntheticEvent, isExpanded: boolean) => {
            event.preventDefault();
            event.stopPropagation();
            setExpanded((prevExpanded) =>
                isExpanded
                    ? [...prevExpanded, panel]
                    : prevExpanded.filter((key) => key !== panel),
            );
        };

    useEffect(() => {
        setLocalStorageItem(planningFiltersAccordionExpandedStateKey, expanded);
    }, [expanded]);

    return (
        <Box>
            {accordionList.map((item) => (
                <Accordion
                    key={item.key}
                    expanded={expanded.includes(item.key)}
                    onChange={handleAccordionChange(item.key)}
                    action={item.action}
                    title={item.title}
                    data-testid={`accordion-filter-${item.key}`}
                >
                    <Box px={2}>{item.children}</Box>
                </Accordion>
            ))}
        </Box>
    );
};
