import { formatDateForBack, formatDateForFront } from "src/utils/date";

import {
    Type_index_constraint,
    Type_post_constraint,
    Type_sch_index_constraint,
    Type_sch_post_constraint,
    Type_sch_put_constraint,
    Type_show_constraint,
} from "./types";

export const formatterIndexConstraint = (
    constraint: Type_sch_index_constraint,
): Type_index_constraint => {
    const { area_id, priority, date, ...rest } = constraint;
    return {
        ...rest,
        areaId: area_id,
        priority: priority.id,
        date: formatDateForFront(date)!,
    };
};

export const formatterIndexConstraints = (
    constraints: Type_sch_index_constraint[],
): Type_index_constraint[] => {
    return constraints.map(formatterIndexConstraint);
};

export const formatterCreateConstraint = (
    constraint: Type_post_constraint,
): Type_sch_post_constraint => {
    const { areaId, subTradeId, companyId, date, ...rest } = constraint;

    return {
        ...rest,
        area_id: areaId,
        subTrade_id: subTradeId,
        company_id: companyId,
        date: formatDateForBack(date)!,
    };
};

export const formatterUpdateConstraint = (
    constraint: Type_show_constraint,
): Type_sch_put_constraint => {
    const { areaId, subTradeId, companyId, date, ...rest } = constraint;

    return {
        ...rest,
        area_id: areaId,
        subTrade_id: subTradeId,
        company_id: companyId,
        date: formatDateForBack(date),
    };
};
