import { Stack, Typography } from "@mui/material";
import { FormikProvider } from "formik";
import React from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";

import { Spinner, TMC_Button, TMC_TextField } from "src/components";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { Styled_Form } from "src/pages/Pages_Common/Page_NewUser_Invitation/Page_NewUser_Invitation.style";
import { COLORS } from "src/theme/stylesheet";
import { getLocalStorageItem } from "src/utils/localStorageServices";

export type Type_Step_1ComponentProps = {
    step: number;
    formik: any;
    maxStep: number;
};

export const Step_1Component = ({
    step,
    formik,
    maxStep,
}: Type_Step_1ComponentProps) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Teamoty.NewUserInvitation",
    );
    const navigate: NavigateFunction = useNavigate();
    const lang = getLocalStorageItem("language");

    return (
        <FormikProvider value={formik}>
            <Styled_Form data-testid={`UsersInvitation-step-${step}-form`}>
                <Stack rowGap={"24px"}>
                    <Stack rowGap={"8px"} width={"100%"}>
                        <Typography
                            variant="body2"
                            color={COLORS.moon600}
                            data-testid={`UsersInvitation-step-${step}-of-${maxStep}`}
                        >
                            {fmt("Steps", {
                                values: {
                                    currentStep: step,
                                    maxStep: maxStep,
                                },
                            })}
                        </Typography>
                        <Typography
                            variant="h1"
                            data-testid={`UsersInvitation-step-${step}-title`}
                        >
                            {fmt("Step1.Title")}
                        </Typography>
                    </Stack>
                    <Stack
                        flexDirection="row"
                        flexWrap="nowrap"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography variant="body2">
                            {fmt("Step1.AlreadyAccount")}
                        </Typography>
                        <TMC_Button
                            data-testid={`UsersInvitation-step-${step}-Login-btn`}
                            type="button"
                            variant="outlined"
                            onClick={() => navigate(`/${lang}/login`)}
                        >
                            {fmt("Step1.SignIn")}
                        </TMC_Button>
                    </Stack>
                    <TMC_TextField
                        data-testid={`UsersInvitation-step-${step}-email`}
                        name="email"
                        label={fmt("Step1.Label.Email")}
                        {...formik.getFieldProps("email")}
                        helperText={formik.errors?.email as string}
                        error={!!formik.errors?.email}
                        disabled={true}
                    />
                </Stack>
                <Stack direction={"row-reverse"}>
                    <TMC_Button
                        data-testid={`UsersInvitation-step-${step}-Submit-btn`}
                        type="submit"
                        variant="contained"
                        disabled={formik.isSubmitting}
                        endIcon={
                            !formik.isSubmitting && (
                                <Icon variant="solid" icon="arrow-right" />
                            )
                        }
                        sx={{ width: "fit-content", blockSize: "fit-content" }}
                    >
                        {formik.isSubmitting ? (
                            <Spinner style="button" />
                        ) : (
                            fmt("Step1.Cta.Submit")
                        )}
                    </TMC_Button>
                </Stack>
            </Styled_Form>
        </FormikProvider>
    );
};
