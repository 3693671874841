import { InputPeriodPropsType } from "./InputPeriod.container";

export type InputPeriodType = {
    type: "inputPeriod";
    nameFrom: string;
    nameTo: string;
    required?: boolean;
    label?: {
        label?: string;
        languageLabel?: string;
    };
    placeholderFrom?: {
        label?: string;
        languageLabel?: string;
    };
    placeholderTo?: {
        label?: string;
        languageLabel?: string;
    };
};

export const InputPeriodComponent = (_props: InputPeriodPropsType) => {
    return (
        <>
            <p>InputPeriodComponent</p>
        </>
    );
};
