import {
    Type_prj_select_area,
    Type_select_area,
} from "src/api/tms-projects/areas/types";

export type Type_sch_index_taskLink = {
    id: number;
    linkType: number;
    dayMode: number;
    quantity: number;
    waitingDay: number;
    taskFrom: Type_sch_select_taskLinkTask;
    taskTo: Type_sch_select_taskLinkTask;
    areaFrom: Type_prj_select_area | null;
    areaTo: Type_prj_select_area | null;
};

export type Type_sch_show_taskLink = {
    id: number;
    linkType: number;
    dayMode: number;
    quantity: number;
    waitingDay: number;
    taskFrom: Type_sch_select_taskLinkTask;
    taskTo: Type_sch_select_taskLinkTask;
    areaFrom: Type_prj_select_area | null;
    areaTo: Type_prj_select_area | null;
};

export type Type_sch_post_taskLink = {
    linkType?: number;
    dayMode?: number;
    quantity?: number;
    waitingDay?: number;
    taskFrom_id?: number;
    taskTo_id?: number;
    areaFrom_id?: number;
    areaTo_id?: number;
};

export type Type_sch_put_taskLink = {
    id: number;
    linkType?: number;
    dayMode?: number;
    quantity?: number;
    waitingDay?: number;
    taskFrom_id?: number;
    taskTo_id?: number;
    areaFrom_id?: number | null;
    areaTo_id?: number | null;
};

export type Type_post_taskLink = {
    linkType?: number;
    dayMode?: number;
    quantity?: number;
    waitingDay?: number;
    taskFrom?: { id: number } | null;
    taskTo?: { id: number } | null;
    areaFrom?: Type_select_area | null;
    areaTo?: Type_select_area | null;
};

export type Type_put_taskLink = {
    id: number;
    linkType?: number;
    dayMode?: number;
    quantity?: number;
    waitingDay?: number;
    taskFrom: { id: number } | null;
    taskTo: { id: number } | null;
    areaFrom: Type_select_area | null;
    areaTo: Type_select_area | null;
};

export type Type_Link_By_Direction = {
    FROM: Type_index_taskLink[];
    TO: Type_index_taskLink[];
};

export enum LINK_DIRECTION {
    FROM = "FROM",
    TO = "TO",
}

export type Type_show_taskLink = {
    id: number;
    linkType: number;
    dayMode: number;
    quantity: number;
    waitingDay: number;
    taskFrom: Type_select_taskLinkTask;
    taskTo: Type_select_taskLinkTask;
    areaFrom: Type_select_area | null;
    areaTo: Type_select_area | null;
};

export type Type_index_taskLink = {
    id: number;
    linkType: number;
    dayMode: number;
    quantity: number;
    waitingDay: number;
    taskFrom: Type_select_taskLinkTask;
    taskTo: Type_select_taskLinkTask;
    areaFrom: Type_select_area | null;
    areaTo: Type_select_area | null;
};

/**
 * TaskLinkTaskResource
 */
export type Type_sch_select_taskLinkTask = {
    id: number;
    code: string;
    name: string;
    sequence: Type_sch_select_taskLinkSequence;
};

export type Type_select_taskLinkTask = {
    id: number;
    code: string;
    name: string;
    sequence: Type_select_taskLinkSequence;
};

/**
 * TaskLinkTaskSequenceResource
 */
type Type_sch_select_taskLinkSequence = {
    id: number;
    name: string;
    code: string;
};
type Type_select_taskLinkSequence = {
    id: number;
    name: string;
    code: string;
};
