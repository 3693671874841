import { FormikValues, useFormik } from "formik";
import { Dispatch, SetStateAction } from "react";
import * as Yup from "yup";

import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { Type_formData_NewUser_Invitation } from "src/pages/Pages_Common/Page_NewUser_Invitation/Page_NewUser_Invitation.container";

import { Step_1Component } from "./Step_1.component";

export type Type_Step_1ContainerProps = {
    formData: Type_formData_NewUser_Invitation;
    setFormData: Dispatch<SetStateAction<Type_formData_NewUser_Invitation>>;
    step: number;
    maxStep: number;
    handleStep: (stateAction: "previous" | "next") => void;
};

const Schema_NewUser_Invitation_Step_1 = Yup.object().shape({
    email: Yup.string()
        .email(FORM_ERR_FMT.MUST_BE_EMAIL)
        .max(255, FORM_ERR_FMT.MAX_LENGTH)
        .required(FORM_ERR_FMT.REQUIRED),
});

export const Step_1Container = ({
    formData,
    setFormData,
    step,
    maxStep,
    handleStep,
}: Type_Step_1ContainerProps) => {
    const handleSubmit = async (values: FormikValues) => {
        try {
            setFormData({ ...formData, email: values.email });
            handleStep("next");
        } catch (e: any) {
            console.error(e.errors);
        }
    };

    const formik: any = useFormik({
        initialValues: formData,
        validationSchema: Schema_NewUser_Invitation_Step_1,
        onSubmit: handleSubmit,
        validateOnChange: false,
        validateOnBlur: false,
    });

    return <Step_1Component step={step} maxStep={maxStep} formik={formik} />;
};
