import React from "react";
import { Group, Text } from "react-konva";

import { colorPlanningDate } from "src/components/Components_Teamoty/Planning/Planning.const";

import { Type_Props_PlanningDate } from "./PlanningDate.type";

export const PlanningWeek = ({
    height,
    width,
    date,
}: Type_Props_PlanningDate) => {
    // const { formatMessageWithPartialKey: fmt } = useCoreIntl("Planning");
    /*const text = fmt("W", {
        values: {
            week: date.week,
        },
    });*/
    const text: string = date.dateNarrowText + " " + date.date;
    return (
        <Group x={date.pos}>
            <Text
                text={text}
                fontSize={12}
                width={width}
                height={height}
                padding={3}
                align="left"
                verticalAlign="middle"
                fill={colorPlanningDate}
            />
        </Group>
    );
};
