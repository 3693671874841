import { Stack, Typography } from "@mui/material";
import { FormikProvider } from "formik";
import React from "react";

import {
    Spinner,
    TMC_Button,
    TMC_TextField,
    TMC_TextFieldPassword_Deprecated,
    TMC_Tooltip,
} from "src/components";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { Styled_Form } from "src/pages/Pages_Common/Page_NewUser_Invitation/Page_NewUser_Invitation.style";
import { COLORS } from "src/theme/stylesheet";

import { Type_requirements } from "./Step_2.container";

export type TypeComponentProps = {
    step: number;
    handleStep: (stateAction: "previous" | "next") => void;
    formik: any;
    requirements: Type_requirements;
    isRequirementsComplete: boolean;
    maxStep: number;
};

export const Step_2Component = ({
    step,
    handleStep,
    formik,
    requirements,
    isRequirementsComplete,
    maxStep,
}: TypeComponentProps) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Teamoty.NewUserInvitation",
    );
    const { formatMessageWithPartialKey: fmtCta } = useCoreIntl("Form.Cta");
    return (
        <FormikProvider value={formik}>
            <Styled_Form data-testid={`UsersInvitation-step-${step}-form`}>
                <Stack rowGap={6}>
                    <Stack rowGap={"8px"} width={"100%"}>
                        <Typography
                            variant="body2"
                            color={COLORS.moon600}
                            data-testid={`UsersInvitation-step-${step}-of-${maxStep}`}
                        >
                            {fmt("Steps", {
                                values: {
                                    currentStep: step,
                                    maxStep: maxStep,
                                },
                            })}
                        </Typography>
                        <Typography
                            variant="h1"
                            data-testid={`UsersInvitation-step-${step}-title`}
                        >
                            {fmt("Step2.Title")}
                        </Typography>
                    </Stack>
                    <Stack rowGap={2}>
                        <TMC_TextFieldPassword_Deprecated
                            data-testid={`UsersInvitation-step-${step}-password`}
                            {...formik.getFieldProps("password")}
                            label={fmt(`Step2.Label.Password`)}
                            error={
                                formik.touched.password &&
                                Boolean(formik.errors.password)
                            }
                        />
                        <Stack
                            gap={1}
                            data-testid="-UserInvitation-PasswordRequirement-container"
                        >
                            <Typography
                                color={
                                    requirements.MinimumLength
                                        ? "success.main"
                                        : "error.main"
                                }
                                sx={{ display: "flex", alignItems: "center" }}
                                data-testid="-UserInvitation-PasswordRequirement-MinimumLength"
                            >
                                <Icon
                                    variant={
                                        requirements.MinimumLength
                                            ? "regular"
                                            : "solid"
                                    }
                                    icon={
                                        requirements.MinimumLength
                                            ? "circle-check"
                                            : "circle-xmark"
                                    }
                                    sx={{ paddingRight: "4px" }}
                                />
                                {fmt(`Step2.Requirements.MinimumLength`)}
                            </Typography>
                            <Typography
                                color={
                                    requirements.Alphanumeric
                                        ? "success.main"
                                        : "error.main"
                                }
                                sx={{ display: "flex", alignItems: "center" }}
                                data-testid="-UserInvitation-PasswordRequirement-Alphanumeric"
                            >
                                <Icon
                                    variant={
                                        requirements.Alphanumeric
                                            ? "regular"
                                            : "solid"
                                    }
                                    icon={
                                        requirements.Alphanumeric
                                            ? "circle-check"
                                            : "circle-xmark"
                                    }
                                    sx={{ paddingRight: "4px" }}
                                />
                                {fmt(`Step2.Requirements.Alphanumeric`)}
                            </Typography>
                            <Typography
                                color={
                                    requirements.MustBeSame
                                        ? "success.main"
                                        : "error.main"
                                }
                                sx={{ display: "flex", alignItems: "center" }}
                                data-testid="UserInvitation-PasswordRequirement-MustBeSame"
                            >
                                <Icon
                                    variant={
                                        requirements.MustBeSame
                                            ? "regular"
                                            : "solid"
                                    }
                                    icon={
                                        requirements.MustBeSame
                                            ? "circle-check"
                                            : "circle-xmark"
                                    }
                                    sx={{ paddingRight: "4px" }}
                                />
                                {fmt(`Step2.Requirements.MustBeSame`)}
                            </Typography>
                        </Stack>
                        <TMC_TextFieldPassword_Deprecated
                            data-testid={`UsersInvitation-step-${step}-passwordConfirm`}
                            {...formik.getFieldProps("passwordConfirm")}
                            label={fmt(`Step2.Label.PasswordConfirm`)}
                            error={
                                formik.touched.passwordConfirm &&
                                Boolean(formik.errors.passwordConfirm)
                            }
                        />
                        <TMC_Tooltip title={"TODO"}>
                            <Typography
                                data-testid={`UsersInvitation-step-${step}-passwordInfo`}
                                variant={"body3Underline"}
                                color={COLORS.blue700}
                                sx={{ display: "flex", alignItems: "center" }}
                            >
                                <Icon
                                    variant="light"
                                    icon="circle-question"
                                    sx={{ paddingRight: "4px" }}
                                />
                                {fmt(`Step2.Cta.Information`)}
                            </Typography>
                        </TMC_Tooltip>
                    </Stack>
                    <Stack rowGap={2}>
                        <TMC_TextField
                            data-testid={`UsersInvitation-step-${step}-firstName`}
                            name="firstName"
                            label={fmt("Step2.Label.FirstName")}
                            {...formik.getFieldProps("firstName")}
                            helperText={formik.errors?.firstName as string}
                            error={!!formik.errors?.firstName}
                        />
                        <TMC_TextField
                            data-testid={`UsersInvitation-step-${step}-lastName`}
                            name="lastName"
                            label={fmt("Step2.Label.LastName")}
                            {...formik.getFieldProps("lastName")}
                            helperText={formik.errors?.lastName as string}
                            error={!!formik.errors?.lastName}
                        />
                        <TMC_TextField
                            data-testid={`UsersInvitation-step-${step}-phone`}
                            name="phone"
                            label={fmt("Step2.Label.Phone")}
                            {...formik.getFieldProps("phone")}
                            helperText={formik.errors?.phone as string}
                            error={!!formik.errors?.phone}
                        />
                    </Stack>
                </Stack>
                <Stack direction={"row"} justifyContent={"space-between"}>
                    <TMC_Button
                        data-testid={`UsersInvitation-step-${step}-GoBack-btn`}
                        onClick={() => handleStep("previous")}
                        type="button"
                        variant="text"
                        disabled={formik.isSubmitting}
                        startIcon={
                            !formik.isSubmitting && (
                                <Icon variant="solid" icon="arrow-left" />
                            )
                        }
                        sx={{
                            width: "fit-content",
                            blockSize: "fit-content",
                            color: COLORS.blue700,
                        }}
                    >
                        {fmtCta("Back")}
                    </TMC_Button>
                    <TMC_Button
                        data-testid={`UsersInvitation-step-${step}-Submit-btn`}
                        type="submit"
                        variant="contained"
                        disabled={
                            formik.isSubmitting || !isRequirementsComplete
                        }
                        endIcon={
                            !formik.isSubmitting && (
                                <Icon variant="solid" icon="arrow-right" />
                            )
                        }
                        sx={{ width: "fit-content", blockSize: "fit-content" }}
                    >
                        {formik.isSubmitting ? (
                            <Spinner style="button" />
                        ) : (
                            fmt("Step2.Cta.Submit")
                        )}
                    </TMC_Button>
                </Stack>
            </Styled_Form>
        </FormikProvider>
    );
};
