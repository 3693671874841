import { Stack, Toolbar, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { ReactElement, useState } from "react";

import { Type_select_sequence } from "src/api/tms-scheduling/sequences/types";
import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { TMC_Chip } from "src/components/Components_Common/Chip/Chip";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { SequenceBadgeIcon } from "src/components/Components_Teamoty/badges/SequenceBadgeIcon";
import { Type_event_message, useChannel } from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { useContextualDrawer } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

//////////////////////////////////////////////
///     STYLED                             ///
//////////////////////////////////////////////

const Styled_Toolbar = styled(Toolbar)(() => ({
    width: "100%",
    justifyContent: "space-between",
    minHeight: 24,
    "&.MuiToolbar-root": {
        padding: 0,
    },
}));

const Styled_ToolbarStack = styled(Stack)(() => ({
    alignItems: "center",
}));

Styled_ToolbarStack.defaultProps = {
    direction: "row",
};

const Styled_FlagContent = styled(Stack)(({ theme }) => ({
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(0.5),
}));

//////////////////////////////////////////////
///     TYPES                              ///
//////////////////////////////////////////////

type Type_Props_HeaderToolbar = {
    onClose: () => void;
    sequence?: Pick<Type_select_sequence, "name" | "color">;
    taskName?: string;
    type?: number;
    taskId?: number;
    taskNumber?: number;
    taskNotesCount?: number;
    showNotesIcon?: boolean;
    showFlagObservations?: boolean;
};

type Type_taskIcon = {
    [key: number]: ReactElement;
};

const taskIcon: Type_taskIcon = {
    1: (
        <Icon
            variant={"kit"}
            icon={"solid-tds-task-work"}
            fontSize={"large"}
            color={"inherit"}
        />
    ),
    2: (
        <Icon
            variant={"kit"}
            icon={"solid-tds-task-study"}
            fontSize={"large"}
            color={"inherit"}
        />
    ),
    3: (
        <Icon
            variant={"kit"}
            icon={"solid-tds-task-waiting"}
            fontSize={"large"}
            color={"inherit"}
        />
    ),
    4: (
        <Icon
            variant={"kit"}
            icon={"solid-tds-task-milestone"}
            fontSize={"large"}
            color={"inherit"}
        />
    ),
    5: (
        <Icon
            variant={"kit"}
            icon={"solid-tds-task-delivery"}
            fontSize={"large"}
            color={"inherit"}
        />
    ),
    6: (
        <Icon
            variant={"kit"}
            icon={"solid-tds-task-technical"}
            fontSize={"large"}
            color={"inherit"}
        />
    ),
};

export const HeaderToolbar = ({
    onClose,
    sequence,
    taskName,
    type,
    taskId,
    taskNumber,
    taskNotesCount,
    showNotesIcon = true,
    showFlagObservations = false,
}: Type_Props_HeaderToolbar) => {
    const { closePaper, openPaper } = useContextualDrawer();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerTasks.Header",
    );

    ///////////////////////////////////////
    ///            States               ///
    ///////////////////////////////////////
    const [observationsCount, setObservationsCount] = useState<number>(0);
    const [notesCount, setNotesCount] = useState<number>(taskNotesCount || 0);

    useChannel({
        eventHandler: ({ event, data }: Type_event_message) => {
            const count = data as number;
            if (event === "setFlagObservations") {
                setObservationsCount(count);
            }
            if (event === "changeFlagObservations") {
                // change flag count with delta (+/-)
                setObservationsCount((old) => old + count);
            }
            if (event === "updateNotesCount") {
                // change flag count with delta (+/-)
                setNotesCount((old) => old + count);
            }
        },
    });

    const handleClick = (): void => {
        closePaper("taskArea");
        openPaper("task", { id: taskId });
    };

    return (
        <Styled_Toolbar variant="dense">
            <Styled_ToolbarStack spacing={1}>
                {sequence && (
                    <SequenceBadgeIcon color={sequence?.color as string} />
                )}
                {sequence && (
                    <Typography
                        data-testid="Task-Header-Sequence-Name"
                        color={"inherit"}
                        variant="body3Medium"
                    >
                        {sequence?.name}
                    </Typography>
                )}
                {taskName && type && (
                    <Stack
                        direction={"row"}
                        alignItems={"center"}
                        sx={(theme) => ({
                            opacity: theme.opacity.mid,
                        })}
                    >
                        {taskIcon[type]}
                        <Typography
                            data-testid="Task-Header-Task-Name"
                            onClick={handleClick}
                            color={"inherit"}
                            variant="body3Medium"
                            sx={{
                                ":hover": {
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                },
                            }}
                        >
                            {taskName}
                        </Typography>
                    </Stack>
                )}
            </Styled_ToolbarStack>
            <Styled_ToolbarStack sx={{ paddingX: 2 }}>
                {showFlagObservations && observationsCount > 0 && (
                    <TMC_Chip
                        data-testid="Task-Header-Observations-Chip"
                        backgroundColor={"error.main"}
                        colorText="white"
                        size={"small"}
                        title={fmt("Tooltip.Observations")}
                        label={
                            <Styled_FlagContent>
                                <Icon
                                    variant="solid"
                                    icon="flag"
                                    fontSize="small"
                                />
                                <Typography variant="body2">
                                    {observationsCount}
                                </Typography>
                            </Styled_FlagContent>
                        }
                    />
                )}
                {showNotesIcon && !!taskNumber && (
                    <>
                        <IconButton
                            data-testid="Task-Header-Notes-Icon"
                            color={"inherit"}
                            size={"small"}
                            title={fmt("Tooltip.Notes")}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                openPaper("taskNotes", {
                                    taskNumber: taskNumber,
                                    focus: true,
                                });
                            }}
                        >
                            <Icon
                                variant={"light"}
                                icon={"sticky-note"}
                                fontSize={"small"}
                            />
                        </IconButton>
                        {notesCount > 0 && (
                            <Typography
                                data-testid="Task-Header-Notes-Count"
                                variant="body3"
                                pr={2}
                            >
                                {notesCount}
                            </Typography>
                        )}
                    </>
                )}
                <IconButton
                    data-testid="Task-Header-Reload-Icon"
                    color={"inherit"}
                    onClick={() => console.log("reload")}
                    size={"small"}
                    title={fmt("Tooltip.Reload")}
                >
                    <Icon
                        variant={"light"}
                        icon={"rotate"}
                        fontSize={"small"}
                    />
                </IconButton>
                <IconButton
                    data-testid="Task-Header-Close-Icon"
                    color={"inherit"}
                    onClick={onClose}
                    size={"small"}
                    title={fmt("Tooltip.Close")}
                >
                    <Icon variant={"solid"} icon={"xmark"} fontSize={"small"} />
                </IconButton>
            </Styled_ToolbarStack>
        </Styled_Toolbar>
    );
};
