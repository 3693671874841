import { Stack, Typography } from "@mui/material";
import React from "react";

import { Type_index_sequenceFolderTree_sequence } from "src/api/tms-scheduling/sequenceFolders/types";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { invertColor } from "src/utils/colors";

type Type_SequenceBadge = {
    sequence: Type_index_sequenceFolderTree_sequence;
};

export const SequenceBadge = ({ sequence }: Type_SequenceBadge) => {
    return (
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Stack
                bgcolor={sequence.color}
                boxShadow={"0px 1px 1px 0px rgba(29, 56, 99, 0.32)"}
                borderRadius={0.5}
                color={invertColor(sequence.color)}
                direction={"row"}
                alignItems={"center"}
            >
                <Icon
                    variant={"kit"}
                    icon={"light-tds-sequence"}
                    fontSize={"large"}
                />
                {sequence.code && (
                    <Typography
                        variant={"body2"}
                        color={invertColor(sequence.color)}
                        paddingInlineEnd={1.5}
                    >
                        {sequence.code}
                    </Typography>
                )}
            </Stack>
            <Typography variant={"body2"}>{sequence.label}</Typography>
        </Stack>
    );
};
