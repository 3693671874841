import { FormikValues } from "formik";

import { Form } from "./Form.component";
import { InputCheckboxType } from "./Form_Components/Form_Checks/InputCheckbox/InputCheckbox.containter";
import { InputAnchorType } from "./Form_Components/Form_CTAs/InputAnchor/InputAnchor.container";
import { InputDateType } from "./Form_Components/Form_Inputs/InputDate/InputDate.component";
import { InputDateRangeType } from "./Form_Components/Form_Inputs/InputDateRange/InputDateRange.component";
import { InputDateTimeType } from "./Form_Components/Form_Inputs/InputDateTime/InputDateTime.component";
import { InputDateTimeRangeType } from "./Form_Components/Form_Inputs/InputDateTimeRange/InputDateTimeRange.component";
import { InputEmailType } from "./Form_Components/Form_Inputs/InputEmail/InputEmail.component";
import { InputNumberType } from "./Form_Components/Form_Inputs/InputNumber/InputNumber.component";
import { InputPasswordType } from "./Form_Components/Form_Inputs/InputPassword/InputPassword.component";
import { InputPasswordConfirmType } from "./Form_Components/Form_Inputs/InputPasswordConfirm/InputPasswordConfirm.component";
import { InputPeriodType } from "./Form_Components/Form_Inputs/InputPeriod/InputPeriod.component";
import { InputPhoneType } from "./Form_Components/Form_Inputs/InputPhone/InputPhone.component";
import { InputRegexType } from "./Form_Components/Form_Inputs/InputRegex/InputRegex.component";
import { InputStaticType } from "./Form_Components/Form_Inputs/InputStatic/InputStatic.component";
import { InputTextType } from "./Form_Components/Form_Inputs/InputText/InputText.component";
import { InputTimeType } from "./Form_Components/Form_Inputs/InputTime/InputTime.component";

export type FormFieldsType =
    | InputDateType
    | InputDateRangeType
    | InputDateTimeType
    | InputDateTimeRangeType
    | InputEmailType
    | InputNumberType
    | InputPasswordType
    | InputPasswordConfirmType
    | InputPeriodType
    | InputPhoneType
    | InputRegexType
    | InputStaticType
    | InputTextType
    | InputTimeType
    | InputAnchorType
    | InputCheckboxType;

export type FormType = {
    fields: FormFieldsType[];
    values: FormikValues;
    onChange?: any;
};

/**\
 * @deprecated
 *
 * But still use by Metadata forms
 * Maybe will be refactored when we have new Forms
 *
 * @param fields
 * @param values
 * @param onChange
 * @constructor
 */
export const FormContainer = ({ fields, values, onChange }: FormType) => {
    return <Form fields={fields} values={values} onChange={onChange} />;
};
