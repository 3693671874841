export enum AreaKeys {
    INDEX = "indexAreas",
    SHOW = "showArea",
    SELECT_LIST = "selectListAreas",
    EXPORT = "exportAreas",
    INDEX_SELECTION = "indexAreasSelection",
}

export enum DayOffKeys {
    INDEX = "indexDaysOff",
    SHOW = "showDayOff",
}

export enum DrawingKeys {
    INDEX = "indexDrawings",
    SHOW = "showDrawing",
    SELECT_LIST = "selectListDrawings",
}

export enum LanguageKeys {
    INDEX = "indexLanguages",
    SHOW = "showLanguage",
    SELECT_LIST = "selectListLanguages",
}

export enum VersionNoteKeys {
    INDEX = "indexVersionNotes",
    SHOW = "showVersionNote",
    SELECT_LIST = "selectListVersionNotes",
}

export enum ProductTypeKeys {
    INDEX = "indexProductTypes",
    SHOW = "showProductType",
    SELECT_LIST = "selectListProductTypes",
}

export enum ProjectCompanyKeys {
    INDEX = "indexProjectCompanies",
    SHOW = "showProjectCompany",
    SELECT_LIST = "selectListProjectCompanies",
}

export enum ProjectUserKeys {
    INDEX = "indexProjectUsers",
    SHOW = "showProjectUser",
    SELECT_LIST = "selectListProjectUsers",
}

export enum ResourcePeriodKeys {
    INDEX = "indexResourcePeriods",
}

export enum ResourceKeys {
    INDEX = "indexResources",
    SHOW = "showResource",
    SELECT_LIST = "selectListResources",
}

export enum ResourceTypeKeys {
    INDEX = "indexResourceTypes",
    SHOW = "showResourceType",
    SELECT_LIST = "selectListResourceTypes",
}

export enum SubProjectKeys {
    INDEX = "indexSubProjects",
    SHOW = "showSubProject",
    SELECT_LIST = "selectListSubProjects",
    CURRENT_WORKSPACE = "currentWorkspaceSubProject",
}

export enum SubTradeKeys {
    INDEX = "indexSubTrades",
    SHOW = "showSubTrade",
    SELECT_LIST = "selectListSubTrades",
}

export enum TradeKeys {
    INDEX = "indexTrades",
    SHOW = "showTrade",
    SELECT_LIST = "selectListTrades",
    EXPORT = "exportTrades",
}

export enum VersionKeys {
    INDEX = "indexVersions",
    SHOW = "showVersion",
    SELECT_LIST = "selectListVersions",
}

export enum ViewKeys {
    SHOW = "showView",
}

export enum WorkspaceKeys {
    SHOW = "showWorkspace",
}
