import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { DrawerProps, Stack, Typography } from "@mui/material";
import * as React from "react";

import { formatterShowCompanyToIndex } from "src/api/tms-commons/companies/formatters";
import { Type_show_company } from "src/api/tms-commons/companies/types";
import { Type_me } from "src/api/tms-gateway/auth/types";
import { Type_put_user } from "src/api/tms-users/users/types";
import { DrawerGeneric } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { DrawerTabList } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric.style";
import { PasswordForm } from "src/forms/security/PasswordForm";
import { UserCompanyForm } from "src/forms/users/UserCompanyForm";
import { UserInfosForm } from "src/forms/users/UserInfosForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { Styled_DrawerTab } from "src/pages/Pages_Teamoty/MyAccount/Page_MyProfile/Page_MyProfile.style";

export type Type_Props_MyProfileDrawerComponent = DrawerProps & {
    open: boolean;
    isLoading: boolean;
    setIsLoading?: (s: boolean) => void;
    onClose: () => void;
    user: Type_me | null;
    onFormSubmit: (values: Type_put_user) => void;
    company?: Type_show_company;
    step: string;
    setStep: React.Dispatch<React.SetStateAction<string>>;
};

export const MyProfileDrawerComponent = ({
    open,
    isLoading,
    setIsLoading,
    onClose,
    user,
    onFormSubmit,
    company,
    step,
    setStep,
}: Type_Props_MyProfileDrawerComponent) => {
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("MyAccount.Profile");

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setStep(newValue);
    };

    //--- CONSTANTS -----
    const FormIDs = ["user-infos", "user-company", "user-password"];
    const DrawerTabs = [
        {
            title: fmt("Informations.Title"),
            value: "1",
        },
        {
            title: fmt("Company.Title"),
            value: "2",
        },
        {
            title: fmt("Security.Form.Password"),
            value: "3",
        },
    ];

    return (
        <DrawerGeneric
            open={open}
            onClose={onClose}
            dataTestIdTitle={`MyProfil-Drawer-Edit-Title`}
            title={fmt("Drawer.Title")}
            isFormLoading={isLoading}
            formId={FormIDs[parseInt(step) - 1]}
        >
            <TabContext value={step}>
                <Stack gap={4}>
                    <DrawerTabList
                        onChange={handleChange}
                        aria-label="myprofile-drawer-tabs"
                    >
                        {DrawerTabs.map(
                            (
                                tab: { title: string; value: string },
                                idx: number,
                            ) => (
                                <Styled_DrawerTab
                                    label={
                                        <Typography variant="body1">
                                            {tab.title}
                                        </Typography>
                                    }
                                    value={tab.value}
                                    key={tab.title + idx}
                                />
                            ),
                        )}
                    </DrawerTabList>
                    <TabPanel value="1">
                        <UserInfosForm
                            onSubmit={onFormSubmit}
                            user={user}
                            formId={FormIDs[0]}
                        />
                    </TabPanel>
                    <TabPanel value="2">
                        <UserCompanyForm
                            formId={FormIDs[1]}
                            onSubmit={onFormSubmit}
                            userId={user?.id}
                            defaultCompany={
                                company && formatterShowCompanyToIndex(company)
                            }
                        />
                    </TabPanel>
                    <TabPanel value="3">
                        <PasswordForm
                            formId={FormIDs[2]}
                            setIsLoading={setIsLoading}
                            onClose={onClose}
                        />
                    </TabPanel>
                </Stack>
            </TabContext>
        </DrawerGeneric>
    );
};
