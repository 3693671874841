import { useMutation, useQuery } from "react-query";

import { PeopleTaskAreaKeys } from "src/api/tms-scheduling/keys";
import {
    formatterFormPeopleTaskArea,
    formatterIndexPeopleTaskAreas,
} from "src/api/tms-scheduling/peopleTaskArea/formatter";
import {
    createTaskAreaPeople,
    indexTaskAreaPeople,
    removeTaskAreaPeople,
} from "src/api/tms-scheduling/peopleTaskArea/services";
import {
    Type_post_peopleTaskArea,
    Type_sch_index_peopleTaskArea,
} from "src/api/tms-scheduling/peopleTaskArea/types";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const useIndexTaskAreaPeople = (
    areaId: number | undefined,
    taskId: number,
) => {
    const { requestConfig } = useProject();
    return useQuery({
        queryKey: [PeopleTaskAreaKeys.INDEX, requestConfig, areaId, taskId],
        queryFn: () =>
            indexTaskAreaPeople({
                ...requestConfig,
                areaId: areaId,
                taskId: taskId,
            }),
        enabled: !!requestConfig.projectId && !!requestConfig.subProjectId,
        refetchOnWindowFocus: false,
        select: (data) => {
            return formatterIndexPeopleTaskAreas(
                data?.data?.data as Type_sch_index_peopleTaskArea[],
            );
        },
        onError: (err) => {
            console.error(err);
            return err;
        },
    });
};

export const mutationCreateTaskAreaPeople = () => {
    const { requestConfig } = useProject();
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");

    return useMutation({
        mutationFn: (taskAreaPeople: Type_post_peopleTaskArea) =>
            createTaskAreaPeople(formatterFormPeopleTaskArea(taskAreaPeople), {
                ...requestConfig,
                areaId: taskAreaPeople.areaId,
                taskId: taskAreaPeople.taskId,
            }),
        onSuccess: (resp) => {
            if (!resp.success || !resp?.data?.data)
                throw new Error(
                    "Wrong format data: mutationCreateTaskAreaPeople",
                );
            return resp;
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErrors("GenericError"),
            });
            return err;
        },
    });
};

export const mutationDeleteTaskAreaPeople = (
    taskId: number,
    areaId: number,
    callback: (id: number) => void,
) => {
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");
    const { requestConfig } = useProject();

    return useMutation({
        mutationFn: (id: number) =>
            removeTaskAreaPeople(id, {
                ...requestConfig,
                taskId: taskId,
                areaId: areaId,
            }),
        onSuccess: (_data, id: number) => {
            callback(id);
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErrors("GenericError"),
            });
            return err;
        },
    });
};
