import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";

import { Type_show_project } from "src/api/tms-commons/projects/types";
import { Type_index_language } from "src/api/tms-projects/languages/types";
import { LoadingBox, TMC_Button } from "src/components";
import { TMC_Chip } from "src/components/Components_Common/Chip/Chip";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { SectionTitle } from "src/components/Components_Common/SectionTitle";
import { Table_Licenses } from "src/components/Components_Teamoty/tables/TableLicenses/Table_Licenses";
import { ProjectDrawer } from "src/drawers/project/ProjectDrawer";
import { ProjectLanguagesDrawer } from "src/drawers/projectLanguages/ProjectLanguagesDrawer";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { Styled_CardHeaderCoverProjectDetail } from "src/pages/Pages_Teamoty/Project/Settings/Page_ProjectDetail/ProjectDetails.style";
import { displayFormattedDate } from "src/utils/date";

export type Type_Props_Page_ProjectDetailsComponent = {
    project: Type_show_project | null;
    metadataProjects: Record<string, string>;
    languages: Type_index_language[] | null | undefined;
    img: string;
    isFetchingMetadata: boolean;
    isFetchingLanguage: boolean;
    isFetchingProject: boolean;
};

export const Page_ProjectDetailsComponent = ({
    project,
    metadataProjects,
    languages,
    img,
    isFetchingMetadata,
    isFetchingLanguage,
    isFetchingProject,
}: Type_Props_Page_ProjectDetailsComponent) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.ProjectDetails",
    );
    const theme = useTheme();
    const page = "projects";

    const [drawerState, setDrawerState] = useState({
        projectDetails: false,
        languages: false,
    });
    const [tab, setTab] = useState<string>("0");

    const toggleDrawer = (
        drawer: "projectDetails" | "languages",
        open: boolean,
    ) => {
        setDrawerState((prev) => ({ ...prev, [drawer]: open }));
    };

    return (
        <>
            {!project || isFetchingProject ? (
                <LoadingBox />
            ) : (
                <>
                    <Box
                        sx={{
                            height: 168,
                            backgroundColor: "#FFF",
                            zIndex: 0,
                            overflow: "hidden",
                            marginTop: -2,
                        }}
                    >
                        <Box
                            sx={{
                                width: "40%",
                                height: 180,
                                backgroundSize: "cover",
                                filter: "blur(100px)",
                                backgroundBlendMode: "multiply",
                                zIndex: 1,
                                backgroundImage: `url(${img})`,
                                marginInlineStart: 40,
                            }}
                            data-testid={"Project-details-image-blur"}
                        />
                    </Box>

                    <Stack rowGap={4}>
                        <Grid container rowSpacing={4} columnSpacing={2}>
                            <Styled_CardHeaderCoverProjectDetail
                                sx={{ backgroundImage: `url(${img})` }}
                                data-testid={"Project-details-image"}
                            />
                            <Grid
                                item
                                container
                                justifyContent="space-between"
                                alignItems="center"
                                xs={12}
                            >
                                <Typography
                                    variant="h1"
                                    data-testid={"Project-details-project-name"}
                                >
                                    {project.name}
                                </Typography>
                                <TMC_Button
                                    variant="text"
                                    data-testid="Project-details-btn-edit-details"
                                    onClick={() => {
                                        setTab("0");
                                        toggleDrawer("projectDetails", true);
                                    }}
                                    sx={{ minWidth: "auto" }}
                                >
                                    {fmt("Edit")}
                                </TMC_Button>
                            </Grid>
                            <Grid item xs={4} md={3}>
                                <Typography
                                    variant="body1"
                                    color="text.secondary"
                                    data-testid={
                                        "Project-details-startDate-label"
                                    }
                                >
                                    {fmt("StartDate")}
                                </Typography>
                            </Grid>
                            <Grid item xs={8} md={9}>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    alignItems="baseline"
                                >
                                    <Icon
                                        variant="light"
                                        icon="calendar"
                                        sx={{
                                            color: theme.palette.text.secondary,
                                        }}
                                    />
                                    <Typography
                                        variant="body1"
                                        data-testid={
                                            "Project-details-startDate"
                                        }
                                    >
                                        {displayFormattedDate(
                                            project.startDate,
                                        )}{" "}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={4} md={3}>
                                <Typography
                                    variant="body1"
                                    color="text.secondary"
                                    data-testid={
                                        "Project-details-company-label"
                                    }
                                >
                                    {fmt("Details")}
                                </Typography>
                            </Grid>
                            <Grid item xs={8} md={9}>
                                <Typography
                                    variant="body1"
                                    data-testid={"Project-details-company-name"}
                                >
                                    {project.company.name}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    data-testid={
                                        "Project-details-postCode_city_country"
                                    }
                                >
                                    {project.postCode} {project.city}{" "}
                                    {project.country}
                                </Typography>
                            </Grid>
                            <Grid item xs={4} md={3}>
                                <Typography
                                    variant="body1"
                                    color="text.secondary"
                                    data-testid={
                                        "Project-details-projectTypes-label"
                                    }
                                >
                                    {fmt("ProjectTypes")}
                                </Typography>
                            </Grid>
                            <Grid item xs={8} md={9}>
                                <Stack
                                    direction="row"
                                    gap={1}
                                    flexWrap="wrap"
                                    data-testid={
                                        "Project-details-projectTypes-list"
                                    }
                                >
                                    {project.projectTypes.map((item, index) => (
                                        <TMC_Chip
                                            label={item.name}
                                            key={index}
                                            data-testid={`Project-details-projectTypes-item-${index}`}
                                        />
                                    ))}
                                </Stack>
                            </Grid>
                        </Grid>
                        {project.metadata && project.metadata.length > 0 && (
                            <Stack rowGap={4}>
                                <SectionTitle title={fmt("MetaData")}>
                                    <TMC_Button
                                        variant="text"
                                        data-testid="Project-details-btn-edit-metadata"
                                        onClick={() => {
                                            setTab("1");
                                            toggleDrawer(
                                                "projectDetails",
                                                true,
                                            );
                                        }}
                                        sx={{ minWidth: "auto" }}
                                    >
                                        {fmt("Edit")}
                                    </TMC_Button>
                                </SectionTitle>
                                {!isFetchingMetadata &&
                                    project.metadata.map(
                                        (
                                            item: Record<string, string>,
                                            index: number,
                                        ) =>
                                            metadataProjects[item.label] && (
                                                <Grid
                                                    key={`metadata${index}`}
                                                    container
                                                    columnSpacing={2}
                                                >
                                                    <Grid item xs={4} md={3}>
                                                        <Typography
                                                            variant="body1"
                                                            color="text.secondary"
                                                            data-testid={`Project-details-metadata-item-${item.label}`}
                                                        >
                                                            {
                                                                metadataProjects[
                                                                    item.label
                                                                ]
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={8} md={9}>
                                                        <Stack
                                                            direction="row"
                                                            spacing={2}
                                                        >
                                                            {Array.isArray(
                                                                item.value,
                                                            ) &&
                                                            item.value.length >
                                                                0 ? (
                                                                item.value.map(
                                                                    (
                                                                        value,
                                                                        indexValue,
                                                                    ) => (
                                                                        <TMC_Chip
                                                                            key={`metadataValue${index}-${indexValue}`}
                                                                            label={
                                                                                value
                                                                            }
                                                                            data-testid={`Project-details-metadata-value-${item.label}`}
                                                                        />
                                                                    ),
                                                                )
                                                            ) : item.value &&
                                                              !Array.isArray(
                                                                  item.value,
                                                              ) &&
                                                              !item.isDate ? (
                                                                <Typography
                                                                    variant="body1"
                                                                    data-testid={`Project-details-metadata-value-${item.label}`}
                                                                >
                                                                    {item.value}
                                                                </Typography>
                                                            ) : item.value &&
                                                              !Array.isArray(
                                                                  item.value,
                                                              ) &&
                                                              item.isDate ? (
                                                                <Stack
                                                                    direction="row"
                                                                    alignItems="baseline"
                                                                    gap={2}
                                                                >
                                                                    <Icon
                                                                        variant="light"
                                                                        icon="calendar"
                                                                        sx={{
                                                                            color: "text.secondary",
                                                                        }}
                                                                    />
                                                                    <Typography
                                                                        variant="body1"
                                                                        data-testid={`Project-details-metadata-value-${item.label}`}
                                                                    >
                                                                        {
                                                                            item.value
                                                                        }
                                                                    </Typography>
                                                                </Stack>
                                                            ) : (
                                                                <TMC_Chip
                                                                    label={fmt(
                                                                        "noMetaData",
                                                                    )}
                                                                    data-testid={`Project-details-metadata-value-${item.label}`}
                                                                />
                                                            )}
                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                            ),
                                    )}
                            </Stack>
                        )}
                        <Box>
                            <Table_Licenses
                                title={fmt("Licenses")}
                                licenses={project.licenses}
                            />
                        </Box>
                        {isFetchingLanguage ? (
                            <LoadingBox />
                        ) : (
                            languages &&
                            languages.length > 0 && (
                                <Stack rowGap={4}>
                                    <SectionTitle title={fmt("Languages")}>
                                        <TMC_Button
                                            variant="text"
                                            data-testid="Project-details-btn-edit-languages"
                                            onClick={() =>
                                                toggleDrawer("languages", true)
                                            }
                                            sx={{ minWidth: "auto" }}
                                        >
                                            {fmt("Edit")}
                                        </TMC_Button>
                                    </SectionTitle>
                                    <Grid container rowGap={2}>
                                        {languages.map((item, index) => (
                                            <Grid
                                                key={`language${index}`}
                                                container
                                                rowSpacing={2}
                                                columnSpacing={2}
                                                alignItems={"center"}
                                            >
                                                <Grid item>
                                                    <Typography variant="body1">
                                                        {item.label}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    {item.default && (
                                                        <TMC_Chip
                                                            key={`languageDefault${index}`}
                                                            label={fmt(
                                                                "Default",
                                                            )}
                                                            backgroundColor={
                                                                "primary.main"
                                                            }
                                                            colorText={
                                                                "primary.contrastText"
                                                            }
                                                        />
                                                    )}
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Stack>
                            )
                        )}
                    </Stack>
                </>
            )}
            {project && (
                <ProjectDrawer
                    projectIdToUpdate={project.id}
                    page={page}
                    open={drawerState.projectDetails}
                    onClose={() => toggleDrawer("projectDetails", false)}
                    tab={tab}
                />
            )}
            {languages && (
                <ProjectLanguagesDrawer
                    page={page}
                    open={drawerState.languages}
                    onClose={() => toggleDrawer("languages", false)}
                />
            )}
        </>
    );
};
