import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    PutReturnType,
    remove,
    RemoveReturnType,
} from "src/api/fetch";
import { RolesPath, Url } from "src/api/paths";
import {
    Type_usr_post_role,
    Type_usr_put_role,
} from "src/api/tms-users/rolesAndPermissions/types";

//ROLES
export const indexRoles = (): GetReturnType => {
    return get(`${Url.USERS}${RolesPath.ROLES}`);
};

export const showRole = (id: number): GetReturnType => {
    return get(`${Url.USERS}${RolesPath.ROLES}/${id}`);
};

export const createRole = (role_Type: Type_usr_post_role): PostReturnType => {
    return post(role_Type, `${Url.USERS}${RolesPath.ROLES}`);
};

export const updateRole = (data: Type_usr_put_role): PostReturnType => {
    return put(data, `${Url.USERS}${RolesPath.ROLES}/${data.id}`);
};

export const statusRole = (data: Type_usr_put_role): PutReturnType => {
    return put(data, `${Url.USERS}${RolesPath.ROLES}/${data.id}`);
};

export const deleteRole = (id: number): RemoveReturnType => {
    return remove({ id }, `${Url.USERS}${RolesPath.ROLES}/${id}`);
};

export const selectListRoles = (): GetReturnType => {
    return get(`${Url.USERS}${RolesPath.ROLES}`);
};

//ROLE TYPES
export const indexRoleTypes = (): GetReturnType => {
    return get(`${Url.USERS}${RolesPath.ROLE_TYPES}`);
};
