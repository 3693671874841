import { FormikValues, useFormik } from "formik";
import { Dispatch, SetStateAction } from "react";
import * as Yup from "yup";

import { Type_formData_NewUser_Invitation } from "src/pages/Pages_Common/Page_NewUser_Invitation/Page_NewUser_Invitation.container";

import { Step_3Component } from "./Step_3.component";

export type Type_Step_3ContainerProps = {
    formData: Type_formData_NewUser_Invitation;
    setFormData: Dispatch<SetStateAction<Type_formData_NewUser_Invitation>>;
    step: number;
    maxStep: number;
    handleStep: (stateAction: "previous" | "next") => void;
    metadataUsersForm: any;
};

const Schema_NewUser_Invitation_Step_3 = Yup.object().shape({});

export const Step_3Container = ({
    formData,
    setFormData,
    step,
    maxStep,
    handleStep,
    metadataUsersForm,
}: Type_Step_3ContainerProps) => {
    const handleSubmit = async (values: FormikValues) => {
        try {
            setFormData({
                ...formData,
                metadata: values.metadata,
            });
            handleStep("next");
        } catch (e: any) {
            console.error(e.errors);
        }
    };

    const formik: any = useFormik({
        initialValues: formData,
        validationSchema: Schema_NewUser_Invitation_Step_3,
        onSubmit: handleSubmit,
        validateOnChange: false,
        validateOnBlur: false,
    });

    return (
        <Step_3Component
            metadataUsersForm={metadataUsersForm}
            step={step}
            maxStep={maxStep}
            handleStep={handleStep}
            formik={formik}
        />
    );
};
