import * as React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { TradeKeys } from "src/api/tms-projects/keys";
import {
    formatterIndexTrade,
    formatterIndexTrades,
    formatterSelectListTrade,
    formatterShowTrade,
} from "src/api/tms-projects/trades/formatters";
import {
    createTrade,
    deleteTrade,
    exportTrades,
    importTrades,
    indexTrades,
    selectListTrades,
    showTrade,
    statusTrade,
    updateTrade,
} from "src/api/tms-projects/trades/services";
import {
    Type_prj_post_trade,
    Type_prj_put_trade,
    Type_selectList_trade,
} from "src/api/tms-projects/trades/types";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const useIndexTrades = () => {
    const { requestConfig } = useProject();
    return useQuery({
        queryKey: [TradeKeys.INDEX, requestConfig],
        queryFn: () => indexTrades(requestConfig),
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Error format data: useIndexTrades");
            }

            return formatterIndexTrades(data.data.data);
        },
        enabled: !!requestConfig.projectId,
    });
};

export const mutationStatusTrade = () => {
    const { requestConfig } = useProject();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (data: Type_prj_put_trade) => {
            return statusTrade(data, requestConfig);
        },
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Error format data: mutationStatusTrade");
            }

            await queryClient.invalidateQueries({
                queryKey: [TradeKeys.INDEX, requestConfig],
            });
        },
    });
};

export const mutationCreateTrade = () => {
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Trades");
    const { addSuccess, addWarning } = useToast();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (trade: Type_prj_post_trade) =>
            createTrade(trade, requestConfig),
        onSuccess: async (data: any) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Error format data: mutationCreateTrade");
            }

            const formattedData = formatterIndexTrade(data.data.data);

            addSuccess({
                description: fmt("ToastSuccess", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        trade: formattedData.name,
                    },
                }),
            });

            await queryClient.invalidateQueries({
                queryKey: [TradeKeys.INDEX, requestConfig],
            });
        },
        onError: (err: any): void => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationUpdateTrade = () => {
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Trades");
    const { addSuccess, addWarning } = useToast();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (tradeToUpdate: Type_prj_put_trade) =>
            updateTrade(tradeToUpdate, requestConfig),
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Error format data: mutationUpdateTrade");
            }

            const formattedData = formatterIndexTrade(data.data.data);

            addSuccess({
                description: fmt("ToastSuccessUpdate", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        trade: formattedData.name,
                    },
                }),
            });

            await queryClient.invalidateQueries({
                queryKey: [TradeKeys.INDEX, requestConfig],
            });
        },
        onError: (err: any): void => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const useSelectListTrades = (enabled: boolean = true) => {
    const { requestConfig, isLoading: isLoadingRequestConfig } = useProject();
    return useQuery({
        queryKey: [TradeKeys.SELECT_LIST, requestConfig],
        queryFn: () => selectListTrades(requestConfig),
        refetchOnWindowFocus: false,
        select: (data): Type_selectList_trade[] => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Error format data: useSelectListTrades");
            }

            return formatterSelectListTrade(data.data.data);
        },
        enabled:
            !!requestConfig.projectId && !isLoadingRequestConfig && enabled,
    });
};

export const useShowTrade = (id: number) => {
    const { requestConfig } = useProject();
    return useQuery(
        [TradeKeys.SHOW, id, requestConfig],
        () => showTrade(id, requestConfig),
        {
            enabled: !!id && !!requestConfig.projectId,
            select: (data: any) => {
                if (!data?.success || !data?.data?.data) {
                    throw new Error("Error format data: useShowTrade");
                }

                return formatterShowTrade(data.data.data);
            },
            refetchOnWindowFocus: false,
        },
    );
};

export const useExportTrades = () => {
    const { requestConfig } = useProject();
    return useQuery({
        queryKey: [TradeKeys.EXPORT, requestConfig],
        queryFn: () => exportTrades(requestConfig),
        refetchOnWindowFocus: false,
        onSuccess: (response): void => {
            // Create a Blob from the data
            const blob = new Blob([response.data], {
                type: response.headers["content-type"],
            });

            // Create a temporary anchor element
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `${Date.now()}_trades.xlsx`;

            // Trigger the download
            document.body.appendChild(a);
            a.click();

            // Cleanup
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        },
        enabled: false,
    });
};

export const mutationImportTrades = () => {
    const queryClient = useQueryClient();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Errors");
    const { requestConfig } = useProject();
    const { addWarning, addSuccess } = useToast();

    return useMutation({
        mutationFn: (data: any) => importTrades(data, requestConfig),
        onSuccess: async (response) => {
            if (!response?.success || !response?.data?.data) {
                throw new Error("Error format data: mutationImportTrades");
            }

            addSuccess({
                description: response.data.data,
            });

            await queryClient.invalidateQueries({
                queryKey: [TradeKeys.INDEX, requestConfig],
            });
        },
        onError: (err: any) => {
            console.debug("ERROR mutationImportTrades", err);
            addWarning({
                description: fmt("GenericError", {}),
            });
            return err;
        },
    });
};

export const mutationDeleteTrade = () => {
    const { addWarning, addSuccess } = useToast();
    const { formatMessageWithPartialKey: fmtError } = useCoreIntl("Errors");
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Trades");
    const { requestConfig } = useProject();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (id: number) => deleteTrade(id, requestConfig),
        onSuccess: async (data) => {
            if (!data?.success) {
                throw new Error("Error format data: mutationDeleteTrade");
            }

            await queryClient.invalidateQueries({
                queryKey: [TradeKeys.INDEX, requestConfig],
            });

            addSuccess({
                description: fmt("ToastSuccessDelete"),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtError("CantDeleteSubTrade", {}),
            });
            return err;
        },
    });
};
