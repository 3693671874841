import {
    FormControl,
    FormControlLabel,
    InputAdornment,
    Radio,
    RadioGroup,
    Stack,
    Typography,
} from "@mui/material";
import { FormikProvider } from "formik";
import React from "react";

import { Type_com_search_company } from "src/api/tms-commons/companies/types";
import { Spinner, TMC_Button, TMC_TextField } from "src/components";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import {
    Styled_Form,
    Styled_Scrollable_Stack,
} from "src/pages/Pages_Common/Page_NewUser_Invitation/Page_NewUser_Invitation.style";
import { COLORS } from "src/theme/stylesheet";

export type Type_SearchCompanyComponentProps = {
    step: number;
    maxStep: number;
    handleStep: (stateAction: "previous" | "next") => void;
    formik: any;
    isLoading: boolean;
    isSuccess: boolean;
    data: any; // TODO improve
    companySearch: string;
    debouncedOnChange: any; // TODO improve,
    handleCreateCompanyButton: (searchString: string) => void;
};

/**
 *
 * @param value
 * @param search
 */
const setBoldText = (value: string, search: string): JSX.Element => {
    const companyName = value.toLowerCase();
    const highlightTest = search.toLowerCase();

    if (!companyName.includes(highlightTest)) {
        return <Typography>{value}</Typography>;
    }

    const indexOfHighlightText = companyName.indexOf(highlightTest);

    return (
        <>
            {indexOfHighlightText !== 0 && (
                <Typography component="span">
                    {companyName.slice(0, indexOfHighlightText)}
                </Typography>
            )}
            <Typography variant="body1BoldUnderline" component="span">
                {highlightTest}
            </Typography>
            <Typography component="span">
                {companyName.slice(
                    indexOfHighlightText + highlightTest.length,
                    companyName.length,
                )}
            </Typography>
        </>
    );
};

export const SearchCompanyComponent = ({
    step,
    maxStep,
    handleStep,
    formik,
    isLoading,
    isSuccess,
    data,
    companySearch,
    debouncedOnChange,
    handleCreateCompanyButton,
}: Type_SearchCompanyComponentProps) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Teamoty.NewUserInvitation",
    );
    const { formatMessageWithPartialKey: fmtCta } = useCoreIntl("Form.Cta");

    return (
        <FormikProvider value={formik}>
            <Styled_Form data-testid={`UsersInvitation-step-${step}-form`}>
                <Stack rowGap={6}>
                    <Stack rowGap={"8px"} width={"100%"}>
                        <Typography
                            variant="body2"
                            color={COLORS.moon600}
                            data-testid={`UsersInvitation-step-${step}-of-${maxStep}`}
                        >
                            {fmt("Steps", {
                                values: {
                                    currentStep: step,
                                    maxStep: maxStep,
                                },
                            })}
                        </Typography>
                        <Typography
                            variant="h1"
                            data-testid={`UsersInvitation-step-${step}-title`}
                        >
                            {fmt("Step4.Title")}
                        </Typography>
                    </Stack>
                    <Stack rowGap={2}>
                        <TMC_TextField
                            data-testid={`UsersInvitation-step-${step}-SearchCompany-input`}
                            label={fmt("Step4.Label.Company")}
                            placeholder={fmt("Step4.Label.CompanyPlaceholder")}
                            defaultValue={companySearch}
                            onChange={(e) => debouncedOnChange(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Icon
                                            variant="solid"
                                            icon="search"
                                            sx={{ color: COLORS.moon900 }}
                                        />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {formik?.errors?.company_id && (
                            <Typography
                                paddingX={1}
                                variant={"body1"}
                                color={"error.main"}
                                data-testid="UserInvitation-SearchCompany-HelperText-company_id"
                            >
                                {formik.errors.company_id}
                            </Typography>
                        )}
                        <Styled_Scrollable_Stack>
                            {isLoading && (
                                <Stack alignItems="center">
                                    <Spinner />
                                </Stack>
                            )}

                            {!isLoading &&
                                isSuccess &&
                                data &&
                                data.length === 0 && (
                                    <Typography
                                        data-testid={`UsersInvitation-step-${step}-NoCompanyFound`}
                                        variant="body2"
                                        color={COLORS.moon500}
                                    >
                                        {fmt("Step4.NoCompaniesFound")}
                                    </Typography>
                                )}

                            {!isLoading && isSuccess && data && (
                                <FormControl
                                    data-testid={`UsersInvitation-step-${step}-searchCompanyResults-container`}
                                >
                                    <RadioGroup
                                        aria-labelledby="company_id"
                                        defaultValue={formik.values?.company_id}
                                        {...formik.getFieldProps("company_id")}
                                    >
                                        {data.map(
                                            (
                                                company: Type_com_search_company,
                                            ) => (
                                                <FormControlLabel
                                                    data-testid={`UsersInvitation-step-${step}-company_id-${company.id}`}
                                                    key={company.id}
                                                    value={company.id}
                                                    control={<Radio />}
                                                    label={
                                                        <>
                                                            {setBoldText(
                                                                company.name,
                                                                companySearch,
                                                            )}
                                                            <Typography
                                                                variant="body2"
                                                                color={
                                                                    COLORS.moon500
                                                                }
                                                            >{`${company.postalCode} - ${company.city} - ${company.country}`}</Typography>
                                                        </>
                                                    }
                                                    sx={{ my: 3 }}
                                                />
                                            ),
                                        )}
                                    </RadioGroup>
                                </FormControl>
                            )}
                        </Styled_Scrollable_Stack>

                        {!isLoading && isSuccess && (
                            <TMC_Button
                                data-testid={`UsersInvitation-step-${step}-AddMyCompany-btn`}
                                onClick={() =>
                                    handleCreateCompanyButton(companySearch)
                                }
                                variant="text"
                            >
                                <Typography
                                    variant="body1Underline"
                                    color={COLORS.blue700}
                                >
                                    {fmt("Step4.Cta.AddMyCompany")}
                                </Typography>
                            </TMC_Button>
                        )}
                    </Stack>
                </Stack>
                <Stack direction={"row"} justifyContent={"space-between"}>
                    <TMC_Button
                        data-testid={`UsersInvitation-step-${step}-GoBack-btn`}
                        onClick={() => handleStep("previous")}
                        type="button"
                        variant="text"
                        disabled={formik.isSubmitting}
                        startIcon={
                            !formik.isSubmitting && (
                                <Icon variant="solid" icon="arrow-left" />
                            )
                        }
                        sx={{
                            width: "fit-content",
                            blockSize: "fit-content",
                            color: COLORS.blue700,
                        }}
                    >
                        {fmtCta("Back")}
                    </TMC_Button>
                    <TMC_Button
                        data-testid={`UsersInvitation-step-${step}-Submit-btn`}
                        type="submit"
                        variant="contained"
                        disabled={
                            formik.isSubmitting ||
                            (isSuccess && data && data.length === 0)
                        }
                        endIcon={
                            !formik.isSubmitting && (
                                <Icon variant="solid" icon="arrow-right" />
                            )
                        }
                        sx={{ width: "fit-content", blockSize: "fit-content" }}
                    >
                        {formik.isSubmitting ? (
                            <Spinner style="button" />
                        ) : (
                            fmt("Step4.Cta.Submit")
                        )}
                    </TMC_Button>
                </Stack>
            </Styled_Form>
        </FormikProvider>
    );
};
