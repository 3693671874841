import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    PutReturnType,
    remove,
    RemoveReturnType,
} from "src/api/fetch";
import { ResourcePath, Url } from "src/api/paths";
import {
    Type_com_post_resourceFamily,
    Type_com_put_resourceFamily,
} from "src/api/tms-commons/resourceFamilies/types";

export const indexResourceFamilies = (): GetReturnType => {
    return get(`${Url.COMMONS}${ResourcePath.RESOURCE_FAMILIES}`);
};

export const createResourceFamily = (
    data: Type_com_post_resourceFamily,
): PostReturnType => {
    return post(data, `${Url.COMMONS}${ResourcePath.RESOURCE_FAMILIES}`);
};

export const showResourceFamily = (id: number): GetReturnType => {
    return get(`${Url.COMMONS}${ResourcePath.RESOURCE_FAMILIES}/${id}`);
};

export const updateResourceFamily = (
    data: Type_com_put_resourceFamily,
    id: number,
): PutReturnType => {
    return put(data, `${Url.COMMONS}${ResourcePath.RESOURCE_FAMILIES}/${id}`);
};

export const deleteResourceFamily = (id: number): RemoveReturnType => {
    return remove(
        { id },
        `${Url.COMMONS}${ResourcePath.RESOURCE_FAMILIES}/${id}`,
    );
};

export const statusResourceFamily = (
    data: Type_com_put_resourceFamily,
    id: number,
): PutReturnType => {
    return put(data, `${Url.COMMONS}${ResourcePath.RESOURCE_FAMILIES}/${id}`);
};

export const selectListResourceFamilies = (): GetReturnType => {
    return get(`${Url.COMMONS}${ResourcePath.RESOURCE_FAMILIES_SELECT_LIST}`);
};
