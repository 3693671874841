import { day } from "@cimba-digital-construction-solution/utils/dist/decoder/const";
import {
    Type_planningElement,
    Type_planningTask,
} from "@cimba-digital-construction-solution/utils/dist/decoder/types";
import Konva from "konva";
import React, { RefObject, useEffect, useRef } from "react";
import { Group, Rect, Text } from "react-konva";
import { Portal } from "react-konva-utils";

import { Type_planningLine } from "src/components/Components_Teamoty/Planning/Areas/PlanningArea.type";
import {
    borderDashStylePlanningTask,
    strokeWidthPlanningSequence,
} from "src/components/Components_Teamoty/Planning/Planning.const";
import { changeCursor } from "src/components/Components_Teamoty/Planning/tools/changeCursor";
import { useChannel } from "src/hooks/useChannel";
import { COLORS } from "src/theme/stylesheet";

const DEFAULT_SEQUENCE_COLOR = COLORS.teamotyBlue500;

type Type_Props_PlanningSequence = {
    id: string;
    task: Type_planningTask;
    sequence: Type_planningElement;
    line: Type_planningLine;
    heightArea: number;
    widthDate: number;
};

export const PlanningSequence = ({
    id,
    line,
    widthDate,
    sequence,
    task,
    heightArea,
}: Type_Props_PlanningSequence) => {
    const rectHeight = 20;
    const paddingAmount = 4;
    const paddingText = 6;

    const { sendEvent } = useChannel({});

    const refSeqName: RefObject<Konva.Rect> = useRef(null);
    const refSeqBox: RefObject<Konva.Rect> = useRef(null);
    const refSeqNameText: RefObject<Konva.Text> = useRef(null);

    const minX = Math.round(
        (sequence.beginDate?.pos as number) +
            ((sequence.begin % day) / day) * widthDate -
            paddingAmount,
    );

    const maxX = Math.round(
        (sequence.endDate?.pos as number) +
            ((sequence.end % day) / day) * widthDate +
            paddingAmount,
    );

    const minY = line.pos - task.y * heightArea;
    const maxY = minY + sequence.size * heightArea;

    const width = maxX - minX;
    const height = maxY - minY;

    const sequenceColor = DEFAULT_SEQUENCE_COLOR;

    const handleOpenSequence = (sequenceId: number) => {
        sendEvent("openPert", {
            id: sequenceId,
            open: true,
        });
    };

    useEffect(() => {
        if (refSeqName.current && refSeqBox.current && refSeqNameText.current) {
            refSeqNameText.current.text(sequence.name);

            let nameWidth =
                refSeqNameText.current.measureSize(sequence.name).width +
                2 * paddingText;

            if (nameWidth > width) {
                // Calculate max allowed text length
                nameWidth = width;
                refSeqNameText.current.ellipsis(true);
            }

            refSeqName.current.width(nameWidth);
            refSeqNameText.current.width(nameWidth - paddingText);
        }
    }, [sequence.name, refSeqBox, refSeqName, refSeqNameText]);

    return (
        <Group key={id}>
            <Portal enabled={true} selector=".bottomTasks">
                <Rect
                    ref={refSeqBox}
                    x={minX}
                    y={minY}
                    width={width}
                    height={height}
                    stroke={sequenceColor}
                    fill={`${sequenceColor}10`}
                    strokeWidth={strokeWidthPlanningSequence}
                    dash={borderDashStylePlanningTask}
                />
            </Portal>
            <Portal enabled={true} selector=".topTasks">
                <Group
                    onClick={() => handleOpenSequence(sequence.id)}
                    onMouseOver={(e: Konva.KonvaEventObject<MouseEvent>) =>
                        changeCursor(e, "pointer")
                    }
                    onMouseOut={(e: Konva.KonvaEventObject<MouseEvent>) =>
                        changeCursor(e, "default")
                    }
                    y={minY - rectHeight}
                    x={minX}
                >
                    <Rect
                        key={"rect-" + id}
                        ref={refSeqName}
                        width={0}
                        height={rectHeight}
                        fill={sequenceColor}
                        stroke={sequenceColor}
                        strokeWidth={strokeWidthPlanningSequence}
                    />
                    <Text
                        key={"text-" + id}
                        offsetX={-1 * paddingText}
                        ref={refSeqNameText}
                        ellipsis={false}
                        width={0}
                        height={rectHeight}
                        text={sequence.name}
                        align="left"
                        verticalAlign="middle"
                        fontSize={11}
                        fill={COLORS.white}
                    />
                </Group>
            </Portal>
        </Group>
    );
};
