import { Grid, Stack, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { ColorPickerIcon } from "src/components/Components_Common/forms/reactHookFormComponents/ColorPicker/ColorPickerIcon";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useLanguage } from "src/contexts/languages";
import { Styled_Input } from "src/forms/sequence/SequenceForm";
import { useContextualDrawer } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";
import { invertColor } from "src/utils/colors";

export const SequenceHeader = () => {
    const { languages } = useLanguage();
    const { control, register } = useFormContext();
    const { closePaper } = useContextualDrawer();
    const sequenceColor = useWatch({
        name: "color",
        defaultValue: null,
        control: control,
    });

    const headerBackgroundColor = useMemo(() => {
        return sequenceColor;
    }, [sequenceColor]);

    const localeToShortLocale = (locale: string) =>
        locale.slice(0, 2).toUpperCase();

    return (
        <Stack
            padding={2}
            spacing={4}
            sx={{
                backgroundColor: headerBackgroundColor,
                color: invertColor(headerBackgroundColor || "#FFF"),
                boxShadow: `0px -1px 0px 0px rgba(22, 50, 95, 0.18) inset`,
            }}
        >
            <Stack
                direction={"row"}
                spacing={1}
                justifyContent={"end"}
                paddingX={2}
            >
                <IconButton
                    color={"inherit"}
                    onClick={() => closePaper("sequence")}
                    size={"small"}
                    data-testid={"sequence-drawer-iconButton-close"}
                >
                    <Icon variant={"solid"} icon={"xmark"} />
                </IconButton>
            </Stack>
            <Stack direction={"row"} alignItems={"start"}>
                <Stack flexGrow={1}>
                    {languages.map((language, index) => (
                        <Grid container key={language} rowSpacing={0.5}>
                            <Grid item xs={1}>
                                {index === 0 ? ( // TODO should be refactored
                                    <Stack
                                        justifyContent={"center"}
                                        alignItems={"end"}
                                    >
                                        <Icon
                                            variant={"kit"}
                                            icon={"light-tds-task-work"}
                                            fontSize={"large"}
                                        />
                                    </Stack>
                                ) : (
                                    <Typography
                                        variant={"body3"}
                                        paddingInlineStart={3}
                                    >
                                        {localeToShortLocale(language)}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item xs={11} paddingInlineStart={2}>
                                <Styled_Input
                                    {...register(`names.${language}`)}
                                    data-testid={`sequence-drawer-input-name-${language}`}
                                />
                            </Grid>
                        </Grid>
                    ))}
                </Stack>
                <ColorPickerIcon
                    name="color"
                    data-testid={"sequence-drawer-colorPicker"}
                />
            </Stack>
        </Stack>
    );
};
