import { LINK_DIRECTION } from "src/api/tms-scheduling/taskLinks/types";
import { TaskAreaLinkForm } from "src/forms/taskArea/TaskAreaFormComponents/AccordionForms/Links/TaskAreaLinkForm";
import { Styled_PaperContextualLayout } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

type Type_Props_TaskAreaLinkContextualDrawer = {
    onClose: () => void;
    id: number;
    type: LINK_DIRECTION;
};
export const TaskAreaLinkContextualDrawer = ({
    onClose,
    id,
    type,
}: Type_Props_TaskAreaLinkContextualDrawer) => {
    return (
        <Styled_PaperContextualLayout id="taskAreaLink">
            <TaskAreaLinkForm onClose={onClose} id={id} type={type} />
        </Styled_PaperContextualLayout>
    );
};
