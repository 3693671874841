import {
    Box,
    Divider,
    ListItemIcon,
    ListItemText,
    MenuList,
    Popover,
    Stack,
    Typography,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import {
    TreeItem2Content,
    TreeItem2GroupTransition,
    TreeItem2Icon,
    TreeItem2IconContainer,
    TreeItem2Label,
    TreeItem2Provider,
    TreeItem2Root,
    UseTreeItem2Parameters,
    useTreeItem2Utils,
} from "@mui/x-tree-view";
import { unstable_useTreeItem2 as useTreeItem2 } from "@mui/x-tree-view/useTreeItem2";
import React, { forwardRef, ReactElement, useState } from "react";

import {
    Type_index_sequenceFolderTree_sequence,
    Type_sequenceFolderTree_item,
} from "src/api/tms-scheduling/sequenceFolders/types";
import { mutationCreateDefaultSequence } from "src/api/tms-scheduling/sequences";
import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { SequenceBadge } from "src/components/Components_Teamoty/badges/SequenceBadge";
import { TaskBadge } from "src/components/Components_Teamoty/badges/TaskBadge";
import { useChannel } from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { useContextualDrawer } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

const CustomTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
    flexDirection: "row-reverse",
    padding: theme.spacing(1.5, 4),
    borderRadius: 0,
    ":hover": {
        backgroundColor: theme.palette.action.hover,
        ".buttonGroup": {
            visibility: "visible",
        },
    },
    ".buttonGroup": {
        visibility: "hidden",
    },
}));

interface CustomLabelProps {
    children: React.ReactNode;
    item: Type_sequenceFolderTree_item;
    expandable?: boolean | number;
}

const CustomLabel = ({
    item,
    children,
    ...other
}: CustomLabelProps): ReactElement => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.SequenceFolders",
    );
    const { mutateAsync } = mutationCreateDefaultSequence();
    const { sendEvent } = useChannel({});
    const { openPaper, closePaper } = useContextualDrawer();
    const handleCreate = async (id: number) => {
        const respSequence = await mutateAsync(id);
        const sequenceId = respSequence.data.data.id;
        closePaper("sequences");
        openPaper("sequence", { id: sequenceId });
    };

    if (item.itemType === "folder") {
        const handleClick = (
            event: React.MouseEvent,
            action: string,
            id: number,
        ) => {
            event.stopPropagation();
            action === "edit" &&
                sendEvent("openDialogFolder", {
                    open: true,
                    action: "update",
                    data: { name: "createOrUpdateSequenceFolder", id: id },
                });
            if (action === "create") handleCreate(id);
        };
        return (
            <TreeItem2Label
                {...other}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Stack direction="row" alignItems="center" gap={1}>
                    <Icon
                        variant={"light"}
                        icon={"folder"}
                        fontSize={"small"}
                    />
                    <Typography variant="body2Medium">
                        {children} ({item.children.length})
                    </Typography>
                </Stack>
                <Box sx={{ justifySelf: "end" }} className={"buttonGroup"}>
                    <IconButton
                        onClick={(e) => handleClick(e, "edit", item.id)}
                        name={"editSequenceFolderBtn"}
                        type="button"
                        size="small"
                        title={fmt("EditFolder")}
                    >
                        <Icon
                            variant="light"
                            icon="pen"
                            fontSize="small"
                            data-testid="sequence-edit-sequenceFolder-btn"
                        />
                    </IconButton>
                    <IconButton
                        onClick={(e) => handleClick(e, "create", item.id)}
                        name={"createSequenceBtn"}
                        type="button"
                        size="small"
                        title={fmt("CreateSequence")}
                    >
                        <Icon
                            variant="light"
                            icon="plus"
                            fontSize="small"
                            data-testid="sequence-create-sequence-btn"
                        />
                    </IconButton>
                </Box>
            </TreeItem2Label>
        );
    } else if (item.itemType === "sequence") {
        const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(
            null,
        );

        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();
            setAnchorEl(event.currentTarget);
        };

        const handleClose = (event: React.MouseEvent<HTMLLIElement>) => {
            event.stopPropagation();
            setAnchorEl(null);
        };

        const handleClickMenuItem = (
            e: React.MouseEvent<HTMLLIElement>,
            item: Type_sequenceFolderTree_item,
        ) => {
            handleClose(e);
            sendEvent("openDialogFolder", {
                open: true,
                data: { name: "duplicateSequence", ...item },
            });
        };

        const open = Boolean(anchorEl);
        const id = open ? "simple-popover" : undefined;

        return (
            <TreeItem2Label
                {...other}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <SequenceBadge
                    sequence={item as Type_index_sequenceFolderTree_sequence}
                />
                <Box className={"buttonGroup"}>
                    <IconButton
                        aria-describedby={id}
                        onClick={(e) => handleClick(e)}
                        name={"moreOptionsSeqenceBtn"}
                        type="button"
                        size="small"
                        title={fmt("Sequence.MoreOptions")}
                    >
                        <Icon
                            variant="light"
                            icon="ellipsis-vertical"
                            fontSize="small"
                            data-testid="sequence-more-btn"
                        />
                    </IconButton>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                    >
                        <MenuList
                            sx={(theme) => ({
                                minWidth: "320px",
                                color: theme.palette.text.primary,
                            })}
                        >
                            <MenuItem
                                onClick={(e) => handleClickMenuItem(e, item)}
                            >
                                <ListItemIcon>
                                    <Icon
                                        variant="light"
                                        icon="copy"
                                        data-testid="sequence-more-copy-btn"
                                    />
                                </ListItemIcon>
                                <ListItemText>
                                    {fmt("Sequence.More.Duplicate")}
                                </ListItemText>
                            </MenuItem>
                            <MenuItem onClick={(e) => handleClose(e)}>
                                <ListItemIcon>
                                    <Icon
                                        variant="light"
                                        icon="circle-dashed"
                                        data-testid="sequence-more-disable-btn"
                                    />
                                </ListItemIcon>
                                <ListItemText>
                                    {fmt("Sequence.More.Disable")}
                                </ListItemText>
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={(e) => handleClose(e)}>
                                <ListItemIcon>
                                    <Icon
                                        variant="light"
                                        icon="folder"
                                        data-testid="sequence-more-moveToFolder-btn"
                                    />
                                </ListItemIcon>
                                <ListItemText>
                                    {fmt("Sequence.More.MoveToFolder")}
                                </ListItemText>
                            </MenuItem>
                            <Divider />
                            <MenuItem
                                onClick={(e) => handleClose(e)}
                                sx={(theme) => ({
                                    color: theme.palette.error.main,
                                })}
                            >
                                <ListItemIcon>
                                    <Icon
                                        variant="light"
                                        icon="trash"
                                        data-testid="sequence-more-delete-btn"
                                    />
                                </ListItemIcon>
                                <ListItemText>
                                    {fmt("Sequence.More.Delete")}
                                </ListItemText>
                            </MenuItem>
                        </MenuList>
                    </Popover>
                </Box>
            </TreeItem2Label>
        );
    } else {
        return (
            <TreeItem2Label
                {...other}
                sx={{
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <TaskBadge task={item} />
            </TreeItem2Label>
        );
    }
};

const isExpandable = (reactChildren: React.ReactNode) => {
    if (Array.isArray(reactChildren)) {
        return reactChildren.length > 0 && reactChildren.some(isExpandable);
    }
    return Boolean(reactChildren);
};

interface CustomTreeItemProps
    extends Omit<UseTreeItem2Parameters, "rootRef">,
        Omit<React.HTMLAttributes<HTMLLIElement>, "onFocus"> {}

export const SequencesTreeItem = forwardRef(function CustomTreeItem(
    props: CustomTreeItemProps,
    ref: React.Ref<HTMLLIElement>,
) {
    const { id, itemId, label, disabled, children, ...other } = props;

    const {
        getRootProps,
        getContentProps,
        getIconContainerProps,
        getLabelProps,
        getGroupTransitionProps,
        status,
        publicAPI,
    } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });

    const item = publicAPI.getItem(itemId);
    const expandable = isExpandable(children);

    const { interactions } = useTreeItem2Utils({
        itemId: props.itemId,
        children: props.children,
    });

    const { sendEvent } = useChannel({});
    const { openPaper } = useContextualDrawer();

    const handleContentClick = (
        itemType: "folder" | "sequence" | "task",
        id: number,
    ): void => {
        if (itemType === "sequence") {
            sendEvent("openPert", {
                id: id,
                open: true,
            });
        } else if (itemType === "task") {
            openPaper("task", { id });
        }
    };

    const handleIconContainerClick = (event: React.MouseEvent): void => {
        interactions.handleExpansion(event);
        event.stopPropagation();
    };

    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <TreeItem2Provider itemId={itemId}>
            <TreeItem2Root {...getRootProps(other)}>
                <CustomTreeItemContent
                    {...getContentProps()}
                    onClick={() => handleContentClick(item.itemType, item.id)}
                >
                    <TreeItem2IconContainer
                        {...getIconContainerProps()}
                        onClick={handleIconContainerClick}
                    >
                        <TreeItem2Icon status={status} />
                    </TreeItem2IconContainer>
                    <CustomLabel
                        {...getLabelProps({
                            item,
                            expandable: +expandable && +status.expanded,
                        })}
                    />
                </CustomTreeItemContent>
                {children && (
                    <TreeItem2GroupTransition {...getGroupTransitionProps()} />
                )}
            </TreeItem2Root>
        </TreeItem2Provider>
    );
});
