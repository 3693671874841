import {
    Card,
    CardContent,
    CardMedia,
    CardMediaProps,
    Chip,
    Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { COLORS } from "src/theme/stylesheet";

interface Styled_CardMediaProps extends CardMediaProps {
    ownerState?: {
        status:
            | "is_active"
            | "is_archived"
            | "is_disabled"
            | "is_expired"
            | "not_started";
    };
}
export const Styled_Card = styled(Card)(() => ({
    width: 240,
    height: 320,
    borderColor: COLORS.moon200,
    borderWidth: "1px",
    borderRadius: "8px",
}));

export const Styled_CardMedia = styled(CardMedia)<Styled_CardMediaProps>(
    ({ ownerState }) => ({
        height: 190,
        ...(ownerState?.status === "is_disabled" && {
            filter: "grayscale(100%)",
        }),
        ...(ownerState?.status === "is_expired" && {
            filter: "grayscale(100%)",
        }),
    }),
);

export const Styled_CardContent = styled(CardContent)(() => ({
    height: 130,
    padding: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "&.MuiCardContent-root": {
        "&:last-child": {
            paddingBottom: "8px",
        },
    },
}));

export const Styled_CardStack = styled(Stack)(() => ({
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    alignItems: "center",
}));

export const Styled_CardChip = styled(Chip)(() => ({
    "&.MuiChip-root": {
        backgroundColor: COLORS.yellow500,
        padding: 0,
        height: "auto",
        borderRadius: "4px",
        "& .MuiChip-label": {
            padding: "2px 4px",
        },
    },
}));
