import { useEffect, useState } from "react";
import { ErrorsType, ImageListType } from "react-images-uploading";

import { getImage } from "src/api/storage";

import { UploadImageComponent } from "./UploadImage.component";

export type Type_UploadImageContainerProps = {
    name: string;
    value: ImageListType | string;
    setValue: (
        field: string,
        value: any,
        shouldValidate?: Partial<{
            shouldValidate: boolean;
            shouldDirty: boolean;
            shouldTouch: boolean;
        }>,
    ) => void;
    limitResolutions?: {
        min?: number;
        max?: number;
    };
    withDescription?: boolean;
    host: string;
    service: string;
    id?: number | undefined;
};

export const UploadImageContainer = (props: Type_UploadImageContainerProps) => {
    const [errors, setErrors] = useState<ErrorsType>(null);
    const [img, setImg] = useState("");

    const onChange = (imageList: ImageListType) => {
        if (errors) {
            console.error(errors);
            setErrors(null);
        }
        props.setValue(props.name, imageList); // setValue est le handleChange de react hook form
    };

    useEffect(() => {
        const fetchImg = async () => {
            if (props.id && img === "") {
                let cacheTimestamp = "";
                if (typeof props.value === "string") {
                    cacheTimestamp = props.value.slice(props.value.length - 14);
                }

                const res = await getImage(
                    props.id,
                    props.host,
                    props.service,
                    "thumbnail",
                    cacheTimestamp,
                );
                const imageObjectURL = URL.createObjectURL(res.data);
                setImg(imageObjectURL);
            }
        };
        fetchImg();
    }, []);

    const deleteDefaultImg = () => {
        setImg("");
        onChange([]);
    };

    return (
        <UploadImageComponent
            {...props}
            onChange={onChange}
            errors={errors}
            setErrors={setErrors}
            img={img}
            deleteDefaultImg={deleteDefaultImg}
        />
    );
};
