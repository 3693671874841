import { styled } from "@mui/material/styles";
import * as React from "react";
import { useParams } from "react-router-dom";

import { useShowView } from "src/api/tms-projects/workspacesAndViews/views";
import { LoadingBox } from "src/components";
import {
    DialogFullScreen,
    Type_props_DialogFullScreen,
} from "src/components/Components_Common/Dialog/DialogFullScreen";
import { PrintSettingsForm } from "src/forms/print/PrintSettingsForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

const Styled_Layout = styled("div")({
    height: "100%",
    width: "100%",
    display: "flex",
    alignSelf: "stretch",
});

const Styled_Preview = styled("div")(({ theme }) => ({
    flexGrow: 1,
    backgroundColor: theme.palette.background.section,
}));

const Styled_Sidebar = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignSelf: "stretch",
    height: "100%",
    width: "30%",
    maxWidth: "400px",
    padding: theme.spacing(2, 4, 4, 4),
    overflow: "auto",
}));

type Type_Props_PrintSettingDialog = Omit<Type_props_DialogFullScreen, "title">;

export const PrintSettingsDialog = ({
    openDialog,
    setToggleDialog,
}: Type_Props_PrintSettingDialog) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Dialog.Print");
    const { view: viewIdParam } = useParams();

    const viewId: number = Number(viewIdParam);

    const { isFetching, data: view } = useShowView(viewId);

    return (
        <DialogFullScreen
            openDialog={openDialog}
            setToggleDialog={setToggleDialog}
            title={fmt("Title")}
        >
            <Styled_Layout data-testid="Print-Layout">
                {isFetching && <LoadingBox />}
                {!isFetching && view && (
                    <>
                        <Styled_Preview data-testid="Print-Preview">
                            Preview
                        </Styled_Preview>
                        <Styled_Sidebar data-testid="Print-Form">
                            <PrintSettingsForm view={view} />
                        </Styled_Sidebar>
                    </>
                )}
            </Styled_Layout>
        </DialogFullScreen>
    );
};
