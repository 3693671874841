import { Stack } from "@mui/material";
import React from "react";

import { FormFieldsType, FormType } from "./Form.container";
import { Form_Components } from "./Form_Components";

type FormInputsPropsType = {
    fields: FormFieldsType[];
};

export const FormInputs = ({
    fields,
    onChange,
    values,
}: FormInputsPropsType | any) => {
    return (
        <Stack gap={4}>
            {fields.map((field: any, index: number) => {
                const FormComponent = Form_Components[field.metadataType];
                const data = field?.values;
                const label = field?.label;

                return (
                    <Stack
                        key={index}
                        flexDirection="column"
                        data-testid={`MetadataForm-input-fieldName-${field?.name}`}
                    >
                        <FormComponent
                            defaultValue={field?.options?.defaultValue}
                            value={values?.[field?.name]}
                            placeholder={""}
                            name={field?.name}
                            label={label}
                            data={data}
                            onChange={onChange}
                        />
                    </Stack>
                );
            })}
        </Stack>
    );
};

/**
 * @deprecated
 */
export const Form = ({ fields, values, onChange }: FormType) => {
    return <FormInputs fields={fields} onChange={onChange} values={values} />;
};
