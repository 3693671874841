import { FormikValues } from "formik";

import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    PutReturnType,
    remove,
    RemoveReturnType,
    Type_RequestConfig,
} from "src/api/fetch";
import { getFilterJoin, Type_Filter_Sequence } from "src/api/filters";
import { SequenceArea, Url } from "src/api/paths";

export const indexSequenceAreas = (
    requestConfig: Type_RequestConfig,
    filters: Type_Filter_Sequence,
): GetReturnType => {
    const urlParams: string = getFilterJoin(filters);

    return get(
        `${Url.SCHEDULING}${SequenceArea.SEQUENCE_AREAS}${urlParams}`,
        requestConfig,
    );
};

export const createSequenceArea = (
    data: FormikValues,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return post(
        data,
        `${Url.SCHEDULING}${SequenceArea.SEQUENCE_AREAS}`,
        requestConfig,
    );
};

export const updateSequenceArea = (
    data: any,
    requestConfig: Type_RequestConfig,
): PutReturnType => {
    return put(
        data.data,
        `${Url.SCHEDULING}${SequenceArea.SEQUENCE_AREAS}/${data?.id}`,
        requestConfig,
    );
};

export const deleteSequenceArea = (
    id: number,
    requestConfig: Type_RequestConfig,
): RemoveReturnType => {
    return remove(
        { id },
        `${Url.SCHEDULING}${SequenceArea.SEQUENCE_AREAS}/${id}`,
        requestConfig,
    );
};
