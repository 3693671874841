import { Stack } from "@mui/material";
import React from "react";

import {
    DialogFullScreen,
    Type_props_DialogFullScreen,
} from "src/components/Components_Common/Dialog/DialogFullScreen";
import { MatrixTableAreas } from "src/components/Components_Teamoty/tables/TableAreas/MatrixTableAreas";
import { TableSequenceAreas } from "src/components/Components_Teamoty/tables/TableSequenceAreas/TableSequenceAreas";
import { FiltersProvider } from "src/components/Components_Teamoty/tables/TableSequenceAreas/TableSequenceAreasFilterContext";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_AreaTableFullScreen = Omit<
    Type_props_DialogFullScreen,
    "title"
>;

export const TableAreasFullScreen = ({
    openDialog,
    setToggleDialog,
}: Type_Props_AreaTableFullScreen) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Labels");

    return (
        <DialogFullScreen
            openDialog={openDialog}
            setToggleDialog={setToggleDialog}
            title={fmt("Areas")}
        >
            <Stack direction={"column"}>
                <FiltersProvider>
                    <TableSequenceAreas sequenceId={1} />
                </FiltersProvider>
                <MatrixTableAreas />
            </Stack>
        </DialogFullScreen>
    );
};
