import { useMutation } from "react-query";

import { formatterDownLoadPrint } from "src/api/tms-print/print/formatters";
import { downloadPrint } from "src/api/tms-print/print/services";
import { Type_post_print } from "src/api/tms-print/print/types";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const mutationPrint = () => {
    const { requestConfig, project } = useProject();
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");

    return useMutation({
        mutationFn: (settings: Type_post_print) =>
            downloadPrint(formatterDownLoadPrint(settings), requestConfig),
        onSuccess: (data) => {
            if (!data?.success || !data?.data) {
                throw new Error("Wrong format data: mutationPrint");
            }

            // Create a Blob from the data
            const blob = new Blob([data.data], {
                type: "application/pdf",
            });

            // Create a temporary anchor element
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `${project?.slug}_${Date.now()}_print.pdf`;

            // Trigger the download
            document.body.appendChild(a);
            a.click();

            // Cleanup
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        },
        onError: (err: any) => {
            console.debug("ERROR mutationPrint", err);
            addWarning({
                description: fmtErrors("GenericError"),
            });
            return err;
        },
    });
};
