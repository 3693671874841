import TabContext from "@mui/lab/TabContext";
import React, { Dispatch, SetStateAction, useMemo } from "react";

import { Type_metadataProjects_For_Form } from "src/api/tms-commons/metadata/types";
import { Type_index_project } from "src/api/tms-commons/projects/types";
import { Header, LoadingBox } from "src/components";
import { Styled_MainStack } from "src/components/Components_Common/Drawer/Drawer.style";
import { Styled_TabPanelDrawerContent } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric.style";
import { useCoreIntl } from "src/hooks/useCoreIntl";

import { Tab_MetadataContainer } from "./Tab_Metadata/Tab_Metadata.container";
import { Tab_ProjectContainer } from "./Tab_Project/Tab_Project.container";

export type Type_Projects_DrawerContentComponentProps = {
    isFetching: boolean;
    onClose: () => void;
    onSubmit: (values: any, props: any) => void;
    tab: string;
    metadataProjects: Type_metadataProjects_For_Form[];
    setTab: Dispatch<SetStateAction<string>>;
    data: Type_index_project;
};

/**
 * @deprecated new Drawer comming up
 */
export const Projects_DrawerContentComponent = ({
    isFetching,
    onClose,
    onSubmit,
    tab,
    setTab,
    metadataProjects,
    data,
}: Type_Projects_DrawerContentComponentProps) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Projects.Drawer");

    const drawerTabs = useMemo(() => {
        if (!metadataProjects.length)
            return [{ label: fmt("TabsLabel.Global"), value: "0" }];
        return [
            { label: fmt("TabsLabel.Global"), value: "0" },
            { label: fmt("TabsLabel.Metadata"), value: "1" },
        ];
    }, [metadataProjects]);

    return (
        <TabContext value={tab}>
            <Styled_MainStack>
                <Header
                    onClose={onClose}
                    title={data.name || ""}
                    setTabValue={setTab}
                    drawerTabs={drawerTabs}
                    dataTestIdTitle={"Projects-Drawer-Update-Title"}
                />
                {isFetching && <LoadingBox />}
                {!isFetching && (
                    <>
                        <Styled_TabPanelDrawerContent value="0">
                            <Tab_ProjectContainer
                                data={data}
                                onSubmit={onSubmit}
                            />
                        </Styled_TabPanelDrawerContent>
                        {metadataProjects.length > 0 && (
                            <Styled_TabPanelDrawerContent value="1">
                                <Tab_MetadataContainer
                                    metadataProjects={metadataProjects}
                                    onSubmit={onSubmit}
                                    data={data}
                                />
                            </Styled_TabPanelDrawerContent>
                        )}
                        <Styled_TabPanelDrawerContent value="2"></Styled_TabPanelDrawerContent>
                    </>
                )}
            </Styled_MainStack>
        </TabContext>
    );
};
