import { Button, Stack, Typography } from "@mui/material";
import { FormikProvider } from "formik";
import React from "react";

import { Type_com_search_company } from "src/api/tms-commons/companies/types";
import { Spinner, TMC_Button } from "src/components";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import {
    Styled_Form,
    Styled_Scrollable_Stack,
} from "src/pages/Pages_Common/Page_NewUser_Invitation/Page_NewUser_Invitation.style";
import { COLORS } from "src/theme/stylesheet";

export type Type_CompareCompanyComponentProps = {
    step: number;
    maxStep: number;
    handleStep: (stateAction: "previous" | "next") => void;
    formik: any;
    handleCompanyStep: (
        stateAction: "previous" | "next",
        resetFormData: boolean,
    ) => void;
    handleSubmitKnownCompany: (company: any) => void;
    companyCreated: string;
    data: any;
};

export const CompareCompanyComponent = ({
    step,
    maxStep,
    data,
    companyCreated,
    formik,
    handleCompanyStep,
    handleSubmitKnownCompany,
}: Type_CompareCompanyComponentProps) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Teamoty.NewUserInvitation",
    );
    const { formatMessageWithPartialKey: fmtCta } = useCoreIntl("Form.Cta");

    return (
        <FormikProvider value={formik}>
            <Styled_Form data-testid={`UsersInvitation-step-${step}-form`}>
                <Stack rowGap={6}>
                    <Stack rowGap={"8px"} width={"100%"}>
                        <Typography
                            variant="body2"
                            color={COLORS.moon600}
                            data-testid={`UsersInvitation-step-${step}-of-${maxStep}`}
                        >
                            {fmt("Steps", {
                                values: {
                                    currentStep: step,
                                    maxStep: maxStep,
                                },
                            })}
                        </Typography>
                        <Typography
                            variant="h1"
                            data-testid={`UsersInvitation-step-${step}-title`}
                        >
                            {fmt("Step4Ter.Title")}
                        </Typography>
                        <Typography variant="body1">
                            {fmt("Step4Ter.Description", {
                                values: {
                                    name: (
                                        <b>{companyCreated}</b>
                                    ) as unknown as string,
                                },
                            })}
                        </Typography>
                    </Stack>
                    <Styled_Scrollable_Stack
                        data-testid={`UsersInvitation-step-${step}-knownCompany-container`}
                    >
                        {data?.map(
                            (
                                company: Type_com_search_company,
                                index: number,
                            ) => (
                                <Button
                                    data-testid={`UsersInvitation-step-${step}-knownCompany-${company.id}-btn`}
                                    onClick={() =>
                                        handleSubmitKnownCompany(company)
                                    }
                                    key={index}
                                    type="button"
                                    disabled={formik.isSubmitting}
                                    sx={{
                                        justifyContent: "start",
                                        padding: "8px",
                                    }}
                                >
                                    <Stack
                                        direction={"column"}
                                        alignItems={"start"}
                                        rowGap={"8px"}
                                    >
                                        <Typography>{company.name}</Typography>
                                        <Typography
                                            variant="body2"
                                            color={COLORS.moon500}
                                        >
                                            {`${company.postalCode} - ${company.city} - ${company.country}`}
                                        </Typography>
                                    </Stack>
                                </Button>
                            ),
                        )}
                    </Styled_Scrollable_Stack>
                </Stack>

                <Stack direction={"row"} justifyContent={"space-between"}>
                    <TMC_Button
                        data-testid={`UsersInvitation-step-${step}-GoBack-btn`}
                        onClick={() => handleCompanyStep("previous", false)}
                        type="button"
                        variant="text"
                        disabled={formik.isSubmitting}
                        startIcon={
                            !formik.isSubmitting && (
                                <Icon variant="solid" icon="arrow-left" />
                            )
                        }
                        sx={{
                            width: "fit-content",
                            blockSize: "fit-content",
                            color: COLORS.blue700,
                        }}
                    >
                        {fmtCta("Back")}
                    </TMC_Button>
                    <TMC_Button
                        data-testid={`UsersInvitation-step-${step}-Submit-btn`}
                        type="submit"
                        variant="contained"
                        disabled={formik.isSubmitting}
                        endIcon={
                            !formik.isSubmitting && (
                                <Icon variant="solid" icon="arrow-right" />
                            )
                        }
                        sx={{ width: "fit-content", blockSize: "fit-content" }}
                    >
                        {formik.isSubmitting ? (
                            <Spinner style="button" />
                        ) : (
                            fmt("Step4Ter.Cta.Submit")
                        )}
                    </TMC_Button>
                </Stack>
            </Styled_Form>
        </FormikProvider>
    );
};
