import { Badge, BadgeProps, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useMemo } from "react";

import { useShowSubTrade } from "src/api/tms-projects/subTrades";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useCoreIntl } from "src/hooks/useCoreIntl";

const Styled_Badge = styled(Badge)<BadgeProps>(() => ({
    width: 32,
    "& .MuiBadge-badge": {
        top: 6,
        left: 10,
    },
}));

type Type_Props_TeamBadge = BadgeProps & {
    subTradeId?: number;
};

export const SubTradeTeamBadge = ({
    subTradeId,
    ...props
}: Type_Props_TeamBadge) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerTasks.Main.Tooltip",
    );
    const { isFetching, data } = useShowSubTrade(subTradeId);

    const teamCount = useMemo(() => {
        if (isFetching) return "-";
        return data?.team ?? "-";
    }, [isFetching, data]);

    return (
        <Styled_Badge badgeContent={teamCount} {...props}>
            <Tooltip title={fmt("Team")} placement={"left"}>
                <Icon variant={"light"} icon={"user"} fontSize="small" />
            </Tooltip>
        </Styled_Badge>
    );
};
