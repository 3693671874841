import { useMutation, useQuery } from "react-query";

import { formatterCurrentUser } from "src/api/tms-gateway/auth/formatters";
import { getCurrentUser, login } from "src/api/tms-gateway/auth/services";
import { AuthKeys } from "src/api/tms-gateway/keys";
import { Type_loginState } from "src/pages/Pages_Common/Page_Identification/Page_Identification.container";

export const mutationLogin = () => {
    return useMutation({
        mutationFn: (loginState: Type_loginState) => login(loginState),
        onError: (err: any) => {
            return err;
        },
    });
};

export const refreshCurrentUser = () => {
    return useQuery({
        queryKey: [AuthKeys.ME],
        queryFn: getCurrentUser,
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Error format data: refreshCurrentUser");
            }

            return formatterCurrentUser(data.data.data);
        },
        onError: (err: any) => {
            console.error("ERROR get current user", err);
            return err;
        },
    });
};
