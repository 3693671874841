import React from "react";
import { Group, Text } from "react-konva";

import {
    colorPlanningDate,
    widthPlanningDateWithNameDay,
} from "src/components/Components_Teamoty/Planning/Planning.const";

import { Type_Props_PlanningDate } from "./PlanningDate.type";

export const PlanningDate = ({
    height,
    width,
    date,
    handleContextMenuDates = () => {},
}: Type_Props_PlanningDate) => {
    const text: string =
        width < widthPlanningDateWithNameDay
            ? date.date.toString()
            : date.dateNarrowText + " " + date.date;
    return (
        <Group
            x={date.pos}
            onContextMenu={(e) => handleContextMenuDates(e, date)}
        >
            <Text
                text={text}
                fontSize={12}
                width={width}
                height={height}
                align="center"
                verticalAlign="middle"
                fill={colorPlanningDate}
            />
        </Group>
    );
};
