import { Box, Stack, styled, Typography } from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { formatterIndexToEventSequence } from "src/api/tms-scheduling/sequences/formatters";
import { Type_show_sequence } from "src/api/tms-scheduling/sequences/types";
import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { Type_event_sequence, useChannel } from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { useContextualDrawer } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

const Styled_Toolbar = styled("div")(({ theme }) => ({
    flexShrink: 0,
    alignSelf: "stretch",
    height: 48,
    padding: `0 ${theme.spacing(2)}`,
    borderBottom: theme.border.default,

    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
}));

const Styled_SequenceColorChip = styled(Box)(({ theme }) => ({
    height: 24,
    width: 24,
    borderRadius: theme.shape.borderRadiusSmall,
    border: `solid 2px rgba(0, 0, 0, ${theme.opacity.high})`,
}));

type Type_Props_SequencePertToolbar = {
    handleClose: () => void;
    sequence: Type_show_sequence;
};

export const SequencePertToolbar = ({
    handleClose,
    sequence,
}: Type_Props_SequencePertToolbar) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.PertDrawer.Toolbar",
    );

    const { lang } = useParams();
    const [state, setState] = useState<Type_show_sequence>(sequence);
    const { sendEvent } = useChannel({});
    //update in real time with useChannel event "updateSequence"
    useChannel({
        eventHandler: ({ event, data }): void => {
            const sequenceData = data as Type_event_sequence;
            if (event === "updateSequence" && sequenceData?.id === state.id) {
                setState((prevState: Type_show_sequence) => ({
                    ...prevState,
                    ...formatterIndexToEventSequence(
                        lang as string,
                        sequenceData,
                    ),
                }));
            }
        },
    });

    const { openPaper } = useContextualDrawer();
    const handleEdit = (id: number | undefined): void => {
        openPaper("sequence", { id }, true);
    };

    const sortTaskAuto = () => {
        sendEvent("sortTask");
    };

    return (
        <Styled_Toolbar data-testid="SequencePertToolbar-Stack">
            <IconButton
                aria-label="organize-sequence-btn"
                color={"inherit"}
                data-testid={"Sequence-SortTask-Btn"}
                onClick={sortTaskAuto}
            >
                <Icon
                    icon="light-sitemap-sparkle"
                    variant="kit"
                    sx={{ mr: 1, width: 22 }}
                />
                <Typography variant="body2"> {fmt("Organize")}</Typography>
            </IconButton>
            <Stack
                direction={"row"}
                flexGrow={1}
                justifyContent={"center"}
                alignItems={"center"}
                spacing={2}
                sx={{ userSelect: "none" }}
            >
                <Styled_SequenceColorChip
                    component={"div"}
                    sx={{
                        backgroundColor: state?.color,
                    }}
                />
                <Typography variant={"body2"}>
                    {state?.sequenceFolder?.name} /
                </Typography>
                <Typography variant={"body2Medium"}>
                    {state?.names[lang as string]}
                </Typography>
                <IconButton
                    onClick={() => handleEdit(state?.id)}
                    size="small"
                    data-testid={`SequencePert-Toolbar-Edit`}
                >
                    <Icon variant="regular" icon="pen" fontSize="small" />
                </IconButton>
                <IconButton
                    size="small"
                    data-testid={`SequencePert-Toolbar-Dropdown`}
                >
                    <Icon
                        variant="regular"
                        icon="ellipsis-vertical"
                        fontSize="small"
                    />
                </IconButton>
            </Stack>
            <IconButton
                onClick={handleClose}
                size="small"
                data-testid={`SequencePert-Toolbar-Close`}
            >
                <Icon variant={"regular"} icon={"xmark"} />
            </IconButton>
        </Styled_Toolbar>
    );
};
