import { yupResolver } from "@hookform/resolvers/yup";
import { debounce, Stack, useTheme } from "@mui/material";
import InputBase, { InputBaseProps } from "@mui/material/InputBase";
import {
    Dispatch,
    ForwardedRef,
    ReactElement,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useRef,
} from "react";
import {
    FormProvider,
    SubmitHandler,
    useForm,
    useWatch,
} from "react-hook-form";
import * as Yup from "yup";

import { mutationUpdateConstraint } from "src/api/tms-scheduling/constraints";
import {
    ConstraintStatus,
    Type_index_constraint,
    Type_show_constraint,
} from "src/api/tms-scheduling/constraints/types";
import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { Tooltip } from "src/components/Components_Common/_MuiComponentsVariants/Tooltip/Tooltip.component";
import { DatePicker } from "src/components/Components_Common/forms/reactHookFormComponents/DatePicker/DatePicker";
import { TextField } from "src/components/Components_Common/forms/reactHookFormComponents/TextField/TextField";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { Spinner } from "src/components/Components_Common/Spinner/Spinner";
import { AutocompleteSubTrades } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteSubTrades";
import { ChipSelectPriority } from "src/components/Components_Teamoty/forms/select/ChipSelectPriority";
import { ChipSelectStatus } from "src/components/Components_Teamoty/forms/select/ChipSelectStatus";
import { SelectSubTradeCompany } from "src/components/Components_Teamoty/forms/select/SelectSubTradeCompany";
import { Type_modalDeleteConstraint } from "src/drawers/observations/ObservationContextualDrawer";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import {
    Row,
    RowIcon,
} from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

const Schema_ObservationForm = Yup.object().shape({
    priority: Yup.number().nullable(),
    status: Yup.number()
        .nullable()
        .oneOf(
            Object.values(ConstraintStatus) as number[],
            `Status must be one of ${Object.values(ConstraintStatus).join(", ")}`,
        ),
    subTrade: Yup.object().nullable(),
    companyId: Yup.number().nullable(),
    description: Yup.string().nullable(),
    date: Yup.date().nullable(),
});

type Type_Props_ObservationForm = {
    formId: string;
    constraint: Type_index_constraint;
    setModalDelete: Dispatch<SetStateAction<Type_modalDeleteConstraint>>;
    isLoading?: boolean;
};

export const ObservationForm = ({
    formId,
    constraint,
    setModalDelete,
    isLoading = false,
}: Type_Props_ObservationForm) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerTasks.Observations",
    );
    const { formatMessageWithPartialKey: fmtActions } = useCoreIntl("Actions");
    const theme = useTheme();

    const { isLoading: isUpdating, mutateAsync: updateConstraint } =
        mutationUpdateConstraint();

    const id = useMemo(() => constraint.id, [constraint]);

    //------------------------------------------------#
    //                       FORM                     |
    //------------------------------------------------#
    const descriptionRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        // we put the focus on description at first render
        descriptionRef.current?.focus();
    }, []);

    const form = useForm({
        defaultValues: {
            priority: constraint.priority, // hight
            status: constraint.status, // to do
            description: constraint.description,
            date: constraint.date,
            subTrade: constraint.subTrade,
            companyId: constraint.company?.id,
        },
        mode: "onBlur",
        resolver: yupResolver<any>(Schema_ObservationForm),
    });

    const watchedsubTrade = useWatch({
        control: form.control,
        name: "subTrade",
    });

    const onSubmit: SubmitHandler<any> = async (
        values: Type_show_constraint,
    ) => {
        await updateConstraint({ ...values, id });
    };

    const debounceOnSubmit = useCallback(
        debounce((props) => onSubmit(props), 1000),
        [id, constraint],
    );
    //------------------------------------------------#
    const handleDelete = () => {
        setModalDelete({ isOpen: true, id: constraint.id });
    };

    return (
        <FormProvider {...form}>
            <form
                onBlur={form.handleSubmit(debounceOnSubmit, (props) =>
                    console.error("handleSubmit: invalid response", props),
                )}
                data-testid="Observation-form"
                id={formId}
                style={{ paddingBottom: theme.spacing(2) }}
            >
                <Stack paddingInline={4} gap={2}>
                    <Stack direction="row" justifyContent="space-between">
                        <Stack direction="row" gap={2} alignItems="center">
                            <ChipSelectPriority
                                data-testid={`chip-select-priority`}
                                name="priority"
                                value={form.watch("priority")}
                            />
                            <ChipSelectStatus
                                data-testid={`chip-select-status`}
                                name="status"
                                value={form.watch("status")}
                            />
                        </Stack>
                        <Stack gap={2} direction="row" alignItems="center">
                            {(isUpdating || isLoading) && (
                                <Spinner style="button" size="small" />
                            )}
                            <IconButton
                                data-testid={`Delete-observation-btn`}
                                title={fmtActions("Delete")}
                                onClick={handleDelete}
                            >
                                <Icon
                                    color="secondary"
                                    fontSize="small"
                                    icon="trash"
                                    variant="solid"
                                />
                            </IconButton>
                        </Stack>
                    </Stack>
                    <TextField
                        placeholder={fmt("DescriptionPlaceholder")}
                        autoFocus
                        inputRef={descriptionRef}
                        name="description"
                        onFocus={(e) =>
                            e.currentTarget.setSelectionRange(
                                e.currentTarget.value.length,
                                e.currentTarget.value.length,
                            )
                        }
                        inputProps={{ maxLength: 255 }}
                        multiline
                        rows={3}
                        sx={{ ...theme.typography.body1 }}
                    />
                    <FormRow
                        tooltip={fmt("Tooltip.Date")}
                        icon="calendar-days"
                        inputComponent={
                            <DatePicker
                                name="date"
                                data-testid="date-observation"
                                slots={{
                                    openPickerIcon: () => (
                                        <Icon
                                            variant="light"
                                            icon="calendar-days"
                                            fontSize="small"
                                        />
                                    ),
                                    textField: CustomInputBase,
                                }}
                            />
                        }
                    />
                    <FormRow
                        tooltip={fmt("Tooltip.Trade")}
                        icon="helmet-safety"
                        inputComponent={
                            <AutocompleteSubTrades
                                name="subTrade"
                                renderInputProps={{
                                    variant: "standard",
                                    placeholder: fmt("TradePlaceholder"),
                                }}
                                data-testid="Form-Observation-subTrade"
                                readonly={false}
                            />
                        }
                    />
                    <FormRow
                        tooltip={fmt("Tooltip.Company")}
                        icon="building"
                        inputComponent={
                            <SelectSubTradeCompany
                                name="companyId"
                                subTradeId={watchedsubTrade?.id}
                                size={"small"}
                                variant="standard"
                                placeholder={fmt("CompanyPlaceholder")}
                                data-testid="Form-Observation-company"
                                readonly={false}
                            />
                        }
                    />
                </Stack>
            </form>
        </FormProvider>
    );
};

interface CustomInputBaseProps extends InputBaseProps {
    inputRef?: ForwardedRef<HTMLDivElement>;
    InputProps?: InputBaseProps;
}

const CustomInputBase = (props: CustomInputBaseProps) => {
    const { inputRef, InputProps, ...other } = props;

    return <InputBase ref={inputRef} {...InputProps} {...other} />;
};

type Type_Props_FormRow = {
    tooltip: string;
    icon: string;
    inputComponent: ReactElement;
};

const FormRow = ({ tooltip, icon, inputComponent }: Type_Props_FormRow) => {
    return (
        <Row>
            <RowIcon>
                <Tooltip title={tooltip} placement="left">
                    <Icon variant="light" icon={icon} fontSize="small" />
                </Tooltip>
            </RowIcon>
            {inputComponent}
        </Row>
    );
};
