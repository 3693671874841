import { useEffect, useState } from "react";
import { useQuery } from "react-query";

export const useCustomQuery = (
    params: any,
    request: any,
    { debounce, ...options }: any,
) => {
    const [newParams, setNewParams] = useState(params);
    const stringify = (obj: any) => JSON.stringify(obj);

    useEffect(() => {
        if (stringify(params) !== stringify(newParams)) {
            const timerId = setTimeout(() => setNewParams(params), debounce);
            return () => clearTimeout(timerId);
        }
    }, [params]);

    return useQuery(newParams, request, options.options);
};
