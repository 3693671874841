import { FormFieldsType } from "src/components/Components_Common/Form/Form.container";

import { InputMultipleComponent } from "./InputMultiple.component";

export type InputMultiplePropsType = {
    fields?: FormFieldsType[];
};

export const InputMultipleContainer = (props: InputMultiplePropsType) => {
    return <InputMultipleComponent {...props} />;
};
