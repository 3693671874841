import Konva from "konva";
import React from "react";
import { Group, Path, Rect } from "react-konva";

import {
    colorPlanningButtonText,
    colorPlanningButtonTextSelected,
    colorPlanningSelected,
    sizePlanningButtonPictogram,
    sizePlanningScroll,
} from "src/components/Components_Teamoty/Planning/Planning.const";
import { changeCursor } from "src/components/Components_Teamoty/Planning/tools/changeCursor";
import { Type_Props_PlanningWorkforceButton } from "src/components/Components_Teamoty/Planning/Workforce/PlanningWorkforce.type";
import { COLORS } from "src/theme/stylesheet";

export const PlanningWorkforceButton = ({
    x,
    y,
    width,
    height,
    onChange,
    selected,
    pictogram,
}: Type_Props_PlanningWorkforceButton) => {
    const maxValue = Math.max(width, height);

    const offsetX = (maxValue - width) / 2;
    const offsetY = (maxValue - height) / 2;

    return (
        <Group
            x={x}
            y={y}
            onClick={() => {
                onChange();
            }}
            onMouseOver={(e: Konva.KonvaEventObject<MouseEvent>) =>
                changeCursor(e, "pointer")
            }
            onMouseOut={(e: Konva.KonvaEventObject<MouseEvent>) =>
                changeCursor(e, "default")
            }
        >
            <Rect
                width={sizePlanningScroll}
                height={sizePlanningScroll}
                fill={selected ? colorPlanningSelected : COLORS.transparent}
            />

            <Path
                scaleX={sizePlanningButtonPictogram / maxValue}
                scaleY={sizePlanningButtonPictogram / maxValue}
                x={(sizePlanningScroll - sizePlanningButtonPictogram) / 2}
                y={(sizePlanningScroll - sizePlanningButtonPictogram) / 2}
                offsetX={-offsetX}
                offsetY={-offsetY}
                fill={
                    selected
                        ? colorPlanningButtonTextSelected
                        : colorPlanningButtonText
                }
                data={pictogram}
            />
        </Group>
    );
};
