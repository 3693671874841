import { Type_show_taskArea } from "src/api/tms-scheduling/taskArea/types";
import { formatDateForBack, formatDateForFront } from "src/utils/date";

import {
    Type_index_punchlist,
    Type_post_punchlist,
    Type_sch_index_punchlist,
    Type_sch_post_punchlist,
} from "./types";

export const formatterIndexPunchlists = (
    Punchlists: Type_sch_index_punchlist[],
): null | Type_index_punchlist[] => {
    if (!Punchlists) return null;
    return Punchlists.map(formatterIndexPunchlist);
};

export const formatterIndexPunchlist = (
    punchlist: Type_sch_index_punchlist,
): Type_index_punchlist => {
    return {
        id: punchlist.id,
        taskId: punchlist.task_id,
        areaId: punchlist.area_id,
        duration: punchlist.duration,
        areaQuantity: punchlist.areaQuantity,
        team: punchlist.team,
        order: punchlist.order,
        workforce: punchlist.workforce,
        earliestDate: formatDateForFront(punchlist.earliestDate),
        waitingDay: punchlist.waitingDay,
        waitingDayMode: punchlist.waitingDayMode,
        company: punchlist.company,
        startDate: formatDateForFront(punchlist.startDate),
        endDate: formatDateForFront(punchlist.endDate),
        pin: punchlist.pin,
        subProject: punchlist.subProject,
        names: punchlist.names,
        progressValue: punchlist.progressValue,
        progressDate: formatDateForFront(punchlist.progressDate),
        punchlistCause: punchlist.punchlistCause,
    };
};

export const formatterCreatePunchlist = (
    punchlist: Type_post_punchlist,
): Type_sch_post_punchlist => {
    return {
        names: punchlist.names,
        earliestDate: formatDateForBack(punchlist.earliestDate),
        duration: punchlist.duration,
        punchlist_cause_id: punchlist.punchlistCauseId,
        workforce: punchlist.workforce,
    };
};

export const formatterTaskToPunchlist = (
    task: Type_show_taskArea,
): Type_index_punchlist => {
    return {
        ...task,
        id: task.id,
        taskId: task.taskId,
        areaId: task.areaId,
        duration: 0,
        areaQuantity: task.areaQuantity || 0,
        team: task.team,
        order: task.order,
        workforce: task.workforce || 0,
        earliestDate: task.earliestDate,
        waitingDay: task.waitingDay,
        waitingDayMode: task.waitingDayMode,
        startDate: task.startDate,
        pin: task.pin,
        subProject: task.subProject,
        names: task.names,
        progressDate: task.progressLastDate,
        progressValue: task.progressValue,
        punchlistCause: "",
        endDate: task.endDate,
        company: null,
    };
};
