import { InputSelectComponent } from "./InputSelect.component";

export type InputSelectPropsType = {
    label: string;
    data: any;
    name: string;
    onChange: any;
    defaultValue?: string;
    value?: any;
};

export const InputSelectContainer = (props: InputSelectPropsType) => {
    return <InputSelectComponent {...props} />;
};
