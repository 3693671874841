import { ToggleButton } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Styled_ToggleButton = styled(ToggleButton)(({ theme }) => ({
    border: "none",
    padding: theme.spacing(1, 2),
    color: theme.palette.common.black,
    "&:hover": {
        backgroundColor: theme.palette.action.hover,
    },
    "&.Mui-selected": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    "&.Mui-disabled": {
        color: theme.palette.text.disabled,
        backgroundColor: "none",
    },
}));
