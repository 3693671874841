import { InputCancelPropsType } from "./InputCancel.container";

export type InputCancelType = {
    type: "inputCancel";
    label?: {
        label?: string;
        languageLabel?: string;
    };
    options?: any;
};

export const InputCancelComponent = ({
    label,
    props,
    onClick,
}: InputCancelPropsType) => {
    return (
        <button
            onClick={onClick}
            {...props}
            className={`bg-teamoty-secondary text-white px-4 py-2 rounded-md shadow-md`}
        >
            {label}
        </button>
    );
};
