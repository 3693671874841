import { yupResolver } from "@hookform/resolvers/yup";
import { debounce, Input } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useCallback, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";

import {
    mutationCreateTaskArea,
    mutationSetProgressTaskArea,
} from "src/api/tms-scheduling/taskArea";
import {
    Type_post_taskArea,
    Type_show_taskArea,
} from "src/api/tms-scheduling/taskArea/types";
import { languagesList } from "src/assets/translations";
import { useFormDefaultConfig } from "src/configurations/app";
import { TaskAreaFormAccordionList } from "src/forms/taskArea/TaskAreaFormComponents/TaskAreaFormAccordionList";
import { TaskAreaFormContent } from "src/forms/taskArea/TaskAreaFormComponents/TaskAreaFormContent";
import { TaskAreaFormHeader } from "src/forms/taskArea/TaskAreaFormComponents/TaskAreaFormHeader";
import { useChannel } from "src/hooks/useChannel";
import {
    Content,
    Form,
} from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";
import { getDate } from "src/utils/date";

const validationSchema = Yup.lazy(() => {
    return Yup.object().shape({
        companyId: Yup.number().nullable(),
        duration: Yup.number(),
        earliestDate: Yup.date().nullable(),
        endDate: Yup.date().nullable(),
        names: Yup.object().shape(
            Object.entries(languagesList).reduce((acc: any, [languageCode]) => {
                acc[languageCode] = Yup.string().nullable();
                return acc;
            }, {}),
        ),
        order: Yup.number().nullable(),
        pin: Yup.boolean(),
        startDate: Yup.date().nullable(),
        team: Yup.number(),
        waitingDay: Yup.number(),
        waitingDayMode: Yup.number().nullable(),
        workforce: Yup.number(),
    });
});

export const Styled_Input = styled(Input)(() => ({
    color: "inherit",
}));

Styled_Input.defaultProps = {
    size: "small",
    fullWidth: true,
};

type Type_Props_TaskAreaForm = {
    data: Type_show_taskArea;
};

export const TaskAreaForm = ({ data }: Type_Props_TaskAreaForm) => {
    const { sendEvent } = useChannel({});

    const [localData, setLocalData] = useState(data);
    const { mutateAsync } = mutationCreateTaskArea();
    const { mutateAsync: mutateAsyncSetProgressValue } =
        mutationSetProgressTaskArea();

    const form = useForm<Type_show_taskArea>({
        ...useFormDefaultConfig,
        values: localData,
        mode: "onBlur",
        resolver: yupResolver<any>(validationSchema),
    });

    const updateProgressValue = async (values: Type_post_taskArea) => {
        try {
            await mutateAsyncSetProgressValue({
                origin: "Form",
                areaId: values.areaId,
                taskId: values.taskId,
                progressValue: Number(values.progressValue),
                progressLastDate: getDate(),
            });
            setLocalData((prevData) => ({
                ...prevData,
                progressValue: Number(values.progressValue),
            }));
        } catch (error) {
            console.error("Failed to update progress value", error);
            return;
        }
    };

    const handleSubmit = async (values: Type_post_taskArea): Promise<void> => {
        const isProgressChanged =
            Number(localData.progressValue) !== Number(values.progressValue);

        if (isProgressChanged) {
            await updateProgressValue(values);
            sendEvent("updateProgress", {
                taskId: values.taskId,
                areaId: values.areaId,
            });
        }

        await mutateAsync(values);
    };

    const debounceOnSubmit = useCallback(
        debounce((props) => handleSubmit(props), 1000),
        [localData],
    );

    return (
        <FormProvider {...form}>
            <Form
                onBlur={form.handleSubmit(debounceOnSubmit, (props) =>
                    console.error("handleSubmit: invalid response", props),
                )}
            >
                <TaskAreaFormHeader task={localData} />
                <Content data-testid="TaskAreaForm-Content">
                    <TaskAreaFormContent />
                    <TaskAreaFormAccordionList task={localData} />
                </Content>
            </Form>
        </FormProvider>
    );
};
