import { Type_sch_select_areaWorkLevel } from "src/api/tms-scheduling/areas/types";
import { Type_selectList } from "src/api/types";

export const formatterSelectListAreaWorkLevels = (
    selectListAreaWorkLevels: Type_sch_select_areaWorkLevel[],
): Type_selectList[] => {
    if (!selectListAreaWorkLevels) return [];
    return selectListAreaWorkLevels.map(
        (areaWorkLevel: Type_sch_select_areaWorkLevel) => ({
            id: areaWorkLevel.id,
            name: areaWorkLevel.name,
        }),
    );
};
