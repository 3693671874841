import { Type_planning as Type_dataPlanning } from "@cimba-digital-construction-solution/utils/dist/decoder/types";
import React, {
    createContext,
    Dispatch,
    SetStateAction,
    useContext,
    useState,
} from "react";

import { Type_planningProps } from "src/components/Components_Teamoty/Planning/Dates/PlanningDates";
import { Type_state_PlanningFilters } from "src/components/Components_Teamoty/Planning/Planning.type";
import { Enum_typeChart } from "src/components/Components_Teamoty/Planning/Workforce/PlanningWorkforce.type";

// Declaration of all dialogs that will be opened by the planning system
type Dialogs = "daysOff" | "keyDate";

export type Type_planningDialog = {
    daysOff: boolean;
    keyDate: boolean;
};

type Type_planning = {
    dialog: Type_planningDialog;
    props: Type_planningProps | null | undefined;
};

export type Type_planningContext = {
    dataPlanning: Type_dataPlanning | null;
    setDataPlanning: Dispatch<SetStateAction<Type_dataPlanning | null>>;

    toggleDialog: (dialog: Dialogs, props?: Type_planningProps) => void;
    setDialogState: Dispatch<SetStateAction<Type_planning>>;
    dialog: Type_planningDialog;

    props: Type_planningProps | null | undefined;

    enableTaskDone: boolean;
    setEnableTaskDone: (value: boolean) => void;

    filters: Type_state_PlanningFilters;
    setFilters: Dispatch<SetStateAction<Type_state_PlanningFilters>>;
};

const initialPlanningContext: Type_planning = {
    dialog: {
        daysOff: false,
        keyDate: false,
    },
    props: null,
};

const PlanningContext = createContext<Type_planningContext | undefined>(
    undefined,
);

export const usePlanningContext = () => {
    const context = useContext(PlanningContext);
    if (!context) {
        throw new Error(
            "usePlanningContext must be used within a PlanningProvider",
        );
    }
    return context;
};

type Type_Props_PlanningProvider = {
    children: JSX.Element | JSX.Element[];
};
export const PlanningProvider = ({ children }: Type_Props_PlanningProvider) => {
    // Data Planning
    const [dataPlanning, setDataPlanning] = useState<Type_dataPlanning | null>(
        null,
    );

    // Dialog
    const [dialogState, setDialogState] = useState<Type_planning>(
        initialPlanningContext,
    );

    const toggleDialog = (dialog: Dialogs, props?: Type_planningProps) => {
        setDialogState((prev) => ({
            ...prev,
            props,
            dialog: {
                ...prev.dialog,
                [dialog]: !prev.dialog[dialog],
            },
        }));
    };

    // Enable Task Done
    const [enableTaskDone, setEnableTaskDone] = useState(false);

    // Filters
    const [filters, setFilters] = useState<Type_state_PlanningFilters>({
        withWeekend: false,
        withDaysOff: false,
        workforceChart: Enum_typeChart.none,
        withPlanningData: true,
        withLinksTaskArea: true,
    });

    return (
        <PlanningContext.Provider
            value={{
                dataPlanning,
                setDataPlanning,

                dialog: dialogState.dialog,
                props: dialogState.props,
                setDialogState,
                toggleDialog,

                enableTaskDone,
                setEnableTaskDone,

                filters,
                setFilters,
            }}
        >
            {children}
        </PlanningContext.Provider>
    );
};
