import { get, GetReturnType, post, PostReturnType } from "src/api/fetch";
import { AuthPath, Url } from "src/api/paths";
import { Type_loginState } from "src/pages/Pages_Common/Page_Identification/Page_Identification.container";

export const login = async (data: Type_loginState): PostReturnType => {
    return post(data, `${Url.GATEWAY}${AuthPath.LOGIN}`);
};
export const logout = (): PostReturnType => {
    return post({}, `${Url.GATEWAY}${AuthPath.LOGOUT}`);
};

export const getCurrentUser = (): GetReturnType => {
    return get(`${Url.GATEWAY}${AuthPath.ME}`);
};
