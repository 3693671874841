import * as React from "react";

import { TaskNotesForm } from "src/forms/taskNotes/TaskNotesForm";
import { Styled_PaperContextualLayout } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

//////////////////////////////////////////////

type Type_Props_TaskNotesContextualDrawer = {
    taskNumber: number;
};

export const TaskNotesContextualDrawer = ({
    taskNumber,
}: Type_Props_TaskNotesContextualDrawer) => {
    return (
        <Styled_PaperContextualLayout
            id="taskNotes"
            data-testid="TaskNotesContextualDrawer"
        >
            <TaskNotesForm taskNumber={taskNumber} />
        </Styled_PaperContextualLayout>
    );
};
