import dayjs from "dayjs";

import { formatterIndexLicenses } from "src/api/tms-commons/licenses/formatters";
import {
    Type_com_index_project,
    Type_com_projectType,
    Type_com_show_project,
    Type_index_project,
    Type_metadataProject,
    Type_put_project,
    Type_show_project,
} from "src/api/tms-commons/projects/types";
import {
    formatDateForBack,
    formatDateForFront,
    getCurrentFormat,
    getDate,
    isValidDate,
} from "src/utils/date";

// TODO add return type
export const formattedProjectCreate = (project: Type_put_project) => {
    return {
        name: project.name,
        postCode: project.postCode,
        startDate: formatDateForBack(project.projectStartDate),
        city: project.city,
        country: project.country,
        slug: project.slug,
        cover: project.thumbnail[0].data_url,
        company_id: project.company_id,
        projectTypes: project.projectTypes.map((projectType) => projectType.id),
        licenses: [
            {
                license_type_id: project.licenses.id,
                name: project.licenses.name,
                startDate: formatDateForBack(project.startDate),
                renewal: project.renewal,
                cost: project.cost,
            },
        ],
        metadata: project.metadata,
    };
};

export const formatterShowProject = (
    project: Type_com_show_project,
): Type_show_project => {
    return {
        id: project.id,
        name: project.name,
        startDate: formatDateForFront(project.startDate),
        postCode: project.postCode,
        city: project.city,
        country: project.country,
        slug: project.slug,
        cover: project.cover,
        thumbnail: project.thumbnail,
        companyId: project.company?.id,
        company: project?.company,
        projectTypes: project.projectTypes
            .map((projectType: Type_com_projectType) => ({
                id: projectType.id,
                name: projectType.name,
            }))
            .sort((a: any, b: any) => a.name?.localeCompare(b.name)),
        metadata: formatterMetaDataProjectToArray(project.metadata),
        licenses: formatterIndexLicenses(project.licenses),
        archived: project?.archived,
        enabled: project?.enabled,
        status: project?.status,
        lastUpdate: formatDateForFront(project?.lastUpdate),
        archivedDate: formatDateForFront(project?.archivedDate),
    };
};

export const formattedEditShowProject = (
    project: Type_com_show_project,
): Pick<
    Type_show_project,
    | "id"
    | "name"
    | "postCode"
    | "city"
    | "country"
    | "startDate"
    | "thumbnail"
    | "slug"
    | "companyId"
    | "projectTypes"
    | "metadata"
    | "licenses"
> => {
    return {
        id: project.id,
        name: project.name,
        startDate: formatDateForFront(project.startDate),
        postCode: project.postCode,
        city: project.city,
        country: project.country,
        slug: project.slug,
        thumbnail: project.thumbnail,
        companyId: project.company?.id,
        projectTypes: project.projectTypes.map(
            (projectType: Type_com_projectType) => ({
                id: projectType.id,
                name: projectType.name,
            }),
        ),
        metadata: formatterMetaDataForEdit(project.metadata),
        licenses: formatterIndexLicenses(project.licenses),
    };
};

export const formatterIndexProjects = (
    projects: Type_com_index_project[],
): Type_index_project[] => {
    return projects.map((project: Type_com_index_project) => ({
        id: project.id,
        name: project.name,
        slug: project.slug,
        thumbnail: project.thumbnail,
        status: project.status,
        enabled: project.enabled,
        archived: project.archived,
        company: project.company,
        startDate: formatDateForFront(project.archivedDate),
        archivedDate: formatDateForFront(project.archivedDate),
        lastUpdate: formatDateForFront(project.lastUpdate),
    }));
};

export const formattedUpdateProject = (
    project: Pick<
        Type_show_project,
        | "id"
        | "name"
        | "postCode"
        | "city"
        | "country"
        | "startDate"
        | "thumbnail"
        | "slug"
        | "companyId"
        | "projectTypes"
        | "metadata"
    >,
) => {
    let updatedProject = {
        id: project.id,
        name: project.name,
        startDate: formatDateForBack(project.startDate),
        postCode: project.postCode,
        city: project.city,
        country: project.country,
        slug: project.slug,
        company_id: project.companyId,
        projectTypes: project.projectTypes.map(
            (projectType: any) => projectType.id,
        ),
        metadata: formatterMetadataUpdate(project.metadata),
    };

    if (Array.isArray(project.thumbnail) && project.thumbnail[0]?.data_url)
        updatedProject = {
            ...updatedProject,
            cover: project.thumbnail[0]?.data_url,
        } as any;

    return updatedProject;
};

export const formatterMetadataUpdate = (metadata: Record<string, string>) => {
    if (!metadata) return null;

    const formattedUpdatedMetadata: any = {};

    for (const [label, value] of Object.entries(metadata)) {
        if (Array.isArray(value) && value.length < 1) continue;
        if (value.length < 1) continue;

        formattedUpdatedMetadata[label] = isValidDate(value)
            ? formatDateForBack(value)
            : value;
    }

    return formattedUpdatedMetadata;
};

export const formatterMetaDataForEdit = (
    metadata: Record<string, string> | null,
) => {
    if (!metadata) return [];

    const formattedMetadata: any = {};

    for (const [label, value] of Object.entries(metadata)) {
        if (!Array.isArray(value) && isValidDate(value))
            formattedMetadata[label] = getDate(value);
        else {
            formattedMetadata[label] = value;
        }
    }

    return formattedMetadata;
};

/**
 * formatted metadata object into Array
 * @param metadata
 */
export const formatterMetaDataProjectToArray = (
    metadata: Record<string, string> | null,
): Type_metadataProject[] => {
    if (!metadata) return [];

    const res: Type_metadataProject[] = [];

    for (const [label, value] of Object.entries(metadata)) {
        let result = value;
        let isDate = false;
        //check if value is date
        if (
            !Array.isArray(value) &&
            dayjs(value.substring(0, 10), "YYYY-MM-DD", true).isValid()
        ) {
            result = getDate(value).format(getCurrentFormat());
            isDate = true;
        }

        res.push({ label: label, value: result, isDate: isDate });
    }
    return res;
};
