import { FormikValues } from "formik";

import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    PutReturnType,
    remove,
    RemoveReturnType,
} from "src/api/fetch";
import { getFilterJoin, Type_Filter_Projects } from "src/api/filters";
import { ProjectPath, Url } from "src/api/paths";

export const createProject = (data: FormikValues): PostReturnType => {
    return post(data, `${Url.COMMONS}${ProjectPath.PROJECTS}`);
};

export const indexProjects = (
    filters: Type_Filter_Projects = {},
): GetReturnType => {
    const urlParams: string = getFilterJoin(filters);

    return get(`${Url.COMMONS}${ProjectPath.PROJECTS}${urlParams}`);
};

export const showProject = (id: number): GetReturnType => {
    return get(`${Url.COMMONS}${ProjectPath.PROJECTS}/${id}`);
};

export const getProjectBySlug = (slug: string): GetReturnType => {
    return get(`${Url.COMMONS}${ProjectPath.SHOW}/${slug}`);
};

export const checkProjectNameIsAvailable = (
    data: FormikValues,
): PostReturnType => {
    return post(data, `${Url.COMMONS}${ProjectPath.IS_NAME_AVAILABLE}`);
};

export const updateProject = (data: any): PutReturnType => {
    return put(data, `${Url.COMMONS}${ProjectPath.PROJECTS}/${data?.id}`);
};

export const changeProjectStatus = (data: any, id: number): PutReturnType => {
    return put(data, `${Url.COMMONS}${ProjectPath.PROJECTS}/${id}`);
};

export const removeProject = (id: number): RemoveReturnType => {
    return remove({ id }, `${Url.COMMONS}${ProjectPath.PROJECTS}/${id}`);
};
