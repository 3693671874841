import { TabContext } from "@mui/lab";
import { Box, DrawerProps, Stack, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import { ProjectPath, Url } from "src/api/paths";
import { getImage } from "src/api/storage";
import { Type_index_project } from "src/api/tms-commons/projects/types";
import { ConfirmDeleteModal, TMC_Button } from "src/components";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { ProjectCard } from "src/components/Components_Teamoty/cards/ProjectCard/ProjectCard";
import { useDrawer } from "src/contexts/drawer";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { COLORS } from "src/theme/stylesheet";

import { Type_modalDeleteProject } from "./Page_Projects.container";
import {
    Styled_ChipProjects,
    Styled_TabListContainerProjects,
    Styled_TabListProjects,
    Styled_TabPanelProjects,
    Styled_TabProjects,
} from "./Page_Projects.style";
import { Projects_DrawerContentContainer as DrawerContent } from "./Projects_DrawerContent/Projects_DrawerContent.container";
import { Projects_DrawerContentContainer as DrawerContentEdit } from "./Projects_DrawerContent_Edit/Projects_DrawerContent.container";

export type Type_Page_ProjectsComponent = {
    projects: Type_index_project[];
    archivedProjects: Type_index_project[];
    favouritesProjects: Type_index_project[];
    addProjectToFavourites: (id: number) => void;
    deleteFavouriteProject: (id: number) => void;
    handleProjectStatus: (id: number, project: Type_index_project) => void;
    handleProjectArchived: (id: number, project: Type_index_project) => void;
    deleteProject: (id: number) => void;
    modalDeleteProject: Type_modalDeleteProject;
    setModalDeleteProject: Dispatch<SetStateAction<Type_modalDeleteProject>>;
    favouritesProjectsId?: any[];
};

export const Page_ProjectsComponent = ({
    projects,
    archivedProjects,
    addProjectToFavourites,
    deleteFavouriteProject,
    handleProjectStatus,
    favouritesProjects,
    handleProjectArchived,
    deleteProject,
    modalDeleteProject,
    setModalDeleteProject,
    favouritesProjectsId,
}: Type_Page_ProjectsComponent) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Projects");

    enum ProjectTabsValues {
        DEFAULT = "allProjects",
        ALL_PROJECTS = "allProjects",
        ARCHIVED_PROJECTS = "archivedProjects",
    }

    const [value, setValue] = useState(ProjectTabsValues.DEFAULT);
    const handleChange = (
        event: React.SyntheticEvent,
        newValue: ProjectTabsValues,
    ) => {
        setValue(newValue);
    };

    return (
        <>
            <Stack
                direction="column"
                width="100%"
                paddingX={7}
                paddingTop={5}
                gap={4}
                sx={{ height: "calc(100vh - 70px)", overflowY: "auto" }}
            >
                <Header_ProjectsComponent />
                <Cards_ProjectsComponent
                    cards={favouritesProjects}
                    isFav
                    addProjectToFavourites={addProjectToFavourites}
                    deleteFavouriteProject={deleteFavouriteProject}
                    handleProjectStatus={handleProjectStatus}
                    handleProjectArchived={handleProjectArchived}
                    dataTestIdRef="Favorites"
                />
                <Box sx={{ width: "100%" }}>
                    <TabContext value={value}>
                        <Styled_TabListContainerProjects>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                flexWrap="nowrap"
                                alignItems="baseline"
                                gap={2}
                            >
                                <Styled_TabListProjects onChange={handleChange}>
                                    <Styled_TabProjects
                                        label={
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                gap={2}
                                            >
                                                {fmt("TabsLabel.AllProjects")}
                                                <Styled_ChipProjects
                                                    customcolor={COLORS.blue300}
                                                    label={
                                                        <Typography
                                                            variant="body2"
                                                            color={
                                                                COLORS.blue600
                                                            }
                                                        >
                                                            {projects.length}
                                                        </Typography>
                                                    }
                                                />
                                            </Stack>
                                        }
                                        value={ProjectTabsValues.ALL_PROJECTS}
                                        disableRipple
                                    />
                                    <Styled_TabProjects
                                        label={
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                gap={2}
                                            >
                                                {fmt(
                                                    "TabsLabel.ArchiveProjects",
                                                )}
                                                <Styled_ChipProjects
                                                    customcolor={COLORS.moon200}
                                                    label={
                                                        <Typography
                                                            variant="body2"
                                                            color={
                                                                COLORS.moon500
                                                            }
                                                        >
                                                            {
                                                                archivedProjects.length
                                                            }
                                                        </Typography>
                                                    }
                                                />
                                            </Stack>
                                        }
                                        value={
                                            ProjectTabsValues.ARCHIVED_PROJECTS
                                        }
                                        disableRipple
                                    />
                                </Styled_TabListProjects>
                                <Stack
                                    direction="row"
                                    flexWrap="nowrap"
                                    alignItems="baseline"
                                    gap={2}
                                >
                                    <Typography
                                        variant="body3"
                                        color={COLORS.moon500}
                                    >
                                        {fmt("SortBy")}
                                    </Typography>
                                    <TMC_Button
                                        variant={"text"}
                                        sx={{ color: COLORS.blue700 }}
                                        endIcon={
                                            <Icon
                                                variant="solid"
                                                icon="arrow-up-arrow-down"
                                            />
                                        }
                                    >
                                        <Typography
                                            variant="body3Underline"
                                            color={COLORS.blue700}
                                        >
                                            A-Z
                                        </Typography>
                                    </TMC_Button>
                                    <TMC_Button
                                        variant={"text"}
                                        sx={{ color: COLORS.blue700 }}
                                        endIcon={
                                            <Icon
                                                variant="solid"
                                                icon="arrow-up-arrow-down"
                                            />
                                        }
                                    >
                                        <Typography
                                            variant="body3Underline"
                                            color={COLORS.blue700}
                                        >
                                            {fmt("CtaLastOpened")}
                                        </Typography>
                                    </TMC_Button>
                                    <TMC_Button
                                        variant={"text"}
                                        sx={{ color: COLORS.blue700 }}
                                        endIcon={
                                            <Icon
                                                variant="solid"
                                                icon="arrow-up-arrow-down"
                                            />
                                        }
                                    >
                                        <Typography
                                            variant="body3Underline"
                                            color={COLORS.blue700}
                                        >
                                            {fmt("CtaStatus")}
                                        </Typography>
                                    </TMC_Button>
                                </Stack>
                            </Stack>
                        </Styled_TabListContainerProjects>
                        <Styled_TabPanelProjects
                            value={ProjectTabsValues.ALL_PROJECTS}
                        >
                            <Cards_ProjectsComponent
                                cards={projects}
                                addProjectToFavourites={addProjectToFavourites}
                                deleteFavouriteProject={deleteFavouriteProject}
                                favouritesProjectsId={favouritesProjectsId}
                                handleProjectStatus={handleProjectStatus}
                                handleProjectArchived={handleProjectArchived}
                                dataTestIdRef="List"
                            />
                        </Styled_TabPanelProjects>
                        <Styled_TabPanelProjects
                            value={ProjectTabsValues.ARCHIVED_PROJECTS}
                        >
                            <Cards_ProjectsComponent
                                cards={archivedProjects}
                                addProjectToFavourites={addProjectToFavourites}
                                deleteFavouriteProject={deleteFavouriteProject}
                                favouritesProjectsId={favouritesProjectsId}
                                handleProjectStatus={handleProjectStatus}
                                handleProjectArchived={handleProjectArchived}
                                modalDeleteProject={modalDeleteProject}
                                setModalDeleteProject={setModalDeleteProject}
                                dataTestIdRef="Archived"
                            />
                        </Styled_TabPanelProjects>
                    </TabContext>
                </Box>
            </Stack>
            <ConfirmDeleteModal
                open={modalDeleteProject.isOpen}
                header={{
                    title: fmt("Modal.Header.Title"),
                    onClose: () => setModalDeleteProject({ isOpen: false }),
                }}
                actions={{
                    onClose: {
                        onClick: () => setModalDeleteProject({ isOpen: false }),
                        content: fmt("Modal.Actions.OnCloseContent"),
                    },
                    onSubmit: {
                        onClick: () => {
                            setModalDeleteProject({ isOpen: false });
                            deleteProject(modalDeleteProject.card.id || 0);
                        },
                        content: fmt("Modal.Actions.OnSubmitContent"),
                    },
                }}
                form={{
                    validationString: modalDeleteProject?.card?.name,
                }}
            >
                {fmt("Modal.Content")}
            </ConfirmDeleteModal>
        </>
    );
};

export const Header_ProjectsComponent = () => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Projects");
    const { setDrawer, closeDrawer } = useDrawer();

    const drawerOptions: DrawerProps = {
        anchor: "right",
        ModalProps: {
            BackdropProps: {
                onClick: () => null,
            },
        },
        elevation: 2,
    };

    const openDrawer = () =>
        setDrawer(
            <DrawerContent
                onClose={closeDrawer}
                onSubmit={(_value: any) => {
                    //   ajouter à la liste
                }}
                action={"create"}
            />,
            drawerOptions,
        );

    return (
        <Stack
            direction="row"
            flexWrap="nowrap"
            justifyContent="space-between"
            alignItems="center"
        >
            <Stack
                direction="row"
                flexWrap="nowrap"
                alignItems="baseline"
                gap={2}
            >
                <Typography variant="h5" color={COLORS.moon500}>
                    {fmt("FavouritesProjects")}
                </Typography>
            </Stack>
            <Stack
                direction="row"
                flexWrap="nowrap"
                alignItems="center"
                gap={2}
            >
                <TMC_Button
                    onClick={openDrawer}
                    variant="contained"
                    endIcon={<Icon variant="solid" icon="plus" />}
                >
                    {fmt("CtaCreate")}
                </TMC_Button>
                <TMC_Button
                    onClick={() => null}
                    variant="outlined"
                    endIcon={
                        <Icon variant="solid" icon="arrow-up-arrow-down" />
                    }
                >
                    {fmt("CtaExport")}
                </TMC_Button>
            </Stack>
        </Stack>
    );
};

export type Type_Cards_ProjectsComponentProps = {
    cards: Type_index_project[];
    isFav?: boolean;
    addProjectToFavourites: (id: number) => void;
    deleteFavouriteProject: (id: number) => void;
    handleProjectStatus: (id: number, project: Type_index_project) => void;
    handleProjectArchived: (id: number, project: Type_index_project) => void;
    modalDeleteProject?: Type_modalDeleteProject;
    setModalDeleteProject?: Dispatch<SetStateAction<Type_modalDeleteProject>>;
    favouritesProjectsId?: any[];
    dataTestIdRef: "Favorites" | "List" | "Archived";
};

export const Cards_ProjectsComponent = ({
    cards,
    isFav = false,
    addProjectToFavourites,
    deleteFavouriteProject,
    handleProjectStatus,
    handleProjectArchived,
    modalDeleteProject,
    setModalDeleteProject,
    favouritesProjectsId,
    dataTestIdRef,
}: Type_Cards_ProjectsComponentProps) => {
    if (cards.length)
        return (
            <Stack
                direction="row"
                flexWrap="wrap"
                alignItems="center"
                gap={4}
                data-testid={`Projects-Home-${
                    isFav ? "Favorites" : "List"
                }-Cards`}
            >
                {cards.map((card: Type_index_project) => (
                    <Card_ProjectsComponent
                        key={card.name}
                        card={card}
                        isFav={isFav}
                        addProjectToFavourites={addProjectToFavourites}
                        deleteFavouriteProject={deleteFavouriteProject}
                        favouritesProjectsId={favouritesProjectsId}
                        handleProjectStatus={handleProjectStatus}
                        handleProjectArchived={handleProjectArchived}
                        modalDeleteProject={modalDeleteProject}
                        setModalDeleteProject={setModalDeleteProject}
                        dataTestIdRef={dataTestIdRef}
                    />
                ))}
            </Stack>
        );
    return null;
};

export type Type_Card_ProjectsComponentProps = {
    card: Type_index_project;
    isFav: boolean;
    addProjectToFavourites: (id: number) => void;
    deleteFavouriteProject: (id: number) => void;
    handleProjectStatus: (id: number, project: Type_index_project) => void;
    handleProjectArchived: (id: number, project: Type_index_project) => void;
    modalDeleteProject?: Type_modalDeleteProject;
    setModalDeleteProject?: Dispatch<SetStateAction<Type_modalDeleteProject>>;
    favouritesProjectsId?: any[];
    dataTestIdRef: "Favorites" | "List" | "Archived";
};

export const Card_ProjectsComponent = ({
    card,
    isFav,
    addProjectToFavourites,
    deleteFavouriteProject,
    handleProjectStatus,
    handleProjectArchived,
    setModalDeleteProject,
    favouritesProjectsId,
    dataTestIdRef,
}: Type_Card_ProjectsComponentProps) => {
    const { formatMessageWithPartialKey: fmtCta } = useCoreIntl("Form.Cta");
    const [img, setImg] = useState("");

    const { setDrawer, closeDrawer } = useDrawer();

    const drawerOptions: DrawerProps = {
        anchor: "right",
        ModalProps: {
            BackdropProps: {
                onClick: () => null,
            },
        },
        elevation: 2,
    };

    const openDrawerEdit = (id: number) =>
        setDrawer(
            <DrawerContentEdit
                onClose={closeDrawer}
                step={"0"}
                projectIdToUpdate={id}
            />,
            drawerOptions,
        );

    const menuItems = [
        {
            label: fmtCta("Update"),
            call: () => openDrawerEdit(card.id),
        },
        {
            label: fmtCta("Duplicate"),
            call: () => console.log("TODO"),
        },
        {
            label: card.archived ? fmtCta("Unarchived") : fmtCta("Archive"),
            call: () => handleProjectArchived(card.id, card),
        },
    ];

    useEffect(() => {
        const fetchImg = async () => {
            const res = await getImage(
                card.id,
                Url.COMMONS,
                ProjectPath.PROJECTS,
                "thumbnail",
                card.thumbnail as string,
            );
            const imageObjectURL = URL.createObjectURL(res.data);
            setImg(imageObjectURL);
        };
        fetchImg();
    }, []);

    if (card && favouritesProjectsId?.includes(card?.id)) isFav = true;

    return (
        <ProjectCard
            isFav={isFav}
            menuItems={menuItems}
            card={card}
            status={card.status}
            img={img}
            addProjectToFavourites={addProjectToFavourites}
            deleteFavouriteProject={deleteFavouriteProject}
            handleProjectStatus={handleProjectStatus}
            setModalDeleteProject={setModalDeleteProject}
            dataTestIdRef={dataTestIdRef}
        />
    );
};
