import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import * as React from "react";
import { Dispatch, SetStateAction } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";

import {
    mutationCreateResourceFamily,
    mutationUpdateResourceFamily,
    useShowResourceFamily,
} from "src/api/tms-commons/resourceFamilies";
import {
    Type_post_resourceFamily,
    Type_show_resourceFamily,
} from "src/api/tms-commons/resourceFamilies/types";
import { LoadingBox } from "src/components";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { InputMultiLanguages } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages";
import { inputMultiLanguagesSchema } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages.component";
import { useFormDefaultConfig } from "src/configurations/app";

type Type_Props_ResourceFamilyForm = {
    onClose: () => void;
    resourceFamilyIdToUpdate: number | null;
    action: Type_action;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
};

const Schema_ResourceFamily = Yup.lazy(() => {
    return Yup.object().shape({
        names: Yup.object().shape(inputMultiLanguagesSchema.fields),
    });
});

export const ResourceFamilyForm = ({
    onClose,
    resourceFamilyIdToUpdate,
    action,
    setIsLoading,
}: Type_Props_ResourceFamilyForm) => {
    const { mutateAsync: mutateCreate } = mutationCreateResourceFamily() || {};

    const { mutateAsync: mutateUpdate } = mutationUpdateResourceFamily();

    const { data, isFetching } = useShowResourceFamily(
        resourceFamilyIdToUpdate as number,
    );

    const form = useForm<Type_show_resourceFamily | Type_post_resourceFamily>({
        ...useFormDefaultConfig,
        defaultValues: {
            id: undefined,
            names: {},
        },
        values: data,
        resolver: yupResolver<any>(Schema_ResourceFamily),
    });

    const handleSubmit = async (
        values: Type_show_resourceFamily | Type_post_resourceFamily,
        e?: React.BaseSyntheticEvent,
    ) => {
        e?.preventDefault;

        setIsLoading(true);

        try {
            if (action === "update") {
                await mutateUpdate(values as Type_show_resourceFamily);
            } else {
                await mutateCreate(values as Type_post_resourceFamily);
            }

            // Fermeture du drawer
            onClose();
        } catch (e: any) {
            console.error("Error when creating or updating resource family");
        }

        setIsLoading(false);
    };

    return (
        <FormProvider {...form}>
            <form
                onSubmit={form.handleSubmit(handleSubmit)}
                id={"resourceFamily"}
            >
                {isFetching ? (
                    <LoadingBox />
                ) : (
                    <Stack marginTop={6}>
                        <InputMultiLanguages name="names" />
                    </Stack>
                )}
            </form>
        </FormProvider>
    );
};
