import { debounce } from "@mui/material";
import { FormikValues, useFormik } from "formik";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import * as Yup from "yup";

import { useSearchCompany } from "src/api/tms-commons/companies";
import { Type_com_search_company } from "src/api/tms-commons/companies/types";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { Type_formData_NewUser_Invitation } from "src/pages/Pages_Common/Page_NewUser_Invitation/Page_NewUser_Invitation.container";

import { SearchCompanyComponent } from "./SearchCompany.component";

export type Type_SearchCompanyContainerProps = {
    formData: Type_formData_NewUser_Invitation;
    setFormData: Dispatch<SetStateAction<Type_formData_NewUser_Invitation>>;
    step: number;
    maxStep: number;
    handleStep: (stateAction: "previous" | "next") => void;
    handleCompanyStep: (
        stateAction: "previous" | "next",
        resetFormData: boolean,
    ) => void;
    companySearch: string;
    setCompanySearch: (stateAction: string) => void;
};

const Schema_SearchCompany = Yup.object().shape({
    company_id: Yup.number().required(FORM_ERR_FMT.REQUIRED),
});

export const SearchCompanyContainer = ({
    formData,
    setFormData,
    step,
    maxStep,
    handleStep,
    handleCompanyStep,
    companySearch,
    setCompanySearch,
}: Type_SearchCompanyContainerProps) => {
    const debouncedOnChange = debounce((value: string) => {
        setCompanySearch(value);
        // reset formik selected company_id
        formik.setFieldValue("company_id", null);
        formik.setFieldError("company_id", "");
    }, 500);

    // Remove debounce when component unmounts
    useEffect(() => {
        return () => {
            debouncedOnChange.clear();
        };
    }, [debouncedOnChange]);

    // Call search query
    const { data, isLoading, isSuccess } = useSearchCompany(companySearch);

    const handleSubmit = async (values: FormikValues) => {
        let companyData = {};

        if (isSuccess && Array.isArray(data)) {
            const selectedCompany = data
                .filter((company: Type_com_search_company) => {
                    return company.id == values.company_id;
                })
                .pop();

            companyData = {
                company_name: selectedCompany.name,
                company_nationalId: selectedCompany.nationalId,
                company_postalCode: selectedCompany.postalCode,
                company_city: selectedCompany.city,
                company_country: selectedCompany.country,
            };
        }

        try {
            setFormData({
                ...formData,
                company_id: values.company_id,
                ...companyData,
            });

            handleStep("next");
        } catch (e: any) {
            console.error(e.errors);
        }
    };

    const handleCreateCompanyButton = (searchString: string) => {
        setFormData({ ...formData, company_name: searchString });
        handleCompanyStep("next", false);
    };

    const formik: any = useFormik({
        initialValues: formData,
        validationSchema: Schema_SearchCompany,
        onSubmit: handleSubmit,
        validateOnChange: false,
        validateOnBlur: false,
    });

    return (
        <SearchCompanyComponent
            step={step}
            maxStep={maxStep}
            handleStep={handleStep}
            formik={formik}
            isLoading={isLoading}
            isSuccess={isSuccess}
            data={data as Type_com_search_company[]}
            companySearch={companySearch}
            debouncedOnChange={debouncedOnChange}
            handleCreateCompanyButton={handleCreateCompanyButton}
        />
    );
};
