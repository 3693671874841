import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    PutReturnType,
    remove,
    RemoveReturnType,
} from "src/api/fetch";
import { UnitPath, Url } from "src/api/paths";
import {
    Type_com_post_unit,
    Type_com_put_unit,
} from "src/api/tms-commons/units/types";

export const indexUnits = (): GetReturnType => {
    return get(`${Url.COMMONS}${UnitPath.UNITS}`);
};

export const deleteUnit = (id: number): RemoveReturnType => {
    return remove({ id }, `${Url.COMMONS}${UnitPath.UNITS}/${id}`);
};

export const statusUnit = (
    id: number,
    data: Type_com_put_unit,
): PutReturnType => {
    return put(data, `${Url.COMMONS}${UnitPath.UNITS}/${id}`);
};

export const createUnit = (data: Type_com_post_unit): PostReturnType => {
    return post(data, `${Url.COMMONS}${UnitPath.UNITS}`);
};

export const showUnit = (id: number): GetReturnType => {
    return get(`${Url.COMMONS}${UnitPath.UNITS}/${id}`);
};

export const updateUnit = (
    id: number,
    data: Type_com_put_unit,
): PutReturnType => {
    return put(data, `${Url.COMMONS}${UnitPath.UNITS}/${id}`);
};

export const selectListUnits = (): GetReturnType => {
    return get(`${Url.COMMONS}${UnitPath.UNITS_SELECT_LIST}`);
};
