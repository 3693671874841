import { FormikValues, useFormik } from "formik";
import React, { Dispatch, SetStateAction } from "react";
import * as Yup from "yup";

import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { Type_formData_NewUser_Invitation } from "src/pages/Pages_Common/Page_NewUser_Invitation/Page_NewUser_Invitation.container";

import { CreateCompanyComponent } from "./CreateCompany.component";

export type Type_CreateCompanyContainerProps = {
    formData: Type_formData_NewUser_Invitation;
    setFormData: Dispatch<SetStateAction<Type_formData_NewUser_Invitation>>;
    step: number;
    maxStep: number;
    handleCompanyStep: (
        stateAction: "previous" | "next",
        resetFormData: boolean,
    ) => void;
};

const Schema_CreateCompany = Yup.object().shape({
    company_name: Yup.string()
        .required(FORM_ERR_FMT.REQUIRED)
        .max(255, FORM_ERR_FMT.MAX_LENGTH),
    company_nationalId: Yup.string()
        .nullable()
        .max(255, FORM_ERR_FMT.MAX_LENGTH),
    company_postalCode: Yup.string().required(FORM_ERR_FMT.REQUIRED),
    company_city: Yup.string()
        .required(FORM_ERR_FMT.REQUIRED)
        .max(255, FORM_ERR_FMT.MAX_LENGTH),
    company_country: Yup.string()
        .required(FORM_ERR_FMT.REQUIRED)
        .max(255, FORM_ERR_FMT.MAX_LENGTH),
});

export const CreateCompanyContainer = ({
    formData,
    setFormData,
    step,
    maxStep,
    handleCompanyStep,
}: Type_CreateCompanyContainerProps) => {
    const handleSubmit = async (values: FormikValues) => {
        try {
            setFormData({
                ...formData,
                company_name: values.company_name,
                company_nationalId: values.company_nationalId,
                company_code: values.company_code,
                company_postalCode: values.company_postalCode,
                company_city: values.company_city,
                company_country: values.company_country,
            });
            handleCompanyStep("next", false);
        } catch (e: any) {
            console.error(e.errors);
        }
    };

    const formik: any = useFormik({
        initialValues: formData,
        validationSchema: Schema_CreateCompany,
        onSubmit: handleSubmit,
        validateOnChange: false,
        validateOnBlur: false,
    });

    return (
        <CreateCompanyComponent
            step={step}
            maxStep={maxStep}
            formik={formik}
            handleCompanyStep={handleCompanyStep}
        />
    );
};
