import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Form } from "formik";

export const Styled_StackChildrenIdentification = styled(Stack)(() => ({
    flexGrow: 1,
    maxWidth: "400px",
    width: "100%",
    justifyContent: "center",
}));

export const Styled_FormIdentification = styled(Form)(() => ({
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "column",
}));
