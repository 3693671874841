import { InputRadioComponent } from "./InputRadio.component";

export type InputRadioPropsType = {
    label: string;
    data: any;
    name: string;
    onChange: any;
    value?: any;
};

export const InputRadioContainer = (props: InputRadioPropsType) => {
    return <InputRadioComponent {...props} />;
};
