import { Stack, TextFieldProps } from "@mui/material";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";

import { TMC_TextField } from "src/components/Components_Common/_MuiComponentsVariants";
import { FormHelperMessage } from "src/components/Components_Common/_MuiComponentsVariants/FormHelperText/FormHelperMessage";
import { Type_ReadonlyComponent } from "src/components/Components_Common/types";

type Type_Props_TextField = TextFieldProps &
    Type_ReadonlyComponent & {
        name: string; // Overwrite TextFieldProps because
    };

export const TextField = ({ name, ...props }: Type_Props_TextField) => {
    const { formState, control } = useFormContext(); // retrieve those props

    return (
        <Stack>
            <Controller
                name={name}
                control={control}
                render={({ field: { ref: inputRef, ...rest } }) => (
                    <TMC_TextField
                        {...props}
                        {...rest}
                        inputRef={inputRef}
                        data-testid={`Form-TextField-Input-${name}`}
                        error={!!formState.errors[name]}
                        helperText={
                            formState.errors[name] && (
                                <FormHelperMessage
                                    inputName={name}
                                    message={
                                        formState?.errors[name]
                                            ?.message as string
                                    }
                                />
                            )
                        }
                    />
                )}
            />
        </Stack>
    );
};
