import { yupResolver } from "@hookform/resolvers/yup";
import * as React from "react";
import { Dispatch, SetStateAction } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";

import {
    mutationCreatePunchlistItemCause,
    mutationUpdatePunchlistItemCause,
    useShowPunchlistItemCause,
} from "src/api/tms-commons/punchlistItemCauses";
import { Type_show_punchlistItemCause } from "src/api/tms-commons/punchlistItemCauses/types";
import { LoadingBox } from "src/components";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { InputMultiLanguages } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages";
import { inputMultiLanguagesSchema } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages.component";
import { useFormDefaultConfig } from "src/configurations/app";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_PunchlistItemCauseForm = {
    onClose: () => void;
    id: number | undefined;
    action: Type_action;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
};

const Schema_PunchlistItemCauseForm = Yup.lazy(() => {
    return Yup.object().shape({
        names: Yup.object().shape(inputMultiLanguagesSchema.fields),
    });
});

export const PunchlistItemCauseForm = ({
    onClose,
    id,
    action,
    setIsLoading,
}: Type_Props_PunchlistItemCauseForm) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.PunchlistItemCauses",
    );

    const { mutateAsync: mutateCreate } =
        mutationCreatePunchlistItemCause() || {};
    const { mutateAsync: mutateUpdate } =
        mutationUpdatePunchlistItemCause() || {};

    const { data, isFetching } = useShowPunchlistItemCause(id as number);

    const form = useForm<Type_show_punchlistItemCause>({
        ...useFormDefaultConfig,
        defaultValues: {
            names: {},
        },
        values: data,
        resolver: yupResolver<any>(Schema_PunchlistItemCauseForm),
    });

    const handleSubmit = async (
        values: Type_show_punchlistItemCause,
        e?: React.BaseSyntheticEvent,
    ) => {
        e?.preventDefault;

        setIsLoading(true);

        try {
            if (action === "update") {
                await mutateUpdate(values);
            } else {
                await mutateCreate(values);
            }
            // Fermeture du drawer
            onClose();
        } catch (e: any) {
            console.error(
                "Error when creating or updating punchlist item cause",
            );
        }

        setIsLoading(false);
    };

    return (
        <FormProvider {...form}>
            <form
                onSubmit={form.handleSubmit(handleSubmit)}
                id={"punchlistItemCauses"}
            >
                {isFetching ? (
                    <LoadingBox />
                ) : (
                    <InputMultiLanguages
                        name="names"
                        label={fmt(`PunchlistItemName`)}
                    />
                )}
            </form>
        </FormProvider>
    );
};
