import { useState } from "react";

import { InputPasswordConfirmComponent } from "./InputPasswordConfirm.component";

export type InputPasswordConfirmPropsType = {
    type: "password" | "text";
    changeType: () => void;
    props: any;
    name: string;
    label: string;
    onChange?: any;
    placeholder?: string;
};

export const InputPasswordConfirmContainer = (
    props: InputPasswordConfirmPropsType,
) => {
    const [inputType, setInputType] = useState<"password" | "text">("password");
    const changeType = () => {
        if (inputType === "password") setInputType("text");
        if (inputType === "text") setInputType("password");
    };

    return (
        <InputPasswordConfirmComponent
            {...props}
            type={inputType}
            changeType={changeType}
        />
    );
};
