import { InputRegexPropsType } from "./InputRegex.container";

export type InputRegexType = {
    type: "inputRegex";
    name: string;
    required?: boolean;
    label?: {
        label?: string;
        languageLabel?: string;
    };
    unit?: {
        label?: string;
        languageLabel?: string;
    };
    placeholder?: {
        label?: string;
        languageLabel?: string;
    };
};

export const InputRegexComponent = (_props: InputRegexPropsType) => {
    return (
        <>
            <p>InputRegexComponent</p>
        </>
    );
};
