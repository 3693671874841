import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    PutReturnType,
    remove,
    RemoveReturnType,
    Type_RequestConfig,
} from "src/api/fetch";
import { getFilterJoin, Type_Filter_ProgressHistory } from "src/api/filters";
import { ProgressHistory, Url } from "src/api/paths";

import {
    Type_sch_post_progressHistory,
    Type_sch_put_progressHistory,
} from "./types";

export const indexProgressHistory = (
    requestConfig: Type_RequestConfig,
    filters: Type_Filter_ProgressHistory,
): GetReturnType => {
    const urlParams: string = getFilterJoin(filters);

    return get(
        `${Url.SCHEDULING}${ProgressHistory.PROGRESS_HISTORY}${urlParams}`,
        requestConfig,
    );
};

export const showProgressHistory = (
    id: number,
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(
        `${Url.SCHEDULING}${ProgressHistory.PROGRESS_HISTORY}/${id}`,
        requestConfig,
    );
};

export const createProgressHistory = (
    data: Type_sch_post_progressHistory,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return post(
        data,
        `${Url.SCHEDULING}${ProgressHistory.PROGRESS_HISTORY}`,
        requestConfig,
    );
};

export const updateProgressHistory = (
    data: Type_sch_put_progressHistory,
    id: number,
    requestConfig: Type_RequestConfig,
): PutReturnType => {
    return put(
        data,
        `${Url.SCHEDULING}${ProgressHistory.PROGRESS_HISTORY}/${id}`,
        requestConfig,
    );
};

export const deleteProgressHistory = (
    id: number,
    requestConfig: Type_RequestConfig,
): RemoveReturnType => {
    return remove(
        { id },
        `${Url.SCHEDULING}${ProgressHistory.PROGRESS_HISTORY}/${id}`,
        requestConfig,
    );
};
