import React from "react";
import { Group, Line } from "react-konva";

import {
    colorPlanningLine,
    sizePlanningScroll,
} from "src/components/Components_Teamoty/Planning/Planning.const";

import {
    Enum_typeZoom,
    Type_Props_PlanningZoomButtons,
} from "./PlanningZoom.type";
import { PlanningZoomButton } from "./PlanningZoomButton";

export const PlanningZoomButtons = ({
    pos,
    changeZoom,
    x,
    y,
    buttons,
    direction,
    withBorder = false,
}: Type_Props_PlanningZoomButtons) => {
    const updateDensity = (newPos: number): void => {
        changeZoom(newPos, Enum_typeZoom.y);
    };

    return (
        <Group x={x} y={y}>
            {withBorder && (
                <Line
                    points={[
                        0,
                        0,
                        direction == Enum_typeZoom.x ? sizePlanningScroll : 0,
                        direction == Enum_typeZoom.y ? sizePlanningScroll : 0,
                    ]}
                    stroke={colorPlanningLine}
                    strokeWidth={1}
                />
            )}
            {buttons.map((level, index) => (
                <PlanningZoomButton
                    key={index}
                    x={
                        direction == Enum_typeZoom.y
                            ? index *
                                  (level.pictogram
                                      ? sizePlanningScroll
                                      : (level.width as number)) +
                              (withBorder ? 1 : 0)
                            : 0
                    }
                    y={
                        direction == Enum_typeZoom.x
                            ? index *
                                  (level.pictogram
                                      ? sizePlanningScroll
                                      : (level.width as number)) +
                              (withBorder ? 1 : 0)
                            : 0
                    }
                    pictogram={level.pictogram}
                    text={level.text}
                    width={level.width}
                    selected={level.size === pos}
                    onChange={() => updateDensity(level.size)}
                />
            ))}
            {withBorder && (
                <Line
                    points={[
                        direction == Enum_typeZoom.y
                            ? buttons.length * sizePlanningScroll + 2
                            : 0,
                        direction == Enum_typeZoom.x
                            ? buttons.length * sizePlanningScroll + 2
                            : 0,
                        (direction == Enum_typeZoom.y
                            ? buttons.length * sizePlanningScroll + 2
                            : 0) +
                            (direction == Enum_typeZoom.x
                                ? sizePlanningScroll
                                : 0),
                        (direction == Enum_typeZoom.x
                            ? buttons.length * sizePlanningScroll + 2
                            : 0) +
                            (direction == Enum_typeZoom.y
                                ? sizePlanningScroll
                                : 0),
                    ]}
                    stroke={colorPlanningLine}
                    strokeWidth={1}
                />
            )}
        </Group>
    );
};
