import { Type_com_favouritesProject } from "src/api/tms-commons/favouriteProjects/types";
import { Type_index_project } from "src/api/tms-commons/projects/types";
import { formatDateForFront } from "src/utils/date";

export const formatted_FavouritesProjectsIndex = (
    favouritesProjects: Type_com_favouritesProject[],
): Type_index_project[] => {
    return favouritesProjects.map(
        (favouriteProject: Type_com_favouritesProject) => ({
            favourite_id: favouriteProject?.id,
            id: favouriteProject?.project?.id,
            name: favouriteProject?.project?.name,
            slug: favouriteProject?.project?.slug,
            thumbnail: favouriteProject?.project?.thumbnail,
            status: favouriteProject?.project?.status,
            enabled: favouriteProject?.project?.enabled,
            company: favouriteProject?.project?.company,
            startDate: formatDateForFront(favouriteProject?.project?.startDate),
            archived: favouriteProject?.project?.archived,
            archivedDate: formatDateForFront(
                favouriteProject?.project?.archivedDate,
            ),
            lastUpdate: formatDateForFront(
                favouriteProject?.project?.lastUpdate,
            ),
        }),
    );
};
