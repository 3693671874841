import { InputDateTimeRangePropsType } from "./InputDateTimeRange.container";

export type InputDateTimeRangeType = {
    type: "inputDateTimeRange";
    nameFrom: string;
    nameTo: string;
    required?: boolean;
    label?: {
        label?: string;
        languageLabel?: string;
    };
    placeholderFrom?: {
        label?: string;
        languageLabel?: string;
    };
    placeholderTo?: {
        label?: string;
        languageLabel?: string;
    };
};

export const InputDateTimeRangeComponent = (
    _props: InputDateTimeRangePropsType,
) => {
    return <>InputDateTimeRangePropsType</>;
};
