import { Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";

import { Type_show_taskArea } from "src/api/tms-scheduling/taskArea/types";
import { CompleteButtonRhf } from "src/components/Components_Common/forms/reactHookFormComponents/CompleteButtonRhf/CompleteButtonRhf";
import { useLanguage } from "src/contexts/languages";
import { HeaderToolbar } from "src/drawers/task/TaskContextualDrawer/HeaderToolbar";
import { Styled_Input } from "src/forms/taskArea/TaskAreaForm";
import {
    Header,
    useContextualDrawer,
} from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";
import { invertColor } from "src/utils/colors";

type Type_Props_TaskAreaFormHeader = {
    task: Type_show_taskArea;
};

export const taskLinearBackground = (bgColor: string) =>
    `linear-gradient(90deg, ${bgColor} 0%, ${invertColor(invertColor(bgColor))} 161%)`;

export const TaskAreaFormHeader = ({ task }: Type_Props_TaskAreaFormHeader) => {
    const { languages } = useLanguage();
    const { register, getValues } = useFormContext();
    const { closePaper } = useContextualDrawer();

    const [withTradeColor, subTradeTradeColor, color, type, taskName, taskId] =
        getValues([
            "withTradeColor",
            "subTrade.trade.color",
            "color",
            "type",
            "taskName",
            "taskId",
        ]);

    const localeToShortLocale = (locale: string) =>
        locale.slice(0, 2).toUpperCase();

    const backgroundColor = withTradeColor ? subTradeTradeColor : color;

    return (
        <Header
            data-testid="DrawerHeader"
            ownerState={{ borderBottom: true }}
            sx={{
                color: invertColor(backgroundColor),
                background: taskLinearBackground(backgroundColor),
            }}
        >
            <HeaderToolbar
                onClose={() => closePaper("taskArea")}
                type={type}
                taskName={taskName}
                taskId={taskId}
                showFlagObservations={true}
            />
            <Stack direction={"row"} alignItems={"start"}>
                <Stack flexGrow={1}>
                    {languages.map((language, index) => (
                        <Grid container key={index} rowSpacing={0.5}>
                            <Grid item xs={1}>
                                {index === 0 ? ( // TODO should be refactored
                                    <CompleteButtonRhf
                                        name="progressValue"
                                        task={task}
                                        taskType={type}
                                        backgroundColor={backgroundColor}
                                    />
                                ) : (
                                    <Typography
                                        variant={"body3"}
                                        paddingInlineStart={3}
                                        sx={(theme) => ({
                                            opacity: theme.opacity.mid,
                                        })}
                                    >
                                        {localeToShortLocale(language)}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item xs={11} paddingInlineStart={2}>
                                <Styled_Input
                                    {...register(`names.${language}`)}
                                    data-testid={`taskArea-drawer-input-name-${language}`}
                                />
                            </Grid>
                        </Grid>
                    ))}
                </Stack>
            </Stack>
        </Header>
    );
};
