import { styled } from "@mui/material/styles";

const TOP_BAR_TITLE_DIVIDER_HEIGHT = 137;

export const Styled_LayoutSidebarContainer = styled("div")(() => ({
    display: "flex",
    flexDirection: "row",
    height: "100%",
    flex: 1,
    overflow: "hidden",
}));

export const Styled_Sidebar = styled("div")(({ theme }) => ({
    width: theme.layouts.sidebarWidth,
    borderRight: theme.border.default,
    minWidth: theme.layouts.sidebarWidth,
    maxWidth: theme.layouts.sidebarWidth,
    height: `calc(100vh - ${theme.layouts.appBarHeight}px)`,
}));

export const Styled_NavigationMenuContainer = styled("div")(({ theme }) => ({
    display: "flex",
    flex: 1,
    flexDirection: "column",
    overflow: "auto",
    height: `calc(100vh - (${theme.layouts.appBarHeight}px + ${theme.layouts.headerHeight}px))`,
}));

export const Styled_Header = styled("div")(({ theme }) => ({
    height: theme.layouts.headerHeight,
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    padding: theme.spacing(0, 4),
    flex: 1,
}));

export const Styled_LayoutSidebarRoot = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    height: `calc(100vh - ${theme.layouts.appBarHeight}px)`,
    flex: 1,
    overflow: "hidden",
}));

export const Styled_MainContent = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    height: `calc(100vh - (${theme.layouts.appBarHeight}px + ${theme.layouts.headerHeight}px))`,
}));

export const Styled_LayoutSidebarMainSection = styled("div")(({ theme }) => ({
    height: "100%",
    flexDirection: "column",
    paddingBottom: theme.spacing(6),
    maxWidth: theme.layouts.layoutSidebarSectionMaxWidth,
    overflow: "auto",
}));

export const Styled_LayoutSidebarMainTable = styled("div")(({ theme }) => ({
    height: `calc(100vH - ${TOP_BAR_TITLE_DIVIDER_HEIGHT}px)`,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(0, 6, 6, 6),
    overflow: "hidden",
}));
