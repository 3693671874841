import {
    FormControlLabel,
    Switch as MuiSwitch,
    SwitchProps,
    Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";

import { Type_ReadonlyComponent } from "src/components/Components_Common/types";

const Styled_FormControlLabel = styled(FormControlLabel)(
    ({ theme, labelPlacement }: any) => ({
        marginLeft: 0,
        marginRight: 0,
        "& .MuiFormControlLabel-label": {
            ...(labelPlacement === "start" && {
                paddingRight: theme.spacing(2),
            }),
            ...(labelPlacement === "end" && {
                paddingLeft: theme.spacing(2),
            }),
        },
    }),
);

type Type_labelPlacement = "top" | "start" | "bottom" | "end";

type Type_Props_Switch = SwitchProps &
    Type_ReadonlyComponent & {
        name: string;
        label?: string;
        labelPlacement?: Type_labelPlacement;
        caption?: string;
    };

export const Switch = ({
    name,
    label = "",
    labelPlacement = "start",
    sx,
    caption,
    readonly = false,
    ...props
}: Type_Props_Switch) => {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({
                field: { onChange, onBlur, value, ref, name, ...field },
            }) => (
                <>
                    <Styled_FormControlLabel
                        sx={sx}
                        control={
                            <MuiSwitch
                                {...props}
                                checked={value}
                                onChange={(event) => {
                                    onChange(event.target.checked);
                                }}
                                onBlur={onBlur}
                                inputRef={ref}
                                inputProps={{
                                    id: name,
                                }}
                                disabled={readonly || props.disabled}
                                {...field}
                            />
                        }
                        label={label}
                        labelPlacement={labelPlacement}
                    />
                    {caption && (
                        <Typography
                            variant="body2"
                            sx={{
                                color: "text.secondary",
                                marginRight: 2,
                            }}
                        >
                            {caption}
                        </Typography>
                    )}
                </>
            )}
        />
    );
};
