import {
    Type_planningArea,
    Type_planningKeysDay,
} from "@cimba-digital-construction-solution/utils/dist/decoder/types";
import { KonvaEventObject } from "konva/lib/Node";
import React, { ReactElement } from "react";
import { Group, Layer, Line, Rect } from "react-konva";

import { Type_planningLine } from "src/components/Components_Teamoty/Planning/Areas/PlanningArea.type";
import { useMenuItems } from "src/components/Components_Teamoty/Planning/Dialogs/menusConf";
import {
    colorPlanningCursorHighlight,
    colorPlanningGridBackground,
    colorPlanningLine,
    sizePlanningScroll,
} from "src/components/Components_Teamoty/Planning/Planning.const";
import { Type_rectDayKeys } from "src/components/Components_Teamoty/Planning/Planning.type";
import { getGridVertical } from "src/components/Components_Teamoty/Planning/tools/getGridVertical";
import { useChannel } from "src/hooks/useChannel";
import { lightenColor } from "src/utils/colors";

import { Type_Props_PlanningGrid } from "./PlanningGrid.type";

export const PlanningGrid = ({
    dates,
    datesActif,
    areasOffset,
    x,
    y,
    width,
    height,
    bounds,
    cursorHighlight,
    widthDate,
    heightArea,
    onClick,
}: Type_Props_PlanningGrid) => {
    const [elmRects, elmLine] = getGridVertical(height, dates, widthDate);

    const { sendEvent } = useChannel({});
    const { menuItemsPlanningGrid } = useMenuItems();

    const handleContextMenuOnEmpty = ({
        evt,
    }: KonvaEventObject<PointerEvent>) => {
        evt.preventDefault();
        evt.stopPropagation();

        const { offsetX: clientX, offsetY: clientY } = evt;
        let date = undefined;
        let area = undefined;

        // On récupère la date en X selon l'emplacement de la souris
        const dateIndex = datesActif.findIndex(
            (date) => date.pos >= clientX - x - widthDate,
        );
        // On récupère l'area en Y selon l'emplacement de la souris
        const areasOffsetIndex = areasOffset.lines.findIndex(
            (areaOffset) => areaOffset.pos >= clientY - y - heightArea,
        );

        if (dateIndex !== -1) {
            date = datesActif[dateIndex];
        }

        if (areasOffsetIndex !== -1) {
            const currentArea = areasOffset.lines[areasOffsetIndex - 1].area;
            area = [{ id: +currentArea.id, name: currentArea.name }];
        }

        sendEvent("dropDownOnRightClick", {
            open: true,
            mouseX: clientX,
            mouseY: clientY,
            menuItems: menuItemsPlanningGrid,
            props: {
                date: date,
                areas: area,
            },
        });
    };

    const handleContextMenuOnDayKey = (
        { evt }: KonvaEventObject<PointerEvent>,
        area: Type_planningArea,
        dayKeys?: Type_planningKeysDay[],
    ) => {
        evt.preventDefault();
        evt.stopPropagation();

        if (!dayKeys || !area) return;

        let date = undefined;

        const dateIndex = datesActif.findIndex(
            (date) => date.pos >= evt.clientX - x - widthDate,
        );

        if (dateIndex !== -1) {
            date = datesActif[dateIndex];
        }

        const { clientX, clientY } = evt;
        sendEvent("dropDownOnRightClickEditKeyDate", {
            open: true,
            mouseX: clientX,
            mouseY: clientY,
            menuItems: menuItemsPlanningGrid,
            props: {
                selectedDate: dayKeys,
                areas: [{ id: +area.key, name: area.name }],
                date: date,
            },
        });
    };

    const elmDayKeys: Array<ReactElement> = [];

    areasOffset.lines.forEach((line: Type_planningLine, lineIndex: number) => {
        // Ne pas rendre la ligne si elle est en dehors de la zone visible
        if (line.pos + heightArea < 0 || line.pos >= height) return;

        // Ajout d'une ligne horizontale si nécessaire
        elmLine.push(
            <Line
                key={`line-${lineIndex}`}
                points={[0, line.pos, width, line.pos]}
                stroke={line.index < 2 ? colorPlanningLine : undefined}
                dash={line.index > 0 ? [5, 5] : undefined}
                strokeWidth={1}
            />,
        );

        // S'il y a des dates clé (dayKey/keyDate), on dessine des rectangles de couleur(s)
        const { dayKeys } = line.area;

        if (Array.isArray(dayKeys)) {
            const rectDayKeys: Type_rectDayKeys[] = [];
            dayKeys.forEach((dayKey: Type_planningKeysDay) => {
                const { dayKeys: dayKeysOnDate, pos } = dayKey.date || {};
                if (dayKeysOnDate === undefined) return;
                if (typeof pos === "number" && pos < 0) return;

                const deyKeysCount = dayKeysOnDate.length;
                const offset = dayKeysOnDate.findIndex(
                    (dk) => dk.keyDate.id === dayKey.keyDate.id,
                );

                // Calcul de la largeur de chaque rectangle selon le nombre de couleur
                const rectWidth: number = widthDate / deyKeysCount;

                rectDayKeys.push({
                    x: pos! + offset * rectWidth,
                    y: line.pos,
                    width: rectWidth,
                    height: heightArea,
                    dayKeys: dayKeysOnDate,
                    color: dayKey.keyDate.color,
                });
            });

            // Création des rectangles selon les couleurs
            rectDayKeys.forEach((rectDayKey, rectIndex) => {
                elmDayKeys.push(
                    <Rect
                        onContextMenu={(e: KonvaEventObject<PointerEvent>) =>
                            handleContextMenuOnDayKey(
                                e,
                                line.area,
                                rectDayKey.dayKeys,
                            )
                        }
                        key={`glDkRect-${lineIndex}-${rectIndex}`}
                        x={rectDayKey.x}
                        y={rectDayKey.y}
                        width={rectDayKey.width}
                        height={rectDayKey.height}
                        fill={lightenColor(rectDayKey.color, 0.7)}
                    />,
                );
            });
        }
    });

    elmLine.push(
        <Line
            key={"glxTop"}
            points={[0, 0, width, 0]}
            stroke={colorPlanningLine}
            strokeWidth={1}
        />,
        <Line
            key={"glxBottom"}
            points={[0, height, width, height]}
            stroke={colorPlanningLine}
            strokeWidth={1}
        />,
        <Line
            key={"glxRight"}
            points={[width, 0, width, height]}
            stroke={colorPlanningLine}
            strokeWidth={1}
        />,
    );

    return (
        <Layer>
            <Group
                x={x}
                y={y}
                clipX={-0.5}
                clipY={-0.5}
                clipWidth={width + 0.5}
                clipHeight={height + 0.5}
                onClick={onClick}
                onContextMenu={handleContextMenuOnEmpty}
            >
                <Rect
                    x={0}
                    y={0}
                    width={width}
                    height={height}
                    fill={colorPlanningGridBackground}
                />
                {elmRects}
            </Group>

            <Group
                x={x}
                y={y}
                clipX={-0.5}
                clipY={-0.5}
                clipWidth={width + 0.5}
                clipHeight={height + 0.5}
            >
                {elmDayKeys}
            </Group>

            <Group
                x={0}
                y={0}
                clipX={0.5}
                clipY={0.5}
                clipWidth={bounds.width - sizePlanningScroll}
                clipHeight={bounds.height - sizePlanningScroll}
                listening={false}
            >
                {cursorHighlight.x >= 0 && (
                    <Rect
                        x={cursorHighlight.x}
                        y={0}
                        width={widthDate}
                        height={bounds.height}
                        fill={colorPlanningCursorHighlight}
                    />
                )}
            </Group>
            <Group
                x={0}
                y={0}
                clipX={0.5}
                clipY={0.5}
                clipWidth={bounds.width - sizePlanningScroll}
                clipHeight={y + height}
                listening={false}
            >
                {cursorHighlight.y >= 0 && (
                    <Rect
                        x={0}
                        y={cursorHighlight.y}
                        width={bounds.width}
                        height={heightArea}
                        fill={colorPlanningCursorHighlight}
                    />
                )}
            </Group>
            <Group
                x={x}
                y={y}
                clipX={-0.5}
                clipY={-0.5}
                clipWidth={width + 1}
                clipHeight={height + 1}
            >
                {elmLine}
            </Group>
        </Layer>
    );
};
