import {
    Type_com_index_licenseType,
    Type_com_index_licenseTypeMode,
    Type_com_post_licenseType,
    Type_com_put_licenseType,
    Type_com_selectList_licenseType,
    Type_com_show_licenseType,
    Type_index_licenseType,
    Type_index_licenseTypeMode,
    Type_put_licenseType,
    Type_selectList_licenseType,
    Type_show_licenseType,
} from "src/api/tms-commons/licenseTypes/types";
import {
    formatterIndexRole,
    formatterIndexRoles,
} from "src/api/tms-users/rolesAndPermissions/formatters";
import { Type_usr_index_role } from "src/api/tms-users/rolesAndPermissions/types";
import { Type_ToggleButtonGroupItem } from "src/components/Components_Common/forms/reactHookFormComponents/ToggleButtonGroup/ToggleButtonGroup";
import { modesIconsHookForm } from "src/configurations/keyIconRelations";
import { isoToMonths, monthsToIso } from "src/utils/date";

export const formatterIndexLicenseTypes = (
    data: Type_com_index_licenseType[],
): Type_index_licenseType[] => {
    if (!data) return [];
    return data.map((item: Type_com_index_licenseType) =>
        formatterIndexLicenseType(item),
    );
};

export const formatterIndexLicenseType = (
    item: Type_com_index_licenseType,
): Type_index_licenseType => {
    return {
        id: item.id,
        name: item.name,
        duration: isoToMonths(item.duration),
        licenseTypeName: item.licenseTypeMode.name,
        roleTypeNames: item.roles
            .map((role: Type_usr_index_role) => role.name)
            .join(", "),
        enabled: item.enabled,
    };
};

export const formatterIndexLicenseTypesMode = (
    data: Type_com_index_licenseTypeMode[],
): Type_index_licenseTypeMode[] => {
    if (!data) return [];
    return data;
};

export const formatterButtonModes = (
    data: Type_index_licenseTypeMode[],
): Type_ToggleButtonGroupItem[] => {
    if (!data) return [];

    return data.map((item: any) => ({
        value: item.id,
        label: item.name,
        icon: modesIconsHookForm[item.name],
    }));
};

export const formatterShowLicenseType = (
    data: Type_com_show_licenseType,
): Type_show_licenseType => {
    return {
        id: data.id,
        mode: data.licenseTypeMode?.id,
        duration: isoToMonths(data.duration),
        names: data.names,
        roles: formatterIndexRoles(data.roles),
        role: !data.licenseTypeMode.hasMultipleRoles
            ? formatterIndexRole(data.roles[0])
            : undefined,
        hasMultipleRoles: data.licenseTypeMode.hasMultipleRoles,
        enabled: data.enabled,
    };
};

export const formatterPutLicenseType = (
    data: Type_show_licenseType,
): Type_com_put_licenseType => {
    return {
        license_type_mode_id: data.mode,
        duration: monthsToIso(data.duration) as unknown as number,
        names: data.names,
        roles: data.roles as number[],
        enabled: data.enabled,
    };
};

export const formatterPostLicenseType = (
    data: Type_show_licenseType,
): Type_com_post_licenseType => {
    return {
        license_type_mode_id: data.mode,
        duration: monthsToIso(data.duration) as unknown as number,
        names: data.names,
        roles: data.roles as number[],
    };
};

export const formatterSelectListLicenseTypes = (
    data: Type_com_selectList_licenseType[],
): Type_selectList_licenseType[] => {
    if (!data) return [];

    return data.map((item: Type_com_selectList_licenseType) => ({
        id: item.id,
        name: item.name,
    }));
};

export const formatterStatusLicenseType = (
    data: Type_put_licenseType,
): Type_com_put_licenseType => {
    return {
        enabled: data.enabled,
    };
};
