import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";

import { TMC_Button } from "src/components";
import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { TextField } from "src/components/Components_Common/forms/reactHookFormComponents/TextField/TextField";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useCoreIntl } from "src/hooks/useCoreIntl";

const Styled_Form = styled("form")(({ theme }) => ({
    padding: theme.spacing(4),
    gap: theme.spacing(2),
    flexDirection: "row",
    alignItems: "stretch",
    display: "flex",
    boxShadow: theme.boxShadow.box,
}));

const Styled_IconButton = styled(IconButton)(({ theme }) => ({
    border: theme.border.default,
    borderRadius: theme.shape.borderRadius,
}));

const Schema_ObservationCommentsForm = Yup.object().shape({
    comment: Yup.string().required(),
});

type Type_Props_ObservationCommentsForm = {
    taskId: number;
};

export const ObservationCommentsForm = ({
    taskId,
}: Type_Props_ObservationCommentsForm) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerTasks.Observations.Comments",
    );

    const form = useForm({
        resolver: yupResolver<any>(Schema_ObservationCommentsForm),
    });

    //TODO: complete later
    const onSubmit = () => {
        console.log(taskId);
    };

    return (
        <FormProvider {...form}>
            <Styled_Form
                onSubmit={onSubmit}
                data-testid="Observation-comments-form"
                id="observation-add-comment-form"
            >
                <Stack flexGrow={1}>
                    <TextField name="comment" placeholder={fmt("AddComment")} />
                </Stack>

                <Styled_IconButton
                    data-testid={`Attach-file-observation-comment-btn`}
                >
                    <Icon
                        color="inherit"
                        fontSize="small"
                        icon="paperclip"
                        variant="solid"
                    />
                </Styled_IconButton>
                <TMC_Button size="large">{fmt("Send")}</TMC_Button>
            </Styled_Form>
        </FormProvider>
    );
};
