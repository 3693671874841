import {
    Alert,
    Autocomplete,
    Checkbox,
    Collapse,
    FormControlLabel,
    FormGroup,
    Stack,
    Typography,
} from "@mui/material";
import { Formik, FormikHelpers } from "formik";
import React from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";

import { Divider, Spinner, TMC_Button, TMC_TextField } from "src/components";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { COLORS } from "src/theme/stylesheet";
import { getLocalStorageItem } from "src/utils/localStorageServices";

import { Type_loginState } from "./Page_Identification.container";
import {
    Styled_FormIdentification,
    Styled_StackChildrenIdentification,
} from "./Page_Identification.style";

const schemaIdentification = Yup.object().shape({
    email: Yup.string()
        .email(FORM_ERR_FMT.MUST_BE_EMAIL)
        .required(FORM_ERR_FMT.REQUIRED),
    password: Yup.string().required(FORM_ERR_FMT.REQUIRED),
});

type Type_Page_IdentificationProps = {
    state: Type_loginState;
    handleSubmit: (
        values: Type_loginState,
        formikHelpers: FormikHelpers<Type_loginState>,
    ) => void | Promise<any>;
};

export const Page_Identification = ({
    state,
    handleSubmit,
}: Type_Page_IdentificationProps) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Identification");
    const lang = getLocalStorageItem("language");

    return (
        <Styled_StackChildrenIdentification
            gap={4}
            data-testid="Identification-form-container"
        >
            <Typography variant="h1">{fmt("Hello")}</Typography>
            <Formik
                initialValues={state}
                onSubmit={handleSubmit}
                validationSchema={schemaIdentification}
                validateOnChange={false}
            >
                {({
                    values,
                    errors,
                    handleChange,
                    isSubmitting,
                    setFieldValue,
                }) => {
                    return (
                        <Styled_FormIdentification data-testid="Identification-form">
                            <Stack width="100%" gap={2}>
                                <TMC_TextField
                                    data-testid="Identification-email"
                                    name="email"
                                    label={fmt(`EmailLabel`)}
                                    onChange={handleChange}
                                    value={values.email}
                                    helperText={errors.email as string}
                                    error={!!errors.email}
                                    autoComplete={"on"}
                                />
                                <TMC_TextField
                                    data-testid="Identification-password"
                                    name="password"
                                    label={fmt(`PasswordLabel`)}
                                    onChange={handleChange}
                                    value={values.password}
                                    helperText={errors.password as string}
                                    error={!!errors.password}
                                    type="password"
                                />
                                <Stack
                                    flexDirection="row"
                                    flexWrap="nowrap"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <FormGroup>
                                        <FormControlLabel
                                            data-testid="Identification-remember-me"
                                            control={<Checkbox />}
                                            label={fmt("RememberMe")}
                                            checked={values.rememberMe}
                                            onChange={() =>
                                                setFieldValue(
                                                    "rememberMe",
                                                    !values.rememberMe,
                                                )
                                            }
                                        />
                                    </FormGroup>
                                    <Link
                                        to={`/${lang}/forget-password`}
                                        data-testid="Identification-link-forgot-password"
                                    >
                                        <Typography
                                            variant="body3Underline"
                                            color={COLORS.blue700}
                                        >
                                            {fmt("ForgottenPassword")}
                                        </Typography>
                                    </Link>
                                </Stack>
                                <TMC_Button
                                    data-testid="Identification-form-submit"
                                    type="submit"
                                    variant="contained"
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? (
                                        <Spinner style="button" />
                                    ) : (
                                        fmt("SignIn")
                                    )}
                                </TMC_Button>
                                <Collapse in={errors.success === "error"}>
                                    <Alert severity="error">
                                        {fmt("ErrorIdentification")}
                                    </Alert>
                                </Collapse>
                            </Stack>
                        </Styled_FormIdentification>
                    );
                }}
            </Formik>
            <Divider />
            <Autocomplete
                id="sso"
                multiple={false}
                onChange={() => {}}
                value={null}
                options={[]}
                renderInput={(params) => (
                    <TMC_TextField
                        data-testid="Identification-SSO"
                        {...params}
                        name="sso"
                        placeholder={fmt("SsoPlaceholder")}
                        label={fmt(`SsoLabel`)}
                    />
                )}
            />
            <TMC_Button
                data-testid="Identification-SSO-submit"
                type="button"
                variant="outlined"
            >
                {fmt("SsoCTA")}
            </TMC_Button>
        </Styled_StackChildrenIdentification>
    );
};
