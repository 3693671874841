import { Type_sidebar_configuration } from "src/layouts/Layout_Sidebar/types";
import { COLORS } from "src/theme/stylesheet";

export const myAccountCategoriesList: Type_sidebar_configuration = {
    nameLabel: "MyAccount",
    baseUrl: "/my-account",
    blocks: [
        {
            categories: [
                {
                    nameLabel: "MyProfile",
                    urlName: "my-profile",
                    permissionKeys: [],
                    variant: "regular",
                    icon: "address-card",
                    contentType: "section",
                },
                {
                    nameLabel: "Security",
                    urlName: "security",
                    permissionKeys: [],
                    variant: "regular",
                    icon: "key",
                    contentType: "section",
                },
                {
                    urlName: "language",
                    contentType: "section",
                    permissionKeys: [],
                    variant: "regular",
                    icon: "globe",
                    nameLabel: "LanguageAndRegion",
                    subCategories: [
                        {
                            permissionKey: "projectTypes_index",
                            nameLabel: "ProjectType",
                            urlName: "project-types",
                        },
                        {
                            permissionKey: "areaTypes_index",
                            nameLabel: "AreaTypes",
                            urlName: "area-types",
                        },
                    ],
                },
                {
                    nameLabel: "Notifications",
                    urlName: "notifications",
                    permissionKeys: [],
                    variant: "regular",
                    icon: "bell",
                    contentType: "section",
                },
                {
                    nameLabel: "DeleteAccount",
                    urlName: "delete",
                    permissionKeys: [],
                    variant: "regular",
                    icon: "radiation",
                    divider: true,
                    contentType: "section",
                    styled: {
                        "&:hover": {
                            color: COLORS.red500,
                        },
                        color: `${COLORS.red500} !important`,
                    },
                },
            ],
        },
    ],
};
