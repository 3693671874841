import { Grid } from "@mui/material";
import React from "react";

import { DatePicker } from "src/components/Components_Common/forms/reactHookFormComponents/DatePicker/DatePicker";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_TaskAreaDate = {
    readonly?: boolean;
};

export const TaskAreaDate = ({ readonly = true }: Type_Props_TaskAreaDate) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerTaskArea.Date",
    );

    return (
        <Grid container spacing={2} p={2}>
            <Grid item xs={6}>
                <DatePicker
                    name="startDate"
                    label={fmt("StartDate")}
                    data-testid="Form-TaskArea-startDate"
                    readonly={readonly}
                />
            </Grid>
            <Grid item xs={6}>
                <DatePicker
                    name="endDate"
                    label={fmt("EndDate")}
                    data-testid="Form-TaskArea-endDate"
                    readonly={readonly}
                />
            </Grid>
        </Grid>
    );
};
