import {
    Box,
    DrawerProps,
    IconButton,
    Stack,
    ToggleButton,
    Typography,
} from "@mui/material";
import { Form, FormikProvider, FormikValues, useFormik } from "formik";
import { canvastoDataURL, EImageType } from "image-conversion";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { Cropper, CropperRef, ImageRestriction } from "react-advanced-cropper";
import { useQueryClient } from "react-query";
import * as Yup from "yup";

import { DrawingPath } from "src/api/paths";
import {
    mutationCreateDrawing,
    mutationUpdateDrawing,
} from "src/api/tms-projects/drawing";
import {
    Type_prj_post_drawing,
    Type_prj_put_drawing,
    Type_show_drawing,
} from "src/api/tms-projects/drawing/types";
import {
    Header,
    TMC_Button,
    TMC_Drawer,
    TMC_TextField,
    TMC_ToggleButtonGroup,
} from "src/components";
import { Content } from "src/components/Components_Common/Drawer/Content";
import { Footer } from "src/components/Components_Common/Drawer/Footer";
import {
    emptyResource,
    InputFile,
    Type_Resource,
} from "src/components/Components_Common/forms/InputFile";
import { TextSizeSlider } from "src/components/Components_Common/forms/TextSizeSlider";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { Styled_FormControlLabel } from "src/components/Components_Common/ToggleValue/ToggleValue.style";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

import "react-advanced-cropper/dist/style.css";

const colorSettings = ["grayscale", "colorful"];

const Schema_Drawing = Yup.object().shape({
    name: Yup.string().required(FORM_ERR_FMT.REQUIRED),
    colorSetting: Yup.string()
        .required(FORM_ERR_FMT.REQUIRED)
        .oneOf(colorSettings, FORM_ERR_FMT.REQUIRED),
});

export type Type_DrawingImageCrop = Type_Resource & {
    isDrawingImageUpdated?: boolean;
    isCropEnabled?: boolean;
};

export type Type_Props_DrawingDrawer = DrawerProps & {
    // Tous les props natives du drawer de mui.
    onClose: () => void;
    drawingImage: Type_Resource;
    drawing?: Type_show_drawing;
    enabledCrop?: boolean;
};

export const DrawingDrawer = ({
    onClose,
    drawingImage,
    drawing,
    enabledCrop = true,
    ...props
}: Type_Props_DrawingDrawer) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Drawing");
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { formatMessageWithPartialKey: fmtCta } = useCoreIntl("Form.Cta");
    const { add } = useToast();
    const queryClient = useQueryClient();

    const cropperRef = useRef<CropperRef>(null);

    ////////////////////////////////////////////
    // ---- STATES                          ----
    ////////////////////////////////////////////
    const [isLoading, setIsLoading] = useState(false);
    const [isNewDrawing, setIsNewDrawing] = useState(false);
    const [drawingImageCrop, setDrawingImageCrop] =
        useState<Type_DrawingImageCrop>({
            ...drawingImage,
            isCropEnabled: enabledCrop,
        });

    ////////////////////////////////////////////
    // ---- QUERIES AND MUTATIONS           ----
    ////////////////////////////////////////////

    // Create mutation
    const {
        mutateAsync: mutateCreate,
        isLoading: mutationCreateDrawingIsLoading,
    } = mutationCreateDrawing() || {};

    // Update mutation
    const {
        mutateAsync: mutateUpdate,
        isLoading: mutationUpdateDrawingIsLoading,
    } = mutationUpdateDrawing(drawing?.id as number) || {};

    ////////////////////////////////////////////
    // ---- FORM                            ----
    ////////////////////////////////////////////

    const handleClose = () => {
        onClose();
        handleResetImage();
        formik.resetForm();
    };

    const handleOnError = (): void => {
        add({
            type: "warning",
            duration: 4000,
            description: fmtErr("GenericError"),
        });
    };

    const handleSubmit = async (values: FormikValues) => {
        if (isNewDrawing) {
            // call api create
            const data: Type_prj_post_drawing = {
                name: values.name,
                grayscale: values.colorSetting === "grayscale", // TODO replace by boolean
                textSize: values.textSize,
                image: await getCroppedImage(),
            };
            mutateCreate(data, {
                onSuccess: (): void => {
                    add({
                        type: "success",
                        duration: 4000,
                        description: fmt("CallApi.Create.ToastSuccess"),
                    });
                    handleClose();
                },
                onError: handleOnError,
            });
        } else {
            // call api update
            const updateData: Type_prj_put_drawing = {
                name: values.name,
                grayscale: values.colorSetting === "grayscale", // TODO replace by boolean
                textSize: values.textSize,
            };
            if (drawingImageCrop.isDrawingImageUpdated) {
                if (!drawingImageCrop.isCropEnabled) {
                    updateData.image = drawingImageCrop.image?.src;
                } else {
                    // Get image from crop if crop enabled
                    updateData.image = await getCroppedImage();
                }
            }

            mutateUpdate(updateData, {
                onSuccess: async () => {
                    add({
                        type: "success",
                        duration: 4000,
                        description: fmt("CallApi.Update.ToastSuccess"),
                    });
                    // Force refetch drawimg image
                    await queryClient.invalidateQueries({
                        queryKey: [DrawingPath.DRAWINGS, "image", drawing?.id],
                    });
                    // reset state
                    setDrawingImageCrop((prev) => ({
                        ...prev,
                        file: null,
                        isCropEnabled: false,
                        isDrawingImageUpdated: false,
                    }));
                    handleClose();
                },
                onError: handleOnError,
            });
        }
    };

    const handleResetImage = () => {
        setDrawingImageCrop((prev) => ({
            ...prev,
            ...emptyResource,
        }));
    };

    const formik = useFormik({
        initialValues: {
            name: drawing?.name || "",
            colorSetting: drawing?.grayscale ? "grayscale" : "colorful", // TODO replace by boolean
            textSize: drawing?.textSize || 4,
        },
        validationSchema: Schema_Drawing,
        onSubmit: handleSubmit,
        enableReinitialize: true,
    });

    ////////////////////////////////////////////
    // ---- EFFECTS                         ----
    ////////////////////////////////////////////

    // on load
    useEffect(() => {
        if (drawingImage.image) {
            setDrawingImageCrop((prev) => ({
                ...prev,
                file: drawingImage.file,
                image: drawingImage.image,
            }));
        }
    }, [drawingImage.image]);

    useEffect(() => {
        setIsNewDrawing(!drawing?.id);
    }, [drawing]);

    // user has upload a new image, drawing image file change
    useEffect(() => {
        if (drawingImageCrop.file) {
            // Update name field when file change if name is empty (creation only)
            if (formik.values.name === "") {
                formik.setFieldValue("name", drawingImageCrop.file.name);
            }
            // enabled crop
            setDrawingImageCrop((prev) => ({
                ...prev,
                isCropEnabled: true,
                isDrawingImageUpdated: true,
            }));
        }
    }, [drawingImageCrop.file]);

    useEffect(() => {
        setIsLoading(
            formik.isValidating ||
                formik.isSubmitting ||
                mutationCreateDrawingIsLoading ||
                mutationUpdateDrawingIsLoading,
        );
    }, [
        formik.isValidating,
        formik.isSubmitting,
        mutationCreateDrawingIsLoading,
        mutationUpdateDrawingIsLoading,
    ]);

    /////////////////////////////////////
    // ---- FUNCTIONS                ----
    /////////////////////////////////////

    const handleCropChange = () => {
        // update state to know that image crop change
        if (!drawingImageCrop.isDrawingImageUpdated) {
            setDrawingImageCrop((prev) => ({
                ...prev,
                isDrawingImageUpdated: true,
            }));
        }
    };

    const getCroppedImage = async () => {
        return await canvastoDataURL(
            cropperRef.current?.getCanvas() as HTMLCanvasElement,
            100,
            EImageType.PNG,
        );
    };

    return (
        <FormikProvider value={formik}>
            <TMC_Drawer open={props?.open} onClose={handleClose} {...props}>
                <Header
                    onClose={handleClose}
                    title={drawing ? fmt("TitleUpdate") : fmt("TitleCreate")}
                />
                <Form data-testid="Form-Drawing">
                    <Content>
                        <Stack display={"grid"} gap={2}>
                            <Stack
                                direction={"row"}
                                justifyContent="space-between"
                            >
                                <Typography
                                    variant="h3"
                                    data-testid="Form-Drawing-SectionFileTitle"
                                >
                                    {fmt("SectionFileTitle")}
                                </Typography>
                                {drawingImageCrop.image !== null && (
                                    <IconButton
                                        onClick={handleResetImage}
                                        data-testid="Form-Drawing-Image-Delete-Btn"
                                    >
                                        <Icon
                                            variant={"regular"}
                                            icon={"trash"}
                                        />
                                    </IconButton>
                                )}
                            </Stack>
                            {drawingImageCrop.image === null && (
                                <InputFile
                                    acceptFormats={["png", "jpg", "pdf"]}
                                    state={drawingImageCrop}
                                    setState={
                                        setDrawingImageCrop as Dispatch<
                                            SetStateAction<Type_Resource>
                                        >
                                    }
                                    data-testid="Form-Drawing-Input-Drawing-File"
                                />
                            )}
                            {drawingImageCrop.image &&
                                drawingImageCrop.isCropEnabled && (
                                    <Cropper
                                        ref={cropperRef}
                                        src={drawingImageCrop.image.src}
                                        imageRestriction={
                                            ImageRestriction.stencil
                                        }
                                        onChange={handleCropChange}
                                    />
                                )}
                            {drawingImageCrop.image &&
                                !drawingImageCrop.isCropEnabled && (
                                    <Box
                                        sx={(theme) => ({
                                            p: 2,
                                            border: theme.border.default,
                                            borderRadius:
                                                theme?.shape?.borderRadiusSmall,
                                        })}
                                    >
                                        <img
                                            src={drawingImageCrop.image.src}
                                            alt="drawing image"
                                        />
                                    </Box>
                                )}

                            <Typography
                                variant="h3"
                                data-testid="Form-Drawing-SectionSettings"
                            >
                                {fmt("SectionSettings")}
                            </Typography>

                            <TMC_TextField
                                data-testid="Form-Drawing-Input-Name"
                                {...formik.getFieldProps("name")}
                                label={fmt(`Fields.DrawingName`)}
                                error={
                                    formik.touched.name &&
                                    Boolean(formik.errors.name)
                                }
                            />
                            <Styled_FormControlLabel
                                data-testid="Form-Drawing-Toggle-ColorSetting-Label"
                                control={
                                    <TMC_ToggleButtonGroup
                                        value={formik.values.colorSetting}
                                        exclusive
                                        onChange={(e, value) =>
                                            formik.setFieldValue(
                                                "colorSetting",
                                                value,
                                            )
                                        }
                                        helperText={
                                            formik.errors.colorSetting as string
                                        }
                                    >
                                        {colorSettings.map((name) => (
                                            <ToggleButton
                                                data-testid={`Form-Drawing-ColorSettings-${name}`}
                                                value={name}
                                                key={name}
                                            >
                                                {fmt(
                                                    `Fields.ColorSettings.${name}`,
                                                )}
                                            </ToggleButton>
                                        ))}
                                    </TMC_ToggleButtonGroup>
                                }
                                label={fmt("Fields.ColorSetting")}
                            />

                            <TextSizeSlider
                                data-testid="Form-Drawing-Toggle-TextSize"
                                label={fmt("Fields.AdjustTextSize")}
                                {...formik.getFieldProps("textSize")}
                            />
                        </Stack>
                    </Content>
                    <Footer>
                        <TMC_Button
                            data-testid="Form-Drawing-Submit-Btn"
                            color="primary"
                            type="submit"
                            disabled={isLoading}
                        >
                            {fmtCta(`Save`)}
                        </TMC_Button>
                    </Footer>
                </Form>
            </TMC_Drawer>
        </FormikProvider>
    );
};
