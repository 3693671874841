import { ButtonProps, Stack } from "@mui/material";
import React, { useMemo } from "react";

import { TMC_Button } from "src/components/Components_Common";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useUser } from "src/contexts/user";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_AddButton = ButtonProps & {
    page: string;
    label?: string;
};
export const AddButton = ({
    page,
    label,
    ...restProps
}: Type_Props_AddButton) => {
    const { formatMessageWithPartialKey: fmtActions } = useCoreIntl("Actions");

    const { checkPermission } = useUser();

    const show = useMemo(() => checkPermission(page, "create"), []);

    return !show ? (
        <Stack></Stack>
    ) : (
        <TMC_Button
            variant="contained"
            startIcon={<Icon variant="solid" icon="plus" />}
            {...restProps}
        >
            {label || fmtActions("Add")}
        </TMC_Button>
    );
};
