import {
    AutocompleteRenderGetTagProps,
    AutocompleteRenderInputParams,
    AutocompleteValue,
    Chip,
    Autocomplete as MuiAutocomplete,
    Skeleton,
} from "@mui/material";
import { AutocompleteProps } from "@mui/material/Autocomplete/Autocomplete";
import { ChipTypeMap } from "@mui/material/Chip";
import { useEffect, useRef } from "react";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";

import { Type_selectList } from "src/api/types";
import { countryCodeLanguageRtl } from "src/assets/translations";
import { TMC_TextField } from "src/components/Components_Common/_MuiComponentsVariants";
import { FormHelperMessage } from "src/components/Components_Common/_MuiComponentsVariants/FormHelperText/FormHelperMessage";
import { Type_ReadonlyComponent } from "src/components/Components_Common/types";
import { getLocalStorageItem } from "src/utils/localStorageServices";

export type Type_Props_Autocomplete<
    T extends Type_selectList,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
    ChipComponent extends React.ElementType = ChipTypeMap["defaultComponent"],
> = Omit<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent>,
    "renderInput"
> &
    Type_ReadonlyComponent & {
        isFetching?: boolean;
        placeholder?: string;
        autoFocus?: boolean;
        name: string;
        label?: string;
        renderInputProps?: any;
        disabledOptions?: number[];
    };

export const getOptionLabelDefault = (
    option: any, // TODO fix that : Value | AutocompleteFreeSoloValueMapping<FreeSolo>
) => (typeof option === "string" ? option : option?.name);

export const renderOptionDefault = (props: any, option: any) => (
    <li
        {...props}
        key={props.key}
        data-testid={`${props.id.slice(0, props.id.lastIndexOf("-"))}-${option.id}`}
    >
        {option.name}
    </li>
);

export const renderTagsDefault = (
    tagValue: any[],
    getTagProps: AutocompleteRenderGetTagProps,
) =>
    tagValue.map((option, index: number) => (
        // eslint-disable-next-line react/jsx-key
        <Chip
            label={option.name}
            data-testid={`Autocomplete-tag-${option.id}`}
            {...getTagProps({ index })}
            key={`${option.name}-${index}`}
        />
    ));

export const Autocomplete = <
    T extends Type_selectList,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
    ChipComponent extends React.ElementType = ChipTypeMap["defaultComponent"],
>({
    isFetching = false,
    autoFocus = false,
    name,
    label,
    defaultValue,
    getOptionLabel = getOptionLabelDefault,
    renderOption = renderOptionDefault,
    renderTags = renderTagsDefault,
    renderInputProps = {
        variant: "standard",
    },
    multiple,
    readonly,
    ...restProps
}: Type_Props_Autocomplete<
    T,
    Multiple,
    DisableClearable,
    FreeSolo,
    ChipComponent
>) => {
    const { formState, control } = useFormContext(); // retrieve those props
    const isRtlLang = countryCodeLanguageRtl.includes(
        getLocalStorageItem("language"),
    );

    const autocompleteRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!isFetching && autoFocus && autocompleteRef.current) {
            const input = autocompleteRef.current.querySelector(
                "input",
            ) as HTMLInputElement;
            if (input) {
                input.click();
                // Simulate a keyDown event to open the options dropdown
                const keyboardEvent = new KeyboardEvent("keydown", {
                    key: "ArrowDown",
                });
                input.dispatchEvent(keyboardEvent);
            }
        }
    }, [isFetching, autoFocus]);

    return (
        <>
            {isFetching ? (
                <Skeleton variant="rounded" height={60} />
            ) : (
                <Controller
                    control={control}
                    name={name}
                    defaultValue={defaultValue ?? (multiple ? [] : null)}
                    render={({
                        field: { ref, onChange, onBlur: onBlurRhf, value },
                    }) => (
                        <MuiAutocomplete
                            {...restProps}
                            multiple={multiple}
                            ref={autocompleteRef}
                            id={`Autocomplete-${name}`}
                            data-testid={`Autocomplete-${name}-${multiple ? "multiple" : "simple"}`}
                            getOptionLabel={getOptionLabel}
                            isOptionEqualToValue={(
                                option,
                                selectedValues,
                            ): boolean => option?.id === selectedValues.id}
                            renderInput={(
                                params: AutocompleteRenderInputParams,
                            ) => {
                                return (
                                    <TMC_TextField
                                        {...params}
                                        {...renderInputProps}
                                        inputRef={ref} // RHF
                                        label={label}
                                        inputProps={{
                                            ...params.inputProps,
                                            onBlur: (e) => {
                                                if (params.inputProps.onBlur) {
                                                    params.inputProps.onBlur(
                                                        e as React.FocusEvent<HTMLInputElement>,
                                                    );
                                                }
                                                onBlurRhf();
                                            },
                                            "data-testid": `Autocomplete-${name}-input`,
                                        }}
                                        // RHF
                                        error={!!formState.errors[name]}
                                        helperText={
                                            formState.errors[name] ? (
                                                <FormHelperMessage
                                                    inputName={name as string}
                                                    message={
                                                        formState.errors?.[name]
                                                            ?.message as string
                                                    }
                                                    isRtlLang={isRtlLang}
                                                />
                                            ) : null
                                        }
                                    />
                                );
                            }}
                            renderTags={renderTags}
                            renderOption={renderOption}
                            disabled={readonly || restProps.disabled}
                            // RHF
                            value={value || (multiple ? [] : null)}
                            onChange={(
                                event,
                                value: AutocompleteValue<
                                    T,
                                    Multiple,
                                    DisableClearable,
                                    FreeSolo
                                >,
                            ) => {
                                onChange(value);
                            }}
                        />
                    )}
                />
            )}
        </>
    );
};
