import { Typography } from "@mui/material";
import {
    GridRenderCellParams,
    GridRowId,
    GridRowModel,
} from "@mui/x-data-grid-pro";
import React, { Dispatch, SetStateAction, useMemo, useState } from "react";

import { Type_index_dayOff } from "src/api/tms-projects/daysOffs/types";
import { Type_selectList_company } from "src/api/tms-projects/projectCompanies/types";
import { StartDateEndDateCell } from "src/components/Components_Common/cellComponents/StartDateEndDateCell/StartDateEndDateCell";
import { TradesListCell } from "src/components/Components_Common/cellComponents/TradesListCell/TradesListCell";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { ModalDelete } from "src/components/Components_Common/ModalDelete/ModalDelete";
import { HeaderGrid } from "src/components/Components_Common/Table/HeaderGrid/HeaderGrid";
import {
    GRID_HEIGHT,
    Table,
    Type_column,
    Type_menuDropDown,
} from "src/components/Components_Common/Table/Table";
import { AddButton } from "src/components/Components_Teamoty/AddButton/AddButton";
import { useProject } from "src/contexts/project";
import { DayOffDrawer } from "src/drawers/dayOff/DayOffDrawer";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { Type_modalDeleteDayOff } from "src/pages/Pages_Teamoty/Project/Settings/Page_DaysOff/Page_DaysOff.container";

export type Type_Props_Page_DaysOffComponent = {
    daysOff: Type_index_dayOff[];
    isFetching: boolean;
    changeStatus: (id: number, dayOff: any) => void;
    deleteDayOff: (id: number) => void;
    modalDeleteDayOff: Type_modalDeleteDayOff;
    setModalDeleteDayOff: Dispatch<SetStateAction<Type_modalDeleteDayOff>>;
};

export const Page_DaysOffComponent = ({
    daysOff,
    isFetching,
    changeStatus,
    deleteDayOff,
    modalDeleteDayOff,
    setModalDeleteDayOff,
}: Type_Props_Page_DaysOffComponent) => {
    const { formatMessageWithPartialKey: fmtTableDataName } =
        useCoreIntl("Table.DataName");
    const { formatMessageWithPartialKey: fmtTableColumn } =
        useCoreIntl("Table.Column");

    const page = "dayOffs";
    const { requestConfig, isLoading: isLoadingProject } = useProject();

    const [selectedDayOff, setSelectedDayOff] = useState<number | null>(null);
    const [openDayOffDrawer, setOpenDayOffDrawer] = useState(false);
    const [action, setAction] = useState<Type_action>("create");

    const openDrawer = (dayOffId: number | null, action: Type_action) => {
        setSelectedDayOff(dayOffId);
        setAction(action);
        setOpenDayOffDrawer(true);
    };

    const handleDayOffCreate = () => openDrawer(null, "create");

    const handleDayOffEdit = (dayOffId: number) =>
        openDrawer(dayOffId, "update");

    const handleDayOffDuplicate = (dayOffId: number) =>
        openDrawer(dayOffId, "duplicate");

    const handleCloseDayOffDrawer = () => {
        setOpenDayOffDrawer(false);
    };

    const dropDownItems: Type_menuDropDown = {};

    dropDownItems.callbackEdit = (row: GridRowModel) => {
        handleDayOffEdit(row.id);
    };

    dropDownItems.callbackDuplicate = (row: GridRowModel) => {
        handleDayOffDuplicate(row.id);
    };

    dropDownItems.callbackStatus = (row: GridRowModel) => {
        changeStatus(row.id, row as Type_index_dayOff);
    };

    dropDownItems.callbackDelete = (row: GridRowModel) => {
        setModalDeleteDayOff({
            isOpen: true,
            props: row as Type_index_dayOff,
        });
    };

    const showItem = (row: GridRowModel) => {
        if (isLoadingProject) return false;
        if (!isLoadingProject && requestConfig.subProjectId) {
            return !!row.subProject;
        }
        return true;
    };

    dropDownItems.showEdit = showItem;
    dropDownItems.showDuplicate = showItem;
    dropDownItems.showDelete = showItem;

    const gridColumns: Type_column[] = [
        {
            field: "name",
            headerName: fmtTableColumn("Name"),
            sortable: false,
        },
        {
            field: "startDate",
            headerName: fmtTableColumn("Dates"),
            sortable: false,
            colType: "dates",
            renderCell: (props: GridRenderCellParams) => {
                return (
                    <StartDateEndDateCell
                        startDate={props.row.startDate}
                        endDate={props.row.endDate}
                    />
                );
            },
        },
        {
            field: "companies",
            headerName: fmtTableColumn("Companies"),
            sortable: false,
            renderCell: (props: GridRenderCellParams) => {
                return props.row.companies.map(
                    (company: Type_selectList_company) => (
                        <Typography
                            key={`cell-${props.row.id}-company-${company.id}`}
                            data-testid={`cell-${props.row.id}-company-${company.id}`}
                        >
                            {company.name}
                        </Typography>
                    ),
                );
            },
        },
        {
            field: "trades",
            headerName: fmtTableColumn("Trades"),
            sortable: false,
            renderCell: (props: GridRenderCellParams) => {
                return <TradesListCell trades={props.row.trades} />;
            },
        },
    ];

    // Temporairement caché, remettre daysOff plus tard
    const daysOffWithoutSharedDaysOff = useMemo(
        () => daysOff?.filter((dayOff) => dayOff.subProject) || [],
        [daysOff],
    );

    return (
        <>
            <HeaderGrid>
                <AddButton
                    data-testid="DayOff-AddOne-btn"
                    onClick={() => handleDayOffCreate()}
                    page={page}
                />
            </HeaderGrid>
            <Table
                rows={daysOffWithoutSharedDaysOff}
                columns={gridColumns}
                disableColumnSorting
                noDataConfig={{
                    isFetching: isFetching,
                    dataName: fmtTableDataName("DaysOff"),
                    action: () => handleDayOffCreate(),
                }}
                onClick={(id: GridRowId) => {
                    handleDayOffEdit(parseInt(id as string));
                }}
                menuDropDown={dropDownItems}
                page={page}
                height={GRID_HEIGHT}
            />
            <ModalDelete
                open={modalDeleteDayOff.isOpen}
                onClose={() => setModalDeleteDayOff({ isOpen: false })}
                item={fmtTableDataName("DaysOff")}
                validationString={modalDeleteDayOff?.props?.name as string}
                actions={{
                    onSubmit: {
                        onClick: () => {
                            setModalDeleteDayOff({ isOpen: false });
                            deleteDayOff(
                                modalDeleteDayOff?.props?.id as number,
                            );
                        },
                    },
                }}
            />
            <DayOffDrawer
                open={openDayOffDrawer}
                onClose={handleCloseDayOffDrawer}
                dayOffIdToUpdate={selectedDayOff}
                action={action}
                page={page}
            />
        </>
    );
};
