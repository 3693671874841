import { Grid } from "@mui/material";
import { Key } from "react";

import { DashboardCard } from "src/components/Components_Teamoty/Dashboard/DashboardCard";
import { HeaderDashboard } from "src/components/Components_Teamoty/Dashboard/HeaderDashboard";
import { Type_Props_ViewData } from "src/pages/Pages_Teamoty/Project/Views";

export const ViewDashboard = ({ viewData: viewData }: Type_Props_ViewData) => {
    return (
        <Grid container spacing={4} padding={4}>
            <HeaderDashboard />
            {viewData?.data.type === "dashboard" &&
                viewData?.data?.settings?.widgets?.map(
                    (widget: any, index: Key | null | undefined) => {
                        return <DashboardCard widget={widget} key={index} />;
                    },
                )}
        </Grid>
    );
};
