import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    PutReturnType,
    remove,
    RemoveReturnType,
} from "src/api/fetch";
import { MetadataUsers, Url } from "src/api/paths";
import {
    Type_usr_post_metadataUser,
    Type_usr_put_metadataUser,
} from "src/api/tms-users/metadata/types";

export const indexMetadataUsers = (): GetReturnType => {
    return get(`${Url.USERS}${MetadataUsers.METADATA_USERS}`);
};

export const createMetadataUser = (
    data: Type_usr_post_metadataUser,
): PostReturnType => {
    return post(data, `${Url.USERS}${MetadataUsers.METADATA_USERS}`);
};

export const statusMetadataUser = (
    data: Type_usr_put_metadataUser,
    id: number,
): PutReturnType => {
    return put(data, `${Url.USERS}${MetadataUsers.METADATA_USERS}/${id}`);
};

export const deleteMetadataUser = (id: number): RemoveReturnType => {
    return remove({ id }, `${Url.USERS}${MetadataUsers.METADATA_USERS}/${id}`);
};

export const showMetadataUser = (id: number): GetReturnType => {
    return get(`${Url.USERS}${MetadataUsers.METADATA_USERS}/${id}`);
};

export const updateMetadataUser = (
    id: number,
    data: Type_usr_put_metadataUser,
): PutReturnType => {
    return put(data, `${Url.USERS}${MetadataUsers.METADATA_USERS}/${id}`);
};
