import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    PutReturnType,
    remove,
    RemoveReturnType,
} from "src/api/fetch";
import { getFilterJoin, Type_Filter } from "src/api/filters";
import { MetadataProjects, Url } from "src/api/paths";
import {
    Type_com_post_metadataProject,
    Type_com_put_metadataProject,
} from "src/api/tms-commons/metadata/types";

export const indexMetadataProjects = (
    filters: Type_Filter = {},
): GetReturnType => {
    const urlParams: string = getFilterJoin(filters);

    return get(
        `${Url.COMMONS}${MetadataProjects.METADATA_PROJECTS}${urlParams}`,
    );
};

export const createMetadataProjects = (
    data: Type_com_post_metadataProject,
): PostReturnType => {
    return post(data, `${Url.COMMONS}${MetadataProjects.METADATA_PROJECTS}`);
};

export const statusMetadataProject = (data: any, id: number): PutReturnType => {
    return put(
        data,
        `${Url.COMMONS}${MetadataProjects.METADATA_PROJECTS}/${id}`,
    );
};

export const deleteMetadataProject = (id: number): RemoveReturnType => {
    return remove(
        { id },
        `${Url.COMMONS}${MetadataProjects.METADATA_PROJECTS}/${id}`,
    );
};

export const showMetadataProject = (id: number): GetReturnType => {
    return get(`${Url.COMMONS}${MetadataProjects.METADATA_PROJECTS}/${id}`);
};

export const updateMetadataProject = (
    id: number,
    data: Type_com_put_metadataProject,
): PostReturnType => {
    return put(
        data,
        `${Url.COMMONS}${MetadataProjects.METADATA_PROJECTS}/${id}`,
    );
};
