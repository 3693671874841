import { useFormik } from "formik";
import { Dispatch, SetStateAction, useEffect } from "react";
import * as Yup from "yup";

import {
    mutationCreateCompanyWithToken,
    mutationCreateUserWithToken,
} from "src/api/tms-users/userInvitations";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { Type_formData_NewUser_Invitation } from "src/pages/Pages_Common/Page_NewUser_Invitation/Page_NewUser_Invitation.container";

import { Step_5Component } from "./Step_5.component";

export type Type_Step_5ContainerProps = {
    formData: Type_formData_NewUser_Invitation;
    setFormData: Dispatch<SetStateAction<Type_formData_NewUser_Invitation>>;
    step: number;
    maxStep: number;
    handleStep: (stateAction: "previous" | "next") => void;
};
export const Step_5Container = ({
    formData,
    setFormData,
    step,
    maxStep,
    handleStep,
}: Type_Step_5ContainerProps) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Teamoty.NewUserInvitation.Step5",
    );
    const { mutate: mutateCreateUserWithToken } =
        mutationCreateUserWithToken() || {};
    const { mutate: mutateCreateCompanyWithToken } =
        mutationCreateCompanyWithToken(formData, mutateCreateUserWithToken) ||
        {};

    //set the checkbox to false when the container is reloaded
    useEffect(() => {
        setFormData({ ...formData, checkbox: false });
    }, []);

    const Schema_NewUser_Invitation_Step_5 = Yup.object().shape({
        checkbox: Yup.boolean()
            .required(fmt("Error.RequiredCheckbox"))
            .oneOf([true], FORM_ERR_FMT.REQUIRED),
    });

    const handleSubmit = async (formData: Type_formData_NewUser_Invitation) => {
        try {
            if (formData.company_id) {
                mutateCreateUserWithToken(formData);
            } else {
                mutateCreateCompanyWithToken(formData);
            }
            handleStep("next");
        } catch (e: any) {
            console.error(e.errors);
        }
    };

    const formik: any = useFormik({
        initialValues: formData,
        validationSchema: Schema_NewUser_Invitation_Step_5,
        onSubmit: handleSubmit,
        validateOnChange: false,
        validateOnBlur: false,
    });

    return (
        <Step_5Component
            step={step}
            maxStep={maxStep}
            formik={formik}
            handleStep={handleStep}
            formData={formData}
        />
    );
};
