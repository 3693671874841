import React from "react";

import {
    mutationCreateTaskNote,
    mutationDeleteTaskNote,
    mutationUpdateTaskNote,
    useIndexTaskNotes,
} from "src/api/tms-scheduling/taskNotes";
import { Type_index_taskNote } from "src/api/tms-scheduling/taskNotes/types";
import { useUser } from "src/contexts/user";
import {
    NotesForm,
    Type_permissionsNote,
    Type_Props_NotesForm,
} from "src/forms/notes/NotesForm";
import { useContextualDrawer } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

type Type_Props_TaskNotesForm = {
    taskNumber: number;
};

export const TaskNotesForm = ({ taskNumber }: Type_Props_TaskNotesForm) => {
    const { closePaper } = useContextualDrawer();
    const { hasPermission } = useUser();

    ///////////////////////////////////////
    ///            Query                ///
    ///////////////////////////////////////

    const { isFetching, data } =
        useIndexTaskNotes(
            {
                task_number: taskNumber,
            },
            !!taskNumber,
        ) || {};

    ///////////////////////////////////////
    ///           Mutations             ///
    ///////////////////////////////////////

    const { mutateAsync: mutateCreateNote } =
        mutationCreateTaskNote({
            task_number: taskNumber,
        }) || {};
    const { mutateAsync: mutateUpdateNote } = mutationUpdateTaskNote() || {};
    const { mutateAsync: mutateDeleteNote } =
        mutationDeleteTaskNote({
            task_number: taskNumber,
        }) || {};

    ///////////////////////////////////////
    ///         Permissions             ///
    ///////////////////////////////////////

    const permissions: Type_permissionsNote = {
        canShow: hasPermission("taskNotes_index"),
        canDelete: hasPermission("taskNotes_delete"),
        canEdit: hasPermission("taskNotes_create"),
    };

    return (
        <>
            {data && (
                <TaskNotes
                    apiRef="taskNotes"
                    data={data}
                    isFetching={isFetching}
                    mutateCreateNote={(formData) =>
                        mutateCreateNote({ ...formData, taskNumber })
                    }
                    mutateUpdateNote={mutateUpdateNote}
                    mutateDeleteNote={mutateDeleteNote}
                    permissions={permissions}
                    onClose={() => closePaper("taskNotes")}
                    sendEventCount={"updateNotesCount"}
                />
            )}
        </>
    );
};

// this function formats the data correctly
const TaskNotes = ({ ...props }: Type_Props_NotesForm<Type_index_taskNote>) => {
    return <NotesForm {...props} />;
};
