import { Typography } from "@mui/material";
import { Formik } from "formik";
import React from "react";

import { Form, Spinner, TMC_Button } from "src/components";
import {
    Styled_FormDrawer,
    Styled_StackActionDrawer,
    Styled_StackContentDrawer,
} from "src/components/Components_Common/Drawer/Drawer.style";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Tab_MetadataComponentProps = {
    data: any;
    onSubmit: (values: any, props: any) => void;
    metadataProjects: any;
};

export const Tab_MetadataComponent = ({
    data,
    onSubmit,
    metadataProjects,
}: Type_Tab_MetadataComponentProps) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Projects.Drawer");
    const { formatMessageWithPartialKey: fmtCta } = useCoreIntl("Form.Cta");

    return (
        <Formik
            initialValues={data}
            onSubmit={onSubmit}
            validateOnChange={false}
        >
            {({ values, setFieldValue, isSubmitting }) => {
                const customSetFieldValue = (field: string, value: any) => {
                    setFieldValue("metadata." + field, value);
                };
                return (
                    <Styled_FormDrawer data-testid="EditProject-DrawerContent-TabMetadata-form">
                        <Styled_StackContentDrawer gap={4}>
                            <Typography variant="body1Bold">
                                {fmt(`AddProject.Step0.GlobalInformation`)}
                            </Typography>
                            <Form
                                fields={metadataProjects}
                                values={values.metadata}
                                onChange={customSetFieldValue}
                            />
                        </Styled_StackContentDrawer>
                        <Styled_StackActionDrawer>
                            <TMC_Button
                                data-testid="EditProject-DrawerContent-TabMetadata-updateBtn"
                                variant="contained"
                                type="submit"
                                disabled={isSubmitting}
                                sx={{ minWidth: 200 }}
                                endIcon={
                                    !isSubmitting && (
                                        <Icon
                                            variant="solid"
                                            icon="arrow-right"
                                        />
                                    )
                                }
                            >
                                {isSubmitting ? <Spinner /> : fmtCta(`Save`)}
                            </TMC_Button>
                        </Styled_StackActionDrawer>
                    </Styled_FormDrawer>
                );
            }}
        </Formik>
    );
};
