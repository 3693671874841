import { Stack, Typography } from "@mui/material";
import { FormikProvider } from "formik";
import React from "react";

import { Form, Spinner, TMC_Button } from "src/components";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import {
    Styled_Form,
    Styled_Scrollable_Stack,
} from "src/pages/Pages_Common/Page_NewUser_Invitation/Page_NewUser_Invitation.style";
import { COLORS } from "src/theme/stylesheet";

export type Type_Step_3ComponentProps = {
    metadataUsersForm: any;
    step: number;
    maxStep: number;
    handleStep: (stateAction: "previous" | "next") => void;
    formik: any;
};

export const Step_3Component = ({
    metadataUsersForm,
    step,
    maxStep,
    handleStep,
    formik,
}: Type_Step_3ComponentProps) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Teamoty.NewUserInvitation",
    );
    const { formatMessageWithPartialKey: fmtCta } = useCoreIntl("Form.Cta");

    const customSetFieldValue = (field: string, value: any) => {
        formik.setFieldValue("metadata." + field, value);
    };

    return (
        <FormikProvider value={formik}>
            <Styled_Form data-testid={`UsersInvitation-step-${step}-form`}>
                <Stack rowGap={6}>
                    <Stack rowGap={2} width={"100%"}>
                        <Typography
                            variant="body2"
                            color={COLORS.moon600}
                            data-testid={`UsersInvitation-step-${step}-of-${maxStep}`}
                        >
                            {fmt("Steps", {
                                values: {
                                    currentStep: step,
                                    maxStep: maxStep,
                                },
                            })}
                        </Typography>
                        <Typography
                            variant="h1"
                            data-testid={`UsersInvitation-step-${step}-title`}
                        >
                            {fmt("Step3.Title")}
                        </Typography>
                    </Stack>

                    <Styled_Scrollable_Stack
                        data-testid={`UsersInvitation-step-${step}-metadataForm`}
                    >
                        <Form
                            fields={metadataUsersForm}
                            values={formik.values.metadata}
                            onChange={customSetFieldValue}
                        />
                    </Styled_Scrollable_Stack>
                </Stack>
                <Stack direction={"row"} justifyContent={"space-between"}>
                    <TMC_Button
                        data-testid={`UsersInvitation-step-${step}-GoBack-btn`}
                        onClick={() => handleStep("previous")}
                        type="button"
                        variant="text"
                        disabled={formik.isSubmitting}
                        startIcon={
                            !formik.isSubmitting && (
                                <Icon variant="solid" icon="arrow-left" />
                            )
                        }
                        sx={{
                            width: "fit-content",
                            blockSize: "fit-content",
                            color: COLORS.blue700,
                        }}
                    >
                        {fmtCta("Back")}
                    </TMC_Button>
                    <TMC_Button
                        data-testid={`UsersInvitation-step-${step}-Submit-btn`}
                        type="submit"
                        variant="contained"
                        disabled={formik.isSubmitting}
                        endIcon={
                            !formik.isSubmitting && (
                                <Icon variant="solid" icon="arrow-right" />
                            )
                        }
                        sx={{ width: "fit-content", blockSize: "fit-content" }}
                    >
                        {formik.isSubmitting ? (
                            <Spinner style="button" />
                        ) : (
                            fmt("Step3.Cta.Submit")
                        )}
                    </TMC_Button>
                </Stack>
            </Styled_Form>
        </FormikProvider>
    );
};
