import { useState } from "react";

import { InputMultipleSelectComponent } from "./InputMultipleSelect.component";

export type InputMultipleSelectPropsType = {
    label: string;
    data: any;
    name: string;
    onChange: any;
    placeholder?: string;
    defaultValue?: string;
};

export const InputMultipleSelectContainer = (
    props: InputMultipleSelectPropsType,
) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = (mustBeOpen: boolean) => {
        setIsOpen(mustBeOpen);
    };

    return (
        <InputMultipleSelectComponent
            {...props}
            isOpen={isOpen}
            handleOpen={handleOpen}
        />
    );
};
