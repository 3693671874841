import { Stack, Typography } from "@mui/material";
import { isEqual } from "lodash";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";

import { mutationPrint } from "src/api/tms-print/print";
import { formatterOrientationToRadioItem } from "src/api/tms-print/print/formatters";
import {
    defaultPrintSettings,
    orientation,
    Type_post_print,
} from "src/api/tms-print/print/types";
import { mutationUpdateView } from "src/api/tms-projects/workspacesAndViews/views";
import { formatterPostPrintToPrintSettings } from "src/api/tms-projects/workspacesAndViews/views/formatters";
import { Type_show_view } from "src/api/tms-projects/workspacesAndViews/views/types";
import { TMC_Button } from "src/components";
import { RadioGroup } from "src/components/Components_Common/forms/reactHookFormComponents/RadioGroup/RadioGroup";
import { SelectPaperFormat } from "src/components/Components_Teamoty/forms/select/SelectPaperFormat";
import { SelectPrintTimelineType } from "src/components/Components_Teamoty/forms/select/SelectPrintTimelineType";
import { useFormDefaultConfig } from "src/configurations/app";
import { usePlanningContext } from "src/contexts/planning";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { Form } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

type Type_Props_PrintSettingsForm = {
    view: Type_show_view;
};

export const PrintSettingsForm = ({ view }: Type_Props_PrintSettingsForm) => {
    const { formatMessageWithPartialKey: fmtActions } = useCoreIntl("Form.Cta");
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Dialog.Print.Form");
    const { filters } = usePlanningContext();

    const { mutate: mutatePrint } = mutationPrint();
    const { mutate: mutateView } = mutationUpdateView(view.id);

    const form = useForm<Type_post_print>({
        ...useFormDefaultConfig,
        defaultValues: {
            ...defaultPrintSettings,
        },
        values:
            view?.data.type === "planning" || view?.data.type === "gantt"
                ? (view?.data.settings?.print as Type_post_print)
                : undefined,
    });

    const handleSubmit = (
        values: Type_post_print,
        e?: React.BaseSyntheticEvent,
    ) => {
        e?.preventDefault();

        // Call download pdf file
        mutatePrint({
            ...values,
            withDaysOff: filters.withDaysOff,
            withWeekend: filters.withWeekend,
        });

        // Store current print settings in the view
        const printSettings = formatterPostPrintToPrintSettings(values);

        // Compare with previous settings before saving on the view
        if (
            (view.data.type === "planning" || view.data.type === "gantt") &&
            !isEqual(printSettings, view.data?.settings?.print)
        ) {
            mutateView({
                data: {
                    ...view.data,
                    settings: {
                        ...(view.data?.settings ? view.data?.settings : {}),
                        print: printSettings,
                    },
                },
            });
        }
    };

    return (
        <FormProvider {...form}>
            <Form
                onSubmit={form.handleSubmit(handleSubmit)}
                id={"print-settings"}
                sx={{ justifyContent: "space-between" }}
            >
                <Stack
                    gap={2}
                    direction="column"
                    alignSelf={"stretch"}
                    paddingBottom={2}
                >
                    <Typography variant="h3">{fmt("Format")}</Typography>
                    <SelectPaperFormat
                        name={"paperFormat"}
                        label={fmt("Format")}
                        fullWidth
                    />
                    <RadioGroup
                        name={"orientation"}
                        label={fmt("Layout")}
                        options={formatterOrientationToRadioItem(
                            Object.values(orientation),
                        )}
                    />
                    <SelectPrintTimelineType
                        name={"timelineType"}
                        label={fmt("Timeline")}
                        fullWidth
                    />
                </Stack>
                <Stack gap={2} direction="row-reverse">
                    <TMC_Button
                        type="submit"
                        disabled={
                            form.formState.isSubmitting ||
                            form.formState.isLoading
                        }
                    >
                        {fmtActions("Download")}
                    </TMC_Button>
                    <TMC_Button
                        variant="outlined"
                        disabled={
                            form.formState.isSubmitting ||
                            form.formState.isLoading
                        }
                        onClick={() => console.log("TODO Generate action")}
                    >
                        {fmtActions("Generate")}
                    </TMC_Button>
                </Stack>
            </Form>
        </FormProvider>
    );
};
