import { Tooltip } from "@mui/material";
import React, { memo } from "react";

import logoTeamoty from "src/assets/images/logoTeamoty.png";
import {
    Styled_ProjectMenuBtn,
    Styled_SubProjectTypography,
} from "src/components/Components_Teamoty/TopBar/TopBar_Left/ProjectMenu/ProjectMenu.style";
import { useProject } from "src/contexts/project";

type Type_Props_ProjectMenuButton = {
    handleClick: (event: React.MouseEvent<HTMLElement>) => void;
};

export const ProjectMenuButton = memo(function ProjectMenuButton({
    handleClick,
}: Type_Props_ProjectMenuButton) {
    const { subProject } = useProject();

    return (
        <Styled_ProjectMenuBtn
            aria-label="selected project menu"
            aria-haspopup="true"
            onClick={handleClick}
            data-testid={`ProjectMenu-Open-btn`}
            aria-controls={"project-menu"}
            size={"large"}
        >
            <img
                alt={"teamoty logo"}
                src={logoTeamoty}
                width={21}
                style={{ zIndex: 1 }}
            />
            {subProject?.name && (
                <Tooltip title={subProject.name}>
                    <Styled_SubProjectTypography>
                        {subProject.name}
                    </Styled_SubProjectTypography>
                </Tooltip>
            )}
        </Styled_ProjectMenuBtn>
    );
});
