import {
    Box,
    Divider,
    AppBar as MuiAppBar,
    Toolbar as MuiToolbar,
    Stack,
    Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

import {
    DropdownMenu,
    TMC_Button,
    TMC_ToggleButton,
} from "src/components/Components_Common";
import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { usePlanningContext } from "src/contexts/planning";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { useContextualDrawer } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

////////////////////////////////////////////
// --- STYLES                             //
////////////////////////////////////////////

const Styled_AppBar = styled(MuiAppBar)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    borderBottom: `1px solid ${theme.palette.divider}`,
}));

const ToolbarSection = styled(Stack)(({ theme }) => ({
    flexDirection: "row",
    alignItems: "center",
    color: theme.palette.text.primary,
    gap: theme.spacing(2),
}));

////////////////////////////////////////////
// --- Toolbar                             //
////////////////////////////////////////////

export const PlanningToolbar = () => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.Toolbar",
    );
    const { setEnableTaskDone, enableTaskDone } = usePlanningContext();

    const { openPaper } = useContextualDrawer();

    const handleSequencesClick = () => {
        openPaper("sequences", {}, true);
    };

    const menuItems = [
        {
            id: "sequences",
            icon: {
                name: "light-tds-sequence",
                variant: "kit",
                fontSize: "small",
            },
            label: fmt("Sequences"),

            call: () => console.log("Sequences clicked"),
        },
        {
            id: "trades",
            icon: {
                name: "helmet-safety",
                variant: "light",
                fontSize: "small",
            },

            label: fmt("Trades"),
            call: () => console.log("Trades clicked"),
        },
        {
            id: "companies",
            icon: {
                name: "building",
                variant: "light",
                fontSize: "small",
            },
            label: fmt("Companies"),
            call: () => console.log("Companies clicked"),
        },
    ];

    const theme = useTheme();
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Styled_AppBar position="static" elevation={0}>
                <MuiToolbar variant="dense">
                    <ToolbarSection color={theme.palette.text.primary}>
                        <DropdownMenu
                            iconButton={{
                                sx: { color: theme.palette.text.primary },
                            }}
                            showHeader
                            id="group-by"
                            label={fmt("GroupBy")}
                            icon={
                                <Icon
                                    color={"inherit"}
                                    icon="rectangle-list"
                                    variant="light"
                                />
                            }
                            menuItems={menuItems}
                        />

                        <IconButton
                            aria-label="filter-btn"
                            color={"inherit"}
                            data-testid={`planning-toolbar-filter-btn`}
                        >
                            <Icon
                                sx={{ mr: 1 }}
                                fontSize="small"
                                icon="bars-filter"
                                variant="light"
                            />
                            <Typography variant="body2">
                                {fmt("Filters")}
                            </Typography>
                        </IconButton>

                        <IconButton
                            aria-label="sequence-btn"
                            color={"inherit"}
                            data-testid={`planning-toolbar-sequence-btn`}
                            onClick={handleSequencesClick}
                        >
                            <Icon
                                sx={{ mr: 1 }}
                                icon="light-tds-sequence"
                                variant="kit"
                            />
                            <Typography variant="body2">
                                {" "}
                                {fmt("Sequences")}
                            </Typography>
                        </IconButton>

                        <Divider
                            orientation="vertical"
                            variant="middle"
                            flexItem
                        />
                        <IconButton
                            color={"inherit"}
                            aria-label="add-btn"
                            data-testid={`planning-toolbar-add-task-btn`}
                        >
                            <Icon icon="add" variant="light" />
                        </IconButton>

                        <TMC_ToggleButton
                            value="check"
                            selected={enableTaskDone}
                            onChange={() => setEnableTaskDone(!enableTaskDone)}
                        >
                            <Icon icon="circle-check" variant="light" />
                        </TMC_ToggleButton>

                        <DropdownMenu
                            iconButton={{
                                sx: { color: theme.palette.text.primary },
                            }}
                            showHeader
                            showCaretDownIcon
                            id="thumbtack"
                            icon={
                                <Icon
                                    color={"inherit"}
                                    icon="thumbtack"
                                    variant="light"
                                />
                            }
                        />
                    </ToolbarSection>

                    <Box sx={{ flexGrow: 1 }} />

                    <ToolbarSection>
                        <IconButton
                            color={"inherit"}
                            aria-label="add-btn"
                            data-testid={`planning-toolbar-settings-btn`}
                            onClick={() => {
                                openPaper("planningSettings", {});
                            }}
                        >
                            <Icon variant="light" icon="sliders" />
                        </IconButton>

                        <TMC_Button
                            variant="outlined"
                            data-testid={`planning-toolbar-export-btn`}
                            startIcon={
                                <Icon
                                    variant="light"
                                    icon="arrow-down-to-bracket"
                                />
                            }
                            onClick={() => console.log("Export PDF")}
                        >
                            {fmt("ExportPDF")}
                        </TMC_Button>
                    </ToolbarSection>
                </MuiToolbar>
            </Styled_AppBar>
        </Box>
    );
};
