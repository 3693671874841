import { Box } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";

import { useIndexTaskAreaPeople } from "src/api/tms-scheduling/peopleTaskArea";
import { Type_index_peopleTaskArea } from "src/api/tms-scheduling/peopleTaskArea/types";
import { Empty, UserSmallCard } from "src/components";
import { FullSpinner } from "src/components/Components_Common/Spinner/FullSpinner";
import { Type_event_peopleTaskArea, useChannel } from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { useContextualDrawer } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";
import { hasKey } from "src/utils/object";
import { sortData } from "src/utils/sortData";

type Type_Props_PeopleTaskAreaList = {
    peopleTaskAreas: Type_index_peopleTaskArea[];
};

const PeopleTaskList = ({ peopleTaskAreas }: Type_Props_PeopleTaskAreaList) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerTasks.PeopleTask",
    );

    const sortedPeopleTaskArea: Type_index_peopleTaskArea[] = useMemo(
        () => sortData(peopleTaskAreas, "firstName"),
        [peopleTaskAreas],
    );

    return (
        <Box display="flex" flexDirection="column" gap={1} paddingBottom={2}>
            {sortedPeopleTaskArea?.map(
                (assignee: Type_index_peopleTaskArea) => (
                    <UserSmallCard
                        id={assignee.id}
                        firstName={assignee.firstName}
                        lastName={assignee.lastName}
                        email={assignee.email}
                        key={`peopleTask_${assignee.id}`}
                        badgeLabel={
                            hasKey(assignee, "userId") || assignee?.userId
                                ? undefined
                                : fmt("Ext")
                        }
                        sx={{ paddingX: 0 }}
                    />
                ),
            )}
        </Box>
    );
};

export const PeopleTaskArea = ({ readonly }: any) => {
    const { getValues } = useFormContext();

    const [areaId, taskId] = getValues(["areaId", "taskId"]);

    const [peopleTaskAreas, setPeopleTaskAreas] = useState<
        Type_index_peopleTaskArea[]
    >([]);
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerTasks.PeopleTask",
    );

    const { openPaper } = useContextualDrawer();

    const { isFetching, data } = useIndexTaskAreaPeople(areaId, taskId);

    useEffect(() => {
        if (data?.length) setPeopleTaskAreas(data);
    }, [data]);

    useChannel({
        eventHandler: ({ event, data }) => {
            const resourceTasksData = data as Type_event_peopleTaskArea;
            if (event === "updatePeopleTasks") {
                setPeopleTaskAreas(resourceTasksData);
            }
        },
    });

    const handleNewPeopleTaskArea = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        openPaper("peopleTask", {
            id: taskId,
            focus: true,
            areaId: areaId,
        });
    };

    return isFetching ? (
        <FullSpinner />
    ) : peopleTaskAreas?.length ? (
        <PeopleTaskList peopleTaskAreas={peopleTaskAreas} />
    ) : (
        <Empty
            message={fmt("EmptyPeopleTaskMessage")}
            {...(!readonly && {
                actionMessage: fmt("AssignSomeone"),
                onClick: handleNewPeopleTaskArea,
            })}
            sx={{ marginBottom: 2 }}
            dataTestIdRef="PeopleTask"
        />
    );
};
