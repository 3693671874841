import { yupResolver } from "@hookform/resolvers/yup";
import { styled } from "@mui/material/styles";
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";

import { mutationChangeCurrentPassword } from "src/api/tms-users/auth";
import { PasswordRequirements } from "src/components/Components_Common/forms/PasswordRequirements";
import { TextFieldPassword } from "src/components/Components_Common/forms/reactHookFormComponents/TextFieldPassword/TextFieldPassword";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const Styled_Form_Security = styled("form")(({ theme }: any) => ({
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "column",
    gap: theme.spacing(2),
}));

export type Type_PasswordFormState = {
    currentPassword: string;
    password: string;
    passwordConfirm: string;
};

const Schema_PasswordForm = Yup.object().shape({
    currentPassword: Yup.string().required(FORM_ERR_FMT.REQUIRED),
    password: Yup.string()
        .required(FORM_ERR_FMT.REQUIRED)
        .min(16)
        .matches(/[0-9]/)
        .matches(/[a-zA-Z]/),
    passwordConfirm: Yup.string()
        .required(FORM_ERR_FMT.REQUIRED)
        .test("is-same", (value, context) => value === context.parent.password),
});

type Type_Props_PasswordForm = {
    formId?: string;
    setIsLoading?: (s: boolean) => void;
    setRequirementsComplete?: React.Dispatch<React.SetStateAction<boolean>>;
    onClose?: () => void;
};

export const PasswordForm = ({
    formId,
    setIsLoading,
    onClose,
    setRequirementsComplete,
}: Type_Props_PasswordForm) => {
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("MyAccount.Security");

    const initialState: Type_PasswordFormState = {
        currentPassword: "",
        password: "",
        passwordConfirm: "",
    };

    const { add } = useToast();
    const form = useForm({
        defaultValues: initialState,
        resolver: yupResolver<any>(Schema_PasswordForm),
    });
    const { handleSubmit, watch, reset, setError, setValue } = form;

    const { mutate, isLoading } = mutationChangeCurrentPassword();

    const onSubmit = (values: Type_PasswordFormState) => {
        mutate(values, {
            onSuccess: (): void => {
                add({
                    type: "success",
                    duration: 4000,
                    description: fmt("Form.ToastSuccess"),
                });
                reset();
                onClose?.();
            },
            onError: (): void => {
                add({
                    type: "warning",
                    duration: 4000,
                    description: fmt("Form.ToastError"),
                });
                setError("currentPassword", {
                    type: "required",
                    message: FORM_ERR_FMT.REQUIRED,
                });
                setValue("currentPassword", "");
            },
        });
    };

    useEffect(() => {
        setIsLoading?.(isLoading);
    }, [isLoading]);

    return (
        <FormProvider {...form}>
            <Styled_Form_Security
                id={formId}
                onSubmit={handleSubmit(onSubmit)}
                data-testid="PageSecurity-ChangePassword-form"
            >
                <TextFieldPassword
                    name="currentPassword"
                    label={fmt(`Form.Labels.CurrentPassword`)}
                    dataTestid="MyAccount-Security-Form-currentPassword"
                />
                <TextFieldPassword
                    name="password"
                    label={fmt(`Form.Labels.Password`)}
                    dataTestid="MyAccount-Security-Form-password"
                />
                <PasswordRequirements
                    password={watch("password")}
                    passwordConfirm={watch("passwordConfirm")}
                    setIsRequirementsComplete={setRequirementsComplete}
                />
                <TextFieldPassword
                    name="passwordConfirm"
                    label={fmt(`Form.Labels.PasswordConfirm`)}
                    dataTestid="MyAccount-Security-Form-PasswordConfirm"
                />
            </Styled_Form_Security>
        </FormProvider>
    );
};
