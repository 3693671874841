import { useEffect, useState } from "react";

import { useSelectListAreaWorkLevels } from "src/api/tms-scheduling/areas";
import { Type_selectList } from "src/api/types";
import {
    formatSelectListToSelectOptionItem,
    Type_SelectOptionItem,
} from "src/components/Components_Common/forms/reactHookFormComponents/Select/Select";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_BreakLevelList = {
    sequenceId?: number;
    withCurrent?: boolean;
};

const transformData = (
    data: Type_selectList[],
    withCurrent: boolean,
    name: string,
) => {
    // Increment the 'id' of each item by 1
    const dataCopy = data.map((item) => ({
        ...item,
        id: (item.id as number) + 1,
    }));

    // Remove the last item
    dataCopy.pop();

    // If withCurrent is true, add the 'current' item
    if (withCurrent) {
        dataCopy.unshift({ id: 0, name: name });
    }

    return dataCopy;
};

export const useBreakLevelList = ({
    sequenceId,
    withCurrent = false,
}: Type_Props_BreakLevelList) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Form.Field");
    const { isFetching, data } = useSelectListAreaWorkLevels(sequenceId);

    const [breakLevelList, setBreakLevelList] = useState<
        Type_SelectOptionItem[]
    >([]);

    useEffect(() => {
        if (!isFetching && data) {
            const transformedData = transformData(
                data,
                withCurrent,
                fmt("Current"),
            );
            setBreakLevelList(
                formatSelectListToSelectOptionItem(transformedData),
            );
        }
    }, [data, withCurrent]);

    return {
        isFetching,
        breakLevelList,
    };
};
