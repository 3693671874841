import { InputCheckboxComponent } from "./InputCheckbox.component";

/* COMPONENT TYPE */
export type fieldType = {
    value?: string;
    default?: boolean;
    label?: {
        label?: string;
        languageLabel?: string;
    };
};

export type InputCheckboxType = {
    type: "inputCheckbox";
    name: string;
    required?: boolean;
    label?: {
        label?: string;
        languageLabel?: string;
    };
    fields?: fieldType[];
};

/* PROPS TYPE */
export type InputCheckboxPropsType = {
    label: string;
    name: string;
    data: any;
    defaultValue?: boolean;
    onChange: any;
    value: any;
};

export const InputCheckboxContainer = ({
    label,
    name,
    data,
    onChange,
    value = [],
}: InputCheckboxPropsType) => {
    const handleChange = (e: any) => {
        const newTab = [...value];
        const newValue = e.target.value;
        const index = newTab.indexOf(newValue);

        if (index === -1) {
            newTab.push(newValue);
        } else {
            newTab.splice(index, 1);
        }

        onChange(name, newTab);
    };

    return (
        <InputCheckboxComponent
            label={label}
            value={value}
            data={data}
            onChange={handleChange}
        />
    );
};
