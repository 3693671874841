import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    PutReturnType,
    remove,
    RemoveReturnType,
    Type_RequestConfig,
} from "src/api/fetch";
import { CompanyPath, Url } from "src/api/paths";
import {
    Type_prj_post_company,
    Type_prj_put_company,
} from "src/api/tms-projects/projectCompanies/types";

export const indexProjectCompanies = (
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(`${Url.PROJECTS}${CompanyPath.COMPANIES}`, requestConfig);
};

export const showProjectCompany = (
    id: number,
    requestConfig?: Type_RequestConfig,
): GetReturnType => {
    return get(`${Url.PROJECTS}${CompanyPath.COMPANIES}/${id}`, requestConfig);
};

export const statusProjectCompany = (
    id: number,
    data: Type_prj_put_company,
    requestConfig: Type_RequestConfig,
): PutReturnType => {
    return put(
        data,
        `${Url.PROJECTS}${CompanyPath.COMPANIES}/${id}`,
        requestConfig,
    );
};

export const createProjectCompany = (
    data: Type_prj_post_company,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return post(data, `${Url.PROJECTS}${CompanyPath.COMPANIES}`, requestConfig);
};

export const updateProjectCompany = (
    id: number,
    data: Type_prj_put_company,
    requestConfig: Type_RequestConfig,
): PutReturnType => {
    return put(
        data,
        `${Url.PROJECTS}${CompanyPath.COMPANIES}/${id}`,
        requestConfig,
    );
};

export const deleteProjectCompany = (
    id: number,
    requestConfig: Type_RequestConfig,
): RemoveReturnType => {
    return remove(
        { id },
        `${Url.PROJECTS}${CompanyPath.COMPANIES}/${id}`,
        requestConfig,
    );
};

export const selectListProjectCompanies = (
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(
        `${Url.PROJECTS}${CompanyPath.COMPANIES_SELECT_LIST}`,
        requestConfig,
    );
};
