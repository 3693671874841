import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Stack } from "@mui/material";
import * as React from "react";
import { Dispatch, SetStateAction } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";

import {
    mutationCreatePriorityOfConstraint,
    mutationUpdatePriorityOfConstraint,
    useShowPriorityOfConstraint,
} from "src/api/tms-commons/priorityOfConstraints";
import {
    Type_post_priorityOfConstraint,
    Type_show_priorityOfConstraint,
} from "src/api/tms-commons/priorityOfConstraints/types";
import { LoadingBox } from "src/components";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { DrawerLabel } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric.style";
import { ColorPicker } from "src/components/Components_Common/forms/reactHookFormComponents/ColorPicker/ColorPicker";
import { InputMultiLanguages } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages";
import { inputMultiLanguagesSchema } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages.component";
import { TextField } from "src/components/Components_Common/forms/reactHookFormComponents/TextField/TextField";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_PriorityOfConstraintForm = {
    onClose: () => void;
    action: Type_action;
    priorityIdToUpdate: number | null;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
};

const Schema_PriorityOfConstraintForm = Yup.lazy(() => {
    return Yup.object().shape({
        names: Yup.object().shape(inputMultiLanguagesSchema.fields),
        color: Yup.string().required(FORM_ERR_FMT.REQUIRED),
        sortOrder: Yup.number()
            .typeError(FORM_ERR_FMT.MUST_BE_NUMBER)
            .integer(FORM_ERR_FMT.INTEGER)
            .min(1, FORM_ERR_FMT.MUST_BE_POSITIF)
            .required(FORM_ERR_FMT.REQUIRED),
    });
});

export const PriorityOfConstraintForm = ({
    onClose,
    action,
    priorityIdToUpdate,
    setIsLoading,
}: Type_Props_PriorityOfConstraintForm) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.PriorityOfConstraints",
    );

    const { mutateAsync: mutateCreate } =
        mutationCreatePriorityOfConstraint() || {};
    const { mutateAsync: mutateUpdate } =
        mutationUpdatePriorityOfConstraint() || {};
    const { data, isFetching } = useShowPriorityOfConstraint(
        priorityIdToUpdate as number,
    );

    const handleSubmit = async (
        values: Type_show_priorityOfConstraint | Type_post_priorityOfConstraint,
        e?: React.BaseSyntheticEvent,
    ) => {
        e?.preventDefault;

        setIsLoading(true);

        try {
            if (action === "update") {
                mutateUpdate(values as Type_show_priorityOfConstraint);
            } else {
                delete values.id;
                await mutateCreate(values as Type_post_priorityOfConstraint);
            }
            // Fermeture du drawer
            onClose();
        } catch (e: any) {
            console.error("Error when creating or updating priority level");
        }

        setIsLoading(false);
    };

    const form = useForm<
        Type_show_priorityOfConstraint | Type_post_priorityOfConstraint
    >({
        defaultValues: {
            color: "",
            sortOrder: 1,
            names: {},
            enabled: true,
        },
        values: data,
        resolver: yupResolver<any>(Schema_PriorityOfConstraintForm),
    });

    return (
        <FormProvider {...form}>
            <form
                onSubmit={form.handleSubmit(handleSubmit)}
                id={"priorityOfConstraints"}
            >
                {isFetching ? (
                    <LoadingBox />
                ) : (
                    <Stack gap={6}>
                        <Box width={"100%"}>
                            <InputMultiLanguages
                                name="names"
                                label={fmt(`PriorityName`)}
                            />
                            <Grid container>
                                <Grid item xs={6}>
                                    <DrawerLabel variant="h3">
                                        {fmt(`Color`)}
                                    </DrawerLabel>
                                    <ColorPicker
                                        name="color"
                                        label={""}
                                        data-testid="PriorityOfConstraint-Drawer-color"
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <Grid container>
                            <Grid item xs={6}>
                                <TextField
                                    name={"sortOrder"}
                                    label={fmt(`SortOrder`)}
                                />
                            </Grid>
                        </Grid>
                    </Stack>
                )}
            </form>
        </FormProvider>
    );
};
