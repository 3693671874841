import { InputAnchorPropsType } from "./InputAnchor.container";

export type InputAnchorType = {
    type: "inputAnchor";
    label?: {
        label?: string;
        languageLabel?: string;
    };
    link?: string;
};

export const InputAnchorComponent = ({ label, link }: InputAnchorPropsType) => {
    return (
        <a href={link} className="underline text-blue-800 text-sm">
            {label}
        </a>
    );
};
