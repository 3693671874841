import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";

import { Type_RequestConfig } from "src/api/fetch";
import { useSelectListRoles } from "src/api/tms-users/rolesAndPermissions";
import { mutationCreateUserInvitation } from "src/api/tms-users/users";
import { Type_post_userInvitation } from "src/api/tms-users/users/types";
import { LoadingBox } from "src/components";
import {
    formatSelectListToSelectOptionItem,
    Select,
} from "src/components/Components_Common/forms/reactHookFormComponents/Select/Select";
import { TextField } from "src/components/Components_Common/forms/reactHookFormComponents/TextField/TextField";
import { useFormDefaultConfig } from "src/configurations/app";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_UserInvitationForm = {
    onClose: () => void;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    requestConfig: Type_RequestConfig;
};

const Schema_UserInvitation = Yup.lazy(() => {
    return Yup.object().shape({
        email: Yup.string()
            .email(FORM_ERR_FMT.MUST_BE_EMAIL)
            .required(FORM_ERR_FMT.REQUIRED),
        roleId: Yup.number().required(FORM_ERR_FMT.REQUIRED),
    });
});

export const UserInvitationForm = ({
    onClose,
    setIsLoading,
    requestConfig,
}: Type_Props_UserInvitationForm) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.UserInvitation",
    );

    const { isFetching, data: fetchedRoles = [] } = useSelectListRoles();

    const form = useForm<Type_post_userInvitation>({
        ...useFormDefaultConfig,
        defaultValues: {
            email: "",
        },
        resolver: yupResolver<any>(Schema_UserInvitation),
    });
    const { mutateAsync: mutateCreate } =
        mutationCreateUserInvitation(requestConfig);

    const handleSubmit = async (values: Type_post_userInvitation) => {
        setIsLoading(true);

        try {
            await mutateCreate(values);

            onClose();
        } catch (e: any) {
            console.error("Error when creating user");
        }
        setIsLoading(false);
    };

    const optionsRoles = formatSelectListToSelectOptionItem(fetchedRoles);

    return (
        <FormProvider {...form}>
            <form
                onSubmit={form.handleSubmit(handleSubmit)}
                id={"userInvitation"}
            >
                {isFetching ? (
                    <LoadingBox />
                ) : (
                    <Stack gap={6}>
                        <TextField
                            label={fmt("Email")}
                            data-testid="UserInvitation-Drawer-email"
                            {...form.register("email")}
                        />
                        <Select
                            name="roleId"
                            options={optionsRoles}
                            label={fmt("Roles")}
                            data-testid="UserInvitation-Drawer-roles"
                        />
                    </Stack>
                )}
            </form>
        </FormProvider>
    );
};
