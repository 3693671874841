///////////////////////////////
/// React Hook Form         ///
///////////////////////////////

import { UseFormProps } from "react-hook-form";

export const useFormDefaultConfig: UseFormProps<any> = {
    /**
     * When set to all, all errors from each field will be gathered.
     * @see https://react-hook-form.com/docs/useform#criteriaMode
     */
    criteriaMode: "all",
    /**
     * Setting shouldUnregister: true makes your form behave more closely to native forms.
     * @see https://react-hook-form.com/docs/useform#shouldUnregister
     */
    // shouldUnregister: true,
    // TODO find why taskform loose TaskId on PUT
    /**
     * When set to true (default), and the user submits a form that fails validation, focus is set on the first field with an error.
     * @see https://react-hook-form.com/docs/useform#shouldFocusError
     */
    shouldFocusError: false,
};

export const useMeasureDefaultConfig = {
    debounce: 100,
    scroll: false,
};

export const separatorCodeTask: string = "-";
