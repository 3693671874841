import {
    Type_index_peopleTaskArea,
    Type_post_peopleTaskArea,
    Type_sch_index_peopleTaskArea,
    Type_sch_post_peopleTaskArea,
} from "src/api/tms-scheduling/peopleTaskArea/types";

export const formatterIndexPeopleTaskAreas = (
    peopleTaskAreas: Type_sch_index_peopleTaskArea[],
): Type_index_peopleTaskArea[] => {
    return peopleTaskAreas.map(
        (peopleTaskArea: Type_sch_index_peopleTaskArea) =>
            formatterIndexPeopleTaskArea(peopleTaskArea),
    );
};

export const formatterIndexPeopleTaskArea = (
    peopleTaskArea: Type_sch_index_peopleTaskArea,
): Type_index_peopleTaskArea => {
    return {
        peopleTaskId: peopleTaskArea.id,
        taskId: peopleTaskArea.task_id,
        areaId: peopleTaskArea.area_id,
        id: peopleTaskArea.people.id,
        firstName: peopleTaskArea.people.firstName,
        lastName: peopleTaskArea.people.lastName,
        email: peopleTaskArea.people.email,
        userId: peopleTaskArea.people.user_id,
    };
};

export const formatterFormPeopleTaskArea = (
    data: Type_post_peopleTaskArea,
): Type_sch_post_peopleTaskArea => {
    return {
        people_id: data.peopleId,
    };
};
