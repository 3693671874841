import { Menu, MenuItem, Stack, Typography } from "@mui/material";
import Fade from "@mui/material/Fade";
import { useState } from "react";

import { Spinner, TMC_ImportFileModal } from "src/components";
import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import {
    AcceptedFormat,
    Type_Resource,
} from "src/components/Components_Common/forms/InputFile";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_ExportImportDropDown_Component_Props = {
    title: string;
    isLoading?: boolean;
    errors?: any | null | undefined;
    importTypes: AcceptedFormat[];
    exportType: string;
    onExport: () => void;
    onImport: (sheet: any) => Promise<void>;
    onAbort?: () => void;
};

export const ExportImportDropDown = ({
    title,
    importTypes,
    exportType,
    isLoading = false,
    errors,
    onAbort = () => {},
    onImport,
    onExport,
}: Type_ExportImportDropDown_Component_Props) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [showModal, setShowModal] = useState<boolean>(false);
    const { formatMessageWithPartialKey: fmtActions } = useCoreIntl("Actions");
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setShowModal(false);
    };

    const handleExportClick = () => {
        handleClose();
        onExport();
    };

    const handleImportClick = (_sheet: any) => {
        handleClose();
        setShowModal(true);
    };

    const handleSubmit = async (sheet: Type_Resource) => {
        try {
            await onImport({ file: sheet.file });
            handleClose();
        } catch (err) {
            console.error({ err });
        }
    };
    return (
        <>
            {showModal && (
                <TMC_ImportFileModal
                    title={title}
                    acceptedFormats={importTypes}
                    isLoading={isLoading}
                    errors={errors}
                    onSubmit={handleSubmit}
                    onClose={() => setShowModal(false)}
                    onAbort={onAbort}
                />
            )}
            {isLoading ? (
                <Spinner size="small" />
            ) : (
                <div>
                    <IconButton
                        size="small"
                        aria-haspopup="true"
                        color="inherit"
                        onClick={handleClick}
                        data-testid="export-open-btn"
                        aria-controls={open ? "export-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        sx={{ marginX: 1 }}
                    >
                        <Icon
                            icon="arrow-down-to-bracket"
                            variant="light"
                            fontSize="medium"
                        />
                    </IconButton>

                    <Menu
                        id="fade-menu"
                        MenuListProps={{
                            "aria-labelledby": "fade-button",
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        TransitionComponent={Fade}
                    >
                        <MenuItem onClick={handleImportClick}>
                            <Stack
                                direction="row"
                                spacing={3}
                                alignItems={"center"}
                            >
                                <Icon
                                    icon="arrow-down-to-bracket"
                                    variant="light"
                                    fontSize="medium"
                                />
                                <Typography variant="body1">
                                    {fmtActions("Import")}
                                </Typography>
                            </Stack>
                        </MenuItem>
                        <MenuItem onClick={handleExportClick}>
                            <Stack
                                direction="row"
                                spacing={3}
                                alignItems={"center"}
                            >
                                <Icon
                                    icon="arrow-up-from-bracket"
                                    variant="light"
                                    fontSize="medium"
                                />
                                <Typography variant="body1">
                                    {fmtActions("Export")} ({exportType})
                                </Typography>
                            </Stack>
                        </MenuItem>
                    </Menu>
                </div>
            )}
        </>
    );
};
