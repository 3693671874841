import { GridPaginationModel } from "@mui/x-data-grid";
import { useEffect, useMemo, useRef, useState } from "react";

import { Type_RequestConfig } from "src/api/fetch";
import {
    mutationDeclineProjectUser,
    mutationDeleteProjectUser,
    mutationStatusProjectUser,
    mutationUpdateProjectUser,
    useIndexProjectUsers,
} from "src/api/tms-projects/projectUsers";
import {
    Type_index_projectUser,
    Type_put_projectUser,
} from "src/api/tms-projects/projectUsers/types";
import { USER_STATUS } from "src/api/tms-users/users/types";
import { useProject } from "src/contexts/project";
import { Page_ProjectUsersComponent } from "src/pages/Pages_Teamoty/Project/Settings/Page_ProjectUsers/Page_ProjectUsers.component";

export type Type_modalDeleteProjectUser = {
    isOpen: boolean;
    props?: Type_index_projectUser;
};

export const Page_ProjectUsersContainer = () => {
    const [modalDeleteProjectUser, setModalDeleteProjectUser] =
        useState<Type_modalDeleteProjectUser>({
            isOpen: false,
        });

    const [pagination, setPagination] = useState<GridPaginationModel>({
        page: 0,
        pageSize: 25,
    });

    const { project } = useProject();

    const defaultConfig: Type_RequestConfig = {
        params: {
            page: 1,
            per_page: 25,
        },
        projectId: project?.id,
    };

    const [config, setConfig] = useState<Type_RequestConfig>(defaultConfig);

    const [projectUsers, setProjectUsers] = useState<Type_index_projectUser[]>(
        [],
    );
    const { isFetching, data } = useIndexProjectUsers(config) || {};

    useEffect(() => {
        if (data?.projectUsers?.length) {
            setProjectUsers(data?.projectUsers);
        }
    }, [data]);

    useEffect(() => {
        setConfig((prev) => ({
            ...prev,
            params: {
                page: pagination.page + 1,
                per_page: pagination.pageSize,
            },
        }));
    }, [pagination]);

    const rowCountRef = useRef(data?.total || 0);

    const rowCount = useMemo(() => {
        if (data?.total !== undefined) {
            rowCountRef.current = data?.total;
        }
        return rowCountRef.current;
    }, [data?.total]);

    //DELETE PROJECT USER
    const { mutate: mutateDeleteProjectUser } =
        mutationDeleteProjectUser(config) || {};

    const removeProjectUser = (id: number) => {
        mutateDeleteProjectUser(id);
    };

    //CHANGE STATUS PROJECT USER
    const { mutate: mutateStatusProjectUser } =
        mutationStatusProjectUser(config) || {};

    const changeStatusProjectUser = (data: Type_index_projectUser) => {
        mutateStatusProjectUser({
            id: data.id,
            enabled: !data.enabled,
        } as Type_put_projectUser);
    };

    //DECLINE PROJECT USER SUBSCRIPTION
    const { mutate: mutateDeclineProjectUser } =
        mutationDeclineProjectUser(config) || {};

    const declineProjectUser = (id: number) => {
        mutateDeclineProjectUser(id);
    };

    //ACCEPT PROJECT USER SUBSCRIPTION
    const {
        mutate: mutateAcceptProjectUser,
        isLoading: isLoadingAcceptProjectUser,
    } = mutationUpdateProjectUser(config, true) || {};

    const acceptProjectUser = (data: Type_index_projectUser) => {
        mutateAcceptProjectUser({
            id: data.id,
            status: USER_STATUS.VALIDATED,
        } as Type_put_projectUser);
    };

    const isLoading = useMemo(
        () => isLoadingAcceptProjectUser || isFetching,
        [isFetching, isLoadingAcceptProjectUser],
    );

    return (
        <Page_ProjectUsersComponent
            isFetching={isLoading}
            projectUsers={projectUsers}
            pagination={pagination}
            setPagination={setPagination}
            rowCount={rowCount}
            config={config}
            removeProjectUser={removeProjectUser}
            modalDeleteProjectUser={modalDeleteProjectUser}
            setModalDeleteProjectUser={setModalDeleteProjectUser}
            changeStatusProjectUser={changeStatusProjectUser}
            declineProjectUser={declineProjectUser}
            acceptProjectUser={acceptProjectUser}
        />
    );
};
