import { Type_metadataProjects_For_Form } from "src/api/tms-commons/metadata/types";

import { Tab_MetadataComponent } from "./Tab_Metadata.component";

type Type_Tab_MetadataContainerProps = {
    data: any;
    onSubmit: (values: any, props: any) => void;
    metadataProjects: Type_metadataProjects_For_Form[];
};

export const Tab_MetadataContainer = ({
    onSubmit,
    metadataProjects,
    data,
}: Type_Tab_MetadataContainerProps) => {
    return (
        <Tab_MetadataComponent
            data={data}
            onSubmit={onSubmit}
            metadataProjects={metadataProjects}
        />
    );
};
