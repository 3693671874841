import { InputTimePropsType } from "./InputTime.container";

export type InputTimeType = {
    type: "inputTime";
    name: string;
    required?: boolean;
    label?: {
        label?: string;
        languageLabel?: string;
    };
    placeholder?: {
        label?: string;
        languageLabel?: string;
    };
};

export const InputTimeComponent = (_props: InputTimePropsType) => {
    return (
        <>
            <p>InputTimeComponent</p>
        </>
    );
};
