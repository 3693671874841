import * as React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { SubTradeKeys, TradeKeys } from "src/api/tms-projects/keys";
import {
    formatterIndexSubTrade,
    formatterSelectListSubTrades,
    formatterShowSubTrade,
} from "src/api/tms-projects/subTrades/formatters";
import {
    createSubTrade,
    deleteSubTrade,
    selectListSubTrades,
    showSubTrade,
    statusSubTrade,
    updateSubTrade,
} from "src/api/tms-projects/subTrades/services";
import {
    Type_prj_post_subTrade,
    Type_prj_put_subTrade,
    Type_selectList_subTrade,
    Type_show_subTrade,
} from "src/api/tms-projects/subTrades/types";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const mutationCreateSubTrade = () => {
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Drawer.SubTrades");
    const { addSuccess, addWarning } = useToast();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (subTrade: Type_prj_post_subTrade) =>
            createSubTrade(subTrade, requestConfig),
        onSuccess: async (data: any) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Error format data: mutationCreateSubTrade");
            }

            const formattedData = formatterIndexSubTrade(data.data.data);

            addSuccess({
                description: fmt("ToastSuccess", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        subTrade: formattedData.name,
                    },
                }),
            });

            await queryClient.invalidateQueries({
                queryKey: [TradeKeys.INDEX, requestConfig],
            });
        },
        onError: (err: any): void => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationUpdateSubTrade = () => {
    const { requestConfig } = useProject();
    const { addSuccess, addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Drawer.SubTrades");
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (subTradeToUpdate: Type_prj_put_subTrade) =>
            updateSubTrade(subTradeToUpdate, requestConfig),
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Error format data: mutationUpdateSubTrade");
            }

            const formattedData = formatterIndexSubTrade(data.data.data);

            addSuccess({
                description: fmt("ToastSuccessUpdate", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        subTrade: formattedData.name,
                    },
                }),
            });

            await queryClient.invalidateQueries({
                queryKey: [TradeKeys.INDEX, requestConfig],
            });
        },
        onError: (err: any): void => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const useShowSubTrade = (id?: number) => {
    const { requestConfig, isLoading: isLoadingRequestConfig } = useProject();
    return useQuery(
        [SubTradeKeys.SHOW, id],
        () => showSubTrade(id as number, requestConfig),
        {
            refetchOnWindowFocus: false,
            select: (data): Type_show_subTrade => {
                if (!data?.success || !data?.data?.data) {
                    throw new Error("Error format data: useShowSubTrade");
                }

                return formatterShowSubTrade(data.data.data);
            },
            onError: (err) => {
                return err;
            },
            enabled: !!id && !isLoadingRequestConfig,
        },
    );
};

export const mutationStatusSubTrade = () => {
    const { requestConfig } = useProject();
    const queryClient = useQueryClient();
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtError } = useCoreIntl("Errors");

    return useMutation({
        mutationFn: (data: Type_prj_put_subTrade) => {
            return statusSubTrade(data, requestConfig);
        },
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Error format data: mutationStatusSubTrade");
            }

            await queryClient.invalidateQueries({
                queryKey: [TradeKeys.INDEX, requestConfig],
            });
        },
        onError: () => {
            addWarning({
                description: fmtError("CantDisableSubTrade", {}),
            });
        },
    });
};

export const useSelectListSubTrades = (enabled = true) => {
    const { requestConfig, isLoading: isLoadingRequestConfig } = useProject();

    return useQuery({
        queryKey: [SubTradeKeys.SELECT_LIST, requestConfig],
        queryFn: () => selectListSubTrades(requestConfig),
        refetchOnWindowFocus: false,
        select: (data): Type_selectList_subTrade[] => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Error format data: useSelectListSubTrades");
            }

            return formatterSelectListSubTrades(data.data.data);
        },
        enabled:
            !!requestConfig.projectId && !isLoadingRequestConfig && enabled,
    });
};

export const mutationDeleteSubTrade = () => {
    const { addWarning, addSuccess } = useToast();
    const { formatMessageWithPartialKey: fmtError } = useCoreIntl("Errors");
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Drawer.SubTrades");
    const { requestConfig } = useProject();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (id: number) => deleteSubTrade(id, requestConfig),
        onSuccess: async (data) => {
            if (!data?.success) {
                throw new Error("Error format data: useIndexSubProjects");
            }

            await queryClient.invalidateQueries({
                queryKey: [TradeKeys.INDEX, requestConfig],
            });

            addSuccess({
                description: fmt("ToastSuccessDelete"),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtError("CantDeleteSubTrade", {}),
            });
            return err;
        },
    });
};
