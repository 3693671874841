import { InputStaticPropsType } from "./InputStatic.container";

export type InputStaticType = {
    type: "inputStatic";
    label?: {
        label?: string;
        languageLabel?: string;
    };
    unit?: {
        label?: string;
        languageLabel?: string;
    };
};

export const InputStaticComponent = (_props: InputStaticPropsType) => {
    return (
        <>
            <p>InputStaticComponent</p>
        </>
    );
};
