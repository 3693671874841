import { Stack, Typography } from "@mui/material";
import { GridRowModel } from "@mui/x-data-grid-pro";
import React from "react";

import { TMC_Chip } from "src/components/Components_Common/Chip/Chip";
import { invertColor } from "src/utils/colors";

type Type_Props_ResourceCell = {
    row: GridRowModel;
};

export const ResourceCell = ({ row }: Type_Props_ResourceCell) => {
    const backgroundColor = row.color;

    const colorText =
        backgroundColor !== "transparent"
            ? invertColor(row.color)
            : "text.primary";

    return (
        <Stack gap={1} direction={"row"} alignItems={"center"}>
            <TMC_Chip
                backgroundColor={backgroundColor}
                label={row.shortName.toUpperCase()}
                size={"small"}
                colorText={colorText}
                withShadow={true}
                sx={{ minWidth: "24px" }}
            />
            <Typography
                variant={"body1Medium"}
                color={row.enabled ? "text.primary" : "text.disabled"}
            >
                {row.name}
            </Typography>
        </Stack>
    );
};
