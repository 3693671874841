import { Stack, Typography } from "@mui/material";
import { Formik, FormikHelpers } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { Alert, Spinner, TMC_Button, TMC_TextField } from "src/components";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { COLORS } from "src/theme/stylesheet";
import { getLocalStorageItem } from "src/utils/localStorageServices";

import { Type_forgetPasswordState } from "./Page_ForgetPassword.container";
import {
    Styled_FormForgetPassword,
    Styled_StackBackToLogin,
    Styled_StackChildrenForgetPassword,
} from "./Page_ForgetPassword.style";

const schemaForgetPassword = Yup.object().shape({
    email: Yup.string()
        .email(FORM_ERR_FMT.MUST_BE_EMAIL)
        .required(FORM_ERR_FMT.REQUIRED),
});

type Type_Page_ForgetPasswordProps = {
    state: Type_forgetPasswordState;
    handleSubmit: (
        values: Type_forgetPasswordState,
        formikHelpers: FormikHelpers<Type_forgetPasswordState>,
    ) => void | Promise<any>;
    emailSended: boolean;
};

export const Page_ForgetPasswordComponent = ({
    state,
    handleSubmit,
    emailSended,
}: Type_Page_ForgetPasswordProps) => {
    const navigate = useNavigate();

    const { formatMessageWithPartialKey: fmt } = useCoreIntl("ForgetPassword");
    const lang = getLocalStorageItem("language");

    return (
        <Styled_StackChildrenForgetPassword gap={6}>
            <Typography variant="h1" data-testid="ForgetPassword-Title">
                {fmt("Title")}
            </Typography>
            {!emailSended && (
                <Typography variant="body1">{fmt("Description")}</Typography>
            )}
            <Formik
                initialValues={state}
                onSubmit={handleSubmit}
                validationSchema={schemaForgetPassword}
                validateOnChange={false}
            >
                {({ values, errors, handleChange, isSubmitting }) => {
                    if (emailSended)
                        return (
                            <Styled_FormForgetPassword data-testid="ForgetPassword-form">
                                <Alert status="info">
                                    {fmt("MessageEmailSended", {
                                        values: {
                                            email: values.email,
                                            b: (chunks: string) => (
                                                <Typography variant="body1Bold">
                                                    {chunks}
                                                </Typography>
                                            ),
                                        },
                                    })}
                                </Alert>
                                <Styled_StackBackToLogin
                                    data-testid="ForgetPassword-BackToLogin"
                                    sx={{ marginTop: "140px" }}
                                    onClick={() =>
                                        navigate(`/${lang}/login`, {
                                            replace: true,
                                        })
                                    }
                                >
                                    <Icon
                                        variant="solid"
                                        icon="arrow-left"
                                        sx={{ color: COLORS.blue700 }}
                                    />
                                    <Typography
                                        variant="body1Underline"
                                        color={COLORS.blue700}
                                    >
                                        {fmt("BackToLogin")}
                                    </Typography>
                                </Styled_StackBackToLogin>
                            </Styled_FormForgetPassword>
                        );
                    return (
                        <Styled_FormForgetPassword data-testid="ForgetPassword-form">
                            <TMC_TextField
                                data-testid="ForgetPassword-email"
                                name="email"
                                label={fmt(`EmailLabel`)}
                                onChange={handleChange}
                                value={values?.email}
                                helperText={errors?.email as string}
                                error={!!errors?.email}
                                autoComplete={"on"}
                            />
                            <Stack
                                flexDirection="row"
                                flexWrap="nowrap"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Typography variant="body2">
                                    {fmt("RememberPwd")}
                                </Typography>
                                <TMC_Button
                                    data-testid="ForgetPassword-SignIn-btn"
                                    type="button"
                                    variant="outlined"
                                    onClick={() =>
                                        navigate(`/${lang}/login`, {
                                            replace: true,
                                        })
                                    }
                                >
                                    {fmt("SignIn")}
                                </TMC_Button>
                            </Stack>
                            <TMC_Button
                                data-testid="ForgetPassword-Submit-btn"
                                type="submit"
                                variant="contained"
                                disabled={isSubmitting}
                                sx={{ marginTop: "68px" }}
                            >
                                {isSubmitting ? (
                                    <Spinner style="button" />
                                ) : (
                                    fmt("ReceiveInstruction")
                                )}
                            </TMC_Button>
                        </Styled_FormForgetPassword>
                    );
                }}
            </Formik>
        </Styled_StackChildrenForgetPassword>
    );
};
