import { InputDateComponent } from "./InputDate.component";

export type InputDatePropsType = {
    name: string;
    label?: string;
    placeholder?: string;
    onChange?: any;
    defaultValue?: string;
    value: any;
};

export const InputDateContainer = (props: InputDatePropsType) => {
    return <InputDateComponent {...props} />;
};
