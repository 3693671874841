import { Type_put_task } from "src/api/tms-scheduling/tasks/types";

type Type_fieldsList = keyof Type_put_task;

const fieldsDisplayByTaskType: { [key: number]: Type_fieldsList[] } = {
    // work
    1: [
        "withTradeColor",
        "subTrade",
        "companyId",
        "duration",
        "areaQuantity",
        "workLevel",
        "areaRhythmLevel",
        "areaRhythmBreak",
        "team",
        "workforce",
        "daily",
        "flow",
        "flowRhythmBreak",
        "flowRhythmLevel",
        "waitingDay",
        "waitingDayMode",
        "slideOutResourceType",
        "slideOutMovementType",
        "checking",
        "follow",
        "progressValue",
    ],
    // study
    2: [
        "withTradeColor",
        "subTrade",
        "companyId",
        "duration",
        "areaQuantity",
        "workLevel",
        "daily",
        "checking",
        "follow",
        "progressValue",
    ],
    // waiting
    3: ["duration", "checking", "follow", "progressValue"],
    // milestone
    4: ["withTradeColor", "subTrade", "companyId", "checking", "follow"],
    // delivery
    5: ["withTradeColor", "subTrade", "companyId", "checking", "follow"],
    // technical
    6: [
        "duration",
        "areaQuantity",
        "workLevel",
        "areaRhythmBreak",
        "areaRhythmLevel",
        "daily",
        "flow",
        "flowRhythmBreak",
        "flowRhythmLevel",
        "slideOutResourceType",
        "slideOutMovementType",
    ],
};

type Type_accordionList =
    | "links"
    | "date"
    | "automaticOptimization"
    | "waitingDays"
    | "ratio"
    | "slideOut"
    | "assignees"
    | "resources"
    | "materials"
    | "advanced"
    | "people"
    | "observations"
    | "progression";

const accordionDisplayByTaskType: { [key: number]: Type_accordionList[] } = {
    // work
    1: [
        "links",
        "date",
        "automaticOptimization",
        "waitingDays",
        "ratio",
        "slideOut",
        "assignees",
        "resources",
        "materials",
        "advanced",
        "progression",
        "observations",
    ],
    // study
    2: [
        "links",
        "date",
        "automaticOptimization",
        "waitingDays",
        "assignees",
        "advanced",
        "progression",
        "observations",
    ],
    // waiting
    3: [
        "links",
        "date",
        "automaticOptimization",
        "advanced",
        "progression",
        "people",
    ],
    // milestone
    4: ["links", "date", "automaticOptimization", "advanced", "observations"],
    // delivery
    5: ["links", "date", "automaticOptimization", "advanced", "observations"],
    // technical
    6: [
        "links",
        "date",
        "automaticOptimization",
        "waitingDays",
        "slideOut",
        "observations",
    ],
};

type Type_Props_shouldDisplay = {
    name: Type_fieldsList | Type_accordionList | any; // TODO need to be fixed
    taskType: number | null;
    type?: "field" | "accordion";
};
export const shouldDisplay = ({
    name,
    taskType,
    type = "field",
}: Type_Props_shouldDisplay) => {
    const config =
        type === "field" ? fieldsDisplayByTaskType : accordionDisplayByTaskType;

    return !!taskType && config[taskType] && config[taskType].includes(name);
};
