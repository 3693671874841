import { useCallback } from "react";
import { useQuery } from "react-query";

import { formatterSelectListAreaWorkLevels } from "src/api/tms-scheduling/areas/formatters";
import { selectListAreaWorkLevels } from "src/api/tms-scheduling/areas/services";
import { AreaWorkLevelKeys } from "src/api/tms-scheduling/keys";
import { useProject } from "src/contexts/project";

export const useSelectListAreaWorkLevels = (sequenceId?: number) => {
    const { requestConfig } = useProject();

    const newRequestConfig = { ...requestConfig, sequenceId: sequenceId };

    return useQuery({
        queryKey: [AreaWorkLevelKeys.SELECT_LIST, newRequestConfig],
        queryFn: () => selectListAreaWorkLevels(newRequestConfig),
        refetchOnWindowFocus: false,
        select: useCallback((data: any) => {
            return formatterSelectListAreaWorkLevels(data?.data?.data);
        }, []),
        enabled: !!requestConfig.projectId && !!sequenceId,
        staleTime: 1200000, // 2 min
    });
};
