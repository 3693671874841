import { Autocomplete } from "@mui/material";
import React from "react";

import { TMC_TextField } from "src/components/Components_Common/_MuiComponentsVariants";

import { InputSelectPropsType } from "./InputSelect.container";

type field = {
    value?: string;
    default?: boolean;
    label?: {
        label?: string;
        languageLabel?: string;
    };
};

export type InputSelectType = {
    type: "inputSelect";
    name: string;
    required?: boolean;
    search?: boolean;
    label?: {
        label?: string;
        languageLabel?: string;
    };
    placeholder?: {
        label?: string;
        languageLabel?: string;
    };
    fields?: field[];
    unit?: {
        label?: string;
        languageLabel?: string;
    };
};

export const InputSelectComponent = ({
    label,
    value,
    data,
    name,
    onChange,
    defaultValue,
}: InputSelectPropsType) => {
    return (
        <Autocomplete
            id={name}
            multiple={false}
            defaultValue={defaultValue}
            value={value || null}
            onChange={(e, value) => onChange(name, value)}
            options={data.map((item: any) => item)}
            isOptionEqualToValue={(option, selectedValues) =>
                option === selectedValues
            }
            renderInput={(params) => (
                <TMC_TextField
                    variant="outlined"
                    {...params}
                    name={name}
                    label={label}
                />
            )}
        />
    );
};
