import { NavigateFunction, useNavigate } from "react-router-dom";

import { Type_formData_NewUser_Invitation } from "src/pages/Pages_Common/Page_NewUser_Invitation/Page_NewUser_Invitation.container";
import { getLocalStorageItem } from "src/utils/localStorageServices";

import { Step_6Component } from "./Step_6.component";

export type Type_Step_6ContainerProps = {
    formData: Type_formData_NewUser_Invitation;
};

export const Step_6Container = ({ formData }: Type_Step_6ContainerProps) => {
    const language = getLocalStorageItem("language");
    const navigate: NavigateFunction = useNavigate();

    const handleLogin = (): void => {
        navigate(`/${language}/login`);
    };

    return <Step_6Component formData={formData} handleLogin={handleLogin} />;
};
