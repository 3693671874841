// import { DevTool } from "@hookform/devtools";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, debounce, Typography } from "@mui/material";
import React, {
    cloneElement,
    isValidElement,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import {
    FormProvider,
    SubmitHandler,
    useForm,
    useWatch,
} from "react-hook-form";
import * as Yup from "yup";

import { mutationUpdateTask } from "src/api/tms-scheduling/tasks";
import {
    formatterShowTask,
    formatterShowToPutTask,
} from "src/api/tms-scheduling/tasks/formatters";
import {
    Type_put_task,
    Type_show_task,
} from "src/api/tms-scheduling/tasks/types";
import { TMC_Button } from "src/components";
import {
    Accordion,
    Type_accordionList,
} from "src/components/Components_Common/accordions/Accordion";
import { useFormDefaultConfig } from "src/configurations/app";
import { HeaderToolbar } from "src/drawers/task/TaskContextualDrawer/HeaderToolbar";
import {
    Advanced,
    advancedInitialData,
    advancedSchema,
} from "src/forms/tasks/AccordionForms/Advanced";
import {
    AutomaticOptimizationForm,
    automaticOptimizationInitialData,
    automaticOptimizationSchema,
} from "src/forms/tasks/AccordionForms/AutomaticOptimizationForm/AutomaticOptimizationForm";
import {
    DateForm,
    dateFormInitialData,
    dateFormSchema,
} from "src/forms/tasks/AccordionForms/DateForm";
import {
    MainForm,
    mainFormInitialData,
    mainFormSchema,
} from "src/forms/tasks/MainForm/MainForm";
import {
    NameForm,
    nameFormInitialData,
    nameFormSchema,
} from "src/forms/tasks/NameForm/NameForm";
import { shouldDisplay } from "src/forms/tasks/utils";
import { useChannel } from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import {
    Content,
    Form,
    Header,
    useContextualDrawer,
} from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";
import { invertColor } from "src/utils/colors";
import {
    getLocalStorageItem,
    setLocalStorageItem,
} from "src/utils/localStorageServices";

import { LinksTask } from "./AccordionForms/Links/TaskLinks";
import { PeopleTask } from "./AccordionForms/PeopleTask/PeopleTask";
import { ProductTypesTask } from "./AccordionForms/ProductTypes/ProductTypesTask";
import { RatioTask } from "./AccordionForms/RatioTask/RatioTask";
import { ResourcesTask } from "./AccordionForms/Resources/ResourcesTask";
import {
    SlideOut,
    slideOutFormInitialData,
    slideOutFormSchema,
} from "./AccordionForms/SlideOut/SlideOut";
import {
    waitingDaysFormInitialData,
    waitingDaysFormSchema,
    WaitingDaysTask,
} from "./AccordionForms/WaitingDays/WaitingDays";

const taskFormAccordionExpandedStateKey = "taskFormAccordionExpandedState";

//////////////////////////////////////////////
///     FORM                               ///
//////////////////////////////////////////////

const taskValidationSchema = Yup.object().shape({
    ...nameFormSchema.fields,
    ...mainFormSchema.fields,
    ...dateFormSchema.fields,
    ...automaticOptimizationSchema.fields,
    ...waitingDaysFormSchema.fields,
    ...slideOutFormSchema.fields,
    ...advancedSchema.fields,
});

//////////////////////////////////////////////
///     TYPES                              ///
//////////////////////////////////////////////

type Type_Props_TaskForm = {
    onClose: () => void;
    data: Type_show_task;
    readonly: boolean;
};

export const TaskForm = ({
    onClose,
    data: task,
    readonly = false,
}: Type_Props_TaskForm) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerTasks",
    );
    const { formatMessageWithPartialKey: fmtActions } = useCoreIntl("Actions");

    ///////////////////////////////////////
    ///         States                  ///
    ///////////////////////////////////////
    const [expanded, setExpanded] = useState<string[]>(
        getLocalStorageItem(taskFormAccordionExpandedStateKey, true),
    );

    ///////////////////////////////////////
    ///         Queries                 ///
    ///////////////////////////////////////

    const { mutateAsync } = mutationUpdateTask();
    const { openPaper } = useContextualDrawer();

    ///////////////////////////////////////
    ///         Form                    ///
    ///////////////////////////////////////

    const form = useForm<Type_put_task>({
        ...useFormDefaultConfig,
        defaultValues: {
            ...nameFormInitialData,
            ...mainFormInitialData,
            ...dateFormInitialData,
            ...automaticOptimizationInitialData,
            ...waitingDaysFormInitialData,
            ...slideOutFormInitialData,
            ...advancedInitialData,
        },
        values: task
            ? formatterShowToPutTask(task as Type_show_task)
            : undefined,
        mode: "onBlur", // submit onBlur
        resolver: yupResolver<any>(taskValidationSchema),
    });

    const { sendEvent } = useChannel({
        eventHandler: ({ event, data }) => {
            if (event === `updateTask_${task.id}`) {
                const formattedData = formatterShowToPutTask(
                    data as Type_show_task,
                );
                form.reset({ ...form.getValues(), ...formattedData });
            }
        },
    });

    const onSubmit: SubmitHandler<Type_put_task> = async (values: any) => {
        console.log("====> onSubmit: should call API", values);
        await mutateAsync(values).then((data) => {
            sendEvent("updateTask", formatterShowTask(data.data.data));
        });
    };

    const debounceOnSubmit = useCallback(
        debounce((props) => onSubmit(props), 1000),
        [],
    );

    ///////////////////////////////////////
    ///         Task header color       ///
    ///////////////////////////////////////

    const taskColor = useWatch({
        name: "color",
        defaultValue: task?.color,
        control: form.control,
    });

    const withTradeColor = useWatch({
        name: "withTradeColor",
        defaultValue: task?.withTradeColor,
        control: form.control,
    });

    const subTrade = useWatch({
        name: "subTrade",
        defaultValue: task?.subTrade,
        control: form.control,
    });

    const taskType = useWatch({
        name: "type",
        control: form.control,
    });

    const headerBackgroundColor = useMemo(() => {
        const isWithTradeColorEnabled = shouldDisplay({
            name: "withTradeColor",
            taskType: taskType as number,
        });

        return withTradeColor && subTrade && isWithTradeColorEnabled
            ? subTrade.trade?.color
            : taskColor;
    }, [withTradeColor, subTrade, taskColor, taskType]);

    ///////////////////////////////////////
    ///         Accordions              ///
    ///////////////////////////////////////

    const taskAccordionTitle = (title: string) => (
        <Typography>{title}</Typography>
    );

    const accordionList: Type_accordionList[] = useMemo(
        () => [
            {
                key: "links",
                title: taskAccordionTitle(fmt("AccordionsTitle.Links")),
                children: <LinksTask taskId={task.id} />,
            },
            {
                key: "date",
                title: taskAccordionTitle(fmt("AccordionsTitle.Date")),
                children: <DateForm />,
            },
            {
                key: "automaticOptimization",
                title: taskAccordionTitle(
                    fmt("AccordionsTitle.AutomaticOptimization"),
                ),
                children: (
                    <AutomaticOptimizationForm sequenceId={task?.sequence.id} />
                ),
            },
            {
                key: "waitingDays",
                title: taskAccordionTitle(fmt("AccordionsTitle.WaitingDays")),
                children: <WaitingDaysTask />,
            },
            {
                key: "ratio",
                title: taskAccordionTitle(fmt("AccordionsTitle.Ratio")),
                children: <RatioTask />,
            },
            {
                key: "slideOut",
                title: taskAccordionTitle(fmt("AccordionsTitle.SlideOut")),
                children: <SlideOut />,
            },
            {
                key: "assignees",
                title: taskAccordionTitle(fmt("AccordionsTitle.Assignees")),
                children: <PeopleTask taskId={task.id} />,
                ...(!readonly && {
                    action: (
                        <TMC_Button
                            data-testid={`List-all-task-people-task-btn`}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                openPaper("peopleTask", {
                                    id: task.id,
                                });
                            }}
                            variant="text"
                            size="small"
                        >
                            {fmtActions("SeeAll")}
                        </TMC_Button>
                    ),
                }),
            },
            {
                key: "resources",
                title: taskAccordionTitle(fmt("AccordionsTitle.Resources")),
                children: <ResourcesTask taskId={task.id} />,
                ...(!readonly && {
                    action: (
                        <TMC_Button
                            data-testid={`List-all-task-resources-btn`}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                openPaper("resource", {
                                    id: task.id,
                                });
                            }}
                            variant="text"
                            size="small"
                        >
                            {fmtActions("SeeAll")}
                        </TMC_Button>
                    ),
                }),
            },
            {
                key: "materials",
                title: taskAccordionTitle(fmt("AccordionsTitle.Materials")),
                children: <ProductTypesTask taskId={task.id} />,
                ...(!readonly && {
                    action: (
                        <TMC_Button
                            data-testid={`List-all-task-materials-btn`}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                openPaper("productType", {
                                    id: task.id,
                                });
                            }}
                            variant="text"
                            size="small"
                        >
                            {fmtActions("SeeAll")}
                        </TMC_Button>
                    ),
                }),
            },
            {
                key: "advanced",
                title: taskAccordionTitle(fmt("AccordionsTitle.Advanced")),
                children: <Advanced />,
            },
        ],
        [task.id],
    );

    const handleAccordionChange =
        (panel: string) =>
        (event: React.SyntheticEvent, isExpanded: boolean) => {
            event.preventDefault();
            event.stopPropagation();
            setExpanded((prevExpanded) =>
                isExpanded
                    ? [...prevExpanded, panel]
                    : prevExpanded.filter((key) => key !== panel),
            );
        };

    useEffect(() => {
        setLocalStorageItem(taskFormAccordionExpandedStateKey, expanded);
    }, [expanded]);

    ///////////////////////////////////////
    ///         Everything else         ///
    ///////////////////////////////////////

    return (
        <FormProvider {...form}>
            <Form
                onBlur={form.handleSubmit(debounceOnSubmit, (props) =>
                    console.error("handleSubmit: invalid response", props),
                )}
                data-testid="TaskForm-form"
            >
                <Header
                    data-testid="DrawerHeader"
                    ownerState={{ borderBottom: true }}
                    sx={{
                        backgroundColor: headerBackgroundColor,
                        color: invertColor(headerBackgroundColor || "#FFF"),
                    }}
                >
                    <HeaderToolbar
                        onClose={onClose}
                        sequence={task?.sequence}
                        taskNumber={task?.number}
                        taskNotesCount={task?.notesCount}
                    />
                    <NameForm
                        sequenceId={task?.sequence.id}
                        id={task.id}
                        headerBackgroundColor={headerBackgroundColor}
                        readonly={readonly}
                    />
                </Header>
                <Content data-testid="TaskForm-Content">
                    <MainForm
                        sequenceId={task?.sequence.id}
                        readonly={readonly}
                    />
                    <Box>
                        {accordionList.map(
                            (item) =>
                                shouldDisplay({
                                    name: item.key,
                                    taskType: task?.type,
                                    type: "accordion",
                                }) && (
                                    <Accordion
                                        key={item.key}
                                        expanded={expanded.includes(item.key)}
                                        onChange={handleAccordionChange(
                                            item.key,
                                        )}
                                        action={item.action}
                                        title={item.title}
                                        data-testid={`accordion-${item.key}`}
                                    >
                                        {isValidElement(item.children)
                                            ? cloneElement(item.children, {
                                                  open: expanded.includes(
                                                      item.key,
                                                  ),
                                                  readonly,
                                              })
                                            : item.children}
                                    </Accordion>
                                ),
                        )}
                    </Box>
                    {/*<DevTool control={form.control} />*/}
                </Content>
            </Form>
        </FormProvider>
    );
};
