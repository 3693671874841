import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    remove,
    RemoveReturnType,
    Type_RequestConfig,
} from "src/api/fetch";
import { PeopleTasksPath, Url } from "src/api/paths";

import { Type_sch_post_peopleTask } from "./types";

export const indexPeopleTask = (
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(
        `${Url.SCHEDULING}${PeopleTasksPath.PEOPLE_TASKS}`,
        requestConfig,
    );
};

export const createPeopleTask = (
    data: Type_sch_post_peopleTask,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return post(
        data,
        `${Url.SCHEDULING}${PeopleTasksPath.PEOPLE_TASKS}`,
        requestConfig,
    );
};

export const removePeopleTask = (
    id: number,
    requestConfig: Type_RequestConfig,
): RemoveReturnType => {
    return remove(
        { id },
        `${Url.SCHEDULING}${PeopleTasksPath.PEOPLE_TASKS}/${id}`,
        requestConfig,
    );
};
