import React, { forwardRef } from "react";
import { Controller, useFormContext } from "react-hook-form";

import ChipSelectGeneric, {
    Type_Props_ChipSelect as Type_Props_ChipSelectGeneric,
} from "src/components/Components_Common/forms/ChipSelect/ChipSelect";

type Type_Props_ChipSelect = Type_Props_ChipSelectGeneric;

export const ChipSelect = forwardRef(
    ({ name, ...props }: Type_Props_ChipSelect, ref) => {
        const { formState, control } = useFormContext();

        return (
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <ChipSelectGeneric
                        {...props}
                        {...field}
                        inputRef={ref}
                        error={!!formState.errors[name]}
                    />
                )}
            />
        );
    },
);

ChipSelect.displayName = "ChipSelect";
