import {
    Box,
    debounce,
    InputAdornment,
    Stack,
    Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useCallback } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import {
    mutationUpdateTaskLink,
    useShowTaskLink,
} from "src/api/tms-scheduling/taskLinks";
import { formatterShowTaskLink } from "src/api/tms-scheduling/taskLinks/formatters";
import {
    LINK_DIRECTION,
    Type_show_taskLink,
} from "src/api/tms-scheduling/taskLinks/types";
import { TextField } from "src/components/Components_Common/forms/reactHookFormComponents/TextField/TextField";
import { FullSpinner } from "src/components/Components_Common/Spinner/FullSpinner";
import { AutocompleteAreas } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteAreas";
import { AutocompleteTasks } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteTasks";
import { SelectLinkType } from "src/components/Components_Teamoty/forms/select/SelectLinkType";
import { useFormDefaultConfig } from "src/configurations/app";
import { HeaderToolbar } from "src/drawers/links/HeaderToolbar";
import { useChannel } from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";

const Styled_Header = styled(Box)(({ theme }) => ({
    color: theme.palette.text.primary,
    padding: `${theme.spacing(2)} 0`,
    borderBottom: theme.border.default,
}));

const Styled_HeaderContent = styled(Box)(({ theme }) => ({
    padding: `0 ${theme.spacing(4)}`,
}));

type Type_Props_TaskAreaLinkForm = {
    onClose: () => void;
    id: number;
    type: LINK_DIRECTION;
};

export const TaskAreaLinkForm = ({
    onClose,
    id,
    type,
}: Type_Props_TaskAreaLinkForm) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerLinks",
    );
    const { formatMessageWithPartialKey: fmtLabels } = useCoreIntl("Labels");

    const { data, isFetching } = useShowTaskLink(id);
    const { mutateAsync } = mutationUpdateTaskLink();
    const { sendEvent } = useChannel({});

    const form = useForm<Type_show_taskLink>({
        ...useFormDefaultConfig,
        defaultValues: {
            linkType: 1,
            dayMode: 1,
            quantity: 0,
            waitingDay: 0,
            areaFrom: undefined,
            areaTo: undefined,
            taskFrom: undefined,
            taskTo: undefined,
        },
        values: data,
        mode: "onBlur", // submit onBlur
    });

    const onSubmit: SubmitHandler<any> = async (values: any) => {
        await mutateAsync(values).then((data) => {
            sendEvent("updateLink", formatterShowTaskLink(data.data.data));
        });
    };

    const debounceOnSubmit = useCallback(
        debounce((props) => onSubmit(props), 1000),
        [],
    );

    return (
        <FormProvider {...form}>
            <form
                onBlur={form.handleSubmit(debounceOnSubmit, (props) =>
                    console.error("handleSubmit: invalid response", props),
                )}
            >
                <Styled_Header>
                    <HeaderToolbar onClose={onClose} />
                    <Styled_HeaderContent>
                        <Typography>{fmt("Title")}</Typography>
                    </Styled_HeaderContent>
                </Styled_Header>

                {isFetching ? (
                    <FullSpinner />
                ) : (
                    <Box padding={4}>
                        <Stack spacing={2}>
                            <AutocompleteTasks
                                getOptionDisabled={(option) => {
                                    const hideId =
                                        type === LINK_DIRECTION.FROM
                                            ? data?.taskTo?.id
                                            : data?.taskFrom?.id;
                                    return option.id === hideId;
                                }}
                                data-testid="Form-TaskLink-autocomplete-task"
                                name={
                                    type === LINK_DIRECTION.FROM
                                        ? "taskFrom"
                                        : "taskTo"
                                }
                                label={fmtLabels("Task")}
                                multiple={false}
                            />

                            <SelectLinkType name="linkType" />

                            <Stack direction={"row"} spacing={2}>
                                <TextField
                                    name="quantity"
                                    label={fmt("Quantity")}
                                    type="number"
                                    data-testid="Form-TaskLink-quantity"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                u
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {fmt("Day")}
                                            </InputAdornment>
                                        ),
                                    }}
                                    name="waitingDay"
                                    label={fmt("WaitingDay")}
                                    type="number"
                                    data-testid="Form-TaskLink-waitingDay"
                                />
                            </Stack>
                            <Stack>
                                <AutocompleteAreas
                                    label={
                                        type === LINK_DIRECTION.FROM
                                            ? fmt("FromArea")
                                            : fmt("ToArea")
                                    }
                                    name={
                                        type === LINK_DIRECTION.FROM
                                            ? "areaFrom"
                                            : "areaTo"
                                    }
                                />
                            </Stack>
                        </Stack>
                    </Box>
                )}
            </form>
        </FormProvider>
    );
};
