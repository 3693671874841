import { Box, Button, Stack } from "@mui/material";
import { UseMutateAsyncFunction } from "react-query";

import { Type_sch_post_task } from "src/api/tms-scheduling/tasks/types";
import { Type_CountryCode } from "src/assets/translations";
import { Spinner } from "src/components/Components_Common";
import { useLanguage } from "src/contexts/languages";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_SequencePertDiagramEmpty = {
    sequenceId: number;
    createTask: UseMutateAsyncFunction<any, any, Type_sch_post_task, unknown>;
    isLoadingCreateTask: boolean;
};
export const SequencePertDiagramEmpty = ({
    sequenceId,
    createTask,
    isLoadingCreateTask,
}: Type_Props_SequencePertDiagramEmpty) => {
    const { languages } = useLanguage();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.PertDrawer",
    );

    const handleCreateFirstTask = async () => {
        const defaultValues: Type_sch_post_task = {
            names: {},
            sequence_id: sequenceId,
            taskCategory_id: null,
        };
        languages.forEach((language: Type_CountryCode): void => {
            defaultValues.names[language] = fmt(`DefaultTaskNames.${language}`);
        });
        await createTask(defaultValues);
    };

    return (
        <Box component="div" sx={{ flexGrow: 1 }}>
            <Stack
                sx={{
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Button
                    disabled={isLoadingCreateTask}
                    onClick={handleCreateFirstTask}
                    type="button"
                    variant="contained"
                    data-testid={"Planning-Pert-Button-CreateTask"}
                    endIcon={
                        isLoadingCreateTask && (
                            <Spinner size="small" style="button" />
                        )
                    }
                >
                    {fmt("CreateTask")}
                </Button>
            </Stack>
        </Box>
    );
};
