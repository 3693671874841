export enum KeysMouse {
    LEFT_CLICK = 0,
    RIGHT_CLICK = 1,
    MIDDLE_CLICK = 2,
}

export enum ShortKeys {
    LEFT_CLICK = "LEFT_CLICK",
    ALT_LEFT_CLICK = "ALT_LEFT_CLICK",

    RIGHT_CLICK = "RIGHT_CLICK",

    MIDDLE_CLICK = "MIDDLE_CLICK", // molette
}

export const isShortKey = (e: MouseEvent, key: ShortKeys): boolean => {
    switch (key) {
        case ShortKeys.ALT_LEFT_CLICK:
            return e.button === KeysMouse.LEFT_CLICK && e.altKey;
        case ShortKeys.LEFT_CLICK:
            return e.button === KeysMouse.LEFT_CLICK;
        case ShortKeys.MIDDLE_CLICK:
            return e.button === KeysMouse.MIDDLE_CLICK;
        case ShortKeys.RIGHT_CLICK:
            return e.button === KeysMouse.RIGHT_CLICK;
        default:
            return false;
    }
};
