import {
    Type_index_subProject,
    Type_post_subProject,
    Type_prj_index_subProject,
    Type_prj_post_subProject,
    Type_prj_put_subProject,
    Type_prj_selectList_subproject,
    Type_prj_show_subProject,
    Type_prj_show_subProjectWithCurrentWorkspace,
    Type_put_default_subProject,
    Type_selectList_subProject,
    Type_show_subProject,
    Type_show_subProjectWithCurrentWorkspace,
} from "src/api/tms-projects/subProjects/types";
import { displayFormattedDate } from "src/utils/date";

export const formatterIndexSubProjects = (
    subProjects: Type_prj_index_subProject[],
): Type_index_subProject[] => {
    if (!subProjects) return [];

    return subProjects.map((subProject: Type_prj_index_subProject) =>
        formatterIndexSubProject(subProject),
    );
};

export const formatterIndexSubProject = (
    subProject: Type_prj_index_subProject,
): Type_index_subProject => {
    return {
        id: subProject.id,
        name: subProject.name,
        enabled: subProject.enabled,
        dateCreated: displayFormattedDate(subProject.dateCreated),
    };
};

export const formatterShowSubProject = (
    subProject: Type_prj_show_subProject,
): Type_show_subProject => {
    return {
        id: subProject.id,
        name: subProject.name,
        optimizedFlow: subProject.optimizedFlow,
        minDuration: subProject.minDuration,
        timeModel: subProject.timeModel,
        versionLimit: subProject.versionLimit,
        workingDays: subProject.workingDays,
    };
};

export const formatterUpdateSubProject = (
    subProject: Type_show_subProject,
): Type_prj_put_subProject => {
    return {
        id: subProject.id,
        name: subProject.name,
    };
};

export const formatterCreateSubProject = (
    subProject: Type_post_subProject,
): Type_prj_post_subProject => {
    return {
        name: subProject.name,
    };
};

export const formatterUpdateDefaultSettings = (
    subProject: Type_put_default_subProject,
): Type_prj_put_subProject => {
    return {
        id: subProject.id,
        optimizedFlow: subProject.optimizedFlow,
        minDuration: subProject.minDuration,
        timeModel_id: subProject.timeModel.id,
        versionLimit: subProject.versionLimit,
        workingDays: subProject.workingDays,
    };
};

export const formatterSelectListSubProjects = (
    subProjects: Type_prj_selectList_subproject[],
): Type_selectList_subProject[] => {
    return subProjects;
};

export const formatterShowSubProjectWithCurrentWorkspace = (
    subProject: Type_prj_show_subProjectWithCurrentWorkspace,
): Type_show_subProjectWithCurrentWorkspace => {
    return subProject;
};
