import Konva from "konva";
import React, { useState } from "react";
import { Group, Rect } from "react-konva";
import { Portal } from "react-konva-utils";

import {
    colorPlanningDrawerResizerOver,
    heightPlanningDrawerTitle,
    marginPlanningElements,
    sizePlanningResizer,
} from "src/components/Components_Teamoty/Planning/Planning.const";
import { changeCursor } from "src/components/Components_Teamoty/Planning/tools/changeCursor";

import { Type_Props_PlanningDrawerResizer } from "./PlanningDrawerTitle.type";

export const PlanningPlanningDrawerResizerX = ({
    x,
    y,
    width,
    height,
    onResize,
}: Type_Props_PlanningDrawerResizer) => {
    const [drag, setDrag] = useState<boolean>(false);

    const xx: number = width / 2 - sizePlanningResizer / 2;
    const yy: number = y + 0.5;

    return (
        <Portal selector=".top" enabled={true}>
            <Group
                x={x}
                y={yy}
                height={height}
                width={sizePlanningResizer}
                draggable={true}
                onMouseOver={(e: Konva.KonvaEventObject<MouseEvent>): void => {
                    e.cancelBubble = true;
                    if (drag) return;
                    changeCursor(e, "row-resize");
                }}
                onMouseOut={(e: Konva.KonvaEventObject<MouseEvent>): void => {
                    e.cancelBubble = true;
                    if (drag) return;
                    changeCursor(e, "default");
                }}
                onDragStart={(e: Konva.KonvaEventObject<DragEvent>) => {
                    setDrag(true);
                    changeCursor(e, "row-resize");
                }}
                onDragMove={(e: Konva.KonvaEventObject<DragEvent>): void => {
                    e.cancelBubble = true;
                    onResize(
                        e.target.y() +
                            heightPlanningDrawerTitle +
                            marginPlanningElements,
                    );
                    e.target.x(x);
                    e.target.y(yy);
                }}
                onDragEnd={(e: Konva.KonvaEventObject<DragEvent>): void => {
                    setDrag(false);
                    changeCursor(e, "default");
                }}
            >
                <Rect
                    height={marginPlanningElements - 2}
                    width={width}
                    hitStrokeWidth={marginPlanningElements}
                />

                <Rect
                    x={xx}
                    height={marginPlanningElements - 2}
                    width={sizePlanningResizer}
                    hitStrokeWidth={marginPlanningElements}
                    fill={colorPlanningDrawerResizerOver}
                />
            </Group>
        </Portal>
    );
};
