import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Stack, styled, Typography } from "@mui/material";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";

import { TMC_Button } from "src/components";
import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useProject } from "src/contexts/project";
import { HeaderToolbar } from "src/drawers/links/HeaderToolbar";
import { useChannel } from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { Header } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

import { PlanningFiltersFormAccordionList } from "./PlanningFiltersFormAccordionList";

export type Type_PlanningFilters = {
    period: any;
    trades: string;
    companies: string;
    typesTasks: any;
    status: any;
};

const planningFiltersValidationSchema = Yup.object().shape({
    period: Yup.mixed().nullable(),
    trades: Yup.string().nullable(),
    companies: Yup.string().nullable(),
    typesTasks: Yup.array().nullable(),
    status: Yup.array().nullable(),
});

type Type_Props_PlanningFiltersForm = {
    onClose: () => void;
};

const Styled_Form = styled("form")(() => ({
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
}));

export const PlanningFiltersForm = ({
    onClose,
}: Type_Props_PlanningFiltersForm) => {
    const { sendEvent } = useChannel({});
    const { project, subProject } = useProject();
    const { formatMessageWithPartialKey: fmtAction } = useCoreIntl("Actions");

    // i18n
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.PlanningFilters",
    );

    // React hook form
    const form = useForm<any>({
        defaultValues: {
            period: null,
            trades: "",
            companies: "",
            typesTasks: [],
            status: [],
        },
        mode: "onSubmit",
        resolver: yupResolver<any>(planningFiltersValidationSchema),
    });

    useEffect(() => {
        if (project && subProject) {
            form.reset();
        }
    }, [project, subProject]);

    const handleSubmit = async (
        values: Type_PlanningFilters,
        e?: React.BaseSyntheticEvent,
    ) => {
        e?.preventDefault();
        sendEvent("updatePlanningFilters", values);
    };

    return (
        <FormProvider {...form}>
            <Styled_Form onSubmit={form.handleSubmit(handleSubmit)}>
                <Header>
                    <HeaderToolbar onClose={onClose} />
                    <Stack
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        px={2}
                    >
                        <Typography variant="h3">{fmt("Title")}</Typography>
                        <IconButton
                            onClick={() => form.reset()}
                            aria-label="filter-btn"
                            color={"primary"}
                            data-testid={`planning-filters-clean-btn`}
                        >
                            <Icon
                                sx={{ mr: 1 }}
                                fontSize="small"
                                icon="arrow-rotate-left"
                                variant="solid"
                            />
                            <Typography variant="body2Medium">
                                {fmt("ClearAll")}
                            </Typography>
                        </IconButton>
                    </Stack>
                </Header>
                <Stack
                    direction="column"
                    justifyContent="space-between"
                    sx={{ height: "100%", flexGrow: 1 }}
                >
                    <PlanningFiltersFormAccordionList />

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            mt: "auto",
                            p: 2,
                        }}
                    >
                        <TMC_Button
                            data-testid="Form-PlanningFilters-Submit-Btn"
                            color="primary"
                            type="submit"
                            disabled={false}
                        >
                            {fmtAction(`Apply`)}
                        </TMC_Button>
                    </Box>
                </Stack>
            </Styled_Form>
        </FormProvider>
    );
};
