export enum orientation {
    LANDSCAPE = "landscape",
    PORTRAIT = "portrait",
}

/**
 * Will be moved to fonction in Cimba-Digital-Construction-Solution/utils
 */
export const defaultPrintSettings: Type_printSettings = {
    paperFormat: "A3",
    orientation: orientation.LANDSCAPE,
    timelineType: "week",
};

export type Type_printSettings = {
    paperFormat: string;
    orientation: orientation;
    timelineType: string;
};

export type Type_prt_post_print = {
    size: string;
    layout: orientation;
    timelineType: string;
    withWeekend: boolean;
    withDaysOff: boolean;
};

export type Type_post_print = {
    paperFormat: string;
    orientation: orientation;
    timelineType: string;
    withWeekend: boolean;
    withDaysOff: boolean;
};
