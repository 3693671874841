import { FormikValues } from "formik";

import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    PutReturnType,
    remove,
    RemoveReturnType,
    Type_RequestConfig,
} from "src/api/fetch";
import { Url, Version } from "src/api/paths";

export const indexVersions = (
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(`${Url.PROJECTS}${Version.VERSIONS}`, requestConfig);
};

export const selectListVersions = (
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(`${Url.PROJECTS}${Version.SELECT_LIST}`, requestConfig);
};

export const createVersion = (
    data: FormikValues,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return post(data, `${Url.PROJECTS}${Version.VERSIONS}`, requestConfig);
};

export const showVersion = (
    id: number,
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(`${Url.PROJECTS}${Version.VERSIONS}/${id}`, requestConfig);
};

export const updateVersion = (
    data: any,
    requestConfig: Type_RequestConfig,
): PutReturnType => {
    return put(
        data,
        `${Url.PROJECTS}${Version.VERSIONS}/${data.id}`,
        requestConfig,
    );
};

export const deleteVersion = (
    id: number,
    requestConfig: Type_RequestConfig,
): RemoveReturnType => {
    return remove(
        { id },
        `${Url.PROJECTS}${Version.VERSIONS}/${id}`,
        requestConfig,
    );
};
