import React from "react";

import { TMC_TextField } from "src/components/Components_Common/_MuiComponentsVariants";

import { InputTextPropsType } from "./InputText.container";

export type InputTextType = {
    type: "inputText";
    name: string;
    required?: boolean;
    label?: {
        label?: string;
        languageLabel?: string;
    };
    unit?: {
        label?: string;
        languageLabel?: string;
    };
    placeholder?: {
        label?: string;
        languageLabel?: string;
    };
    options: any;
};

export const InputTextComponent = ({
    value = "",
    name,
    label,
    onChange,
}: InputTextPropsType) => {
    return (
        <TMC_TextField
            value={value}
            onChange={(e) => onChange(name, e.target.value)}
            variant="outlined"
            label={label}
        />
    );
};
