import { Box, Grid, Tooltip } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import * as Yup from "yup";

import { Type_selectList_subTrade } from "src/api/tms-projects/subTrades/types";
import { Input } from "src/components/Components_Common/forms/reactHookFormComponents/Input/Input";
import { Switch } from "src/components/Components_Common/forms/reactHookFormComponents/Switch/Switch";
import { TextField } from "src/components/Components_Common/forms/reactHookFormComponents/TextField/TextField";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { Type_ReadonlyComponent } from "src/components/Components_Common/types";
import { AutocompleteFreeSoloTags } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteFreeSoloTags";
import { AutocompleteSubTrades } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteSubTrades";
import { CompanyTeamBadge } from "src/components/Components_Teamoty/badges/CompanyTeamBadge";
import { SubTradeTeamBadge } from "src/components/Components_Teamoty/badges/SubTradeTeamBadge";
import { SelectBreakLevel } from "src/components/Components_Teamoty/forms/select/SelectBreakLevel";
import { SelectSubTradeCompany } from "src/components/Components_Teamoty/forms/select/SelectSubTradeCompany";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { shouldDisplay } from "src/forms/tasks/utils";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import {
    Row,
    RowIcon,
    Section,
} from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

//////////////////////////////////
// Form                         //
//////////////////////////////////

export type Type_mainForm = {
    subTrade?: Type_selectList_subTrade | null;
    companyId?: number | null;
    code?: string;
    duration?: number | null;
    areaQuantity?: number;
    areaRhythmBreak?: boolean;
    areaRhythmLevel?: number;
    workLevel?: number;
    team?: number | null;
    workforce?: number | null;
    tags: [];
};

export const mainFormInitialData = {
    subTrade: null,
    companyId: null,
    code: "",
    duration: 0,
    areaQuantity: 1,
    areaRhythmBreak: false,
    areaRhythmLevel: undefined,
    workLevel: undefined,
    team: 0,
    workforce: 0,
    tags: [],
};

export const mainFormSchema = Yup.object().shape({
    code: Yup.string().nullable(),
    duration: Yup.number().typeError(FORM_ERR_FMT.MUST_BE_NUMBER).nullable(),
    subTrade: Yup.object()
        .shape({
            id: Yup.number(),
        })
        .nullable(),
    areaQuantity: Yup.number()
        .typeError(FORM_ERR_FMT.MUST_BE_NUMBER)
        .nullable(),
    areaRhythmBreak: Yup.boolean().nullable(),
    areaRhythmLevel: Yup.number().nullable(),
    workLevel: Yup.number().typeError(FORM_ERR_FMT.MUST_BE_NUMBER).nullable(),
    team: Yup.number().typeError(FORM_ERR_FMT.MUST_BE_NUMBER).nullable(),
    workforce: Yup.number().typeError(FORM_ERR_FMT.MUST_BE_NUMBER).nullable(),
    tags: Yup.array().nullable(),
});

type Type_Props_MainForm = Type_ReadonlyComponent & {
    sequenceId?: number;
};

export const MainForm = ({
    sequenceId,
    readonly = false,
}: Type_Props_MainForm) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerTasks.Main",
    );

    const { control, setValue } = useFormContext();

    const [subTradeChange, setSubTradeChange] = useState(false);

    const taskType: number | null = useWatch({
        control,
        name: "type",
    });

    const subTrade: Type_selectList_subTrade | null = useWatch({
        control,
        name: "subTrade",
    });
    const companyId: number | null = useWatch({
        control,
        name: "companyId",
    });

    const areaQuantity: number | null = useWatch({
        control,
        name: "areaQuantity",
    });
    const areaRhythmBreak: number | null = useWatch({
        control,
        name: "areaRhythmBreak",
    });

    useEffect(() => {
        if (subTrade && !subTradeChange) {
            // on subtrade change
            setValue("companyId", null);
            setSubTradeChange(true);
        } else if (!subTrade && subTradeChange) {
            // On delete subtrade
            setValue("companyId", null);
            setSubTradeChange(true);
        }
    }, [subTrade]);

    return (
        <Section>
            <Box display="flex" flexDirection="column" gap={2}>
                {shouldDisplay({
                    name: "subTrade",
                    taskType,
                }) && (
                    <Row>
                        <RowIcon>
                            <Tooltip
                                title={fmt("Tooltip.Trade")}
                                placement={"left"}
                            >
                                <Icon
                                    variant="light"
                                    icon="helmet-safety"
                                    fontSize="small"
                                />
                            </Tooltip>
                        </RowIcon>
                        <AutocompleteSubTrades
                            name="subTrade"
                            data-testid="Form-Task-subTrade"
                            readonly={readonly}
                        />
                        <SubTradeTeamBadge subTradeId={subTrade?.id} />
                    </Row>
                )}
                {shouldDisplay({
                    name: "companyId",
                    taskType,
                }) && (
                    <Row>
                        <RowIcon>
                            <Tooltip
                                title={fmt("Tooltip.Company")}
                                placement={"left"}
                            >
                                <Icon
                                    variant="light"
                                    icon="building"
                                    fontSize="small"
                                />
                            </Tooltip>
                        </RowIcon>
                        <SelectSubTradeCompany
                            name="companyId"
                            subTradeId={subTrade?.id}
                            size={"small"}
                            variant="standard"
                            autoSelectIfOneElement={subTradeChange}
                            data-testid="Form-Task-company"
                            readonly={readonly}
                        />
                        <CompanyTeamBadge companyId={companyId} />
                    </Row>
                )}
                <Row>
                    <RowIcon>
                        <Tooltip title={fmt("Tooltip.Code")} placement={"left"}>
                            <Icon
                                variant="light"
                                icon="barcode"
                                fontSize="small"
                            />
                        </Tooltip>
                    </RowIcon>
                    <Input
                        name="code"
                        variant="standard"
                        size="small"
                        fullWidth
                        placeholder={fmt("CodePlaceholder")}
                        data-testid="Form-Task-code"
                        readonly={readonly}
                    />
                </Row>
                <Row>
                    <RowIcon>
                        <Tooltip title={fmt("Tooltip.Tag")} placement={"left"}>
                            <Icon
                                variant="light"
                                icon="hashtag"
                                fontSize="small"
                            />
                        </Tooltip>
                    </RowIcon>
                    <AutocompleteFreeSoloTags
                        name="tags"
                        renderInputProps={{
                            placeholder: fmt("AddTag"),
                            variant: "standard",
                        }}
                        readonly={readonly}
                    />
                </Row>
            </Box>
            <Grid container spacing={2}>
                {shouldDisplay({
                    name: "duration",
                    taskType,
                }) && (
                    <>
                        <Grid item xs={6}>
                            <TextField
                                name="duration"
                                label={fmt("Duration")}
                                type="number"
                                inputProps={{ step: "0.1", min: "0.01" }}
                                data-testid="Form-Task-duration"
                                readonly={readonly}
                            />
                        </Grid>
                        <Grid item xs={6}></Grid>
                    </>
                )}

                {shouldDisplay({
                    name: "areaQuantity",
                    taskType,
                }) && (
                    <Grid item xs={6}>
                        <TextField
                            name="areaQuantity"
                            label={fmt("Quantity")}
                            type="number"
                            inputProps={{ min: "1" }}
                            data-testid="Form-Task-areaQuantity"
                            readonly={readonly}
                        />
                    </Grid>
                )}

                {shouldDisplay({
                    name: "workLevel",
                    taskType,
                }) && (
                    <Grid item xs={6}>
                        <SelectBreakLevel
                            name="workLevel"
                            label={fmt("QuantityUnit")}
                            size="small"
                            sequenceId={sequenceId}
                            withCurrent
                            readonly={readonly}
                        />
                    </Grid>
                )}

                {shouldDisplay({
                    name: "areaRhythmBreak",
                    taskType,
                }) &&
                    areaQuantity &&
                    areaQuantity > 1 && (
                        <Grid item xs={12}>
                            <Switch
                                name="areaRhythmBreak"
                                label={fmt("BreakRhythm")}
                                labelPlacement="end"
                                data-testid="Form-Task-areaRhythmBreak"
                                readonly={readonly}
                            />
                        </Grid>
                    )}

                {shouldDisplay({
                    name: "areaRhythmLevel",
                    taskType,
                }) &&
                    areaRhythmBreak && (
                        <Grid item xs={12}>
                            <SelectBreakLevel
                                name="areaRhythmLevel"
                                label={fmt("BreakLevel")}
                                size="small"
                                data-testid="Form-Task-areaRhythmLevel"
                                sequenceId={sequenceId}
                                autoSelectFirstItem={true}
                                readonly={readonly}
                            />
                        </Grid>
                    )}

                {shouldDisplay({
                    name: "team",
                    taskType,
                }) && (
                    <Grid item xs={6}>
                        <TextField
                            name="team"
                            label={fmt("Team")}
                            type="number"
                            inputProps={{ min: "0" }}
                            data-testid="Form-Task-team"
                            readonly={readonly}
                        />
                    </Grid>
                )}
                {shouldDisplay({
                    name: "workforce",
                    taskType,
                }) && (
                    <Grid item xs={6}>
                        <TextField
                            name="workforce"
                            label={fmt("Workforce")}
                            type="number"
                            inputProps={{ step: "0.1", min: "0" }}
                            data-testid="Form-Task-workforce"
                            readonly={readonly}
                        />
                    </Grid>
                )}
            </Grid>
        </Section>
    );
};
