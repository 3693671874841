import { Box, Dialog, DialogContent, DialogProps } from "@mui/material";
import React, { useEffect, useState } from "react";

import {
    mutationUpdateArea,
    useIndexAreasSelection,
} from "src/api/tms-projects/areas";
import { Type_index_area } from "src/api/tms-projects/areas/types";
import { Spinner, TMC_Button } from "src/components/Components_Common";
import { DialogActions } from "src/components/Components_Common/Dialog/DialogActions";
import { TMC_DialogTitle } from "src/components/Components_Common/Dialog/TMC_DialogTitle";
import { AreasTreeView } from "src/components/Components_Teamoty/trees/AreasTreeView";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_DrawingAreaSelection = Omit<DialogProps, "children"> & {
    open: boolean;
    handleClose: (hasChanged: boolean) => void;
    drawingId: number;
    selectedAreas: string[];
};

export const DrawingAreaSelection = ({
    open,
    handleClose,
    drawingId,
    selectedAreas,
    ...props
}: Type_Props_DrawingAreaSelection) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Dialog.Drawing.AreaSelection",
    );
    const { formatMessageWithPartialKey: fmtCta } = useCoreIntl("Actions");
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { add } = useToast();

    const [areas, setAreas] = useState<Type_index_area[]>([]);
    const [checkedAreas, setCheckedAreas] = useState<string[]>([]);

    useEffect(() => {
        setCheckedAreas(selectedAreas);
    }, [selectedAreas]);

    const { isFetching } =
        useIndexAreasSelection((areas: any[]) => {
            setAreas(areas);
        }, {}) || {};

    const { mutateAsync: mutateAsyncArea } = mutationUpdateArea();

    const handleSubmit = async () => {
        const areasRemoved = selectedAreas.filter(
            (element) => !checkedAreas.includes(element),
        );
        const areasAdded = checkedAreas.filter(
            (element) => !selectedAreas.includes(element),
        );

        const removeMutations = areasRemoved.map((areaId) =>
            mutateAsyncArea({ id: Number(areaId), drawing: undefined }),
        );
        const addMutations = areasAdded.map((areaId) =>
            mutateAsyncArea({
                id: Number(areaId),
                drawing: { id: drawingId },
                sortOrder: 1,
            }),
        );

        const hasChanged: boolean =
            areasRemoved.length > 0 || areasAdded.length > 0;

        try {
            if (hasChanged) {
                await Promise.all([...removeMutations, ...addMutations]);
                add({
                    type: "success",
                    duration: 4000,
                    description: fmt("ToastSuccess"),
                });
            }
            handleClose(hasChanged);
        } catch (error) {
            console.error("An error has occured: ", error);
            add({
                type: "warning",
                duration: 4000,
                description: fmtErr("GenericError"),
            });
        }
    };

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            {...props}
        >
            <TMC_DialogTitle handleClose={() => handleClose(false)}>
                {fmt("Title")}
            </TMC_DialogTitle>
            <DialogContent>
                {isFetching && <Spinner />}
                {!isFetching && areas.length > 0 && (
                    <AreasTreeView
                        areas={areas}
                        selectedAreas={checkedAreas}
                        setSelectedAreas={setCheckedAreas}
                        drawingId={drawingId}
                        outlined
                    />
                )}
                {!isFetching && areas.length === 0 && (
                    <Box>{fmt("NoAreasAvailable")}</Box>
                )}
            </DialogContent>
            <DialogActions>
                <TMC_Button color="primary" onClick={handleSubmit}>
                    {fmtCta("Save")}
                </TMC_Button>
            </DialogActions>
        </Dialog>
    );
};
