import {
    useMutation,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from "react-query";
import { useParams } from "react-router-dom";

import { Type_Filter_Projects } from "src/api/filters";
import { ProjectPath, Url } from "src/api/paths";
import { getImageQuery } from "src/api/storage";
import { ProjectKeys } from "src/api/tms-commons/keys";
import {
    formattedProjectCreate,
    formattedUpdateProject,
    formatterIndexProjects,
    formatterShowProject,
} from "src/api/tms-commons/projects/formatters";
import {
    changeProjectStatus,
    createProject,
    getProjectBySlug,
    indexProjects,
    removeProject,
    showProject,
    updateProject,
} from "src/api/tms-commons/projects/services";
import {
    Type_index_project,
    Type_put_project,
    Type_show_project,
} from "src/api/tms-commons/projects/types";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const mutationCreateProject = (_callback: any) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (project: Type_put_project) =>
            createProject(formattedProjectCreate(project)),
        onSuccess: async () => {
            await queryClient.invalidateQueries(ProjectKeys.INDEX);
        },
        onError: (err: any) => {
            return err;
        },
    });
};

export const useIndexProjects = (
    callback: any,
    filters: Type_Filter_Projects = {},
) => {
    return useQuery({
        queryKey: [ProjectKeys.INDEX, ...Object.entries(filters)],
        queryFn: async () => await indexProjects(filters),
        refetchOnWindowFocus: false,
        onSuccess: (data): void => {
            callback(formatterIndexProjects(data.data.data));
        },
    });
};

export const mutationUpdateProject = () => {
    const { addSuccess, addWarning } = useToast();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Projects");
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const queryClient = useQueryClient();
    const { slug } = useParams();

    return useMutation({
        mutationFn: (projectToUpdate: any) =>
            updateProject(formattedUpdateProject(projectToUpdate)),
        onSuccess: async () => {
            await queryClient.invalidateQueries([ProjectKeys.SLUG, slug]);

            addSuccess({
                description: fmt("ToastSuccessUpdate", {}),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationHandleProjectStatus = (
    callback: any,
    projects: Type_index_project[],
) => {
    return useMutation({
        mutationFn: (newStatus: Pick<Type_index_project, "enabled" | "id">) => {
            return changeProjectStatus(
                {
                    enabled: newStatus.enabled,
                },
                newStatus.id as number,
            );
        },
        onSuccess: (resp: any) => {
            const newProjects: Type_index_project[] = [...projects];
            const index: number = projects
                .map((project: Type_index_project) => project.id)
                .indexOf(resp.data.data.id);
            newProjects[index].enabled = resp.data?.data?.enabled;
            newProjects[index].status = resp.data?.data?.status;
            callback(newProjects);
        },
    });
};

export const mutationHandleProjectArchived = (
    callback: any,
    projects: Type_index_project[],
) => {
    return useMutation({
        mutationFn: (
            newStatus: Pick<Type_index_project, "archived" | "id">,
        ) => {
            return changeProjectStatus(
                {
                    archived: newStatus.archived,
                },
                newStatus.id as number,
            );
        },
        onSuccess: (resp: any) => {
            const newProjects: Type_index_project[] = [...projects];
            const index: number = projects
                .map((project: Type_index_project) => project.id)
                .indexOf(resp.data.data.id);
            newProjects[index].archived = resp.data?.data?.archived;
            newProjects[index].status = resp.data?.data?.status;
            callback(newProjects);
        },
    });
};

export const mutationRemoveProject = () => {
    return useMutation({
        mutationFn: (id: number) => removeProject(id),
    });
};

export const useProjectCover = (
    project: Type_show_project | null,
): UseQueryResult => {
    const cachedName = project?.id ? `project_cover_${project.id}` : "";

    return getImageQuery(
        project?.id as number,
        Url.COMMONS,
        ProjectPath.PROJECTS,
        "cover",
        cachedName,
        !!project,
    );
};

export const useShowProject = (projectId?: number) => {
    return useQuery(
        [ProjectKeys.SHOW, projectId],
        () => showProject(projectId as number),
        {
            enabled: !!projectId,
            refetchOnWindowFocus: false,
            select: (data) => {
                if (!data?.success || !data?.data?.data) {
                    throw new Error("Wrong format data: useShowProjectForEdit");
                }

                return formatterShowProject(data.data.data);
            },
            onError: (err) => {
                return err;
            },
        },
    );
};

export const useShowProjectBySlug = (slug?: string) => {
    return useQuery({
        queryKey: [ProjectKeys.SLUG, slug?.toLocaleLowerCase() || ""],
        queryFn: () => getProjectBySlug(slug?.toLocaleLowerCase() || ""),
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Error format data: useShowProjectBySlug");
            }
            return formatterShowProject(data.data.data);
        },
        enabled: !!slug && slug.length > 0,
    });
};
