import { Stack } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { mutationPrint } from "src/api/tms-print/print";
import { formatterOrientationToRadioItem } from "src/api/tms-print/print/formatters";
import {
    defaultPrintSettings,
    orientation,
    Type_post_print,
} from "src/api/tms-print/print/types";
import { TMC_Button } from "src/components";
import { RadioGroup } from "src/components/Components_Common/forms/reactHookFormComponents/RadioGroup/RadioGroup";
import { Switch } from "src/components/Components_Common/forms/reactHookFormComponents/Switch/Switch";
import { SelectPaperFormat } from "src/components/Components_Teamoty/forms/select/SelectPaperFormat";
import { SelectPrintTimelineType } from "src/components/Components_Teamoty/forms/select/SelectPrintTimelineType";
import { useFormDefaultConfig } from "src/configurations/app";
import { PrintSettingsDialog } from "src/dialogs/print/PrintSettingsDialog";

export const PrintDemo = () => {
    const { mutate } = mutationPrint();

    const form = useForm<Type_post_print>({
        ...useFormDefaultConfig,
        defaultValues: {
            ...defaultPrintSettings,
            withDaysOff: true,
            withWeekend: true,
        },
    });

    const handleSubmit = (
        values: Type_post_print,
        e?: React.BaseSyntheticEvent,
    ) => {
        e?.preventDefault();
        mutate({
            ...values,
        });
    };

    // FullScreen Dialog

    const [open, setOpen] = useState<boolean>(false);

    return (
        <Stack
            direction="row"
            spacing={10}
            alignItems="center"
            justifyContent="space-around"
        >
            <Stack
                gap={4}
                direction="column"
                sx={{ width: 200, margin: "auto" }}
            >
                <FormProvider {...form}>
                    <form
                        onSubmit={form.handleSubmit(handleSubmit)}
                        id={"roles"}
                    >
                        <Stack
                            gap={2}
                            direction="column"
                            sx={{ width: 200, margin: "auto" }}
                        >
                            <SelectPaperFormat name={"paperFormat"} />
                            <RadioGroup
                                name={"orientation"}
                                options={formatterOrientationToRadioItem(
                                    Object.values(orientation),
                                )}
                            />
                            <SelectPrintTimelineType name={"timelineType"} />
                            <Switch
                                name="withWeekend"
                                label={"WithWeekend"}
                                data-testid="MetadataUser-DrawerContent-filterable"
                            />
                            <Switch
                                name="withDaysOff"
                                label={"WithDaysOff"}
                                data-testid="MetadataUser-DrawerContent-filterable"
                            />
                            <TMC_Button type="submit">Call Print</TMC_Button>
                        </Stack>
                    </form>
                </FormProvider>
            </Stack>
            <TMC_Button onClick={() => setOpen((prev) => !prev)}>
                Open Dialog
            </TMC_Button>
            <PrintSettingsDialog openDialog={open} setToggleDialog={setOpen} />
        </Stack>
    );
};
