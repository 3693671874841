import * as React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { ResourceTypeKeys } from "src/api/tms-projects/keys";
import {
    formatterIndexResourceType,
    formatterIndexResourceTypes,
    formatterPostResourceType,
    formatterSelectListResourceTypes,
    formatterShowResourceType,
} from "src/api/tms-projects/resourceTypes/formatters";
import {
    createResourceType,
    deleteResourceType,
    indexResourceTypes,
    selectListResourceTypes,
    showResourceType,
    statusResourceType,
    updateResourceType,
} from "src/api/tms-projects/resourceTypes/services";
import {
    Type_post_resourceType,
    Type_prj_put_resourceType,
} from "src/api/tms-projects/resourceTypes/types";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const useIndexResourceTypes = () => {
    const { requestConfig } = useProject();
    return useQuery({
        queryKey: [ResourceTypeKeys.INDEX, requestConfig],
        queryFn: () => indexResourceTypes(requestConfig),
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Error format data: useIndexResourceTypes");
            }

            return formatterIndexResourceTypes(data.data.data);
        },
        enabled: !!requestConfig.projectId,
    });
};

export const mutationCreateResourceType = () => {
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.ResourceType",
    );
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (data: Type_post_resourceType) => {
            return createResourceType(
                formatterPostResourceType(data),
                requestConfig,
            );
        },
        onSuccess: async (data: any) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Error format data: mutationCreateResourceType",
                );
            }

            const formattedData = formatterIndexResourceType(data.data.data);

            addSuccess({
                description: fmt("ToastSuccess", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        resourceType: formattedData.name,
                    },
                }),
            });

            await queryClient.invalidateQueries([ResourceTypeKeys.INDEX]);
        },
        onError: (err: any): void => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationStatusResourceType = () => {
    const { requestConfig } = useProject();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (newStatus: Type_prj_put_resourceType) => {
            return statusResourceType(
                {
                    id: newStatus.id,
                    enabled: newStatus.enabled,
                },
                requestConfig,
            );
        },
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Error format data: mutationStatusResourceType",
                );
            }
            await queryClient.invalidateQueries([ResourceTypeKeys.INDEX]);
        },
    });
};

export const mutationUpdateResourceType = () => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.ResourceType",
    );
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { requestConfig } = useProject();
    const { addSuccess, addWarning } = useToast();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (resourceTypeToUpdate: Type_prj_put_resourceType) => {
            return updateResourceType(resourceTypeToUpdate, requestConfig);
        },
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Error format data: mutationUpdateResourceType",
                );
            }

            const formattedData = formatterIndexResourceType(data.data.data);

            addSuccess({
                description: fmt("ToastSuccess", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        resourceType: formattedData.name,
                    },
                }),
            });

            await queryClient.invalidateQueries([ResourceTypeKeys.INDEX]);
        },
        onError: (err: any): void => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const useShowResourceType = (resourceTypeIdToUpdate: number) => {
    const { requestConfig } = useProject();
    return useQuery(
        [ResourceTypeKeys.SHOW, resourceTypeIdToUpdate, requestConfig],
        () => showResourceType(resourceTypeIdToUpdate, requestConfig),
        {
            enabled: !!resourceTypeIdToUpdate && !!requestConfig.projectId,
            select: (data: any) => {
                if (!data?.success || !data?.data?.data) {
                    throw new Error("Error format data: useIndexResourceTypes");
                }

                return formatterShowResourceType(data.data.data);
            },
            refetchOnWindowFocus: false,
        },
    );
};

export const useSelectListResourceTypes = (enabled: boolean = true) => {
    const { requestConfig: req } = useProject();
    return useQuery({
        queryKey: [ResourceTypeKeys.SELECT_LIST, req],
        queryFn: () => selectListResourceTypes(req),
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Error format data: useSelectListResourceTypes",
                );
            }

            return formatterSelectListResourceTypes(data.data.data);
        },
        enabled: !!req.projectId && enabled,
    });
};

export const useDeleteResourceType = () => {
    const { requestConfig } = useProject();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (id: number) => deleteResourceType(id, requestConfig),
        onSuccess: async (data) => {
            if (!data?.success) {
                throw new Error("Error format data: useDeleteResourceType");
            }

            await queryClient.invalidateQueries([ResourceTypeKeys.INDEX]);
        },
    });
};
