import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    remove,
    RemoveReturnType,
} from "src/api/fetch";
import { FavouriteProjectPath, Url } from "src/api/paths";

export const addProjectToFavourites = (data: any): PostReturnType => {
    return post(
        data,
        `${Url.COMMONS}${FavouriteProjectPath.FAVOURITE_PROJECTS}`,
    );
};

export const indexFavouritesProjects = (): GetReturnType => {
    return get(`${Url.COMMONS}${FavouriteProjectPath.FAVOURITE_PROJECTS}`);
};

export const removeProjectFromFavourites = (id: number): RemoveReturnType => {
    return remove(
        { id },
        `${Url.COMMONS}${FavouriteProjectPath.FAVOURITE_PROJECTS}/${id}`,
    );
};
