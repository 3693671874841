import {
    GridRenderCellParams,
    GridRowId,
    GridRowModel,
} from "@mui/x-data-grid-pro";
import React, { Dispatch, SetStateAction, useMemo, useState } from "react";

import { Type_index_resource } from "src/api/tms-projects/resources/types";
import { ResourcePeriodsListCell } from "src/components/Components_Common/cellComponents/ResourcePeriodsListCell/ResourcePeriodsListCell";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { ModalDelete } from "src/components/Components_Common/ModalDelete/ModalDelete";
import { HeaderGrid } from "src/components/Components_Common/Table/HeaderGrid/HeaderGrid";
import {
    GRID_HEIGHT,
    Table,
    Type_column,
    Type_menuDropDown,
} from "src/components/Components_Common/Table/Table";
import { AddButton } from "src/components/Components_Teamoty/AddButton/AddButton";
import { ResourceCell } from "src/components/Components_Teamoty/cells";
import { useProject } from "src/contexts/project";
import { ResourceDrawer } from "src/drawers/resources/ResourceDrawer";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { Type_modalDeleteResource } from "src/pages/Pages_Teamoty/Project/Settings/Page_Resources/Page_Resources.container";

export type Type_Props_ResourcesComponent = {
    resources: Type_index_resource[];
    isFetching: boolean;
    deleteResource: (id: number) => void;
    modalDeleteResource: Type_modalDeleteResource;
    setModalDeleteResource: Dispatch<SetStateAction<Type_modalDeleteResource>>;
    changeStatus: (id: number, resource: any) => void;
};

export const Page_ResourcesComponent = ({
    resources,
    isFetching,
    deleteResource,
    modalDeleteResource,
    setModalDeleteResource,
    changeStatus,
}: Type_Props_ResourcesComponent) => {
    const { formatMessageWithPartialKey: fmtTableColumn } =
        useCoreIntl("Table.Column");
    const { formatMessageWithPartialKey: fmtTableDataName } =
        useCoreIntl("Table.DataName");

    const page = "resources";
    const { requestConfig, isLoading: isLoadingProject } = useProject();

    const [selectedResource, setSelectedResource] = useState<number | null>(
        null,
    );
    const [openResourceDrawer, setOpenResourceDrawer] = useState(false);
    const [action, setAction] = useState<Type_action>("create");

    const openDrawer = (resourceId: number | null, action: Type_action) => {
        setSelectedResource(resourceId);
        setAction(action);
        setOpenResourceDrawer(true);
    };

    const handleResourceCreate = () => openDrawer(null, "create");

    const handleResourceEdit = (resourceId: number) =>
        openDrawer(resourceId, "update");

    const handleResourceDuplicate = (resourceId: number) =>
        openDrawer(resourceId, "duplicate");

    const handleCloseResourceDrawer = () => {
        setOpenResourceDrawer(false);
    };

    const dropDownItems: Type_menuDropDown = {};

    dropDownItems.callbackEdit = (row: GridRowModel) => {
        handleResourceEdit(row.id);
    };

    dropDownItems.callbackDuplicate = (row: GridRowModel) => {
        handleResourceDuplicate(row.id);
    };

    dropDownItems.callbackStatus = (row: GridRowModel) => {
        changeStatus(row.id, row as Type_index_resource);
    };

    dropDownItems.callbackDelete = (row: GridRowModel) => {
        setModalDeleteResource({
            isOpen: true,
            props: row as Type_index_resource,
        });
    };

    const showItem = (row: GridRowModel) => {
        if (isLoadingProject) return false;
        if (!isLoadingProject && requestConfig.subProjectId) {
            return !!row.subProject;
        }
        return true;
    };

    dropDownItems.showEdit = showItem;
    dropDownItems.showDuplicate = showItem;
    dropDownItems.showDelete = showItem;

    const gridColumns: Type_column[] = [
        {
            field: "name",
            headerName: fmtTableColumn("Name"),
            renderCell: (params: GridRenderCellParams) => {
                return <ResourceCell row={params.row} />;
            },
        },
        {
            field: "resourceTypeName",
            headerName: fmtTableColumn("ResourceType"),
        },
        {
            field: "resourcePeriods",
            headerName: fmtTableColumn("Availability"),
            sortable: false,
            renderCell: (params: any) => {
                return <ResourcePeriodsListCell resourceId={params.row.id} />;
            },
        },
    ];

    // Temporairement caché, remettre resources dans rows plus tard
    const resourcesWithoutSharedResources = useMemo(
        () => resources?.filter((resource) => resource.subProject) || [],
        [resources],
    );

    return (
        <>
            <HeaderGrid>
                <AddButton
                    data-testid="Resource-AddOne-btn"
                    onClick={() => handleResourceCreate()}
                    page={page}
                />
            </HeaderGrid>
            <Table
                rows={resourcesWithoutSharedResources}
                columns={gridColumns}
                noDataConfig={{
                    isFetching: isFetching,
                    dataName: fmtTableDataName("Resource"),
                    action: () => handleResourceCreate(),
                }}
                onClick={(id: GridRowId) => {
                    handleResourceEdit(parseInt(id as string));
                }}
                menuDropDown={dropDownItems}
                page={page}
                height={GRID_HEIGHT}
            />
            <ModalDelete
                open={modalDeleteResource.isOpen}
                onClose={() => setModalDeleteResource({ isOpen: false })}
                item={fmtTableDataName("Resource")}
                validationString={modalDeleteResource?.props?.name as string}
                actions={{
                    onSubmit: {
                        onClick: () => {
                            setModalDeleteResource({ isOpen: false });
                            deleteResource(
                                modalDeleteResource?.props?.id as number,
                            );
                        },
                    },
                }}
            />
            <ResourceDrawer
                open={openResourceDrawer}
                onClose={handleCloseResourceDrawer}
                resourceIdToUpdate={selectedResource}
                action={action}
                page={page}
            />
        </>
    );
};
