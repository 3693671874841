import { yupResolver } from "@hookform/resolvers/yup";
import { debounce, Stack, Typography } from "@mui/material";
import { useCallback, useEffect } from "react";
import { Form, FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";

import { Switch } from "src/components/Components_Common/forms/reactHookFormComponents/Switch/Switch";
import { useProject } from "src/contexts/project";
import { HeaderToolbar } from "src/drawers/links/HeaderToolbar";
import { useChannel } from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { Header } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";
import {
    getLocalStorageItem,
    setLocalStorageItem,
} from "src/utils/localStorageServices";

export type Type_PlanningSettings = {
    withWeekend: boolean;
    withDaysOff: boolean;
    todayDate: boolean;
    withLinksTaskArea: boolean;
};

const planningSettingsValidationSchema = Yup.object().shape({
    withWeekend: Yup.boolean(),
    withDaysOff: Yup.boolean(),
    todayDate: Yup.boolean(),
    withLinksTaskArea: Yup.boolean(),
});

type Type_Props_PlanningSettingsForm = {
    onClose: () => void;
};

export const PlanningSettingsForm = ({
    onClose,
}: Type_Props_PlanningSettingsForm) => {
    const { sendEvent } = useChannel({});
    const { project, subProject } = useProject();

    // i18n
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.PlanningSettings",
    );

    // React hook form
    const form = useForm<any>({
        defaultValues: {
            withWeekend: false,
            withDaysOff: false,
            todayDate: false,
            withLinksTaskArea: true,
            ...getLocalStorageItem(
                `planning_settings_${project?.id}_${subProject?.id}`,
            ),
        },
        mode: "onBlur",
        resolver: yupResolver<any>(planningSettingsValidationSchema),
    });

    useEffect(() => {
        if (project && subProject) {
            form.reset();
        }
    }, [project, subProject]);

    const handleSubmit = (values: Type_PlanningSettings) => {
        setLocalStorageItem(
            `planning_settings_${project?.id}_${subProject?.id}`,
            values,
        );
        sendEvent("updatePlanningSettings", values);
    };

    const debounceOnSubmit = useCallback(
        debounce((props) => handleSubmit(props), 1000),
        [],
    );

    return (
        <FormProvider {...form}>
            <Form
                onBlur={form.handleSubmit(debounceOnSubmit, (props) =>
                    console.error("handleSubmit: invalid response", props),
                )}
            >
                <Header>
                    <HeaderToolbar onClose={onClose} />
                    <Typography>{fmt("Title")}</Typography>
                </Header>
                <Stack padding={2} direction={"column"} spacing={3}>
                    <Switch
                        name={"withWeekend"}
                        label={fmt("WeekEnd")}
                        labelPlacement={"start"}
                        sx={{ justifyContent: "space-between" }}
                    />
                    <Switch
                        name={"withDaysOff"}
                        label={fmt("DaysOff")}
                        labelPlacement={"start"}
                        sx={{ justifyContent: "space-between" }}
                    />
                    <Switch
                        name={"todayDate"}
                        label={fmt("TodaySDate")}
                        labelPlacement={"start"}
                        sx={{ justifyContent: "space-between" }}
                    />
                    <Switch
                        name={"withLinksTaskArea"}
                        label={fmt("Links")}
                        labelPlacement={"start"}
                        sx={{ justifyContent: "space-between" }}
                    />
                </Stack>
            </Form>
        </FormProvider>
    );
};
