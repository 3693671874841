import React, { useState } from "react";

import { useShowCompany } from "src/api/tms-commons/companies";
import { useUser } from "src/contexts/user";

import { MyProfileDrawerContainer } from "./MyProfile_Drawer/MyProfileDrawer.container";
import { Page_MyProfileComponent } from "./Page_MyProfile.component";

export const Page_MyProfileContainer = () => {
    // ---- States
    const [open, setOpen] = useState(false);
    const [step, setStep] = useState("1");

    // ---- Queries
    const { user, getFullName } = useUser();
    const { data: company, isFetching } = useShowCompany(
        user?.companyId as number,
    );

    // ---- Utils
    const fullname = getFullName();

    // ---- Drawer utils
    const onClose = () => {
        setOpen(false);
    };

    const handleOpen = (step: string) => {
        setOpen(true);
        setStep(step);
    };

    return (
        <>
            <Page_MyProfileComponent
                user={user}
                company={company}
                isFetchingCompany={isFetching}
                fullName={fullname}
                handleOpenDrawer={handleOpen}
            />
            <MyProfileDrawerContainer
                user={user}
                company={company}
                step={step}
                setStep={setStep}
                open={open}
                onClose={onClose}
            />
        </>
    );
};
