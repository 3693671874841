import Konva from "konva";
import React from "react";
import { Group, Path, Rect, Text } from "react-konva";

import {
    colorPlanningButtonText,
    colorPlanningButtonTextSelected,
    colorPlanningSelected,
    fontSizeButton,
    sizePlanningButtonPictogram,
    sizePlanningScroll,
} from "src/components/Components_Teamoty/Planning/Planning.const";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { COLORS } from "src/theme/stylesheet";

import { Type_Props_PlanningZoomButton } from "./PlanningZoom.type";

export const PlanningZoomButton = ({
    x,
    y,
    pictogram,
    text,
    width,
    height,
    selected,
    onChange,
}: Type_Props_PlanningZoomButton) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Planning");

    return (
        <Group
            x={x}
            y={y}
            onClick={() => {
                onChange();
            }}
            onMouseOver={(e: Konva.KonvaEventObject<MouseEvent>) => {
                e.cancelBubble = true;
                const container: HTMLDivElement | undefined = e.currentTarget
                    .getStage()
                    ?.getContent();
                if (container) container.style.cursor = "pointer";
            }}
            onMouseOut={(e: Konva.KonvaEventObject<MouseEvent>) => {
                e.cancelBubble = true;
                const container: HTMLDivElement | undefined = e.currentTarget
                    .getStage()
                    ?.getContent();
                if (container) container.style.cursor = "default";
            }}
        >
            <Rect
                width={width ? width : sizePlanningScroll}
                height={height ? height : sizePlanningScroll}
                fill={selected ? colorPlanningSelected : COLORS.transparent}
            />

            {pictogram && (
                <Path
                    scaleX={sizePlanningButtonPictogram / 512}
                    scaleY={sizePlanningButtonPictogram / 512}
                    x={(sizePlanningScroll - sizePlanningButtonPictogram) / 2}
                    y={(sizePlanningScroll - sizePlanningButtonPictogram) / 2}
                    fill={
                        selected
                            ? colorPlanningButtonTextSelected
                            : colorPlanningButtonText
                    }
                    data={pictogram}
                />
            )}

            {!pictogram && text && (
                <Text
                    width={width ? width : sizePlanningScroll}
                    height={height ? height : sizePlanningScroll}
                    align="center"
                    verticalAlign="middle"
                    text={fmt(text)}
                    fontSize={fontSizeButton}
                    fill={
                        selected
                            ? colorPlanningButtonTextSelected
                            : colorPlanningButtonText
                    }
                />
            )}
        </Group>
    );
};
