import { planningDateClass } from "@cimba-digital-construction-solution/utils/dist/class/planningDateClass";
import { Type_planningSubTrade } from "@cimba-digital-construction-solution/utils/dist/decoder/types";
import Konva from "konva";
import React, { useEffect, useState } from "react";
import { Group, Line, Rect, Text } from "react-konva";

import { TextChip } from "src/components/Components_Common/canvas/TextChip";
import { PlanningDrawerTitle } from "src/components/Components_Teamoty/Planning/Drawer/PlanningDrawerTitle";
import { PlanningPlanningDrawerResizerX } from "src/components/Components_Teamoty/Planning/Drawer/PlanningPlanningDrawerResizerX";
import {
    colorPlanningLine,
    heightPlanningDrawerTitle,
    heightPlanningWorkforceSubTradesTabLine,
    marginPlanningElements,
} from "src/components/Components_Teamoty/Planning/Planning.const";
import { PlanningScrollY } from "src/components/Components_Teamoty/Planning/Scrolls/PlanningScrollY";
import { getGridVertical } from "src/components/Components_Teamoty/Planning/tools/getGridVertical";

import { getQuantitiesBySubTrades } from "./getQuantitiesBySubTrades";
import {
    Type_Map_planningQuantities,
    Type_Props_PlanningWorkforceSubTradesTab,
} from "./PlanningWorkforce.type";

export const PlanningWorkforceSubTradesTab = ({
    x,
    y,
    width,
    height,
    widthLegend,
    dates,
    widthDate,
    workforce,
    workforceSubTrades,
    subTrades,
    onClose,
    onResize,
}: Type_Props_PlanningWorkforceSubTradesTab) => {
    const heightReal: number =
        height - marginPlanningElements - heightPlanningDrawerTitle;

    const [quantities, setQuantities] = useState<Type_Map_planningQuantities>(
        new Map<number, number[]>(),
    );

    const [scrollPos, setScrollPos] = useState<number>(0);

    useEffect((): void => {
        getQuantitiesBySubTrades(dates, workforce, setQuantities);
    }, [dates, workforce, widthDate]);

    const [elmRects] = getGridVertical(heightReal, dates, widthDate);

    const elmText: Array<React.JSX.Element> = [];
    const elmLine: Array<React.JSX.Element> = [];

    let yy: number = 0;
    workforceSubTrades.forEach((subTradeId: number): void => {
        const subTrade: Type_planningSubTrade = subTrades.get(
            subTradeId,
        ) as Type_planningSubTrade;
        elmText.push(
            <TextChip
                key={"gll" + subTradeId}
                x={3}
                y={yy}
                height={heightPlanningWorkforceSubTradesTabLine}
                width={widthLegend as number}
                name={subTrade.name}
                code={subTrade.code}
                color={subTrade.color}
            />,
        );

        if (quantities.has(subTradeId)) {
            const values: number[] = quantities.get(subTradeId) as number[];
            values.map((value: number, index: number): void => {
                elmText.push(
                    <Text
                        key={"glt" + subTradeId + "." + index}
                        x={(widthLegend as number) + index * widthDate}
                        y={yy}
                        height={heightPlanningWorkforceSubTradesTabLine}
                        width={widthDate}
                        align={"center"}
                        verticalAlign={"middle"}
                        wrap={"none"}
                        text={value.toString()}
                    />,
                );
            });
        }
        elmLine.push(
            <Line
                key={"glx" + subTradeId}
                points={[0, yy, width, yy]}
                stroke={colorPlanningLine}
                strokeWidth={1}
            />,
        );
        yy += heightPlanningWorkforceSubTradesTabLine;
    });

    elmLine.push(
        <Line
            key={"glx" + 999}
            points={[0, yy, width, yy]}
            stroke={colorPlanningLine}
            strokeWidth={1}
        />,
    );

    dates.days.map((date: planningDateClass, index: number) => {
        elmLine.push(
            <Line
                x={widthLegend as number}
                key={"gly" + index}
                points={[date.pos, 0, date.pos, yy]}
                stroke={colorPlanningLine}
                strokeWidth={1}
            />,
        );
    });

    return (
        <Group
            onWheel={(e: Konva.KonvaEventObject<WheelEvent>) => {
                e.evt.preventDefault();
                e.evt.stopPropagation();
                e.cancelBubble = true;

                const delta = e.evt.deltaY > 0 ? 1 : -1;

                setScrollPos((prev: number): number =>
                    Math.max(
                        0,
                        Math.min(
                            workforceSubTrades.size -
                                heightReal /
                                    heightPlanningWorkforceSubTradesTabLine,
                            prev + delta,
                        ),
                    ),
                );
            }}
        >
            <Group x={x} y={y}>
                <Group x={widthLegend as number}>{elmRects}</Group>
                <PlanningDrawerTitle
                    width={width}
                    titleKey={"WorkforceSubTradesTabTitle"}
                    onClose={onClose}
                />
                <Rect
                    y={heightPlanningDrawerTitle}
                    width={width - 0.5}
                    height={heightReal}
                    stroke={colorPlanningLine}
                    strokeWidth={1}
                />
                <Group
                    clipX={-0.5}
                    clipY={heightPlanningDrawerTitle}
                    clipWidth={width}
                    clipHeight={
                        height -
                        heightPlanningDrawerTitle -
                        marginPlanningElements
                    }
                >
                    <Group
                        y={
                            heightPlanningDrawerTitle -
                            scrollPos * heightPlanningWorkforceSubTradesTabLine
                        }
                    >
                        {elmLine}
                        {elmText}
                    </Group>
                </Group>
                <PlanningScrollY
                    pos={scrollPos}
                    size={workforceSubTrades.size}
                    page={Math.min(
                        workforceSubTrades.size,
                        Math.round(
                            heightReal /
                                heightPlanningWorkforceSubTradesTabLine,
                        ),
                    )}
                    widthPage={heightReal}
                    x={x + width}
                    y={heightPlanningDrawerTitle}
                    width={heightReal}
                    changePos={(pos: number): void => {
                        setScrollPos(pos);
                    }}
                />
            </Group>
            <PlanningPlanningDrawerResizerX
                x={x}
                y={y}
                width={width}
                height={height}
                onResize={onResize}
            />
        </Group>
    );
};
