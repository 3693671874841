import { Typography, useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";

import { Type_index_constraint } from "src/api/tms-scheduling/constraints/types";
import { Avatar } from "src/components/Components_Common/Avatar/Avatar";
import { ChipSelectPriority } from "src/components/Components_Teamoty/forms/select/ChipSelectPriority";
import { ChipSelectStatus } from "src/components/Components_Teamoty/forms/select/ChipSelectStatus";
import { useChannel } from "src/hooks/useChannel";
import { useContextualDrawer } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";
import { displayFormattedDate } from "src/utils/date";

import {
    Styled_Container,
    Styled_Content,
    Styled_Footer,
    Styled_HeaderAuthor,
    Styled_Username,
} from "./TaskObservation.style";

type Type_Props_TaskObservation = {
    constraint: Type_index_constraint;
};

export const TaskObservation = ({ constraint }: Type_Props_TaskObservation) => {
    /////////////////////////
    ///     Hooks         ///
    /////////////////////////
    const [observation, setObservation] = useState(constraint);
    const [isActive, setIsActive] = useState(false);
    useEffect(() => {
        !!constraint && setObservation(constraint);
    }, [constraint]);

    const { openPaper } = useContextualDrawer();

    /////////////////////////
    ///     Channels      ///
    /////////////////////////
    const { sendEvent } = useChannel({
        eventHandler: ({ event, data }) => {
            if (event === "updateObservation") {
                const observationsData = data as Type_index_constraint;
                if (observationsData.id === constraint.id) {
                    setObservation(observationsData);
                }
            }
            if (event === "setActiveObservation") {
                const activeObservationId = data as number;
                setIsActive(activeObservationId === constraint.id);
            }
        },
    });

    const handleClik = () => {
        openPaper("observation", { constraint: observation });
        sendEvent("setActiveObservation", observation.id);
        setIsActive(true);
    };

    const MemoizedChipSelectPriority = useMemo(() => {
        return (
            <ChipSelectPriority value={observation.priority} readonly={true} />
        );
    }, [observation.priority]);

    const theme = useTheme();

    return (
        <Styled_Container
            sx={{
                backgroundColor: isActive
                    ? theme.palette.common.greyLight
                    : "none",
            }}
            onClick={handleClik}
        >
            <Styled_Content>
                <Styled_HeaderAuthor>
                    <Styled_Username>
                        <Avatar
                            name={
                                observation.author.firstName +
                                " " +
                                observation.author.lastName
                            }
                            sx={{
                                width: 24,
                                height: 24,
                                textTransform: "uppercase",
                                fontSize: "0.7rem",
                            }}
                        />
                        <Typography
                            variant="body3Medium"
                            color={"text.primary"}
                        >
                            {observation.author.firstName}{" "}
                            {observation.author.lastName}
                        </Typography>
                    </Styled_Username>
                </Styled_HeaderAuthor>
                <Typography variant="body2">
                    {observation.description}
                </Typography>
                <Styled_Footer>
                    {MemoizedChipSelectPriority}
                    <ChipSelectStatus
                        value={observation.status}
                        readonly={true}
                    />
                    <Typography variant="body2" color="secondary">
                        {displayFormattedDate(observation.date)}
                    </Typography>
                </Styled_Footer>
            </Styled_Content>
        </Styled_Container>
    );
};
