import { useMutation, useQuery } from "react-query";

import { Type_Filter_Constraint } from "src/api/filters";
import { ConstraintKeys } from "src/api/tms-scheduling/keys";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { useChannel } from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { getDate } from "src/utils/date";

import {
    formatterCreateConstraint,
    formatterIndexConstraint,
    formatterIndexConstraints,
    formatterUpdateConstraint,
} from "./formatters";
import {
    createConstraint,
    deleteConstraint,
    indexConstraints,
    updateConstraint,
} from "./services";
import {
    ConstraintStatus,
    Type_defautlConstraintParams,
    Type_index_constraint,
    Type_sch_index_constraint,
    Type_show_constraint,
} from "./types";

export const useIndexConstraints = (
    filters: Type_Filter_Constraint = {},
    enabled?: boolean,
) => {
    const { requestConfig } = useProject();
    return useQuery({
        enabled: !!enabled,
        queryKey: [ConstraintKeys.INDEX, requestConfig, filters],
        queryFn: () => indexConstraints(requestConfig, filters),
        refetchOnWindowFocus: false,
        select: (data) => {
            const constraints: Type_sch_index_constraint[] = data?.data?.data;
            return formatterIndexConstraints(constraints);
        },
    });
};

export const mutationCreateDefaultConstraint = (
    callback: (data: Type_index_constraint) => void,
) => {
    const { requestConfig } = useProject();
    const { sendEvent } = useChannel({});
    const { formatMessageWithPartialKey: fmtGeneric } = useCoreIntl("Errors");
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerTasks.Observations",
    );
    const { addSuccess, addWarning } = useToast();

    const defaultConstraint = {
        description: fmt("DefaultDescription"),
        date: getDate().add(7, "days"),
        subTradeId: null,
        companyId: null,
        status: ConstraintStatus.TO_DO,
    };
    return useMutation({
        mutationFn: (constraintParams: Type_defautlConstraintParams) => {
            return createConstraint(
                formatterCreateConstraint({
                    ...defaultConstraint,
                    ...constraintParams,
                }),
                requestConfig,
            );
        },
        onSuccess: (data: any) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: mutationCreateDefaultConstraint",
                );
            }
            const formattedData = formatterIndexConstraint(data?.data?.data);
            callback(formattedData);
            sendEvent("addObservation", formattedData);
            sendEvent("changeFlagObservations", 1);
            addSuccess({
                description: fmt("SuccessCreate"),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtGeneric("GenericError", {}),
            });
            return err;
        },
    });
};

export const mutationUpdateConstraint = () => {
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Form.Error");
    const { addWarning } = useToast();
    const { sendEvent } = useChannel({});
    return useMutation({
        mutationFn: (constraint: Type_show_constraint) =>
            updateConstraint(
                formatterUpdateConstraint(constraint),
                constraint.id,
                requestConfig,
            ),
        onSuccess: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationUpdateConstraint");
            }
            const formattedConstraint = formatterIndexConstraint(
                data?.data?.data,
            );
            sendEvent("updateObservation", formattedConstraint);
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("ErrorGeneric"),
            });
            return err;
        },
    });
};

export const mutationDeleteConstraint = (callback: () => void) => {
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Form.Error");
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerTasks.Observations",
    );
    const { addWarning, addSuccess } = useToast();
    return useMutation({
        mutationFn: (constraintId: number) => {
            return deleteConstraint(constraintId, requestConfig);
        },
        onSuccess: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationDeleteConstraint");
            }
            callback();
            addSuccess({
                description: fmt("SuccessDelete"),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("ErrorGeneric"),
            });
            return err;
        },
    });
};
