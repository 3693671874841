import { InputAnchorComponent } from "./InputAnchor.component";

/* COMPONENT TYPE */
export type InputAnchorType = {
    type: "inputAnchor";
    label?: {
        label?: string;
        languageLabel?: string;
    };
    link?: string;
};

/* PROPS TYPE */
export type InputAnchorPropsType = {
    label: string;
    link: string;
};

export const InputAnchorContainer = (props: InputAnchorPropsType) => {
    return <InputAnchorComponent {...props} />;
};
