import { Stack } from "@mui/material";
import {
    DateTimeRangePickerProps,
    DateTimeRangePicker as MuiDateTimeRangePicker,
    renderDigitalClockTimeView,
} from "@mui/x-date-pickers-pro";

import { TMC_FormHelperText } from "src/components/Components_Common/_MuiComponentsVariants/index";

export type Type_Props_DateTimeRangePickerProps =
    DateTimeRangePickerProps<any> & {
        name?: string;
        helperText?: string;
        error?: boolean;
    };

export const DateTimeRangePicker = ({
    helperText,
    name,
    error,
    ...restProps
}: Type_Props_DateTimeRangePickerProps) => {
    return (
        <Stack
            flexDirection="column"
            data-testid={`DateTimeRangePicker-${name}-container`}
        >
            <MuiDateTimeRangePicker
                views={["day", "hours"]}
                timeSteps={{ ...restProps.timeSteps, minutes: 30 }}
                viewRenderers={{
                    ...restProps.viewRenderers,
                    hours: renderDigitalClockTimeView,
                }}
                {...restProps}
            />
            {helperText && error && (
                <TMC_FormHelperText
                    helperText={helperText as string}
                    inputName={name as string}
                />
            )}
        </Stack>
    );
};
