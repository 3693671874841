import dayjs from "dayjs";

import { Type_me } from "src/api/tms-gateway/auth/types";
import {
    Type_index_user,
    Type_index_userWithMeta,
    Type_metadataUser,
    Type_post_userInvitation,
    Type_put_user,
    Type_usr_index_user,
    Type_usr_index_userWithMeta,
    Type_usr_post_user,
    Type_usr_put_user,
    USER_ORIGIN,
    USER_STATUS,
} from "src/api/tms-users/users/types";
import { BACKEND_DATE_FORMAT } from "src/configurations/LocalizationWrapper";
import { displayFormattedDate, formatDateForFront } from "src/utils/date";

export const formatterIndexUsers = (
    users: Type_usr_index_user[],
): Type_index_user[] => {
    return users.map((user: Type_usr_index_user) => formatterIndexUser(user));
};

export const formatterIndexUser = (
    user: Type_usr_index_user,
): Type_index_user => {
    return {
        id: user.id,
        name:
            user.firstName && user.lastName
                ? user.firstName + " " + user.lastName
                : "",
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.phone,
        role: user?.role?.name,
        company: user?.company?.name || "-",
        companyId: user?.company?.id,
        lastActivityDate: formatDateForFront(user.last_activity_date),
        status: user.status,
        origin: user.origin,
        metadata: user.metadata,
        enabled: user.enabled,
    };
};

export const formatterIndexUsersWithMeta = (
    data: Type_usr_index_userWithMeta,
): Type_index_userWithMeta => {
    const users = formatterIndexUsers(data.data);
    return {
        users: users,
        total: data.meta && data.meta.total,
    };
};

export const formatterCreateUserInvitation = (
    user: Type_post_userInvitation,
): Type_usr_post_user => {
    return {
        email: user.email,
        role_id: user.roleId as number,
        origin: USER_ORIGIN.INVITATION,
        status: USER_STATUS.INVITED,
    };
};

export const formatterUpdateUser = (user: Type_put_user): Type_usr_put_user => {
    const { companyId, ...rest } = user;
    return {
        ...rest,
        company_id: companyId,
    };
};

export const formatterIndexUserToMe = (user: Type_index_user): Type_me => {
    const {
        id,
        email,
        firstName = "",
        lastName = "",
        phone,
        metadata,
        enabled,
        companyId = 0,
        permissions = [""],
    } = user;
    // on restructure Type_index_user pour le convertir en Type_me
    // le reste des champs de Type_me n'existe pas dans Type_index_user
    return {
        id,
        email,
        firstName,
        lastName,
        phone,
        metadata,
        enabled,
        companyId,
        permissions,
    };
};

export const formatterMetadataUserToArray = (
    metadata: Record<string, string> | null,
): Type_metadataUser[] => {
    if (!metadata) return [];

    const res: Type_metadataUser[] = [];

    for (const [label, value] of Object.entries(metadata)) {
        let result = value;
        let isDate = false;
        //check if value is date
        if (
            dayjs(value.substring(0, 10), BACKEND_DATE_FORMAT, true).isValid()
        ) {
            result = displayFormattedDate(value);
            isDate = true;
        }

        res.push({ label: label, value: result, isDate: isDate });
    }
    return res;
};
