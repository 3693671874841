import { Box, Grid, Tooltip, Typography } from "@mui/material";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import { DatePicker } from "src/components/Components_Common/forms/reactHookFormComponents/DatePicker/DatePicker";
import { TextField } from "src/components/Components_Common/forms/reactHookFormComponents/TextField/TextField";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { CompanyTeamBadge } from "src/components/Components_Teamoty/badges/CompanyTeamBadge";
import { SelectPunchlistItemCauses } from "src/components/Components_Teamoty/forms/select/SelectPunchlistItemCauses";
import { SelectSubTradeCompany } from "src/components/Components_Teamoty/forms/select/SelectSubTradeCompany";
import { shouldDisplay } from "src/forms/tasks/utils";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import {
    Row,
    RowIcon,
    Section,
} from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

export const TaskAreaPunchlistFormContent = () => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerTaskArea.Main",
    );

    const { getValues } = useFormContext();

    const [subTradeName, subTradeId, companyId, taskType] = getValues([
        "subTrade.name",
        "subTrade.id",
        "companyId",
        "type",
    ]);

    return (
        <Section>
            <Box display="flex" flexDirection="column" gap={2}>
                {shouldDisplay({ name: "subTrade", taskType }) && (
                    <Row>
                        <RowIcon>
                            <Tooltip
                                title={fmt("Tooltip.Trade")}
                                placement="left"
                            >
                                <Icon
                                    variant={"light"}
                                    icon={"helmet-safety"}
                                    fontSize="small"
                                />
                            </Tooltip>
                        </RowIcon>
                        <Typography variant="body1" color="textSecondary">
                            {subTradeName}
                        </Typography>
                    </Row>
                )}

                {shouldDisplay({ name: "companyId", taskType }) && (
                    <Row>
                        <RowIcon>
                            <Tooltip
                                title={fmt("Tooltip.Company")}
                                placement="left"
                            >
                                <Icon
                                    variant="light"
                                    icon="building"
                                    fontSize="small"
                                />
                            </Tooltip>
                        </RowIcon>
                        <SelectSubTradeCompany
                            readonly
                            name="companyId"
                            subTradeId={subTradeId}
                            size="small"
                            variant="standard"
                            data-testid="Form-TaskAreaPunchlist-company"
                        />
                        <CompanyTeamBadge companyId={companyId} />
                    </Row>
                )}
                <SelectPunchlistItemCauses
                    label={"Cause"}
                    name="punchlistCauseId"
                    data-testid="Form-TaskAreaPunchlist-punchlistCause-id"
                />

                <Grid item xs={6}>
                    <TextField
                        name={"duration"}
                        label={fmt("Duration")}
                        type={"number"}
                        inputProps={{ step: 0.1 }}
                        data-testid={`Form-TaskAreaPunchlist-duration`}
                    />
                </Grid>

                <DatePicker
                    name="earliestDate"
                    label={fmt("EarliestDate")}
                    data-testid="Form-TaskAreaPunchlist-earliestDate"
                />
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <TextField
                            name={"team"}
                            label={fmt("Team")}
                            type={"number"}
                            inputProps={{ min: 1 }}
                            data-testid={`Form-TaskAreaPunchlist-team`}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            name={"workforce"}
                            label={fmt("Workforce")}
                            type={"number"}
                            inputProps={{ step: 0.1 }}
                            data-testid={`Form-TaskAreaPunchlist-workforce`}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Section>
    );
};
