import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    PutReturnType,
    remove,
    RemoveReturnType,
} from "src/api/fetch";
import { PriorityOfConstraintsPath, Url } from "src/api/paths";
import {
    Type_com_post_priorityOfConstraint,
    Type_com_put_priorityOfConstraint,
} from "src/api/tms-commons/priorityOfConstraints/types";

export const indexPriorityOfConstraints = (): GetReturnType => {
    return get(
        `${Url.COMMONS}${PriorityOfConstraintsPath.PRIORITY_OF_CONSTRAINTS}`,
    );
};

export const showPriorityOfConstraint = (id: number): GetReturnType => {
    return get(
        `${Url.COMMONS}${PriorityOfConstraintsPath.PRIORITY_OF_CONSTRAINTS}/${id}`,
    );
};

export const createPriorityOfConstraint = (
    data: Type_com_post_priorityOfConstraint,
): PostReturnType => {
    return post(
        data,
        `${Url.COMMONS}${PriorityOfConstraintsPath.PRIORITY_OF_CONSTRAINTS}`,
    );
};
export const updatePriorityOfConstraint = (
    id: number,
    data: Type_com_put_priorityOfConstraint,
): PutReturnType => {
    return put(
        data,
        `${Url.COMMONS}${PriorityOfConstraintsPath.PRIORITY_OF_CONSTRAINTS}/${id}`,
    );
};

export const deletePriorityOfConstraint = (id: number): RemoveReturnType => {
    return remove(
        { id },
        `${Url.COMMONS}${PriorityOfConstraintsPath.PRIORITY_OF_CONSTRAINTS}/${id}`,
    );
};
