import {
    Type_index_taskNote,
    Type_post_taskNote,
    Type_sch_index_taskNote,
    Type_sch_post_taskNote,
    Type_sch_put_taskNote,
} from "src/api/tms-scheduling/taskNotes/types";
import { Type_put_note } from "src/forms/notes/NoteForm";
import { formatDateTimeForFront } from "src/utils/date";

export const formatterIndexTaskNotes = (
    taskNotes: Type_sch_index_taskNote[],
): Type_index_taskNote[] => {
    return taskNotes.map(formatterIndexTaskNote);
};

export const formatterIndexTaskNote = (
    taskNote: Type_sch_index_taskNote,
): Type_index_taskNote => {
    const { date, ...rest } = taskNote;

    return {
        ...rest,
        date: formatDateTimeForFront(date)!,
    };
};

export const formatterCreateTaskNote = (
    taskNote: Type_post_taskNote,
): Type_sch_post_taskNote => {
    return {
        text: taskNote.text,
        task_number: taskNote.taskNumber,
    };
};

export const formatterUpdateTaskNote = (
    taskNote: Type_put_note,
): Type_sch_put_taskNote => {
    return {
        text: taskNote.text,
    };
};
