import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    PutReturnType,
    remove,
    RemoveReturnType,
    Type_RequestConfig,
} from "src/api/fetch";
import { ResourcePath, Url } from "src/api/paths";
import {
    Type_prj_post_resource,
    Type_prj_put_resource,
} from "src/api/tms-projects/resources/types";

export const indexResources = (
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(`${Url.PROJECTS}${ResourcePath.RESOURCES}`, requestConfig);
};

export const showResource = (
    requestConfig: Type_RequestConfig,
    id: number,
): GetReturnType => {
    return get(`${Url.PROJECTS}${ResourcePath.RESOURCES}/${id}`, requestConfig);
};

export const createResource = (
    requestConfig: Type_RequestConfig,
    data: Type_prj_post_resource,
): PostReturnType => {
    return post(
        data,
        `${Url.PROJECTS}${ResourcePath.RESOURCES}`,
        requestConfig,
    );
};

export const updateResource = (
    data: Type_prj_put_resource,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return put(
        data,
        `${Url.PROJECTS}${ResourcePath.RESOURCES}/${data.id}`,
        requestConfig,
    );
};

export const statusResource = (
    data: Type_prj_put_resource,
    requestConfig: Type_RequestConfig,
): PutReturnType => {
    return put(
        data,
        `${Url.PROJECTS}${ResourcePath.RESOURCES}/${data.id}`,
        requestConfig,
    );
};

export const selectListResources = (
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(
        `${Url.PROJECTS}${ResourcePath.RESOURCES_SELECT_LIST}`,
        requestConfig,
    );
};

export const deleteResource = (
    id: number,
    requestConfig: Type_RequestConfig,
): RemoveReturnType => {
    return remove(
        { id },
        `${Url.PROJECTS}${ResourcePath.RESOURCES}/${id}`,
        requestConfig,
    );
};
