import { Box, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Form, FormProvider, useForm, useWatch } from "react-hook-form";

import { useSelectListProjectUsers } from "src/api/tms-projects/projectUsers";
import { Type_selectList_projectUser } from "src/api/tms-projects/projectUsers/types";
import {
    mutationCreatePeople,
    mutationUpdatePeople,
    useSelectPeople,
} from "src/api/tms-scheduling/people";
import { formatterSelectPerson } from "src/api/tms-scheduling/people/formatter";
import {
    Type_sch_select_people,
    Type_select_people,
} from "src/api/tms-scheduling/people/types";
import {
    mutationCreatePeopleTask,
    mutationDeletePeopleTask,
    useIndexPeopleTask,
} from "src/api/tms-scheduling/peopleTask";
import { formatterIndexPeopleTask } from "src/api/tms-scheduling/peopleTask/formatter";
import { Type_index_peopleTask } from "src/api/tms-scheduling/peopleTask/types";
import {
    mutationCreateTaskAreaPeople,
    mutationDeleteTaskAreaPeople,
    useIndexTaskAreaPeople,
} from "src/api/tms-scheduling/peopleTaskArea";
import { formatterIndexPeopleTaskArea } from "src/api/tms-scheduling/peopleTaskArea/formatter";
import {
    DropdownMenu,
    Empty,
    Spinner,
    TMC_Button,
    UserSmallCard,
} from "src/components";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { FullSpinner } from "src/components/Components_Common/Spinner/FullSpinner";
import { AutocompletePeopleTasks } from "src/components/Components_Teamoty/autocompletesRhf/AutocompletePeopleTask";
import { HeaderToolbar } from "src/drawers/links/HeaderToolbar";
import { useChannel } from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { Header } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";
import { hasKey } from "src/utils/object";
import { sortData, sortDscData } from "src/utils/sortData";

import { Styled_Stack_buttonsGroup } from "./PeopleTaskForm.style";

export type Type_createNewPeople = {
    id: number;
    firstName: string;
    lastName: string;
    email?: string;
};

type Type_extended_peopleTask = Type_index_peopleTask & {
    areaId?: number;
};

type Type_Props_PeopleTaskForm = {
    onClose: () => void;
    id: number;
    focus: boolean;
    areaId?: number;
};

export const PeopleTaskForm = ({
    onClose,
    id,
    focus,
    areaId,
}: Type_Props_PeopleTaskForm) => {
    const { sendEvent } = useChannel({});

    ///////////////////////////////////////
    ///            i18n                 ///
    ///////////////////////////////////////

    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerPeople",
    );
    const { formatMessageWithPartialKey: fmtActions } = useCoreIntl("Actions");
    const { formatMessageWithPartialKey: fmtExt } = useCoreIntl(
        "Project.Views.Planning.DrawerTasks.PeopleTask",
    );

    ///////////////////////////////////////
    ///            State                ///
    ///////////////////////////////////////

    const [projectUsers, setProjectUsersFetched] = useState<
        Type_selectList_projectUser[]
    >([]);
    const [peopleTasks, setPeopleTasks] = useState<Type_extended_peopleTask[]>(
        [],
    );
    const [people, setPeople] = useState<Type_select_people[]>([]);

    const addNewPeopleTaskToState = (newPersonTask: Type_extended_peopleTask) =>
        setPeopleTasks((old) => [...old, newPersonTask]);

    const updatePeopleTaskToState = (
        peopleTaskUpdated: Type_extended_peopleTask,
    ) => {
        setPeopleTasks((old) =>
            old.map((people) =>
                people.id !== peopleTaskUpdated.id
                    ? people
                    : {
                          ...people,
                          ...peopleTaskUpdated,
                          peopleTaskId: people.peopleTaskId,
                      },
            ),
        );
    };

    const addNewPeopleToState = (newPerson: Type_sch_select_people) =>
        setPeople((old) => [...old, formatterSelectPerson(newPerson)]);

    const removePeopleTaskToState = (idToRemove: number) =>
        setPeopleTasks((old) =>
            old.filter((peopleTask) => peopleTask.peopleTaskId !== idToRemove),
        );

    const updatePeopleToState = (peopleUpdated: Type_select_people) => {
        setPeople((old) =>
            old.map((people) =>
                people.id !== peopleUpdated.id ? people : peopleUpdated,
            ),
        );
    };

    const updatePeopleFromDialog = (peopleUpdated: Type_select_people) => {
        updatePeopleToState(peopleUpdated);
        updatePeopleTaskToState(peopleUpdated as Type_extended_peopleTask);
    };

    useEffect(() => {
        sendEvent("updatePeopleTasks", peopleTasks);
    }, [peopleTasks]);

    ///////////////////////////////////////
    ///         Queries                 ///
    ///////////////////////////////////////

    const {
        isFetching: isFetchingSelectProjectUsers,
        data: projectUsersFetched,
    } = useSelectListProjectUsers();
    const { isFetching: isFetchingPeopleTasks, data: peopleTasksFetched } =
        useIndexPeopleTask(id);
    const {
        isFetching: isFetchingPeopleTaskArea,
        data: peopleTaskAreaFetched,
    } = useIndexTaskAreaPeople(areaId, id);
    const { data: peopleFetched } = useSelectPeople();

    useEffect(() => {
        if (projectUsersFetched?.length)
            setProjectUsersFetched(projectUsersFetched);
    }, [projectUsersFetched]);

    useEffect(() => {
        if (areaId) {
            peopleTaskAreaFetched?.length &&
                setPeopleTasks(peopleTaskAreaFetched);
        } else {
            peopleTasksFetched?.length && setPeopleTasks(peopleTasksFetched);
        }
    }, [peopleTasksFetched, peopleTaskAreaFetched]);

    useEffect(() => {
        if (peopleFetched?.length) setPeople(peopleFetched);
    }, [peopleFetched]);

    const { mutateAsync: createPeopleTask, isLoading: isCreatingPeopleTask } =
        mutationCreatePeopleTask();
    const {
        mutateAsync: createPeopleTaskArea,
        isLoading: isCreatingPeopleTaskArea,
    } = mutationCreateTaskAreaPeople();
    const { mutateAsync: createPeople, isLoading: isCreatingPeople } =
        mutationCreatePeople();
    const { mutateAsync: removePeopleTask } = mutationDeletePeopleTask(
        id,
        (id: number) => removePeopleTaskToState(id),
    );
    const { mutateAsync: removeTaskAreaPeople } = mutationDeleteTaskAreaPeople(
        id,
        areaId as number,
        (id: number) => removePeopleTaskToState(id),
    );
    const { mutateAsync: updatePeople } = mutationUpdatePeople(
        (peopleUpdated: Type_select_people) =>
            updatePeopleFromDialog(peopleUpdated),
    );

    const isLoading: boolean = useMemo(
        () =>
            isCreatingPeopleTask ||
            isCreatingPeopleTaskArea ||
            isCreatingPeople,
        [isCreatingPeopleTask, isCreatingPeopleTaskArea, isCreatingPeople],
    );

    ///////////////////////////////////////
    ///         React hook form         ///
    ///////////////////////////////////////

    const form = useForm<any>({
        defaultValues: {
            peopleTasks: [],
        },
    });

    const selectedPoeple = useWatch({
        control: form.control,
        name: "peopleTasks",
    });

    ///////////////////////////////////////
    ///           Utils                 ///
    ///////////////////////////////////////

    const peopleNotAssigned: Type_select_people[] = useMemo(() => {
        const peopleTasksIds = peopleTasks?.map((user) => user.id);
        return sortData(
            people.filter((user) => !peopleTasksIds?.includes(user.id)),
            "firstName",
        );
    }, [people, peopleTasks]);

    const filterProjectUsersWithoutPeople = (
        projectUsers: Type_selectList_projectUser[],
        people: Type_select_people[],
    ) => {
        const notAssignedIds = people?.map((user) => user.userId);
        return projectUsers.filter(
            (user) => !notAssignedIds?.includes(user.id),
        );
    };

    const assignableUsers: Type_selectList_projectUser[] = useMemo(
        () =>
            sortDscData(
                sortData(
                    [
                        ...(peopleNotAssigned?.length
                            ? (peopleNotAssigned?.map((person) => ({
                                  ...person,
                                  group: person.userId
                                      ? fmt("Platform")
                                      : fmt("Other"),
                              })) as Type_select_people[])
                            : []),
                        ...(projectUsers?.length
                            ? (filterProjectUsersWithoutPeople(
                                  projectUsers,
                                  people,
                              )?.map((person) => ({
                                  ...person,
                                  group: fmt("Platform"),
                              })) as Type_selectList_projectUser[])
                            : []),
                    ],
                    "firstName",
                ),
                "group",
            ),
        [projectUsers, peopleTasks, people],
    );

    const sx = {
        "&:hover .buttonGroup-peopleTask": {
            visibility: "visible",
        },
    };

    const addNewPeopleToTask = async () => {
        for (const newPeopleTask of selectedPoeple) {
            let idNewPeopleTask: number = newPeopleTask.id;
            let newPeopleResp = null;

            if (!("userId" in newPeopleTask)) {
                const { data: resp } = await createPeople({
                    user_id: newPeopleTask.id,
                });
                idNewPeopleTask = resp.data.id;
                newPeopleResp = resp;
            }

            if (areaId) {
                const { data: respPeopleTaskArea } = await createPeopleTaskArea(
                    {
                        areaId: areaId,
                        taskId: id,
                        peopleId: idNewPeopleTask,
                    },
                );
                addNewPeopleTaskToState(
                    formatterIndexPeopleTaskArea(respPeopleTaskArea.data),
                );
            } else {
                const { data: respPeopleTask } = await createPeopleTask({
                    task_id: id,
                    people_id: idNewPeopleTask,
                });
                addNewPeopleTaskToState(
                    formatterIndexPeopleTask(respPeopleTask.data),
                );
            }

            if (newPeopleResp?.success)
                addNewPeopleToState(newPeopleResp?.data);
        }
        form.reset({ peopleTasks: [] });
    };

    const addNewPeopleTask = async (data: Type_createNewPeople) => {
        const { data: resp } = await createPeople(
            hasKey(data, "userId")
                ? {
                      firstName: data.firstName,
                      lastName: data.lastName,
                      email: data.email,
                  }
                : { user_id: data.id },
        );

        if (resp?.data) {
            if (areaId) {
                const { data: respPeopleTaskArea } = await createPeopleTaskArea(
                    {
                        areaId: areaId,
                        taskId: id,
                        peopleId: resp.data.id,
                    },
                );
                addNewPeopleTaskToState(
                    formatterIndexPeopleTaskArea(respPeopleTaskArea.data),
                );
            } else {
                const { data: respPeopleTask } = await createPeopleTask({
                    task_id: id,
                    people_id: resp.data.id,
                });
                addNewPeopleTaskToState(
                    formatterIndexPeopleTask(respPeopleTask.data),
                );
            }
            addNewPeopleToState(resp.data);
        }
    };

    const addNewPersonToTask = async (idNewPerson: number) => {
        const { data: resp } = areaId
            ? await createPeopleTaskArea({
                  areaId: areaId,
                  taskId: id,
                  peopleId: idNewPerson,
              })
            : await createPeopleTask({ task_id: id, people_id: idNewPerson });
        if (areaId)
            addNewPeopleTaskToState(formatterIndexPeopleTaskArea(resp.data));
        else addNewPeopleTaskToState(formatterIndexPeopleTask(resp.data));

        if (resp) {
            const currentPeopleTasks = form.getValues("peopleTasks");

            if (currentPeopleTasks?.length) {
                const updatedPeopleTasks = currentPeopleTasks.filter(
                    (peopleTask: Type_extended_peopleTask) =>
                        peopleTask.id !== idNewPerson,
                );
                form.setValue("peopleTasks", updatedPeopleTasks);
            }
        }
    };

    const removePersonToTask = (peopleTaskId: number) => {
        if (areaId) {
            removeTaskAreaPeople(peopleTaskId);
        } else {
            removePeopleTask(peopleTaskId);
        }
    };

    const sortedPeopleTasks: Type_extended_peopleTask[] = useMemo(() => {
        return sortData(peopleTasks, "firstName");
    }, [peopleTasks]);

    ///////////////////////////////////////
    ///         Menus DropDown          ///
    ///////////////////////////////////////

    const menuItemsAssigned = useMemo(
        () => [
            {
                label: fmtActions("Remove"),
                icon: {
                    name: "empty-set",
                    variant: "regular",
                },
                call: (_id: number, peopleTask: Type_extended_peopleTask) =>
                    removePersonToTask(peopleTask.peopleTaskId),
            },
        ],
        [],
    );

    const menuItemsNotAssigned = useMemo(
        () => [
            {
                label: fmtActions("Assign"),
                icon: {
                    name: "plus",
                    variant: "solid",
                },
                call: (id: number) => {
                    addNewPersonToTask(id);
                },
            },
        ],
        [],
    );

    const menuCanEdit = useMemo(
        () => [
            {
                label: fmtActions("Update"),
                icon: {
                    name: "pen",
                    variant: "regular",
                },
                call: (_id: number, user: any) =>
                    sendEvent("updatePeople", {
                        open: true,
                        action: "update",
                        id: user.id,
                    }),
            },
        ],
        [],
    );

    return (
        <FormProvider {...form}>
            <Form>
                <Header alignItems={"stretch"}>
                    <HeaderToolbar onClose={onClose} />
                    <Typography>{fmt("Title")}</Typography>
                </Header>
            </Form>
            <Stack gap={2} display={"flex"} flexGrow={1} sx={{ paddingX: 4 }}>
                <Stack
                    spacing={2}
                    direction="row"
                    sx={{ paddingX: 2, paddingTop: 2 }}
                >
                    <AutocompletePeopleTasks
                        autoFocus={focus}
                        name="peopleTasks"
                        data={assignableUsers}
                        placeholder={fmt("Placeholder")}
                        createNewPeople={addNewPeopleTask}
                        updatePeople={updatePeople}
                    />
                    <TMC_Button
                        data-testid={`people-task-form-add-new-people-task-btn`}
                        variant="contained"
                        onClick={addNewPeopleToTask}
                        disabled={!selectedPoeple?.length || isLoading}
                        style={{ height: "auto" }}
                    >
                        {isLoading ? <Spinner /> : fmtActions("Assign")}
                    </TMC_Button>
                </Stack>
                {isFetchingSelectProjectUsers ||
                isFetchingPeopleTasks ||
                isFetchingPeopleTaskArea ? (
                    <FullSpinner />
                ) : !people?.length && !peopleTasks?.length ? (
                    <Box sx={{ paddingX: 2 }}>
                        <Empty
                            message={fmt("EmptyPeopleTaskMessage")}
                            icon={true}
                            dataTestIdRef={
                                "EmptyPeopleTask-contextualDrawer-no-data"
                            }
                        />
                    </Box>
                ) : (
                    <Stack>
                        <Typography
                            variant="h5"
                            color="text.secondary"
                            sx={{ paddingY: 2, paddingX: 4 }}
                            data-testid="people-task-form-subtitle-assigned"
                        >
                            {`${fmt("Assigned")} (${peopleTasks?.length})`}
                        </Typography>
                        {sortedPeopleTasks?.length ? (
                            sortedPeopleTasks.map(
                                (personTask: Type_extended_peopleTask) => (
                                    <UserSmallCard
                                        canHover
                                        key={`people-task-form-people-assigned-${personTask.id}`}
                                        id={personTask.id}
                                        firstName={personTask?.firstName}
                                        lastName={personTask?.lastName}
                                        email={personTask?.email}
                                        badgeLabel={
                                            !hasKey(personTask, "userId") ||
                                            personTask?.userId
                                                ? undefined
                                                : fmtExt("Ext")
                                        }
                                        actionsChildren={
                                            <Styled_Stack_buttonsGroup
                                                className={
                                                    "buttonGroup-peopleTask"
                                                }
                                            >
                                                <DropdownMenu
                                                    menuItems={[
                                                        ...menuItemsAssigned,
                                                        ...(personTask?.userId
                                                            ? []
                                                            : menuCanEdit),
                                                    ]}
                                                    props={personTask}
                                                    id={personTask.peopleTaskId}
                                                />
                                            </Styled_Stack_buttonsGroup>
                                        }
                                        sx={sx}
                                    />
                                ),
                            )
                        ) : (
                            <Box sx={{ paddingX: 2 }}>
                                <Empty
                                    dataTestIdRef={
                                        "EmptyPeopleTask-contextualDrawer-no-assigned"
                                    }
                                    message={fmt("EmptyPeopleTaskMessage")}
                                />
                            </Box>
                        )}
                        {!!peopleNotAssigned?.length && (
                            <Typography
                                variant="h5"
                                color="text.secondary"
                                sx={{ paddingY: 2, paddingX: 4 }}
                                data-testid="people-task-form-subtitle-not-assigned"
                            >
                                {`${fmt("NotAssigned")} (${peopleNotAssigned?.length})`}
                            </Typography>
                        )}
                        {peopleNotAssigned?.map(
                            (person: Type_select_people) => (
                                <UserSmallCard
                                    canHover
                                    key={`person-${person.id}`}
                                    id={person.id}
                                    firstName={person?.firstName}
                                    lastName={person?.lastName}
                                    email={person?.email}
                                    badgeLabel={
                                        !hasKey(person, "userId") ||
                                        person?.userId
                                            ? undefined
                                            : fmtExt("Ext")
                                    }
                                    actionsChildren={
                                        <Styled_Stack_buttonsGroup
                                            className={"buttonGroup-peopleTask"}
                                        >
                                            <IconButton
                                                size="small"
                                                onClick={() =>
                                                    addNewPersonToTask(
                                                        person.id,
                                                    )
                                                }
                                                disabled={isLoading}
                                            >
                                                <Icon
                                                    variant={"solid"}
                                                    icon={"plus"}
                                                />
                                            </IconButton>
                                            <DropdownMenu
                                                menuItems={[
                                                    ...menuItemsNotAssigned,
                                                    ...(person?.userId
                                                        ? []
                                                        : menuCanEdit),
                                                ]}
                                                id={person.id}
                                                props={person}
                                            />
                                        </Styled_Stack_buttonsGroup>
                                    }
                                    sx={sx}
                                />
                            ),
                        )}
                    </Stack>
                )}
            </Stack>
        </FormProvider>
    );
};
