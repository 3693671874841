import { Box, BoxProps, Button, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { createContext, ReactNode, useContext, useState } from "react";

import { TMC_Button } from "src/components";
import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { AreaContextualDrawer } from "src/drawers/areas/AreaContextualDrawer";
import { AreasContextualDrawer } from "src/drawers/areas/AreasContextualDrawer";
import { TaskAreaLinkContextualDrawer } from "src/drawers/links/TaskAreaLinkContextualDrawer";
import { TaskLinkContextualDrawer } from "src/drawers/links/TaskLinkContextualDrawer";
import { ObservationContextualDrawer } from "src/drawers/observations/ObservationContextualDrawer";
import { PeopleTaskContextualDrawer } from "src/drawers/peopleTask/PeopleTaskContextualDrawer";
import { PlanningFiltersContextualDrawer } from "src/drawers/planningFilters/PlanningFiltersContextualDrawer";
import { PlanningSettingsContextualDrawer } from "src/drawers/planningSettings/PlanningSettingsContextualDrawer";
import { ProductTypeTaskContextualDrawer } from "src/drawers/productTypeTask/ProductTypeTaskContextualDrawer";
import { ResourceTaskContextualDrawer } from "src/drawers/resources/ResourceTaskContextualDrawer";
import { SequenceContextualDrawer } from "src/drawers/sequences/SequenceContextualDrawer/SequenceContextualDrawer";
import { SequenceFolderContextualDrawer } from "src/drawers/sequences/SequenceFolderContextualDrawer/SequenceFolderContextualDrawer";
import { SequencesContextualDrawer } from "src/drawers/sequences/SequencesContextualDrawer/SequencesContextualDrawer";
import { TaskContextualDrawer } from "src/drawers/task/TaskContextualDrawer/TaskContextualDrawer";
import { TaskAreaContextualDrawer } from "src/drawers/taskArea/TaskAreaContextualDrawer/TaskAreaContextualDrawer";
import { TaskNotesContextualDrawer } from "src/drawers/taskNotes/TaskNotesContextualDrawer";
import {
    Type_event_message,
    Type_event_sequence,
    useChannel,
} from "src/hooks/useChannel";
import { COLORS } from "src/theme/stylesheet";

export const Styled_PaperContextualLayout = styled(Box)(({ theme }) => ({
    zIndex: 1,
    width: 450,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(0),
    backgroundColor: theme.palette.background.paper,
    borderLeft: theme.border.default,
}));

//////////////////////////////////////////////
///     STYLED                             ///
//////////////////////////////////////////////

/// HEADER
type Type_Header_BoxProps = {
    ownerState?: {
        borderBottom: boolean;
    };
};

export const Header = styled(Box)<Type_Header_BoxProps & BoxProps>(
    ({ theme, ownerState }) => ({
        color: theme.palette.text.primary,
        padding: theme.spacing(2),
        ...(ownerState?.borderBottom && {
            boxShadow: `0px -1px 0px 0px ${COLORS.moonAlpha200} inset`,
        }),
        display: "flex",
        flexDirection: "column",
        alignSelf: "stretch",
        gap: theme.spacing(4),
    }),
);

export const HeaderMain = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    alignSelf: "stretch",
}));

export const HeaderRow = styled(Box)(() => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "stretch",
    height: "24px",
}));

export const HeaderRowFirst = styled(HeaderRow)(() => ({
    height: "32px",
}));

export const FirstItem = styled(Box)(() => ({
    width: "48px",
    display: "flex",
    justifyContent: "center",
}));

export const Item = styled(Box)(() => ({
    flexGrow: 1,
}));

export const LastItem = styled(Box)(() => ({
    minWidth: "24px",
    display: "flex",
    justifyContent: "center",
}));

/// CONTENT
export const Form = styled("form")(() => ({
    display: "flex",
    flexDirection: "column",
    height: "100%",
}));

export const Content = styled(Box)(() => ({
    overflowY: "auto",
    alignSelf: "stretch",
    height: "100%",
}));

export const Section = styled(Box)(({ theme }) => ({
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignSelf: "stretch",
    rowGap: theme.spacing(4),
}));

export const Row = styled(Stack)(() => ({
    alignItems: "center",
    alignSelf: "stretch",
    "& > *:nth-of-type(2)": {
        flexGrow: 1, // ou la valeur que vous souhaitez
    },
}));

Row.defaultProps = {
    direction: "row",
};

export const RowIcon = styled(Box)(({ theme }) => ({
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(2),
}));

// TODO move to app/src/components/Components_Teamoty/lists/Sequences ?

const Styled_Header = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    borderBottom: theme.border.default,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
}));

export const SequencesList = ({
    id,
    handleSegmentClick,
}: {
    id: number;
    handleSegmentClick: (sequenceId: number) => void;
}) => {
    const { closePaper } = useContextualDrawer();

    const [event, setEvent] = useState<Type_event_message>({ event: "" });
    const { sendEvent } = useChannel({
        eventHandler: setEvent,
    });

    return (
        <Styled_PaperContextualLayout id="testSequencesList">
            <Styled_Header>
                <Typography variant="h5">Sequences</Typography>
                <IconButton
                    size="small"
                    onClick={() => closePaper("sequences")}
                >
                    <Icon variant="light" icon="xmark" />
                </IconButton>
            </Styled_Header>
            je suis sequence List {id} : <br /> {event.event}{" "}
            {(event?.data as Type_event_sequence)?.id}{" "}
            <Button onClick={() => sendEvent("test seq", { id: Date.now() })}>
                event
            </Button>
            <TMC_Button onClick={() => handleSegmentClick(1)}>
                Ouvrir une sequence
            </TMC_Button>
        </Styled_PaperContextualLayout>
    );
};

export const SequenceDetail = ({ value }: { value: number }) => {
    const { closePaper } = useContextualDrawer();
    return (
        <Styled_PaperContextualLayout id="testSequenceDetail">
            <Button onClick={() => closePaper("sequence")}>close</Button>
            je suis sequence detail {value}
        </Styled_PaperContextualLayout>
    );
};

export const Trade = ({ value }: { value: number }) => {
    const { closePaper } = useContextualDrawer();
    return (
        <Styled_PaperContextualLayout id="testTrades">
            <Button onClick={() => closePaper("trade")}>close</Button>
            je suis trade {value}
        </Styled_PaperContextualLayout>
    );
};

const papers = {
    sequences: SequencesContextualDrawer,
    sequence: SequenceContextualDrawer,
    sequenceFolder: SequenceFolderContextualDrawer,
    areas: AreasContextualDrawer,
    area: AreaContextualDrawer,
    trade: Trade,
    task: TaskContextualDrawer,
    taskArea: TaskAreaContextualDrawer,
    link: TaskLinkContextualDrawer,
    taskAreaLink: TaskAreaLinkContextualDrawer,
    resource: ResourceTaskContextualDrawer,
    peopleTask: PeopleTaskContextualDrawer,
    productType: ProductTypeTaskContextualDrawer,
    planningSettings: PlanningSettingsContextualDrawer,
    observation: ObservationContextualDrawer,
    taskNotes: TaskNotesContextualDrawer,
    planningFilters: PlanningFiltersContextualDrawer,
};

type Type_context_Papers = {
    key: string;
    paper: any;
    props: { value: number };
};

export type Type_context_contextualDrawer = {
    value: Type_context_Papers[];
    updateValue: (newValue: any[]) => void;
    openPaper: (key: string, props: any, mustCloseOthers?: boolean) => void;
    togglePaper: (key: string, props: any, mustCloseOthers?: boolean) => void;
    closePaper: (key: string) => void;
};

type Type_Props_OpenPaper = {
    id: string;
    onClose?: () => void;
    readonly?: boolean;
};

const Context_ContextualDrawer = createContext<Type_context_contextualDrawer>({
    value: [],
    updateValue: () => {},
    openPaper: () => {},
    togglePaper: () => {},
    closePaper: () => {},
});

export const useContextualDrawer = () => {
    const context = useContext(Context_ContextualDrawer);
    if (!context) {
        throw new Error(
            "useTheme must be used within a Provider_ContextualDrawer",
        );
    }
    return context;
};

const CLOSE_ALL_OPENED_DRAWERS = "CLOSE_ALL";

type Type_Props_Provider_ContextualDrawer = {
    children: ReactNode;
};

export const Provider_ContextualDrawer = ({
    children,
}: Type_Props_Provider_ContextualDrawer) => {
    const [value, updateValue] = useState<any[]>([]);

    const closePaper = (key: string) => {
        if (key === CLOSE_ALL_OPENED_DRAWERS) updateValue([]);
        else
            updateValue((prevPapers: any[]) => {
                const index = prevPapers.findIndex((elem) => elem.key === key);
                index >= 0 ? prevPapers.splice(index, 1) : prevPapers;
                return [...prevPapers];
            });
    };

    const openPaper = (
        key: any,
        props: Type_Props_OpenPaper,
        mustCloseOthers?: boolean,
    ) => {
        mustCloseOthers && closePaper(CLOSE_ALL_OPENED_DRAWERS);

        if (props.onClose === undefined) {
            props = {
                ...props,
                onClose: () => closePaper(key),
            };
        }

        updateValue((prevPapers: any[]) => {
            const update = prevPapers.find((elem) => elem.key === key);
            if (update) {
                update.key = key;
                update.props = props;
            } else {
                prevPapers.push({
                    paper: papers[key as keyof typeof papers],
                    key: key,
                    props: props,
                });
            }
            return [...prevPapers];
        });
    };

    const isPaperOpen = (paper: string): boolean => {
        return value.some((elem) => elem.key === paper);
    };

    const togglePaper = (
        paper: string,
        props: Type_Props_OpenPaper,
        mustCloseOthers?: boolean,
    ) => {
        isPaperOpen(paper)
            ? closePaper(paper)
            : openPaper(paper, props, mustCloseOthers);
    };

    const valeurContexte = {
        value: value,
        updateValue: updateValue,
        openPaper: openPaper,
        closePaper: closePaper,
        togglePaper: togglePaper,
    };

    return (
        <Context_ContextualDrawer.Provider value={valeurContexte}>
            {children}
        </Context_ContextualDrawer.Provider>
    );
};
