import { Type_index_peopleTask, Type_sch_index_peopleTask } from "./types";

export const formatterIndexPeopleTasks = (
    data: Type_sch_index_peopleTask[],
): Type_index_peopleTask[] => {
    return data.map((peopleTask: Type_sch_index_peopleTask) =>
        formatterIndexPeopleTask(peopleTask),
    );
};

export const formatterIndexPeopleTask = (
    peopleTask: Type_sch_index_peopleTask,
): Type_index_peopleTask => {
    return {
        id: peopleTask.people.id,
        peopleTaskId: peopleTask.id,
        userId: peopleTask.people.user_id,
        firstName: peopleTask.people.firstName,
        lastName: peopleTask.people.lastName,
        email: peopleTask.people.email,
    };
};
