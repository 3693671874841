import { Dayjs } from "dayjs";

import { Type_com_selectList_company } from "src/api/tms-commons/companies/types";
import { Type_prj_index_subTrade } from "src/api/tms-projects/subTrades/types";

export enum ConstraintStatus {
    TO_DO = 1,
    IN_PROGRESS = 2,
    RESOLVED = 3,
    CANCELED = 4,
}

//API
export type Type_sch_index_constraint = {
    id: number;
    description: string;
    taskNumber: number;
    area_id: number;
    subTrade: Type_prj_index_subTrade | null;
    company: Type_com_selectList_company | null;
    status: ConstraintStatus;
    author: {
        firstName: string;
        lastName: string;
        email: string;
    };
    date: string;
    priority: {
        id: number;
        name: string;
        color: string;
        sortOrder: number;
        enabled: boolean;
    };
};

export type Type_sch_post_constraint = {
    description: string;
    taskNumber: number;
    area_id: number;
    subTrade_id: number | null;
    company_id: number | null;
    status: ConstraintStatus;
    date: string;
    priority: number;
};

export type Type_sch_put_constraint = {
    description?: string;
    taskNumber?: number;
    area_id?: number;
    subTrade_id?: number | null;
    company_id?: number | null;
    status?: ConstraintStatus;
    date?: string;
    priority?: number;
};

//FRONT
export type Type_index_constraint = {
    id: number;
    description: string;
    taskNumber: number;
    areaId: number;
    subTrade: Type_prj_index_subTrade | null;
    company: Type_com_selectList_company | null;
    status: ConstraintStatus;
    author: {
        firstName: string;
        lastName: string;
        email: string;
    };
    date: Dayjs;
    priority: number;
};

export type Type_show_constraint = {
    id: number;
    description?: string;
    taskNumber?: number;
    areaId?: number;
    subTradeId?: number | null;
    companyId?: number | null;
    status?: ConstraintStatus;
    author: {
        firstName: string;
        lastName: string;
        email: string;
    };
    date?: Dayjs;
    priority?: number;
};

export type Type_post_constraint = {
    description: string;
    taskNumber: number;
    areaId: number;
    subTradeId: number | null;
    companyId: number | null;
    status: ConstraintStatus;
    date?: Dayjs;
    priority: number;
};

export type Type_put_constraint = {
    description?: string;
    taskNumber?: number;
    areaId?: number;
    subTrade?: Type_prj_index_subTrade | null;
    companyId?: number | null;
    status?: ConstraintStatus;
    date?: Dayjs;
    priority?: number;
};

export type Type_defautlConstraintParams = {
    taskNumber: number;
    areaId: number;
    priority: number;
};
