import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";

import { useIndexAreas } from "src/api/tms-projects/areas";
import {
    mutationCreateSequenceArea,
    mutationDeleteSequenceArea,
    useIndexSequenceAreas,
} from "src/api/tms-scheduling/sequenceAreas";
import { Type_index_sequenceArea } from "src/api/tms-scheduling/sequenceAreas/types";
import { LoadingBox, TMC_Button } from "src/components/Components_Common";
import { TableAreasFullScreen } from "src/components/Components_Teamoty/tables/TableAreas/TableAreasFullScreen";
import { AreasTreeView } from "src/components/Components_Teamoty/trees/AreasTreeView";
import { useChannel } from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_AreasSelection = {
    sequenceId: number;
};

export const AreasSelection = ({ sequenceId }: Type_Props_AreasSelection) => {
    const { sendEvent } = useChannel({});
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerSequenceAreas",
    );

    // States
    const [checkedAreas, setCheckedAreas] = useState<string[]>([]);
    const [sequenceAreas, setSequenceAreas] = useState<
        Type_index_sequenceArea[]
    >([]);
    const [prevCheckedAreas, setPrevCheckedAreas] = useState<string[]>([]);
    const [fullScreenModal, setFullScreenModal] = useState<any>(false);
    const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);

    // Queries
    const { isFetching: isFetchingAreas, data: fetchedAreas } = useIndexAreas();

    const { data: sequenceAreasData, isFetching: isFetchingSequenceAreas } =
        useIndexSequenceAreas({ sequence_id: sequenceId });

    // Mutations
    const { mutateAsync: createSequenceArea } = mutationCreateSequenceArea(
        (createdSequenceArea) => {
            setSequenceAreas((prevSequenceAreas) => [
                ...prevSequenceAreas,
                createdSequenceArea,
            ]);
        },
    );
    const { mutateAsync: deleteSequenceArea } = mutationDeleteSequenceArea(
        (id) => {
            setSequenceAreas((prevSequenceAreas) => [
                ...prevSequenceAreas.filter(
                    (sequenceArea) => sequenceArea.id !== id,
                ),
            ]);
        },
    );

    const isFetching = useMemo(
        () => isFetchingAreas || isFetchingSequenceAreas,
        [isFetchingAreas, isFetchingSequenceAreas],
    );

    useEffect(() => {
        if (sequenceAreasData) {
            setSequenceAreas(sequenceAreasData);
            const initialCheckedAreas = sequenceAreasData.map((area) =>
                String(area.area_id),
            );
            setCheckedAreas(initialCheckedAreas);
            setPrevCheckedAreas(initialCheckedAreas); // Set the previous checked areas state
            setIsInitialLoad(false); // Mark the initial load as complete
        }
    }, [sequenceAreasData]);

    useEffect(() => {
        if (!isInitialLoad) {
            const added = checkedAreas.filter(
                (areaId) => !prevCheckedAreas.includes(areaId),
            );
            const removed = prevCheckedAreas.filter(
                (areaId) => !checkedAreas.includes(areaId),
            );

            const createPromises = added.map(async (areaId) => {
                await createSequenceArea({
                    sequence_id: sequenceId,
                    area_id: Number(areaId),
                });
            });

            const deletePromises = removed.map((areaId) => {
                const areaToDelete = sequenceAreas.find(
                    (item) => item.area_id === +areaId,
                );
                return areaToDelete
                    ? deleteSequenceArea(areaToDelete.id)
                    : Promise.resolve();
            });

            Promise.all([...createPromises, ...deletePromises]).then(() => {
                sendEvent("updatePlanningAreas");
            });

            setPrevCheckedAreas(checkedAreas);
        }
    }, [checkedAreas]);

    return (
        <>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                p={2}
                mb={2}
            >
                <Typography variant="h5" pl={2}>
                    {fmt("AreasSelection")}
                </Typography>
                <TMC_Button
                    data-testid="Matrix-fullScreen-btn"
                    onClick={() => setFullScreenModal(true)}
                    variant="text"
                >
                    {fmt("CustomOrder")}
                </TMC_Button>
            </Stack>
            {isFetching ? (
                <LoadingBox />
            ) : (
                <Box>
                    <AreasTreeView
                        areas={fetchedAreas || []}
                        selectedAreas={checkedAreas}
                        setSelectedAreas={setCheckedAreas}
                        isAllExpanded
                    />
                </Box>
            )}
            <TableAreasFullScreen
                openDialog={fullScreenModal}
                setToggleDialog={setFullScreenModal}
            />
        </>
    );
};
