import * as React from "react";
import { useState } from "react";
import { useQueryClient } from "react-query";

import { mutationDeleteConstraint } from "src/api/tms-scheduling/constraints";
import { Type_index_constraint } from "src/api/tms-scheduling/constraints/types";
import {
    DELETE,
    ModalDelete,
} from "src/components/Components_Common/ModalDelete/ModalDelete";
import { HeaderToolbar } from "src/drawers/task/TaskContextualDrawer/HeaderToolbar";
import { ObservationCommentsForm } from "src/forms/observationComments/ObservationCommentsForm";
import { ObservationCommentsList } from "src/forms/observationComments/ObservationCommentsList";
import { ObservationForm } from "src/forms/observations/ObservationForm";
import { useChannel } from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import {
    Header,
    Styled_PaperContextualLayout,
    useContextualDrawer,
} from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

export type Type_modalDeleteConstraint = {
    isOpen: boolean;
    id?: number;
};

type Type_Props_ObservationContextualDrawer = {
    constraint: Type_index_constraint;
};

export const ObservationContextualDrawer = ({
    constraint,
}: Type_Props_ObservationContextualDrawer) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerTasks.Observations",
    );
    const { closePaper } = useContextualDrawer();
    const [modalDelete, setModalDelete] = useState<Type_modalDeleteConstraint>({
        isOpen: false,
    });
    const { sendEvent } = useChannel({});
    const { taskNumber } = constraint;

    const callbackDelete = () => {
        closePaper("observation");
        sendEvent("deleteObservation", modalDelete?.id);
        sendEvent("changeFlagObservations", -1);
    };
    const { mutateAsync: deleteConstraint, isLoading } =
        mutationDeleteConstraint(callbackDelete);
    const queryClient = useQueryClient();

    const actions = {
        onSubmit: {
            onClick: async () => {
                // Cancel all ongoing queries
                queryClient.cancelQueries();
                setModalDelete({ isOpen: false });
                await deleteConstraint(modalDelete?.id as number);
            },
        },
    };

    return (
        <Styled_PaperContextualLayout
            id="observation"
            data-testid="ObservationContextualDrawer"
        >
            <Header alignItems={"stretch"}>
                <HeaderToolbar
                    onClose={() => closePaper("observation")}
                    taskId={taskNumber}
                    showNotesIcon={false}
                />
            </Header>
            <ObservationForm
                constraint={constraint}
                formId="observation-form-create-update"
                setModalDelete={setModalDelete}
                isLoading={isLoading}
            />
            <ObservationCommentsList />
            <ObservationCommentsForm taskId={taskNumber} />
            <ModalDelete
                open={modalDelete.isOpen}
                onClose={() => setModalDelete({ isOpen: false })}
                item={"Observation"}
                validationString={DELETE}
                descriptionString={fmt("DeleteDescriptionString")}
                actions={actions}
            />
        </Styled_PaperContextualLayout>
    );
};
