import { Stack, Typography } from "@mui/material";
import React from "react";

import { Alert, TMC_Button } from "src/components";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { Type_formData_NewUser_Invitation } from "src/pages/Pages_Common/Page_NewUser_Invitation/Page_NewUser_Invitation.container";
import { COLORS } from "src/theme/stylesheet";

export type TypeComponentProps = {
    formData: Type_formData_NewUser_Invitation;
    handleLogin: () => void;
};

export const Step_6Component = ({
    formData,
    handleLogin,
}: TypeComponentProps) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Teamoty.NewUserInvitation",
    );

    return (
        <Stack width="400px" height="800px" justifyContent="space-between">
            <Stack rowGap="24px" width={"100%"}>
                <Typography variant="body2" color={COLORS.moon600}>
                    {fmt("Step6.Step")}
                </Typography>
                <Typography
                    variant="h1"
                    data-testid="UsersInvitation-FinalStep-Title"
                >
                    {fmt("Step6.Title")}
                </Typography>
                <Alert status="info">
                    {fmt("Step6.Alert", {
                        values: {
                            email: formData.email,
                        },
                    })}
                </Alert>
            </Stack>
            <TMC_Button
                data-testid="UsersInvitation-FinalStep-BackToLoginPage-btn"
                onClick={handleLogin}
                variant={"text"}
                style={{ color: COLORS.blue700 }}
            >
                <Typography variant={"body3"} color={COLORS.blue700}>
                    {fmt("Step6.Cta")}
                </Typography>
            </TMC_Button>
        </Stack>
    );
};
