import { Grid, Stack, Typography, useTheme } from "@mui/material";
import React from "react";

import { Type_show_company } from "src/api/tms-commons/companies/types";
import { Type_me } from "src/api/tms-gateway/auth/types";
import { Avatar, Empty, LoadingBox } from "src/components";
import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import {
    Styled_Section,
    Styled_SectionHead,
} from "src/pages/Pages_Teamoty/MyAccount/Page_MyProfile/Page_MyProfile.style";

type Type_InfoRow = {
    label: string;
    value?: string;
};

// --- Utils functions for render
const displayValue = (value?: string): string => value || "-";

const renderInfoRow = (label: string, value?: string) => {
    const theme = useTheme();
    return (
        <React.Fragment key={label + "" + Math.random().toFixed(1)}>
            <Grid item xs={12} md={3}>
                <Typography
                    variant="body1"
                    color={theme.palette.text.secondary}
                >
                    {label}
                </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
                <Typography variant="body1">{displayValue(value)}</Typography>
            </Grid>
        </React.Fragment>
    );
};

export type Type_Props_Page_Page_MyProfileComponent = {
    user: Type_me | null;
    fullName: string;
    company?: Type_show_company;
    isFetchingCompany: boolean;
    handleOpenDrawer: (step: string) => void;
};

export const Page_MyProfileComponent = ({
    user,
    fullName,
    company,
    isFetchingCompany,
    handleOpenDrawer,
}: Type_Props_Page_Page_MyProfileComponent) => {
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("MyAccount.Profile");
    const theme = useTheme();

    // ---- CONSTANTS
    const hiddenPassword: string = "············";

    const userInfos: Type_InfoRow[] = [
        {
            label: fmt("Informations.Form.Firstname"),
            value: user?.firstName,
        },
        {
            label: fmt("Informations.Form.Lastname"),
            value: user?.lastName,
        },
        {
            label: fmt("Informations.Form.Email"),
            value: user?.email,
        },

        {
            label: fmt("Informations.Form.Phone"),
            value: user?.phone,
        },
    ];

    const companyInfos: Type_InfoRow[] = [
        {
            label: fmt("Company.Form.CompanyName"),
            value: company?.name,
        },
        {
            label: fmt("Company.Form.NationalID"),
            value: company?.nationalId || "",
        },
        {
            label: fmt("Company.Form.ZIPcode"),
            value: company?.postalCode,
        },
        {
            label: fmt("Company.Form.City"),
            value: company?.city,
        },
        {
            label: fmt("Company.Form.Country"),
            value: company?.country,
        },
    ];

    // ---- RENDER UTILS
    const renderInfos = (data: Type_InfoRow[]) =>
        data.map((item: Type_InfoRow) => renderInfoRow(item.label, item.value));

    return (
        <>
            {user ? (
                <Stack gap={6} sx={{ width: "50vw", maxWidth: "100%" }}>
                    <Styled_Section data-testid="section-informations">
                        <Styled_SectionHead>
                            <Typography variant="h3">
                                {fmt("Informations.Title")}
                            </Typography>
                            <IconButton
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: theme.spacing(1.5),
                                }}
                                data-testid="MyProfile_Edit_Informations"
                                onClick={() => handleOpenDrawer("1")}
                                color="primary"
                            >
                                <Typography variant="body2Medium">
                                    {fmt("Edit")}
                                </Typography>
                            </IconButton>
                        </Styled_SectionHead>
                        <Avatar
                            name={fullName}
                            sx={{
                                width: 96,
                                height: 96,
                                textTransform: "uppercase",
                            }}
                        />
                        <Grid container rowSpacing={2}>
                            {renderInfos(userInfos)}
                        </Grid>
                    </Styled_Section>
                    <Styled_Section data-testid="section-company">
                        <Styled_SectionHead>
                            <Typography variant="h3">
                                {fmt("Company.Title")}
                            </Typography>
                            <IconButton
                                disabled={isFetchingCompany || !company}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: theme.spacing(1.5),
                                }}
                                data-testid="MyProfile_Edit_Company"
                                color="primary"
                                onClick={() => handleOpenDrawer("2")}
                            >
                                <Typography variant="body2Medium">
                                    {fmt("Edit")}
                                </Typography>
                            </IconButton>
                        </Styled_SectionHead>
                        {isFetchingCompany ? (
                            <LoadingBox />
                        ) : company ? (
                            <Grid container rowSpacing={2}>
                                {renderInfos(companyInfos)}
                            </Grid>
                        ) : (
                            <Empty
                                message={fmt("NoData")}
                                icon={true}
                                dataTestIdRef="MyProfile-company-no-data"
                            />
                        )}
                    </Styled_Section>

                    <Styled_Section data-testid="section-security">
                        <Styled_SectionHead>
                            <Typography variant="h3">
                                {fmt("Security.Title")}
                            </Typography>
                            <IconButton
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: theme.spacing(1.5),
                                }}
                                data-testid="MyProfile_Edit_Password"
                                color="primary"
                                onClick={() => handleOpenDrawer("3")}
                            >
                                <Typography variant="body2Medium">
                                    {fmt("Security.Form.ChangePassword")}
                                </Typography>
                            </IconButton>
                        </Styled_SectionHead>

                        <Grid container rowSpacing={2}>
                            {renderInfoRow(
                                fmt("Security.Form.Password"),
                                hiddenPassword,
                            )}
                        </Grid>
                    </Styled_Section>
                </Stack>
            ) : (
                <Empty
                    message={fmt("NoData")}
                    icon={true}
                    dataTestIdRef="MyProfile-user-no-data"
                />
            )}
        </>
    );
};
