import { FormikValues } from "formik";

import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    PutReturnType,
    remove,
    RemoveReturnType,
} from "src/api/fetch";
import { LicensesPath, ProjectPath, Url } from "src/api/paths";

import { Type_com_put_license } from "./types";

export const indexLicenses = (): GetReturnType => {
    return get(`${Url.COMMONS}${LicensesPath.LICENSES}`);
};

export const showLicense = (id: number): GetReturnType => {
    return get(`${Url.COMMONS}${LicensesPath.LICENSES}/${id}`);
};

export const updateLicense = (
    id: number,
    data: Type_com_put_license,
): PutReturnType => {
    return put(data, `${Url.COMMONS}${LicensesPath.LICENSES}/${id}`);
};

export const deleteLicense = (id: number): RemoveReturnType => {
    return remove({ id }, `${Url.COMMONS}${LicensesPath.LICENSES}/${id}`);
};

export const createLicense = (
    data: FormikValues,
    projectIdToUpdate: number,
): PostReturnType => {
    return post(
        data,
        `${Url.COMMONS}${ProjectPath.PROJECTS}/${projectIdToUpdate}/${LicensesPath.LICENSES}`,
    );
};
