import { Stack, Typography } from "@mui/material";
import { FormikProvider } from "formik";
import React from "react";

import { Spinner, TMC_Button, TMC_TextField } from "src/components";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { Styled_Form } from "src/pages/Pages_Common/Page_NewUser_Invitation/Page_NewUser_Invitation.style";
import { COLORS } from "src/theme/stylesheet";

export type Type_CreateCompanyComponentProps = {
    step: number;
    formik: any;
    maxStep: number;
    handleCompanyStep: (
        stateAction: "previous" | "next",
        resetFormData: boolean,
    ) => void;
};

export const CreateCompanyComponent = ({
    step,
    formik,
    maxStep,
    handleCompanyStep,
}: Type_CreateCompanyComponentProps) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Teamoty.NewUserInvitation",
    );
    const { formatMessageWithPartialKey: fmtCta } = useCoreIntl("Form.Cta");
    return (
        <FormikProvider value={formik}>
            <Styled_Form data-testid={`UsersInvitation-step-${step}-form`}>
                <Stack rowGap={6}>
                    <Stack rowGap={"8px"} width={"100%"}>
                        <Typography
                            variant="body2"
                            color={COLORS.moon600}
                            data-testid={`UsersInvitation-step-${step}-of-${maxStep}`}
                        >
                            {fmt("Steps", {
                                values: {
                                    currentStep: step,
                                    maxStep: maxStep,
                                },
                            })}
                        </Typography>
                        <Typography
                            variant="h1"
                            data-testid={`UsersInvitation-step-${step}-title`}
                        >
                            {fmt("Step4Bis.Title")}
                        </Typography>
                    </Stack>
                    <Stack rowGap={2}>
                        <TMC_TextField
                            data-testid={`UsersInvitation-step-${step}-company_name`}
                            label={fmt("Step4Bis.Label.company_name")}
                            {...formik.getFieldProps("company_name")}
                            helperText={formik.errors?.company_name as string}
                            error={!!formik.errors?.company_name}
                        />
                        <TMC_TextField
                            data-testid={`UsersInvitation-step-${step}-company_nationalId`}
                            label={fmt("Step4Bis.Label.company_nationalId")}
                            {...formik.getFieldProps("company_nationalId")}
                            helperText={
                                formik.errors?.company_nationalId as string
                            }
                            error={!!formik.errors?.company_nationalId}
                        />
                        <Typography variant="body3" color={COLORS.moon600}>
                            {fmt("Step4Bis.Info")}
                        </Typography>
                        <TMC_TextField
                            data-testid={`UsersInvitation-step-${step}-company_postalCode`}
                            label={fmt("Step4Bis.Label.company_postalCode")}
                            {...formik.getFieldProps("company_postalCode")}
                            helperText={
                                formik.errors?.company_postalCode as string
                            }
                            error={!!formik.errors?.company_postalCode}
                        />
                        <TMC_TextField
                            data-testid={`UsersInvitation-step-${step}-company_city`}
                            label={fmt("Step4Bis.Label.company_city")}
                            {...formik.getFieldProps("company_city")}
                            helperText={formik.errors?.company_city as string}
                            error={!!formik.errors?.company_city}
                        />
                        <TMC_TextField
                            data-testid={`UsersInvitation-step-${step}-company_country`}
                            label={fmt("Step4Bis.Label.company_country")}
                            {...formik.getFieldProps("company_country")}
                            helperText={
                                formik.errors?.company_country as string
                            }
                            error={!!formik.errors?.company_country}
                        />
                    </Stack>
                </Stack>

                <Stack direction={"row"} justifyContent={"space-between"}>
                    <TMC_Button
                        data-testid={`UsersInvitation-step-${step}-GoBack-btn`}
                        onClick={() => handleCompanyStep("previous", true)}
                        type="button"
                        variant="text"
                        disabled={formik.isSubmitting}
                        startIcon={
                            !formik.isSubmitting && (
                                <Icon variant="solid" icon="arrow-left" />
                            )
                        }
                        sx={{
                            width: "fit-content",
                            blockSize: "fit-content",
                            color: COLORS.blue700,
                        }}
                    >
                        {fmtCta("Back")}
                    </TMC_Button>
                    <TMC_Button
                        data-testid={`UsersInvitation-step-${step}-Submit-btn`}
                        type="submit"
                        variant="contained"
                        disabled={formik.isSubmitting}
                        endIcon={
                            !formik.isSubmitting && (
                                <Icon variant="solid" icon="arrow-right" />
                            )
                        }
                        sx={{ width: "fit-content", blockSize: "fit-content" }}
                    >
                        {formik.isSubmitting ? (
                            <Spinner style="button" />
                        ) : (
                            fmt("Step4Bis.Cta.Submit")
                        )}
                    </TMC_Button>
                </Stack>
            </Styled_Form>
        </FormikProvider>
    );
};
