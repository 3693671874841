import {
    Type_index_taskArea,
    Type_post_taskArea,
    Type_progress_taskArea,
    Type_sch_index_taskArea,
    Type_sch_post_taskArea,
    Type_sch_progress_taskArea,
    Type_sch_show_taskArea,
    Type_show_taskArea,
} from "src/api/tms-scheduling/taskArea/types";
import {
    formatDateForBack,
    formatDateForFront,
    formatDateTimeForBack,
} from "src/utils/date";

export const formatterIndexTaskAreas = (
    taskAreas: Type_sch_index_taskArea[],
): null | Type_index_taskArea[] => {
    if (!taskAreas) return null;
    return taskAreas.map(formatterIndexTaskArea);
};

export const formatterIndexTaskArea = (
    taskArea: Type_sch_index_taskArea,
): Type_index_taskArea => {
    return {
        areaId: taskArea.area_id,
        areaName: taskArea.areaName,
        areaQuantity: taskArea.areaQuantity,
        color: taskArea.color,
        companyId: taskArea.company ? taskArea.company.id : null,
        duration: taskArea.duration,
        earliestDate: formatDateForFront(taskArea.earliestDate),
        endDate: formatDateForFront(taskArea.endDate),
        id: taskArea.id,
        names: taskArea.names,
        order: taskArea.order,
        pin: taskArea.pin,
        progressLastDate: formatDateForFront(taskArea.progressLastDate),
        progressValue: taskArea.progressValue,
        sequenceName: taskArea.sequenceName,
        startDate: taskArea.startDate,
        subProject: taskArea.subProject,
        subTrade: taskArea.subTrade,
        taskId: taskArea.task_id,
        taskName: taskArea.taskName,
        team: taskArea.team,
        type: taskArea.type,
        waitingDay: taskArea.waitingDay,
        waitingDayMode: taskArea.waitingDayMode,
        withPunchlist: taskArea.withPunchlist,
        withTradeColor: taskArea.withTradeColor,
        workforce: taskArea.workforce,
    };
};

export const formatterShowTaskArea = (
    taskArea: Type_sch_show_taskArea,
): Type_show_taskArea => {
    return {
        areaId: taskArea.area_id,
        areaName: taskArea.areaName,
        areaQuantity: taskArea.areaQuantity,
        color: taskArea.color,
        companyId: taskArea.company ? taskArea.company.id : null,
        duration: taskArea.duration,
        earliestDate: formatDateForFront(taskArea.earliestDate),
        endDate: formatDateForFront(taskArea.endDate),
        id: taskArea.id,
        names: taskArea.names,
        order: taskArea.order,
        pin: taskArea.pin,
        progressLastDate: formatDateForFront(taskArea.progressLastDate),
        progressValue: taskArea.progressValue,
        sequenceName: taskArea.sequenceName,
        startDate: formatDateForFront(taskArea.startDate),
        subProject: taskArea.subProject,
        subTrade: taskArea.subTrade,
        taskId: taskArea.task_id,
        taskName: taskArea.taskName,
        team: taskArea.team,
        type: taskArea.type,
        waitingDay: taskArea.waitingDay,
        waitingDayMode: taskArea.waitingDayMode,
        withPunchlist: taskArea.withPunchlist,
        withTradeColor: taskArea.withTradeColor,
        workforce: taskArea.workforce,
    };
};

export const formatterCreateTaskArea = (
    taskArea: Type_post_taskArea,
): Type_sch_post_taskArea => {
    return {
        areaQuantity: taskArea.areaQuantity,
        company_id: taskArea.companyId,
        duration: taskArea.duration,
        earliestDate: formatDateForBack(taskArea.earliestDate),
        endDate: formatDateForBack(taskArea.endDate),
        names: taskArea.names,
        order: taskArea.order,
        pin: taskArea.pin,
        startDate: taskArea.startDate,
        team: taskArea.team,
        waitingDay: taskArea.waitingDay,
        waitingDayMode: taskArea.waitingDayMode,
        workforce: taskArea.workforce,
        progressValue: taskArea.progressValue,
    };
};

export const formatterProgressTaskArea = (
    progressTaskArea: Type_progress_taskArea,
): Type_sch_progress_taskArea => {
    return {
        progressValue: progressTaskArea.progressValue,
        progressLastDate: formatDateTimeForBack(
            progressTaskArea.progressLastDate,
        )!,
    };
};
