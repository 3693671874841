import {
    AccordionDetailsProps,
    AccordionProps,
    AccordionSummaryProps,
} from "@mui/material";
import React, { ReactElement } from "react";

import {
    Styled_Accordion,
    Styled_AccordionDetails,
    Styled_AccordionSummary,
} from "src/components/Components_Common/accordions/Accordion.style";
import { Icon } from "src/components/Components_Common/Icon/Icon";

export type ItemChildrenProps = {
    open?: boolean;
    readonly?: boolean;
};

export type Type_accordionList = Type_Props_Accordion & {
    key: string;
};

export type Type_Props_Accordion = {
    children: ReactElement<ItemChildrenProps>;
    title?: ReactElement;
    action?: ReactElement;
    open?: boolean;
    accordionSummary?: AccordionSummaryProps;
    accordionDetails?: AccordionDetailsProps;
};

export const Accordion = ({
    title,
    action,
    children,
    open,
    onChange,
    accordionSummary,
    accordionDetails,
    ...restProps
}: Type_Props_Accordion & Omit<AccordionProps, "title">) => {
    return (
        <Styled_Accordion
            expanded={open}
            onChange={onChange}
            disableGutters
            square
            elevation={0}
            {...restProps}
        >
            {title && (
                <Styled_AccordionSummary
                    {...accordionSummary}
                    expandIcon={<Icon variant="solid" icon="caret-down" />}
                >
                    {title}
                    {action}
                </Styled_AccordionSummary>
            )}
            <Styled_AccordionDetails {...accordionDetails}>
                {children}
            </Styled_AccordionDetails>
        </Styled_Accordion>
    );
};
