import { DrawerProps } from "@mui/material";
import * as React from "react";
import { useState } from "react";

import { DrawerGeneric } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { PdfAppearanceForm } from "src/forms/pdfAppearance/PdfAppearanceForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export type Type_Props_PdfAppearanceDrawer = DrawerProps & {
    open: boolean;
    onClose: () => void;
    page: string;
};

export const PdfAppearanceDrawer = ({
    open,
    onClose,
    page,
}: Type_Props_PdfAppearanceDrawer) => {
    // i18n
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.PdfAppearance",
    );

    // State
    const [isLoading, setIsLoading] = useState(false);

    // Utils
    const formId = "PdfAppearance";

    return (
        <DrawerGeneric
            open={open}
            onClose={onClose}
            dataTestIdTitle={`PdfAppearance-Drawer-Title`}
            title={fmt("Title")}
            page={page}
            isFormLoading={isLoading}
            formId={formId}
        >
            <PdfAppearanceForm
                formId={formId}
                onClose={onClose}
                setIsLoading={setIsLoading}
            />
        </DrawerGeneric>
    );
};
