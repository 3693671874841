import { useMutation, useQuery, useQueryClient } from "react-query";

import { Type_Filter_Task } from "src/api/filters";
import { TaskNoteKeys } from "src/api/tms-scheduling/keys";
import {
    formatterCreateTaskNote,
    formatterIndexTaskNotes,
    formatterUpdateTaskNote,
} from "src/api/tms-scheduling/taskNotes/formatters";
import {
    createTaskNote,
    deleteTaskNote,
    indexTaskNotes,
    updateTaskNote,
} from "src/api/tms-scheduling/taskNotes/services";
import {
    Type_post_taskNote,
    Type_sch_index_taskNote,
} from "src/api/tms-scheduling/taskNotes/types";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { Type_put_note } from "src/forms/notes/NoteForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const useIndexTaskNotes = (
    filters: Type_Filter_Task = {},
    enabled?: boolean,
) => {
    const { requestConfig } = useProject();
    return useQuery({
        enabled: enabled,
        queryKey: [TaskNoteKeys.INDEX, requestConfig, filters],
        queryFn: () => indexTaskNotes(requestConfig, filters),
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data.success || !data?.data?.data) {
                throw new Error("Wrong format data: useIndexTaskNotes");
            }
            return formatterIndexTaskNotes(
                data.data.data as Type_sch_index_taskNote[],
            );
        },
    });
};

export const mutationCreateTaskNote = (filters: Type_Filter_Task) => {
    const queryClient = useQueryClient();
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerNotes",
    );
    const { addSuccess } = useToast();

    return useMutation({
        mutationFn: (newTaskNote: Type_post_taskNote) => {
            return createTaskNote(
                formatterCreateTaskNote(newTaskNote),
                requestConfig,
            );
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries([
                TaskNoteKeys.INDEX,
                requestConfig,
                filters,
            ]);
            addSuccess({
                description: fmt("ToastSuccessCreate"),
            });
        },
        onError: (err: any) => {
            return err;
        },
    });
};

export const mutationUpdateTaskNote = () => {
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerNotes",
    );
    const { addSuccess } = useToast();

    return useMutation({
        mutationFn: (updatedTaskNote: Type_put_note) =>
            updateTaskNote(
                updatedTaskNote.id,
                formatterUpdateTaskNote(updatedTaskNote),
                requestConfig,
            ),
        onSuccess: async () => {
            addSuccess({
                description: fmt("ToastSuccessUpdate"),
            });
        },
        onError: (err: any) => {
            return err;
        },
    });
};

export const mutationDeleteTaskNote = (filters: Type_Filter_Task) => {
    const queryClient = useQueryClient();
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerNotes",
    );
    const { addSuccess } = useToast();

    return useMutation({
        mutationFn: (taskNoteId: number) =>
            deleteTaskNote(taskNoteId, requestConfig),
        onSuccess: async () => {
            await queryClient.invalidateQueries([
                TaskNoteKeys.INDEX,
                requestConfig,
                filters,
            ]);
            addSuccess({
                description: fmt("ToastSuccessDelete"),
            });
        },
        onError: (err: any) => {
            return err;
        },
    });
};
