import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";

import { ProjectPath, Url } from "src/api/paths";
import { ColorPicker } from "src/components/Components_Common/forms/reactHookFormComponents/ColorPicker/ColorPicker";
import { TextField } from "src/components/Components_Common/forms/reactHookFormComponents/TextField/TextField";
import { UploadImageRhf } from "src/components/Components_Common/forms/reactHookFormComponents/UploadImage/UploadImage";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { useCoreIntl } from "src/hooks/useCoreIntl";

const Schema_PdfAppearance = Yup.object().shape({
    title: Yup.string().required(FORM_ERR_FMT.REQUIRED),
    backgroundColor: Yup.string(),
    leftImage: Yup.string(),
    rightImage: Yup.string(),
});

type Type_Props_PdfAppearanceForm = {
    onClose: () => void;
    formId: string;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
};

export const PdfAppearanceForm = ({
    onClose,
    formId,
    setIsLoading,
}: Type_Props_PdfAppearanceForm) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.PdfAppearance.Form",
    );

    const handleSubmit = async (
        //TODO completer dans ticket 3625
        values: { [key: string]: any },
        e?: React.BaseSyntheticEvent,
    ) => {
        e?.preventDefault();
        setIsLoading(true);
        onClose();
    };

    const form = useForm({
        //TODO à completer dans ticket 3625
        defaultValues: {
            backgroundColor: "#fff",
        },
        values: {},
        resolver: yupResolver<any>(Schema_PdfAppearance),
    });

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)} id={formId}>
                <Stack mt={4} gap={6}>
                    <Stack gap={2}>
                        <TextField name="title" label={fmt(`Title`)} />
                        <ColorPicker
                            name="backgroundColor"
                            label={fmt("BackgroundColor")}
                            data-testid="PdfAppearance-Drawer-background-color"
                        />
                    </Stack>
                    <Stack gap={2}>
                        <Typography variant="h3">{fmt(`LeftImg`)}</Typography>
                        <UploadImageRhf
                            host={Url.COMMONS}
                            service={ProjectPath.PROJECTS}
                            id={1}
                            name="leftImg"
                            withDescription
                        />
                    </Stack>
                    <Stack gap={2}>
                        <Typography variant="h3">{fmt(`RightImg`)}</Typography>
                        <UploadImageRhf
                            host={Url.COMMONS}
                            service={ProjectPath.PROJECTS}
                            id={1}
                            name="rightImg"
                            withDescription
                        />
                    </Stack>
                </Stack>
            </form>
        </FormProvider>
    );
};
