import { InputSubmitComponent } from "./InputSubmit.component";

export type InputSubmitPropsType = {
    label: string;
    onClick?: any;
    props?: any;
    type?: string;
};

export const InputSubmitContainer = (props: InputSubmitPropsType) => {
    return <InputSubmitComponent {...props} />;
};
