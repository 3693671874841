import {
    DrawerProps,
    Grid,
    IconButton,
    ModalProps,
    Stack,
    Typography,
} from "@mui/material";
import React, { MouseEventHandler, ReactNode, useMemo } from "react";

import {
    TMC_Button,
    TMC_Drawer,
} from "src/components/Components_Common/_MuiComponentsVariants";
import {
    DrawerContent,
    ScrollableContent,
    Styled_DrawerFooter,
    Styled_DrawerHeader,
} from "src/components/Components_Common/DrawerGeneric/DrawerGeneric.style";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { NoPermission } from "src/components/Components_Common/NoPermission/NoPermission";
import { useUser } from "src/contexts/user";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { COLORS } from "src/theme/stylesheet";

export type Type_action = "create" | "duplicate" | "update" | "";

export type Type_Props_DrawerGeneric = DrawerProps & {
    open: boolean;
    title: string;
    onClose: ModalProps["onClose"];
    currentStep?: number;
    maxStep?: number;
    handleChange?: (event: React.SyntheticEvent, newValue: string) => void;
    dataTestIdTitle?: string;
    children: ReactNode;
    isFormLoading?: boolean;
    formId?: string;
    page?: string;
    disableScroll?: boolean;
    customFooter?: ReactNode;
};

export const DrawerGeneric = ({
    open,
    onClose,
    currentStep,
    maxStep,
    dataTestIdTitle,
    children,
    title,
    page,
    isFormLoading,
    formId,
    disableScroll = false,
    customFooter,
}: Type_Props_DrawerGeneric) => {
    const { checkPermission } = useUser();

    const isAuthorized = useMemo(() => {
        return page
            ? checkPermission(page, "create") || checkPermission(page, "update")
            : true;
    }, [page]);

    return (
        <TMC_Drawer open={open} onClose={onClose}>
            <DrawerHeader
                open={open}
                title={title}
                onClose={onClose}
                currentStep={currentStep}
                maxStep={maxStep}
                dataTestIdTitle={dataTestIdTitle}
            ></DrawerHeader>
            {!isAuthorized ? (
                <DrawerContent>
                    <NoPermission />
                </DrawerContent>
            ) : (
                <ScrollableContent
                    sx={{ ...(disableScroll ? { overflowY: "hidden" } : {}) }}
                >
                    <DrawerContent>{children}</DrawerContent>
                </ScrollableContent>
            )}
            <DrawerFooter
                customFooter={customFooter}
                isFormLoading={isFormLoading}
                formId={formId}
            />
        </TMC_Drawer>
    );
};

type Type_Header_Props = {
    open: boolean;
    title: string;
    onClose: ModalProps["onClose"];
    currentStep?: number;
    maxStep?: number;
    handleChange?: (event: React.SyntheticEvent, newValue: string) => void;
    dataTestIdTitle?: string;
};
const DrawerHeader = ({
    onClose,
    currentStep,
    maxStep,
    dataTestIdTitle,
    title,
}: Type_Header_Props) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Teamoty.Drawer.Header",
    );
    const hasSteps: boolean = !!currentStep && !!maxStep && maxStep > 1;

    return (
        <Styled_DrawerHeader>
            <Stack alignItems={"end"}>
                <IconButton
                    data-testid="Drawer-CloseButton"
                    onClick={
                        onClose as unknown as MouseEventHandler<HTMLButtonElement>
                    }
                    size="small"
                    sx={{
                        color: COLORS.dark,
                    }}
                >
                    <Icon variant="light" icon="xmark" />
                </IconButton>
            </Stack>
            <Grid item container wrap="nowrap" alignItems="center">
                {hasSteps && (
                    <Typography
                        variant="h3"
                        data-testid={`DrawerContent-Title-steps-${currentStep}`}
                    >
                        {fmt("Steps", {
                            values: {
                                currentStep: currentStep,
                                maxStep: maxStep,
                            },
                        })}
                    </Typography>
                )}
                <Typography variant="h3" data-testid={dataTestIdTitle}>
                    {title}
                </Typography>
            </Grid>
        </Styled_DrawerHeader>
    );
};

type Type_Props_DrawerFooter = {
    isFormLoading?: boolean;
    formId?: string;
    customFooter?: ReactNode;
};
const DrawerFooter = ({
    isFormLoading,
    formId,
    customFooter,
}: Type_Props_DrawerFooter) => {
    const { formatMessageWithPartialKey: fmtActions } = useCoreIntl("Actions");

    return (
        <Styled_DrawerFooter>
            {customFooter ? (
                customFooter
            ) : (
                <TMC_Button
                    form={formId}
                    data-testid="DrawerContent-Submit"
                    color="primary"
                    type="submit"
                    disabled={isFormLoading}
                    startIcon={
                        !isFormLoading && <Icon variant="solid" icon="check" />
                    }
                >
                    {isFormLoading ? (
                        <Icon variant="solid" icon="spinner-third" spin />
                    ) : (
                        fmtActions("Save")
                    )}
                </TMC_Button>
            )}
        </Styled_DrawerFooter>
    );
};
