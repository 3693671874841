import { Grid } from "@mui/material";
import React, { Dispatch, SetStateAction, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { TMC_TextField } from "src/components/Components_Common/_MuiComponentsVariants";
import { Type_Props_TextField } from "src/components/Components_Common/_MuiComponentsVariants/TextField/TextField";
import {
    Styled_ButtonColorGrid,
    Styled_Popover,
    Styled_StackColorAdornment,
} from "src/components/Components_Common/ColorPicker/ColorPicker.style";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { COLOR_PICKER_COLORS } from "src/theme/stylesheet";
import { invertColor } from "src/utils/colors";

type Type_Props_ColorPopover = {
    anchorEl: HTMLButtonElement | null;
    setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
    open: boolean;
    onClose: () => void;
    callback: (color: string) => void;
    selectedColors?: string[];
    currentValue?: string;
    name: string;
};

const ColorPopover = ({
    anchorEl,
    setAnchorEl,
    open,
    onClose,
    callback,
    selectedColors,
    currentValue,
    name,
}: Type_Props_ColorPopover) => {
    const handleColorPickerClick = (color: string): void => {
        callback(color);
        setAnchorEl(null);
    };

    return (
        <Styled_Popover
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            hideBackdrop
            disableAutoFocus={true}
            disableRestoreFocus={true}
        >
            <Grid container spacing={1} columns={8}>
                {COLOR_PICKER_COLORS.map((color: string, index: number) => {
                    return (
                        <Grid item xs={1} key={index}>
                            <Styled_ButtonColorGrid
                                onClick={() => handleColorPickerClick(color)}
                                ownerState={{ fullPaletteColor: color }}
                                data-testid={`ColorPicker-${name}-${color}`}
                            >
                                {(color === currentValue && (
                                    <Icon
                                        variant="solid"
                                        icon="check"
                                        sx={{
                                            color: invertColor(color),
                                        }}
                                        data-testid={`ColorPicker-${name}-${color}-check`}
                                    />
                                )) ||
                                    (selectedColors?.includes(color) && (
                                        <Icon
                                            variant="solid"
                                            icon="close"
                                            sx={{
                                                color: invertColor(color),
                                            }}
                                            data-testid={`ColorPicker-${name}-${color}-close`}
                                        />
                                    ))}
                            </Styled_ButtonColorGrid>
                        </Grid>
                    );
                })}
            </Grid>
        </Styled_Popover>
    );
};

type Type_Props_ColorPicker = Omit<Type_Props_TextField, "slotProps"> & {
    name: string;
    selectedColors?: string[];
};

export const ColorPicker = ({
    name,
    selectedColors,
    ...restProps
}: Type_Props_ColorPicker) => {
    const { formState, control, setValue, getValues } = useFormContext(); // retrieve those props

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const open: boolean = Boolean(anchorEl);
    const handleClick = (event: any): void => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (): void => {
        setAnchorEl(null);
    };

    return (
        <>
            <Controller
                control={control}
                name={name}
                render={({ field: { ref, value } }) => (
                    <TMC_TextField
                        {...restProps}
                        inputRef={ref}
                        value={value}
                        name={name}
                        onClick={handleClick}
                        inputProps={{
                            readOnly: true,
                            "data-testid": `ColorPicker-${name}-selected`,
                        }}
                        sx={{
                            cursor: "pointer",
                            ...restProps.sx,
                        }}
                        InputProps={{
                            startAdornment: (
                                <Styled_StackColorAdornment
                                    sx={{ backgroundColor: value }}
                                />
                            ),
                        }}
                        // RHF
                        error={!!formState.errors[name]}
                        helperText={formState.errors?.[name]?.message as string}
                    />
                )}
            />
            <ColorPopover
                anchorEl={anchorEl}
                onClose={handleClose}
                setAnchorEl={setAnchorEl}
                open={open}
                selectedColors={selectedColors}
                callback={(color) => {
                    setValue(name, color);
                }}
                currentValue={getValues(name)}
                name={name}
            />
        </>
    );
};
