import { useMutation, useQuery, useQueryClient } from "react-query";

import { LanguageKeys } from "src/api/tms-projects/keys";
import {
    formatterIndexLanguages,
    formatterLanguages,
} from "src/api/tms-projects/languages/formatters";
import {
    createLanguage,
    deleteLanguage,
    indexLanguages,
    updateLanguage,
} from "src/api/tms-projects/languages/services";
import {
    Type_prj_index_language,
    Type_prj_post_language,
    Type_prj_put_language,
} from "src/api/tms-projects/languages/types";
import { useProject } from "src/contexts/project";

export const useIndexProjectLanguages = (projectId: number | undefined) => {
    return useQuery({
        queryKey: [LanguageKeys.INDEX, projectId],
        queryFn: () => indexLanguages({ projectId }),
        enabled: !!projectId,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Error format data: useIndexProjectLanguages");
            }
            return formatterIndexLanguages(
                data?.data?.data as Type_prj_index_language[],
            );
        },
        onError: (err) => {
            console.error(err);
        },
    });
};

export const useShowLanguages = () => {
    // TODO: à supprimer lors du fix languages, cette query est identique à useIndexLanguages
    const { requestConfig } = useProject();
    return useQuery({
        queryKey: [LanguageKeys.INDEX, requestConfig],
        queryFn: () => indexLanguages(requestConfig),
        enabled: !!requestConfig.projectId,
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.data?.data)
                throw new Error("Wrong data: useShowLanguages");
            else return <Type_prj_index_language[]>data.data.data;
        },
    });
};

export const mutationCreateLanguage = () => {
    const { requestConfig } = useProject();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (newLanguage: Type_prj_post_language) =>
            createLanguage(newLanguage, requestConfig),
        onSuccess: async (data: any) => {
            // Permet d'invalider la dernière requête et de l'exécuter de nouveau
            await queryClient.invalidateQueries(LanguageKeys.INDEX);
            return data;
        },
        onError: (err: any) => {
            return err;
        },
    });
};

export const mutationUpdateLanguage = () => {
    const { requestConfig } = useProject();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (updatedLanguage: Type_prj_put_language) =>
            updateLanguage(updatedLanguage, requestConfig),
        onSuccess: async (data: any) => {
            // Permet d'invalider la dernière requête et de l'exécuter de nouveau
            await queryClient.invalidateQueries(LanguageKeys.INDEX);
            return data;
        },
        onError: (err: any) => {
            return err;
        },
    });
};

export const mutationDeleteLanguage = () => {
    const { requestConfig } = useProject();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (languageId: number) =>
            deleteLanguage(languageId, requestConfig),
        onSuccess: async (data: any) => {
            // Permet d'invalider la dernière requête et de l'exécuter de nouveau
            await queryClient.invalidateQueries(LanguageKeys.INDEX);

            return data;
        },
        onError: (err: any) => {
            return err;
        },
    });
};

export const useGetLanguages = () => {
    // TODO: à supprimer lors du fix languages, cette query est identique à useIndexLanguages
    const { requestConfig } = useProject();
    return useQuery({
        queryKey: ["indexLanguages", requestConfig],
        queryFn: () => indexLanguages(requestConfig),
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Error format data: useGetLanguages");
            }

            return formatterLanguages(data.data.data);
        },
        enabled: !!requestConfig.projectId,
    });
};
