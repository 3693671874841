import { FormikProps, FormikValues } from "formik";
import React, { useMemo, useState } from "react";

import { Type_RequestConfig } from "src/api/fetch";
import {
    mutationCreateVersion,
    mutationUpdateVersion,
    useShowVersion,
} from "src/api/tms-projects/versions";
import { Type_show_version } from "src/api/tms-projects/versions/types";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { getLocalStorageItem } from "src/utils/localStorageServices";

import { Version_DrawerContentComponent } from "./Version_DrawerContent.component";

export type Type_Version_DrawerContentContainer_Props = {
    onClose: () => void;
    onSubmit: any;
    versionIdToUpdate?: number;
    action: string;
    submitStep?: number | undefined;
    requestConfig: Type_RequestConfig;
};

export const Version_DrawerContentContainer = ({
    onClose,
    onSubmit,
    versionIdToUpdate,
    action,
    requestConfig,
}: Type_Version_DrawerContentContainer_Props) => {
    const { mutateAsync: mutateCreateVersion, isLoading: createLoading } =
        mutationCreateVersion(onSubmit, requestConfig);

    const { mutateAsync: mutateUpdate, isLoading: updateLoading } =
        mutationUpdateVersion(onSubmit, requestConfig);

    const language = getLocalStorageItem("language");

    const [tab, setTab] = useState<string>("0");

    const [data, setData] = useState<Type_show_version>();

    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.SubprojectSettings.Versions.Drawer",
    );
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Form.Error");
    const { add } = useToast();

    const { isFetching: isFetchingVersion } = useShowVersion(
        setData,
        requestConfig,
        versionIdToUpdate as number,
    );

    const handleSubmit = async (
        values: FormikValues,
        { setErrors }: FormikProps<any>,
    ) => {
        try {
            if (action === "update") {
                await mutateUpdate(values);
            } else {
                await mutateCreateVersion(values);
            }

            add({
                type: "success",
                duration: 4000,
                description: fmt(
                    versionIdToUpdate ? "ToastSuccessUpdate" : "ToastSuccess",
                    {
                        values: {
                            b: (chunks: string) => <b>{chunks}</b>,
                            version: values.names[language],
                        },
                    },
                ),
            });
            // Fermeture du Drawer
            onClose();
        } catch (e: any) {
            setErrors({
                names: {
                    [e.data.data.field]: FORM_ERR_FMT.DUPLICATE,
                },
            });
            add({
                type: "warning",
                description: fmtErr(FORM_ERR_FMT.DUPLICATE),
            });
        }
    };

    const isLoading = useMemo(
        () => createLoading || updateLoading,
        [createLoading, updateLoading],
    );

    const isFetching = useMemo(
        () =>
            !!(versionIdToUpdate && data?.id === undefined) ||
            isFetchingVersion,
        [versionIdToUpdate, data?.id, isFetchingVersion],
    );

    return (
        <Version_DrawerContentComponent
            projectId={requestConfig.projectId as number}
            subProjectId={requestConfig.subProjectId as number}
            isFetching={isFetching}
            isLoading={isLoading}
            onClose={onClose}
            onSubmit={handleSubmit}
            data={data}
            isUpdateVersion={action === "update"}
            tab={tab}
            setTab={setTab}
        />
    );
};
