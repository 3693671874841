import { useMutation, useQuery } from "react-query";

import { TaskAreaKeys } from "src/api/tms-scheduling/keys";
import {
    formatterCreateTaskArea,
    formatterIndexTaskAreas,
    formatterProgressTaskArea,
    formatterShowTaskArea,
} from "src/api/tms-scheduling/taskArea/formatters";
import {
    createTaskArea,
    indexTaskAreas,
    setProgressTaskArea,
    showTaskArea,
} from "src/api/tms-scheduling/taskArea/services";
import {
    Type_post_taskArea,
    Type_progress_taskArea,
    Type_sch_index_taskArea,
    Type_sch_show_taskArea,
} from "src/api/tms-scheduling/taskArea/types";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { useChannel } from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const useIndexTaskAreas = (areaId: number, taskId: number) => {
    const { requestConfig } = useProject();

    return useQuery({
        queryKey: [TaskAreaKeys.INDEX, requestConfig, areaId, taskId],
        queryFn: () =>
            indexTaskAreas({
                ...requestConfig,
                areaId: areaId,
                taskId: taskId,
            }),
        enabled: !!requestConfig.projectId && !!requestConfig.subProjectId,
        refetchOnWindowFocus: false,
        select: (data) => {
            return formatterIndexTaskAreas(
                data?.data?.data as Type_sch_index_taskArea[],
            );
        },
        onError: (err) => {
            console.error(err);
            return err;
        },
    });
};

export const useShowTaskArea = (taskId: number, areaId: number) => {
    const { requestConfig } = useProject();
    return useQuery({
        queryKey: [TaskAreaKeys.SHOW, requestConfig, taskId, areaId],
        queryFn: () => showTaskArea(taskId, areaId, requestConfig),
        enabled: !!requestConfig.projectId && !!requestConfig.subProjectId,
        refetchOnWindowFocus: false,
        select: (data) => {
            return formatterShowTaskArea(
                data?.data?.data as Type_sch_show_taskArea,
            );
        },
        onError: (err) => {
            console.error(err);
            return err;
        },
    });
};

export const mutationCreateTaskArea = () => {
    const { requestConfig } = useProject();
    const { sendEvent } = useChannel({});
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");

    return useMutation({
        mutationFn: (taskArea: Type_post_taskArea) =>
            createTaskArea(formatterCreateTaskArea(taskArea), {
                ...requestConfig,
                areaId: taskArea.areaId,
                taskId: taskArea.taskId,
            }),
        onSuccess: (resp): void => {
            if (resp.success) {
                // const taskArea: Type_sch_index_taskArea = resp.data.data;
                sendEvent("postTaskArea");
            }
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErrors("GenericError"),
            });
            return err;
        },
    });
};

export const mutationSetProgressTaskArea = () => {
    const { requestConfig } = useProject();
    const { sendEvent } = useChannel({});
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");

    return useMutation({
        mutationFn: (progressTaskArea: Type_progress_taskArea) =>
            setProgressTaskArea(formatterProgressTaskArea(progressTaskArea), {
                ...requestConfig,
                areaId: progressTaskArea.areaId,
                taskId: progressTaskArea.taskId,
            }),
        onSuccess: (resp, variables): void => {
            if (resp.success) {
                // const taskArea: Type_sch_index_taskArea = resp.data.data;
                sendEvent("progressTaskArea" + variables.origin);
            }
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErrors("GenericError"),
            });
            return err;
        },
    });
};
