import { Skeleton, Stack } from "@mui/material";
import { unix } from "dayjs";
import React, { useMemo, useState } from "react";

import { useShowKeyDate } from "src/api/tms-scheduling/keyDates";
import { TMC_Button } from "src/components/Components_Common";
import { Dialog } from "src/components/Components_Common/Dialog/Dialog";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { usePlanningContext } from "src/contexts/planning";
import { KeyDateForm } from "src/forms/keyDate/KeyDateForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

import { Type_DialogParamsRightClick } from "./index";

export const KeyDateDialog = () => {
    const { formatMessageWithPartialKey: fmtAction } = useCoreIntl("Actions");
    const { formatMessageWithPartialKey: fmtLabels } = useCoreIntl("Labels");

    const { toggleDialog, dialog, props } = usePlanningContext();

    const [isLoading, setIsLoading] = useState(false);
    const [dialogParams] = useState<Type_DialogParamsRightClick>({
        open: dialog.keyDate,
        action: "create",
        data: {
            date: props?.date?.timestamp ? unix(props.date.timestamp) : null,
            id: props?.id,
        },
    });

    const { data, isFetching } = useShowKeyDate(dialogParams?.data?.id);

    const closeDialog = () => {
        toggleDialog("keyDate");
    };

    const title = `${dialogParams?.data?.id ? fmtAction("Update") : fmtAction("New")} ${fmtLabels("KeyDate")}`;

    const defaultValues = useMemo(
        () =>
            data
                ? data
                : {
                      date: dialogParams?.data?.date,
                      areas: props?.areas,
                  },
        [data],
    );

    return (
        <Dialog
            name="keyDate"
            open={dialogParams.open}
            dialogTitle={title}
            isLoading={isLoading}
            onClose={closeDialog}
            actions={
                <TMC_Button
                    form={"keyDate"}
                    type="submit"
                    size="medium"
                    variant="contained"
                    disabled={isLoading}
                    startIcon={
                        !isLoading && <Icon variant="solid" icon="add" />
                    }
                    data-testid={`dialog-keyDate-save-btn`}
                >
                    {isLoading ? (
                        <Icon variant="solid" icon="spinner-third" spin />
                    ) : (
                        fmtAction("Create")
                    )}
                </TMC_Button>
            }
        >
            {!isFetching && (
                <KeyDateForm
                    setIsLoading={setIsLoading}
                    defaultValues={defaultValues}
                    closeDialog={closeDialog}
                />
            )}
            {isFetching && (
                <Stack rowGap={6}>
                    <Skeleton variant="rounded" height={139} />
                    <Skeleton variant="rounded" height={62} />
                    <Skeleton variant="rounded" height={62} />
                    <Skeleton variant="rounded" height={62} />
                    <Skeleton variant="rounded" height={62} />
                    <Skeleton variant="rounded" height={60} />
                </Stack>
            )}
        </Dialog>
    );
};
