import { Box, InputAdornment, Stack, Typography } from "@mui/material";
import React, { useState } from "react";

import { TMC_Button, TMC_TextField } from "src/components";
import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import {
    Dialog,
    Type_DialogConfig,
    Type_DialogParams,
} from "src/components/Components_Common/Dialog/Dialog";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { Spinner } from "src/components/Components_Common/Spinner/Spinner";
import { SequencesTreeView } from "src/drawers/sequences/SequencesContextualDrawer/SequencesTreeView";
import { HeaderToolbar } from "src/drawers/task/TaskContextualDrawer/HeaderToolbar";
import { SequenceDuplicationForm } from "src/forms/sequence/SequenceDuplicationForm";
import { SequenceFolderForm } from "src/forms/sequenceFolder/SequenceFolderForm";
import { Type_event_dialog, useChannel } from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import {
    Content,
    Header,
    Styled_PaperContextualLayout,
    useContextualDrawer,
} from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";
import { COLORS } from "src/theme/stylesheet";

export const SequencesContextualDrawer = () => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.SequenceFolders",
    );
    const { closePaper } = useContextualDrawer();
    const onClose = () => closePaper("sequences");
    const [dialogParams, setDialogParams] = useState<Type_DialogParams>({
        open: false,
        action: "create",
        data: { name: "" },
    });
    const [isFormLoading, setIsFormLoading] = useState<boolean>(false);

    const handleClickOpen = (): void => {
        setDialogParams((prevParams) => ({
            ...prevParams,
            action: "create",
            open: true,
            data: { name: "createOrUpdateSequenceFolder" },
        }));
    };

    useChannel({
        eventHandler: ({ event, data }): void => {
            if (event === "openDialogFolder") {
                setDialogParams((prevParams) => ({
                    ...prevParams,
                    ...(data as Type_event_dialog),
                }));
            }
        },
    });

    const handleCloseDialog = (): void => {
        setDialogParams((prev) => ({ ...prev, open: false, data: {} }));
    };

    const DialogConfig: Type_DialogConfig = {
        createOrUpdateSequenceFolder: {
            props: {
                open: dialogParams.open,
                onClose: handleCloseDialog,
                dialogTitle:
                    dialogParams.action === "create"
                        ? fmt("Form.TitleCreate")
                        : fmt("Form.TitleUpdate"),
                name: "createOrUpdateSequenceFolder",
                isLoading: isFormLoading,
                children: (
                    <SequenceFolderForm
                        dialogParams={dialogParams}
                        setDialogParams={setDialogParams}
                        setIsFormLoading={setIsFormLoading}
                    />
                ),
            },
        },
        duplicateSequence: {
            props: {
                open: dialogParams.open,
                onClose: handleCloseDialog,
                dialogTitle: `${fmt("Sequence.Duplicating")} "${dialogParams?.data?.label}"`,
                name: "duplicateSequence",
                isLoading: isFormLoading,
                children: (
                    <SequenceDuplicationForm
                        setIsFormLoading={setIsFormLoading}
                        dialogParams={dialogParams}
                        setDialogParams={setDialogParams}
                    />
                ),
                actions: (
                    <TMC_Button
                        form={dialogParams?.data?.name}
                        type="submit"
                        size="medium"
                        variant="contained"
                        disabled={isFormLoading}
                        startIcon={
                            isFormLoading ? (
                                <Spinner />
                            ) : (
                                <Icon variant="solid" icon="copy" />
                            )
                        }
                        data-testid={`dialog-${dialogParams?.data?.name}-btn`}
                    >
                        {fmt("Sequence.More.Duplicate")}
                    </TMC_Button>
                ),
            },
        },
    };

    return (
        <>
            <Styled_PaperContextualLayout>
                <Header
                    data-testid="sequences-Header"
                    ownerState={{ borderBottom: false }}
                >
                    <HeaderToolbar onClose={onClose} />
                </Header>
                <Content data-testid="sequences-Content">
                    <Stack
                        justifyContent="space-between"
                        alignItems="center"
                        direction={"row"}
                        paddingX={4}
                        paddingY={1}
                    >
                        <Typography variant={"h3"}>{fmt("Title")}</Typography>
                        <IconButton
                            onClick={handleClickOpen}
                            size="small"
                            title={fmt("CreateFolder")}
                            data-testid="sequence-create-sequenceFolder-btn"
                        >
                            <Icon
                                variant="solid"
                                icon="folder-plus"
                                fontSize="medium"
                            />
                        </IconButton>
                    </Stack>
                    <Box paddingX={4} paddingY={2}>
                        <TMC_TextField
                            data-testid="sequences-SearchBar"
                            name="global-search"
                            placeholder="Search..."
                            variant="outlined"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Icon
                                            variant="solid"
                                            icon="search"
                                            sx={{ color: COLORS.moon500 }}
                                        />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <SequencesTreeView />
                </Content>
            </Styled_PaperContextualLayout>
            <Dialog {...DialogConfig[dialogParams?.data?.name]?.props} />
        </>
    );
};
