import { InputDateRangePropsType } from "./InputDateRange.container";

export type InputDateRangeType = {
    type: "inputDateRange";
    nameFrom: string;
    nameTo: string;
    required?: boolean;
    label?: {
        label?: string;
        languageLabel?: string;
    };
    placeholderFrom?: {
        label?: string;
        languageLabel?: string;
    };
    placeholderTo?: {
        label?: string;
        languageLabel?: string;
    };
};

export const InputDateRangeComponent = (_props: InputDateRangePropsType) => {
    return (
        <>
            <p>InputDateRangeComponent</p>
        </>
    );
};
