import Konva from "konva";
import React, { RefObject, useRef } from "react";
import { Group, Path, Rect, Text } from "react-konva";

import {
    backgroundPlanningDrawerTitlePictogram,
    backgroundPlanningDrawerTitlePictogramOver,
    colorPlanningDrawerTitle,
    colorPlanningDrawerTitlePictogram,
    colorPlanningLine,
    durationPlanningDrawerTitlePictogramOver,
    durationPlanningScrollCursor,
    fillPlanningDrawerTitle,
    heightPlanningDrawerTitle,
    pictogramClose,
    sizePlanningDrawerTitlePictogram,
} from "src/components/Components_Teamoty/Planning/Planning.const";
import { useCoreIntl } from "src/hooks/useCoreIntl";

import { Type_Props_PlanningDrawerTitle } from "./PlanningDrawerTitle.type";

export const PlanningDrawerTitle = ({
    width,
    titleKey,
    onClose,
}: Type_Props_PlanningDrawerTitle) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Planning");

    const refRect: RefObject<Konva.Rect> = useRef<Konva.Rect>(null);

    return (
        <Group>
            <Rect
                width={width - 1}
                height={heightPlanningDrawerTitle}
                fill={fillPlanningDrawerTitle}
                stroke={colorPlanningLine}
                strokeWidth={1}
            />
            <Text
                width={width}
                height={heightPlanningDrawerTitle}
                text={fmt(titleKey)}
                fill={colorPlanningDrawerTitle}
                verticalAlign={"middle"}
                align={"left"}
                padding={6}
            />
            <Group
                x={width - heightPlanningDrawerTitle - 0.5}
                onMouseOver={(e: Konva.KonvaEventObject<MouseEvent>): void => {
                    e.cancelBubble = true;
                    const container: HTMLDivElement | undefined =
                        e.currentTarget.getStage()?.getContent();
                    if (container) container.style.cursor = "pointer";
                    refRect.current &&
                        refRect.current.to({
                            fill: backgroundPlanningDrawerTitlePictogramOver,
                            duration: durationPlanningDrawerTitlePictogramOver,
                        });
                }}
                onMouseOut={(e: Konva.KonvaEventObject<MouseEvent>): void => {
                    e.cancelBubble = true;
                    const container: HTMLDivElement | undefined =
                        e.currentTarget.getStage()?.getContent();
                    if (container) container.style.cursor = "default";
                    refRect.current &&
                        refRect.current.to({
                            fill: backgroundPlanningDrawerTitlePictogram,
                            duration: durationPlanningScrollCursor,
                        });
                }}
                onClick={onClose}
            >
                <Rect
                    ref={refRect}
                    width={heightPlanningDrawerTitle}
                    height={heightPlanningDrawerTitle}
                    fill={backgroundPlanningDrawerTitlePictogram}
                    stroke={colorPlanningLine}
                    strokeWidth={1}
                />
                <Path
                    x={
                        (heightPlanningDrawerTitle -
                            sizePlanningDrawerTitlePictogram) /
                            2 +
                        2
                    }
                    y={
                        (heightPlanningDrawerTitle -
                            sizePlanningDrawerTitlePictogram) /
                        2
                    }
                    scaleX={sizePlanningDrawerTitlePictogram / 512}
                    scaleY={sizePlanningDrawerTitlePictogram / 512}
                    fill={colorPlanningDrawerTitlePictogram}
                    data={pictogramClose}
                />
            </Group>
        </Group>
    );
};
