import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { LayoutDashboard } from "src/components";
import {
    Layout_NotLogged,
    Layout_Sidebar,
    Layout_Workspace,
} from "src/layouts";
import { Layout_FullWidth } from "src/layouts/Layout_FullWidth";
import { logisticsSettingsCategoriesList } from "src/layouts/Layout_Sidebar/configurations/logisticsSettings";
import { myAccountCategoriesList } from "src/layouts/Layout_Sidebar/configurations/myAccount";
import { configurationCategoriesList } from "src/layouts/Layout_Sidebar/configurations/platformConfiguration";
import { projectSettingsCategoriesList } from "src/layouts/Layout_Sidebar/configurations/projectSettings";
import { subprojectSettingsCategoriesList } from "src/layouts/Layout_Sidebar/configurations/subprojectSettings";
import { usersAndCompaniesCategoriesList } from "src/layouts/Layout_Sidebar/configurations/usersAndCompanies";
import {
    Page_Areas,
    Page_AreaTypes,
    Page_Assign_Manager,
    Page_Assign_Resources,
    Page_CausesOfDelay,
    Page_CGU,
    Page_ChangePassword,
    Page_ChangePasswordConfirm,
    Page_ChangePasswordExpire,
    Page_Companies,
    Page_ConfirmMail,
    Page_DaysOff,
    Page_DefaultSettings,
    Page_Drawing,
    Page_Drawings,
    Page_ForgetPassword,
    Page_ForgetPasswordConfirm,
    Page_Identification,
    Page_LicenseTypes,
    Page_MetadataProjects,
    Page_MetadataUsers,
    Page_MyProfile,
    Page_NewUser_Invitation,
    Page_PlatformSettings,
    Page_PriorityOfConstraints,
    Page_ProjectCompanies,
    Page_ProjectDetails,
    Page_Projects,
    Page_ProjectTypes,
    Page_ProjectUsers,
    Page_PunchlistItemCauses,
    Page_Request_Types,
    Page_ResourceFamilies,
    Page_ResourceModels,
    Page_Resources,
    Page_ResourceTypes,
    Page_Roles,
    Page_Security,
    Page_Statistics,
    Page_Subprojects,
    Page_TimeModels,
    Page_Trades,
    Page_Units,
    Page_UnitTypes,
    Page_UserInvitations,
    Page_Versions,
} from "src/pages";
import { Page_Demo } from "src/pages/Pages_Common/Page_Demo";
import { Page_DemoProject } from "src/pages/Pages_Common/Page_DemoProject";
import { Page_Library } from "src/pages/Pages_Teamoty/Library/Page_Library";

import { HandleRedirection } from "./HandleRedirection";
import { ProtectedRoutes } from "./ProtectedRoutes/ProtectedRoutes.container";

export const URL_BASE: string = "/:lang";

export const URL_USERS_MANAGEMENT: string = `${URL_BASE}/users-and-companies`;
export const URL_MY_ACCOUNT: string = `${URL_BASE}/my-account`;
export const URL_CONFIGURATION_PLATFORM: string = `${URL_BASE}/platform-configuration`;
export const URL_TEAMOTY_PROJECT: string = `${URL_BASE}/projects/:projectSlug`;
export const URL_LOGISTICS: string = `${URL_TEAMOTY_PROJECT}/logistics`;
export const URL_SCHEDULING: string = `${URL_TEAMOTY_PROJECT}/scheduling`;
export const URL_RECYCLE: string = `${URL_TEAMOTY_PROJECT}/recycle`;
export const URL_MY_TEAMOTY: string = `${URL_TEAMOTY_PROJECT}/myteamoty`;

export const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                {/* Routes d'authentification (connexion, enregistrement, mdp perdu) */}
                <Route path={`${URL_BASE}/*`} element={<Layout_NotLogged />}>
                    <Route path={`login`} element={<Page_Identification />} />
                    <Route
                        path={`projects/:project/login`}
                        element={<Page_Identification />}
                    />
                    <Route
                        path={`user-invitations/register/:token`}
                        element={<Page_NewUser_Invitation />}
                    />
                    <Route
                        path={`confirm-mail/:id/:hash`}
                        element={<Page_ConfirmMail />}
                    />
                    <Route
                        path={`forget-password`}
                        element={<Page_ForgetPassword />}
                    />
                    <Route
                        path={`forget-password-confirm`}
                        element={<Page_ForgetPasswordConfirm />}
                    />
                    <Route
                        path={`change-password`}
                        element={<Page_ChangePassword />}
                    />
                    <Route
                        path={`change-password-confirm`}
                        element={<Page_ChangePasswordConfirm />}
                    />
                    <Route
                        path={`change-password-expire`}
                        element={<Page_ChangePasswordExpire />}
                    />
                </Route>

                {/* Routes demandant un utilisateur connecté */}
                <Route
                    element={
                        <ProtectedRoutes>
                            <LayoutDashboard />
                        </ProtectedRoutes>
                    }
                >
                    <Route path={`${URL_BASE}/demo`} element={<Page_Demo />} />
                    {/* TODO: will be replace by dashboard or views */}
                    <Route
                        path={`${URL_BASE}/projects`}
                        element={<Page_Projects />}
                    />
                    <Route
                        path={`${URL_BASE}/library`}
                        element={<Page_Library />}
                    />{" "}
                    <Route
                        path={`${URL_BASE}/statistics`}
                        element={<Page_Statistics />}
                    />
                    <Route path={`${URL_BASE}/cgu`} element={<Page_CGU />} />
                    {/* Urls de /users-and-companies */}
                    <Route
                        path={`${URL_USERS_MANAGEMENT}/*`}
                        element={
                            <Layout_Sidebar
                                configuration={usersAndCompaniesCategoriesList}
                            />
                        }
                    >
                        <Route
                            path={`users`}
                            element={<Page_UserInvitations />}
                        />
                        <Route
                            path={`companies`}
                            element={<Page_Companies />}
                        />
                        {/* --> REDIRECTION : */}
                        <Route path={`*`} element={<Navigate to={`users`} />} />
                    </Route>
                    {/* Urls de /my-account : */}
                    <Route
                        path={`${URL_MY_ACCOUNT}/*`}
                        element={
                            <Layout_Sidebar
                                configuration={myAccountCategoriesList}
                            />
                        }
                    >
                        <Route
                            path={"my-profile"}
                            element={<Page_MyProfile />}
                        />
                        <Route path={"security"} element={<Page_Security />} />
                        <Route path={"language"} element={<>language</>} />
                        <Route
                            path={"notifications"}
                            element={<>notifications</>}
                        />
                        <Route path={"delete"} element={<>delete</>} />
                        {/* --> REDIRECTION : */}
                        <Route
                            path={`*`}
                            element={<Navigate to={`my-profile`} />}
                        />
                    </Route>
                    {/* Urls de /configuration platform : */}
                    <Route
                        path={`${URL_CONFIGURATION_PLATFORM}/*`}
                        element={
                            <Layout_Sidebar
                                configuration={configurationCategoriesList}
                            />
                        }
                    >
                        <Route path={`projects/*`}>
                            <Route
                                path={`project-types`}
                                element={<Page_ProjectTypes />}
                            />
                            <Route
                                path={`area-types`}
                                element={<Page_AreaTypes />}
                            />
                            <Route
                                path={`*`}
                                element={<Navigate to={`project-types`} />}
                            />
                        </Route>
                        <Route path={`roles`} element={<Page_Roles />} />
                        <Route
                            path={`license-types`}
                            element={<Page_LicenseTypes />}
                        />
                        <Route path={`units/*`}>
                            <Route path={`units`} element={<Page_Units />} />
                            <Route
                                path={`unit-types`}
                                element={<Page_UnitTypes />}
                            />
                            <Route
                                path={`*`}
                                element={<Navigate to={`units`} />}
                            />
                        </Route>
                        <Route
                            path={`time-models`}
                            element={<Page_TimeModels />}
                        />
                        <Route
                            path={`platform-settings`}
                            element={<Page_PlatformSettings />}
                        />
                        <Route
                            path={`priority-of-constraints`}
                            element={<Page_PriorityOfConstraints />}
                        />
                        <Route path={`causes/*`}>
                            <Route
                                path={`causes-of-delay`}
                                element={<Page_CausesOfDelay />}
                            />
                            <Route
                                path={`punchlist-item-causes`}
                                element={<Page_PunchlistItemCauses />}
                            />
                            <Route
                                path={`*`}
                                element={<Navigate to={`causes-of-delay`} />}
                            />
                        </Route>
                        <Route path={`resources/*`}>
                            <Route
                                path={`resource-models`}
                                element={<Page_ResourceModels />}
                            />
                            <Route
                                path={`resource-families`}
                                element={<Page_ResourceFamilies />}
                            />
                            <Route
                                path={`*`}
                                element={<Navigate to={`resource-models`} />}
                            />
                        </Route>
                        <Route path={`metadata/*`}>
                            <Route
                                path={`projects`}
                                element={<Page_MetadataProjects />}
                            />
                            <Route
                                path={`users`}
                                element={<Page_MetadataUsers />}
                            />
                            <Route
                                path={`*`}
                                element={<Navigate to={`projects`} />}
                            />
                        </Route>
                        {/* --> REDIRECTION */}
                        <Route path={`*`} element={<Navigate to={`roles`} />} />
                    </Route>
                    {/* Projects routes /:lang/projects/:projectSlug */}
                    <Route path={`${URL_TEAMOTY_PROJECT}`}>
                        {/* MY TEAMOTY */}
                        <Route
                            path={`myteamoty/*`}
                            element={<Layout_Workspace />}
                        >
                            {/* Workspace */}
                            <Route path={`workspace`}>
                                <Route
                                    index
                                    path={":view"}
                                    element={<Layout_Workspace />}
                                />
                                <Route
                                    index
                                    path={"*"}
                                    element={<Layout_Workspace />}
                                />
                            </Route>
                            {/* --> REDIRECTION */}
                            <Route
                                path={`*`}
                                element={<Navigate to={`workspace`} />}
                            />
                        </Route>

                        {/* LOGISTICS */}
                        <Route path={`logistics/*`}>
                            {/* Workspace */}
                            <Route path={`workspace`}>
                                <Route
                                    index
                                    path={":view"}
                                    element={<Layout_Workspace />}
                                />
                                <Route
                                    index
                                    path={"*"}
                                    element={<Layout_Workspace />}
                                />
                            </Route>
                            <Route
                                path={`settings/*`}
                                element={
                                    <Layout_Sidebar
                                        configuration={
                                            logisticsSettingsCategoriesList
                                        }
                                    />
                                }
                            >
                                <Route
                                    path={`request-types`}
                                    element={<Page_Request_Types />}
                                />
                                <Route
                                    path={`assign-manager`}
                                    element={<Page_Assign_Manager />}
                                />
                                <Route
                                    path={`assign-resources`}
                                    element={<Page_Assign_Resources />}
                                />
                                {/* --> REDIRECTION */}
                                <Route
                                    path={`*`}
                                    element={<Navigate to={`request-types`} />}
                                />
                            </Route>
                            {/* --> REDIRECTION */}
                            <Route
                                path={`*`}
                                element={<Navigate to={`workspace`} />}
                            />
                        </Route>

                        {/* SCHEDULING */}
                        <Route path={`scheduling/*`}>
                            {/* Workspace */}
                            <Route path={`workspace`}>
                                <Route
                                    index
                                    path={":view"}
                                    element={<Layout_Workspace />}
                                />
                                <Route
                                    index
                                    path={"*"}
                                    element={<Layout_Workspace />}
                                />
                            </Route>
                            {/* --> REDIRECTION */}
                            <Route
                                path={`*`}
                                element={<Navigate to={`workspace`} />}
                            />
                        </Route>

                        {/* RECYCLE */}
                        <Route
                            path={`recycle/*`}
                            element={
                                <div>
                                    <h1>Recycle Route</h1>
                                    <p>
                                        This is a test paragraph for the Recycle
                                        route.
                                    </p>
                                </div>
                            }
                        ></Route>

                        {/* SUB-PROJECT */}
                        <Route path={`:subProjectId`}>
                            <Route path={`myteamoty/*`}>
                                {/* Workspace */}
                                <Route path={`workspace`}>
                                    <Route
                                        index
                                        path={":view"}
                                        element={<Layout_Workspace />}
                                    />
                                    <Route
                                        index
                                        path={"*"}
                                        element={<Layout_Workspace />}
                                    />
                                </Route>
                                {/* --> REDIRECTION */}
                                <Route
                                    path={`*`}
                                    element={<Navigate to={`workspace`} />}
                                />
                            </Route>
                        </Route>

                        {/* PROJECT SETTINGS */}
                        <Route
                            path={`settings/*`}
                            element={
                                <Layout_Sidebar
                                    configuration={
                                        projectSettingsCategoriesList
                                    }
                                    configurationSubProjects={
                                        subprojectSettingsCategoriesList
                                    }
                                />
                            }
                        >
                            <Route
                                path={`subprojects`}
                                element={<Page_Subprojects />}
                            />
                            <Route
                                path={`companies`}
                                element={<Page_ProjectCompanies />}
                            />
                            <Route
                                path={`project-details`}
                                element={<Page_ProjectDetails />}
                            />
                            <Route path={`trades`} element={<Page_Trades />} />

                            <Route
                                path={`users`}
                                element={<Page_ProjectUsers />}
                            />

                            <Route
                                path={`days-off`}
                                element={<Page_DaysOff />}
                            />
                            <Route path={`resources/*`}>
                                <Route
                                    path={`resources`}
                                    element={<Page_Resources />}
                                />
                                <Route
                                    path={`resource-types`}
                                    element={<Page_ResourceTypes />}
                                />
                                <Route
                                    path={`*`}
                                    element={<Navigate to={`resources`} />}
                                />
                            </Route>

                            {/* SUBPROJECT SETTINGS */}
                            <Route path={`subproject/:subProjectId/*`}>
                                <Route
                                    path={`companies`}
                                    element={<Page_ProjectCompanies />}
                                />
                                <Route
                                    path={`trades`}
                                    element={<Page_Trades />}
                                />
                                <Route
                                    path={`areas`}
                                    element={<Page_Areas />}
                                />
                                <Route
                                    path={`versions`}
                                    element={<Page_Versions />}
                                />
                                <Route
                                    path={`drawings`}
                                    element={<Page_Drawings />}
                                />
                                <Route
                                    path={`user`}
                                    element={<Page_ProjectUsers />}
                                />
                                <Route
                                    path={`days-off`}
                                    element={<Page_DaysOff />}
                                />
                                <Route path={`resources/*`}>
                                    <Route
                                        path={`resources`}
                                        element={<Page_Resources />}
                                    />
                                    <Route
                                        path={`resource-types`}
                                        element={<Page_ResourceTypes />}
                                    />
                                    <Route
                                        path={`*`}
                                        element={<Navigate to={`resources`} />}
                                    />
                                </Route>
                                <Route
                                    path={`default-settings`}
                                    element={<Page_DefaultSettings />}
                                />

                                {/* --> REDIRECTION */}
                                <Route
                                    path={`*`}
                                    element={<Navigate to={`areas`} />}
                                />
                            </Route>

                            {/*/!* --> REDIRECTION *!/*/}
                            <Route
                                path={`*`}
                                element={<Navigate to={`subprojects`} />}
                            />
                        </Route>

                        {/* SUB-PROJECT WITH DIFFERENT LAYOUT*/}
                        <Route
                            path={`:subProjectId`}
                            element={<Layout_FullWidth />}
                        >
                            <Route path={`drawings`}>
                                <Route
                                    path={`new`}
                                    element={<Page_Drawing />}
                                />
                                <Route
                                    path={`:drawingId`}
                                    element={<Page_Drawing />}
                                />
                            </Route>
                            <Route
                                path={`demo`}
                                element={<Page_DemoProject />}
                            />
                        </Route>

                        {/* --> REDIRECTION */}
                        <Route
                            path={`*`}
                            element={<Navigate to={`settings`} />}
                        />
                    </Route>
                </Route>
                {/* Redirection en cas de mauvaise route */}
                <Route path="*" element={<HandleRedirection />} />
            </Routes>
        </BrowserRouter>
    );
};
