import { Box, Skeleton, Typography } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { useShowSubTrade } from "src/api/tms-projects/subTrades";
import { Type_show_subTrade } from "src/api/tms-projects/subTrades/types";
import {
    Select,
    Type_Props_Select,
    Type_SelectOptionItem,
} from "src/components/Components_Common/forms/reactHookFormComponents/Select/Select";
import { Type_ReadonlyComponent } from "src/components/Components_Common/types";
import { useCoreIntl } from "src/hooks/useCoreIntl";

const formatCompaniesList = (
    subTrade: Type_show_subTrade,
): Type_SelectOptionItem[] => {
    if (!subTrade?.companies) return [];

    return subTrade?.companies.map((item) => ({
        value: item ? item.id : "",
        label: item ? item.name : "",
    }));
};

type Type_Props_SelectTradeCompany = Omit<Type_Props_Select, "options"> &
    Type_ReadonlyComponent & {
        subTradeId?: number;
        autoSelectIfOneElement?: boolean;
    };

export const SelectSubTradeCompany = ({
    subTradeId,
    name,
    autoSelectIfOneElement = false,
    ...props
}: Type_Props_SelectTradeCompany) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Form");
    const { setValue } = useFormContext(); // retrieve those props

    const [options, setOptions] = useState<Type_SelectOptionItem[]>([]);

    const { isFetching, data } = useShowSubTrade(subTradeId);
    useEffect(() => {
        if (!isFetching && data) {
            const formattedData = formatCompaniesList(data);

            setOptions([
                ...(formattedData?.length > 1
                    ? [
                          {
                              value: "",
                              label: "None",
                              disabled: true,
                          },
                      ]
                    : []),
                ...formattedData,
            ]);
            if (
                autoSelectIfOneElement &&
                formattedData.length === 1 &&
                formattedData[0].value
            ) {
                console.log(
                    "AUTOSELECT setValue formattedData[0]",
                    formattedData[0].value,
                );
                setValue(name, formattedData[0].value);
            }
            if (formattedData.length === 0) {
                setValue(name, null);
            }
        }
    }, [isFetching, data]);

    return (
        <>
            {isFetching && (
                <Skeleton
                    animation="wave"
                    variant="rounded"
                    height={30}
                    width={"100%"}
                />
            )}
            {!isFetching && subTradeId && options.length > 0 && (
                <Select name={name} options={options} {...props} />
            )}
            {!isFetching && (!subTradeId || options.length === 0) && (
                <Box sx={{ height: 30, display: "flex", alignItems: "center" }}>
                    <Typography>{fmt("Placeholder.NoCompany")}</Typography>
                </Box>
            )}
        </>
    );
};
