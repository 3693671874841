import { Box, SelectProps, Stack, Typography, useTheme } from "@mui/material";
import { useCallback, useMemo } from "react";

import { useIndexPriorityOfConstraints } from "src/api/tms-commons/priorityOfConstraints";
import { formatterItemPriorityOfConstraints } from "src/api/tms-commons/priorityOfConstraints/formatters";
import { Spinner } from "src/components/Components_Common";
import { TMC_Chip } from "src/components/Components_Common/Chip/Chip";
import { Type_ItemChip } from "src/components/Components_Common/forms/ChipSelect/ChipSelect";
import { ChipSelect } from "src/components/Components_Common/forms/reactHookFormComponents/ChipSelect/ChipSelect";
import { Type_ReadonlyComponent } from "src/components/Components_Common/types";
import { DEFAULT_COLORS } from "src/theme/stylesheet";
import { invertColor } from "src/utils/colors";

type Type_Props_ChipSelectPriority = SelectProps & {
    name?: string;
} & Type_ReadonlyComponent;

export const ChipSelectPriority = ({
    variant,
    readonly = false,
    disabled,
    value,
    name = "",
    ...props
}: Type_Props_ChipSelectPriority) => {
    const { isFetching, data: priorityOfConstraints } =
        useIndexPriorityOfConstraints() || {};
    const theme = useTheme();

    // Memoize the list of priorities
    const listPriorities = useMemo(
        () => formatterItemPriorityOfConstraints(priorityOfConstraints || []),
        [priorityOfConstraints],
    );

    // Memoize the getPriority function
    const getPriority = useCallback((): { color: string; label: string } => {
        const priority: Type_ItemChip | undefined = listPriorities.find(
            (priority) => priority.value === value,
        );
        if (priority) {
            return {
                color: priority.chipColor || DEFAULT_COLORS.chipPriority,
                label: priority.label,
            };
        }
        return { color: DEFAULT_COLORS.chipPriority, label: "" };
    }, [value, listPriorities]);

    const priority = useMemo(() => getPriority(), [getPriority]);

    const renderPriorityOption = useCallback((option: Type_ItemChip) => {
        return (
            <Stack direction="row" width="100%" alignItems="center" gap={4}>
                {colorBox(option?.chipColor)}
                <Typography variant="body1" color="text.primary">
                    {option.label}
                </Typography>
            </Stack>
        );
    }, []);

    const colorBox = useCallback((color = DEFAULT_COLORS.chipPriority) => {
        return (
            <Box
                sx={{
                    bgcolor: color,
                    width: 24,
                    height: 24,
                    borderRadius: 0.5,
                    border: `1px ${theme?.palette?.border?.default} solid`,
                }}
            />
        );
    }, []);

    if (readonly)
        return (
            <TMC_Chip
                height={24}
                backgroundColor={priority.color}
                colorText={invertColor(priority.color)}
                label={priority.label}
            />
        );
    if (isFetching) return <Spinner size="small" />;
    return (
        <ChipSelect
            items={listPriorities}
            renderOption={renderPriorityOption}
            variant={variant || "standard"}
            disabled={disabled}
            bgColor={priority.color}
            textColor={invertColor(priority.color)}
            name={name}
            {...props}
        />
    );
};
