import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    PutReturnType,
    remove,
    RemoveReturnType,
    Type_RequestConfig,
} from "src/api/fetch";
import { getFilterJoin, Type_Filter_Areas } from "src/api/filters";
import { TasksPath, Url } from "src/api/paths";
import {
    Type_sch_post_task,
    Type_sch_put_task,
} from "src/api/tms-scheduling/tasks/types";

export const indexTasks = (
    requestConfig: Type_RequestConfig,
    filters: Type_Filter_Areas = {},
): GetReturnType => {
    const urlParams: string = getFilterJoin(filters);

    return get(
        `${Url.SCHEDULING}${TasksPath.TASKS}${urlParams}`,
        requestConfig,
    );
};

export const showTask = (
    id: number,
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(`${Url.SCHEDULING}${TasksPath.TASKS}/${id}`, requestConfig);
};

export const createTask = (
    data: Type_sch_post_task,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return post(data, `${Url.SCHEDULING}${TasksPath.TASKS}`, requestConfig);
};

export const updateTask = (
    data: Type_sch_put_task,
    requestConfig: Type_RequestConfig,
): PutReturnType => {
    return put(
        data,
        `${Url.SCHEDULING}${TasksPath.TASKS}/${data.id}`,
        requestConfig,
    );
};

export const removeTask = (
    id: number,
    requestConfig: Type_RequestConfig,
): RemoveReturnType => {
    return remove(
        { id },
        `${Url.SCHEDULING}${TasksPath.TASKS}/${id}`,
        requestConfig,
    );
};
