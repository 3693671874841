import { FormikValues } from "formik";
import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";

import { useIndexMetadataProjectsForForm } from "src/api/tms-commons/metadata";
import { mutationUpdateProject } from "src/api/tms-commons/projects";
import { formatterShowProject } from "src/api/tms-commons/projects/formatters";
import { showProject } from "src/api/tms-commons/projects/services";

import { Projects_DrawerContentComponent } from "./Projects_DrawerContent.component";

export type Type_Projects_DrawerContentContainerProps = {
    onClose: () => void;
    onSubmit?: any;
    projectIdToUpdate: number;
    step: string;
};

/**
 * @deprecated new Drawer coming up
 * @param onClose
 * @param _onSubmit
 * @param projectIdToUpdate
 * @param step
 * @constructor
 */
export const Projects_DrawerContentContainer = ({
    onClose,
    onSubmit: _onSubmit,
    projectIdToUpdate,
    step,
}: Type_Projects_DrawerContentContainerProps) => {
    const [tab, setTab] = useState<string>(step);

    const [data, setData] = useState<any>({});
    const { mutateAsync: mutateUpdate } = mutationUpdateProject() || {};

    const { isFetching: isFetching_MetadataProjects, data: metadataProjects } =
        useIndexMetadataProjectsForForm({
            enabled: true,
        }) || {};

    const { isFetching: isFetching_ProjectById } = useQuery(
        ["showProject", projectIdToUpdate],
        () => showProject(projectIdToUpdate || 0),
        {
            onError: (err) => {
                console.log(err);
            },
            onSuccess: (res) => {
                const data = res.data.data;
                setData(formatterShowProject(data) as any);
            },
            enabled: !!projectIdToUpdate,
            refetchOnWindowFocus: false,
        },
    );

    const isFetching = useMemo(
        () => isFetching_MetadataProjects || isFetching_ProjectById,
        [isFetching_MetadataProjects, isFetching_ProjectById],
    );

    const handleSubmit = async (values: FormikValues) => {
        try {
            await mutateUpdate(values);
            onClose();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Projects_DrawerContentComponent
            isFetching={isFetching}
            onClose={onClose}
            tab={tab}
            setTab={setTab}
            metadataProjects={metadataProjects || []}
            onSubmit={handleSubmit}
            data={data}
        />
    );
};
