import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";

import {
    mutationCreateTrade,
    mutationUpdateTrade,
    useShowTrade,
} from "src/api/tms-projects/trades";
import {
    formatterCreateTrade,
    formatterUpdateTrade,
} from "src/api/tms-projects/trades/formatters";
import {
    Type_post_trade,
    Type_show_trade,
} from "src/api/tms-projects/trades/types";
import { LoadingBox } from "src/components";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { ColorPicker } from "src/components/Components_Common/forms/reactHookFormComponents/ColorPicker/ColorPicker";
import { InputMultiLanguages } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages";
import { inputMultiLanguagesSchema } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages.component";
import { TextField } from "src/components/Components_Common/forms/reactHookFormComponents/TextField/TextField";
import { useFormDefaultConfig } from "src/configurations/app";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_TradeForm = {
    onClose: () => void;
    tradeId: number | null;
    action: Type_action;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    tradesColors?: string[];
};

const Schema_Trade = Yup.lazy(() => {
    return Yup.object().shape({
        names: Yup.object().shape(inputMultiLanguagesSchema.fields),
        color: Yup.string().required(FORM_ERR_FMT.REQUIRED),
        code: Yup.string().nullable(),
    });
});

export const TradeForm = ({
    onClose,
    tradeId,
    action,
    setIsLoading,
    tradesColors,
}: Type_Props_TradeForm) => {
    const { formatMessageWithPartialKey: fmtLabels } = useCoreIntl("Labels");

    const { mutateAsync: mutateCreate } = mutationCreateTrade() || {};
    const { mutateAsync: mutateUpdate } = mutationUpdateTrade() || {};

    const { isFetching, data } = useShowTrade(tradeId as number) || {};

    const form = useForm<Type_show_trade | Type_post_trade>({
        ...useFormDefaultConfig,
        defaultValues: {
            id: undefined,
            names: {},
            code: "",
            color: "",
        },
        values: data,
        resolver: yupResolver<any>(Schema_Trade),
    });

    const handleSubmit = (values: any, e?: React.BaseSyntheticEvent) => {
        e?.preventDefault;
        setIsLoading(true);

        try {
            if (action === "update") {
                mutateUpdate(formatterUpdateTrade(values));
            } else {
                delete values.id;
                mutateCreate(formatterCreateTrade(values));
            }
            onClose();
        } catch (e: any) {
            console.log(e);
        }
        setIsLoading(false);
    };

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)} id={"trade"}>
                {isFetching ? (
                    <LoadingBox />
                ) : (
                    <>
                        <Stack gap={4}>
                            <InputMultiLanguages name="names" />
                            <Stack
                                direction="row"
                                alignItems={"center"}
                                justifyContent={"space-between"}
                            >
                                <TextField
                                    name="code"
                                    label={fmtLabels(`Code`)}
                                />
                                <ColorPicker
                                    name="color"
                                    label={fmtLabels("Color")}
                                    data-testid="Trade-Drawer-color"
                                    selectedColors={tradesColors}
                                />
                            </Stack>
                        </Stack>
                    </>
                )}
            </form>
        </FormProvider>
    );
};
