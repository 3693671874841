import {
    InputAdornment,
    InputAdornmentProps,
    OutlinedInput,
    OutlinedInputProps,
    Stack,
    TextFieldProps,
    Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";

import { TMC_FormHelperText } from "src/components/Components_Common/_MuiComponentsVariants";
import { Type_ReadonlyComponent } from "src/components/Components_Common/types";

export const Styled_OutlinedInput = styled(OutlinedInput)<OutlinedInputProps>(
    ({ theme }) => ({
        paddingLeft: theme.spacing(2),
    }),
);

interface Styled_InputAdornmentProps extends InputAdornmentProps {
    ownerState?: {
        disabled?: boolean;
    };
}

export const Styled_InputAdornment = styled(
    InputAdornment,
)<Styled_InputAdornmentProps>(({ ownerState, theme }) => ({
    borderRight: `1px solid ${theme.palette.text.disabled}`,
    paddingRight: theme.spacing(2),
    height: "100%",
    maxHeight: "100%",
    margin: 0,
    "& p": {
        color: theme.palette.text.primary,
        ...(ownerState?.disabled && {
            color: theme.palette.text.disabled,
        }),
    },
}));

type Type_Props_TextField = TextFieldProps &
    Type_ReadonlyComponent & {
        name: string;
        block: string;
        description?: string;
        label?: string;
    };

export const TextFieldWithBlock = ({
    name,
    block,
    ...props
}: Type_Props_TextField) => {
    const { formState, control } = useFormContext(); // retrieve those props

    const ownerState = {
        disabled: props.disabled,
    };

    return (
        <Stack>
            <Controller
                name={name}
                control={control}
                render={({ field: { ref: inputRef, ...rest } }) => (
                    <Styled_OutlinedInput
                        data-testid={`TextFieldWithBlock-Input-${name}`}
                        label={props.label ? props.label : null}
                        autoComplete="off"
                        startAdornment={
                            <Styled_InputAdornment
                                data-testid={`TextFieldWithBlock-InputAdornment-${name}`}
                                position="start"
                                ownerState={{
                                    ...ownerState,
                                }}
                            >
                                {block}
                            </Styled_InputAdornment>
                        }
                        inputRef={inputRef}
                        disabled={props.disabled}
                        value={rest.value}
                    />
                )}
            />
            {props.description && (
                <Typography
                    variant="body2"
                    data-testid={`TextFieldWithBlock-Description-${name}`}
                >
                    {props.description}
                </Typography>
            )}
            {formState.errors[name] && (
                <TMC_FormHelperText
                    inputName={name}
                    helperText={formState?.errors[name]?.message as string}
                />
            )}
        </Stack>
    );
};
