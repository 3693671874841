import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

import { DrawerTab } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric.style";

export const Styled_Section = styled(Stack)(({ theme }) => ({
    gap: theme.spacing(4),
    padding: `${theme.spacing(2)} 0px`,
    alignItems: "flex-start",
    width: "100%",
}));

export const Styled_SectionHead = styled(Stack)(({ theme }) => ({
    gap: theme.spacing(2),
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
}));

export const Styled_DrawerTab = styled(DrawerTab)(({ theme }) => ({
    margin: theme.spacing(0, 6, 0, 0),
    padding: theme.spacing(2, 0),
    minHeight: 24,
    textTransform: "none",
}));
