import {
    Avatar,
    Divider,
    List,
    ListItemButton,
    ListItemText,
    Menu,
    Stack,
    useTheme,
} from "@mui/material";
import { Dayjs, unix } from "dayjs";
import React, { memo, useState } from "react";

import { Type_tfe_keyDate } from "src/api/tms-scheduling/keyDates/types";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { usePlanningContext } from "src/contexts/planning";
import {
    Type_event_dropDownOnRightClick,
    Type_event_message,
    useChannel,
} from "src/hooks/useChannel";
import { getCurrentFormat } from "src/utils/date";

import { actions } from "./actions";
import { Styled_MenuItemDropDownMenuRightClick } from "./DropDownMenuRightClick.style";

type Type_Props_DropDownMenuRightClickEditKeyDate = {
    name: string;
};

export const MemoizedDropDownMenuRightClickEditKeyDate = ({
    name,
}: Type_Props_DropDownMenuRightClickEditKeyDate) => {
    const planningContext = usePlanningContext();

    const initialState: Type_event_dropDownOnRightClick = {
        open: false,
        mouseX: 0,
        mouseY: 0,
        menuItems: [],
        props: null,
    };

    const [menuPosition, setMenuPosition] =
        useState<Type_event_dropDownOnRightClick>(initialState);

    const handleClose = () => setMenuPosition((_old) => initialState);

    useChannel({
        eventHandler: ({ event, data }: Type_event_message) => {
            if (event === "dropDownOnRightClickEditKeyDate") {
                setMenuPosition(data as Type_event_dropDownOnRightClick);
            }
        },
    });

    const handleConextMenu = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
        e.preventDefault();
        // Ferme le menuDropDown au clic droit dans le vide
        if (menuPosition.open) {
            handleClose();
        }
    };

    const anchorPosition = {
        top: menuPosition.mouseY,
        left: menuPosition.mouseX,
    };

    const dataTestid = "menu-dropdown-right-click-" + name;

    const { menuItems } = menuPosition;

    if (!menuPosition.open) return null;
    return (
        <Menu
            onContextMenu={handleConextMenu}
            open={menuPosition.open}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={anchorPosition}
            data-testid={dataTestid}
            sx={{ padding: 0 }}
        >
            <KeyDatesList dateKeys={menuPosition?.props?.selectedDate} />
            {!!menuItems?.length && (
                <Divider
                    orientation="horizontal"
                    textAlign="center"
                    variant="fullWidth"
                    sx={{ marginY: 2 }}
                />
            )}
            {menuItems.map((menuItem: any, index: number) => {
                if (menuItem.divider) {
                    return (
                        <Divider
                            key={index}
                            orientation="horizontal"
                            textAlign="center"
                            variant="fullWidth"
                        />
                    );
                }
                // call callback function to show item if exists
                if (!Object.prototype.hasOwnProperty.call(menuItem, "show")) {
                    return (
                        <Styled_MenuItemDropDownMenuRightClick
                            selected={menuItem.selected}
                            onClick={(event) => {
                                event.stopPropagation();
                                if (
                                    menuItem.call &&
                                    actions[
                                        menuItem.call as keyof typeof actions
                                    ]
                                ) {
                                    actions[
                                        menuItem.call as keyof typeof actions
                                    ](planningContext, menuPosition.props);
                                }
                                if (!menuItem.keepOpenOnClick) {
                                    handleClose();
                                }
                            }}
                            key={index}
                        >
                            {menuItem.icon && (
                                <Icon
                                    variant={menuItem.icon.variant}
                                    icon={menuItem.icon.name}
                                    sx={{
                                        color: menuItem.icon.color
                                            ? menuItem.icon.color
                                            : "inherit",
                                        marginRight: "6px",
                                    }}
                                    {...menuItem.icon}
                                />
                            )}
                            {menuItem.label}
                        </Styled_MenuItemDropDownMenuRightClick>
                    );
                }
            })}
        </Menu>
    );
};

type Type_Props_KeyDatesList = {
    dateKeys: {
        date: { [key: string]: any };
        keyDate: Type_tfe_keyDate;
    }[];
};

const KeyDatesList = ({ dateKeys }: Type_Props_KeyDatesList) => {
    const planningContext = usePlanningContext();

    const onClickKeyDateEdit = (id: number) => {
        actions["editAKeyDate"](planningContext, {
            id,
            date: undefined,
        });
    };

    const theme = useTheme();

    return (
        <List component="nav" sx={{ padding: 0 }}>
            {dateKeys?.map((dateKey) => {
                // eslint-disable-next-line import/no-named-as-default-member
                const date: Dayjs = unix(dateKey.date.timestamp);

                return (
                    <ListItemButton
                        dense={true}
                        sx={{ paddingY: 1 }}
                        key={dateKey.keyDate.id}
                        onClick={() => onClickKeyDateEdit(dateKey.keyDate.id)}
                    >
                        <Avatar
                            sx={{
                                backgroundColor: dateKey.keyDate.color,
                                width: "16px",
                                height: "16px",
                                marginRight: theme.spacing(4),
                                border: `1px solid ${theme?.palette?.tertiary?.light}`,
                                borderRadius: theme.shape.borderRadiusChip,
                            }}
                        >
                            {" "}
                        </Avatar>
                        <ListItemText
                            sx={{
                                margin: 0,
                                "& .MuiListItemText-secondary": {
                                    ...theme.typography.body3,
                                },
                            }}
                            primary={<Stack>{dateKey?.keyDate?.name}</Stack>}
                            secondary={date.format(getCurrentFormat())}
                        />
                    </ListItemButton>
                );
            })}
        </List>
    );
};

MemoizedDropDownMenuRightClickEditKeyDate.displayName =
    "DropDownMenuRightClick";

export const DropDownMenuRightClickEditKeyDate = memo(
    MemoizedDropDownMenuRightClickEditKeyDate,
);
