import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    PutReturnType,
    remove,
    RemoveReturnType,
    Type_RequestConfig,
} from "src/api/fetch";
import { SubTradePath, Url } from "src/api/paths";
import {
    Type_prj_post_subTrade,
    Type_prj_put_subTrade,
} from "src/api/tms-projects/subTrades/types";

export const statusSubTrade = (
    data: Type_prj_put_subTrade,
    requestConfig: Type_RequestConfig,
): PutReturnType => {
    return put(
        data,
        `${Url.PROJECTS}${SubTradePath.SUB_TRADES}/${data.id}`,
        requestConfig,
    );
};

export const createSubTrade = (
    data: Type_prj_post_subTrade,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return post(
        data,
        `${Url.PROJECTS}${SubTradePath.SUB_TRADES}`,
        requestConfig,
    );
};

export const updateSubTrade = (
    data: Type_prj_put_subTrade,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return put(
        data,
        `${Url.PROJECTS}${SubTradePath.SUB_TRADES}/${data.id}`,
        requestConfig,
    );
};

export const showSubTrade = (
    id: number,
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(
        `${Url.PROJECTS}${SubTradePath.SUB_TRADES}/${id}`,
        requestConfig,
    );
};

export const deleteSubTrade = (
    id: number,
    requestConfig: Type_RequestConfig,
): RemoveReturnType => {
    return remove(
        { id },
        `${Url.PROJECTS}${SubTradePath.SUB_TRADES}/${id}`,
        requestConfig,
    );
};

export const selectListSubTrades = (
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(`${Url.PROJECTS}${SubTradePath.SUB_TRADES_LIST}`, requestConfig);
};
