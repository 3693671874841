import Konva from "konva";
import { useCallback, useState } from "react";
import { Circle } from "react-konva";

import { Type_point } from "src/components/Components_Common/canvas/types";
import {
    colorFlowCircleFill,
    colorFlowCircleStroke,
    minFlowDist,
    radiusFlowCircle,
    radiusFlowCircleOver,
    widthFlowCircleStroke,
} from "src/components/Components_Teamoty/Flow/Flow.const";
import { Enum_typeCircleFlow } from "src/components/Components_Teamoty/Flow/Flow.enum";
import { onGrid } from "src/components/Components_Teamoty/Flow/tools/onGrid";

import { Type_Props_FlowCircle } from "./FlowCircle.type";

export const FlowCircle = ({
    task,
    type,
    changeNewLink,
    ...props
}: Type_Props_FlowCircle) => {
    const [onPoint, setOnPoint] = useState<boolean>(false);

    const name: string = "circleFlow";

    const getDistance = (p1: Type_point, p2: Type_point): number => {
        return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
    };

    const startLink = useCallback(
        (e: Konva.KonvaEventObject<DragEvent>): void => {
            e.cancelBubble = true;

            e.target.x(e.currentTarget.attrs.startX);
            e.target.y(e.currentTarget.attrs.startY);

            changeNewLink({
                start: true,
                withNewTask: true,
                taskFrom: task.id,
                typeFrom: type,
                ptFrom: {
                    x: e.currentTarget.attrs.startX,
                    y: e.currentTarget.attrs.startY,
                },
                ptTo: {
                    x: e.currentTarget.attrs.startX,
                    y: e.currentTarget.attrs.startY,
                },
            });
        },
        [],
    );

    const moveLink = useCallback(
        (e: Konva.KonvaEventObject<DragEvent>): void => {
            e.cancelBubble = true;

            e.target.x(e.currentTarget.attrs.startX);
            e.target.y(e.currentTarget.attrs.startY);

            const shape: Konva.Shape = e.currentTarget as Konva.Shape;
            const stage: Konva.Stage | null = shape.getStage();

            if (stage) {
                let mousePos = stage.getRelativePointerPosition() as Type_point;
                if (mousePos) {
                    const layer: Konva.Layer | null = shape.getLayer();
                    let withNewTask: boolean = true;
                    if (layer) {
                        const nodes = layer.find("." + name);
                        nodes.forEach((node): void => {
                            if (node.attrs.id === task.id.toString()) return;
                            const dist: number = getDistance(
                                {
                                    x: node.attrs.x,
                                    y: node.attrs.y,
                                },
                                {
                                    x: mousePos.x,
                                    y: mousePos.y,
                                },
                            );
                            if (dist < minFlowDist) {
                                withNewTask = false;
                                mousePos = node.getPosition();
                                node.to({
                                    radius: radiusFlowCircleOver,
                                    duration: 0,
                                });
                            } else {
                                node.to({
                                    radius: radiusFlowCircle,
                                    duration: 0,
                                });
                            }
                        });
                    }

                    changeNewLink({
                        withNewTask: withNewTask,
                        posNewTask: onGrid({
                            x: mousePos.x,
                            y: mousePos.y,
                        }),
                        ptTo: {
                            x: mousePos.x,
                            y: mousePos.y,
                        },
                    });
                }
            }
        },
        [],
    );

    const endLink = useCallback((e: Konva.KonvaEventObject<DragEvent>) => {
        e.cancelBubble = true;

        e.target.x(e.currentTarget.attrs.startX);
        e.target.y(e.currentTarget.attrs.startY);

        const shape: Konva.Shape = e.currentTarget as Konva.Shape;
        const stage: Konva.Stage | null = shape.getStage();

        let idTo = -1;
        let typeTo: Enum_typeCircleFlow = Enum_typeCircleFlow.Start;

        if (stage) {
            const mousePos = stage.getRelativePointerPosition();
            if (mousePos) {
                const layer: Konva.Layer | null = shape.getLayer();
                if (layer) {
                    const nodes = layer.find("." + name);
                    nodes.forEach((node): void => {
                        if (node.attrs.id === task.id.toString()) return;

                        const dist: number = getDistance(
                            {
                                x: node.attrs.x,
                                y: node.attrs.y,
                            },
                            {
                                x: mousePos.x,
                                y: mousePos.y,
                            },
                        );
                        if (dist < minFlowDist) {
                            idTo = parseInt(node.attrs.id);
                            typeTo = parseInt(node.attrs.type);
                        }

                        node.to({
                            radius: radiusFlowCircle,
                            duration: 0,
                        });
                    });
                }
            }
        }

        changeNewLink({
            start: false,
            taskTo: idTo,
            typeTo: typeTo,
        });
    }, []);

    const mouseOver = useCallback((): void => {
        setOnPoint(true);
    }, []);

    const mouseOut = useCallback((): void => {
        setOnPoint(false);
    }, []);

    return (
        <Circle
            id={task.id.toString()}
            type={type}
            name={name}
            x={props.x}
            y={props.y}
            radius={onPoint ? radiusFlowCircleOver : radiusFlowCircle}
            stroke={colorFlowCircleStroke}
            fill={colorFlowCircleFill}
            draggable={true}
            startX={props.x}
            startY={props.y}
            strokeWidth={widthFlowCircleStroke}
            hitStrokeWidth={15}
            onMouseOver={mouseOver}
            onMouseOut={mouseOut}
            onDragStart={startLink}
            onDragMove={moveLink}
            onDragEnd={endLink}
        />
    );
};
