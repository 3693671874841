import { Components } from "@mui/material";

export const MuiMenuItemTheme: Components["MuiMenuItem"] = {
    styleOverrides: {
        root: ({ theme }: any) => ({
            borderRadius: theme.shape.borderRadiusSmall,
            "&&.Mui-selected": {
                color: theme.palette.primary.main,
            },
        }),
    },
};
