import { InputNumberPropsType } from "./InputNumber.container";

export type InputNumberType = {
    type: "inputNumber";
    name: string;
    required?: boolean;
    step?: number;
    label?: {
        label?: string;
        languageLabel?: string;
    };
    unit?: {
        label?: string;
        languageLabel?: string;
    };
    placeholder?: {
        label?: string;
        languageLabel?: string;
    };
};

export const InputNumberComponent = (_props: InputNumberPropsType) => {
    return (
        <>
            <p>InputNumberComponent</p>
        </>
    );
};
