import { InputCheckbox as checkbox, InputRadio as radio } from "./Form_Checks";
import {
    InputAnchor as inputAnchor,
    InputButton as inputButton,
    InputCancel as inputCancel,
    InputSubmit as inputSubmit,
} from "./Form_CTAs";
import {
    InputDate as date,
    InputDateRange as inputDateRange,
    InputDateTime as inputDateTime,
    InputDateTimeRange as inputDateTimeRange,
    InputEmail as inputEmail,
    InputMultiple as inputMultiple,
    InputNumber as inputNumber,
    InputPassword as inputPassword,
    InputPasswordConfirm as inputPasswordConfirm,
    InputPeriod as inputPeriod,
    InputPhone as inputPhone,
    InputRegex as inputRegex,
    InputStatic as inputStatic,
    InputTime as inputTime,
    InputText as text,
} from "./Form_Inputs";
import {
    InputMultipleSelect as inputMultipleSelect,
    InputSelect as select,
} from "./Form_Selects";

export const Form_Components: any = {
    inputMultiple,
    inputPhone,
    date,
    text,
    inputEmail,
    inputNumber,
    inputPassword,
    inputPeriod,
    inputStatic,
    inputTime,
    inputDateRange,
    inputDateTime,
    inputRegex,
    inputDateTimeRange,
    inputPasswordConfirm,
    select,
    inputMultipleSelect,
    checkbox,
    radio,
    inputAnchor,
    inputButton,
    inputSubmit,
    inputCancel,
};
