import { Badge, BadgeProps, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";

import { useShowProjectCompany } from "src/api/tms-projects/projectCompanies";
import { Type_show_projectCompany } from "src/api/tms-projects/projectCompanies/types";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useCoreIntl } from "src/hooks/useCoreIntl";

const Styled_Badge = styled(Badge)<BadgeProps>(() => ({
    width: 32,
    "& .MuiBadge-badge": {
        top: 6,
        left: 10,
    },
}));

type Type_Props_CompanyTeamBadge = BadgeProps & {
    companyId: number | null;
};

export const CompanyTeamBadge = ({
    companyId,
    ...props
}: Type_Props_CompanyTeamBadge) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerTasks.Main.Tooltip",
    );

    const [data, setData] = useState<Type_show_projectCompany>();

    const { data: companyFetched } = useShowProjectCompany(companyId as number);

    useEffect(() => {
        if (companyFetched) setData(companyFetched);
    }, [companyFetched]);
    const teamCount = useMemo(() => {
        return (companyId && data?.team) ?? "-";
    }, [data, companyId]);

    return (
        <Styled_Badge badgeContent={teamCount} {...props}>
            <Tooltip title={fmt("Team")} placement={"left"}>
                <Icon variant={"light"} icon={"user"} fontSize="small" />
            </Tooltip>
        </Styled_Badge>
    );
};
