import { FormLabel, TextField as MuiTextfield, Stack } from "@mui/material";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import * as React from "react";

import { Type_ReadonlyComponent } from "src/components/Components_Common/types";

export type Type_Props_TextField = TextFieldProps & Type_ReadonlyComponent;

export const TextField = ({
    label,
    readonly = false,
    ...props
}: Type_Props_TextField) => {
    return (
        <Stack gap={0.5}>
            {label && <FormLabel>{label}</FormLabel>}
            <MuiTextfield {...props} disabled={readonly || props.disabled} />
        </Stack>
    );
};
