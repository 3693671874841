import { useMutation, useQuery } from "react-query";

import { PeopleTaskKeys } from "src/api/tms-scheduling/keys";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

import { formatterIndexPeopleTasks } from "./formatter";
import {
    createPeopleTask,
    indexPeopleTask,
    removePeopleTask,
} from "./services";
import { Type_sch_post_peopleTask } from "./types";

export const useIndexPeopleTask = (taskId: number, enabled: boolean = true) => {
    const { addWarning } = useToast();
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");

    const isEnabled: boolean =
        !!requestConfig.projectId &&
        !!requestConfig.subProjectId &&
        !!requestConfig.versionId &&
        !!taskId &&
        enabled;
    return useQuery({
        queryKey: [PeopleTaskKeys.INDEX, { ...requestConfig, taskId }],
        queryFn: () => indexPeopleTask({ ...requestConfig, taskId }),
        enabled: isEnabled,
        refetchOnWindowFocus: false,
        select: (data) => {
            return formatterIndexPeopleTasks(data.data.data);
        },
        onError: (err) => {
            console.error(fmtErrors("GenericError", {}), err);
            addWarning({ description: fmtErrors("GenericError", {}) });
            return err;
        },
    });
};

export const mutationCreatePeopleTask = () => {
    const { addWarning } = useToast();
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");

    return useMutation({
        mutationFn: (data: Type_sch_post_peopleTask) =>
            createPeopleTask(data, { ...requestConfig }),
        onSuccess: (data: any) => {
            if (!data.success || !data?.data?.data)
                throw new Error("Wrong format data: mutationCreatePeopleTask");
            return data;
        },
        onError: (err: any): void => {
            console.error(fmtErrors("GenericError", {}), err);
            addWarning({ description: fmtErrors("GenericError", {}) });
            return err;
        },
    });
};

export const mutationDeletePeopleTask = (
    taskId: number,
    callback: (id: number) => void,
) => {
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");
    const { requestConfig } = useProject();
    return useMutation({
        mutationFn: (id: number) =>
            removePeopleTask(id, { ...requestConfig, taskId }),
        onSuccess: (_data, id: number) => {
            callback(id);
        },
        onError: (err: any) => {
            console.error(fmtErrors("GenericError", {}), err);
            addWarning({ description: fmtErrors("GenericError", {}) });
            return err;
        },
    });
};
