import { IconButtonProps, Popover, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";

export const Styled_Popover = styled(Popover)(({ theme }: any) => ({
    "&.MuiPopover-root": {
        "& .MuiPopover-paper": {
            padding: theme.spacing(2),
            width: theme.spacing(62),
        },
    },
}));

interface Type_Props_Styled_ButtonColorGrid extends IconButtonProps {
    ownerState?: {
        fullPaletteColor?: string;
    };
}

export const Styled_ButtonColorGrid = styled(
    IconButton,
)<Type_Props_Styled_ButtonColorGrid>(({ theme, ownerState }) => ({
    borderRadius: theme.shape.borderRadiusSmall,
    height: theme.spacing(6),
    width: theme.spacing(6),
    border: "solid 1px rgba(00, 00, 00, 0.2)",

    backgroundColor: `${ownerState?.fullPaletteColor}`,

    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    transition: `all .1s ease-in-out`,
    ":hover": {
        transform: `scale(1.1)`,
        backgroundColor: `${ownerState?.fullPaletteColor}`,
    },
    "&.Mui-disabled": {
        backgroundColor: `${ownerState?.fullPaletteColor}`,
        opacity: theme.opacity.low,
    },
}));

export const Styled_StackColorAdornment = styled(Stack)(({ theme }: any) => ({
    borderRadius: "100%",
    height: theme.spacing(5),
    width: theme.spacing(5),
    border: "solid 1px rgba(00, 00, 00, 0.2)",
}));
