import {
    Type_com_index_company,
    Type_com_index_companyWithMeta,
    Type_com_post_company,
    Type_com_put_company,
    Type_com_selectList_company,
    Type_com_show_company,
    Type_index_company,
    Type_index_companyWithMeta,
    Type_put_company,
    Type_selectList_company,
    Type_show_company,
} from "src/api/tms-commons/companies/types";

export const formatterIndexCompanies = (
    companies: Type_com_index_company[],
): Type_index_company[] => {
    if (!companies) return [];
    return companies.map((company: Type_com_index_company) =>
        formatterIndexCompany(company),
    );
};

export const formatterIndexCompany = (
    company: Type_com_index_company,
): Type_index_company => {
    return {
        id: company.id,
        name: company.name,
        nationalId: company.nationalId,
        postalCode: company.postalCode ?? "",
        city: company.city ?? "",
        cityZipCode: company.city + " (" + company.postalCode + ")",
        country: company.country ?? "",
        enabled: company.enabled,
        metadata: company.metadata,
        users: company.users,
        usersCount: company.usersCount,
        thumbnail: company.thumbnail,
    };
};

export const formatterIndexCompaniesWithMeta = (
    data: Type_com_index_companyWithMeta,
): Type_index_companyWithMeta => {
    const companies = formatterIndexCompanies(data.data);
    return {
        companies: companies,
        total: data.meta && data.meta.total,
    };
};

export const formatterSelectListCompanies = (
    companies: Type_com_selectList_company[],
): Type_selectList_company[] => {
    if (!companies) return [];
    return companies.map((company: Type_com_selectList_company) =>
        formatterSelectListCompany(company),
    );
};

export const formatterSelectListCompany = (
    company: Type_com_selectList_company,
): Type_selectList_company => {
    return {
        id: company.id,
        name: company.name,
    };
};

export const formatterShowCompany = (
    company: Type_com_show_company,
): Type_show_company => {
    return {
        id: company.id,
        name: company.name,
        nationalId: company.nationalId === null ? "" : company.nationalId,
        postalCode: company.postalCode,
        city: company.city,
        country: company.country,
        enabled: company.enabled,
        metadata: company.metadata,
        users: company.users,
        usersCount: company.usersCount,
        thumbnail: company.thumbnail,
    };
};

export const formatterPostCompany = (
    company: Type_com_show_company,
): Type_com_post_company => {
    return {
        name: company.name,
        nationalId: company.nationalId,
        postalCode: company.postalCode,
        city: company.city,
        country: company.country,
        metadata: company.metadata ?? undefined,
        users: company.users,
        thumbnail: company.thumbnail ?? undefined,
    };
};

export const formatterPutCompany = (
    company: Type_com_show_company,
): Type_com_put_company => {
    return {
        name: company.name,
        nationalId: company.nationalId,
        postalCode: company.postalCode,
        city: company.city,
        country: company.country,
        enabled: company.enabled,
        metadata: company.metadata ?? undefined,
        users: company.users,
        thumbnail: company.thumbnail ?? undefined,
    };
};

export const formatterShowCompanyToIndex = (
    company: Type_show_company,
): Type_index_company => {
    return {
        ...company,
        cityZipCode: company.postalCode,
        metadata: company.metadata,
        thumbnail: company.thumbnail,
    };
};

export const formatterStatusCompany = (
    company: Type_put_company,
): Type_com_put_company => {
    return {
        enabled: company.enabled,
    };
};
