import { Type_gtw_me, Type_me } from "src/api/tms-gateway/auth/types";

export const formatterCurrentUser = (user: Type_gtw_me): null | Type_me => {
    if (!user) return null;
    const { company_id, role, ...rest } = user;
    const permissions =
        role?.permissions?.map((p: { id: number; name: string }) => p.name) ||
        [];
    return {
        ...rest,
        companyId: company_id as number,
        role,
        permissions,
    };
};
