import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";

import {
    TMC_FormHelperText,
    TMC_TextFieldPassword_Deprecated,
} from "src/components/Components_Common/_MuiComponentsVariants";

type Type_Props_TextFieldPassword = {
    name: string;
    label: string;
    dataTestid: string;
};

export const TextFieldPassword = ({
    name,
    label,
    dataTestid,
}: Type_Props_TextFieldPassword) => {
    const { formState, control } = useFormContext();

    return (
        <>
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <TMC_TextFieldPassword_Deprecated
                        {...field}
                        label={label}
                        error={Boolean(formState.errors[name])}
                        inputProps={{
                            "data-testid": dataTestid,
                        }}
                    />
                )}
            />
            {formState.errors[name] && (
                <TMC_FormHelperText
                    inputName={name}
                    helperText={formState?.errors[name]?.message as string}
                />
            )}
        </>
    );
};
