import { InputPhoneComponent } from "./InputPhone.component";

export type InputPhonePropsType = {
    name: string;
    label: string;
    placeholder?: string;
    onChange?: any;
    defaultValue?: string;
};

export const InputPhoneContainer = (props: InputPhonePropsType) => {
    return <InputPhoneComponent {...props} />;
};
