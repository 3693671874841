import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    remove,
    RemoveReturnType,
    Type_RequestConfig,
} from "src/api/fetch";
import { PunchlistPath, Url } from "src/api/paths";

import { Type_sch_post_punchlist } from "./types";

export const indexPunchlists = (
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(`${Url.SCHEDULING}${PunchlistPath.PUNCHLISTS}`, requestConfig);
};

export const createPunchlist = (
    data: Type_sch_post_punchlist,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return post(
        data,
        `${Url.SCHEDULING}${PunchlistPath.PUNCHLISTS}`,
        requestConfig,
    );
};

export const deletePunchlist = (
    id: number,
    requestConfig: Type_RequestConfig,
): RemoveReturnType => {
    return remove(
        { id },
        `${Url.SCHEDULING}${PunchlistPath.PUNCHLISTS}/${id}`,
        requestConfig,
    );
};
