import * as React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { Type_RequestConfig } from "src/api/fetch";
import { UserKeys } from "src/api/tms-users/keys";
import {
    formatterCreateUserInvitation,
    formatterIndexUser,
    formatterIndexUsersWithMeta,
    formatterUpdateUser,
} from "src/api/tms-users/users/formatters";
import {
    createUser,
    deleteUser,
    indexUsers,
    updateUser,
} from "src/api/tms-users/users/services";
import {
    Type_index_user,
    Type_post_userInvitation,
    Type_put_user,
} from "src/api/tms-users/users/types";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const useIndexUsers = (requestConfig?: Type_RequestConfig) => {
    return useQuery({
        queryKey: [UserKeys.INDEX, requestConfig],
        queryFn: () => indexUsers(requestConfig),
        refetchOnWindowFocus: false,
        select: (res) => {
            if (!res?.success || !res?.data) {
                throw new Error("Wrong format data: useIndexUsers");
            }
            return formatterIndexUsersWithMeta(res.data);
        },
    });
};

export const mutationStatusUser = (requestConfig: Type_RequestConfig) => {
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addWarning } = useToast();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (newStatus: any) => {
            return updateUser({
                enabled: newStatus.enabled,
                id: newStatus.id as number,
            });
        },
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationStatusUser");
            }

            await queryClient.invalidateQueries([
                UserKeys.INDEX,
                requestConfig,
            ]);
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationUpdateUser = (callback: (d: Type_index_user) => void) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.UserInformation",
    );
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();
    return useMutation({
        mutationFn: (user: Type_put_user) => {
            return updateUser(formatterUpdateUser(user));
        },
        onSuccess: (data: any) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationUpdateUser");
            }
            callback(formatterIndexUser(data.data.data));
            addSuccess({
                description: fmt("ToastSuccess"),
            });
        },
        onError: (err: any) => {
            console.log(err);
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationCreateUserInvitation = (
    requestConfig: Type_RequestConfig,
) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.UserInvitation",
    );
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (user: Type_post_userInvitation) => {
            return createUser(formatterCreateUserInvitation(user));
        },
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: mutationCreateUserInvitation",
                );
            }

            const newUser = formatterIndexUser(data.data.data);
            addSuccess({
                description: fmt("ToastSuccess", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        user: newUser.email,
                    },
                }),
            });
            await queryClient.invalidateQueries([
                UserKeys.INDEX,
                requestConfig,
            ]);
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationDeleteUser = (requestConfig: Type_RequestConfig) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.UserInvitation",
    );
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (id: number) => {
            return deleteUser(id);
        },
        onSuccess: async (data) => {
            if (!data?.success) {
                throw new Error("Wrong format data: mutationDeleteUser");
            }

            await queryClient.invalidateQueries([
                UserKeys.INDEX,
                requestConfig,
            ]);

            addSuccess({
                description: fmt("ToastSuccessDelete"),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};
