import { Type_planningArea } from "@cimba-digital-construction-solution/utils/dist/decoder/types";
import { KonvaEventObject } from "konva/lib/Node";
import React from "react";
import { Group, Rect } from "react-konva";

import { useMenuItems } from "src/components/Components_Teamoty/Planning/Dialogs/menusConf";
import { PlanningPlanningDrawerResizerY } from "src/components/Components_Teamoty/Planning/Drawer/PlanningPlanningDrawerResizerY";
import {
    colorPlanningLine,
    offsetPlanningArea,
} from "src/components/Components_Teamoty/Planning/Planning.const";
import { useChannel } from "src/hooks/useChannel";

import { PlanningArea } from "./PlanningArea";
import { Type_Props_PlanningAreas } from "./PlanningArea.type";

export const PlanningAreas = ({
    areasOffset,
    x,
    y,
    width,
    height,
    heightArea,
    onResize,
    onChangeNameArea,
    onChangeCloseArea,
}: Type_Props_PlanningAreas) => {
    const elmAreas: Array<React.JSX.Element> = [];

    const recursiveArea = (
        areas: Type_planningArea[],
        x: number = 0,
        y: number = 0,
    ): void => {
        areas.map((area: Type_planningArea): void => {
            const offset: number = area.offset ? area.offset * heightArea : 0;

            if (
                y - areasOffset.offset + area.size * heightArea > 0 &&
                y - areasOffset.offset < height
            ) {
                elmAreas.push(
                    <PlanningArea
                        key={"a" + area.key}
                        area={area}
                        x={x}
                        y={y}
                        width={width}
                        heightArea={heightArea}
                        onChangeNameArea={onChangeNameArea}
                        onChangeCloseArea={onChangeCloseArea}
                    />,
                );
            }

            if (!area.close && area?.areas) {
                recursiveArea(area.areas, x + offsetPlanningArea, y + offset);
            }

            y += area.size * heightArea;
        });
    };

    recursiveArea(areasOffset.areas);

    const { sendEvent } = useChannel({});
    const { menuItemsPlanningAreas } = useMenuItems();

    const handleContextMenuElmAreas = ({
        evt,
    }: KonvaEventObject<PointerEvent>) => {
        evt.preventDefault();
        evt.stopPropagation();
        const { clientX, clientY } = evt;

        sendEvent("dropDownOnRightClick", {
            open: true,
            mouseX: clientX,
            mouseY: clientY,
            menuItems: menuItemsPlanningAreas,
        });
    };

    return (
        <Group
            x={x}
            y={y}
            clipX={0}
            clipY={0}
            clipWidth={width}
            clipHeight={height}
        >
            <Rect
                width={width - 1}
                height={height}
                stroke={colorPlanningLine}
                strokeWidth={1}
            />
            <Group
                offsetY={Math.round(areasOffset.offset)}
                onContextMenu={handleContextMenuElmAreas}
            >
                {elmAreas}
            </Group>
            <PlanningPlanningDrawerResizerY
                x={x - 1.5}
                y={y}
                width={width}
                height={height}
                minSize={75}
                onResize={(size: number): void => {
                    onResize(size + 3);
                }}
            />
        </Group>
    );
};
