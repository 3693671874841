import React from "react";

import { Icon } from "src/components/Components_Common/Icon/Icon";

import { InputMultipleSelectPropsType } from "./InputMultipleSelect.container";

type field = {
    value?: string;
    default?: boolean;
    label?: {
        label?: string;
        languageLabel?: string;
    };
};

export type InputMultipleSelectType = {
    type: "inputSelect";
    name: string;
    required?: boolean;
    search?: boolean;
    withButton?: boolean;
    label?: {
        label?: string;
        languageLabel?: string;
    };
    placeholder?: {
        label?: string;
        languageLabel?: string;
    };
    fields?: field[];
    unit?: {
        label?: string;
        languageLabel?: string;
    };
};

type OtherPropsType = {
    isOpen: boolean;
    handleOpen: any;
};

export const InputMultipleSelectComponent = ({
    label,
    data,
    isOpen,
    handleOpen,
}: InputMultipleSelectPropsType & OtherPropsType) => {
    return (
        <div className="flex items-center justify-end space-x-4">
            <label
                htmlFor={label}
                className="whitespace-nowrap block text-sm font-medium text-gray-700"
            >
                {label}
            </label>
            <div className="relative">
                <div
                    className="py-2 px-4 cursor-pointer block w-96 rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm flex justify-between"
                    onClick={() => handleOpen(!isOpen)}
                >
                    <Icon variant="solid" icon="chevron-down" />
                </div>
                {isOpen && (
                    <div className="absolute z-2 left-0 py-2 rounded-lg shadow-md">
                        <div className="px-4 py-2">
                            <label className="flex items-center">
                                <input
                                    type="checkbox"
                                    value="ALL"
                                    className="form-checkbox"
                                />
                                <span className="ml-2">Tout sélectionner</span>
                            </label>
                        </div>
                        {data.map((item: any, index: number) => (
                            <div key={index} className="px-4 py-2">
                                <label className="flex items-center">
                                    <input
                                        type="checkbox"
                                        value={item.value}
                                        className="form-checkbox"
                                    />
                                    <span className="ml-2">{item.title}</span>
                                </label>
                            </div>
                        ))}
                        <div className="px-4 py-2">
                            <button
                                className="text-red-500 hover:underline"
                                onClick={() => handleOpen(false)}
                            >
                                Annuler
                            </button>
                            <button
                                className="ml-4 text-green-500 hover:underline"
                                onClick={() => handleOpen(false)}
                            >
                                OK
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
