import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    PutReturnType,
    remove,
    RemoveReturnType,
    Type_RequestConfig,
} from "src/api/fetch";
import { ResourcePath, Url } from "src/api/paths";
import {
    Type_prj_post_ResourceType,
    Type_prj_put_resourceType,
} from "src/api/tms-projects/resourceTypes/types";

export const indexResourceTypes = (
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(
        `${Url.PROJECTS}${ResourcePath.RESOURCE_TYPES}?per_page=1000`,
        requestConfig,
    );
};

export const createResourceType = (
    data: Type_prj_post_ResourceType,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return post(
        data,
        `${Url.PROJECTS}${ResourcePath.RESOURCE_TYPES}`,
        requestConfig,
    );
};

export const updateResourceType = (
    data: Type_prj_put_resourceType,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return put(
        data,
        `${Url.PROJECTS}${ResourcePath.RESOURCE_TYPES}/${data.id}`,
        requestConfig,
    );
};

export const showResourceType = (
    id: number,
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(
        `${Url.PROJECTS}${ResourcePath.RESOURCE_TYPES}/${id}`,
        requestConfig,
    );
};

export const deleteResourceType = (
    id: number,
    requestConfig: Type_RequestConfig,
): RemoveReturnType => {
    return remove(
        { id },
        `${Url.PROJECTS}${ResourcePath.RESOURCE_TYPES}/${id}`,
        requestConfig,
    );
};

export const statusResourceType = (
    data: Type_prj_put_resourceType,
    requestConfig: Type_RequestConfig,
): PutReturnType => {
    return put(
        data,
        `${Url.PROJECTS}${ResourcePath.RESOURCE_TYPES}/${data.id}`,
        requestConfig,
    );
};

export const selectListResourceTypes = (
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(
        `${Url.PROJECTS}${ResourcePath.RESOURCE_TYPES}/select-list`,
        requestConfig,
    );
};
