import React from "react";

import { TMC_DatePicker } from "src/components/Components_Common/_MuiComponentsVariants";
import { getCurrentFormat, getDate } from "src/utils/date";

import { InputDatePropsType } from "./InputDate.container";

export type InputDateType = {
    type: "inputDate";
    name: string;
    required?: boolean;
    label?: {
        label?: string;
        languageLabel?: string;
    };
    placeholder?: {
        label?: string;
        languageLabel?: string;
    };
};

export const InputDateComponent = ({
    value = null,
    name,
    label,
    onChange,
}: InputDatePropsType) => {
    const formattedValue = (date: any) => {
        if (typeof date === "string") return getDate(date);
        return date;
    };

    return (
        <TMC_DatePicker
            label={label}
            value={formattedValue(value)}
            onChange={(newDate) => onChange(name, getDate(newDate))}
            format={getCurrentFormat()}
        />
    );
};
