import Konva from "konva";
import React from "react";
import { Shape } from "react-konva";

import {
    colorPlanningScrollCursor,
    colorPlanningScrollCursorOver,
    durationPlanningScrollCursor,
    sizePlanningScroll,
} from "src/components/Components_Teamoty/Planning/Planning.const";

import { Type_Props_PlanningScrollCursor } from "./PlanningScroll.type";

export const PlanningScrollCursor = ({
    refCursor,
    x,
    y,
    width,
    height,
    onDragMove,
    variant = "normal",
}: Type_Props_PlanningScrollCursor) => {
    let factor: number = 3;
    if (variant === "small") factor = 5;

    const clipWidth: number = width ? width : sizePlanningScroll / factor;
    const clipHeight: number = height ? height : sizePlanningScroll / factor;
    const clipDelta: number =
        (sizePlanningScroll - sizePlanningScroll / factor) / 2;

    return (
        <Shape
            ref={refCursor}
            x={x}
            y={y}
            hitStrokeWidth={factor}
            draggable={true}
            onDragStart={(e: Konva.KonvaEventObject<DragEvent>) =>
                (e.cancelBubble = true)
            }
            onDragMove={onDragMove}
            onMouseOver={(e: Konva.KonvaEventObject<MouseEvent>) => {
                e.target.to({
                    fill: colorPlanningScrollCursorOver,
                    duration: durationPlanningScrollCursor,
                });
            }}
            onMouseOut={(e: Konva.KonvaEventObject<MouseEvent>) => {
                e.target.to({
                    fill: colorPlanningScrollCursor,
                    duration: durationPlanningScrollCursor,
                });
            }}
            sceneFunc={(ctx, shape) => {
                if (width) {
                    ctx.beginPath();
                    ctx.arc(
                        clipWidth - clipHeight / 2,
                        clipDelta + clipHeight / 2,
                        clipHeight / 2,
                        (-1 * Math.PI) / 2,
                        Math.PI / 2,
                    );
                    ctx.lineTo(clipHeight / 2, clipDelta + clipHeight);
                    ctx.arc(
                        clipHeight / 2,
                        clipDelta + clipHeight / 2,
                        clipHeight / 2,
                        Math.PI / 2,
                        (3 * Math.PI) / 2,
                    );
                    ctx.closePath();
                } else {
                    ctx.beginPath();
                    ctx.arc(
                        clipDelta + clipWidth / 2,
                        clipHeight - clipWidth / 2,
                        clipWidth / 2,
                        0,
                        Math.PI,
                    );
                    ctx.lineTo(clipDelta, clipWidth / 2);
                    ctx.arc(
                        clipDelta + clipWidth / 2,
                        clipWidth / 2,
                        clipWidth / 2,
                        Math.PI,
                        0,
                    );
                    ctx.closePath();
                }
                ctx.fillStrokeShape(shape);
            }}
            fill={colorPlanningScrollCursor}
        />
    );
};
