import { FormikValues } from "formik";

import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    PutReturnType,
} from "src/api/fetch";
import { Url, UserInvitationsPath } from "src/api/paths";

export const getUsersInvitations = (): GetReturnType => {
    return get(`${Url.USERS}${UserInvitationsPath.USER_INVITATIONS}`);
};

export const createUserInvitations = (data: FormikValues): PostReturnType => {
    return post(
        { invitations: data },
        `${Url.USERS}${UserInvitationsPath.BATCH_INVITATIONS}`,
    );
};

export const getUserInvitationsById = (id: number): GetReturnType => {
    return get(`${Url.USERS}${UserInvitationsPath.USER_INVITATIONS}/${id}`);
};

export const updateUserInvitations = (data: any): PutReturnType => {
    return put(
        data,
        `${Url.USERS}${UserInvitationsPath.USER_INVITATIONS}/${data?.id}`,
    );
};

export const checkEmail = (email: string): PostReturnType => {
    return post({ email }, `${Url.USERS}${UserInvitationsPath.CHECK_EMAIL}`);
};

export const checkToken = (token: string): PostReturnType => {
    return post({ token }, `${Url.USERS}${UserInvitationsPath.CHECK_TOKEN}`);
};

export const getMetadataUsersForm = (): GetReturnType => {
    return get(`${Url.USERS}${UserInvitationsPath.METADATA_USERS}`);
};

export const createUserWithToken = (
    data: any,
    token: string,
): PostReturnType => {
    return post(
        data,
        `${Url.USERS}${UserInvitationsPath.CREATE_USER}/${token}`,
    );
};

export const createCompanyWithToken = (
    data: any,
    token: string,
): PostReturnType => {
    return post(
        data,
        `${Url.USERS}${UserInvitationsPath.CREATE_COMPANY}/${token}`,
    );
};
