import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Form } from "formik";

export const Styled_StackChildrenForgetPassword = styled(Stack)(() => ({
    maxWidth: "400px",
    width: "100%",
    justifyContent: "center",
}));

export const Styled_FormForgetPassword = styled(Form)(({ theme }: any) => ({
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "column",
    gap: theme.spacing(6),
}));

export const Styled_StackBackToLogin = styled(Stack)(({ theme }: any) => ({
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(2),
    padding: "8px 16px",
    cursor: "pointer",
}));
