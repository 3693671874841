import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    PutReturnType,
    remove,
    RemoveReturnType,
} from "src/api/fetch";
import { UnitPath, Url } from "src/api/paths";
import {
    Type_com_post_unitType,
    Type_com_put_unitType,
} from "src/api/tms-commons/unitTypes/types";

export const indexUnitTypes = (): GetReturnType => {
    return get(`${Url.COMMONS}${UnitPath.UNIT_TYPES}`);
};

export const createUnitType = (
    data: Type_com_post_unitType,
): PostReturnType => {
    return post(data, `${Url.COMMONS}${UnitPath.UNIT_TYPES}`);
};

export const showUnitType = (id: number): GetReturnType => {
    return get(`${Url.COMMONS}${UnitPath.UNIT_TYPES}/${id}`);
};

export const updateUnitType = (
    id: number,
    data: Type_com_put_unitType,
): PutReturnType => {
    return put(data, `${Url.COMMONS}${UnitPath.UNIT_TYPES}/${id}`);
};

export const statusUnitType = (
    id: number,
    data: Type_com_put_unitType,
): PutReturnType => {
    return put(data, `${Url.COMMONS}${UnitPath.UNIT_TYPES}/${id}`);
};

export const deleteUnitType = (id: number): RemoveReturnType => {
    return remove({ id }, `${Url.COMMONS}${UnitPath.UNIT_TYPES}/${id}`);
};

export const selectListUnitTypes = (): GetReturnType => {
    return get(`${Url.COMMONS}${UnitPath.UNIT_TYPES_SELECT_LIST}`);
};
