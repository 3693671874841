import React from "react";
import { Group, Text } from "react-konva";

import { colorPlanningTitleText } from "src/components/Components_Teamoty/Planning/Planning.const";
import { useCoreIntl } from "src/hooks/useCoreIntl";

import { Type_Props_PlanningColDataTitle } from "./PlanningColsData.type";

export const PlanningColDataTitle = ({
    x,
    y,
    height,
    width,
    text,
}: Type_Props_PlanningColDataTitle) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Planning");
    return (
        <Group x={x} y={y}>
            <Text
                width={width}
                height={height}
                padding={4}
                wrap={"none"}
                text={fmt(text)}
                fill={colorPlanningTitleText}
            />
        </Group>
    );
};
