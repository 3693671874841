import { Stack, StackProps, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import * as Yup from "yup";

import { LoadingBox, TMC_Button } from "src/components";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import {
    DELETE,
    ModalDelete,
} from "src/components/Components_Common/ModalDelete/ModalDelete";
import { NoPermission } from "src/components/Components_Common/NoPermission/NoPermission";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { useUser } from "src/contexts/user";
import { HeaderToolbar } from "src/drawers/links/HeaderToolbar";
import {
    NoteForm,
    Type_index_note,
    Type_post_note,
    Type_put_note,
} from "src/forms/notes/NoteForm";
import { useChannel } from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import {
    Content,
    Header,
} from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

const Styled_Notes = styled(Stack)<StackProps>(({ theme }) => ({
    backgroundColor: `${theme.palette.background.paperSecondary}`,
    height: "100%",
    padding: theme.spacing(2),
}));

const Styled_EmptyNotes = styled(Stack)<StackProps>(({ theme }) => ({
    backgroundColor: `${theme.palette.background.paperSecondary}`,
    height: "100%",
    borderTop: `${theme.border.default}`,
    borderBottom: `${theme.border.default}`,
    padding: theme.spacing(4),
}));

export type Type_permissionsNote = {
    canShow?: boolean;
    canEdit?: boolean;
    canDelete?: boolean;
};

export const Schema_Note = Yup.object().shape({
    note: Yup.object().shape({
        text: Yup.string()
            .trim()
            .test("not-empty-or-p-tag", FORM_ERR_FMT.REQUIRED, (value) => {
                return value !== "" && value !== "<p></p>";
            }),
    }),
});

export type Type_modalDeleteNote = {
    isOpen: boolean;
    id?: number;
};

export type Type_Props_NotesForm<T extends Type_index_note> = {
    data: T[];
    isFetching: boolean;
    mutateCreateNote: <T extends Type_post_note>(values: T) => Promise<void>;
    mutateUpdateNote: <T extends Type_put_note>(values: T) => Promise<void>;
    mutateDeleteNote: (id: number) => Promise<void>;
    permissions: Type_permissionsNote;
    onClose: () => void;
    apiRef: string;
    sendEventCount: string;
};

export const NotesForm = ({
    data,
    isFetching,
    mutateCreateNote,
    mutateUpdateNote,
    mutateDeleteNote,
    permissions = {
        canShow: false,
        canEdit: false,
        canDelete: false,
    },
    onClose,
    apiRef,
    sendEventCount,
}: Type_Props_NotesForm<Type_index_note>) => {
    const { sendEvent } = useChannel({});
    const { getFullName } = useUser();

    ///////////////////////////////////////
    ///            i18n                 ///
    ///////////////////////////////////////

    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerNotes",
    );
    const { formatMessageWithPartialKey: fmtActions } = useCoreIntl("Actions");

    ///////////////////////////////////////
    ///            States               ///
    ///////////////////////////////////////

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showCreateNote, setShowCreateNote] = useState<boolean>(false);
    const [selectedIndexNote, setSelectedIndexNote] = useState<number | null>(
        null,
    );
    const [modalDeleteNote, setModalDeleteNote] =
        useState<Type_modalDeleteNote>({
            isOpen: false,
        });

    const deleteNote = (id: number) => {
        mutateDeleteNote(id).then(() => {
            setModalDeleteNote({ isOpen: false });
            setSelectedIndexNote(null);
            if (sendEventCount) {
                sendEvent(sendEventCount, -1);
            }
        });
    };

    ///////////////////////////////////////
    ///         Submit                 ///
    ///////////////////////////////////////

    const handleSubmit = async (values: any, e?: React.BaseSyntheticEvent) => {
        e?.preventDefault();

        if (selectedIndexNote === null) {
            return;
        }

        setIsLoading(true);
        if (selectedIndexNote === -1) {
            await mutateCreateNote({
                ...values.note,
            });
            if (sendEventCount) {
                sendEvent(sendEventCount, 1);
            }
        } else {
            await mutateUpdateNote({
                ...values.note,
            });
        }
        setShowCreateNote(false);
        setSelectedIndexNote(null);
        setIsLoading(false);
    };

    ///////////////////////////////////////
    ///         Permissions             ///
    ///////////////////////////////////////

    const CAN_CREATE_NOTE = permissions?.canEdit ? permissions.canEdit : true;
    const CAN_SHOW_NOTES = permissions?.canShow ? permissions.canShow : true;

    return (
        <>
            {!CAN_SHOW_NOTES && (
                <>
                    <Header alignItems={"stretch"}>
                        <HeaderToolbar onClose={onClose} />
                    </Header>
                    <Content>
                        <NoPermission />
                    </Content>
                </>
            )}
            {CAN_SHOW_NOTES && (
                <>
                    <Header alignItems={"stretch"}>
                        <HeaderToolbar onClose={onClose} />
                        <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                        >
                            <Typography data-testid={`${apiRef}-Drawer-Title`}>
                                {fmt("Title")}
                            </Typography>
                            {CAN_CREATE_NOTE && (
                                <TMC_Button
                                    data-testid={`${apiRef}-Drawer-AddNote`}
                                    onClick={() => {
                                        setShowCreateNote(true);
                                        setSelectedIndexNote(null);
                                    }}
                                    startIcon={
                                        <Icon variant="solid" icon="plus" />
                                    }
                                >
                                    {fmtActions(`New`)}
                                </TMC_Button>
                            )}
                        </Stack>
                    </Header>
                    {isFetching ? (
                        <LoadingBox />
                    ) : (
                        <Content>
                            {(data?.length || showCreateNote) && (
                                <Styled_Notes gap={2}>
                                    {showCreateNote && (
                                        <NoteForm
                                            apiRef={apiRef}
                                            index={-1}
                                            note={null}
                                            editable={
                                                selectedIndexNote === -1 ||
                                                selectedIndexNote === null
                                            }
                                            handleSubmit={handleSubmit}
                                            selectedIndexNote={
                                                selectedIndexNote
                                            }
                                            setSelectedIndexNote={
                                                setSelectedIndexNote
                                            }
                                            getFullName={getFullName}
                                            setShowCreateNote={
                                                setShowCreateNote
                                            }
                                            permissions={permissions}
                                        />
                                    )}
                                    {data &&
                                        data.length > 0 &&
                                        data.map((note, index: number) => (
                                            <NoteForm
                                                key={index}
                                                apiRef={apiRef}
                                                index={index}
                                                note={note}
                                                editable={
                                                    CAN_CREATE_NOTE &&
                                                    index === selectedIndexNote
                                                }
                                                handleSubmit={handleSubmit}
                                                selectedIndexNote={
                                                    selectedIndexNote
                                                }
                                                setSelectedIndexNote={
                                                    setSelectedIndexNote
                                                }
                                                setModalDeleteNote={
                                                    setModalDeleteNote
                                                }
                                                permissions={permissions}
                                                isLoading={isLoading}
                                            />
                                        ))}
                                </Styled_Notes>
                            )}
                            {!data?.length && !showCreateNote && (
                                <Styled_EmptyNotes
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    textAlign={"center"}
                                >
                                    <Typography
                                        color={"secondary"}
                                        variant="body3"
                                        data-testid={`${apiRef}-Drawer-Empty-Message`}
                                    >
                                        {fmt("EmptyMessage", {
                                            values: {
                                                br: () => <br />,
                                            },
                                        })}
                                    </Typography>
                                </Styled_EmptyNotes>
                            )}
                        </Content>
                    )}
                </>
            )}
            <ModalDelete
                open={modalDeleteNote.isOpen}
                onClose={() => setModalDeleteNote({ isOpen: false })}
                item={fmt("DataName")}
                validationString={DELETE}
                descriptionString={fmt("DeleteDescriptionString")}
                actions={{
                    onSubmit: {
                        onClick: () => {
                            deleteNote(modalDeleteNote?.id as number);
                        },
                    },
                }}
            />
        </>
    );
};
