import React from "react";

import { InputEmailPropsType } from "./InputEmail.container";

export type InputEmailType = {
    type: "inputEmail";
    name: string;
    required?: boolean;
    label?: {
        label?: string;
        languageLabel?: string;
    };
    placeholder?: {
        label?: string;
        languageLabel?: string;
    };
};

export const InputEmailComponent = ({
    props,
    name,
    label,
    placeholder,
    onChange,
}: InputEmailPropsType) => {
    return (
        <div className="flex items-center justify-end space-x-4">
            <label className="whitespace-nowrap block text-sm font-medium text-gray-700">
                {label}
            </label>
            <input
                type="email"
                {...props}
                className="block w-96 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder={placeholder}
                name={name}
                onChange={onChange}
            />
        </div>
    );
};
