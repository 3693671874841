import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    remove,
    RemoveReturnType,
    Type_RequestConfig,
} from "src/api/fetch";
import { TaskProductTypesPath, Url } from "src/api/paths";

import {
    Type_sch_post_productTypeTasks,
    Type_sch_put_productTypeTasks,
} from "./types";

export const indexProductTypeTasks = (
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(
        `${Url.SCHEDULING}${TaskProductTypesPath.TASK_PRODUCT_TYPES}`,
        requestConfig,
    );
};

export const createProductTypeTask = (
    data: Type_sch_post_productTypeTasks,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return post(
        data,
        `${Url.SCHEDULING}${TaskProductTypesPath.TASK_PRODUCT_TYPES}`,
        requestConfig,
    );
};

export const updateProductTypeTask = (
    data: Type_sch_put_productTypeTasks,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return put(
        data,
        `${Url.SCHEDULING}${TaskProductTypesPath.TASK_PRODUCT_TYPES}/${data.id}`,
        requestConfig,
    );
};

export const showProductTypeTask = (
    id: number,
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(
        `${Url.SCHEDULING}${TaskProductTypesPath.TASK_PRODUCT_TYPES}/${id}`,
        requestConfig,
    );
};

export const removeProductTypeTask = (
    id: number,
    requestConfig: Type_RequestConfig,
): RemoveReturnType => {
    return remove(
        { id },
        `${Url.SCHEDULING}${TaskProductTypesPath.TASK_PRODUCT_TYPES}/${id}`,
        requestConfig,
    );
};
