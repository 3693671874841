import { InputSubmitPropsType } from "./InputSubmit.container";

export type InputSubmitType = {
    type: "inputSubmit";
    label?: {
        label?: string;
        languageLabel?: string;
    };
    options?: any;
};

export const InputSubmitComponent = ({
    label,
    props,
    onClick,
}: InputSubmitPropsType) => {
    return (
        <button
            type="submit"
            {...props}
            onClick={onClick}
            className={
                `bg-teamoty-primary text-white px-4 py-2 rounded-md shadow-md` +
                " " +
                props?.className
            }
        >
            {label}
        </button>
    );
};
