import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import * as React from "react";
import { Dispatch, SetStateAction, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";

import { mutationUpdateResourcePeriod } from "src/api/tms-projects/resourcePeriods";
import {
    mutationCreateResource,
    mutationUpdateResource,
    useIndexResources,
    useShowResource,
} from "src/api/tms-projects/resources";
import {
    Type_post_resource,
    Type_show_resource,
} from "src/api/tms-projects/resources/types";
import { useSelectListResourceTypes } from "src/api/tms-projects/resourceTypes";
import { Type_selectList } from "src/api/types";
import { LoadingBox } from "src/components";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { DrawerLabel } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric.style";
import { ColorPicker } from "src/components/Components_Common/forms/reactHookFormComponents/ColorPicker/ColorPicker";
import { DateTimeRangePicker } from "src/components/Components_Common/forms/reactHookFormComponents/DateTimeRangePicker/DateTimeRangePicker";
import { InputMultiLanguages } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages";
import { inputMultiLanguagesSchema } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages.component";
import {
    formatSelectListToSelectOptionItem,
    Select,
} from "src/components/Components_Common/forms/reactHookFormComponents/Select/Select";
import { TextField } from "src/components/Components_Common/forms/reactHookFormComponents/TextField/TextField";
import { useFormDefaultConfig } from "src/configurations/app";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { isValidDate } from "src/utils/date";
import { getLocalStorageItem } from "src/utils/localStorageServices";

export const Schema_Resource = Yup.object().shape({
    names: Yup.object().shape(inputMultiLanguagesSchema.fields),
    resourceType: Yup.number().required(FORM_ERR_FMT.REQUIRED),
    shortName: Yup.string().required(FORM_ERR_FMT.REQUIRED),
    color: Yup.string().required(FORM_ERR_FMT.REQUIRED),
    period: Yup.array()
        .min(2, FORM_ERR_FMT.REQUIRED)
        .test("all-non-null", FORM_ERR_FMT.REQUIRED_ALL, (value) => {
            return (
                value?.every((date) => date === null) ||
                value?.every((date) => date !== null)
            );
        })
        .test("valid-date", FORM_ERR_FMT.WRONG_DATE, (value) =>
            value?.every((date) => isValidDate(date) || date === null),
        ),
});

type Type_Props_ResourceForm = {
    onClose: () => void;
    resourceIdToUpdate: number | null;
    action: Type_action;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    newResourceName?: string;
    formId?: string;
    createResource?: (resourceId: number) => Promise<void>;
};

export const ResourceForm = ({
    onClose,
    createResource,
    resourceIdToUpdate,
    action,
    setIsLoading,
    newResourceName,
    formId = "resource",
}: Type_Props_ResourceForm) => {
    const language = getLocalStorageItem("language");
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Resource");
    const { formatMessageWithPartialKey: fmtLabels } = useCoreIntl("Labels");

    const { mutateAsync: mutateCreate } =
        mutationCreateResource(createResource) || {};
    const { mutateAsync: mutateUpdate } = mutationUpdateResource();
    const { mutateAsync: mutateUpdateResourcePeriod } =
        mutationUpdateResourcePeriod();

    const { isLoading, resource } = useShowResource(
        resourceIdToUpdate as number,
    );

    const { isFetching: isFetchingResource, data: resources } =
        useIndexResources() || {};
    const resourcesColors = resources?.map((resource) => resource.color);

    const { isFetching: isFetchingResourceTypes, data: resourceTypes } =
        useSelectListResourceTypes();

    const isFetching = useMemo(
        () =>
            !!(resourceIdToUpdate && isLoading) ||
            isFetchingResourceTypes ||
            isFetchingResource,
        [
            resourceIdToUpdate,
            isLoading,
            isFetchingResourceTypes,
            isFetchingResource,
        ],
    );

    const form = useForm<Type_show_resource | Type_post_resource>({
        ...useFormDefaultConfig,
        defaultValues: {
            id: undefined,
            names: {
                [language]: newResourceName ?? "",
            },
            shortName: "",
            color: "",
            resourceType: undefined,
            period: [],
            periodId: undefined,
        },
        values: resource,
        resolver: yupResolver<any>(Schema_Resource),
    });

    const handleSubmit = async (values: any, e?: React.BaseSyntheticEvent) => {
        e?.preventDefault();

        setIsLoading(true);

        try {
            if (action === "update") {
                await Promise.all([
                    mutateUpdateResourcePeriod(values),
                    mutateUpdate(values),
                ]);
            } else {
                delete values.id;

                await mutateCreate(values as Type_post_resource);
            }

            // Fermeture du drawer
            onClose();
        } catch (e: any) {
            console.error("Error when creating or updating resource model");
        }

        setIsLoading(false);
    };

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)} id={formId}>
                {isFetching ? (
                    <LoadingBox />
                ) : (
                    <Stack rowGap={6}>
                        <Select
                            name={"resourceType"}
                            options={
                                formatSelectListToSelectOptionItem(
                                    resourceTypes as Type_selectList[],
                                ) || []
                            }
                            label={fmtLabels("ResourceType")}
                            data-testid="Resource-Drawer-resourceType"
                        />
                        <InputMultiLanguages
                            name="names"
                            label={fmtLabels("Name")}
                        />
                        <Stack
                            direction="row"
                            alignItems={"center"}
                            justifyContent={"space-between"}
                        >
                            <Stack>
                                <TextField
                                    name="shortName"
                                    label={fmtLabels("Code")}
                                    data-testid="Resource-Drawer-code"
                                />
                            </Stack>
                            <ColorPicker
                                name="color"
                                label={fmtLabels("Color")}
                                data-testid="Resource-Drawer-color"
                                selectedColors={resourcesColors}
                            />
                        </Stack>
                        <DrawerLabel
                            variant="h3"
                            data-testid={
                                "Resource-Drawer-TitleAvailabilityPeriod"
                            }
                        >
                            {fmt(`AvailabilityPeriod`)}
                        </DrawerLabel>
                        <DateTimeRangePicker name="period" />
                    </Stack>
                )}
            </form>
        </FormProvider>
    );
};
