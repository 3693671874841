import { Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";

import { useShowPlatformSetting } from "src/api/tms-commons/platformSettings";
import { languages as languagesList } from "src/assets/translations";
import { LoadingBox, TMC_Button } from "src/components";
import { SectionTitle } from "src/components/Components_Common/SectionTitle";
import { PlatformSettingsDrawer } from "src/drawers/platformSettings/PlatformSettingsDrawer";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const Page_PlatformSettings = () => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Teamoty.PlatformSettings",
    );
    const { isFetching, data: platformSettings } =
        useShowPlatformSetting(1) || {};

    const page = "platformSettings";

    const [openPlatformSettingsDrawer, setOpenPlatformSettingsDrawer] =
        useState(false);

    const handleUpdatePlatformSettings = () => {
        setOpenPlatformSettingsDrawer(true);
    };

    const handleClosePlatformSettingsDrawer = () => {
        setOpenPlatformSettingsDrawer(false);
    };

    return (
        <>
            <Stack marginTop={4}>
                <SectionTitle title={fmt("Title")}>
                    <TMC_Button
                        variant={"text"}
                        data-testid="PlatformConfiguration_PlatformSettings_Edit"
                        onClick={handleUpdatePlatformSettings}
                        sx={{
                            minWidth: "auto",
                        }}
                    >
                        {fmt("Edit")}
                    </TMC_Button>
                </SectionTitle>
            </Stack>
            {isFetching ? (
                <LoadingBox />
            ) : (
                <Grid container rowSpacing={4} columnSpacing={2}>
                    <Grid item xs={4} md={3}>
                        <Typography variant="body1" color={"text.secondary"}>
                            {fmt("EmailsAddress")}
                        </Typography>
                    </Grid>
                    <Grid item xs={8} md={8}>
                        <Typography
                            variant="body1"
                            data-testid={
                                "PlatformConfiguration_PlatformSettings_Emails_Address"
                            }
                        >
                            {platformSettings?.emailAddresses.join(", ")}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} md={3}>
                        <Typography variant="body1" color={"text.secondary"}>
                            {fmt("DefaultLanguage")}
                        </Typography>
                    </Grid>
                    <Grid item xs={8} md={9}>
                        <Typography
                            variant="body1"
                            data-testid={
                                "PlatformConfiguration_PlatformSettings_Default_Language"
                            }
                        >
                            {platformSettings?.defaultLanguage
                                ? languagesList[
                                      platformSettings.defaultLanguage
                                  ].toString()
                                : ""}
                        </Typography>
                    </Grid>
                </Grid>
            )}

            <PlatformSettingsDrawer
                page={page}
                open={openPlatformSettingsDrawer}
                platformSetting={platformSettings}
                onClose={handleClosePlatformSettingsDrawer}
                isFetching={isFetching}
            />
        </>
    );
};
