import { yupResolver } from "@hookform/resolvers/yup";
import { debounce, Input } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";

import { mutationUpdateSequence } from "src/api/tms-scheduling/sequences";
import { formatterFormSequence } from "src/api/tms-scheduling/sequences/formatters";
import {
    Type_put_sequence,
    Type_show_sequence,
} from "src/api/tms-scheduling/sequences/types";
import { languagesList } from "src/assets/translations";
import { useFormDefaultConfig } from "src/configurations/app";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { SequenceContent } from "src/forms/sequence/SequenceFormComponents/SequenceFormContent";
import { SequenceFooter } from "src/forms/sequence/SequenceFormComponents/SequenceFormFooter";
import { SequenceHeader } from "src/forms/sequence/SequenceFormComponents/SequenceFormHeader";

const validationSchema = Yup.lazy(() => {
    return Yup.object().shape({
        names: Yup.object().shape(
            Object.entries(languagesList).reduce((acc: any, [languageCode]) => {
                acc[languageCode] = Yup.string().nullable();
                return acc;
            }, {}),
        ),
        color: Yup.string().nullable(),
        code: Yup.string().nullable(),
        sequenceFolderId: Yup.number().required(FORM_ERR_FMT.REQUIRED),
        earliestDate: Yup.string().nullable(),
        fixedSize: Yup.boolean(),
        optimizeByArea: Yup.boolean(),
    });
});

export const Styled_Input = styled(Input)(() => ({
    color: "inherit",
}));
Styled_Input.defaultProps = {
    size: "small",
    fullWidth: true,
};

type Type_Props_SequenceForm = {
    id: number;
    data: Type_show_sequence | undefined;
};

export const SequenceForm = ({ id, data }: Type_Props_SequenceForm) => {
    const { mutateAsync } = mutationUpdateSequence();
    const form = useForm<Type_put_sequence>({
        ...useFormDefaultConfig,
        defaultValues: {
            id: id,
            names: {},
            color: "",
            code: "",
            earliestDate: null,
            sequenceFolderId: null,
            fixedSize: false,
            optimizeByArea: false,
        },
        values: data ? formatterFormSequence(data) : undefined,
        mode: "onBlur",
        resolver: yupResolver<any>(validationSchema),
    });

    const handleSubmit = async (values: Type_put_sequence) => {
        await mutateAsync(values);
    };

    const debounceOnSubmit = useCallback(
        debounce((props) => handleSubmit(props), 1000),
        [],
    );

    return (
        <FormProvider {...form}>
            <form
                onBlur={form.handleSubmit(debounceOnSubmit, (props) =>
                    console.error("handleSubmit: invalid response", props),
                )}
            >
                <SequenceHeader />
                <SequenceContent />
                <SequenceFooter />
            </form>
        </FormProvider>
    );
};
