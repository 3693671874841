import { FormikValues, useFormik } from "formik";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import * as Yup from "yup";

import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { Type_formData_NewUser_Invitation } from "src/pages/Pages_Common/Page_NewUser_Invitation/Page_NewUser_Invitation.container";

import { Step_2Component } from "./Step_2.component";

export type Type_Step_2ContainerProps = {
    formData: Type_formData_NewUser_Invitation;
    setFormData: Dispatch<SetStateAction<Type_formData_NewUser_Invitation>>;
    step: number;
    handleStep: (stateAction: "previous" | "next") => void;
    maxStep: number;
};

export type Type_requirements = {
    MinimumLength: boolean;
    Alphanumeric: boolean;
    MustBeSame: any;
};

const Schema_NewUser_Invitation_Step_2 = Yup.object().shape({
    password: Yup.string()
        .required(FORM_ERR_FMT.REQUIRED)
        .min(16)
        .matches(/[0-9]/)
        .matches(/[a-zA-Z]/),
    passwordConfirm: Yup.string()
        .required(FORM_ERR_FMT.REQUIRED)
        .test("is-same", (value, context) => value === context.parent.password),
    firstName: Yup.string()
        .required(FORM_ERR_FMT.REQUIRED)
        .max(255, FORM_ERR_FMT.MAX_LENGTH),
    lastName: Yup.string()
        .required(FORM_ERR_FMT.REQUIRED)
        .max(255, FORM_ERR_FMT.MAX_LENGTH),
    phone: Yup.string().max(255, FORM_ERR_FMT.MAX_LENGTH),
});

export const Step_2Container = ({
    formData,
    setFormData,
    step,
    handleStep,
    maxStep,
}: Type_Step_2ContainerProps) => {
    const [requirements, setRequirements] = useState<Type_requirements>({
        MinimumLength: false,
        Alphanumeric: false,
        MustBeSame: false,
    });
    const [isRequirementsComplete, setIsRequirementsComplete] =
        useState<boolean>(false);

    const handleSubmit = async (values: FormikValues) => {
        try {
            setFormData({
                ...formData,
                password: values.password,
                passwordConfirm: values.passwordConfirm,
                firstName: values.firstName,
                lastName: values.lastName,
                phone: values.phone,
            });
            handleStep("next");
        } catch (e: any) {
            console.error(e.errors);
        }
    };

    const formik: any = useFormik({
        initialValues: formData,
        validationSchema: Schema_NewUser_Invitation_Step_2,
        onSubmit: handleSubmit,
        validateOnChange: false,
        validateOnBlur: false,
    });

    useEffect(() => {
        setRequirements({
            MinimumLength: formik?.values?.password?.length >= 16,
            Alphanumeric: /.*[a-zA-Z].*\d.*|.*\d.*[a-zA-Z].*/.test(
                formik?.values?.password,
            ),
            MustBeSame:
                formik?.values?.password?.length &&
                formik?.values?.password === formik?.values?.passwordConfirm,
        });
    }, [formik.values]);

    useEffect(() => {
        setIsRequirementsComplete(
            requirements.Alphanumeric &&
                requirements.MinimumLength &&
                requirements.MustBeSame,
        );
    }, [requirements]);

    return (
        <Step_2Component
            step={step}
            maxStep={maxStep}
            handleStep={handleStep}
            formik={formik}
            requirements={requirements}
            isRequirementsComplete={isRequirementsComplete}
        />
    );
};
