import { FormikErrors, FormikValues } from "formik";
import React, { useCallback, useMemo, useState } from "react";

import { useSelectListCompanies } from "src/api/tms-commons/companies";
import { useSelectListLicenseTypes } from "src/api/tms-commons/licenseTypes";
import { useIndexMetadataProjectsForForm } from "src/api/tms-commons/metadata";
import { mutationCreateProject } from "src/api/tms-commons/projects";
import { checkProjectNameIsAvailable } from "src/api/tms-commons/projects/services";
import { Type_put_project } from "src/api/tms-commons/projects/types";
import { useSelectListProjectTypes } from "src/api/tms-commons/projectTypes";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { useCustomQuery } from "src/hooks/useCustomQuery";

import { Projects_DrawerContentComponent } from "./Projects_DrawerContent.component";

export type Type_Projects_DrawerContentContainerProps = {
    onClose: () => void;
    onSubmit: any;
    action: string;
};

export const isNameAvailable = (
    name: string,
    setErrors: any,
    errors: FormikErrors<any>,
    initialName?: string,
) => {
    return useCustomQuery(
        ["posts", name],
        () =>
            checkProjectNameIsAvailable({
                name: name,
            }).then((res: any) => {
                if (initialName === name) {
                    setErrors({
                        ...errors,
                        name: "",
                    });
                    res.data.data = {
                        isNameAvailable: true,
                    };
                    return res;
                } else
                    setErrors({
                        ...errors,
                        name: !res.data?.data?.isNameAvailable
                            ? FORM_ERR_FMT.ALREADY_EXISTS
                            : "",
                    });
                return res;
            }),
        {
            debounce: 750,
            options: {
                enabled: !!name?.length,
                refetchOnWindowFocus: false,
            },
        },
    );
};

export const Projects_DrawerContentContainer = ({
    onClose,
    onSubmit,
    action,
}: Type_Projects_DrawerContentContainerProps) => {
    /* *** GESTION DES ETATS *** */
    const SUBMIT_STEP: number = 2;

    const [step, setStep] = useState<number>(0);

    /* QUERIES */
    const { isFetching: isFetching_ProjectTypes, data: projectTypes } =
        useSelectListProjectTypes() || {};

    const { isFetching: isFetching_LicenseTypes, data: licenseTypes } =
        useSelectListLicenseTypes("?filter[license_type_mode_id]=2") || {};
    const { isFetching: isFetching_Companies, data: companies } =
        useSelectListCompanies() || {};
    const { isFetching: isFetching_MetadataProjects, data: metadataProjects } =
        useIndexMetadataProjectsForForm({ enabled: true }) || {};
    const { mutateAsync: mutateCreate, isLoading: createLoading } =
        mutationCreateProject(onSubmit) || {};

    const [data, setData] = useState<Type_put_project>({
        name: "",
        postCode: "",
        city: "",
        country: "",
        slug: "",
        company_id: null,
        projectTypes: [],
        thumbnail: [],
        projectStartDate: null,
        startDate: null,
        endDate: null,
        renewal: 0,
        cost: 0,
        licenses: null,
        metadata: {},
    });

    const hasMetadataStep = useMemo(
        () => !!metadataProjects?.length,
        [metadataProjects],
    );

    const handleSubmit = async (values: FormikValues) => {
        if (step !== SUBMIT_STEP) {
            setData((prevData) => ({ ...prevData, ...values }));
            setStep((prevStep: number) => prevStep + (hasMetadataStep ? 1 : 2));
            return;
        }
        try {
            await mutateCreate(values as Type_put_project);
            onClose();
        } catch (e: any) {
            console.log(e);
        }
    };

    const isLoading = useMemo(() => createLoading, [createLoading]);

    const isFetching = useMemo(
        () =>
            isFetching_Companies ||
            isFetching_ProjectTypes ||
            isFetching_LicenseTypes ||
            isFetching_MetadataProjects,
        [
            isFetching_ProjectTypes,
            isFetching_Companies,
            isFetching_LicenseTypes,
            isFetching_MetadataProjects,
        ],
    );

    const handleSlug = useCallback(
        (
            value: string,
            setFieldValue: (
                field: string,
                value: any,
                shouldValidate?: boolean | undefined,
            ) => void,
        ) => {
            const convertProjectNameToSlug = (input: string) => {
                return input
                    .normalize("NFD") // Remplace les lettres avec accent par leur format sans accent
                    .replace(/[\u0300-\u036f]/g, "") // Supprime les accents
                    .replace(/[^a-zA-Z0-9- ']+/g, "") // Garde uniquement les caractères demandés
                    .replace(/[\s'-]+/g, "-") // Remplace les espaces et apostrophes par un seul tiret
                    .replace(/^-+|-+$/g, "") // Supprime les tirets en début et fin de chaîne
                    .toLowerCase(); // Convertit tout en minuscules
            };

            setFieldValue("slug", convertProjectNameToSlug(value));
        },
        [],
    );

    const handleProjectName = (
        e: any,
        setFieldValue: (
            field: string,
            value: any,
            shouldValidate?: boolean | undefined,
        ) => void,
    ) => {
        setFieldValue("name", e.target.value);
        handleSlug(e.target.value, setFieldValue);
    };

    const goToPreviousStep = (values: FormikValues) => {
        setData((prevData) => ({ ...prevData, ...values }));
        setStep(
            (prevStep: number) => prevStep - (metadataProjects?.length ? 1 : 2),
        );
    };

    return (
        <Projects_DrawerContentComponent
            isFetching={isFetching}
            onClose={onClose}
            onSubmit={handleSubmit}
            data={data}
            isLoading={isLoading}
            isUpdateProject={action === "update"}
            handleProjectName={handleProjectName}
            handleSlug={handleSlug}
            projectTypes={projectTypes || []}
            companies={companies || []}
            step={step}
            hasMetadataStep={hasMetadataStep}
            submitStep={SUBMIT_STEP}
            goToPreviousStep={goToPreviousStep}
            licenseTypes={licenseTypes || []}
            metadataProjects={metadataProjects}
        />
    );
};
