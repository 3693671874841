// @flow
import { Stack, Toolbar } from "@mui/material";
import { styled } from "@mui/material/styles";
import * as React from "react";

import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { Icon } from "src/components/Components_Common/Icon/Icon";

const Styled_Toolbar = styled(Toolbar)(({ theme }) => ({
    justifyContent: "space-between",
    minHeight: "24px !important",
    marginBottom: theme.spacing(4),
}));
const Styled_ToolbarStack = styled(Stack)(() => ({
    alignItems: "baseline",
}));
Styled_ToolbarStack.defaultProps = {
    spacing: 1,
    direction: "row",
};

type Type_Props_HeaderToolbar = {
    onClose: () => void;
};

export const HeaderToolbar = ({ onClose }: Type_Props_HeaderToolbar) => {
    return (
        <Styled_Toolbar>
            <Styled_ToolbarStack></Styled_ToolbarStack>
            <Styled_ToolbarStack>
                <IconButton
                    color={"inherit"}
                    onClick={() => console.log("reload")}
                    size={"small"}
                >
                    <Icon
                        variant={"light"}
                        icon={"rotate"}
                        fontSize={"small"}
                    />
                </IconButton>

                <IconButton color={"inherit"} onClick={onClose} size={"small"}>
                    <Icon variant={"solid"} icon={"xmark"} fontSize={"small"} />
                </IconButton>
            </Styled_ToolbarStack>
        </Styled_Toolbar>
    );
};
