import { useMutation, useQuery } from "react-query";

import { Type_Filter_Task } from "src/api/filters";
import { TaskLinkKeys } from "src/api/tms-scheduling/keys";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { useChannel } from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";

import {
    formatterCreateTaskLink,
    formatterIndexTaskLinks,
    formatterShowTaskLink,
    formatterUpdateTaskLink,
} from "./formatters";
import {
    createTaskLink,
    deleteTaskLink,
    indexTaskLinks,
    showTaskLink,
    updateTaskLink,
} from "./services";
import {
    Type_put_taskLink,
    Type_sch_index_taskLink,
    Type_sch_show_taskLink,
    Type_show_taskLink,
} from "./types";

export const useShowTaskLink = (id?: number) => {
    const { requestConfig } = useProject();
    return useQuery({
        queryKey: [TaskLinkKeys.SHOW, id, requestConfig],
        queryFn: () => id && showTaskLink(id, requestConfig),
        refetchOnWindowFocus: false,
        select: (data): Type_show_taskLink => {
            const taskLink = data?.data?.data as Type_sch_show_taskLink;
            return formatterShowTaskLink(taskLink);
        },
        enabled: !!requestConfig.projectId && !!id,
    });
};

export const useIndexTaskLinks = (
    filters: Type_Filter_Task = {},
    enabled?: boolean,
    uniqueId?: string,
) => {
    const { requestConfig } = useProject();
    return useQuery({
        enabled: enabled,
        queryKey: [TaskLinkKeys.INDEX, requestConfig, filters, uniqueId],
        queryFn: () => indexTaskLinks(requestConfig, filters),
        refetchOnWindowFocus: false,
        select: (data) => {
            const taskLinks: Type_sch_index_taskLink[] = data.data.data;
            return formatterIndexTaskLinks(taskLinks);
        },
    });
};

export const mutationCreateTaskLink = () => {
    const { requestConfig } = useProject();
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    return useMutation({
        mutationFn: (newTaskLink: any) => {
            return createTaskLink(
                (newTaskLink?.callback
                    ? newTaskLink.callback
                    : formatterCreateTaskLink)(newTaskLink),
                requestConfig,
            );
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationUpdateTaskLink = () => {
    const { requestConfig } = useProject();
    const { sendEvent } = useChannel({});
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addWarning } = useToast();
    return useMutation({
        mutationFn: (taskLink: Type_put_taskLink) => {
            return updateTaskLink(
                formatterUpdateTaskLink(taskLink),
                requestConfig,
            );
        },
        onSuccess: () => {
            sendEvent("updateLink");
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationDeleteTaskLink = () => {
    const { sendEvent } = useChannel({});
    const { formatMessageWithPartialKey: fmtError } = useCoreIntl("Errors");
    const { requestConfig } = useProject();
    return useMutation({
        mutationFn: (id: number) => deleteTaskLink(id, requestConfig),
        onSuccess: () => {
            sendEvent("updateLink");
        },
        onError: (err: any) => {
            console.error(fmtError("GenericError", {}), err);
            return err;
        },
    });
};
