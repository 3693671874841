import { InputEmailComponent } from "./InputEmail.component";

export type InputEmailPropsType = {
    props: any;
    name: string;
    label: string;
    onChange?: any;
    placeholder?: string;
};

export const InputEmailContainer = (props: InputEmailPropsType) => {
    return <InputEmailComponent {...props} />;
};
