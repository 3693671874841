import { styled, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import * as Yup from "yup";

import { languagesList } from "src/assets/translations";
import { TextField } from "src/components/Components_Common/forms/reactHookFormComponents/TextField/TextField";
import { Type_ReadonlyComponent } from "src/components/Components_Common/types";
import { IconSelectTaskType } from "src/components/Components_Teamoty/forms/select/IconSelectTaskType";
import { useLanguage } from "src/contexts/languages";
import { ColorPickerTask } from "src/forms/tasks/NameForm/ColorPickerTask";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import {
    FirstItem,
    HeaderMain,
    HeaderRow,
    HeaderRowFirst,
    Item,
    LastItem,
} from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";
import { invertColor } from "src/utils/colors";
import { getUserLanguageList } from "src/utils/language";
import { getLocalStorageItem } from "src/utils/localStorageServices";

const Styled_TextField = styled(TextField)(() => ({
    ".MuiInputBase-root": {
        color: "inherit",
        // Ce double sélecteur Mui-disabled peut sembler inutile
        // mais je n'ai pas trouvé d'autre moyen de faire fonctionner le style avec le TaskForm en readonly
        "& .Mui-disabled": {
            color: "inherit",
            "-webkit-text-fill-color": "inherit",
        },
        "&.Mui-disabled": {
            color: "inherit",
            "-webkit-text-fill-color": "inherit",
        },
    },
}));

export type Type_nameForm = {
    type: number;
    names: {
        [key: string]: string;
    };
    color?: string | null;
    withTradeColor?: boolean;
};

export const nameFormInitialData = {
    type: 1,
    names: { en_GB: "" },
    color: "",
    withTradeColor: false,
};

const languageSchema = Yup.object().shape(
    Object.entries(languagesList).reduce((acc: any, [languageCode]) => {
        acc[languageCode] = Yup.string().nullable();
        if (languageCode === "en_GB") {
            // TODO set from project data
            acc[languageCode] = acc[languageCode].required(`Required`);
        }
        return acc;
    }, {}),
);

export const nameFormSchema = Yup.object().shape({
    type: Yup.number().nullable(),
    names: languageSchema,
    color: Yup.string().nullable(),
    withTradeColor: Yup.boolean(),
});

type Type_Props_NameForm = Type_ReadonlyComponent & {
    sequenceId?: number;
    id?: number;
    headerBackgroundColor?: string;
};

export const NameForm = ({
    sequenceId,
    id,
    headerBackgroundColor,
    readonly = false,
}: Type_Props_NameForm) => {
    const userLocale = getLocalStorageItem("language");
    const { languages } = useLanguage();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerTasks.Name",
    );
    const { formatMessageWithPartialKey: fmtLanguage } = useCoreIntl(
        "Project.Views.Planning.Languages",
    );

    const languagesList = getUserLanguageList({ userLocale, languages });

    const [openTooltip, setOpenTooltip] = useState(false);
    const handleTooltipClose = () => {
        setOpenTooltip(false);
    };
    const handleTooltipOpen = () => {
        setOpenTooltip(true);
    };

    return (
        <HeaderMain data-testid="NameForm-HeaderMain">
            <HeaderRowFirst
                data-testid={`NameForm-HeaderRowFirst-${languagesList[0]}`}
                sx={{
                    color: invertColor(headerBackgroundColor || "#FFF"),
                }}
            >
                <FirstItem data-testid={`NameForm-FirstItem`}>
                    <Tooltip
                        title={fmt("TaskType")}
                        open={openTooltip}
                        placement="left"
                        slotProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: "offset",
                                        options: {
                                            offset: [0, -14],
                                        },
                                    },
                                ],
                            },
                        }}
                    >
                        <div
                            onMouseEnter={handleTooltipOpen}
                            onMouseLeave={handleTooltipClose}
                            onClick={handleTooltipClose}
                        >
                            <IconSelectTaskType
                                data-testid={`select-icons-task`}
                                name="type"
                                readonly={readonly}
                            />
                        </div>
                    </Tooltip>
                </FirstItem>
                <Item data-testid={`NameForm-Item`}>
                    <Styled_TextField
                        name={`names.${languagesList[0]}`}
                        variant="standard"
                        size="small"
                        fullWidth
                        readonly={readonly}
                        sx={(theme) => ({
                            ...theme.typography.body1Medium,
                        })}
                    />
                </Item>
                {!readonly && (
                    <LastItem data-testid={`NameForm-LastItem`}>
                        <ColorPickerTask
                            name="color"
                            sequenceId={sequenceId}
                            currentTaskId={id}
                        />
                    </LastItem>
                )}
            </HeaderRowFirst>

            {languagesList.slice(1).map((language, key) => (
                <HeaderRow
                    data-testid={`NameForm-HeaderRow-${language}`}
                    key={key}
                    sx={(theme) => ({
                        color: invertColor(headerBackgroundColor || "#FFF"),
                        opacity: theme.opacity.mid,
                    })}
                >
                    <FirstItem data-testid={`NameForm-FirstItem`}>
                        <Typography variant="body3">
                            {fmtLanguage(language)}
                        </Typography>
                    </FirstItem>
                    <Item data-testid={`NameForm-Item`}>
                        <Styled_TextField
                            name={`names.${language}`}
                            variant="standard"
                            size="small"
                            fullWidth
                            readonly={readonly}
                            data-testid={`task-input-name-${language}`}
                        />
                    </Item>
                </HeaderRow>
            ))}
        </HeaderMain>
    );
};
