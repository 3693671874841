import { DevTool } from "@hookform/devtools";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    Box,
    debounce,
    InputAdornment,
    Stack,
    Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useCallback, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import * as Yup from "yup";

import {
    mutationUpdateTaskLink,
    useShowTaskLink,
} from "src/api/tms-scheduling/taskLinks";
import { formatterShowTaskLink } from "src/api/tms-scheduling/taskLinks/formatters";
import {
    LINK_DIRECTION,
    Type_put_taskLink,
} from "src/api/tms-scheduling/taskLinks/types";
import { Accordion } from "src/components/Components_Common/accordions/Accordion";
import { TextField } from "src/components/Components_Common/forms/reactHookFormComponents/TextField/TextField";
import { FullSpinner } from "src/components/Components_Common/Spinner/FullSpinner";
import { AutocompleteAreas } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteAreas";
import { AutocompleteTasks } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteTasks";
import { SelectLinkType } from "src/components/Components_Teamoty/forms/select/SelectLinkType";
import { useFormDefaultConfig } from "src/configurations/app";
import { HeaderToolbar } from "src/drawers/links/HeaderToolbar";
import { Type_event_message, useChannel } from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";

//////////////////////////////////////////////
///     STYLED                             ///
//////////////////////////////////////////////

const Styled_Header = styled(Box)(({ theme }) => ({
    color: theme.palette.text.primary,
    padding: `${theme.spacing(2)} 0`,
    borderBottom: theme.border.default,
}));

const Styled_HeaderContent = styled(Box)(({ theme }) => ({
    padding: `0 ${theme.spacing(4)}`,
}));

const linkValidationSchema = Yup.object().shape({
    linkType: Yup.number().min(1).max(4),
    dayMode: Yup.number().min(1).max(2),
    quantity: Yup.number().min(0),
    waitingDay: Yup.number().min(0),
    taskFrom: Yup.mixed().required(),
    taskTo: Yup.mixed().required(),
    areaFrom: Yup.mixed().nullable(),
    areaTo: Yup.mixed().nullable(),
});

type Type_Props_TaskForm = {
    onClose: () => void;
    id: number;
    type: LINK_DIRECTION;
};

export const LinkForm = ({ onClose, id, type }: Type_Props_TaskForm) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerLinks",
    );

    const { formatMessageWithPartialKey: fmtLabels } = useCoreIntl("Labels");

    const [, setEvent] = useState<Type_event_message>({ event: "" });
    const { sendEvent } = useChannel({
        eventHandler: setEvent,
    });

    const { data, isFetching } = useShowTaskLink(id);

    const { mutateAsync } = mutationUpdateTaskLink();

    const form = useForm<Type_put_taskLink>({
        ...useFormDefaultConfig,
        defaultValues: {
            linkType: 1,
            dayMode: 1,
            quantity: 0,
            waitingDay: 0,
            areaFrom: undefined,
            areaTo: undefined,
            taskFrom: undefined,
            taskTo: undefined,
        },
        values: data as Type_put_taskLink,
        mode: "onBlur", // submit onBlur
        resolver: yupResolver<any>(linkValidationSchema),
    });

    const [expanded, setExpanded] = useState<boolean>(true);

    const onSubmit: SubmitHandler<Type_put_taskLink> = async (
        values: Type_put_taskLink,
    ) => {
        console.log("====> onSubmit: should call API", values);

        await mutateAsync(values).then((data) => {
            sendEvent("updateLink", formatterShowTaskLink(data.data.data));
        });
    };

    const debounceOnSubmit = useCallback(
        debounce((props) => onSubmit(props), 1000),
        [],
    );

    return (
        <FormProvider {...form}>
            <form
                onBlur={form.handleSubmit(debounceOnSubmit, (props) =>
                    console.error("handleSubmit: invalid response", props),
                )}
            >
                <Styled_Header>
                    <HeaderToolbar onClose={onClose} />
                    <Styled_HeaderContent>
                        <Typography>{fmt("Title")}</Typography>
                    </Styled_HeaderContent>
                </Styled_Header>
                {isFetching ? (
                    <FullSpinner />
                ) : (
                    <Box padding={4}>
                        <Stack gap={2}>
                            <AutocompleteTasks
                                data-testid="Form-TaskLink-autocomplete-task"
                                name={
                                    type === LINK_DIRECTION.FROM
                                        ? "taskFrom"
                                        : "taskTo"
                                }
                                label={fmtLabels("Task")}
                                getOptionDisabled={(option) => {
                                    const hideId =
                                        type === LINK_DIRECTION.FROM
                                            ? data?.taskTo?.id
                                            : data?.taskFrom?.id;
                                    return option.id === hideId;
                                }}
                                renderInputProps={{
                                    variant: "outlined",
                                }}
                            />

                            <SelectLinkType name="linkType" />

                            <Stack direction={"row"} spacing={2}>
                                <TextField
                                    name="quantity"
                                    label={fmt("Quantity")}
                                    type="number"
                                    data-testid="Form-TaskLink-quantity"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                u
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {fmt("Day")}
                                            </InputAdornment>
                                        ),
                                    }}
                                    name="waitingDay"
                                    label={fmt("WaitingDay")}
                                    type="number"
                                    data-testid="Form-TaskLink-waitingDay"
                                />
                            </Stack>
                            <Accordion
                                sx={{
                                    border: "none !important",
                                }}
                                accordionSummary={{
                                    sx: {
                                        padding: "0px !important",
                                    },
                                }}
                                accordionDetails={{
                                    sx: {
                                        padding: "0px !important",
                                    },
                                }}
                                expanded={expanded}
                                onChange={() => setExpanded(!expanded)}
                                title={
                                    <Typography variant="body1">
                                        {fmtLabels("Areas")}
                                    </Typography>
                                }
                                data-testid={`Form-TaskLink-accordion-area`}
                            >
                                <Stack gap={2}>
                                    <AutocompleteAreas
                                        label={fmt("FromArea")}
                                        name="areaFrom"
                                        renderInputProps={{
                                            variant: "outlined",
                                        }}
                                    />
                                    <AutocompleteAreas
                                        label={fmt("ToArea")}
                                        name="areaTo"
                                        renderInputProps={{
                                            variant: "outlined",
                                        }}
                                    />
                                </Stack>
                            </Accordion>
                        </Stack>
                    </Box>
                )}

                <DevTool control={form.control} />
            </form>
        </FormProvider>
    );
};
