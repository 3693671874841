import { useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";

import { TaskKeys } from "src/api/tms-scheduling/keys";
import {
    mutationCreateTaskLink,
    mutationDeleteTaskLink,
    useIndexTaskLinks,
} from "src/api/tms-scheduling/taskLinks";
import {
    formatterTaskLinkToFlow,
    formatterTasksLinksToFlow,
    formatterTypePropsLinkToPostTaskLink,
} from "src/api/tms-scheduling/taskLinks/formatters";
import { Type_index_taskLink } from "src/api/tms-scheduling/taskLinks/types";
import {
    mutationCreateTask,
    mutationDeleteTask,
    mutationUpdateTask,
    useIndexTasks,
} from "src/api/tms-scheduling/tasks";
import {
    formatterTasksToFlow,
    formatterTaskToFlow,
} from "src/api/tms-scheduling/tasks/formatters";
import { Type_sch_index_task } from "src/api/tms-scheduling/tasks/types";
import { FullSpinner } from "src/components/Components_Common/Spinner/FullSpinner";
import { Flow } from "src/components/Components_Teamoty/Flow/Flow";
import {
    Type_flowLink,
    Type_flowTask,
    Type_Props_Link,
} from "src/components/Components_Teamoty/Flow/Flow.type";
import { SequencePertDiagramEmpty } from "src/components/Components_Teamoty/SequencePert/SequencePertDiagramEmpty";
import { useProject } from "src/contexts/project";
import {
    Type_event_link,
    Type_event_task,
    useChannel,
} from "src/hooks/useChannel";
import { useContextualDrawer } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";
import { COLOR_PICKER_COLORS } from "src/theme/stylesheet";
import { getLocalStorageItem } from "src/utils/localStorageServices";

type Type_Props_PertDiagram = {
    sequenceId: number;
    fixedSize?: boolean;
};

export const SequencePertDiagram = ({
    sequenceId,
    fixedSize,
}: Type_Props_PertDiagram) => {
    const [tasks, setTasks] = useState<Type_flowTask[]>([]);
    const [selectedTaskId, setSelectedTaskId] = useState<null | number>(null);
    const [taskLinks, setTaskLinks] = useState<Type_flowLink[]>([]);
    const { openPaper, closePaper } = useContextualDrawer();
    const language = getLocalStorageItem("language");
    const { requestConfig } = useProject();
    const queryClient = useQueryClient();

    const { sendEvent } = useChannel({
        eventHandler: ({ event, data }) => {
            if (event === "updateTask") {
                const taskData = data as Type_event_task;
                setTasks((prevTasks) =>
                    prevTasks.map((task) =>
                        task.id === taskData?.id
                            ? {
                                  ...task,
                                  ...formatterTaskToFlow(
                                      taskData as Type_sch_index_task,
                                  ),
                              }
                            : task,
                    ),
                );
            }
            if (event === "updateLink") {
                const linkData = data as Type_event_link;
                setTaskLinks((prevLinks) =>
                    prevLinks.map((link) =>
                        link.id === linkData?.id
                            ? {
                                  ...link,
                                  ...formatterTaskLinkToFlow(
                                      linkData as Type_index_taskLink,
                                  ),
                              }
                            : link,
                    ),
                );
            }
            // TODO: Add the possibility for a task to not have a destination linkTo/linkFrom in PERT.
            // else if (event === "addLink") {
            //     setTaskLinks((prevLinks) => {
            //         return {
            //             ...prevLinks,
            //             ...formatterTaskLinkToFlow(data as Type_event_link),
            //         };
            //     });
            // }
        },
    });

    // Fetch Tasks
    const { isFetching: isFetchingTasks } = useIndexTasks(
        (data: Type_sch_index_task[]) => {
            setTasks(formatterTasksToFlow(data) || []);
        },
        {
            sequence_id: sequenceId,
        },
        true,
        "pert",
    );

    // Fetch Tasks Links
    const { isFetching: isFetchingLinks, data } = useIndexTaskLinks(
        {
            sequence_id: sequenceId,
        },
        true,
        "pert",
    );

    const { mutateAsync: createTask, isLoading: isLoadingCreateTask } =
        mutationCreateTask({
            onSuccess: () => {
                return queryClient.invalidateQueries({
                    queryKey: [
                        TaskKeys.INDEX,
                        requestConfig,
                        {
                            sequence_id: sequenceId,
                        },
                    ],
                    exact: true,
                });
            },
        });

    useEffect(() => {
        if (data) {
            setTaskLinks(formatterTasksLinksToFlow(data) || []);
        }
    }, [data]);

    const { mutateAsync: createTaskLink } = mutationCreateTaskLink();
    const { mutateAsync: deleteTaskLink } = mutationDeleteTaskLink();
    const { mutateAsync: deleteTask } = mutationDeleteTask();
    const { mutateAsync: updateTask } = mutationUpdateTask();

    const onTaskAdd = async (pt: [number, number]) => {
        // Select palette 600
        let colorsTask = COLOR_PICKER_COLORS.slice(4).slice(0, -11);
        colorsTask = colorsTask.filter((value, index) => index % 8 === 0);

        // Check color used
        tasks.forEach((task) => {
            const index = colorsTask.findIndex((value) => value == task.color);
            console.log(task.color, index);

            if (index !== -1) {
                colorsTask.push(task.color);
                colorsTask.splice(index, 1);
            }
        });

        // Create task
        const { data } = await createTask({
            names: { [language]: "new task" },
            color: colorsTask.shift(),
            xy: pt,
            sequence_id: sequenceId,
            taskCategory_id: null,
        });
        return formatterTaskToFlow(data.data as Type_sch_index_task);
    };

    const onLinkAdd = async (props: Type_Props_Link) => {
        const taskLink = formatterTypePropsLinkToPostTaskLink(props);

        const { data } = await createTaskLink(taskLink);
        return formatterTaskLinkToFlow(data.data as Type_index_taskLink);
    };

    const onTaskDelete = async ({ id }: { id: number }) => {
        await deleteTask(id);
        if (selectedTaskId === id) {
            closePaper("task");
        }
    };

    const onLinkDelete = async ({ id }: { id: number }) => {
        await deleteTaskLink(id);
    };

    const onOpenTaskDrawer = ({ id }: { id: number }) => {
        setSelectedTaskId(id);
        openPaper("task", { id }, true);
    };

    const onUpdateTask = async (task: any) => {
        if (task.name) {
            task.names = { [language]: task.name };
            delete task.name;
        }

        await updateTask(task).then(() => {
            if (selectedTaskId) {
                sendEvent(`updateTask_${task.id}`, task);
            }
        });
    };

    const allTasks = useMemo(() => {
        return {
            tasks: tasks,
            links: taskLinks,
        };
    }, [tasks, taskLinks]);

    const isFetching = useMemo(
        () => isFetchingTasks || isFetchingLinks,
        [isFetchingTasks, isFetchingLinks],
    );

    if (!isFetching) {
        if (tasks.length > 0) {
            return (
                <Flow
                    allTasks={allTasks}
                    onAddTask={onTaskAdd}
                    onAddLink={onLinkAdd}
                    onDeleteLink={onLinkDelete}
                    onDeleteTask={onTaskDelete}
                    onUpdateTask={onUpdateTask}
                    openTaskDrawer={onOpenTaskDrawer}
                    fixedSize={fixedSize}
                />
            );
        } else {
            return (
                <SequencePertDiagramEmpty
                    sequenceId={sequenceId}
                    createTask={createTask}
                    isLoadingCreateTask={isLoadingCreateTask}
                />
            );
        }
    } else {
        return <FullSpinner />;
    }
};
