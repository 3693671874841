import { useMutation, useQuery, useQueryClient } from "react-query";

import { TagKeys } from "src/api/tms-scheduling/keys";
import { useManageErrors } from "src/configurations/errorsLabels";
import { useProject } from "src/contexts/project";

import { formatterSelectListTags } from "./formatters";
import { createTag, selectTags } from "./services";
import { Type_sch_post_tag } from "./types";

///////////////////////////////////////
///            Queries              ///
///////////////////////////////////////

export const useSelectTags = (enabled: boolean) => {
    const { requestConfig } = useProject();
    const { generateToastErrsFromBack } = useManageErrors();

    return useQuery({
        queryKey: [TagKeys.SELECT, { ...requestConfig }],
        queryFn: () => selectTags({ ...requestConfig }),
        enabled:
            !!requestConfig.projectId &&
            !!requestConfig.subProjectId &&
            enabled,
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useSelectTags");
            }

            return formatterSelectListTags(data.data.data);
        },
        onError: (err) => {
            return generateToastErrsFromBack(err, true);
        },
    });
};

///////////////////////////////////////
///            Mutations            ///
///////////////////////////////////////

export const mutationCreateTag = () => {
    const { requestConfig } = useProject();
    const { generateToastErrsFromBack } = useManageErrors();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (data: Type_sch_post_tag) =>
            createTag(data, { ...requestConfig }),
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationCreateTag");
            }

            await queryClient.invalidateQueries([TagKeys.SELECT]);
        },
        onError: (err) => {
            return generateToastErrsFromBack(err, true);
        },
    });
};
