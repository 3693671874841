import { FormikProps, FormikValues } from "formik";
import React, { useMemo, useState } from "react";

import { Type_RequestConfig } from "src/api/fetch";
import {
    mutationUpdateVersion,
    useShowVersion,
} from "src/api/tms-projects/versions";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { getLocalStorageItem } from "src/utils/localStorageServices";

import { Version_Duplicate_DrawerContentComponent } from "./Version_Duplicate_DrawerContent.component";

export type Version_Duplicate_DrawerContentContainerPropsType = {
    onClose: () => void;
    onSubmit: any;
    versionIdToUpdate?: number;
    requestConfig: Type_RequestConfig;
};

export const Version_Duplicate_DrawerContentContainer = ({
    onClose,
    onSubmit,
    versionIdToUpdate,
    requestConfig,
}: Version_Duplicate_DrawerContentContainerPropsType) => {
    const { mutateAsync: mutateUpdate, isLoading: updateLoading } =
        mutationUpdateVersion(onSubmit, requestConfig);

    const language = getLocalStorageItem("language");

    const [data, setData] = useState<any>({
        names: {},
        transversal: false,
    });

    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.SubprojectSettings.Versions.Drawer",
    );
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Form.Error");
    const { add } = useToast();

    useShowVersion(setData, requestConfig, versionIdToUpdate as number);

    const handleSubmit = async (
        values: FormikValues,
        { setErrors }: FormikProps<any>,
    ) => {
        mutateUpdate(values);
        try {
            add({
                type: "success",
                duration: 4000,
                description: fmt("ToastSuccessDuplicated", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        version: values.names[language],
                    },
                }),
            });

            onClose();
        } catch (e: any) {
            setErrors({
                names: {
                    [e.data.data.field]: FORM_ERR_FMT.DUPLICATE,
                },
            });
            add({
                type: "warning",
                description: fmtErr(FORM_ERR_FMT.DUPLICATE),
            });
        }
    };

    const isFetching = useMemo(
        () => !!(versionIdToUpdate && data.id === undefined),
        [versionIdToUpdate, data.id],
    );

    return (
        <Version_Duplicate_DrawerContentComponent
            isFetching={isFetching}
            isLoading={updateLoading}
            onClose={onClose}
            onSubmit={handleSubmit}
            data={data}
        />
    );
};
