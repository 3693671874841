import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    PutReturnType,
    remove,
    RemoveReturnType,
    Type_RequestConfig,
} from "src/api/fetch";
import { CompanyPath, Url } from "src/api/paths";
import {
    Type_com_post_company,
    Type_com_put_company,
} from "src/api/tms-commons/companies/types";

export const indexCompanies = (
    requestConfig?: Type_RequestConfig,
): GetReturnType => {
    return get(`${Url.COMMONS}${CompanyPath.COMPANIES}`, requestConfig);
};

export const selectListCompanies = (): GetReturnType => {
    return get(`${Url.COMMONS}${CompanyPath.COMPANIES_SELECT_LIST}`);
};

export const createCompany = (data: Type_com_post_company): PostReturnType => {
    return post(data, `${Url.COMMONS}${CompanyPath.COMPANIES}`);
};

export const showCompany = (id: number): GetReturnType => {
    return get(`${Url.COMMONS}${CompanyPath.COMPANIES}/${id}`);
};

export const updateCompany = (
    id: number,
    data: Type_com_put_company,
): PostReturnType => {
    return put(data, `${Url.COMMONS}${CompanyPath.COMPANIES}/${id}`);
};

export const removeCompany = (id: number): RemoveReturnType => {
    return remove({ id }, `${Url.COMMONS}${CompanyPath.COMPANIES}/${id}`);
};

export const statusCompany = (
    data: Type_com_put_company,
    id: number,
): PutReturnType => {
    return put(data, `${Url.COMMONS}${CompanyPath.COMPANIES}/${id}`);
};

export const searchCompany = (
    search: string,
    companyId?: number,
): GetReturnType => {
    const editRequest: string = companyId ? `&companyId=${companyId}` : "";
    return get(
        `${Url.COMMONS}${CompanyPath.COMPANIES}/search?name=${search}` +
            editRequest,
    );
};
