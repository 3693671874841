import Box from "@mui/material/Box";
import { GridPaginationModel } from "@mui/x-data-grid";
import { GridRowId, GridRowModel } from "@mui/x-data-grid-pro";
import React, { Dispatch, SetStateAction, useState } from "react";

import { Type_RequestConfig } from "src/api/fetch";
import {
    DEFAULT_PAGINATION_MODEL,
    PAGE_SIZE_OPTIONS,
} from "src/api/pagination";
import {
    Type_index_projectCompany,
    Type_put_projectCompany,
} from "src/api/tms-projects/projectCompanies/types";
import { Type_selectList_subTrade } from "src/api/tms-projects/subTrades/types";
import { TMC_Chip } from "src/components/Components_Common/Chip/Chip";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { ModalDelete } from "src/components/Components_Common/ModalDelete/ModalDelete";
import { HeaderGrid } from "src/components/Components_Common/Table/HeaderGrid/HeaderGrid";
import {
    GRID_HEIGHT,
    Table,
    Type_column,
} from "src/components/Components_Common/Table/Table";
import { AddButton } from "src/components/Components_Teamoty/AddButton/AddButton";
import { ProjectCompanyDrawer } from "src/drawers/projectCompanies/ProjectCompanyDrawer";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { Type_modalDeleteProjectCompany } from "src/pages/Pages_Teamoty/Project/Settings/Page_ProjectCompanies/Page_ProjectCompanies.container";

export type Type_Props_Page_CompaniesComponent = {
    companies: Type_index_projectCompany[];
    isFetching: boolean;
    changeStatus: (projectCompany: Type_put_projectCompany) => void;
    deleteProjectCompany: (id: number) => void;
    modalDeleteProjectCompany: Type_modalDeleteProjectCompany;
    setModalDeleteProjectCompany: Dispatch<
        SetStateAction<Type_modalDeleteProjectCompany>
    >;
    pagination: GridPaginationModel;
    setPagination: Dispatch<SetStateAction<GridPaginationModel>>;
    rowCount: number;
    requestConfig: Type_RequestConfig;
};

export const Page_ProjectCompaniesComponent = ({
    companies,
    isFetching,
    changeStatus,
    deleteProjectCompany,
    modalDeleteProjectCompany,
    setModalDeleteProjectCompany,
    pagination,
    setPagination,
    rowCount,
    requestConfig,
}: Type_Props_Page_CompaniesComponent) => {
    const { formatMessageWithPartialKey: fmtTableColumn } =
        useCoreIntl("Table.Column");
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.Companies",
    );

    const page = "projectCompanies";

    const [selectedProjectCompany, setSelectedProjectCompany] = useState<
        number | null
    >(null);
    const [openCompanyDrawer, setOpenCompanyDrawer] = useState(false);
    const [action, setAction] = useState<Type_action>("create");

    const openDrawer = (companyId: number | null, action: Type_action) => {
        setSelectedProjectCompany(companyId);
        setAction(action);
        setOpenCompanyDrawer(true);
    };
    const handleCreateCompany = () => openDrawer(null, "create");

    const handleEditCompany = (id: number) => openDrawer(id, "update");

    const handleCloseDrawer = () => {
        setOpenCompanyDrawer(false);
    };

    const gridColumns: Type_column[] = [
        {
            field: "name",
            sortable: true,
            headerName: fmtTableColumn("Name"),
        },
        {
            field: "subTrades",
            sortable: false,
            headerName: fmtTableColumn("Trades"),
            renderCell: (params) => {
                return (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        {params.row.subTrades.map(
                            (
                                subTrade: Type_selectList_subTrade,
                                index: number,
                            ) => {
                                return (
                                    <Box
                                        key={`ProjectCompaniesBox-${index}`}
                                        sx={{ marginTop: 1 }}
                                    >
                                        <TMC_Chip
                                            data-testid={`ProjectCompaniesBadge-${index}`}
                                            label={subTrade.name}
                                            disabled={params.row.enabled}
                                        />
                                    </Box>
                                );
                            },
                        )}
                    </div>
                );
            },
        },
        {
            field: "team",
            sortable: false,
            headerName: fmtTableColumn("Team"),
            width: 115,
        },
    ];

    const callbackEdit = (row: GridRowModel) => {
        handleEditCompany(row.id);
    };

    const callbackStatus = (row: GridRowModel) => {
        changeStatus({
            id: row.id,
            enabled: row.enabled,
        } as Type_put_projectCompany);
    };

    const callbackDelete = (row: GridRowModel) => {
        setModalDeleteProjectCompany({
            isOpen: true,
            props: row as Type_index_projectCompany,
        });
    };

    const actions = {
        onSubmit: {
            onClick: () => {
                setModalDeleteProjectCompany({
                    isOpen: false,
                });
                deleteProjectCompany(
                    modalDeleteProjectCompany?.props?.id as number,
                );
            },
        },
    };

    return (
        <>
            <HeaderGrid>
                <AddButton
                    data-testid="PagesProjectCompanies-AddOne-btn"
                    onClick={() => handleCreateCompany()}
                    page={page}
                />
            </HeaderGrid>
            <Table
                rows={companies}
                columns={gridColumns}
                noDataConfig={{
                    isFetching: isFetching,
                    dataName: fmt("DataName"),
                    action: () => handleCreateCompany(),
                }}
                onClick={(id: GridRowId) => {
                    handleEditCompany(parseInt(id as string));
                }}
                menuDropDown={{
                    callbackEdit,
                    callbackStatus,
                    callbackDelete,
                }}
                page={page}
                height={GRID_HEIGHT}
                //pagination
                pagination
                {...companies}
                paginationMode={"server"}
                paginationModel={pagination}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                initialState={{
                    pagination: DEFAULT_PAGINATION_MODEL,
                }}
                onPaginationModelChange={setPagination}
                rowCount={rowCount}
                hideFooter={false}
            />
            <ProjectCompanyDrawer
                open={openCompanyDrawer}
                onClose={handleCloseDrawer}
                companyIdToUpdate={selectedProjectCompany}
                action={action}
                page={page}
                requestConfig={requestConfig}
            />
            <ModalDelete
                open={modalDeleteProjectCompany.isOpen}
                onClose={() => setModalDeleteProjectCompany({ isOpen: false })}
                item={fmt("DataName")}
                validationString={
                    modalDeleteProjectCompany?.props?.name as string
                }
                actions={actions}
            />
        </>
    );
};
