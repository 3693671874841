import { Stack } from "@mui/material";
import * as React from "react";

import { Type_metadataProjects_For_Form } from "src/api/tms-commons/metadata/types";
import { Type_CheckboxGroupOptionItem } from "src/components/Components_Common/forms/reactHookFormComponents/CheckboxGroup/CheckboxGroup";
import { FormComponents } from "src/components/Components_Common/forms/reactHookFormComponents/MetadataFormController/FormComponents";
import { Type_RadioGroupItem } from "src/components/Components_Common/forms/reactHookFormComponents/RadioGroup/RadioGroup";
import { Type_SelectOptionItem } from "src/components/Components_Common/forms/reactHookFormComponents/Select/Select";

const formatValues = (
    input: Type_metadataProjects_For_Form,
):
    | Type_SelectOptionItem[]
    | Type_CheckboxGroupOptionItem[]
    | Type_RadioGroupItem[]
    | null => {
    switch (input.metadataType) {
        case "select":
        case "radio":
            return input.values.map((item) => ({
                label: item,
                value: item,
            }));
        case "checkbox":
            return input.values.map((item) => ({
                id: item,
                name: item,
            }));
        default:
            return null;
    }
};

type Type_props_MetadataFormController = {
    metadata: Type_metadataProjects_For_Form[];
};

export const MetadataFormController = ({
    metadata,
}: Type_props_MetadataFormController) => {
    return (
        <Stack gap={4}>
            {metadata.map(
                (input: Type_metadataProjects_For_Form, index: number) => {
                    const FormComponent = FormComponents[input.metadataType];

                    return (
                        <FormComponent
                            key={index}
                            name={`metadata.${input?.name}`}
                            label={input?.label}
                            options={formatValues(input)}
                        />
                    );
                },
            )}
        </Stack>
    );
};
