import { planningDateClass } from "@cimba-digital-construction-solution/utils/dist/class/planningDateClass";
import Konva from "konva";
import { KonvaEventObject } from "konva/lib/Node";
import { Vector2d } from "konva/lib/types";
import {
    Dispatch,
    ReactNode,
    RefObject,
    SetStateAction,
    useCallback,
    useState,
} from "react";
import { Stage } from "react-konva";
import { RectReadOnly } from "react-use-measure";

import {
    Type_planningLine,
    Type_Props_PlanningAreasOffset,
} from "src/components/Components_Teamoty/Planning/Areas/PlanningArea.type";
import { Type_PlanningDrawer } from "src/components/Components_Teamoty/Planning/Drawer/PlanningDrawer.type";
import { Type_State_PlanningDragPos } from "src/components/Components_Teamoty/Planning/Planning.type";
import { Type_State_PlanningScroll } from "src/components/Components_Teamoty/Planning/Scrolls/PlanningScroll.type";

type Type_Props_PlanningStage = {
    refStage: RefObject<Konva.Stage>;
    bounds: RectReadOnly;
    boundsPlanning: Type_PlanningDrawer;
    datesActif: Array<planningDateClass>;
    areasOffset: Type_Props_PlanningAreasOffset;
    widthDate: number;
    heightArea: number;
    scrollX: Type_State_PlanningScroll;
    scrollY: Type_State_PlanningScroll;
    setCursorHighlight: Dispatch<SetStateAction<Vector2d>>;
    setScrollX: Dispatch<SetStateAction<Type_State_PlanningScroll>>;
    setScrollY: Dispatch<SetStateAction<Type_State_PlanningScroll>>;
    changeZoomX?: (pos: number, delta?: number) => void;
    changeZoomY?: (pos: number, delta?: number) => void;
    children: ReactNode;
};

export const PlanningStage = ({
    refStage,
    bounds,
    boundsPlanning,
    datesActif,
    areasOffset,
    widthDate,
    heightArea,
    scrollX,
    scrollY,
    setCursorHighlight,
    setScrollX,
    setScrollY,
    children,
}: Type_Props_PlanningStage) => {
    const [stageDragPos, setStageDragPos] =
        useState<Type_State_PlanningDragPos>({
            mousePos: null,
            scrollPosX: 0,
            scrollPosY: 0,
        });

    const handleStageMouseMove = useCallback(
        (e: Konva.KonvaEventObject<MouseEvent>): void => {
            e.cancelBubble = true;
            const stage: Konva.Stage | null = e.currentTarget.getStage();
            if (stage) {
                const mousePos: Vector2d | null = stage.getPointerPosition();
                if (mousePos) {
                    mousePos.x -= boundsPlanning.x;
                    const dd: Array<planningDateClass> = datesActif.filter(
                        (d: planningDateClass) =>
                            d.pos <= mousePos.x &&
                            d.pos + widthDate >= mousePos.x,
                    );
                    if (dd.length) {
                        mousePos.x = dd[0].pos + boundsPlanning.x;
                    } else {
                        mousePos.x = -1;
                    }

                    mousePos.y -= boundsPlanning.y;
                    const dl: Array<Type_planningLine> =
                        areasOffset.lines.filter(
                            (l: Type_planningLine) =>
                                l.pos >= 0 &&
                                l.pos <= mousePos.y &&
                                l.pos + heightArea >= mousePos.y &&
                                l.pos < boundsPlanning.height,
                        );
                    if (dl.length) {
                        mousePos.y = dl[0].pos + boundsPlanning.y;
                    } else {
                        mousePos.y = -1;
                    }

                    setCursorHighlight(mousePos);
                }
            }
        },
        [boundsPlanning, datesActif, areasOffset, scrollX, scrollY, widthDate],
    );

    const handleStageDragStart = (
        e: Konva.KonvaEventObject<DragEvent>,
    ): void => {
        e.cancelBubble = true;
        const stage: Konva.Stage | null = e.currentTarget.getStage();
        if (stage) {
            setStageDragPos({
                mousePos: stage.getPointerPosition(),
                scrollPosX: scrollX.pos,
                scrollPosY: scrollY.pos,
            });
        }
    };

    const handleStageDragMove = (
        e: Konva.KonvaEventObject<DragEvent>,
    ): void => {
        const stage: Konva.Stage | null = e.currentTarget.getStage();
        if (stageDragPos.mousePos && stage) {
            const mousePos: Vector2d | null = stage.getPointerPosition();
            if (
                mousePos &&
                mousePos.x > boundsPlanning.x &&
                mousePos.x < boundsPlanning.x + boundsPlanning.width &&
                mousePos.y > boundsPlanning.y &&
                mousePos.y < boundsPlanning.y + boundsPlanning.height
            ) {
                const deltaX = Math.round(
                    (stageDragPos.mousePos.x - mousePos.x) / widthDate,
                );
                setScrollX(
                    (
                        prev: Type_State_PlanningScroll,
                    ): Type_State_PlanningScroll => {
                        const newPos: number = Math.min(
                            prev.size - prev.page,
                            Math.max(0, stageDragPos.scrollPosX + deltaX),
                        );
                        if (prev.pos != newPos) {
                            prev.pos = newPos;
                            return { ...prev };
                        }
                        return prev;
                    },
                );

                const deltaY: number = Math.round(
                    (stageDragPos.mousePos.y - mousePos.y) / heightArea,
                );
                setScrollY(
                    (
                        prev: Type_State_PlanningScroll,
                    ): Type_State_PlanningScroll => {
                        const newPos: number = Math.min(
                            prev.size - prev.page,
                            Math.max(0, stageDragPos.scrollPosY + deltaY),
                        );
                        if (prev.pos != newPos) {
                            prev.pos = newPos;
                            return { ...prev };
                        }
                        return prev;
                    },
                );
            }
        }
        e.target.x(0);
        e.target.y(0);
    };

    const handleWheel = (e: Konva.KonvaEventObject<WheelEvent>): void => {
        e.evt.preventDefault();
        e.evt.stopPropagation();
        e.cancelBubble = true;

        const stage: Konva.Stage | null = e.target.getStage();
        if (stage) {
            const delta = e.evt.deltaY > 0 ? 1 : -1;

            /*if (e.evt.shiftKey && e.evt.ctrlKey) {
                changeZoomX && changeZoomX(0, delta);
            } else if (e.evt.ctrlKey) {
                changeZoomY && changeZoomY(0, delta);
            } else*/
            if (e.evt.shiftKey) {
                setScrollX(
                    (
                        prev: Type_State_PlanningScroll,
                    ): Type_State_PlanningScroll => ({
                        ...prev,
                        pos: Math.min(
                            prev.size - prev.page,
                            Math.max(0, prev.pos + delta),
                        ),
                    }),
                );
            } else {
                setScrollY(
                    (
                        prev: Type_State_PlanningScroll,
                    ): Type_State_PlanningScroll => ({
                        ...prev,
                        pos: Math.min(
                            prev.size - prev.page,
                            Math.max(0, prev.pos + delta),
                        ),
                    }),
                );
            }
        }
    };

    // Empêche le clic droit natif.
    const handleContextMenu = ({ evt }: KonvaEventObject<PointerEvent>) =>
        evt.preventDefault();

    return (
        <Stage
            x={0}
            y={0}
            refStage={refStage}
            offsetX={0.5}
            offsetY={0.5}
            width={bounds.width}
            height={bounds.height}
            onWheel={handleWheel}
            onMouseMove={handleStageMouseMove}
            onDragStart={handleStageDragStart}
            onDragMove={handleStageDragMove}
            draggable={true}
            listening={true}
            onContextMenu={handleContextMenu}
        >
            {children}
        </Stage>
    );
};
