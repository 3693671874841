import { Stack, Tooltip } from "@mui/material";
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { Type_index_view } from "src/api/tms-projects/workspacesAndViews/views/types";
import { Type_show_workspace } from "src/api/tms-projects/workspacesAndViews/workspaces/types";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { TopBarLeft_IconButton } from "src/components/Components_Teamoty/TopBar/stylesheet";
import { ViewSelection } from "src/dialogs/ViewSelection";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import {
    Styled_ViewsTabs,
    Styled_ViewTab,
} from "src/layouts/Layout_Workspace/stylesheet";

type Type_Props_ViewsTabs = {
    currentView: Type_index_view | undefined;
    workspace: Type_show_workspace;
    handleChangeTab: (viewId: number) => void;
    addNewView: (view: Type_index_view) => void;
};

export const ViewsTabs = ({
    currentView,
    workspace,
    handleChangeTab,
    addNewView,
}: Type_Props_ViewsTabs) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Project.Views");
    const { formatMessageWithPartialKey: fmtWorkspace } =
        useCoreIntl("Workspace");

    const { views } = workspace;

    const currentTab = useMemo(() => {
        return currentView ? currentView.id : views[0]?.id;
    }, [currentView, views]);

    const [open, setOpen] = useState<boolean>(false);

    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        handleChangeTab(newValue);
    };

    // Check if the currentTab matches any of the view ids
    const validTabIds = views.map((view) => view.id);
    const validTab = validTabIds.includes(currentTab)
        ? currentTab
        : validTabIds[0] ?? null;

    return (
        <>
            <Stack direction="row">
                <Styled_ViewsTabs
                    value={validTab}
                    onChange={handleChange}
                    variant="scrollable"
                >
                    {views.map((view: Type_index_view) => (
                        <Styled_ViewTab
                            data-testid={`Workspace-id-${workspace.id}-View-id-${view.id}`}
                            key={view.id}
                            disableRipple={true}
                            label={fmtWorkspace(view.name + ".Name")}
                            value={view.id}
                            to={`../${view.id}`}
                            component={Link}
                        />
                    ))}
                </Styled_ViewsTabs>
                <Tooltip title={fmt("AddViewTooltip")}>
                    <TopBarLeft_IconButton
                        aria-haspopup="true"
                        color="inherit"
                        onClick={handleOpen}
                        data-testid="ViewsList-AddNew-btn"
                        aria-controls={open ? "views-menu" : undefined}
                        aria-expanded={open}
                        sx={{
                            boxShadow:
                                "inset 1px 0px 0px 0px rgba(7, 8, 8, 0.95), inset -1px 0px 0px 0px rgba(7, 8, 8, 0.95)",
                        }}
                    >
                        <Icon
                            variant="light"
                            icon="plus"
                            sx={{ width: "24px" }}
                        />
                    </TopBarLeft_IconButton>
                </Tooltip>
            </Stack>

            <ViewSelection
                workspaceId={workspace.id}
                handleClose={handleClose}
                open={open}
                addNewView={addNewView}
            />
        </>
    );
};
