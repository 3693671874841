import { useFormik } from "formik";
import { Dispatch, SetStateAction } from "react";

import { useSearchCompany } from "src/api/tms-commons/companies";
import { Spinner } from "src/components";
import { Type_formData_NewUser_Invitation } from "src/pages/Pages_Common/Page_NewUser_Invitation/Page_NewUser_Invitation.container";

import { CompareCompanyComponent } from "./CompareCompany.component";

export type Type_CompareCompanyContainerProps = {
    formData: Type_formData_NewUser_Invitation;
    setFormData: Dispatch<SetStateAction<Type_formData_NewUser_Invitation>>;
    step: number;
    maxStep: number;
    handleStep: (stateAction: "previous" | "next") => void;
    handleCompanyStep: (
        stateAction: "previous" | "next",
        resetFormData: boolean,
    ) => void;
    setStep: any;
};

export const CompareCompanyContainer = ({
    step,
    maxStep,
    handleStep,
    formData,
    setFormData,
    handleCompanyStep,
    setStep,
}: Type_CompareCompanyContainerProps) => {
    const companyCreated: string = formData.company_name;
    const { isLoading, data } = useSearchCompany(companyCreated, () =>
        setStep(5),
    ); //TODO: remplacer por handleStep('next')
    const handleSubmitKnownCompany = (company: any) => {
        setFormData({
            ...formData,
            company_name: company.name,
            company_nationalId: company.nationalId,
            company_postalCode: company.postalCode,
            company_city: company.city,
            company_country: company.country,
            company_id: company.id,
        });
        handleStep("next");
    };

    const handleSubmit = () => {
        try {
            handleStep("next");
        } catch (e: any) {
            console.error(e.errors);
        }
    };

    const formik: any = useFormik({
        initialValues: formData,
        onSubmit: handleSubmit,
        validateOnChange: false,
        validateOnBlur: false,
    });

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <CompareCompanyComponent
            maxStep={maxStep}
            step={step}
            handleStep={handleStep}
            formik={formik}
            handleCompanyStep={handleCompanyStep}
            handleSubmitKnownCompany={handleSubmitKnownCompany}
            companyCreated={companyCreated}
            data={data}
        />
    );
};
