import { Stack } from "@mui/material";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";

import { TMC_FormHelperText } from "src/components/Components_Common/_MuiComponentsVariants";
import { UploadImage } from "src/components/Components_Common/index";

type Type_Props_UploadImageRhf = {
    name: string;
    service: string;
    host: string;
    id: number;
    withDescription: boolean;
    limitResolutions?: {
        min: number;
        max: number;
    };
};

export const UploadImageRhf = ({
    name,
    service,
    host,
    id,
    withDescription,
    limitResolutions = {
        min: 540,
        max: 2550,
    },
}: Type_Props_UploadImageRhf) => {
    const { formState, control, getValues, setValue } = useFormContext(); // retrieve those props

    return (
        <Stack>
            <Controller
                name={name}
                control={control}
                render={({ field: { ref: inputRef } }) => (
                    <UploadImage
                        host={host}
                        service={service}
                        id={id}
                        name={name}
                        value={getValues(name)}
                        setValue={setValue}
                        withDescription={withDescription}
                        limitResolutions={limitResolutions}
                        {...inputRef}
                    />
                )}
            />
            {formState.errors[name] && (
                <TMC_FormHelperText
                    inputName={name}
                    helperText={formState?.errors[name]?.message as string}
                />
            )}
        </Stack>
    );
};
