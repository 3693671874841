import React from "react";
import { useNavigate } from "react-router-dom";

import { Divider } from "src/components";
import { InputCancel } from "src/components/Components_Common/Form/Form_Components/Form_CTAs";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { getLocalStorageItem } from "src/utils/localStorageServices";

type Page_ForgetPasswordConfirmComponentType = {
    email?: string | null;
};

export const Page_ForgetPasswordConfirmComponent = ({
    email,
}: Page_ForgetPasswordConfirmComponentType) => {
    const navigate = useNavigate();
    const { formatMessageWithPartialKey } = useCoreIntl(
        "ForgetPasswordConfirm",
    );
    const language = getLocalStorageItem("language");

    const goHome = () => {
        navigate(`/${language}/login`, { replace: true });
    };

    return (
        <div className="bg-gray-200 flex justify-center items-center w-full h-screen">
            <div className="block-login flex justify-center w-full">
                <div className="p-8 rounded-md shadow-md">
                    <h1 className="w-full text-center">
                        {formatMessageWithPartialKey("Title")}
                    </h1>
                    <Divider className="my-6" />
                    <p>
                        {formatMessageWithPartialKey("Instruction_0", {
                            values: {
                                b: (chunks: string) => <b>{chunks}</b>,
                                email,
                            },
                        })}
                    </p>
                    <p>{formatMessageWithPartialKey("Instruction_1")}</p>
                    <Divider className="my-6" />
                    <InputCancel
                        label={formatMessageWithPartialKey("CtaHome")}
                        props={{
                            onClick: goHome,
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

Page_ForgetPasswordConfirmComponent.defaultProps = {
    email: "",
};
